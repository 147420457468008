var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "popup-style__container",
      class: { "popup-style__container_visible": _vm.isOpened },
      on: { click: _vm.onContainerClick },
    },
    [
      _c("div", {
        staticClass: "popup-style__overlay popup-style__overlay_primary",
        on: { click: _vm.closePopup },
      }),
      _vm._v(" "),
      _c(
        "div",
        { ref: "popup", staticClass: "popup-style", class: _vm.classes },
        [
          _vm.hasClose
            ? _c("span", {
                staticClass: "popup-style__close",
                on: { click: _vm.closePopup },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "popup-style__content" },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }