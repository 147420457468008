import { ISearchAdvertPrice } from '@/interfaces/adverts';
import { IPrice } from '@/interfaces/common';
import { currencies } from '@/dictionaries/common';
import number from '@/filters/format-number';
import { HashMap } from '@/types/common';
import { isEmpty } from 'underscore';

export default (
  price: ISearchAdvertPrice,
  key: string = '',
  withCurrency: boolean = true
): string => {
  if (isEmpty(price)) {
    return '';
  }

  const priceObject: IPrice = price.converted
    ? (price.converted as HashMap<IPrice>)[key]
    : price;

  const amount = key ? priceObject.amount : price.amount;
  const currency = currencies[key || price.currency];

  return withCurrency
    ? `${number(amount, true)} ${currency}`
    : `${number(amount, true)}`;
};
