
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import * as moment from 'moment';

  import date from '@/filters/format-date';
  import relativeDate from '@/filters/format-relative-date';
  import location from '@/filters/format-location';
  import DeviceClass from '@/directives/device-class';
  import { Filters } from '@onlinerby/js-common';

  import { Review } from '@/types/review';

  @Component({
    filters: {
      date,
      relativeDate,
      location,
      plural: Filters.plural,
    },
    directives: {
      DeviceClass,
    },
  })
  export default class ReviewHeader extends Vue {
    @Prop() review!: Review;
    @Prop() commentsCounter!: number | null;

    private get isUpdatedAtVisible() {
      const { updatedAt, createdAt } = this.review;

      return updatedAt && !moment(updatedAt).isSame(createdAt);
    }

    private onCommentsClick() {
      this.$emit('comments-click');
    }
  }
