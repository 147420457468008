
  import { Component, Mixins } from 'vue-property-decorator';

  import CommentsFormMixin from '@/mixins/comments/comments-form';
  import LinksMixin from '@/mixins/comments/links';

  interface IMixins extends CommentsFormMixin, LinksMixin {}

  @Component
  export default class CommentsReplyForm extends Mixins<IMixins>(
    CommentsFormMixin,
    LinksMixin,
  ) {}
