
import { Mixins, Component } from 'vue-mixin-decorator';

import FieldMixin from '@/mixins/create/field';
import StorageMixin from '@/mixins/create/storage';

interface IMixins extends FieldMixin, StorageMixin {}

@Component
export default class StepContactsSellerName extends Mixins<IMixins>(
  FieldMixin,
  StorageMixin
) {
  public created() {
    const sellerName = this.getStorageValue('sellerName');

    if (sellerName) {
      this.name = sellerName;
    }
  }

  private get seller() {
    return this.data.seller || {};
  }

  private get name() {
    return this.seller.name;
  }

  private set name(name) {
    this.clearError({ name: 'seller.name' });

    if (name) {
      this.setField({ seller: { ...this.seller, name } });
    } else {
      const { name, ...seller } = this.seller;

      this.setField({ seller });
    }
  }

  private get error() {
    return (this.errors['seller.name'] || []).join('. ');
  }
}
