var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.comment.isHidden
    ? _c(
        "div",
        {
          staticClass:
            "vehicle-form__comments-item vehicle-form__comments-item_secondary",
          class: {
            "vehicle-form__comments-item_highlighted":
              _vm.comment.isHighlighted,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "vehicle-form__comments-unit",
              class: {
                "vehicle-form__comments-unit_disabled": _vm.comment.isPending,
              },
            },
            [
              _c("div", { staticClass: "vehicle-form__preview" }, [
                _c(
                  "a",
                  {
                    staticClass: "vehicle-form__image",
                    attrs: { target: "_blank", href: _vm.userLink },
                  },
                  [
                    _c("img", {
                      staticClass: "vehicle-form__avatar",
                      attrs: { loading: "lazy", src: _vm.avatar },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_condensed-appendant",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "vehicle-form__link vehicle-form__link_primary-alter vehicle-form__link_middle vehicle-form__link_ellipsis",
                      attrs: { href: _vm.userLink },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.comment.author.name) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isBest
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "button-style button-style_primary-alter button-style_small button-style_noreflex vehicle-form__button vehicle-form__button_status",
                        },
                        [_vm._v("\n        Лучший комментарий\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAuthors
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "button-style button-style_either button-style_small button-style_noreflex vehicle-form__button vehicle-form__button_status",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_primary-additional",
                            },
                            [_vm._v("\n          Автор отзыва\n        ")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny vehicle-form__description_font-weight_normal",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm._f("relativeDate")(_vm.comment.createdAt)
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.comment.isPending ? _c("pending-info") : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "vehicle-form__text" }, [
                _c("div", { staticClass: "tui-editor-contents" }, [
                  _c(
                    "p",
                    [
                      _c("markdown-text", {
                        attrs: {
                          text: _vm.comment.text,
                          mentions: _vm.comment.mentions,
                          config: _vm.config,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              !_vm.comment.isPending
                ? _c("div", { staticClass: "vehicle-form__details" }, [
                    _c("div", { staticClass: "vehicle-form__details-list" }, [
                      _vm.settings &&
                      _vm.settings.enabled &&
                      _vm.currentUser &&
                      !_vm.activeBan
                        ? _c(
                            "div",
                            { staticClass: "vehicle-form__details-item" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_small",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "vehicle-form__link vehicle-form__link_primary vehicle-form__link_small",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onReplyClick({
                                            commentId: _vm.comment.parentId,
                                            mention: _vm.comment.author,
                                          })
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              Ответить\n            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.permissions && _vm.permissions.delete
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "vehicle-form__link vehicle-form__link_primary vehicle-form__link_small",
                                          on: { click: _vm.onDeleteClick },
                                        },
                                        [
                                          _vm._v(
                                            "\n              Удалить\n            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.permissions && _vm.permissions.moderate
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "vehicle-form__link vehicle-form__link_primary vehicle-form__link_small",
                                          attrs: {
                                            target: "_blank",
                                            href: _vm.adminLink,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              Админка\n            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "vehicle-form__details-item" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "button-style button-style_auxiliary button-style_small vehicle-form__button vehicle-form__button_likes vehicle-form__button_likes-specific vehicle-form__button_likes-alter",
                            class: {
                              "vehicle-form__button_likes-active":
                                _vm.comment.myMark === "like" ||
                                !_vm.isMarksActive,
                              "button-style_noreflex": !_vm.isMarksActive,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toggleMark("like")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.comment.marks.likes) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "vehicle-form__details-item" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "button-style button-style_auxiliary button-style_small vehicle-form__button vehicle-form__button_dislikes vehicle-form__button_dislikes-specific vehicle-form__button_dislikes-alter",
                            class: {
                              "vehicle-form__button_dislikes-active":
                                _vm.comment.myMark === "dislike" ||
                                !_vm.isMarksActive,
                              "button-style_noreflex": !_vm.isMarksActive,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toggleMark("dislike")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.comment.marks.dislikes) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }