
import { Component, Prop, Vue } from "vue-property-decorator";
import { maxGrade } from "@/config/reviews/create";
import { IMark } from "@/interfaces/reviews/grade";
import { range } from "@/services/utils-service";

@Component
export default class Grade extends Vue {
  @Prop() mark!: IMark;
  @Prop({
    default: maxGrade,
  }) maxGrade!: number;
  @Prop() currentValue!: number | undefined;

  private get picked() {
    return this.currentValue;
  }

  private set picked(value) {
    this.$emit('change', value);
  }

  private get idElement() {
    return this.mark.id;
  }

  private get reversedGrades() {
    return range(1, this.maxGrade + 1).reverse();
  }

  private getIdInputByGrade(grade) {
    return `${this.idElement}_${grade}`
  }
}
