
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';

import { ISearchAdvert, IAdvertPermissions } from '@/interfaces/adverts';
import { VuexAction } from '@/types/functions';
import AdvertManageMixin from '@/mixins/adverts/advert-manage';
import AdvertsListItemUp from '@/components/adverts/adverts-list-item-up.vue';
import AdvertClosePopover from '@/components/common/advert-close-popover.vue';

const advertsStore = namespace('adverts');

@Component({
  components: {
    AdvertsListItemUp,
    AdvertClosePopover,
  },
})
export default class AdvertsListItemActions extends Mixins<AdvertManageMixin>(
  AdvertManageMixin
) {
  @advertsStore.Action upAdvert!: VuexAction;

  private get promotionLink() {
    return {
      name: 'promote',
      query: {
        entity: this.advert.id,
      },
    };
  }

  protected get advertData() {
    const {
      id,
      location,
      specs,
      price,
      manufacturer,
      seller,
      author,
    } = this.advert;

    return {
      ad_id: id,
      ad_region: `${location.country.id}_${location.region.id}_${location.city.id}`,
      ad_condition: specs.state,
      ad_price: price.converted.USD.amount,
      ad_currency: 'USD',
      ad_mfr: manufacturer.id,
      seller_id: author ? author.id.toString() : '',
      seller_type: seller ? seller.type : '',
    };
  }

  protected onOpenClick() {
    this.$emit('open', [this.advert.id]);
  }

  protected onCloseClick() {
    this.$emit('close', [this.advert.id]);
  }
}
