import { ActionTree } from 'vuex';
import vmApi from '@/api/vm-api';
import { ISearchState, IRootState } from '@/interfaces/store';
import { IFilter } from '@/interfaces/filters';
import { ListPageResponse, CountersResponse } from '@/types/api';
import { isNumber, isEmpty } from 'underscore';
import { ICounters } from '@/interfaces/search';
import { NotificationsSettings } from '@/types/search';

const actions: ActionTree<ISearchState, IRootState> = {
  updateList(context, { filters, callbacks = {} } = {}) {
    const filtersObject = filters || context.getters.filtersObject;

    context.commit('resetPage');
    context.commit('setLoading', true);

    vmApi.search(filtersObject, {
      success({ data }: ListPageResponse) {
        context.commit('setAdvertsList', data.adverts);
        context.commit('setPage', data.page);
        context.commit('setTotal', data.total);
        context.commit('setLoading', false);

        callbacks.success && callbacks.success(data);
        callbacks.complete && callbacks.complete();
      },
    });

    vmApi.getCounters(filtersObject, {
      success({ data }: CountersResponse) {
        context.commit('setCounters', data.counters);
      },
    });
  },

  updateCounters(context, { filters, callbacks }) {
    const filtersObject = filters || context.getters.filtersObject;

    vmApi.getCounters(filtersObject, {
      success({ data }: CountersResponse) {
        context.commit('setCounters', data.counters);
        context.commit('setTotal', data.total);

        callbacks.success && callbacks.success(data);
      },
    });
  },

  updateGeneralCounters(context) {
    vmApi.getGeneralCounters({
      success: ({ data }: { data: ICounters }) => {
        context.commit('setGeneralCounters', data);
      },
    });
  },

  updateSearches(context, { id, callbacks = {} }) {
    vmApi.getSearches(id, {
      success: ({ data }: any) => {
        context.commit('setSearches', data.filters);
        context.commit('setNotificationsSettings', data.notificationsSettings);

        callbacks.success && callbacks.success(data);
        callbacks.complete && callbacks.complete();
      },
    });
  },

  setFilters(context, { filters }) {
    filters.forEach((filter: IFilter) => context.commit('setFilter', filter));
  },

  setFilter(context, { name, value, groupIndex }) {
    isNumber(groupIndex)
      ? context.commit('setGroupFilter', { name, value, groupIndex })
      : context.commit('setFilter', { name, value });
  },

  removeFilter(context, payload) {
    context.commit('removeFilter', payload);
  },

  clearFilters(context) {
    context.commit('clearFilters');
  },

  setPage(context, { page, callbacks = {} }) {
    const params = Object.assign({}, context.getters.filtersObject, {
      page,
    });

    context.commit('setLoading', true);

    vmApi.search(params, {
      success({ data }: ListPageResponse) {
        context.commit('setAdvertsList', data.adverts);
        context.commit('setPage', data.page);
        context.commit('setTotal', data.total);
        context.commit('setLoading', false);

        callbacks.success && callbacks.success(data);
        callbacks.complete && callbacks.complete();
      },
    });

    isEmpty(context.state.counters) &&
      vmApi.getCounters(context.getters.filtersObject, {
        success({ data }: CountersResponse) {
          context.commit('setCounters', data.counters);
        },
      });
  },

  addToBookmarks(context, { id, onError }) {
    vmApi.addToBookmarks(id, {
      success() {
        context.commit('patchAdvert', {
          id,
          data: {
            inBookmarks: true,
          },
        });
      },
      error({ response }: any) {
        onError && onError(response);
      },
    });
  },

  removeFromBookmarks(context, { id }) {
    vmApi.removeFromBookmarks(id, {
      success() {
        context.commit('patchAdvert', {
          id,
          data: {
            inBookmarks: false,
          },
        });
      },
    });
  },

  saveSearch(context, { id, data, callbacks }) {
    vmApi.saveSearch(id, data, callbacks);
  },

  deleteSearch(context, { userId, id, callbacks }) {
    vmApi.deleteSearch(userId, id, callbacks);
  },

  saveNotificationsSettings(
    context,
    {
      userId,
      notificationsSettings,
    }: {
      userId: String;
      notificationsSettings: NotificationsSettings;
    }
  ) {
    vmApi.saveNotificationsSettings(userId, notificationsSettings.values);
  },
};

export default actions;
