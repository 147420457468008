
import { Component, Prop, Vue } from "vue-property-decorator";
import { minus, plus } from "@/config/reviews/create";

@Component
export default class ProsConsSwitch extends Vue {
  @Prop() title!: string;
  @Prop({
    validator: function (value) {
      return [plus, minus].indexOf(value) !== -1
    }
  }) currentValue!: string | undefined;

  private get plus() {
    return plus;
  }

  private get minus() {
    return minus;
  }

  private onClick(value: string) {
    const checked = this.currentValue !== value;

    this.$emit('change', checked ? value : undefined);
  }
}
