var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__offers-unit vehicle-form__offers-unit_additional",
      },
      [
        _c("div", { staticClass: "vehicle-form__offers-item_stub" }, [
          _c("div", { staticClass: "vehicle-form__offers-flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__offers-part vehicle-form__offers-part_image",
              },
              [
                _c("div", { staticClass: "vehicle-form__preview" }, [
                  _c("div", { staticClass: "vehicle-form__image" }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__offers-part vehicle-form__offers-part_data",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_tiny vehicle-form__description_other vehicle-form__description_condensed-appendant",
                  },
                  [_vm._v("\n          Volkswagen Passat B6, 2008\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary vehicle-form__description_font-weight_bold vehicle-form__description_condensed-other",
                  },
                  [
                    _vm._v(
                      "\n          Своих денег стоит, однако есть вопросы\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_condensed-complementary",
                  },
                  [
                    _vm._v(
                      "\n          “История покупки такова, хотел и хочу себе субу STi, но прочитав все\n          форумы, выучив работу двигателя (да так что консультирую теорию\n          людям которые на них ездят) я понял что не смогу ее содержать,\n          точнее...”\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "popover-style__handle /*popover-style__handle_opened*/ popover-style__handle_interactive vehicle-form__popover-handle vehicle-form__popover-handle_reviews",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "popover-style popover-style_primary popover-style_base popover-style_bottom-right popover-style_noarrow popover-style_specific-alter popover-style_specific-additional vehicle-form__popover vehicle-form__popover_width_sssm",
                      },
                      [
                        _c("div", { staticClass: "popover-style__container" }, [
                          _c("div", { staticClass: "popover-style__content" }, [
                            _c("div", { staticClass: "vehicle-form" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__rating vehicle-form__rating_reviews",
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "rating rating_middle rating_single rating_30 vehicle-form__rating-stars",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vehicle-form__rating-text",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__title vehicle-form__title_base",
                                        },
                                        [
                                          _vm._v(
                                            "\n                        3,2\n                      "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary vehicle-form__description_font-weight_bold vehicle-form__description_condensed helpers_show_tablet",
                                },
                                [
                                  _vm._v(
                                    "\n                    “Своих денег стоит, однако есть вопросы”\n                  "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_tiny vehicle-form__description_other vehicle-form__description_condensed-appendant helpers_show_tablet",
                                },
                                [
                                  _vm._v(
                                    "\n                    Volkswagen Passat B6, 2008\n                  "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", {
                                staticClass:
                                  "vehicle-form__divider vehicle-form__divider_condensed-additional helpers_show_tablet",
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_base-alter vehicle-form__description_primary vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-complementary",
                                },
                                [
                                  _vm._v(
                                    "\n                    Оценки\n                  "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter vehicle-form__parameter_specific",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__parameter-list",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__parameter-item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vehicle-form__parameter-flex",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                              Комфорт\n                            "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-form__rating",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "rating rating_small rating_30 vehicle-form__rating-stars",
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "vehicle-form__rating-text",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_small",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                  3\n                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__parameter-item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vehicle-form__parameter-flex",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                              Внешний вид\n                            "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-form__rating",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "rating rating_small rating_30 vehicle-form__rating-stars",
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "vehicle-form__rating-text",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_small",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                  3\n                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__parameter-item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vehicle-form__parameter-flex",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                              Безопасность\n                            "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-form__rating",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "rating rating_small rating_30 vehicle-form__rating-stars",
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "vehicle-form__rating-text",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_small",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                  3\n                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__parameter-item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vehicle-form__parameter-flex",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                              Ходовые качества\n                            "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-form__rating",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "rating rating_small rating_30 vehicle-form__rating-stars",
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "vehicle-form__rating-text",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_small",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                  3\n                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__parameter-item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vehicle-form__parameter-flex",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                              Надежность\n                            "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-form__rating",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "rating rating_small rating_40 vehicle-form__rating-stars",
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "vehicle-form__rating-text",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_small",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                  4\n                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", {
                                staticClass:
                                  "vehicle-form__divider vehicle-form__divider_condensed-additional",
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_base-alter vehicle-form__description_primary vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-complementary",
                                },
                                [
                                  _vm._v(
                                    "\n                    Плюсы и минусы\n                  "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "vehicle-form__proscons" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_small vehicle-form__description_other vehicle-form__description_condensed-other",
                                    },
                                    [
                                      _vm._v(
                                        "\n                      Управляемость, Комфорт, Дизайн, Динамика, Надежность\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_small vehicle-form__description_other vehicle-form__description_condensed-other",
                                    },
                                    [
                                      _vm._v(
                                        "\n                      Дорогие запчасти, Обзорность, Вместительность, Расход\n                      топлива\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "popover-style__bottom helpers_show_tablet",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "button-style button-style_additional button-style_small popover-style__button",
                                attrs: { href: "#" },
                              },
                              [
                                _vm._v(
                                  "\n                Закрыть\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "popover-style__overlay helpers_show_tablet",
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__rating vehicle-form__rating_reviews",
                      },
                      [
                        _c("div", {
                          staticClass:
                            "rating rating_middle rating_single rating_30 vehicle-form__rating-stars",
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "vehicle-form__rating-text" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__title vehicle-form__title_base",
                              },
                              [_vm._v("\n                3,2\n              ")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "vehicle-form__footnote" }, [
                  _c("div", { staticClass: "vehicle-form__person" }, [
                    _c("div", { staticClass: "vehicle-form__person-list" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__person-item vehicle-form__person-item_condensed",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "vehicle-form__preview",
                              attrs: { href: "#" },
                            },
                            [
                              _c("div", { staticClass: "vehicle-form__image" }),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "vehicle-form__status",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny vehicle-form__description_ellipsis",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "vehicle-form__link vehicle-form__link_primary vehicle-form__link_tiny vehicle-form__link_ellipsis",
                                  attrs: { href: "#" },
                                },
                                [
                                  _vm._v(
                                    "\n                    Olezha,\n                  "
                                  ),
                                ]
                              ),
                              _vm._v(
                                "\n                  10.08.2021 в 13:00\n                "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "vehicle-form__details" }, [
                    _c("div", { staticClass: "vehicle-form__details-list" }, [
                      _c("div", { staticClass: "vehicle-form__details-item" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_tiny vehicle-form__description_comments",
                          },
                          [_vm._v("\n                  1546\n                ")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "vehicle-form__details-item" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_tiny vehicle-form__description_likes",
                          },
                          [_vm._v("\n                  20\n                ")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "vehicle-form__details-item" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_tiny vehicle-form__description_dislikes",
                          },
                          [_vm._v("\n                  12\n                ")]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("a", {
          staticClass: "vehicle-form__offers-stub",
          attrs: { href: "#" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }