
import { IUser } from '@/interfaces/common';
import { Vue, Prop, Component } from 'vue-property-decorator';

import userService from '@/services/user-service';

@Component
export default class Jivosite extends Vue {
  @Prop({
    required: true,
  })
  widgetId!: string;
  @Prop() hideMobile!: boolean;

  private mounted() {
    this.init();

    (window as any).jivo_onLoadCallback = () => {
      this.setUser();

      if (this.hideMobile) {
        const button: HTMLButtonElement = document.getElementsByClassName(
          '__jivoMobileButton'
        )[0] as HTMLButtonElement;

        button && (button.style.display = 'none');
      }
    };
  }

  private init() {
    const s = document.createElement('script');

    s.type = 'text/javascript';
    s.async = true;
    s.src = `//code.jivosite.com/script/widget/${this.widgetId}`;

    this.$el.append(s);
  }

  private setUser() {
    userService.getMe((user: IUser) => {
      (window as any).jivo_api.setContactInfo({
        name: `${user.fields.firstName || ''} ${
          user.fields.lastName || ''
        }`.trim(),
        email: user.email,
        phone: `${
          user.phone ? '+' + user.phone : ''
        }`,
        description: `USER ID: ${user.id}`,
      });
    });
  }
}
