
  import { Prop } from 'vue-property-decorator';
  import { Mixins, Component } from 'vue-mixin-decorator';
  import { namespace } from 'vuex-class';
  import * as $ from 'jquery';

  import { Dictionaries } from '@/services/dictionaries-service';
  import SearchFilters from '@/components/search/search-filters.vue';
  import SearchResults from '@/components/search/search-results.vue';
  import BannerWeb from '@/components/common/banner-web.vue';
  import IsMobileMixin from '@/mixins/common/is-mobile';
  import InterfacePropsMixin from '@/mixins/search/interface-props';
  import { FiltersConfig } from '@/config/filters';
  import { VuexAction } from '@/types/functions';
  import { Searches } from '@/types/search';
  import { SchemaProperty } from '@/types/common';

  const commonStore = namespace('common');

  interface IMixins extends IsMobileMixin, InterfacePropsMixin {}

  @Component({
    components: {
      BannerWeb,
      SearchFilters,
      SearchResults,
    },
  })
  export default class SearchFiltersBlock extends Mixins<IMixins>(
    IsMobileMixin,
    InterfacePropsMixin,
  ) {
    private isExtendedFiltersVisible: boolean = false;
    private isFixed: boolean = false;
    private isHanged: boolean = false;
    private interval!: NodeJS.Timeout;

    @Prop() pageName!: string;
    @Prop() isSaveEnabled!: boolean;
    @Prop() isLoaded!: boolean;
    @Prop() filtersConfig!: FiltersConfig;
    @Prop() extendedFilters!: FiltersConfig;
    @Prop() total!: number;
    @Prop() isLoading!: boolean;
    @Prop() searches!: Searches;
    @Prop() clearFilters!: VuexAction;
    @Prop() saveSearch!: VuexAction;

    @commonStore.Getter anyDropdownOpened!: () => boolean;
    @commonStore.State dictionaries!: Dictionaries;

    private mounted() {
      !this.isMobileSpecial && this.initStick();
    }

    private beforeDestroy() {
      clearInterval(this.interval);

      document.removeEventListener('scroll', this.updatePosition);
    }

    private get isGameEnabled() {
      return (window as any).isGameEnabled;
    }

    private hideFilters() {
      document.body.classList.remove('body_state-vehicle-form-filter_opened');

      this.updateList({
        callbacks: {
          success: () => {
            this.$root.$emit('filterChanged');
          },
        },
      });
    }

    private toggleExtendedFilters() {
      this.isExtendedFiltersVisible = !this.isExtendedFiltersVisible;
    }

    private initStick() {
      if (!this.isMobileSpecial) {
        document.addEventListener('scroll', this.updatePosition);

        this.interval = setInterval(() => {
          this.updatePosition();
        }, 500);
      }
    }

    private updatePosition() {
      const $wrapper = $(this.$refs.bannerContainer);
      const $results = $('.js-results');
      const $container = $('.js-container');
      const threshhold = 50;

      if (!$results.length || !$container.length) {
        return;
      }

      const wrapperHeight = $wrapper.outerHeight()!;
      const wrapperTop = $wrapper.offset()!.top;
      const wrapperBottom = wrapperTop + wrapperHeight;
      const resultsHeight = $results.outerHeight()!;
      const containerTop = $results.offset()!.top + resultsHeight;
      const containerHeight = $container.outerHeight()!;
      const containerBottom = $container.offset()!.top + containerHeight;

      const $window: JQuery<Window> = $(window);
      const scrollTop = $window.scrollTop();
      const stickZoneHeight = containerBottom - containerTop;
      const hasEnoughSpace = stickZoneHeight > wrapperHeight! + threshhold;

      if (!hasEnoughSpace) {
        this.isFixed = false;
        this.isHanged = false;
      } else {
        this.isFixed = scrollTop! > containerTop;
        this.isHanged = scrollTop! + wrapperHeight >= containerBottom;
      }
    }
  }
