var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "vehicle-form__comments-item vehicle-form__comments-item_additional",
    },
    [
      _c("div", { staticClass: "vehicle-form__comments-unit" }, [
        _c("div", { staticClass: "vehicle-form__control" }, [
          _c(
            "button",
            {
              staticClass:
                "button-style button-style_auxiliary button-style_small vehicle-form__button vehicle-form__button_answers",
              attrs: { type: "button" },
              on: { click: _vm.onShowClick },
            },
            [
              _vm._v(
                "\n        +\n        " +
                  _vm._s(
                    _vm._f("plural")(this.hiddenReplies.length, [
                      "ответ",
                      "ответа",
                      "ответов",
                    ])
                  ) +
                  "  \n        "
              ),
              _vm._l(_vm.hiddenReplies, function (reply) {
                return _c(
                  "div",
                  { key: reply.id, staticClass: "vehicle-form__preview" },
                  [
                    _c("div", {
                      staticClass: "vehicle-form__image",
                      staticStyle: {
                        "background-image":
                          "url(https://content.onliner.by/user/avatar/60x60/1865611)",
                      },
                      style: _vm._f("background")(_vm.getReplyImage(reply)),
                    }),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }