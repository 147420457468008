import { Vue, Watch } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import { findIndex } from 'underscore';

@Mixin
export default class MetaMixin extends Vue {
  protected header: string = '';
  protected defaultMeta!: {
    title: string;
    description: string;
    header: string;
  };

  protected parseFilters(): {
    title: string;
    description: string;
    header: string;
  } | null {
    return null;
  }

  protected updateMeta({
    title,
    description,
    header,
  }: {
    title: string;
    description: string;
    header: string;
  }) {
    this.header = header;

    this.$metaInfo.title = title;

    const index = findIndex(this.$metaInfo.meta, { name: 'description' });

    if (index !== -1) {
      this.$metaInfo.meta.splice(index, 1, {
        name: 'description',
        content: description,
      });
    }

    this.$meta().refresh();
  }

  protected onFiltersChange() {
    const meta = this.parseFilters();

    if (meta) {
      this.updateMeta(meta);

      const index = findIndex(this.$metaInfo.meta, { name: 'robots' });

      index !== -1 && this.$metaInfo.meta.splice(index, 1);
    } else {
      this.updateMeta(this.defaultMeta);
      this.$metaInfo.meta.push({
        name: 'robots',
        content: 'noindex',
      });
    }

    this.$meta().refresh();
  }

  @Watch('dictionaries')
  onDictionariesChange() {
    this.onFiltersChange();
  }
}
