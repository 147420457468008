var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__row" }, [
    _c(
      "div",
      { staticClass: "vehicle-form__group vehicle-form__group_width_full" },
      [
        _c(
          "div",
          { staticClass: "vehicle-form__label vehicle-form__label_base" },
          [
            _c("div", { staticClass: "vehicle-form__label-flex" }, [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__label-part vehicle-form__label-part_1",
                },
                [
                  _c("div", { staticClass: "vehicle-form__label-title" }, [
                    _vm._v(_vm._s(_vm.label)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "vehicle-form__label-part vehicle-form__label-part_2",
              }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vehicle-form__field" },
          [
            _vm._l(_vm.indexes, function (index) {
              return _vm._t("default", null, {
                groupIndex: index,
                onFilterRemove: _vm.onFilterRemove,
                canDelete: _vm.count > 1,
              })
            }),
            _vm._v(" "),
            _vm.canAddGroup
              ? _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__line vehicle-form__line_condensed-specific",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base vehicle-form__link_add-alter",
                            on: { click: _vm.addFilter },
                          },
                          [_vm._v("Добавить еще " + _vm._s(_vm.placeholder))]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }