import { Vue } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import { findWhere, isArray, isEmpty, isObject, uniq } from 'underscore';

import vmApi from '@/api/vm-api';
import { IFilter } from '@/interfaces/filters';

@Mixin
export default class FiltersMixin extends Vue {
  protected isFirstPage!: boolean;
  protected filters!: Array<IFilter>;

  public created() {
    const url = window.location.href.split('?').shift();

    vmApi.linkParse(url, {
      success: ({ data }: { data: any }) => {
        this.initSeoFilters(data.data);
        this.initList();
        this.updateGeneralCounters();
      },
    });
  }

  protected updateList({ callbacks }: { callbacks: { success: Function } }) {}

  protected initList() {}

  protected updateGeneralCounters() {}

  protected setFilter(filter: IFilter) {}

  protected initSeoFilters(filters: any) {
    if (this.isFirstPage && !isEmpty(this.$route.params) && isEmpty(filters)) {
      this.$router.replace({ name: 'not-found' });
    }

    if (!filters) {
      return;
    }

    Object.keys(filters).forEach(name => {
      const filter = findWhere(this.filters, { name });
      const filterValue = filters[name];
      let value;

      if (isArray(filterValue) && isObject(filterValue[0])) {
        value = [];

        filterValue.forEach((item, index) => {
          if (!filter) {
            value.push(item);

            return;
          }

          const originalItem = (filter!.value as Array<any>)[index];

          value.push({ ...item, ...originalItem });
        });
      } else if (isArray(filterValue)) {
        value = filter ? uniq([...filter.value, ...filterValue]) : filterValue;
      } else if (isObject(filterValue)) {
        value = filter
          ? { ...(filter.value as object), ...filterValue }
          : filterValue;
      } else {
        value = filterValue;
      }

      this.setFilter({
        name,
        value,
      });
    });
  }

  protected hideFilters() {
    $('body').removeClass('body_state-vehicle-form-filter_opened');

    this.updateList({
      callbacks: {
        success: () => {
          this.$root.$emit('filterChanged');
        },
      },
    });
  }
}
