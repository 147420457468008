import { Mixins } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import { IFilter } from '@/interfaces/filters';
import { Dictionaries } from '@/services/dictionaries-service';
import FilterObjects from '@/mixins/search/filter-objects';
import MetaMixin from '@/mixins/search/meta';

const commonStore = namespace('common');
const searchStore = namespace('reviews/search');

interface IMixins extends FilterObjects, MetaMixin {}

@Mixin
export default class ReviewsMetaMixin extends Mixins<IMixins>(
  FilterObjects,
  MetaMixin,
) {
  protected defaultMeta = {
    title: 'Отзывы об авто в «Автобарахолке» Onlíner',
    description:
      'Отзывы автовладельцев об автомобилях. Плюсы и минусы автомобилей в «Автобарахолке» Onlíner',
    header: 'Отзывы об автомобилях',
  };

  @commonStore.State dictionaries!: Dictionaries;
  @searchStore.State filters!: Array<IFilter>;

  protected parseFilters() {
    if (!this.filters.length) {
      return {
        title: 'Отзывы автовладельцев об автомобилях',
        description:
          'Отзывы, рейтинги, плюсы и минусы автомобилей в «Автобарахолке» Onlíner',
        header: 'Отзывы об автомобилях',
      };
    }

    if (
      this.brandObject &&
      this.modelObject &&
      this.generationObject &&
      this.filters.length === 1
    ) {
      return {
        title: `Автомобили ${this.brandObject.name} ${this.modelObject.name} ${this.generationObject.name} отзывы автовладельцев`,
        description: `Отзывы о ${this.brandObject.name} ${this.modelObject.name} ${this.generationObject.name}. Рейтинги, плюсы и минусы автомобилей в «Автобарахолке» Onlíner`,
        header: `Отзывы о ${this.brandObject.name} ${this.modelObject.name} ${this.generationObject.name}`,
      };
    }

    if (this.brandObject && this.modelObject && this.filters.length === 1) {
      return {
        title: `Автомобили ${this.brandObject.name} ${this.modelObject.name} отзывы автовладельцев`,
        description: `Отзывы о ${this.brandObject.name} ${this.modelObject.name}. Рейтинги, плюсы и минусы автомобилей в «Автобарахолке» Onlíner`,
        header: `Отзывы о ${this.brandObject.name} ${this.modelObject.name}`,
      };
    }

    if (this.brandObject && this.filters.length === 1) {
      return {
        title: `Автомобили ${this.brandObject.name} отзывы автовладельцев`,
        description: `Отзывы о ${this.brandObject.name}. Рейтинги, плюсы и минусы автомобилей в «Автобарахолке» Onlíner`,
        header: `Отзывы о ${this.brandObject.name}`,
      };
    }

    return null;
  }
}
