
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { roundTo } from '@/services/utils-service';

  @Component
  export default class RatingStars extends Vue {
    @Prop() ratingAverage!: number;
    @Prop({
      default: false,
    })
    isInfoBlockVisible!: boolean;

    private get ratingAverageCss() {
      return roundTo(this.ratingAverage, 0.5) * 10;
    }

    private onCreateReviewClick() {
      this.$router.push({
        name: 'create-review',
      });
    }
  }
