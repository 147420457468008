var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isPreviewVisible
    ? _c("div", { staticClass: "vehicle-form__offers" }, [
        _c("div", { staticClass: "vehicle-form__offers-list" }, [
          _c("div", { staticClass: "vehicle-form__offers-unit" }, [
            _c("div", { staticClass: "vehicle-form__offers-item" }, [
              _c(
                "div",
                { staticClass: "vehicle-form__offers-flex" },
                [
                  _vm.advert.images && _vm.advert.images.length
                    ? _c("search-adverts-list-photos", {
                        attrs: {
                          images: _vm.advert.images,
                          "is-premium": _vm.advert.isPremium,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__offers-part vehicle-form__offers-part_data",
                    },
                    [
                      _c("div", { staticClass: "vehicle-form__offers-flex" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__offers-part vehicle-form__offers-part_info",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__offers-flex" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__offers-part vehicle-form__offers-part_mileage",
                                  },
                                  [
                                    _vm.specs.odometer &&
                                    _vm.specs.odometer.value
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary",
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm._f("number")(
                                                    _vm.specs.odometer.value
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(_vm.odometerUnit) +
                                                "\n                    "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary",
                                          },
                                          [
                                            _vm._v(
                                              "\n                      Новый\n                    "
                                            ),
                                          ]
                                        ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__offers-part vehicle-form__offers-part_year",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary",
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(_vm.specs.year) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.advert.cityId
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__offers-part vehicle-form__offers-part_city",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_compass-alter",
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm._f("location")(
                                                    _vm.locationObject,
                                                    true
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__offers-part vehicle-form__offers-part_title",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary vehicle-form__description_font-weight_bold",
                                class: {
                                  "vehicle-form__description_premium-additional":
                                    _vm.isPremium,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__link vehicle-form__link_primary-alter vehicle-form__link_middle vehicle-form__link_noreflex",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.title) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.specs.vin
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "button-style button-style_primary button-style_small button-style_noreflex vehicle-form__button vehicle-form__button_tally",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    VIN\n                  "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.dealTerms.exchange
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "button-style button-style_otherwise button-style_small button-style_noreflex vehicle-form__button vehicle-form__button_tally helpers_hide_tablet",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Обмен\n                  "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isPremium
                                  ? _c("search-adverts-list-premium")
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__offers-part vehicle-form__offers-part_specification",
                    },
                    [
                      _c("div", { staticClass: "vehicle-form__offers-flex" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__offers-part vehicle-form__offers-part_parameter",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_engine vehicle-form__description_condensed-other",
                              },
                              [
                                _vm.specs.engine.capacity
                                  ? [
                                      _vm._v(
                                        _vm._s(_vm.specs.engine.capacity) +
                                          " л /"
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm._f("capitalize")(_vm.engine)) +
                                    "\n                "
                                ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_car vehicle-form__description_condensed-other",
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm._f("capitalize")(_vm.bodyType)) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_transmission vehicle-form__description_condensed-other",
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm._f("capitalize")(_vm.transmission)
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__offers-part vehicle-form__offers-part_parameter",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_chassis vehicle-form__description_condensed-other",
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm._f("capitalize")(_vm.drivetrain)
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_condensed-other",
                                class: [
                                  `vehicle-form__description_droplet-${_vm.specs.color}`,
                                ],
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm._f("capitalize")(_vm.color)) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.optionsCount
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_dots vehicle-form__description_condensed-other",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Еще " +
                                        _vm._s(
                                          _vm._f("plural")(_vm.optionsCount, [
                                            "опция",
                                            "опции",
                                            "опций",
                                          ])
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }