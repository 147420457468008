var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-premium" }, [
    !_vm.isLoading
      ? _c(
          "div",
          { staticClass: "vehicle-form" },
          [
            _c("promote-header"),
            _vm._v(" "),
            _c("promote-packages"),
            _vm._v(" "),
            _vm.entities.length
              ? [
                  _c("promote-adverts"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "auth-subscription" },
                    [
                      _vm.paymentMethods && _vm.paymentMethods.length
                        ? _c(
                            "payments-list",
                            {
                              ref: "paymentsList",
                              attrs: {
                                "payment-methods": _vm.paymentMethods,
                                "new-card-errors": _vm.newCardErrors,
                                "is-new-card-rules-accepted":
                                  _vm.isNewCardRulesAccepted,
                                "public-key": _vm.publicKey,
                              },
                              on: {
                                "new-card-accepted-change":
                                  _vm.onNewCardAcceptedChange,
                              },
                              model: {
                                value: _vm.selectedMethod,
                                callback: function ($$v) {
                                  _vm.selectedMethod = $$v
                                },
                                expression: "selectedMethod",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "auth-form__title auth-form__title_big-alter auth-form__title_extended-additional",
                                  attrs: { slot: "header" },
                                  slot: "header",
                                },
                                [
                                  _vm._v(
                                    "\n            Способ оплаты\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.isGameEnabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__mediabox vehicle-form__mediabox_card-alter",
                                      attrs: { slot: "header" },
                                      slot: "header",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__mediabox-flex",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vehicle-form__mediabox-part vehicle-form__mediabox-part_content",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_condensed-other",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  Выиграйте iPhone 12\n                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  Оплатите продвижение объявления на «Автобарахолке» картой\n                  Mastercard\n                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__description vehicle-form__description_other vehicle-form__description_wee",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "vehicle-form__link vehicle-form__link_primary vehicle-form__link_wee",
                                                      attrs: {
                                                        href: "https://b2bblog.onliner.by/2021/10/28/pravila-reklamnoj-igry-vyigryvaj-s-avtobaraxolkoj-onliner-i-mastercard-2",
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    Правила рекламной игры\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vehicle-form__mediabox-part vehicle-form__mediabox-part_image",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__preview",
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "vehicle-form__image vehicle-form__image_card",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.secure3dUrl
                        ? _c("secure-3d", { attrs: { src: _vm.secure3dUrl } })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("promote-controls", {
                    attrs: { "is-conditions-met": _vm.isConditionsMet },
                    on: { pay: _vm.onPay },
                  }),
                ]
              : !_vm.currentUser
              ? _c(
                  "div",
                  { staticClass: "vehicle-message vehicle-message_condensed" },
                  [
                    _c("div", { staticClass: "vehicle-message__content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-container vehicle-container_width_xxllss",
                        },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-message__title vehicle-message__title_big-alter vehicle-message__title_condensed-other",
                            },
                            [
                              _vm._v(
                                "\n            Авторизуйтесь, чтобы продолжить\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-message__control vehicle-message__control_condensed-extra",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "button-style button-style_primary button-style_small vehicle-message__button",
                                  on: { click: _vm.onLoginClick },
                                },
                                [_vm._v("Войти")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              : _c(
                  "div",
                  { staticClass: "vehicle-message vehicle-message_condensed" },
                  [
                    _c("div", { staticClass: "vehicle-message__content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-container vehicle-container_width_xxllss",
                        },
                        [
                          _vm._m(1),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-message__title vehicle-message__title_big-alter vehicle-message__title_condensed-other",
                            },
                            [
                              _vm._v(
                                "\n            У вас нет объявлений\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-message__control vehicle-message__control_condensed-extra",
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  directives: [
                                    {
                                      name: "gtm",
                                      rawName: "v-gtm",
                                      value: { event: "create_ad" },
                                      expression: "{ event: 'create_ad' }",
                                    },
                                  ],
                                  staticClass:
                                    "button-style button-style_primary button-style_small vehicle-message__button",
                                  attrs: { to: { name: "create-tech" } },
                                },
                                [_vm._v("Разместить объявление")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-message__preview" }, [
      _c("div", {
        staticClass: "vehicle-message__image vehicle-message__image_key",
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-message__preview" }, [
      _c("div", {
        staticClass: "vehicle-message__image vehicle-message__image_key",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }