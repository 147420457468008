var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__step vehicle-form__step_stub" },
      [
        _c("div", { staticClass: "vehicle-form__step-list" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__step-item vehicle-form__step-item_primary",
            },
            [
              _c("div", { staticClass: "vehicle-form__step-helper" }, [
                _c("div", { staticClass: "vehicle-form__intro" }, [
                  _c("div", { staticClass: "vehicle-form__intro-flex" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__intro-part vehicle-form__intro-part_1",
                      },
                      [
                        _c("div", { staticClass: "vehicle-form__intro-list" }, [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__intro-item" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_extended-other",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_extended-other",
                                    },
                                    [_vm._v("Kia")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "vehicle-form__intro-item" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_extended-other",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_extended-other",
                                    },
                                    [_vm._v("Magentis")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "vehicle-form__intro-item" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_extended-other",
                                },
                                [
                                  _vm._v(
                                    "2014 г., Купе, Дизель, 4 л., Передний, Механическая"
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__intro-part vehicle-form__intro-part_2",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "vehicle-form__link vehicle-form__link_primary vehicle-form__link_tiny vehicle-form__link_font-weight_normal vehicle-form__link_ellipsis",
                          },
                          [_vm._v("Изменить")]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__step-item vehicle-form__step-item_primary",
            },
            [
              _c("div", { staticClass: "vehicle-form__step-helper" }, [
                _c("div", [
                  _c("div", { staticClass: "vehicle-form__intro" }, [
                    _c("div", { staticClass: "vehicle-form__intro-flex" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__intro-part vehicle-form__intro-part_1",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__intro-list" },
                            [
                              _c(
                                "div",
                                { staticClass: "vehicle-form__intro-item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_extended-other",
                                    },
                                    [_vm._v("Внешний вид и комплектация")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__intro-part vehicle-form__intro-part_2",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "vehicle-form__link vehicle-form__link_primary vehicle-form__link_tiny vehicle-form__link_font-weight_normal vehicle-form__link_ellipsis",
                            },
                            [_vm._v("Изменить")]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "vehicle-form__upload" }, [
                    _c("div", { staticClass: "vehicle-form__upload-list" }, [
                      _c("div", { staticClass: "vehicle-form__upload-item" }, [
                        _c("div", {
                          staticClass: "vehicle-form__upload-preview",
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "vehicle-form__parameter" }, [
                    _c("div", { staticClass: "vehicle-form__parameter-list" }, [
                      _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__parameter-flex" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v("Цвет")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed vehicle-form__description_droplet-violet",
                                    },
                                    [_vm._v("Фиолетовый")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__parameter-flex" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v("Комплектация")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v("2 опции")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__step-item vehicle-form__step-item_primary",
            },
            [
              _c("div", { staticClass: "vehicle-form__step-helper" }, [
                _c("div", [
                  _c("div", { staticClass: "vehicle-form__intro" }, [
                    _c("div", { staticClass: "vehicle-form__intro-flex" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__intro-part vehicle-form__intro-part_1",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__intro-list" },
                            [
                              _c(
                                "div",
                                { staticClass: "vehicle-form__intro-item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_extended-other",
                                    },
                                    [_vm._v("Состояние и цена")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__intro-part vehicle-form__intro-part_2",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "vehicle-form__link vehicle-form__link_primary vehicle-form__link_tiny vehicle-form__link_font-weight_normal vehicle-form__link_ellipsis",
                            },
                            [_vm._v("Изменить")]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "vehicle-form__parameter" }, [
                    _c("div", { staticClass: "vehicle-form__parameter-list" }, [
                      _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__parameter-flex" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v("Состояние")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v("С пробегом, 1 000 000 км")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__parameter-flex" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v("Цена")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v("123 $")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__step-item vehicle-form__step-item_primary",
            },
            [
              _c("div", { staticClass: "vehicle-form__step-helper" }, [
                _c("div", [
                  _c("div", { staticClass: "vehicle-form__intro" }, [
                    _c("div", { staticClass: "vehicle-form__intro-flex" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__intro-part vehicle-form__intro-part_1",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__intro-list" },
                            [
                              _c(
                                "div",
                                { staticClass: "vehicle-form__intro-item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_extended-other",
                                    },
                                    [_vm._v("Контакты")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__intro-part vehicle-form__intro-part_2",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "vehicle-form__link vehicle-form__link_primary vehicle-form__link_tiny vehicle-form__link_font-weight_normal vehicle-form__link_ellipsis",
                            },
                            [_vm._v("Изменить")]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "vehicle-form__parameter" }, [
                    _c("div", { staticClass: "vehicle-form__parameter-list" }, [
                      _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__parameter-flex" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v("Местонахождение")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v("Дубай, Дубай, ОАЭ")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__parameter-flex" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v("Продавец")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v("Автодилер")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__parameter-flex" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v("Имя продавца")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v("1231")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__parameter-flex" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v("Телефон")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v("+74812312312")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__parameter-flex" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v("Звонить")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v("с 11 до 18 часов")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__step-item vehicle-form__step-item_other",
            },
            [
              _c("div", {
                staticClass:
                  "vehicle-form__divider vehicle-form__divider_extended-alter vehicle-form__divider_wide-alter",
              }),
              _vm._v(" "),
              _c("div", { staticClass: "vehicle-form__offers" }, [
                _c("div", { staticClass: "vehicle-form__offers-list" }, [
                  _c("div", { staticClass: "vehicle-form__offers-unit" }, [
                    _c("div", { staticClass: "vehicle-form__offers-item" }, [
                      _c("div", { staticClass: "vehicle-form__offers-flex" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__offers-part vehicle-form__offers-part_image",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__panorama" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "vehicle-form__panorama-box" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__panorama-list",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__panorama-item vehicle-form__panorama-item_secondary",
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "vehicle-form__preview",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__panorama-item vehicle-form__panorama-item_secondary",
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "vehicle-form__preview",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__panorama-item vehicle-form__panorama-item_secondary",
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "vehicle-form__preview",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__panorama-item vehicle-form__panorama-item_secondary",
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "vehicle-form__preview",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("a", {
                                      staticClass:
                                        "button-style button-style_transparent button-style_small vehicle-form__button vehicle-form__button_favorite",
                                      attrs: { title: "Добавить в закладки" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "button-style button-style_transparent button-style_small button-style_noreflex vehicle-form__button vehicle-form__button_foto",
                                      },
                                      [_vm._v("1 фотография")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__offers-part vehicle-form__offers-part_data",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__offers-flex" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__offers-part vehicle-form__offers-part_info",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__offers-flex",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__offers-part vehicle-form__offers-part_mileage",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary",
                                              },
                                              [_vm._v("1 000 000 км")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__offers-part vehicle-form__offers-part_year",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary",
                                              },
                                              [_vm._v("2014")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__offers-part vehicle-form__offers-part_city",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_compass-alter",
                                              },
                                              [_vm._v("Дубай, ОАЭ")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__offers-part vehicle-form__offers-part_title",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary vehicle-form__description_font-weight_bold",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__link vehicle-form__link_primary-alter vehicle-form__link_middle vehicle-form__link_noreflex",
                                          },
                                          [_vm._v("Kia Magentis")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__offers-part vehicle-form__offers-part_specification",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__offers-flex" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__offers-part vehicle-form__offers-part_parameter",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_engine vehicle-form__description_condensed-other",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        4 л /\n                        Дизель\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_car vehicle-form__description_condensed-other",
                                      },
                                      [_vm._v("Купе")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_transmission vehicle-form__description_condensed-other",
                                      },
                                      [_vm._v("Механическая")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__offers-part vehicle-form__offers-part_parameter",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_chassis vehicle-form__description_condensed-other",
                                      },
                                      [_vm._v("Передний")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_condensed-other vehicle-form__description_droplet-violet",
                                      },
                                      [_vm._v("Фиолетовый")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_dots vehicle-form__description_condensed-other",
                                      },
                                      [_vm._v("Еще 2 опции")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "vehicle-form__divider vehicle-form__divider_extended-alter vehicle-form__divider_wide-alter",
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__control vehicle-form__control_condensed-excess",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "button-style button-style_primary button-style_huge vehicle-form__button vehicle-form__button_width_full",
                    },
                    [_vm._v("Сохранить")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_small",
                },
                [
                  _vm._v(
                    "\n        Размещая объявление, вы соглашаетесь с\n        "
                  ),
                  _c(
                    "a",
                    {
                      staticClass:
                        "vehicle-form__link vehicle-form__link_primary vehicle-form__link_small",
                      attrs: {
                        href: "https://blog.onliner.by/avtobaraholka_rules",
                        target: "_blank",
                      },
                    },
                    [_vm._v("правилами Onlíner")]
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }