
import { Mixins, Component } from 'vue-mixin-decorator';

import FieldMixin from '@/mixins/create/field';
import float from '@/filters/format-float';

@Component
export default class StepCostPrice extends Mixins<FieldMixin>(FieldMixin) {
  private get price() {
    return this.data.price || {};
  }

  private get priceAmount() {
    return this.price.amount;
  }

  private set priceAmount(value) {
    const amount = parseInt(value);

    if (amount) {
      this.setField({ price: { ...this.price, amount } });
    } else {
      const { amount, ...price } = this.price;

      this.setField({ price });
    }
  }

  private get priceCurrencies() {
    return [
      {
        id: 'BYN',
        name: 'BYN',
      },
      {
        id: 'USD',
        name: 'USD',
      },
      {
        id: 'EUR',
        name: 'EUR',
      },
      {
        id: 'RUB',
        name: 'RUB',
      },
    ];
  }

  private setPriceCurrency(value: string) {
    this.clearError({ name: 'price.amount' });
    this.setField({ price: { ...this.price, currency: value } });
  }

  private get error() {
    return (this.errors['price.amount'] || []).join('. ');
  }
}
