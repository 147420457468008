
import { Watch } from 'vue-property-decorator';
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { every, findWhere } from 'underscore';

import AdvertsListItem from '@/components/adverts/adverts-list-item.vue';
import SearchAdvertsListItemStub from '@/components/search/search-adverts-list-item-stub.vue';
import { ISearchAdvert } from '@/interfaces/adverts';
import { VuexAction } from '@/types/functions';
import { IUser } from '@/interfaces/common';
import { Counters, HashMap } from '@/types/common';
import StateServiceMixin from '@/mixins/state-service';
import GtmMixin from '@/mixins/common/gtm';
import AdvertClosePopup from '@/components/advert/advert-close-popup.vue';

const userStore = namespace('user');
const advertsStore = namespace('adverts');

interface IMixins extends StateServiceMixin, GtmMixin {}

@Component({
  components: {
    AdvertClosePopup,
    AdvertsListItem,
    SearchAdvertsListItemStub,
  },
})
export default class AdvertsList extends Mixins<IMixins>(
  StateServiceMixin,
  GtmMixin,
) {
  private selectedItems: Array<string> = [];

  @advertsStore.State isLoading!: boolean;
  @advertsStore.State adverts!: Array<ISearchAdvert>;
  @advertsStore.State user!: IUser;
  @advertsStore.State permissions!: HashMap<boolean>;
  @advertsStore.Action updateAdverts!: VuexAction;
  @advertsStore.Action setLoading!: VuexAction;
  @advertsStore.Action setPage!: VuexAction;
  @advertsStore.Action openAdvert!: VuexAction;
  @advertsStore.Action openAdverts!: VuexAction;
  @advertsStore.Action closeAdvert!: VuexAction;
  @advertsStore.Action closeAdverts!: VuexAction;
  @userStore.State currentUser!: IUser;

  public created() {
    (this.$route.params.id || (this.currentUser && this.currentUser.id)) &&
      this.update();
  }

  private get status(): string {
    return this.$route.meta.status || '';
  }

  private get advertsList() {
    return this.adverts.filter((item: ISearchAdvert) => {
      return this.status === 'active' ? !item.closedAt : item.closedAt;
    });
  }

  private get isOwn() {
    return this.currentUser && this.currentUser.id === this.user.id;
  }

  private get isAllSelected() {
    return this.selectedItems.length === this.advertsList.length;
  }

  private set isAllSelected(value) {
    if (value) {
      this.selectedItems = this.advertsList.map((item) => item.id);
    } else {
      this.selectedItems = [];
    }
  }

  private get canOpen() {
    return every(
      this.selectedItems.map((id) => {
        const item = findWhere(this.advertsList, { id });

        return item && item.permissions.open;
      }),
    );
  }

  private get canClose() {
    return every(
      this.selectedItems.map((id) => {
        const item = findWhere(this.advertsList, { id });

        return item && item.permissions.close;
      }),
    );
  }

  private update() {
    const id = this.$route.params.id || this.currentUser.id;

    this.setLoading({ isLoading: true });
    this.selectedItems = [];

    if (this.stateService.state.page) {
      this.setPage({
        page: this.stateService.state.page,
        id,
        status: this.status,
        success: () => {
          this.setLoading({ isLoading: false });
          this.updateGtm();
        },
        error: this.onError,
      });
    } else {
      this.updateAdverts({
        id,
        params: {
          status: this.status,
        },
        success: ({ counters, user }: { counters: Counters; user: IUser }) => {
          this.setLoading({ isLoading: false });
          this.updateGtm();

          this.$emit('counters-update', counters);
          this.$emit('user-update', user);
        },
        error: this.onError,
      });
    }
  }

  private onSelect(value: boolean, id: string) {
    if (value) {
      this.selectedItems.push(id);
    } else {
      const index = this.selectedItems.indexOf(id);

      this.selectedItems.splice(index, 1);
    }
  }

  private onOpen(ids: Array<number>) {
    if (ids.length === 1) {
      this.openAdvert({
        id: ids[0],
        success: () => {
          this.selectedItems = [];
        },
      });
    } else {
      this.openAdverts({
        ids,
        userId: this.$route.params.id || this.currentUser.id,
        success: () => {
          this.update();
        },
      });
    }
  }

  private onClose(ids: Array<number>) {
    if (!this.permissions.moderate) {
      if (ids.length === 1) {
        this.closeAdvert({
          id: ids[0],
          success: () => {
            this.selectedItems = [];
          },
        });
      } else {
        this.closeAdverts({
          ids,
          userId: this.$route.params.id || this.currentUser.id,
          success: () => {
            this.update();
          },
        });
      }
    } else {
      (this.$refs.closeAdvertPopup as any).openPopup(ids);
    }
  }

  private onClosed({
    closureReason,
    ids,
  }: {
    closureReason: string;
    ids: Array<number>;
  }) {
    ids.forEach((id) => this.onCloseGtm(id, closureReason));

    if (ids.length === 1) {
      this.closeAdvert({
        id: ids[0],
        closureReason,
        success: () => {
          (this.$refs.closeAdvertPopup as any).closePopup();
          this.selectedItems = [];
        },
      });
    } else {
      this.closeAdverts({
        ids,
        userId: this.$route.params.id || this.currentUser.id,
        closureReason,
        success: () => {
          (this.$refs.closeAdvertPopup as any).closePopup();
          this.update();
        },
      });
    }
  }

  private onCloseGtm(id: number, reason: string) {
    const { location, specs, price, manufacturer, author } = findWhere(
      this.advertsList,
      { id },
    )!;

    const value = {
      ad_id: id,
      ad_region: `${location.country.id}_${location.region.id}_${location.city.id}`,
      ad_condition: specs.state,
      ad_price: price.converted.USD.amount,
      ad_currency: 'USD',
      ad_mfr: manufacturer.id,
      seller_id: author ? author.id.toString() : '',
      reason,
    };

    this.$gtm.trackEvent({ event: 'close_ad', value });
  }

  private onError(error: any) {
    if (
      [401, 403].indexOf(error.response.status) !== -1 &&
      this.$route.meta.status === 'closed'
    ) {
      this.$router.replace({
        name: this.$route.name!.replace('closed', 'active'),
      });
    }
  }

  private updateGtm() {
    const isOwn =
      this.currentUser &&
      (!this.$route.params.id || this.currentUser.id === this.user.id);

    this.setPageData({
      seller_id: this.user ? this.user.id : '',
      user_id: this.currentUser ? this.currentUser.id.toString() : '',
    });

    isOwn
      ? this.$gtm.trackEvent({
          event: 'content-view',
          'content-view-name': 'my_ads',
        })
      : this.$gtm.trackEvent({
          event: 'content-view',
          'content-view-name': 'all_user_ads',
        });
  }

  @Watch('currentUser') onUserUpdate(user: IUser) {
    if (!this.$route.params.id && user.id) {
      this.update();
    }
  }
}
