import Vue from 'vue';
import app from '@/app';
import router from '@/router';
import store from '@/store/store';
import 'core-js/stable';

import * as moment from 'moment';
import VueClipboard from 'vue-clipboard2';
import VueMeta from 'vue-meta';
import VueGtm from 'vue-gtm';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import VueYoutube from 'vue-youtube';

import gtm from '@/directives/gtm';

import 'moment/locale/ru.js';
moment.locale('ru');

if (PRODUCTION) {
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
}

Vue.config.productionTip = false;

Vue.use(VueClipboard);

Vue.use(VueDOMPurifyHTML, {
  namedConfigurations: {
    a: {
      ALLOWED_TAGS: ['a'],
      ALLOWED_ATTR: ['target', 'href']
    }
  }
});
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

Vue.use(VueGtm, {
  id: 'GTM-NVJM8SH',
  loadScript: false,
  enabled: false
});
Vue.directive('gtm', gtm);

Vue.use(VueYoutube);

document.getElementById('vm-app') &&
  new Vue({
    router,
    store,
    render: function(h) {
      return h(app);
    }
  }).$mount('#vm-app');
