
import { Vue, Component } from 'vue-property-decorator';

import { socialIcon } from '@/config/urls';

@Component({
  metaInfo() {
    return {
      meta: [
        {
          name: 'prerender-status-code',
          content: '404',
        },
        {
          name: 'og:image',
          content: socialIcon,
        },
      ],
    };
  },
})
export default class NotFound extends Vue {}
