var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-message" }, [
      _c("div", { staticClass: "vehicle-message__content" }, [
        _c(
          "div",
          { staticClass: "vehicle-container vehicle-container_width_xxllss" },
          [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-message__description vehicle-message__description_other vehicle-message__description_middle vehicle-message__description_extended-additional",
              },
              [
                _vm._v(
                  "Упс! У нас нет таких объявлений, попробуйте изменить условия поиска."
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-message__preview" }, [
              _c("div", {
                staticClass:
                  "vehicle-message__image vehicle-message__image_forest",
              }),
            ]),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }