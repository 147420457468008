var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-list" },
    [
      _c(
        "div",
        { staticClass: "vehicle-form" },
        [
          _c("tiles"),
          _vm._v(" "),
          _c("search-header"),
          _vm._v(" "),
          _c(
            "h1",
            {
              staticClass: "vehicle-form__title vehicle-form__title_big-alter",
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.header || "Автобарахолка") + "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__filter" }, [
            _c(
              "div",
              { staticClass: "vehicle-form__filter-flex" },
              [
                _c("div", {
                  staticClass:
                    "vehicle-form__filter-overlay helpers_show_tablet",
                  on: { click: _vm.hideFilters },
                }),
                _vm._v(" "),
                _c("search-filters-block", {
                  attrs: {
                    "page-name": "adverts",
                    "is-loaded": _vm.isLoaded,
                    "is-loading": _vm.isLoading,
                    "filters-config": _vm.filtersConfig,
                    "extended-filters": _vm.extendedFilters,
                    "set-filter": _vm.setFilter,
                    "state-counters": _vm.stateCounters,
                    "get-filter": _vm.getFilter,
                    "remove-filter": _vm.removeFilter,
                    "clear-filters": _vm.clearFilters,
                    filters: _vm.filters,
                    searches: _vm.searches,
                    "save-search": _vm.saveSearch,
                    total: _vm.total,
                    "update-list": _vm.updateList,
                    "update-counters": _vm.updateCounters,
                    "is-save-enabled": true,
                    "schema-properties": _vm.schemaProperties,
                  },
                }),
                _vm._v(" "),
                _c("search-adverts", {
                  attrs: {
                    "is-loaded": _vm.isLoaded,
                    "set-filter": _vm.setFilter,
                    "state-counters": _vm.stateCounters,
                    "get-filter": _vm.getFilter,
                    "remove-filter": _vm.removeFilter,
                    filters: _vm.filters,
                    "update-list": _vm.updateList,
                    "update-counters": _vm.updateCounters,
                    "reviews-rating": _vm.total < 1 ? null : _vm.reviewsRating,
                    manufacturer: _vm.brandObject,
                    model: _vm.modelObject,
                    generation: _vm.generationObject,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("commercial"),
        ],
        1
      ),
      _vm._v(" "),
      _vm.ld
        ? _c("json-ld", { attrs: { ld: _vm.ld, type: ["Product", "Car"] } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }