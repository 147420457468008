
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import * as Cookies from 'cookies-js';

  @Component
  export default class BannerWeb extends Vue {
    private banner!: any;

    @Prop() marginLeft!: number;

    mounted() {
      const windowObject = window as any;
      const p1 =
        this.isFeatureCookiesEnabled &&
        !(windowObject.cookieConsent && windowObject.cookieConsent.ads)
          ? 'ddavx'
          : 'ceniu';

      if (
        (window as any).Ya &&
        (window as any).Ya.adfoxCode &&
        (window as any).yaContextCb
      ) {
        (window as any).yaContextCb.push(() => {
          this.banner = (window as any).Ya.adfoxCode.createScroll(
            {
              ownerId: 239538,
              containerId: this.bannerId,
              onRender: () => {
                setTimeout(() => {
                  (window as any).Ya.adfoxCode.reload(this.bannerId, {
                    onlyIfWasVisible: true,
                  });
                }, 30000);
              },
              params: {
                p1,
                p2: 'fgou',
                puid26: 'ab',
              },
            },
            ['desktop'],
            {
              tabletWidth: 1000,
              phoneWidth: 640,
              isAutoReloads: false,
            },
          );
        });
      }
    }

    destroyed() {
      this.banner && this.banner.destroy();
    }

    public get bannerId() {
      return 'adfox_157261249136299698';
    }

    private get isFeatureCookiesEnabled() {
      return Cookies('feature_cookies');
    }
  }
