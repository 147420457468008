import { Prop, Vue, Watch } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import { Editor } from '@toast-ui/vue-editor';
import '@toast-ui/editor/dist/i18n/ru-ru';

import {
  CurrentUser,
  ErrorResponse,
  UrlService,
  ValidationErrors,
} from '@onlinerby/js-common';
import CommentsApi from '@/api/comments-api';
import { Comment, CommentsConfig } from '@/types/comments';
import MarkdownText from '@/components/comments/markdown-text.vue';
import relativeDate from '@/filters/comments/format-relative-date';
import stripHtml from '@/filters/strip-html';
import notificationsService from '@/services/notifications-service';
import { COMMENT_DELETED } from '@/dictionaries/errors';

@Mixin({
  components: {
    MarkdownText,
    Editor,
  },
  filters: {
    relativeDate,
  },
})
export default class CommentsFormMixin extends Vue {
  @Prop() projectName!: string;
  @Prop() currentUser!: CurrentUser;
  @Prop() commentsApi!: CommentsApi;
  @Prop() config!: CommentsConfig;
  @Prop() parentId?: string;
  @Prop() replyText!: string;

  private errors: ValidationErrors = {};
  protected isProcessing: boolean = false;
  protected text: string = '';

  protected created() {
    this.text = this.replyText;
  }

  protected get editorOptions() {
    return {
      placeholder: `Ваше мнение, ${this.currentUser.name}?`,
      hideModeSwitch: true,
      toolbarItems: ['bold', 'italic', 'quote'],
      language: 'ru-RU',
      minHeight: '102px',
    };
  }

  protected get rulesUrl() {
    return UrlService.getCommentRulesUrl(this.projectName);
  }

  private deleteTuiTooltip() {
    const tuiTooltip = document.querySelector('.tui-tooltip');

    tuiTooltip && tuiTooltip.remove();
  }

  private deleteTuiHeading() {
    const editor = this.$refs.toastuiEditor as Vue;
    const tuiHeading = editor.$el.querySelector('.te-heading-add');

    tuiHeading && tuiHeading.remove();
  }

  protected onLoad() {
    this.deleteTuiTooltip();
    this.deleteTuiHeading();
  }

  protected onSubmit() {
    const specialCharacterRegex = /(\\)([`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])/g;
    const editorText: string = stripHtml(
      (this.$refs.toastuiEditor as Editor).invoke('getMarkdown'),
    ).replace(specialCharacterRegex, '$2');

    const callbacks = {
      success: ({ status, data }: { status: number; data: Comment }) => {
        if (status === 202) {
          data.isPending = true;
        }

        this.setText('');
        this.$emit(data.parentId ? 'reply-created' : 'comment-created', data);
        this.onSubmitComplete();
      },
      error: ({
        response: {
          status,
          data: { errors },
        },
      }: {
        response: ErrorResponse;
      }) => {
        if (status === 422 && errors) {
          this.errors = errors as ValidationErrors;
        }

        if (status === 404) {
          notificationsService.error(COMMENT_DELETED);
        }
      },
      complete: () => {
        this.isProcessing = false;
      },
    };

    this.isProcessing = true;

    this.parentId
      ? this.commentsApi.postReply(this.parentId, editorText, callbacks)
      : this.commentsApi.postComment(editorText, callbacks);
  }

  protected onSubmitComplete() {}

  protected onChange(event: InputEvent) {
    this.$emit('input');

    Vue.delete(this.errors, 'text');
  }

  protected setText(text: string) {
    (this.$refs.toastuiEditor as Editor).invoke('setMarkdown', text);
  }

  @Watch('replyText')
  onReplyTextChange() {
    this.setText(this.replyText);
  }
}
