import { ActionTree, Payload } from 'vuex';
import { IRootState, ICommonState } from '@/interfaces/store';
import { Dictionaries, Dictionary } from '@/services/dictionaries-service';

const actions: ActionTree<ICommonState, IRootState> = {
  setDropdown(context, payload: Payload) {
    context.commit('setDropdown', payload);
  },

  removeDropdown(context, payload: Payload) {
    context.commit('removeDropdown', payload);
  },

  setDictionaries(context, { dictionaries }: { dictionaries: Dictionaries }) {
    context.commit('setDictionaries', dictionaries);
  },

  setDictionary(
    context,
    {
      rootName,
      name,
      dictionary,
    }: { rootName: string; name: string; dictionary: Dictionary }
  ) {
    context.commit('setDictionary', { rootName, name, dictionary });
  },

  setRootDictionary(
    context,
    {
      rootName,
      dictionary,
    }: { rootName: string; name: string; dictionary: Dictionary }
  ) {
    context.commit('setRootDictionary', { rootName, dictionary });
  },

  setIsFirstPage(context, { isFirstPage }) {
    context.commit('setIsFirstPage', isFirstPage);
  },
};

export default actions;
