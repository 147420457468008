
import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Review } from '@/types/review';
import { odometerTypes, powerTypes } from '@/config/dictionaries';
import { Dictionaries, DictionaryItem } from '@/services/dictionaries-service';
import { Specs } from '@/types/common';
import capitalize from '@/filters/format-capitalize';
import number from '@/filters/format-number';

const commonStore = namespace('common');

@Component({
  filters: {
    capitalize,
    number,
  },
})
export default class ReviewSpecs extends Vue {
  @Prop() review!: Review;

  @commonStore.Getter getDictionaryItem!: (
    type: string,
    key: string,
  ) => DictionaryItem;

  private get specs(): Specs {
    return this.review.specs;
  }

  private get odometerUnit(): string {
    const unit = odometerTypes[this.specs.odometer.unit];

    return unit;
  }

  private get engine(): string {
    const capacity = this.specs.engine.capacity;

    let type = capitalize(
      this.getDictionaryItem('engineType', this.specs.engine.type).name,
    );

    if (this.specs.engine.hybrid) {
      type += ' / Гибрид';
    }

    if (this.specs.engine.gas) {
      type += ' / Газобалонное оборудование';
    }

    return capacity ? `${capacity} л / ${type}` : type;
  }

  private get power(): string {
    const parts: Array<string> = [];
    const { power, torque } = this.specs.engine;

    if (power && power.value) {
      const unit = powerTypes[power.unit];

      parts.push(`${power.value} ${unit}`);
    }

    if (torque) {
      parts.push(`${torque} Нм`);
    }

    return parts.join(' / ');
  }

  private get drivetrain(): string {
    return this.getDictionaryItem('drivetrain', this.specs.drivetrain).name;
  }

  private get bodyType(): string {
    return this.getDictionaryItem('bodyType', this.specs.bodyType).name;
  }

  private get transmission(): string {
    return this.getDictionaryItem('transmission', this.specs.transmission).name;
  }
}
