import { parsePhoneNumber } from 'libphonenumber-js';

export default (phone: string) => {
  let formattedPhone;

  try {
    formattedPhone = parsePhoneNumber(
      phone[0] === '+' ? phone : `+${phone}`
    ).formatInternational();
  } catch (exception) {
    formattedPhone = phone;
  }

  return formattedPhone;
};
