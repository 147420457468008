var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "vehicle-form__intro" }, [
      _c("div", { staticClass: "vehicle-form__intro-flex" }, [
        _c(
          "div",
          {
            staticClass: "vehicle-form__intro-part vehicle-form__intro-part_1",
          },
          [
            _c("div", { staticClass: "vehicle-form__intro-list" }, [
              !_vm.manufacturerId
                ? _c("div", { staticClass: "vehicle-form__intro-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_extended-other",
                      },
                      [_vm._v("\n              Выберите марку\n            ")]
                    ),
                  ])
                : _c("div", { staticClass: "vehicle-form__intro-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_extended-other",
                      },
                      [
                        _vm.isEditable
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "vehicle-form__link vehicle-form__link_primary vehicle-form__link_middle",
                                on: { click: _vm.onResetManufacturerClick },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.manufacturerName) +
                                    "\n              "
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_extended-other",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.manufacturerName) +
                                    "\n              "
                                ),
                              ]
                            ),
                      ]
                    ),
                  ]),
              _vm._v(" "),
              _vm.manufacturerId
                ? _c("div", { staticClass: "vehicle-form__intro-item" }, [
                    !_vm.modelId
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_extended-other",
                          },
                          [
                            _vm._v(
                              "\n              Выберите модель\n            "
                            ),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_extended-other",
                          },
                          [
                            _vm.isEditable
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "vehicle-form__link vehicle-form__link_primary vehicle-form__link_middle",
                                    on: { click: _vm.onResetModelClick },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.modelName) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_extended-other",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.modelName) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                          ]
                        ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.manufacturerId && _vm.modelId && !_vm.isComplete
                ? _c("div", { staticClass: "vehicle-form__intro-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_extended-other",
                      },
                      [
                        _vm._v(
                          "\n              Выберите технические характеристики\n            "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.isComplete && !_vm.isEditMode
          ? _c(
              "div",
              {
                staticClass:
                  "vehicle-form__intro-part vehicle-form__intro-part_2",
              },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "vehicle-form__link vehicle-form__link_primary vehicle-form__link_tiny vehicle-form__link_font-weight_normal vehicle-form__link_ellipsis",
                    on: {
                      click: function ($event) {
                        return _vm.editStep("tech")
                      },
                    },
                  },
                  [_vm._v("\n          Изменить\n        ")]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _vm.isComplete
      ? _c(
          "div",
          {
            staticClass:
              "vehicle-form__step-helper vehicle-form__step-helper_specific helpers_hide_tablet",
          },
          [
            _c("div", { staticClass: "vehicle-form__parameter" }, [
              _c("div", { staticClass: "vehicle-form__parameter-list" }, [
                _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                  _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.year) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.generationName
                  ? _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                      _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-flex" },
                        [
                          _vm._m(1),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.generationName) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                  _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm._f("capitalize")(_vm.bodyType)) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                  _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                    _vm._m(3),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm._f("capitalize")(_vm.engineType)) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.engineCapacity
                  ? _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                      _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-flex" },
                        [
                          _vm._m(4),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.engineCapacity) +
                                      " л\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                  _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                    _vm._m(5),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm._f("capitalize")(_vm.drivetrain)) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                  _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                    _vm._m(6),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm._f("capitalize")(_vm.transmission)) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]
        )
      : _c("div"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1 vehicle-form__parameter-part_width_s",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n                Год выпуска\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1 vehicle-form__parameter-part_width_s",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n                Поколение\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1 vehicle-form__parameter-part_width_s",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n                Тип кузова\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1 vehicle-form__parameter-part_width_s",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n                Тип двигателя\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1 vehicle-form__parameter-part_width_s",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n                Объем двигателя\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1 vehicle-form__parameter-part_width_s",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n                Привод\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1 vehicle-form__parameter-part_width_s",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n                Коробка передач\n              ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }