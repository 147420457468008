
import { Component, Mixins } from 'vue-mixin-decorator';

import MultiselectFilterMixin from '@/mixins/filters/multiselect-filter';
import capitalize from '@/filters/format-capitalize';

@Component({
  filters: {
    capitalize,
  },
})
export default class ColorFilter extends Mixins<MultiselectFilterMixin>(
  MultiselectFilterMixin
) {}
