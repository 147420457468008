
import { Mixins, Component } from 'vue-mixin-decorator';
import { findWhere } from 'underscore';

import { DictionaryItem } from '@/services/dictionaries-service';
import StepTechManufacturerMixin from '@/mixins/reviews/create/step-tech-manufacturer';

@Component
export default class StepTechManufacturer extends Mixins<
  StepTechManufacturerMixin
>(StepTechManufacturerMixin) {
  private isAllVisible: boolean = false;


  private get manufacturers() {
    if (this.filterString && this.dictionaries.manufacturer) {
      const regex = new RegExp(this.filterString);

      return this.dictionaries.manufacturer.filter((item: DictionaryItem) => {
        return regex.test(item.name.toLowerCase());
      });
    }

    if (this.isAllVisible) {
      return this.dictionaries.manufacturer;
    }

    return this.dictionaries.manufacturer.filter(item =>
      findWhere(this.dictionaries.topManufacturer, { id: item.id })
    );
  }


  private onToggleClick() {
    this.isAllVisible = !this.isAllVisible;
  }
}
