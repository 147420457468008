
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import { Dictionaries } from '@/services/dictionaries-service';
import FieldMixin from '@/mixins/create/field';
import IsMobileMixin from '@/mixins/common/is-mobile';

const commonStore = namespace('common');

interface IMixins extends FieldMixin, IsMobileMixin {}

@Component
export default class StepCostState extends Mixins<IMixins>(
  FieldMixin,
  IsMobileMixin
) {
  @commonStore.State dictionaries!: Dictionaries;

  private get specs() {
    return this.data.specs || {};
  }

  private get state() {
    return this.specs.state;
  }

  private setState(value: string) {
    this.clearError({ name: 'specs.odometer.value' });
    this.clearError({ name: 'specs.state' });
    this.clearError({ name: 'specs.vin' });

    if (value !== 'new') {
      this.setField({
        specs: {
          ...this.specs,
          state: value,
          odometer: {
            unit: 'km',
          },
        },
      });
    } else {
      const { odometer, ...specs } = this.specs;

      this.setField({ specs: { ...specs, state: value } });
    }
  }

  private get warranty() {
    return this.specs.warranty;
  }

  private set warranty(value) {
    this.setField({
      specs: { ...this.specs, warranty: value },
    });
  }

  private get error() {
    return (this.errors['specs.state'] || []).join('. ');
  }
}
