var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "vehicle-form__offers" }, [
      _c(
        "div",
        { staticClass: "vehicle-form__offers-list" },
        [
          _vm.entitiesList.length
            ? _vm._l(_vm.entitiesList, function (entity) {
                return _c("promote-adverts-list-item", {
                  key: entity.id,
                  attrs: { advert: entity.data, entity: entity },
                })
              })
            : _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_extended-complementary",
                },
                [_vm._v("Ничего не найдено")]
              ),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _vm.remains > 0
      ? _c(
          "div",
          { staticClass: "vehicle-pagination vehicle-pagination_visible" },
          [
            _c(
              "a",
              {
                staticClass: "vehicle-pagination__main",
                on: { click: _vm.addPage },
              },
              [
                _vm._v(
                  "Еще " +
                    _vm._s(
                      _vm._f("plural")(_vm.remains, [
                        "объявление",
                        "объявления",
                        "объявлений",
                      ])
                    )
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }