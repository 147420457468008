
  import { Vue, Prop, Component } from 'vue-property-decorator';
  import { HashMap } from '@/types/common';

  @Component
  export default class JsonLd extends Vue {
    @Prop() ld!: HashMap<any>;
    @Prop({
      default: 'Product',
    })
    type!: string | string[];

    private get jsonld() {
      return {
        '@type': this.type,
        '@context': 'https://schema.org',
        ...this.ld,
      };
    }
  }
