
  import { Component, Mixins } from 'vue-mixin-decorator';
  import { findWhere } from 'underscore';

  import FilterMixin from '@/mixins/filters/filter';
  import Dropdown from '@/mixins/common/dropdown';
  import capitalize from '@/filters/format-capitalize';
  import { ISortType } from '@/interfaces/search';
  import { Prop } from 'vue-property-decorator';

  interface IMixinInterface extends Dropdown, FilterMixin {}

  @Component({
    filters: {
      capitalize,
    },
  })
  export default class Order extends Mixins<IMixinInterface>(
    Dropdown,
    FilterMixin,
  ) {
    @Prop() sortTypes!: Array<ISortType>;

    private defaultSortType: ISortType = this.sortTypes[0];

    private get filterValue() {
      return this.getFilter(this.name).value || this.defaultSortType.name;
    }

    private get selectedFilterLabel() {
      const sortType: ISortType =
        findWhere(this.sortTypes, { name: this.filterValue as string }) ||
        this.defaultSortType;

      return sortType.title;
    }

    public setValue(value: string) {
      const filterValue = value !== this.defaultSortType.name ? value : '';

      this.closeDropdown();

      this._updateFilter(filterValue, this.isMobileSpecial);
    }
  }
