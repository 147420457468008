
import { Bar, mixins } from 'vue-chartjs-typescript';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
const { reactiveProp } = mixins;

@Component({
  extends: Bar,
  mixins: [reactiveProp],
})
export default class BarChart extends Vue {
  public renderChart!: (chartData: any, options: any) => void;

  @Prop() chartData: any;
  @Prop({
    default: function() {
      return {};
    },
  })
  options!: object;

  mounted() {
    this.renderChart(this.chartData, this.options);
  }
}
