
  import { Mixins, Component } from 'vue-mixin-decorator';

  import FieldMixin from '@/mixins/reviews/create/field';
  import { Prop } from 'vue-property-decorator';

  @Component
  export default class StepTechTransmission extends Mixins<FieldMixin>(
    FieldMixin,
  ) {
    @Prop() transmissions!: Array<any>;

    private get transmission() {
      return this.data.specs.transmission;
    }

    private onSetTransmissionClick(transmission: string) {
      this.onFieldChange(transmission);
    }
  }
