import { ActionTree } from 'vuex';

import { IPromoteState, IRootState } from '@/interfaces/store';
import vmApi from '@/api/vm-api';
import {
  paygateApi,
  subscriptionsApi,
  Subscriptions,
  PaymentMethods,
} from '@onlinerby/js-api-clients';
import { ICounters } from '@/interfaces/search';

const actions: ActionTree<IPromoteState, IRootState> = {
  updateFeatures(context, { success, complete } = {}) {
    subscriptionsApi.getFeatures({
      success({ data }: any) {
        context.commit('setFeatures', data.features);
        context.commit('setEntities', data.entities);
        context.commit('setPeriods', data.periods);

        success && success(data);
      },
      complete,
    });
  },

  updatePayments(context) {
    paygateApi.payments(
      {
        purpose: 'subscriptions.order',
      },
      {
        success: (paymentMethods: PaymentMethods) => {
          context.commit('setPayments', paymentMethods);
          context.commit(
            'setSelectedPayment',
            paymentMethods.getDefaultMethod(),
          );
        },
      },
    );
  },

  updateGeneralCounters(context) {
    vmApi.getGeneralCounters({
      success: ({ data }: { data: ICounters }) => {
        context.commit('setGeneralCounters', data);
      },
    });
  },

  setLoading(context, { isLoading }) {
    context.commit('setLoading', isLoading);
  },

  setSelectedFeature(context, { feature }) {
    context.commit('setSelectedFeature', feature);
  },

  setRecurrent(context, { isRecurrent }) {
    context.commit('setRecurrent', isRecurrent);
  },

  setPaymentProcessing(context, { isProcessing }) {
    context.commit('setPaymentProcessing', isProcessing);
  },

  setSelectedPayment(context, { method }) {
    context.commit('setSelectedPayment', method);
  },

  setManufacturerId(context, { id }) {
    context.commit('setManufacturerId', id);
  },

  setModelId(context, { id }) {
    context.commit('setModelId', id);
  },

  setShowNonPremiumOnly(context, { showNonPremiumOnly }) {
    context.commit('setShowNonPremiumOnly', showNonPremiumOnly);
  },

  createSubscription(
    context,
    { feature, recurrent, success, error, complete },
  ) {
    subscriptionsApi.createSubscription(
      {
        features: [feature],
        periodId: context.state.periods[0].id,
        recurrent,
      },
      {
        success: (data: Subscriptions) => {
          success && success(data);
        },
        error,
        complete,
      },
    );
  },
};

export default actions;
