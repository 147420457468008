import SearchPage from '@/views/search.vue';
import AdvertPage from '@/views/advert.vue';
import OwnAdvertsHeader from '@/components/adverts/own-adverts-header.vue';
import AdvertsHeader from '@/components/adverts/adverts-header.vue';
import AdvertsList from '@/components/adverts/adverts-list.vue';
import CreatePage from '@/views/create.vue';
import EditPage from '@/views/edit.vue';
import UserPage from '@/views/user.vue';
import NotFound from '@/views/not-found.vue';
import PromotePage from '@/views/promote.vue';
import Adverts from '@/components/adverts/adverts.vue';
import UserReviews from '@/views/reviews/user-reviews.vue';
import StepTech from '@/components/create/step-tech/step-tech.vue';
import StepExterior from '@/components/create/step-exterior/step-exterior.vue';
import StepCost from '@/components/create/step-cost/step-cost.vue';
import StepContacts from '@/components/create/step-contacts/step-contacts.vue';
import StepEdit from '@/components/create/step-edit/step-edit.vue';
// Reviews
import ReviewPage from '@/views/reviews/review.vue';
import CreateReviewPage from '@/views/reviews/create.vue';
import StepReviewTech from '@/components/reviews/create/step-tech/step-tech.vue';
import StepReviewDescription from '@/components/reviews/create/step-description/step-description.vue';
import EditReviewPage from '@/views/reviews/edit.vue';
import StepEditReview from '@/components/reviews/create/step-edit/step-edit.vue';
import Reviews from '@/views/reviews/reviews.vue';

export default [
  {
    path: '/images/:id',
    name: 'deprecated',
    component: NotFound,
  },
  {
    path: '',
    name: 'search',
    component: SearchPage,
    alias: [
      '/used-cars/country/:country/:manufacturer/:model',
      '/new-cars/country/:country/:manufacturer/:model',
      '/used-cars/country/:country/:manufacturer',
      '/new-cars/country/:country/:manufacturer',
      '/used-cars/country/:country',
      '/new-cars/country/:country',
      '/used-cars/:manufacturer/:model',
      '/new-cars/:manufacturer/:model',
      '/used-cars/:manufacturer',
      '/new-cars/:manufacturer',
      '/new-cars/dealers/city/:city',
      '/new-cars/dealers',
      '/used-cars',
      '/new-cars',
      '/damaged-cars',
      '/country/:country/:manufacturer/:model',
      '/country/:country/:manufacturer',
      '/country/:country',
    ],
    meta: {
      customScroll: true,
    },
  },
  {
    path: '/car/:id',
    name: 'advert-old',
    meta: {
      canonical: false,
    },
    component: AdvertPage,
  },
  {
    path: '/user/:id',
    name: 'user',
    component: UserPage,
    meta: {
      navigation: 'user-auto',
    },
    children: [
      {
        path: 'vehicles',
        alias: ['adverts'],
        name: 'adverts',
        component: Adverts,
        children: [
          {
            path: 'active',
            name: 'active-adverts',
            components: {
              header: AdvertsHeader,
              list: AdvertsList,
            },
            meta: {
              status: 'active',
            },
          },
          {
            path: 'closed',
            components: {
              header: AdvertsHeader,
              list: AdvertsList,
            },
            name: 'closed-adverts',
            meta: {
              status: 'closed',
            },
          },
        ],
      },
      {
        path: 'reviews',
        name: 'user-reviews',
        component: UserReviews,
      },
    ],
  },
  {
    path: '/create',
    name: 'create',
    component: CreatePage,
    meta: {
      requiresAuth: true,
      customScroll: true,
      hideBanner: true,
    },
    children: [
      {
        path: 'tech',
        name: 'create-tech',
        alias: '/',
        component: StepTech,
        meta: {
          stepName: 'tech',
        },
      },
      {
        path: 'exterior',
        name: 'create-exterior',
        component: StepExterior,
        meta: {
          stepName: 'exterior',
        },
      },
      {
        path: 'cost',
        name: 'create-cost',
        component: StepCost,
        meta: {
          stepName: 'cost',
        },
      },
      {
        path: 'contacts',
        name: 'create-contacts',
        component: StepContacts,
        meta: {
          stepName: 'contacts',
        },
      },
    ],
  },
  {
    path: '/car/:id/edit',
    name: 'edit',
    component: EditPage,
    meta: {
      requiresAuth: true,
      customScroll: true,
      hideBanner: true,
    },
    children: [
      {
        path: '/',
        name: 'edit-init',
        component: StepEdit,
        meta: {
          stepName: 'edit',
        },
      },
      {
        path: 'tech',
        name: 'edit-tech',
        component: StepTech,
        meta: {
          stepName: 'tech',
        },
      },
      {
        path: 'exterior',
        name: 'edit-exterior',
        component: StepExterior,
        meta: {
          stepName: 'exterior',
        },
      },
      {
        path: 'cost',
        name: 'edit-cost',
        component: StepCost,
        meta: {
          stepName: 'cost',
        },
      },
      {
        path: 'contacts',
        name: 'edit-contacts',
        component: StepContacts,
        meta: {
          stepName: 'contacts',
        },
      },
    ],
  },
  // Reviews
  {
    path: '/reviews/:manufacturer/:model/:id(\\d+)',
    name: 'review',
    component: ReviewPage,
    meta: {
      navigation: 'reviews',
    },
  },
  {
    path: '/reviews/create',
    name: 'create-review',
    component: CreateReviewPage,
    meta: {
      requiresAuth: true,
      customScroll: true,
      navigation: 'reviews',
      hideBanner: true,
    },
    children: [
      {
        path: 'tech',
        name: 'create-review-tech',
        alias: '/',
        component: StepReviewTech,
        meta: {
          stepName: 'tech',
        },
      },
      {
        path: 'description',
        name: 'create-review-description',
        component: StepReviewDescription,
        meta: {
          stepName: 'description',
        },
      },
    ],
  },
  {
    path: '/reviews/:id/edit',
    name: 'edit-review',
    component: EditReviewPage,
    meta: {
      requiresAuth: true,
      customScroll: true,
      navigation: 'reviews',
      hideBanner: true,
    },
    children: [
      {
        path: '/',
        name: 'edit-review-init',
        component: StepEditReview,
        meta: {
          stepName: 'edit',
        },
      },
      {
        path: 'tech',
        name: 'edit-review-tech',
        alias: '/',
        component: StepReviewTech,
        meta: {
          stepName: 'tech',
        },
      },
      {
        path: 'description',
        name: 'edit-review-description',
        component: StepReviewDescription,
        meta: {
          stepName: 'description',
        },
      },
    ],
  },
  {
    path: '/reviews',
    name: 'reviews',
    meta: {
      customScroll: true,
      navigation: 'reviews',
    },
    component: Reviews,
  },
  {
    path: '/reviews/:manufacturer/:model?/:generation?',
    name: 'reviews-generation',
    component: Reviews,
    meta: {
      customScroll: true,
      navigation: 'reviews',
    },
  },
  {
    path: '/:manufacturer/:model/:id(\\d+)',
    name: 'advert',
    component: AdvertPage,
  },
  {
    path: '/promote',
    name: 'promote',
    component: PromotePage,
    meta: {
      hideBanner: true,
    },
  },
  {
    path: '/:manufacturer/:model/:generation/city/:city',
    name: 'search',
    component: SearchPage,
    meta: {
      customScroll: true,
    },
  },
  {
    path: '/:manufacturer/:model/city/:city',
    name: 'search',
    component: SearchPage,
    meta: {
      customScroll: true,
    },
  },
  {
    path: '/:manufacturer?/:model?/:generation?',
    name: 'search-generation',
    component: SearchPage,
    meta: {
      customScroll: true,
    },
  },
  {
    path: '',
    name: 'search',
    component: SearchPage,
    meta: {
      customScroll: true,
    },
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFound,
  },
];
