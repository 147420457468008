
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { findWhere } from 'underscore';

import urlService from '@/services/url-service';
import { IAdvert } from '@/interfaces/adverts';
import { Dictionaries } from '@/services/dictionaries-service';

const advertStore = namespace('advert');
const commonStore = namespace('common');

@Component
export default class AdvertBreadcrumbs extends Vue {
  @advertStore.State advert!: IAdvert;

  @commonStore.State dictionaries!: Dictionaries;

  private get allCarsUrl() {
    return '/';
  }

  private get manufacturer() {
    return this.advert.manufacturer;
  }

  private get model() {
    return this.advert.model;
  }

  private get generation() {
    return this.advert.generation || {};
  }

  private get manufacturerUrl() {
    return `/${this.manufacturer.slug}`;
  }

  private get modelUrl() {
    return `/${this.manufacturer.slug}/${this.model.slug}`;
  }

  private get generationUrl() {
    return `/${this.manufacturer.slug}/${this.model.slug}/${this.generation.slug}`;
  }
}
