var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__slider-item swiper-slide" }, [
    _c("div", { staticClass: "vehicle-form__preview" }, [
      _c(
        "a",
        {
          staticClass: "vehicle-form__image",
          attrs: { target: "__blank", href: _vm.tile.url },
          on: { click: _vm.onTileClick },
        },
        [
          _c("picture", [
            _c("img", {
              attrs: { loading: "lazy", src: _vm.tile.images["228x124"] },
            }),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "vehicle-form__slider-bottom" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__description vehicle-form__description_warning-other vehicle-form__description_small vehicle-form__description_condensed vehicle-form__description_ellipsis",
        },
        [_vm._v("\n      " + _vm._s(_vm.tile.topic) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__description vehicle-form__description_default vehicle-form__description_base vehicle-form__description_condensed",
        },
        [_vm._v("\n      " + _vm._s(_vm.tile.title) + "\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }