var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "popup",
    {
      ref: "leasingSuccessPopup",
      attrs: {
        classes: [
          "popup-style_primary",
          " popup-style_middle",
          "vehicle-popup",
          "vehicle-popup_width_xxls",
        ],
      },
    },
    [
      _c("div", { staticClass: "vehicle-form" }, [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__mediabox vehicle-form__mediabox_success",
          },
          [
            _c("div", { staticClass: "vehicle-form__mediabox-flex" }, [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__mediabox-part vehicle-form__mediabox-part_content",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed-another",
                    },
                    [
                      _vm._v("\n            Спасибо!\n            "),
                      _c("br", { staticClass: "helpers_show_tablet" }),
                      _vm._v(
                        "Ваша заявка отправлена лизинговой компании\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base",
                    },
                    [
                      _vm._v(
                        "Через некоторое время с вами свяжется менеджер для уточнения деталей оформления лизинга."
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__mediabox-part vehicle-form__mediabox-part_image",
                },
                [
                  _c("div", { staticClass: "vehicle-form__preview" }, [
                    _c("div", {
                      staticClass:
                        "vehicle-form__image vehicle-form__image_success",
                    }),
                  ]),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }