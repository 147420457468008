
  import { Mixins, Component } from 'vue-mixin-decorator';

  import FieldMixin from '@/mixins/create/field';
  import StorageMixin from '@/mixins/create/storage';
  import { InputEvent } from '@/types/events';

  interface IMixins extends FieldMixin, StorageMixin {}

  @Component
  export default class StepContactsSellerPhones extends Mixins<IMixins>(
    FieldMixin,
    StorageMixin,
  ) {
    public created() {
      const phones = this.getStorageValue('phones');

      if (phones) {
        this.setField({
          seller: { ...this.seller, phones },
        });
      }
    }

    private get seller() {
      return this.data.seller || {};
    }

    private get phones() {
      return this.seller.phones || [''];
    }

    private addPhone() {
      const phones = this.data.seller.phones
        ? [...this.data.seller.phones]
        : [];

      this.clearError('seller.phones');
      this.setField({
        seller: { ...this.seller, phones: phones.concat(['']) },
      });
    }

    private onPhoneChange(index: number, event: InputEvent) {
      const phones = this.data.seller.phones
        ? [...this.data.seller.phones]
        : [];
      const value = event.target.value;

      phones[index] = value.replace(/[^\d\+]/g, '');

      this.clearError({ name: `seller.phones.${index}` });
      this.clearError({ name: 'seller.phones' });
      this.setField({
        seller: { ...this.seller, phones },
      });
    }

    private removePhone(index: number) {
      const phones = this.data.seller.phones
        ? [...this.data.seller.phones]
        : [];

      phones.splice(index, 1);

      this.setField({
        seller: { ...this.seller, phones },
      });
      this.clearError({ name: `seller.phones.${index}` });
    }

    private get error() {
      return (this.errors['seller.phones'] || []).join('. ');
    }
  }
