import {Prop} from 'vue-property-decorator';
import { Mixins, Mixin } from 'vue-mixin-decorator';

import { DictionaryItem } from '@/services/dictionaries-service';
import FieldMixin from '@/mixins/reviews/create/field';

@Mixin
export default class StepTechModel extends Mixins<FieldMixin>(FieldMixin) {
  protected search: string = '';

  @Prop() modelsByManufacturerId!: Array<any>;


  protected get filterString() {
    return this.search
      .trim()
      .toLowerCase()
      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  protected get models() {
    if (!this.data.manufacturerId) {
      return [];
    }

    const dictionary = this.modelsByManufacturerId;

    if (this.search && dictionary) {
      const regex = new RegExp(this.filterString);

      return dictionary.filter((item: DictionaryItem) => {
        return regex.test(item.name.toLowerCase());
      });
    }

    return dictionary || [];
  }

  protected get model() {
    return this.data.modelId;
  }

  protected set model(value) {
    this.onFieldChange(value);
  }
}
