
import { Mixins, Component } from 'vue-mixin-decorator';
import { without, isEmpty, findWhere } from 'underscore';

import StepExteriorEquipmentDropdownMixin from '@/mixins/create/step-exterior-equipment-dropdown';
import { DictionaryItem } from '@/services/dictionaries-service';
import { ChangeEvent } from '@/types/events';
import { HashMap } from '@/types/common';
import DeviceClass from '@/directives/device-class';

@Component({
  directives: {
    DeviceClass,
  },
})
export default class StepExteriorEquipmentDropdown extends Mixins<
  StepExteriorEquipmentDropdownMixin
>(StepExteriorEquipmentDropdownMixin) {
  private get selectedLabel() {
    if (!this.field) {
      return;
    }

    if (this.item.multiple) {
      return this.field
        .map((id: string) => {
          return findWhere(this.options, { id })!.name;
        })
        .join(',');
    }

    return findWhere(this.options, { id: this.field })!.name;
  }

  private onChange(option: DictionaryItem, event: ChangeEvent) {
    if (event.target.checked) {
      const value = option.id;
      const equipment = { ...this.equipment, [this.property]: value };

      this.setField({ equipment });
    } else {
      this.clear();
    }

    this.close();
  }
}
