
import { Vue, Component } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { findWhere } from 'underscore';
import * as moment from 'moment';

import { HashMap } from '@/types/common';
import { VuexAction } from '@/types/functions';
import { DictionaryItem, Dictionaries } from '@/services/dictionaries-service';
import capitalize from '@/filters/format-capitalize';
import StepHeader from '@/mixins/create/step-header';

const createStore = namespace('create');
const commonStore = namespace('common');

@Component({
  filters: {
    capitalize,
  },
})
export default class StepContactsHeader extends Mixins<StepHeader>(StepHeader) {
  protected step: string = 'contacts';

  @commonStore.State dictionaries!: Dictionaries;
  @commonStore.Getter getDictionaryItem!: (
    type: string,
    key: string
  ) => DictionaryItem;
  @createStore.State data!: HashMap<any>;

  private get seller() {
    return this.data.seller || {};
  }

  private get countryName() {
    return this.getDictionaryItem('country', this.data.country).name;
  }

  private get regionName() {
    return this.getDictionaryItem('region', this.data.region).name;
  }

  private get cityName() {
    return this.getDictionaryItem('city', this.data.cityId).name;
  }

  private get sellerType() {
    return this.getDictionaryItem('sellerType', this.data.seller.type).name;
  }

  private get from() {
    return this.getTime(this.data.seller.call.from);
  }

  private get to() {
    return this.getTime(this.data.seller.call.to);
  }

  private getTime(value: string) {
    return value ? moment(value, 'HH:mmZ').format('H') : '0';
  }
}
