var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { class: _vm.itemClass || "vehicle-form__reviews-item" }, [
    _c(
      "div",
      {
        staticClass: "vehicle-form__reviews-box",
        class: {
          "vehicle-form__reviews-box_success": _vm.type === "positive",
          "vehicle-form__reviews-box_error": _vm.type === "negative",
          "vehicle-form__reviews-box_primary": !_vm.type,
        },
      },
      [
        _c("div", { staticClass: "vehicle-form__reviews-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__reviews-part vehicle-form__reviews-part_image",
            },
            [
              _c("div", { staticClass: "vehicle-form__preview" }, [
                _c("div", {
                  staticClass: "vehicle-form__image",
                  style: _vm._f("background")(
                    _vm.getImageUrl(_vm.review.images[0])
                  ),
                }),
              ]),
              _vm._v(" "),
              _vm.type
                ? _c(
                    "div",
                    {
                      staticClass:
                        "button-style button-style_small button-style_noreflex vehicle-form__button vehicle-form__button_foto",
                      class: {
                        "button-style_primary": _vm.type === "positive",
                        "button-style_alter": _vm.type === "negative",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.type === "positive"
                              ? "Самый позитивный"
                              : "Самый негативный"
                          ) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__reviews-part vehicle-form__reviews-part_data",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_tiny vehicle-form__description_other vehicle-form__description_condensed-appendant",
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.carInformation) + "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary vehicle-form__description_font-weight_bold vehicle-form__description_condensed-other",
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.review.summary) + "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_condensed-complementary",
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.review.text) + "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "popover-style__handle vehicle-form__popover-handle vehicle-form__popover-handle_reviews",
                  class: {
                    "popover-style__handle_interactive":
                      _vm.isMoreInfoShow && !_vm.isMobileSpecial,
                    "popover-style__handle_opened": _vm.isPopoverOpened,
                  },
                },
                [
                  _vm.isTutorialVisible && !_vm.isTutorialWatched
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "popover-style popover-style_primary popover-style_base popover-style_top-right vehicle-form__popover vehicle-form__popover_width_ssms helpers_show_tablet",
                        },
                        [
                          _c("div", { staticClass: "popover-style__content" }, [
                            _c("div", { staticClass: "vehicle-form" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary",
                                },
                                [
                                  _vm._v(
                                    "\n                  Нажмите сюда, чтобы увидеть дополнительную информацию\n                  о рейтинге авто\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__control vehicle-form__control_condensed",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "button-style button-style_primary button-style_small vehicle-form__button vehicle-form__button_width_full",
                                      on: { click: _vm.onSubmitTutorialClick },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Понятно\n                  "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : [
                        _c(
                          "div",
                          {
                            staticClass:
                              "popover-style popover-style_primary popover-style_base popover-style_bottom-right popover-style_noarrow popover-style_specific-alter popover-style_specific-additional vehicle-form__popover vehicle-form__popover_width_sssm",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "popover-style__container" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "popover-style__content" },
                                  [
                                    _c("div", { staticClass: "vehicle-form" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__rating vehicle-form__rating_reviews",
                                        },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "rating rating_middle rating_single vehicle-form__rating-stars",
                                            class: `rating_${_vm.avgStars}`,
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vehicle-form__rating-text",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__title vehicle-form__title_base",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        _vm.review.ratingAverage
                                                          .toFixed(1)
                                                          .replace(".", ",")
                                                      ) +
                                                      "\n                        "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary vehicle-form__description_font-weight_bold vehicle-form__description_condensed helpers_show_tablet",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      “" +
                                              _vm._s(_vm.review.summary) +
                                              "”\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__description vehicle-form__description_tiny vehicle-form__description_other vehicle-form__description_condensed-appendant helpers_show_tablet",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(_vm.carInformation) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass:
                                          "vehicle-form__divider vehicle-form__divider_condensed-additional helpers_show_tablet",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__description vehicle-form__description_base-alter vehicle-form__description_primary vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-complementary",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Оценки\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__parameter vehicle-form__parameter_specific",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vehicle-form__parameter-list",
                                            },
                                            _vm._l(_vm.marks, function (mark) {
                                              return _c("rating-mark", {
                                                key: mark.key,
                                                attrs: {
                                                  "dictionary-key":
                                                    mark.dictionaryKey,
                                                  value: mark.value,
                                                  "is-label-primary": false,
                                                  "is-value-primary": true,
                                                },
                                              })
                                            }),
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass:
                                          "vehicle-form__divider vehicle-form__divider_condensed-additional",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__description vehicle-form__description_base-alter vehicle-form__description_primary vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-complementary",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Плюсы и минусы\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "vehicle-form__proscons",
                                        },
                                        [
                                          _vm.formattedPros
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__description vehicle-form__description_small vehicle-form__description_other vehicle-form__description_plus vehicle-form__description_condensed-other",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.formattedPros
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.formattedCons
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__description vehicle-form__description_small vehicle-form__description_other vehicle-form__description_minus vehicle-form__description_condensed-other",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.formattedCons
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "popover-style__bottom helpers_show_tablet",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "button-style button-style_additional button-style_small popover-style__button",
                                    on: { click: _vm.onMoreInfoCarCloseClick },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Закрыть\n                "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass:
                            "popover-style__overlay helpers_show_tablet",
                          on: { click: _vm.onOverlayClick },
                        }),
                      ],
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__rating vehicle-form__rating_reviews",
                      on: { click: _vm.onMoreInfoCarClick },
                    },
                    [
                      _c("div", {
                        staticClass:
                          "rating rating_middle rating_single vehicle-form__rating-stars",
                        class: `rating_${_vm.avgStars}`,
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "vehicle-form__rating-text" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__title vehicle-form__title_base",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.review.ratingAverage
                                    .toFixed(1)
                                    .replace(".", ",")
                                ) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "vehicle-form__footnote" }, [
                _c("div", { staticClass: "vehicle-form__person" }, [
                  _c("div", { staticClass: "vehicle-form__person-list" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__person-item vehicle-form__person-item_condensed",
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "vehicle-form__preview",
                            attrs: {
                              to: {
                                name: "user-reviews",
                                params: { id: _vm.review.author.id },
                              },
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "vehicle-form__image",
                              style: _vm._f("background")(
                                _vm.review.author.avatar
                              ),
                            }),
                            _vm._v(" "),
                            _vm.review.author.onlineStatus &&
                            _vm.review.author.onlineStatus.isOnline
                              ? _c("div", {
                                  staticClass:
                                    "vehicle-form__status vehicle-form__status_online",
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny vehicle-form__description_ellipsis",
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "vehicle-form__link vehicle-form__link_primary vehicle-form__link_tiny vehicle-form__link_ellipsis",
                                attrs: {
                                  to: {
                                    name: "user-reviews",
                                    params: { id: _vm.review.author.id },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.review.author.name) +
                                    ",\n                  "
                                ),
                              ]
                            ),
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm._f("relativeDate")(
                                    _vm.review.statusChangedAt
                                  )
                                ) +
                                "\n                "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "vehicle-form__details" }, [
                  _c("div", { staticClass: "vehicle-form__details-list" }, [
                    _c("div", { staticClass: "vehicle-form__details-item" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_tiny vehicle-form__description_comments",
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.review.stats.comments.total) +
                              "\n                "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "vehicle-form__details-item" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_tiny vehicle-form__description_likes",
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.review.stats.marks.like) +
                              "\n                "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "vehicle-form__details-item" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_tiny vehicle-form__description_dislikes",
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.review.stats.marks.dislike) +
                              "\n                "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("router-link", {
          staticClass: "vehicle-form__offers-stub",
          attrs: {
            to: {
              name: "review",
              params: {
                manufacturer: _vm.review.manufacturer.slug,
                model: _vm.review.model.slug,
                id: _vm.review.id,
              },
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }