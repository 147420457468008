
  import { Component } from 'vue-property-decorator';
  import { Mixins } from 'vue-mixin-decorator';
  import { Editor } from '@toast-ui/vue-editor';
  import EditorInst from '@toast-ui/editor';
  import '@toast-ui/editor/dist/i18n/ru-ru';

  import FieldMixin from '@/mixins/reviews/create/field';
  import { maxLengthTextReview } from '@/config/reviews/create';
  import stripHtml from '@/filters/strip-html';

  @Component({
    components: {
      Editor,
    },
  })
  export default class StepDescriptionText extends Mixins<FieldMixin>(
    FieldMixin,
  ) {
    private tuiClassError: string = 'wrong';
    private classErrorText: string = 'te-text-wrong';
    private isFirstCallChange: boolean = true;

    private previousValue: string = '';

    private editorOptions = {
      placeholder: 'Опишите слабые и сильные стороны автомобиля',
      hideModeSwitch: true,
      toolbarItems: ['heading', 'bold', 'italic', 'ul'],
      language: 'ru-RU',
    };

    private get editorText() {
      return this.data.text || '';
    }

    private created() {
      EditorInst.setLanguage('ru-RU', {
        URL: 'Ссылка',
        OK: 'Добавить',
      });
    }

    private mounted() {
      this.deleteTuiTooltip();
      this.onClickHeadingButton();
      this.onClickInsertLinkButton();
      this.onClickSubmitPopupButton();
    }

    private deleteTuiTooltip() {
      const tuiTooltip = document.querySelector('.tui-tooltip');
      tuiTooltip && tuiTooltip.remove();
    }

    // Т.к. tui.editor не предоставляет возможности контролировать списком heading,
    // а по требваниям нужен только H5,
    // то были вынуждены программно выбирать данный заголовок и закрывать popup tui.editor
    private onClickHeadingButton() {
      const button = document.querySelector('.tui-heading.tui-toolbar-icons');

      if (!button) {
        return;
      }

      button.addEventListener('click', () => {
        const popup = document.querySelector(
          '.tui-popup-wrapper.te-heading-add',
        );
        const icon = popup?.querySelector(
          button.classList.contains('active')
            ? '[data-type="Paragraph"]'
            : '[data-value="5"]',
        );

        (icon as HTMLElement).click();
      });
    }

    private onClickSubmitPopupButton() {
      document.querySelector('.te-ok-button')?.addEventListener('click', () => {
        this.clearCustomErrorPopup();

        const inputUrl: HTMLInputElement = document.querySelector(
          '.te-url-input',
        );
        const inputLinkText: HTMLInputElement = document.querySelector(
          '.te-link-text-input',
        );

        if (!inputUrl.value) {
          inputUrl.classList.add(this.tuiClassError);
          inputUrl.insertAdjacentHTML(
            'afterend',
            `<div class="${this.classErrorText}">Введите ссылку</div>`,
          );
        }

        if (!inputLinkText.value) {
          inputLinkText.classList.add(this.tuiClassError);
          inputLinkText.insertAdjacentHTML(
            'afterend',
            `<div class="${this.classErrorText}">Введите текст</div>`,
          );
        }
      });
    }

    private onClickInsertLinkButton() {
      const elLink = document.querySelector('.tui-link.tui-toolbar-icons');

      elLink &&
        elLink.addEventListener('click', () => {
          this.clearCustomErrorPopup();
        });
    }

    private clearCustomErrorPopup() {
      document
        .querySelectorAll(`.${this.classErrorText}`)
        .forEach(el => el.remove());
    }

    private onEditorChange() {
      if (this.isFirstCallChange) {
        this.isFirstCallChange = false;

        return;
      }

      const { toastuiEditor } = this.$refs;
      const specialCharacterRegex = /(\\)([`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])/g;
      let editorText: string = toastuiEditor.invoke('getMarkdown');

      editorText = stripHtml(editorText).replace(specialCharacterRegex, '$2');

      if (editorText.length <= maxLengthTextReview) {
        this.previousValue = editorText;
        this.onFieldChange(editorText);
      } else {
        toastuiEditor.invoke('setMarkdown', this.previousValue);
      }
    }
  }
