var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__row vehicle-form__row_extended-other" },
    [
      _c(
        "div",
        {
          staticClass: "vehicle-form__line vehicle-form__line_condensed-other",
        },
        [
          _c("div", { staticClass: "vehicle-form__group" }, [
            _c(
              "div",
              { staticClass: "vehicle-form__label vehicle-form__label_base" },
              [
                _c("div", { staticClass: "vehicle-form__label-flex" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__label-part vehicle-form__label-part_1",
                    },
                    [
                      _c("div", { staticClass: "vehicle-form__label-title" }, [
                        _vm._v("\n              Телефон\n              "),
                        _vm.seller.chatPreferred
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__label-description vehicle-form__label-description_other",
                              },
                              [
                                _vm._v(
                                  "\n                (необязательно)\n              "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass:
                      "vehicle-form__label-part vehicle-form__label-part_2",
                  }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vehicle-form__field" },
              [
                _vm._l(_vm.phones, function (phone, index) {
                  return [
                    _c(
                      "div",
                      {
                        key: `phone_${index}`,
                        staticClass:
                          "vehicle-form__line vehicle-form__line_condensed-either",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-style__wrapper vehicle-form__input-wrapper",
                          },
                          [
                            _vm.phones.length > 1
                              ? _c("div", {
                                  staticClass:
                                    "input-style__helper input-style__helper_remove input-style__helper_shift input-style__helper_visible vehicle-form__input-helper",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removePhone(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("input", {
                              staticClass:
                                "input-style input-style_primary input-style_base vehicle-form__input vehicle-form__input_width_xxssmm",
                              class: {
                                "input-style_error":
                                  _vm.errors[`seller.phones.${index}`],
                              },
                              attrs: { type: "tel", maxlength: "55" },
                              domProps: { value: phone },
                              on: {
                                input: function ($event) {
                                  return _vm.onPhoneChange(index, $event)
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors[`seller.phones.${index}`]
                      ? _c(
                          "div",
                          {
                            key: `error_${index}`,
                            staticClass:
                              "vehicle-form__line vehicle-form__line_condensed-other",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_width_full",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.errors[`seller.phones.${index}`].join(
                                        ". "
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__line vehicle-form__line_reverse",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base vehicle-form__link_nodecor",
                        class: {
                          "vehicle-form__link_disabled": _vm.phones.length >= 5,
                        },
                        on: { click: _vm.addPhone },
                      },
                      [_vm._v("Добавить телефон")]
                    ),
                  ]
                ),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          !_vm.error ? _c("div", { staticClass: "dummy" }) : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            {
              staticClass:
                "vehicle-form__line vehicle-form__line_condensed-other",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_full",
                },
                [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }