
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import * as moment from 'moment';

import { ISearchAdvert, IAdvertSpecs } from '@/interfaces/adverts';
import { HashMap } from '@/types/common';
import urlService from '@/services/url-service';
import SearchAdvertsListPhotos from '@/components/search/search-adverts-list-photos.vue';
import SearchAdvertsListPremium from '@/components/search/search-adverts-list-premium.vue';
import AdvertsListItemBookmark from '@/components/adverts/adverts-list-item-bookmark.vue';
import AdvertsListItemActions from '@/components/adverts/adverts-list-item-actions.vue';
import AdvertsListItemMixin from '@/mixins/adverts/adverts-list-item';

const advertsStore = namespace('adverts');

@Component({
  components: {
    SearchAdvertsListPhotos,
    SearchAdvertsListPremium,
    AdvertsListItemBookmark,
    AdvertsListItemActions,
  },
})
export default class AdvertsListItem extends Mixins<AdvertsListItemMixin>(
  AdvertsListItemMixin
) {
  @Prop() selected!: boolean;

  @advertsStore.State permissions!: HashMap<boolean>;

  private get isSelected() {
    return this.selected;
  }

  private set isSelected(value) {
    this.$emit('select', value, this.advert.id);
  }

  private get closedAt() {
    return moment(this.advert.closedAt).format('DD.MM.YYYY в HH:mm');
  }

  private get closureReason() {
    return this.advert.closureReason.replace(
      /(https?:\/\/[^\s]+)/g,
      '<a href=\'$1\' target="_blank">$1</a>'
    );
  }

  private get closedBy() {
    const closedBy = this.advert.closedBy;

    if (!closedBy) {
      return {};
    }

    return {
      name: closedBy.name,
      link: urlService.secureProjectUrl('profile', `user/${closedBy.id}`),
    };
  }
}
