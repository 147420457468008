
  import { Component, Prop, Mixins } from 'vue-property-decorator';

  import ReviewClose from '@/components/reviews/common/review-close.vue';
  import ReviewStatusHistory from '@/components/reviews/common/review-status-history.vue';
  import ManageMixin from '@/mixins/reviews/common/manage';

  import { Review } from '@/types/review';

  @Component({
    components: {
      ReviewClose,
      ReviewStatusHistory,
    },
  })
  export default class ReviewManage extends Mixins<ManageMixin>(ManageMixin) {
    @Prop() review!: Review;

    private get permissions() {
      return this.review.permissions;
    }
  }
