var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "vehicle-form__group vehicle-form__group_alter vehicle-form__group_wide vehicle-form__group_width_auto",
    },
    [
      _c(
        "div",
        { staticClass: "vehicle-form__label vehicle-form__label_base" },
        [
          _c("div", { staticClass: "vehicle-form__label-flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__label-part vehicle-form__label-part_1",
              },
              [
                _c("div", { staticClass: "vehicle-form__label-title" }, [
                  _vm._v("\n          " + _vm._s(_vm.mark.name) + "\n        "),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_2",
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "vehicle-form__field" }, [
        _c(
          "div",
          {
            staticClass: "i-checkbox i-checkbox_rating vehicle-form__checkbox",
          },
          [
            _vm._l(_vm.reversedGrades, function (grade) {
              return [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.picked,
                      expression: "picked",
                    },
                  ],
                  key: grade,
                  staticClass: "i-checkbox__real",
                  attrs: {
                    id: _vm.getIdInputByGrade(grade),
                    name: _vm.idElement,
                    type: "radio",
                  },
                  domProps: {
                    value: grade,
                    checked: _vm._q(_vm.picked, grade),
                  },
                  on: {
                    change: function ($event) {
                      _vm.picked = grade
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", {
                  staticClass: "i-checkbox__faux",
                  attrs: { for: _vm.getIdInputByGrade(grade) },
                }),
              ]
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }