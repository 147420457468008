var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__row vehicle-form__row_extended-other" },
    [
      _c(
        "div",
        {
          staticClass: "vehicle-form__line vehicle-form__line_condensed-other",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__row vehicle-form__row_extended-other vehicle-form__row_choose",
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__group vehicle-form__group_width_auto",
                },
                [
                  _c("div", { staticClass: "vehicle-form__field" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "input-style__combo input-style__combo_stretch vehicle-form__input-combo vehicle-form__input-combo_width_full",
                      },
                      _vm._l(_vm.engineTypes, function (item) {
                        return _c(
                          "a",
                          {
                            key: item.id,
                            staticClass:
                              "button-style button-style_more button-style_base button-style_bordered vehicle-form__button vehicle-form__button_choose",
                            class: {
                              "button-style_active":
                                item.id === _vm.engine.type,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onSetEngineTypeClick(item.id)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm._f("capitalize")(item.name)) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.isGasAvailable(_vm.engine.type) ||
              _vm.isHybridAvailable(_vm.engine.type)
                ? _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__group vehicle-form__group_width_auto",
                    },
                    [
                      _c("div", { staticClass: "vehicle-form__field" }, [
                        _c(
                          "ul",
                          { staticClass: "vehicle-form__checkbox-list" },
                          [
                            _vm.isGasAvailable(_vm.engine.type)
                              ? _c(
                                  "li",
                                  {
                                    staticClass:
                                      "vehicle-form__checkbox-item vehicle-form__checkbox-item_inline",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "vehicle-form__checkbox-label",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "i-checkbox vehicle-form__checkbox vehicle-form__checkbox_base",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.gas,
                                                  expression: "gas",
                                                },
                                              ],
                                              staticClass: "i-checkbox__real",
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(_vm.gas)
                                                  ? _vm._i(_vm.gas, null) > -1
                                                  : _vm.gas,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.gas,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.gas = $$a.concat([
                                                          $$v,
                                                        ]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.gas = $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                    }
                                                  } else {
                                                    _vm.gas = $$c
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass: "i-checkbox__faux",
                                            }),
                                            _vm._v(" "),
                                            _vm._m(1),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isHybridAvailable(_vm.engine.type)
                              ? _c(
                                  "li",
                                  {
                                    staticClass:
                                      "vehicle-form__checkbox-item vehicle-form__checkbox-item_inline",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "vehicle-form__checkbox-label",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "i-checkbox vehicle-form__checkbox vehicle-form__checkbox_base",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.hybrid,
                                                  expression: "hybrid",
                                                },
                                              ],
                                              staticClass: "i-checkbox__real",
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.hybrid
                                                )
                                                  ? _vm._i(_vm.hybrid, null) >
                                                    -1
                                                  : _vm.hybrid,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.hybrid,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.hybrid =
                                                          $$a.concat([$$v]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.hybrid = $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                    }
                                                  } else {
                                                    _vm.hybrid = $$c
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass: "i-checkbox__faux",
                                            }),
                                            _vm._v(" "),
                                            _vm._m(2),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          !_vm.typeError ? _c("div", { staticClass: "dummy" }) : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.typeError
        ? _c(
            "div",
            {
              staticClass:
                "vehicle-form__line vehicle-form__line_condensed-other",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_full",
                },
                [_vm._v("\n      " + _vm._s(_vm.typeError) + "\n    ")]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isCapacityAvailable(_vm.engine.type)
        ? _c(
            "div",
            {
              staticClass: "vehicle-form__row vehicle-form__row_extended-other",
            },
            [
              _c("div", { staticClass: "vehicle-form__group" }, [
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__line vehicle-form__line_condensed-other",
                  },
                  [
                    _c("div", { staticClass: "vehicle-form__field" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_nonadaptive",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.capacity,
                                expression: "capacity",
                              },
                            ],
                            staticClass:
                              "input-style input-style_primary input-style_base vehicle-form__input vehicle-form__input_nonadaptive vehicle-form__input_width_xxsssss",
                            class: { "input-style_error": _vm.capacityError },
                            attrs: { type: "text", maxlength: "5" },
                            domProps: { value: _vm.capacity },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.capacity = $event.target.value
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm._m(4),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.capacityError
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__line vehicle-form__line_condensed-other",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_full",
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.capacityError) +
                                "\n        "
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__row vehicle-form__row_extended-other vehicle-form__row_choose",
        },
        [
          _vm._m(5),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__group vehicle-form__group_width_xxssmm",
            },
            [
              _c("div", { staticClass: "vehicle-form__field" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__line vehicle-form__line_condensed-other",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_width_full",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.powerValue,
                              expression: "powerValue",
                            },
                          ],
                          staticClass:
                            "input-style input-style_primary input-style_base vehicle-form__input vehicle-form__input_width_full",
                          class: { "input-style_error": _vm.powerError },
                          attrs: { type: "tel", maxlength: "4" },
                          domProps: { value: _vm.powerValue },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.powerValue = $event.target.value
                            },
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.powerError
                  ? _c("div", {
                      staticClass:
                        "vehicle-form__line vehicle-form__line_condensed-other",
                    })
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__group vehicle-form__group_width_xxxsss",
            },
            [
              _c("div", { staticClass: "vehicle-form__field" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__line vehicle-form__line_condensed-other",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "input-style__combo input-style__combo_stretch vehicle-form__input-combo vehicle-form__input-combo_width_full",
                      },
                      _vm._l(_vm.powerUnits, function (item, key) {
                        return _c(
                          "a",
                          {
                            key: key,
                            staticClass:
                              "button-style button-style_more button-style_base button-style_bordered vehicle-form__button vehicle-form__button_choose",
                            class: {
                              "button-style_active": key === _vm.power.unit,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onSetPowerUnitClick(key)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.powerError
                  ? _c("div", {
                      staticClass:
                        "vehicle-form__line vehicle-form__line_condensed-other",
                    })
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.powerError
            ? _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__line vehicle-form__line_condensed-other",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_full",
                    },
                    [_vm._v("\n        " + _vm._s(_vm.powerError) + "\n      ")]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vehicle-form__row vehicle-form__row_extended-other" },
        [
          _c("div", { staticClass: "vehicle-form__group" }, [
            _vm._m(6),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__line vehicle-form__line_condensed-other",
              },
              [
                _c("div", { staticClass: "vehicle-form__field" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_nonadaptive",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.torque,
                            expression: "torque",
                          },
                        ],
                        staticClass:
                          "input-style input-style_primary input-style_base vehicle-form__input vehicle-form__input_nonadaptive vehicle-form__input_width_xxsssss",
                        class: { "input-style_error": _vm.torqueError },
                        attrs: { type: "tel", maxlength: "4" },
                        domProps: { value: _vm.torque },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.torque = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm._m(7),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.torqueError
              ? _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__line vehicle-form__line_condensed-other",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_full",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.torqueError) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("\n              Двигатель\n            "),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__checkbox-text" }, [
      _c("div", { staticClass: "vehicle-form__checkbox-sign" }, [
        _vm._v(
          "\n                      Газобаллонное оборудование\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__checkbox-text" }, [
      _c("div", { staticClass: "vehicle-form__checkbox-sign" }, [
        _vm._v("\n                      Гибрид\n                    "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("\n              Объем двигателя\n            "),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "input-style__helper input-style__helper_hint input-style__helper_visible vehicle-form__input-helper",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__hint vehicle-form__hint_other vehicle-form__hint_base-alter vehicle-form__hint_extended",
          },
          [_vm._v("\n                л\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("\n            Мощность двигателя\n            "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__label-description vehicle-form__label-description_other",
                  },
                  [_vm._v("\n              (необязательно)\n            ")]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("\n              Крутящий момент\n              "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__label-description vehicle-form__label-description_other",
                  },
                  [_vm._v("\n                (необязательно)\n              ")]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "input-style__helper input-style__helper_hint input-style__helper_visible vehicle-form__input-helper",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__hint vehicle-form__hint_other vehicle-form__hint_base-alter vehicle-form__hint_extended",
          },
          [_vm._v("\n                Нм\n              ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }