import { Vue } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';

import vmApi from '@/api/vm-api';
import { Review } from '@/types/review';

@Mixin
export default class ManageMixin extends Vue {
  protected review!: Review;

  private onOpenClick() {
    vmApi.openReview(this.review.id, {
      success: ({ data }: { data: Review }) => {
        this.$emit('opened', data);
      },
    });
  }

  private onClose(closureReason: string) {
    vmApi.closeReview(this.review.id, closureReason, {
      success: ({ data }: { data: Review }) => {
        this.$emit('closed', data);
      },
    });
  }

  private onModerateClick() {
    vmApi.moderateReview(this.review.id, {
      success: ({ data }: { data: Review }) => {
        this.$emit('moderated', data);
      },
    });
  }

  private onPublishClick() {
    vmApi.publishReview(this.review.id, {
      success: ({ data }: { data: Review }) => {
        this.$emit('published', data);
      },
    });
  }
}
