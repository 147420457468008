var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form",
    {
      staticClass:
        "vehicle-form__comments-send vehicle-form__comments-send_opened helpers_hide_tablet",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__comments-item vehicle-form__comments-item_additional",
        },
        [
          _c("div", { staticClass: "vehicle-form__comments-unit" }, [
            _c("div", { staticClass: "vehicle-form__comments-box" }, [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__row vehicle-form__row_condensed-fringe",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__group vehicle-form__group_width_full",
                    },
                    [
                      _c("div", { staticClass: "vehicle-form__field" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__line vehicle-form__line_condensed",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_width_full",
                              },
                              [
                                _c("editor", {
                                  ref: "toastuiEditor",
                                  staticClass:
                                    "input-style input-style_primary input-style_base vehicle-form__input vehicle-form__input_width_full vehicle-form__input_min-height_xss auto-height",
                                  class: {
                                    "input-style_error": _vm.errors.text,
                                  },
                                  attrs: {
                                    "initial-value": _vm.text,
                                    options: _vm.editorOptions,
                                    height: "auto",
                                    "initial-edit-type": "wysiwyg",
                                  },
                                  on: { change: _vm.onChange },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.errors.text
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__line vehicle-form__line_condensed",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_width_full",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.errors.text.join(". ")) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__control vehicle-form__control_condensed-farther",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__control-flex vehicle-form__control-flex_column",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__control-part vehicle-form__control-part_1",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "button-style button-style_primary button-style_base vehicle-form__button vehicle-form__button_width_full",
                              class: {
                                "button-style_animated": _vm.isProcessing,
                              },
                              attrs: { type: "submit" },
                            },
                            [
                              _vm._v(
                                "\n                Ответить\n              "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__control-part vehicle-form__control-part_2 vehicle-form__control-part_text_left",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_other vehicle-form__description_base",
                            },
                            [
                              _vm._v(
                                "\n                Комментируя, вы соглашаетесь с\n                "
                              ),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "vehicle-form__link vehicle-form__link_primary",
                                  attrs: {
                                    href: _vm.rulesUrl,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("правилами")]
                              ),
                              _vm._v("."),
                              _c("br", { staticClass: "helpers_hide_tablet" }),
                              _vm._v(
                                "\n                Мы опубликуем комментарий после проверки:\n                "
                              ),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "vehicle-form__link vehicle-form__link_primary",
                                  attrs: {
                                    href: "https://people.onliner.by/2020/11/03/onliner-kommentarii",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("как это работает")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }