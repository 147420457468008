var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a",
    {
      staticClass:
        "button-style button-style_auxiliary button-style_middle vehicle-form__button vehicle-form__button_statistics vehicle-form__button_width_full",
      on: { click: _vm.openStatsPopup },
    },
    [
      _vm._v("\n  Статистика просмотров\n  "),
      _c("br"),
      _vm._v("объявления\n  "),
      _c(
        "popup",
        {
          ref: "statsPopup",
          attrs: {
            classes: [
              "popup-style_primary",
              " popup-style_middle",
              "vehicle-popup",
              "vehicle-popup_width_xxxxlm",
            ],
          },
        },
        [
          _c("span", {
            staticClass: "popup-style__close",
            on: { click: _vm.closeStatsPopup },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form" }, [
            _c("div", { staticClass: "vehicle-form__chart" }, [
              _c("div", { staticClass: "vehicle-form__chart-flex" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__chart-part vehicle-form__chart-part_1",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__control vehicle-form__control_condensed-either vehicle-form__control_absolute helpers_hide_tablet",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-style__combo input-style__combo_stretch vehicle-form__input-combo vehicle-form__input-combo_width_full",
                          },
                          _vm._l(_vm.periods, function (period) {
                            return _c(
                              "span",
                              {
                                staticClass:
                                  "button-style button-style_obverse button-style_small vehicle-form__button",
                                class: {
                                  "button-style_active":
                                    period.count === _vm.selectedPeriod,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setPeriod(period.count)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(period.label))]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_condensed-other",
                      },
                      [_vm._v("Статистика просмотров")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "vehicle-form__intro" }, [
                      _c("div", { staticClass: "vehicle-form__intro-list" }, [
                        _c("div", { staticClass: "vehicle-form__intro-item" }, [
                          _vm.advert.stats.views.total
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_other vehicle-form__description_base",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("plural")(
                                        _vm.advert.stats.views.total,
                                        ["просмотр", "просмотра", "просмотров"]
                                      )
                                    ) + " за все время"
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_other vehicle-form__description_base",
                                },
                                [_vm._v("Пока нет просмотров")]
                              ),
                        ]),
                        _vm._v(" "),
                        _vm.advert.stats.bookmarks.total
                          ? _c(
                              "div",
                              { staticClass: "vehicle-form__intro-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__description vehicle-form__description_warning-alter vehicle-form__description_base",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("plural")(
                                          _vm.advert.stats.bookmarks.total,
                                          [
                                            "добавление",
                                            "добавления",
                                            "добавлений",
                                          ]
                                        )
                                      ) + " в закладки"
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass:
                        "vehicle-form__divider vehicle-form__divider_condensed-alter",
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "vehicle-form__chart-box" },
                      [
                        _vm.isChartPresent
                          ? _c("chart", {
                              attrs: {
                                "chart-data": _vm.chartData,
                                options: _vm.options,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                !_vm.advert.closedAt && !_vm.advert.premium
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__chart-part vehicle-form__chart-part_2",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__mediabox vehicle-form__mediabox_premium-alter",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__preview" },
                              [
                                _c("div", {
                                  staticClass:
                                    "vehicle-form__image vehicle-form__image_car-up",
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed-another",
                              },
                              [_vm._v("Ускорить продажу")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base",
                              },
                              [
                                _vm._v(
                                  "Поднимите ваше объявление в поиске и сделайте его заметнее, чтобы увеличить шансы на быструю и выгодную продажу."
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__control vehicle-form__control_condensed-other",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "gtm",
                                        rawName: "v-gtm",
                                        value: { event: "buy_premium_feature" },
                                        expression:
                                          "{event: 'buy_premium_feature'}",
                                      },
                                    ],
                                    staticClass:
                                      "button-style button-style_primary button-style_base vehicle-form__button vehicle-form__button_width_full",
                                    on: { click: _vm.onPromoteClick },
                                  },
                                  [_vm._v("Попробовать")]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }