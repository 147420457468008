import { findWhere } from 'underscore';
import { GetterTree } from 'vuex';
import { ICommonState, IRootState } from '@/interfaces/store';

const getters: GetterTree<ICommonState, IRootState> = {
  getDropdown: state => (id: string) => {
    const item = findWhere(state.dropdowns, { id });

    return item || {};
  },

  anyDropdownOpened(state) {
    const anyVisible = findWhere(state.dropdowns, { isVisible: true });

    return !!anyVisible;
  },

  getDictionaryItem: state => (type: string, key: string) => {
    const item = findWhere(state.dictionaries[type], { id: key });

    return item || {};
  },
};

export default getters;
