var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__grade-flex" }, [
    _c("input", {
      staticClass: "i-checkbox__real helpers_show_tablet",
      attrs: {
        type: "radio",
        name: "gradeRadioGroup1",
        id: "gradeRadioAnchor1",
        checked: "",
      },
    }),
    _vm._v(" "),
    _c(
      "label",
      {
        staticClass: "vehicle-form__grade-switcher helpers_show_tablet",
        attrs: { for: "gradeRadioAnchor1" },
      },
      [_vm._v("Оценки")]
    ),
    _vm._v(" "),
    _c("input", {
      staticClass: "i-checkbox__real helpers_show_tablet",
      attrs: {
        type: "radio",
        name: "gradeRadioGroup1",
        id: "gradeRadioAnchor2",
      },
    }),
    _vm._v(" "),
    _c(
      "label",
      {
        staticClass: "vehicle-form__grade-switcher helpers_show_tablet",
        attrs: { for: "gradeRadioAnchor2" },
      },
      [_vm._v("Плюсы")]
    ),
    _vm._v(" "),
    _c("input", {
      staticClass: "i-checkbox__real helpers_show_tablet",
      attrs: {
        type: "radio",
        name: "gradeRadioGroup1",
        id: "gradeRadioAnchor3",
      },
    }),
    _vm._v(" "),
    _c(
      "label",
      {
        staticClass: "vehicle-form__grade-switcher helpers_show_tablet",
        attrs: { for: "gradeRadioAnchor3" },
      },
      [_vm._v("Минусы")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "vehicle-form__grade-part vehicle-form__grade-part_1" },
      [
        _c("rating-header", {
          attrs: {
            "rating-by": _vm.reviewsRating.ratingBy,
            "rating-average": _vm.reviewsRating.average,
            "count-reviews": _vm.reviewsRating.count,
            "is-counter-clickable": _vm.isCounterClickable,
            "is-info-block-visible": _vm.isInfoBlockVisible,
            manufacturer: _vm.manufacturer,
            model: _vm.model,
            generation: _vm.generation,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "vehicle-form__grade-part vehicle-form__grade-part_2" },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_base-alter vehicle-form__description_primary vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-complementary",
          },
          [_vm._v("\n      Оценки\n    ")]
        ),
        _vm._v(" "),
        !_vm.hide.divider
          ? _c("div", {
              staticClass:
                "vehicle-form__divider vehicle-form__divider_condensed-default",
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__parameter vehicle-form__parameter_specific",
          },
          [
            _c(
              "div",
              { staticClass: "vehicle-form__parameter-list" },
              _vm._l(_vm.marks, function (mark) {
                return _c("rating-mark", {
                  key: mark.key,
                  attrs: {
                    "dictionary-key": mark.dictionaryKey,
                    value: mark.value,
                    "is-value-float": true,
                  },
                })
              }),
              1
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "vehicle-form__grade-part vehicle-form__grade-part_3" },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_base-alter vehicle-form__description_primary vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-complementary vehicle-form__description_nonadaptive",
          },
          [
            _c("div", { staticClass: "vehicle-form__description-flex" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description-part vehicle-form__description-part_2 helpers_show_tablet",
                },
                [
                  _vm.hasPros
                    ? _c("detailed-information", {
                        attrs: {
                          manufacturer: _vm.reviewsRating.car.manufacturer,
                          model: _vm.reviewsRating.car.model,
                          generation: _vm.reviewsRating.car.generation,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        !_vm.hide.divider
          ? _c("div", {
              staticClass:
                "vehicle-form__divider vehicle-form__divider_condensed-default",
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.hasPros
          ? _c(
              "div",
              {
                staticClass:
                  "vehicle-form__parameter vehicle-form__parameter_specific",
              },
              [
                _c(
                  "div",
                  { staticClass: "vehicle-form__parameter-list" },
                  _vm._l(_vm.reviewsRating.stats.pros, function (item) {
                    return _c("secondary-parameter", {
                      key: item.id,
                      attrs: {
                        label: item.title,
                        "positive-value": item.positive,
                        "negative-value": item.negative,
                      },
                    })
                  }),
                  1
                ),
              ]
            )
          : !_vm.hasPros && _vm.hasCons
          ? _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_other vehicle-form__description_small vehicle-form__description_info",
              },
              [
                _vm._v(
                  "\n      Пользователи пока не отметили ни одного плюса\n    "
                ),
              ]
            )
          : _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_other vehicle-form__description_small vehicle-form__description_info",
              },
              [
                _vm._v(
                  "\n      Недостаточно данных для отображения статистики\n    "
                ),
              ]
            ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "vehicle-form__grade-part vehicle-form__grade-part_4",
        class: { helpers_show_tablet: !_vm.hasPros && !_vm.hasCons },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_base-alter vehicle-form__description_primary vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-complementary vehicle-form__description_nonadaptive",
          },
          [
            _c("div", { staticClass: "vehicle-form__description-flex" }, [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description-part vehicle-form__description-part_1 helpers_show_tablet",
                },
                [_vm._v("\n          Минусы\n        ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description-part vehicle-form__description-part_2",
                  class: { helpers_hide_tablet: !_vm.hasCons },
                },
                [
                  _vm.hasPros || _vm.hasCons
                    ? _c("detailed-information", {
                        attrs: {
                          manufacturer: _vm.reviewsRating.car.manufacturer,
                          model: _vm.reviewsRating.car.model,
                          generation: _vm.reviewsRating.car.generation,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        !_vm.hide.divider
          ? _c("div", {
              staticClass:
                "vehicle-form__divider vehicle-form__divider_condensed-default",
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.hasCons
          ? _c(
              "div",
              {
                staticClass:
                  "vehicle-form__parameter vehicle-form__parameter_specific",
              },
              [
                _c(
                  "div",
                  { staticClass: "vehicle-form__parameter-list" },
                  _vm._l(_vm.reviewsRating.stats.cons, function (item) {
                    return _c("secondary-parameter", {
                      key: item.id,
                      attrs: {
                        label: item.title,
                        "positive-value": item.positive,
                        "negative-value": item.negative,
                      },
                    })
                  }),
                  1
                ),
              ]
            )
          : !_vm.hasCons && _vm.hasPros
          ? _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_other vehicle-form__description_small vehicle-form__description_info",
              },
              [
                _vm._v(
                  "\n      Пользователи пока не отметили ни одного минуса\n    "
                ),
              ]
            )
          : _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_other vehicle-form__description_small vehicle-form__description_info",
              },
              [
                _vm._v(
                  "\n      Недостаточно данных для отображения статистики\n    "
                ),
              ]
            ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__description-part vehicle-form__description-part_1",
      },
      [
        _vm._v("\n          Плюсы "),
        _c("span", { staticClass: "helpers_hide_tablet" }, [
          _vm._v("и минусы"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }