
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';

  import { DictionaryItem } from '@/services/dictionaries-service';
  import camelizeService from '@/services/camelize-service';
  import { roundTo } from '@/services/utils-service';

  const commonStore = namespace('common');

  @Component
  export default class RatingMark extends Vue {
    @Prop() dictionaryKey!: string;
    @Prop() value!: number;
    @Prop({
      default: true,
    })
    isLabelPrimary: boolean;
    @Prop({
      default: false,
    })
    isValuePrimary: boolean;
    @Prop({
      default: false,
    })
    isValueFloat!: boolean;

    @commonStore.Getter getDictionaryItem!: (
      type: string,
      key: string,
    ) => DictionaryItem;

    private get rating() {
      return roundTo(this.value, 0.5) * 10;
    }

    private get label() {
      return this.getDictionaryItem(
        'primaryMarks',
        camelizeService.decamelizeString(this.dictionaryKey),
      )?.name;
    }
  }
