import { Mixins } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';

import CommentsCoreMixin from '@/mixins/comments/comments-core';
import ScrollMixin from '@/mixins/common/scroll';
import { find, findIndex, findWhere, some } from 'underscore';
import { Comment } from '@/types/comments';
import ScrollList from '@/components/comments/scroll-list.vue';
import CommentsListBranch from '@/components/comments/comments-list-branch.vue';

interface IMixins extends CommentsCoreMixin, ScrollMixin {}

@Mixin
export default class CommentsScrollMixin extends Mixins<IMixins>(
  CommentsCoreMixin,
  ScrollMixin,
) {
  private findCommentById(commentId: string) {
    return findWhere(this.comments, { id: commentId });
  }

  private findParentByCommentId(commentId: string) {
    return find(this.comments, comment => {
      return some(comment.replies, { id: commentId });
    });
  }

  private searchComment(commentId: string) {
    let comment = this.findCommentById(commentId);

    if (comment) {
      return comment;
    } else {
      const parent = this.findParentByCommentId(commentId);

      return parent ? findWhere(parent.replies, { id: commentId }) : null;
    }
  }

  private async scrollToComment(
    comment: Comment,
    isHighlighted: boolean = true,
  ) {
    return new Promise<void>(resolve => {
      const index = findIndex(this.orderedComments, {
        id: comment.parentId ? comment.parentId : comment.id,
      });

      if (!comment.parentId) {
        (this.$refs.scroll as ScrollList).goToIndex(index, () => {
          this.$set(comment, 'isHighlighted', isHighlighted);

          this.$nextTick(() => {
            const commentRef = (this.$refs[
              `comment_${comment.id}`
            ] as Vue[])[0] as CommentsListBranch;

            commentRef && commentRef.$el.scrollIntoView();
            this.correctOffset();

            resolve();
          });
        });
      } else {
        const parent = this.findCommentById(comment.parentId);

        parent &&
          (this.$refs.scroll as ScrollList).goToIndex(index, () => {
            this.$set(parent, 'isBranchOpened', true);
            this.$set(comment, 'isHighlighted', isHighlighted);

            this.$nextTick(() => {
              this.scrollToReply(comment);
            });
          });
      }
    });
  }

  private scrollToReply(comment: Comment) {
    const commentRef = (this.$refs[
      `comment_${comment.parentId}`
    ] as Vue[])[0] as CommentsListBranch;

    if (!commentRef) {
      return;
    }

    const replyRef = (commentRef.$refs[`comment_${comment.id}`] as Vue[])[0];

    if (!replyRef) {
      return;
    }

    replyRef && replyRef.$el.scrollIntoView();
    this.correctOffset();
  }

  protected async goToComment(commentId: string, isHighlighted?: boolean) {
    return new Promise<void>(resolve => {
      let comment = this.searchComment(commentId);

      if (comment) {
        this.scrollToComment(comment, isHighlighted).then(() => {
          resolve();
        });
      } else {
        this.showAllComments(() => {
          let comment = this.searchComment(commentId);

          this.$nextTick(() => {
            comment &&
              this.scrollToComment(comment, isHighlighted).then(() => {
                resolve();
              });
          });
        });
      }
    });
  }
}
