var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_extended-alter",
      },
      [_vm._v("Выбор объявления")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "vehicle-form__row vehicle-form__row_extended-specific vehicle-form__row_choose",
      },
      [
        _c("promote-adverts-manufacturer"),
        _vm._v(" "),
        _c("promote-adverts-model"),
        _vm._v(" "),
        _c("promote-adverts-filter-premium"),
      ],
      1
    ),
    _vm._v(" "),
    _c("div"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }