var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "vehicle-form__intro" }, [
      _c("div", { staticClass: "vehicle-form__intro-flex" }, [
        _c(
          "div",
          {
            staticClass: "vehicle-form__intro-part vehicle-form__intro-part_1",
          },
          [
            _c("div", { staticClass: "vehicle-form__intro-list" }, [
              _c("div", { staticClass: "vehicle-form__intro-item" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_extended-other",
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.title || "Автомобиль") +
                        "\n            "
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.isComplete && !_vm.isEditMode
          ? _c(
              "div",
              {
                staticClass:
                  "vehicle-form__intro-part vehicle-form__intro-part_2",
              },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "vehicle-form__link vehicle-form__link_primary vehicle-form__link_tiny vehicle-form__link_font-weight_normal vehicle-form__link_ellipsis",
                    on: {
                      click: function ($event) {
                        return _vm.editStep("tech")
                      },
                    },
                  },
                  [_vm._v("\n          Изменить\n        ")]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _vm.isComplete
      ? _c("div", { staticClass: "vehicle-form__parameter" }, [
          _c("div", { staticClass: "vehicle-form__parameter-list" }, [
            _c("div", { staticClass: "vehicle-form__parameter-item" }, [
              _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.year) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.generationName
              ? _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                  _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.generationName) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__parameter-item" }, [
              _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                      },
                      [_vm._v(_vm._s(_vm._f("capitalize")(_vm.bodyType)))]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__parameter-item" }, [
              _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                      },
                      [_vm._v(_vm._s(_vm._f("capitalize")(_vm.engineType)))]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.engineCapacity
              ? _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                  _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                    _vm._m(4),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                          },
                          [_vm._v(_vm._s(_vm.engineCapacity))]
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__parameter-item" }, [
              _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                _vm._m(5),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                      },
                      [_vm._v(_vm._s(_vm._f("capitalize")(_vm.drivetrain)))]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__parameter-item" }, [
              _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                _vm._m(6),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                      },
                      [_vm._v(_vm._s(_vm._f("capitalize")(_vm.transmission)))]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n              Год выпуска\n            ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n              Поколение\n            ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("Тип кузова")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("Тип двигателя")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("Объем двигателя")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("Привод")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("Коробка передач")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }