import { HashMap } from '@/types/common';

export default {
  auto_up: {
    up: 'Автоподнятие в&nbsp;общем списке 3&nbsp;раза в&nbsp;день',
  },
  sticky: {
    up: 'Автоподнятие в&nbsp;закрепленном списке 3&nbsp;раза в&nbsp;день',
    stick: true,
    top: true,
  },
  premium: {
    up: 'Автоподнятие в&nbsp;премиальном списке 1&nbsp;раз в&nbsp;день',
    top: true,
    stickTop: true,
    visual: true,
    tiles: true,
  },
} as HashMap<any>;

export const iconMap: HashMap<string> = {
  up: 'vehicle-form__description_up-additional',
  pin: 'vehicle-form__description_fixing-other',
  pin_premium: 'vehicle-form__description_premium-other',
  top: 'vehicle-form__description_top',
  highlight: 'vehicle-form__description_eye-alter',
  rotate: 'vehicle-form__description_home',
};

export const labelIds: string[] = ['img_icon'];
