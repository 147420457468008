import { Prop } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { findWhere, isEmpty, last } from 'underscore';

import dictionariesService, {
  DictionaryItem,
} from '@/services/dictionaries-service';
import FilterMixin from '@/mixins/filters/filter';
import { ChainChunk } from '@/types/filters';
import { VuexAction } from '@/types/functions';
import dictionariesMap from '@/config/dictionaries-map';
import { HashMap } from '@/types/common';

const searchStore = namespace('search');

@Component
export default class ChainedSelectFilterMixin extends Mixins<FilterMixin>(
  FilterMixin,
) {
  @Prop() typeChain!: ChainChunk[];
  @Prop() placeholder!: string[];

  protected filterType = 'object';

  private get chain(): ChainChunk[] {
    const initialChain: any = this.filter.value;
    const chain: ChainChunk[] = [];
    const addChunk = (index: number) => {
      if (index >= this.typeChain.length) {
        return;
      }

      chain.push(
        this.getChunk(chain, index, () => {
          const chunk: ChainChunk = chain[index];

          chunk && chunk.value && addChunk(index + 1);
        }),
      );

      const lastChunk: ChainChunk | undefined = last(chain);

      if (lastChunk) {
        lastChunk.value = initialChain[lastChunk.name];
      }
    };

    chain.push(this.getChunk(chain, 0));

    if (!isEmpty(initialChain)) {
      const chunk: ChainChunk = chain[0];

      chunk.value = initialChain[chunk.name];

      addChunk(1);
    }

    return chain;
  }

  private getChunk(
    chain: ChainChunk[],
    index: number,
    callback: Function = () => {},
  ): ChainChunk {
    const chunk = this.typeChain[index];

    if (index > 0) {
      const prevChunk = chain[index - 1];

      const selectedOption: DictionaryItem | undefined = findWhere(
        this.dictionaries[prevChunk.name],
        {
          id: parseInt(prevChunk.value),
        },
      );

      if (selectedOption) {
        this.addDictionary(prevChunk.name, callback);
      }
    }

    return {
      name: chunk.name,
      placeholder: chunk.placeholder,
      value: '',
    };
  }

  protected updateFilter(index: number, value: string) {
    this._updateFilter(this.getFilterValue(index, value));
  }

  private getFilterValue(index: number, value: string) {
    const newValue: { [index: string]: string } = {};
    let i: number = 0;

    while (i < index) {
      const chunk = this.chain[i];

      chunk.value && (newValue[chunk.name] = chunk.value);
      i++;
    }

    newValue[this.typeChain[index].name] = value;

    return newValue;
  }

  private getDictionaryName(index: number) {
    if (index === 0) {
      return this.typeChain[index].name;
    }

    const parent = this.chain[index - 1];

    if (!parent) {
      return '';
    }

    const urlData = this.filter.value as HashMap<any>;

    return urlData ? dictionariesService.buildUrl(parent.name, urlData) : '';
  }
}
