
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import { Dictionaries } from '@/services/dictionaries-service';
import FieldMixin from '@/mixins/create/field';
import StorageMixin from '@/mixins/create/storage';

interface IMixins extends FieldMixin, StorageMixin {}

const commonStore = namespace('common');

@Component
export default class StepSellerType extends Mixins<IMixins>(
  FieldMixin,
  StorageMixin
) {
  @commonStore.State dictionaries!: Dictionaries;

  private get seller() {
    return this.data.seller || {};
  }

  private setSellerType(value: string) {
    this.clearError({ name: 'seller.type' });
    this.clearError({ name: 'seller.name' });
    this.clearError({ name: 'seller.unp' });
    this.clearError({ name: 'seller.contactName' });

    if (value === 'individual') {
      const { contactName, unp, ...seller } = this.seller;

      this.setField({ seller: { ...seller, type: value } });
    } else {
      const unp = this.getStorageValue('unp');
      const contactName = this.getStorageValue('contactName');

      this.setField({
        seller: { ...this.seller, type: value, unp, contactName },
      });
    }
  }

  private get error() {
    return (this.errors['seller.type'] || []).join('. ');
  }
}
