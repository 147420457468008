import { ActionTree, Payload } from 'vuex';

import vmApi from '@/api/vm-api';
import { IRootState, ICreateState } from '@/interfaces/store';
import createTransformer from '@/transformers/create-transfromer';
import { AdvertResponse } from '@/types/api';
import { HashMap } from '@/types/common';

const actions: ActionTree<ICreateState, IRootState> = {
  init(context) {
    context.commit('init');
  },

  setMode(context, { mode }: { mode: string }) {
    context.commit('setMode', mode);
  },

  setField(context, payload: Payload) {
    context.commit('setField', payload);
  },

  clearData(context) {
    context.commit('clearData');
  },

  initData(
    context,
    { id, success, error }: { id: string; success: Function; error: Function }
  ) {
    vmApi.getAdvert(id, {
      success({ data }: AdvertResponse) {
        context.commit('setData', data);

        success && success(data);
      },
      error,
    });
  },

  setError(context, payload: Payload) {
    context.commit('setError', payload);
  },

  setStep(context, payload: Payload) {
    context.commit('setStep', payload);
  },

  createAdvert(context, payload: Payload) {
    vmApi.createAdvert(createTransformer(context.state.data), payload);
  },

  updateAdvert(
    context,
    { id, callbacks }: { id: string; callbacks: HashMap<Function> }
  ) {
    vmApi.updateAdvert(id, createTransformer(context.state.data), callbacks);
  },

  clearError(context, { name }: { name: string }) {
    context.commit('clearError', name);
  },
};

export default actions;
