
import { Prop, Watch } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import { State, namespace } from 'vuex-class';
import { findWhere, first, isEmpty, map, difference } from 'underscore';

import ChainedSelectFilterChunkPopular from '@/components/filters/chained-select-filter-chunk-popular.vue';
import Dropdown from '@/mixins/common/dropdown';
import { ChainChunk } from '@/types/filters';
import {
  Dictionary,
  Dictionaries,
  DictionaryItem,
} from '@/services/dictionaries-service';
import { ICounters } from '@/interfaces/search';
import { HashMap } from '@/types/common';
import capitalize from '@/filters/format-capitalize';
import number from '@/filters/format-number';
import background from '@/filters/format-background';

const commonStore = namespace('common');

@Component({
  components: {
    ChainedSelectFilterChunkPopular,
  },
  filters: {
    number,
    background,
  },
})
export default class ChainedSelectFilterChunkMulti extends Mixins<Dropdown>(
  Dropdown
) {
  @Prop() value!: Array<number>;
  @Prop() index!: number;
  @Prop() groupIndex!: number;
  @Prop() dictionaryName!: string;
  @Prop() parent!: ChainChunk;
  @Prop() chunk!: ChainChunk;
  @Prop() typeChunk!: ChainChunk;
  @Prop() counters!: HashMap<ICounters>;
  @Prop({
    default: false,
  })
  isDisabled!: boolean;

  private optionsFilter: string = '';

  @commonStore.State dictionaries!: Dictionaries;

  private get filterString() {
    return this.optionsFilter
      .trim()
      .toLowerCase()
      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  private get options() {
    if (!this.dictionaryName) {
      return [];
    }

    const dictionary: Dictionary = this.dictionaries[this.dictionaryName];

    if (!dictionary) {
      return [];
    }

    if (!this.optionsFilter) {
      return dictionary;
    }

    const regex = new RegExp(this.filterString);

    return dictionary.filter((item: DictionaryItem) => {
      return regex.test(item.name.toLowerCase());
    });
  }

  protected get selectedValues() {
    const selectedItems: Array<string> = [];

    this.value &&
      this.value.forEach((id: any) => {
        const option = findWhere(this.options, { id: parseInt(id) });

        option && selectedItems.push(option.name);
      });

    return selectedItems.join(', ');
  }

  private get checkboxes(): HashMap<boolean> {
    const values: Array<number> = this.value || [];
    const checkboxes: HashMap<boolean> = {};

    values.forEach(value => {
      checkboxes[value] = true;
    });

    return checkboxes;
  }

  private get isNoneSelected() {
    return !this.value || !this.value.length;
  }

  private onChange(val: any) {
    const newValue = map(this.checkboxes, (value: boolean, id: string) => {
      return value ? parseInt(id) : 0;
    }).filter(item => item !== 0);
    const oldValue = this.value
      ? this.value.map(item => parseInt(item.toString()))
      : [];
    const newElements = difference(newValue, oldValue);

    const value =
      this.value && newValue.length > this.value.length
        ? [...this.value, ...newElements]
        : newValue;

    this.$emit('change', {
      index: this.index,
      value: value.length ? value : null,
    });
  }

  private clearFilter() {
    this.$emit('change', {
      index: this.index,
      value: null,
    });
  }

  @Watch('dictionaryName') onDictionaryNameChanged(options: string) {
    this.optionsFilter = '';
  }
}
