var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__row vehicle-form__row_extended-other" },
    [
      _c(
        "div",
        {
          staticClass: "vehicle-form__line vehicle-form__line_condensed-other",
        },
        [
          _c("div", { staticClass: "vehicle-form__group" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__field" }, [
              _c(
                "div",
                {
                  staticClass:
                    "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_nonadaptive",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "input-style input-style_primary input-style_base input-style_arrow_top-bottom vehicle-form__input vehicle-form__input_nonadaptive vehicle-form__input_width_xxsssss",
                      class: { "input-style_placeholder": !_vm.year },
                    },
                    [
                      _c("div", { staticClass: "input-style__faux" }, [
                        _vm._v(_vm._s(_vm.year || "Выберите")),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.year,
                              expression: "year",
                            },
                          ],
                          staticClass:
                            "button-style__select vehicle-form__button-select",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.year = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("Год"),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.yearsDictionary, function (item) {
                            return _c("option", { key: item.id }, [
                              _vm._v(_vm._s(item.name)),
                            ])
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            {
              staticClass:
                "vehicle-form__line vehicle-form__line_condensed-other",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_full",
                },
                [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("Год"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }