
  import { last } from 'underscore';
  import { Vue, Prop } from 'vue-property-decorator';
  import { Mixins, Component } from 'vue-mixin-decorator';
  import { debounce } from 'underscore';
  import * as $ from 'jquery';

  import { IPhoto } from '@/interfaces/common';
  import background from '@/filters/format-background';
  import plural from '@/filters/plural-form';
  import IsMobile from '@/mixins/common/is-mobile';

  const previewLength = 4;

  @Component({
    filters: {
      background,
      plural,
    },
  })
  export default class SearchAdvertsListPhotos extends Mixins<IsMobile>(
    IsMobile,
  ) {
    @Prop() isClosed!: boolean;
    @Prop() closureReason!: string;
    @Prop() images!: Array<IPhoto>;
    @Prop() isPremium!: boolean;

    private get galleryPhotos() {
      const galleryPhotos = [];

      for (let i = 0; i < previewLength; i++) {
        galleryPhotos.push(this.images[i] || last(this.images));
      }

      return galleryPhotos;
    }

    private get thumbnails() {
      return this.galleryPhotos.slice(1);
    }
  }
