var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "handle",
      staticClass: "popover-style__handle vehicle-form__popover-handle",
      class: { "popover-style__handle_opened": _vm.isVisible },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "vehicle-form__link vehicle-form__link_primary vehicle-form__link_tiny vehicle-form__link_qmark",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.toggleDropdown.apply(null, arguments)
            },
          },
        },
        [
          _c("span", { staticClass: "helpers_hide_tablet" }, [
            _vm._v("Комментарий на"),
          ]),
          _c("span", { staticClass: "helpers_show_tablet" }, [_vm._v("На")]),
          _vm._v(" проверке\n  "),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "popover-style popover-style_primary popover-style_base popover-style_bottom-right vehicle-form__popover vehicle-form__popover_width_sssm",
      },
      [
        _c("div", { staticClass: "popover-style__content" }, [
          _c("div", { staticClass: "vehicle-form" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base",
              },
              [
                _vm._v(
                  "\n          Пока что комментарий видите только вы. Мы добавим его к обсуждению\n          после проверки, если он соответствует\n          "
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base",
                    attrs: {
                      href: "https://blog.onliner.by/kommentarii-v-novostnyx-razdelax-pravila",
                      target: "__blank",
                    },
                  },
                  [_vm._v("правилам")]
                ),
                _vm._v(".\n        "),
              ]
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }