
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { each, isEmpty, sortBy } from 'underscore';

  import { UrlService } from '@onlinerby/js-common';

  import { CommentsConfig, Mention } from '@/types/comments';

  const MarkdownIt = require('markdown-it');
  const mdEmoji = require('markdown-it-emoji');
  const mdIns = require('markdown-it-ins');

  const md = new MarkdownIt({
    breaks: true,
    linkify: true,
  });

  md.configure({
    components: {
      core: {
        rules: ['block', 'inline', 'linkify'],
      },
      block: {
        rules: ['blockquote', 'paragraph'],
      },
      inline: {
        rules: [
          'autolink',
          'strikethrough',
          'escape',
          'emphasis',
          'link',
          'newline',
        ],
      },
    },
  });

  md.use(mdEmoji);
  md.use(mdIns);

  @Component
  export default class MarkdownText extends Vue {
    @Prop() text!: string;
    @Prop() mentions!: Mention[];
    @Prop() config!: CommentsConfig;

    private now: number = Date.now();

    private get markDownText() {
      let text = this.text;

      text = this.renderMentionsToIds(text);
      text = this.renderMarkdown(text);
      text = this.renderIdsToMentions(text);

      return text;
    }

    private replaceAll(input: string, strReplace: string, strWith: string) {
      const esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      const reg = new RegExp(esc, 'ig');

      return input.replace(reg, strWith);
    }

    private renderMentionsToIds(text: string) {
      const mentions = (
        sortBy(this.mentions, (mention: Mention) => mention.placeholder.length) ||
        []
      ).reverse();
      const now = this.now;

      if (isEmpty(mentions)) {
        return text;
      }

      each(mentions, (value) => {
        text = this.replaceAll(text, value.placeholder, `@${value.id}_${now}`);
      });

      return text;
    }

    private renderMarkdown(text: string) {
      return md.render(text);
    }

    private renderIdsToMentions(text: string) {
      const mentions = this.mentions || [];
      const now = this.now;

      if (isEmpty(mentions)) {
        return text;
      }

      each(mentions, (mention) => {
        const link = UrlService.secureProjectUrl('profile', `user/${mention.id}`);

        text = this.replaceAll(
          text,
          `@${mention.id}_${now}`,
          `<a href="${link}" class="${this.config.mentions.linkClass}" target="_blank">${mention.name}</a>`,
        );
      });

      return text;
    }
  }
