
import { Component, Mixins } from 'vue-mixin-decorator';
import { Prop } from 'vue-property-decorator';

import RangeUnitFilterMixin from '@/mixins/filters/range-unit-filter';

@Component
export default class RangeUnitFilter extends Mixins<RangeUnitFilterMixin>(
  RangeUnitFilterMixin
) {
  @Prop() isCondensedAlter!: boolean;

  private get availableUnits() {
    return this.units.filter(item => item.id !== this.selectedUnit.id);
  }
}
