var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "button-style button-style_auxiliary button-style_base vehicle-form__button vehicle-form__button_time-additional",
      on: { click: _vm.onHistoryClick },
    },
    [
      _c(
        "popup",
        {
          ref: "historyPopup",
          attrs: {
            classes: [
              "popup-style_primary",
              " popup-style_middle",
              "vehicle-popup",
              "vehicle-popup_width_xxllm",
            ],
          },
        },
        [
          _c("div", { staticClass: "vehicle-form" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_condensed",
              },
              [_vm._v("\n        История изменений\n      ")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__overflow" }, [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__overflow-slice vehicle-form__overflow-slice_min-height_xll",
                },
                [
                  _c(
                    "table",
                    {
                      staticClass:
                        "vehicle-form__table vehicle-form__table_extended vehicle-form__table_bordered",
                    },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { attrs: { width: "33%" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-alter vehicle-form__description_nowrap",
                              },
                              [
                                _vm._v(
                                  "\n                    Время\n                  "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "33%" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-alter vehicle-form__description_nowrap",
                              },
                              [
                                _vm._v(
                                  "\n                    Статус\n                  "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { width: "33%" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-alter vehicle-form__description_nowrap vehicle-form__description_text_right",
                              },
                              [
                                _vm._v(
                                  "\n                    Инициатор\n                  "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.history, function (entry, index) {
                          return _c("review-status-history-entry", {
                            key: index,
                            attrs: { entry: entry },
                          })
                        }),
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }