import { debounce } from 'underscore';
import userService from '@/services/user-service';
import { HashMap } from '@/types/common';
import { IUser } from '@/interfaces/common';

const notificationsService: any = (window as any).notificationsService;
const eventDispatcher: any = notificationsService.getEventDispatcher();

class WebSocketService {
  private static instance: WebSocketService;
  private ownId!: string;

  private constructor() {
    userService.getMe((data: IUser) => {
      this.ownId = data.id;
    });
  }

  public initUserSubscription(userId: string) {
    notificationsService.addChannelGroup([
      {
        name: `$public:activity.user.${userId}`,
        alias: `activity:${userId}`,
      },
    ]);
  }

  public trackUserActivity(userId: string, callbacks: HashMap<Function>) {
    this.initUserSubscription(userId);

    const eventMap: HashMap<Function> = {
      'activity.updated': debounce(callbacks['activity.updated'], 1000),
    };

    if (eventDispatcher) {
      for (let key in eventMap) {
        eventDispatcher.subscribe(`activity:${userId}:${key}`, eventMap[key]);
      }
    }
  }

  public static getInstance() {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }

    return WebSocketService.instance;
  }
}

export default WebSocketService.getInstance();
