import camelCaseInterceptor from './interceptors/camel-case-interceptor';
import { stringify } from 'qs';
import { HashMap } from '@/types/common';
import { ISearchParams } from '@/interfaces/reviews/api';

const isMobile: boolean = ((window as any).Onliner as any).isMobile;
const isMobileSpecial: boolean =
  isMobile && window.matchMedia('(max-width:1000px)').matches;

const ApiService = (window as any).ApiService;

class VMApi {
  private static instance: any;
  private apiService: any;

  private constructor() {
    this.apiService = new ApiService({
      baseURL: '/sdapi/ab.api',
      errors: {
        silent: true,
      },
      cache: true,
    });

    camelCaseInterceptor(this.apiService);
  }

  public search(params = {}, callbacks = {}) {
    const config = {
      url: '/search/vehicles',
      method: 'get',
      params: { ...params, extended: true, limit: isMobileSpecial ? 25 : 50 },
      paramsSerializer: (params: {}) => {
        return stringify(params);
      },
      callbacks,
    };

    this.apiService.query(config);
  }

  public linkParse(url: string, callbacks = {}) {
    const config = {
      url: '/links/parse',
      method: 'post',
      data: {
        url,
      },
      callbacks,
    };

    this.apiService.query(config);
  }

  public getCounters(params = {}, callbacks = {}) {
    const config = {
      url: '/search/facets/vehicles',
      method: 'get',
      params,
      paramsSerializer: (params: {}) => {
        return stringify(params);
      },
      callbacks,
    };

    this.apiService.query(config);
  }

  public getGeneralCounters(callbacks = {}) {
    const config = {
      url: '/stats',
      method: 'get',
      callbacks,
    };

    this.apiService.query(config);
  }

  public getAdvert(id: number | string, callbacks = {}) {
    const config = {
      url: `/vehicles/${id}`,
      method: 'get',
      callbacks,
    };

    this.apiService.query(config);
  }

  public getAdvertPhones(id: number | string, callbacks = {}) {
    const config = {
      url: `/vehicles/${id}/phones`,
      method: 'get',
      callbacks,
    };

    this.apiService.query(config);
  }

  public getAdvertVin(id: number | string, callbacks = {}) {
    const config = {
      url: `/vehicles/${id}/vin`,
      method: 'get',
      callbacks,
    };

    this.apiService.query(config);
  }

  public getAdvertSimilar(id: number | string, callbacks = {}) {
    const config = {
      url: `/vehicles/${id}/similar`,
      method: 'get',
      callbacks,
    };

    this.apiService.query(config);
  }

  public getAdvertStats(id: number | string, callbacks = {}) {
    const config = {
      url: `/vehicles/${id}/stats`,
      method: 'get',
      callbacks,
    };

    this.apiService.query(config);
  }

  public getAdverts(id: number | string, params: HashMap<any>, callbacks = {}) {
    const config = {
      url: `/users/${id}/vehicles`,
      method: 'get',
      params,
      callbacks,
    };

    this.apiService.query(config);
  }

  public getSearchSchema(name: string, callbacks = {}) {
    const config = {
      url: `/schemas/${name}/search`,
      method: 'get',
      callbacks,
    };

    this.apiService.query(config);
  }

  public getUserReviews(
    id: number | string,
    params: HashMap<string>,
    callbacks = {},
  ) {
    const config = {
      url: `/users/${id}/reviews`,
      method: 'get',
      callbacks,
      params,
    };

    this.apiService.query(config);
  }

  public getDictionaries(callbacks: object) {
    const config = {
      url: '/dictionaries',
      method: 'get',
      callbacks,
    };

    this.apiService.query(config);
  }

  public getDictionaryByUrl(url: string, callbacks: object) {
    const config = {
      url,
      method: 'get',
      callbacks,
    };

    this.apiService.query(config);
  }

  public getDictionary(data: HashMap<number>, callbacks: object) {
    const parts = [];

    for (let key of Object.keys(data)) {
      parts.push(`/${key}/${data[key]}`);
    }

    const url = parts.join();
    const config = {
      url,
      method: 'get',
      callbacks,
    };

    this.apiService.query(config);
  }

  public addToBookmarks(id: string, callbacks: object) {
    const config = {
      url: '/bookmarks',
      data: {
        advertId: id,
      },
      method: 'post',
      callbacks,
    };

    this.apiService.query(config);
  }

  public removeFromBookmarks(id: string, callbacks: object) {
    const config = {
      url: `/bookmarks/${id}`,
      method: 'delete',
      data: {},
      callbacks,
    };

    this.apiService.query(config);
  }

  public openAdvert(id: string, callbacks: object) {
    //TODO: remove Content-Type
    const config = {
      url: `/vehicles/${id}/open`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      data: null,
      callbacks,
    };

    this.apiService.query(config);
  }

  public openAdverts(userId: string, ids: Array<string>, callbacks: object) {
    const data = { ids };

    const config = {
      url: `/users/${userId}/open-adverts`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
      callbacks,
    };

    this.apiService.query(config);
  }

  public upAdvert(id: string, callbacks: object) {
    //TODO: remove Content-Type
    const config = {
      url: `/vehicles/${id}/up`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      data: null,
      callbacks,
    };

    this.apiService.query(config);
  }

  public closeAdvert(id: string, closureReason: string, callbacks: object) {
    const data = closureReason ? { closureReason } : null;
    //TODO: remove Content-Type
    const config = {
      url: `/vehicles/${id}/close`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
      callbacks,
    };

    this.apiService.query(config);
  }

  public closeAdverts(
    userId: string,
    ids: Array<string>,
    closureReason: string,
    callbacks: object,
  ) {
    const data = closureReason ? { closureReason, ids } : { ids };

    const config = {
      url: `/users/${userId}/close-adverts`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
      callbacks,
    };

    this.apiService.query(config);
  }

  public createAdvert(data: HashMap<any>, callbacks: HashMap<Function>) {
    const config = {
      url: '/vehicles',
      method: 'post',
      data,
      callbacks,
    };

    this.apiService.query(config);
  }

  public updateAdvert(
    id: string,
    data: HashMap<any>,
    callbacks: HashMap<Function>,
  ) {
    const config = {
      url: `/vehicles/${id}`,
      method: 'patch',
      data,
      callbacks,
    };

    this.apiService.query(config);
  }

  public getReviewMarks(callbacks: HashMap<Function>) {
    const config = {
      url: '/schemas/review-marks',
      method: 'get',
      callbacks,
    };

    this.apiService.query(config);
  }

  public getReview(id: number | string, callbacks = {}) {
    const config = {
      url: `/reviews/${id}`,
      method: 'get',
      callbacks,
    };

    this.apiService.query(config);
  }

  public createReview(data: HashMap<any>, callbacks: HashMap<Function>) {
    const config = {
      url: '/reviews',
      method: 'post',
      data,
      callbacks,
    };

    this.apiService.query(config);
  }

  public updateReview(
    id: string,
    data: HashMap<any>,
    callbacks: HashMap<Function>,
  ) {
    const config = {
      url: `/reviews/${id}`,
      method: 'patch',
      data,
      callbacks,
    };

    this.apiService.query(config);
  }

  public openReview(id: number | string, callbacks = {}) {
    const config = {
      url: `/reviews/${id}/open`,
      method: 'post',
      callbacks,
    };

    this.apiService.query(config);
  }

  public closeReview(
    id: number | string,
    closureReason: string,
    callbacks = {},
  ) {
    const data = closureReason ? { closureReason } : null;

    const config = {
      url: `/reviews/${id}/close`,
      method: 'post',
      data,
      callbacks,
    };

    this.apiService.query(config);
  }

  public moderateReview(id: number | string, callbacks = {}) {
    const config = {
      url: `/reviews/${id}/moderate`,
      method: 'post',
      callbacks,
    };

    this.apiService.query(config);
  }

  public publishReview(id: number | string, callbacks = {}) {
    const config = {
      url: `/reviews/${id}/publish`,
      method: 'post',
      callbacks,
    };

    this.apiService.query(config);
  }

  public reviewComplain(
    id: string,
    data: HashMap<any>,
    callbacks: HashMap<Function>,
  ) {
    const config = {
      url: `/reviews/${id}/complaint`,
      method: 'post',
      data,
      callbacks,
    };

    this.apiService.query(config);
  }

  public getReviewStatusHistory(id: number | string, callbacks = {}) {
    const config = {
      url: `/reviews/${id}/status-history`,
      method: 'get',
      callbacks,
    };

    this.apiService.query(config);
  }

  public markReview(
    id: number | string,
    mark: string,
    callbacks: HashMap<Function>,
  ) {
    const config = {
      url: `/reviews/${id}/mark`,
      method: 'post',
      data: {
        mark,
      },
      callbacks,
    };

    this.apiService.query(config);
  }

  public getReviews(
    params: ISearchParams | undefined,
    callbacks: HashMap<Function>,
  ) {
    const config = {
      url: '/search/reviews',
      method: 'get',
      callbacks,
      params,
      paramsSerializer: (params: {}) => {
        return stringify(params);
      },
    };

    this.apiService.query(config);
  }

  public getSimilarReviews(id: number, callbacks: HashMap<Function>) {
    const config = {
      url: `/reviews/${id}/similar`,
      method: 'get',
      callbacks,
    };

    this.apiService.query(config);
  }

  public getReviewCounters(params = {}, callbacks = {}) {
    const config = {
      url: '/search/counters/reviews',
      method: 'get',
      params,
      paramsSerializer: (params: {}) => {
        return stringify(params);
      },
      callbacks,
    };

    this.apiService.query(config);
  }

  public getReviewsRating(params = {}, callbacks: HashMap<Function>) {
    const config = {
      url: '/reviews-rating',
      method: 'get',
      callbacks,
      params,
      paramsSerializer: (params: {}) => {
        return stringify(params);
      },
    };

    this.apiService.query(config);
  }

  public getReviewsRatingDetailed(params = {}, callbacks: HashMap<Function>) {
    const config = {
      url: '/reviews-stats',
      method: 'get',
      callbacks,
      params,
      paramsSerializer: (params: {}) => {
        return stringify(params);
      },
    };

    this.apiService.query(config);
  }

  public advertComplain(
    id: string,
    data: HashMap<any>,
    callbacks: HashMap<Function>,
  ) {
    const config = {
      url: `/vehicles/${id}/complaint`,
      method: 'put',
      data,
      callbacks,
    };

    this.apiService.query(config);
  }

  public getSearches(id: string, callbacks: HashMap<Function>) {
    const config = {
      url: `/users/${id}/filters`,
      params: {
        include: 'models,regions,generations,cities',
      },
      method: 'get',
      callbacks,
    };

    this.apiService.query(config);
  }

  public saveSearch(
    id: string,
    data: HashMap<any>,
    callbacks: HashMap<Function>,
  ) {
    const config = {
      url: `/users/${id}/filters`,
      method: 'post',
      data,
      callbacks,
    };

    this.apiService.query(config);
  }

  public deleteSearch(
    userId: string,
    id: string,
    callbacks: HashMap<Function>,
  ) {
    const config = {
      url: `/users/${userId}/filters/${id}`,
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
      },
      data: null,
      callbacks,
    };

    this.apiService.query(config);
  }

  public saveNotificationsSettings(
    id: string,
    data: HashMap<any>,
    callbacks: HashMap<Function>,
  ) {
    const config = {
      url: `/users/${id}/filters/notifications`,
      method: 'post',
      data,
      callbacks,
    };

    this.apiService.query(config);
  }

  public leasingBid(id: string, data: any, callbacks: HashMap<Function>) {
    const config = {
      url: `/vehicles/${id}/leasing-bid`,
      method: 'PUT',
      data,
      callbacks,
      errors: {
        silent: false,
        messages: {
          403: 'Объявление было закрыто, отправить заявку невозможно',
          404: 'Объявление было удалено, отправить заявку невозможно',
          409: 'Произошла ошибка, попробуйте отправить заявку еще раз',
          429: 'Вы превысили лимит на количество заявок, попробуйте отправить заявку на лизинг позже',
        },
      },
    };

    this.apiService.query(config);
  }

  public static getInstance() {
    if (!VMApi.instance) {
      VMApi.instance = new VMApi();
    }

    return VMApi.instance;
  }
}

export default VMApi.getInstance();
