import { UrlService } from '@onlinerby/js-common';

const ApiService = (window as any).ApiService;

class UploadApiService {
  private static instance: any;
  private apiService: any;

  private constructor() {
    this.apiService = new ApiService({
      baseURL: UrlService.secureProjectUrl('upload.api'),
      errors: {
        silent: true,
      },
    });
  }

  postImage(data = {}, callbacks = {}) {
    const config = {
      url: '/upload',
      method: 'post',
      data,
      authorization: {
        useTokenInQueryParams: true,
      },
      callbacks,
    };

    this.apiService.query(config);
  }

  getImageStatus(params: { id: string }, callbacks = {}) {
    const config = {
      url: `/upload/${params.id}`,
      method: 'get',
      callbacks,
    };

    this.apiService.query(config);
  }

  public static getInstance() {
    if (!UploadApiService.instance) {
      UploadApiService.instance = new UploadApiService();
    }

    return UploadApiService.instance;
  }
}

export default UploadApiService.getInstance();
