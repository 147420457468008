
  import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';

  import CommentMixin from '@/mixins/comments/comment';
  import MarkdownText from '@/components/comments/markdown-text.vue';
  import relativeDate from '@/filters/comments/format-relative-date';
  import CommentsListReply from '@/components/comments/comments-list-reply.vue';
  import CommentsListMoreReplies from '@/components/comments/comments-list-more-replies.vue';
  import CommentsReplyForm from '@/components/comments/comments-reply-form.vue';
  import PendingInfo from '@/components/comments/pending-info.vue';
  import { Comment } from '@/types/comments';
  import { sortBy } from 'underscore';

  @Component({
    components: {
      CommentsReplyForm,
      CommentsListReply,
      CommentsListMoreReplies,
      MarkdownText,
      PendingInfo,
    },
    filters: {
      relativeDate,
    },
  })
  export default class CommentsListBranch extends Mixins<CommentMixin>(
    CommentMixin,
  ) {
    @Prop() isReplyFormVisible!: boolean;
    @Prop() replyText!: string;

    private visibleRepliesCount: number = Math.min(
      this.replies.length,
      this.config.repliesVisible,
    );

    private get replies() {
      return sortBy(this.comment.replies, 'createdAt');
    }

    private get visibleReplies() {
      return this.comment.isBranchOpened
        ? this.replies
        : this.replies.slice(0, this.visibleRepliesCount);
    }

    private get isShowRepliesVisible() {
      return (
        !this.comment.isBranchOpened &&
        this.comment.replies?.length &&
        this.comment.replies.length > this.visibleReplies.length
      );
    }

    private onReplyCreated(comment: Comment) {
      this.$emit('reply-created', comment);
    }

    private onReplyMark({
      commentId,
      mark,
    }: {
      commentId: string;
      mark: string;
    }) {
      this.$emit('mark', {
        commentId,
        mark,
      });
    }

    public showAllReplies() {
      this.$set(this.comment, 'isBranchOpened', true);
    }

    public scrollToForm() {
      const element = (this.$refs.replyForm as Vue).$el as HTMLElement;

      (element as any).scrollIntoViewIfNeeded
        ? (element as any).scrollIntoViewIfNeeded(true)
        : element.scrollIntoView(true);
    }

    protected onShowAll() {
      this.showAllReplies();
    }

    protected onReplyDelete(comment: Comment) {
      this.$emit('delete', comment);
    }

    @Watch('isReplyFormVisible') onReplyFormVisible(value: boolean) {
      if (value) {
        this.showAllReplies();

        this.$nextTick(() => {
          this.scrollToForm();
        });
      }
    }
  }
