
import { Vue, Prop, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import Popup from '@/components/common/popup.vue';
import {
  Dictionary,
  Dictionaries,
  DictionaryItem,
} from '@/services/dictionaries-service';
import { VuexAction } from '@/types/functions';

const advertStore = namespace('advert');
const commonStore = namespace('common');

@Component({
  components: {
    Popup,
  },
})
export default class AdvertClosePopup extends Vue {
  private closureReasonId: number = 1;
  private text: string = '';
  private ids: Array<number> = [];
  private error: string = '';

  @Prop() advertId!: string;

  @advertStore.Action closeAdvert!: VuexAction;
  @commonStore.State dictionaries!: Dictionaries;
  @commonStore.Getter getDictionaryItem!: (
    type: string,
    key: number
  ) => DictionaryItem;

  public $refs!: {
    closeAdvertPopup: HTMLFormElement;
  };

  private get closureReasons() {
    if (!this.dictionaries.closureReason) {
      return [];
    }

    return [
      ...this.dictionaries.closureReason,
      {
        id: 0,
        name: 'Иное',
      },
    ];
  }

  private onCloseClick() {
    if (this.closureReasonId === 0 && !this.text) {
      this.error = 'Укажите причину закрытия';

      return;
    }

    const closureReason = this.closureReasonId
      ? this.getDictionaryItem('closureReason', this.closureReasonId).name
      : this.text;

    this.$emit('close', { closureReason, ids: this.ids });
  }

  public closePopup() {
    this.$refs.closeAdvertPopup.closePopup();
    this.ids = [];
  }

  public openPopup(ids: Array<number>) {
    this.ids = ids;
    this.$refs.closeAdvertPopup.openPopup();
  }

  private onTextInput() {
    this.error = '';
  }
}
