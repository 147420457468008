var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__parameter-item" }, [
    _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__parameter-part vehicle-form__parameter-part_1",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_small",
              class: _vm.isLabelPrimary
                ? "vehicle-form__description_primary"
                : "vehicle-form__description_other",
            },
            [_vm._v("\n        " + _vm._s(_vm.label) + "\n      ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__parameter-part vehicle-form__parameter-part_2",
        },
        [
          _c("div", { staticClass: "vehicle-form__rating" }, [
            _c("div", {
              staticClass: "rating rating_small vehicle-form__rating-stars",
              class: [`rating_${_vm.rating}`],
            }),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__rating-text" }, [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_small",
                  class: _vm.isValuePrimary
                    ? "vehicle-form__description_primary"
                    : "vehicle-form__description_other",
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.isValueFloat
                          ? _vm.value.toFixed(1).replace(".", ",")
                          : _vm.value
                      ) +
                      "\n          "
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }