
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IAdvert } from '@/interfaces/adverts';
import { VuexAction } from '@/types/functions';
import Popup from '@/components/common/popup.vue';

const advertStore = namespace('advert');
const userStore = namespace('user');

const reasons = [
  'Продавец указал ложные данные в объявлении',
  'В объявлении указаны оскорбительные комментарии или спам',
  'Существует опасность обмана или других противоправных действий',
  'Автомобиль уже продан - объявление осталось',
  'Иное',
];

@Component({
  components: {
    Popup,
  },
})
export default class AdvertActionComplain extends Vue {
  private subject: string = reasons[0];
  private text: string = '';
  private isProcessing: boolean = false;
  private error: string = '';
  private authError: string = '';

  @advertStore.State advert!: IAdvert;
  @advertStore.Action complain!: VuexAction;
  @userStore.Getter isAuthenticated!: boolean;

  public $refs!: {
    complainPopup: HTMLFormElement;
  };

  private get reasons() {
    return reasons;
  }

  private onComplainClick() {
    if (this.subject === 'Иное' && !this.text) {
      this.error = 'Укажите причину жалобы';

      return;
    }

    this.isProcessing = true;

    this.complain({
      id: this.advert.id,
      subject: this.subject,
      text: this.text,
      success: () => {
        this.$gtm.trackEvent({
          event: 'claim_ad',
          value: {
            reason: this.subject,
          },
        });
        this.closeComplainPopup();
      },
      error: ({ response }: any) => {
        if (response.status === 403 || response.status === 404) {
          this.authError = 'Произошла ошибка, попробуйте позже';
        }
      },
      complete: () => {
        this.isProcessing = false;
      },
    });
  }

  private openComplainPopup() {
    if (this.isAuthenticated) {
      this.subject = reasons[0];
      this.text = '';

      this.$refs.complainPopup.openPopup();
    } else {
      const profileAuth = (window as any).profileAuth as {
        showLoginModal: () => {};
      };

      profileAuth.showLoginModal();
    }
  }

  private closeComplainPopup() {
    this.$refs.complainPopup.closePopup();
  }

  private onSubjectChange() {
    this.error = '';
  }

  private onTextInput() {
    this.error = '';
  }
}
