var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.dictionaries
    ? _c(
        "div",
        {
          staticClass:
            "vehicle-form__filter-part vehicle-form__filter-part_1 js-container",
        },
        [
          _vm.isLoaded
            ? [
                _c("div", {
                  staticClass:
                    "vehicle-form__filter-toggle helpers_show_tablet",
                  on: { click: _vm.hideFilters },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__filter-overflow js-results-container",
                    class: {
                      "vehicle-form__filter-overflow_blocked":
                        _vm.anyDropdownOpened,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed-default helpers_show_tablet",
                      },
                      [_vm._v("\n          Фильтры\n        ")]
                    ),
                    _vm._v(" "),
                    _vm.isGameEnabled
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__mediabox vehicle-form__mediabox_tecno-additional",
                          },
                          [_vm._m(0)]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.schemaProperties
                      ? _c("search-filters", {
                          attrs: {
                            "filters-config": _vm.filtersConfig,
                            setFilter: _vm.setFilter,
                            stateCounters: _vm.stateCounters,
                            getFilter: _vm.getFilter,
                            filters: _vm.filters,
                            updateList: _vm.updateList,
                            updateCounters: _vm.updateCounters,
                            "remove-filter": _vm.removeFilter,
                            "schema-properties": _vm.schemaProperties,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.extendedFilters
                      ? _c(
                          "div",
                          {
                            staticClass: "vehicle-form__collapse",
                            class: {
                              "vehicle-form__collapse_opened":
                                _vm.isExtendedFiltersVisible,
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__collapse-body" },
                              [
                                _vm.schemaProperties
                                  ? _c("search-filters", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isExtendedFiltersVisible,
                                          expression:
                                            "isExtendedFiltersVisible",
                                        },
                                      ],
                                      attrs: {
                                        "filters-config": _vm.extendedFilters,
                                        setFilter: _vm.setFilter,
                                        stateCounters: _vm.stateCounters,
                                        getFilter: _vm.getFilter,
                                        filters: _vm.filters,
                                        updateList: _vm.updateList,
                                        udpateCounters: _vm.updateCounters,
                                        "remove-filter": _vm.removeFilter,
                                        "schema-properties":
                                          _vm.schemaProperties,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "vehicle-form__collapse-footer" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base",
                                        on: {
                                          click: _vm.toggleExtendedFilters,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__collapse-text vehicle-form__collapse-text_on",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Дополнительные параметры\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__collapse-text vehicle-form__collapse-text_off",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Скрыть\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("search-results", {
                  attrs: {
                    "page-name": _vm.pageName,
                    total: _vm.total,
                    isLoading: _vm.isLoading,
                    searches: _vm.searches,
                    filters: _vm.filters,
                    "clear-filters": _vm.clearFilters,
                    "update-list": _vm.updateList,
                    "update-counters": _vm.updateCounters,
                    "save-search": _vm.saveSearch,
                    "is-save-enabled": _vm.isSaveEnabled,
                  },
                }),
                _vm._v(" "),
                !_vm.isMobileSpecial
                  ? _c(
                      "div",
                      {
                        ref: "bannerContainer",
                        staticClass: "vehicle-form__filter-banner",
                        class: {
                          "vehicle-form__filter-banner_fixed": _vm.isFixed,
                          "vehicle-form__filter-banner_hanged": _vm.isHanged,
                        },
                      },
                      [_c("banner-web", { attrs: { "margin-left": "-41" } })],
                      1
                    )
                  : _vm._e(),
              ]
            : _c("div"),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__mediabox-flex" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__mediabox-part vehicle-form__mediabox-part_image",
        },
        [
          _c(
            "a",
            {
              staticClass: "vehicle-form__preview",
              attrs: {
                href: "https://auto.onliner.by/2022/11/01/razygryvaem-9-smartfonov-tecno-spark-8c-za-otzyvy-na-avto\n",
              },
            },
            [
              _c("div", {
                staticClass: "vehicle-form__image vehicle-form__image_tecno",
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__mediabox-part vehicle-form__mediabox-part_content",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_primary",
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "vehicle-form__link vehicle-form__link_primary-alter vehicle-form__link_nodecor",
                  attrs: {
                    href: "https://auto.onliner.by/2022/11/01/razygryvaem-9-smartfonov-tecno-spark-8c-za-otzyvy-na-avto\n",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_tiny vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-appendant",
                    },
                    [
                      _vm._v("\n                    Разыгрываем"),
                      _c("br"),
                      _vm._v(
                        "\n                    смартфоны TECNO\n                  "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_tiny",
                    },
                    [
                      _vm._v("\n                    Оставьте отзыв об авто"),
                      _c("br"),
                      _vm._v(
                        "\n                    в «Автобарахолке»\n                  "
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_other vehicle-form__description_wee",
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "vehicle-form__link vehicle-form__link_primary vehicle-form__link_wee",
                  attrs: {
                    href: "https://b2bblog.onliner.by/2022/10/18/pravila-reklamnoj-igry-vyigryvaj-s-onliner-30",
                  },
                },
                [
                  _vm._v(
                    "\n                  Правила рекламной игры\n                "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }