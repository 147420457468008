
import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { findWhere, isBoolean, isString, isArray } from 'underscore';

import plural from '@/filters/plural-form';
import { IEquipmentItem } from '@/interfaces/adverts';
import { Dictionaries } from '@/services/dictionaries-service';
import camelize from '@/filters/format-camelize';

const commonStore = namespace('common');

@Component({
  filters: {
    plural,
  },
})
export default class AdvertEquipmentSection extends Vue {
  @Prop() section!: {
    name: string;
    items: Array<IEquipmentItem>;
  };
  @Prop() isOpened!: boolean;

  @commonStore.State dictionaries!: Dictionaries;

  private get sectionItems() {
    const items: Array<string> = [];

    this.section.items.forEach((item: IEquipmentItem) => {
      const itemId = camelize(item.id);

      if (isBoolean(item.value)) {
        items.push(item.name);
      }

      if (isString(item.value)) {
        const dictionaryItem = findWhere(this.dictionaries[itemId], {
          id: item.value,
        });

        dictionaryItem && items.push(dictionaryItem!.name);
      }

      if (isArray(item.value)) {
        item.value.forEach(id => {
          const dictionaryItem = findWhere(this.dictionaries[itemId], {
            id,
          });

          dictionaryItem && items.push(dictionaryItem!.name);
        });
      }
    });

    return items;
  }
}
