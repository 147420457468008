var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__tag" }, [
    _c(
      "div",
      { staticClass: "vehicle-form__tag-list" },
      _vm._l(_vm.tags, function (tag) {
        return _c("search-tag", {
          key: tag.id,
          attrs: {
            tag: tag,
            filters: _vm.filters,
            "remove-filter": _vm.removeFilter,
            "update-list": _vm.updateList,
            "global-emit": _vm.getGlobalEmitByTag(tag),
          },
          on: { removed: _vm.onTagRemove },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }