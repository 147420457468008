import { IAdvertsState } from '@/interfaces/store';

const state: IAdvertsState = {
  isLoading: true,
  adverts: [],
  page: {},
  user: {},
  permissions: {},
  total: 0,
};

export default state;
