
  import { Vue, Prop, Component } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';

  import { ILeasingItem, IAdvert } from '@/interfaces/adverts';
  import background from '@/filters/format-background';
  import currency from '@/filters/format-currency';
  import { HashMap } from '@/types/common';
  import vmApi from '@/api/vm-api';
  import AdvertLeasingSuccessPopup from '@/components/advert/advert-leasing-success-popup.vue';
  import notificationsService from '@/services/notifications-service';

  const advertStore = namespace('advert');

  @Component({
    components: {
      AdvertLeasingSuccessPopup,
    },
    filters: {
      background,
      currency,
    },
  })
  export default class AdvertLeasingForm extends Vue {
    @Prop() isSpecific!: boolean;
    @Prop() isExclusive!: boolean;
    @Prop() leasing!: ILeasingItem;

    private phone: string = '';
    private name: string = '';
    private errors: HashMap<string> = {};
    private isProcessing: boolean = false;
    private isRulesAccepted: boolean = false;

    @advertStore.State advert!: IAdvert;

    private onNameChange() {
      this.clearError(`name`);
    }

    private onPhoneChange(event: InputEvent) {
      const value = (event!.target! as any).value;
      const hasPlus = value.indexOf('+') === 0;
      const digits = value.replace(/\D/g, '');

      this.phone = hasPlus ? `+${digits}` : digits;

      this.clearError('phone');
    }

    private clearError(name: string) {
      const errors: HashMap<string> = {};

      for (let key of Object.keys(this.errors)) {
        if (name !== key) {
          errors[key] = this.errors[key];
        }
      }

      this.errors = errors;
    }

    private onSubmit() {
      if (!this.isRulesAccepted) {
        notificationsService.error(
          'Для отправки заявки необходимо ваше согласие с Политикой обработки персональных данных и Пользовательским соглашением',
        );

        return;
      }

      this.isProcessing = true;

      vmApi.leasingBid(
        this.advert.id,
        {
          type: this.isExclusive ? 'exclusive' : 'common',
          company: this.leasing.company,
          name: this.name,
          phone: this.phone,
        },
        {
          success: () => {
            this.$emit('success');

            this.phone = '';
            this.name = '';

            (this.$refs.successPopup as AdvertLeasingSuccessPopup).openPopup();
          },
          error: ({ response }: any) => {
            if (response.status === 422) {
              this.errors = response.data.errors;
            }
          },
          complete: () => {
            this.isProcessing = false;
          },
        },
      );
    }
  }
