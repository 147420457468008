var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__row vehicle-form__row_extended-other" },
    [
      _c(
        "div",
        {
          staticClass: "vehicle-form__line vehicle-form__line_condensed-other",
        },
        [
          _c(
            "div",
            { staticClass: "vehicle-form__row vehicle-form__row_choose" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__group vehicle-form__group_width_xxssmm",
                },
                [
                  _c("div", { staticClass: "vehicle-form__field" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_width_full",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.priceAmount,
                              expression: "priceAmount",
                            },
                          ],
                          staticClass:
                            "input-style input-style_primary input-style_base vehicle-form__input vehicle-form__input_width_full",
                          class: { "input-style_error": _vm.error },
                          attrs: { type: "tel", maxlength: "8" },
                          domProps: { value: _vm.priceAmount },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.priceAmount = $event.target.value
                              },
                              function ($event) {
                                return _vm.clearError({ name: "price.amount" })
                              },
                            ],
                          },
                        }),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__group vehicle-form__group_width_auto",
                },
                [
                  _c("div", { staticClass: "vehicle-form__field" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "input-style__combo input-style__combo_stretch vehicle-form__input-combo vehicle-form__input-combo_width_full",
                      },
                      _vm._l(_vm.priceCurrencies, function (item) {
                        return _c(
                          "a",
                          {
                            key: item.id,
                            staticClass:
                              "button-style button-style_more button-style_base button-style_bordered vehicle-form__button vehicle-form__button_choose",
                            class: {
                              "button-style_active":
                                item.id === _vm.price.currency,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.setPriceCurrency(item.id)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            {
              staticClass:
                "vehicle-form__line vehicle-form__line_condensed-other",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_full",
                },
                [_vm._v(_vm._s(_vm.error))]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("Цена"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }