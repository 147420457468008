
  import { Mixins, Component } from 'vue-mixin-decorator';
  import { each, findIndex, first } from 'underscore';
  const draggable = require('vuedraggable');

  import ImageUploadService from '@/services/image-upload-service';
  import FieldMixin from '@/mixins/create/field';
  import IsMobileMixin from '@/mixins/common/is-mobile';
  import background from '@/filters/format-background';
  import { Image } from '@/types/common';
  import { getRandomId } from '@/services/random-service';
  import { projects } from '@/config/image-upload';

  interface IMixins extends FieldMixin, IsMobileMixin {}

  @Component({
    components: {
      draggable,
    },
    filters: {
      background,
    },
  })
  export default class StepExteriorImages extends Mixins<IMixins>(
    FieldMixin,
    IsMobileMixin,
  ) {
    private images: Array<Image> = [];
    private isDropZoneActive: boolean = false;
    private LIMIT = 50;

    private created() {
      this.data.images &&
        this.data.images.forEach((image: any) => {
          this.images.push({ images: image, status: 'processed' });
        });
    }

    private get mainIndex() {
      return findIndex(this.images, { status: 'processed' });
    }

    private getImageUrl(images) {
      return (
        images.preview || // base64 from user
        images['380x240'] || // For data from AB with old upload.api
        images.original // fallback
      );
    }

    private onFilesSelected(event: any) {
      this.processImageUpload(event.target.files);

      event.target.value = '';
    }

    private processImageUpload(files: Array<File>) {
      each(files, (file: File) => {
        const id: string = getRandomId();

        let message;
        let progress;

        const image =
          this.images.length < this.LIMIT
            ? {
                id,
                status: 'init',
              }
            : {
                id,
                status: 'error',
                errorText: 'Превышен лимит',
              };

        this.images.push(image);

        ImageUploadService.processUpload(
          file,
          {
            previewReady: (preview: { content: string }) => {
              const index = findIndex(this.images, { id });

              if (index === -1) {
                return;
              }

              const newImage =
                this.images[index].status === 'init'
                  ? {
                      id,
                      status: 'processing',
                      preview: preview.content,
                    }
                  : {
                      id,
                      status: 'error',
                      errorText: 'Превышен лимит',
                      preview: preview.content,
                    };

              this.images.splice(index, 1, newImage);
            },

            complete: (data: any) => {
              const index = findIndex(this.images, { id });

              if (index >= this.LIMIT) {
                return;
              }

              this.images.splice(index, 1, data);

              this.clearError({ name: 'images' });
              this.update();
            },

            error: ({ errorText }: { errorText: string }) => {
              const index = findIndex(this.images, { id });

              if (index >= this.LIMIT) {
                return;
              }

              this.images.splice(index, 1, {
                ...this.images[index],
                errorText: errorText || 'Не удалось загрузить изображение',
                status: 'error',
              });
            },
          },
          projects.ab,
        );
      });
    }

    private removePhoto(index: number) {
      this.images.splice(index, 1);

      this.update();
    }

    private onDrag() {
      this.update();
    }

    private onDragOver(event: any) {
      event.preventDefault();
      event.stopPropagation();

      if (this.isDropZoneActive) {
        return;
      }

      if (
        $(this.$refs.dropzoneContainer).find(event.fromElement).length ||
        $(this.$refs.dropzoneContainer).find(event.fromTo).length
      ) {
        return;
      }

      this.isDropZoneActive = true;
    }

    private onDragLeave(event: any) {
      event.preventDefault();
      event.stopPropagation();

      if (!this.isDropZoneActive) {
        return;
      }

      if (
        $(this.$refs.dropzoneContainer).find(event.fromElement).length ||
        $(this.$refs.dropzoneContainer).find(event.fromTo).length
      ) {
        return;
      }

      this.isDropZoneActive = false;
    }

    private onDrop(event: any) {
      event.preventDefault();
      event.stopPropagation();

      this.isDropZoneActive = false;

      this.processImageUpload(event.dataTransfer.files);

      event.target.value = '';
    }

    private makeMain(index: number) {
      const item = first(this.images.splice(index, 1));

      this.images.unshift(item!);

      this.update();
    }

    private update() {
      this.setField({ images: this.images.map(image => image.images) });
    }

    private get error() {
      return (this.errors.images || []).join('. ');
    }
  }
