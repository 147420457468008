
  import { Prop } from 'vue-property-decorator';
  import { Mixins, Component } from 'vue-mixin-decorator';

  import IsMobileMixin from '@/mixins/common/is-mobile';
  import { IPhoto } from '@/interfaces/common';
  import { HashMap } from '@/types/common';

  const $: any = (window as any).$;
  const modifiers = {
    fotoramaReady: 'fotorama-ready',
  };
  const selectors = {
    fotoramaStage: '.fotorama__stage__frame',
    fotoramaActive: '.fotorama__stage__frame.fotorama__active',
  };

  @Component
  export default class Gallery extends Mixins<IsMobileMixin>(IsMobileMixin) {
    @Prop() images!: IPhoto[];
    @Prop() imageKeys!: HashMap<string>;
    @Prop({
      default: true,
    })
    keyboard!: boolean;

    private $gallery?: any;
    private $galleryFotorama?: any;
    private $galleryFotoramaPagesBar?: any;

    mounted() {
      this.$gallery = $(this.$refs.gallery);
      this.$galleryFotorama = $(this.$refs.galleryFotorama);
      this.$galleryFotoramaPagesBar = $(this.$refs.galleryFotoramaPagesBar);

      this.initGalleryFotorama();
    }

    beforeDestroy() {
      const fotorama = this.$galleryFotorama.data('fotorama');

      fotorama && fotorama.destroy();

      $(selectors.fotoramaStage).panzoom('destroy');
    }

    private initGalleryFotorama() {
      if (!this.$galleryFotorama.length) return;

      const options: any = {
        arrows: !this.isMobileSpecial ? 'always' : false,
        nav: !this.isMobileSpecial ? 'thumbs' : 'dots',
        thumbwidth: 116,
        thumbheight: 72,
        thumbmargin: 8,
        margin: 4,
        allowfullscreen: true,
        keyboard: this.keyboard,
        ratio: 16 / 9,
        width: '100%',
      };

      if (this.isMobileSpecial) {
        options.glimpse = 10;
      }

      this.$nextTick(() => {
        this.bindEventsGalleryFotorama();

        this.$galleryFotorama.fotorama(options);
      });
    }

    private bindEventsGalleryFotorama() {
      this.$galleryFotorama.on('fotorama:ready', (e: any, fotorama: any) => {
        this.$gallery.addClass(modifiers.fotoramaReady);
      });

      this.$galleryFotorama.on(
        'fotorama:ready ' + 'fotorama:show',
        (e: any, fotorama: any) => {
          this.setGalleryFotoramaActivePage(fotorama);

          if (!this.isMobile || !$('html').hasClass('fullscreen')) {
            return;
          }

          $(selectors.fotoramaStage).panzoom('reset', false);
          $(selectors.fotoramaStage).panzoom('option', {
            disablePan: true,
          });
        },
      );

      this.$galleryFotorama.on(
        'fotorama:fullscreenenter ' + 'fotorama:showend',
        (e: any, fotorama: any) => {
          if (!this.isMobile || !$('html').hasClass('fullscreen')) {
            return;
          }

          $(selectors.fotoramaActive).panzoom({
            contain: 'invert',
            minScale: 1,
            increment: 0.75,
            disablePan: true,
            onZoom: function(e: any, panzoom: any, scale: any, opts: any) {
              $(selectors.fotoramaActive).panzoom('option', {
                disablePan: false,
              });
            },
            onPan: function(e: any, panzoom: any, x: any, y: any) {
              if (x !== 0 && y !== 0) return;
              $(selectors.fotoramaActive).panzoom('option', {
                disablePan: true,
              });
            },
          });
        },
      );

      this.$galleryFotorama.on(
        'fotorama:fullscreenexit',
        (e: any, fotorama: any) => {
          if (!this.isMobile) {
            return;
          }

          $(selectors.fotoramaStage).panzoom('reset', false);
          $(selectors.fotoramaStage).panzoom('option', {
            disablePan: true,
          });
        },
      );
    }

    private setGalleryFotoramaActivePage(fotorama: any) {
      const fotoramaActiveIndex = fotorama.activeIndex + 1;
      const fotoramaSize = fotorama.size;

      this.$galleryFotoramaPagesBar.text(
        fotoramaActiveIndex + '/' + fotoramaSize,
      );
    }
  }
