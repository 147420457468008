
import { Vue } from 'vue-property-decorator';
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { isEmpty } from 'underscore';

import { Dictionaries } from '@/services/dictionaries-service';
import { VuexAction } from '@/types/functions';
import { HashMap } from '@/types/common';
import { IUser } from '@/interfaces/common';
import GtmMixin from '@/mixins/common/gtm';
import Jivosite from '@/components/common/jivosite.vue';
import { socialIcon } from '@/config/urls';

const commonStore = namespace('common');
const createStore = namespace('create');
const userStore = namespace('user');

@Component({
  components: {
    Jivosite,
  },
  metaInfo(): any {
    return {
      title: 'Создать объявление - Автобарахолка Onlíner',
      meta: [
        {
          name: 'og:image',
          content: socialIcon,
        },
      ],
    };
  },
})
export default class CreatePage extends Mixins<GtmMixin>(GtmMixin) {
  private beforeEach!: Function;

  @commonStore.State dictionaries!: Dictionaries;
  @createStore.State steps!: HashMap<any>;
  @createStore.Action setStep!: VuexAction;
  @createStore.Action setMode!: VuexAction;
  @createStore.Action setField!: VuexAction;
  @userStore.State currentUser!: IUser;

  created() {
    this.checkStep();
    this.setMode({ mode: 'create' });
    this.setStep({
      tech: 'current',
      exterior: 'pristine',
      cost: 'pristine',
      contacts: 'pristine',
    });
    this.updateGtm();

    this.beforeEach = this.$router.beforeEach((to, from, next) => {
      if (this.steps[to.meta.stepName] !== 'edit') {
        this.setStep({ [to.meta.stepName]: 'current' });
      }

      next();
    });
  }

  destroyed() {
    this.beforeEach();
  }

  private get isLoading() {
    return isEmpty(this.dictionaries);
  }

  private checkStep() {
    if (this.$route.name !== 'create-tech') {
      this.$router.replace({ name: 'create-tech' });
    }
  }

  private updateGtm() {
    this.setPageData({
      user_id: this.currentUser ? this.currentUser.id.toString() : '',
    });

    this.$gtm.trackEvent({
      event: 'content-view',
      'content-view-name': 'ad_insertion',
    });
  }
}
