var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "vehicle-form__description vehicle-form__description_small vehicle-form__description_primary vehicle-form__description_condensed-other",
      class: [
        {
          pro: "vehicle-form__description_plus",
          con: "vehicle-form__description_minus",
        }[_vm.mark],
      ],
    },
    [_vm._v("\n  " + _vm._s(_vm.label) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }