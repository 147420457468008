
import { Watch } from 'vue-property-decorator';
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { isEqual, contains, find, findWhere } from 'underscore';

import dictionariesService, {
  Dictionaries,
  DictionaryItem,
  Dictionary,
} from '@/services/dictionaries-service';
import FieldMixin from '@/mixins/create/field';
import { VuexAction } from '@/types/functions';
import { HashMap } from '@/types/common';
import background from '@/filters/format-background';
import dictionariesMap from '@/config/dictionaries-map';

const commonStore = namespace('common');
const createStore = namespace('create');

@Component({
  filters: {
    background,
  },
})
export default class StepTechGeneration extends Mixins<FieldMixin>(FieldMixin) {
  private isAllVisible: boolean = false;

  @commonStore.State dictionaries!: Dictionaries;
  @commonStore.Action setDictionary!: VuexAction;
  @createStore.State data!: HashMap<any>;

  private created() {
    this.updateGenerations();
  }

  private get urlData() {
    return {
      manufacturer: this.data.manufacturerId,
      model: this.data.modelId,
    };
  }

  private get url() {
    return dictionariesService.buildUrl('model', this.urlData);
  }

  private get generations() {
    const dictionary = this.dictionaries[this.url];

    if (!dictionary) {
      return null;
    }

    const year = this.data.specs.year;

    return dictionary.filter((item: any) => {
      return (
        (year >= item.year.from && year <= item.year.to) ||
        (year >= item.year.from && item.year.to === null)
      );
    });
  }

  private get year() {
    return this.data.specs?.year;
  }

  private get modelName() {
    const model = findWhere(this.dictionaries.model, { id: this.data.modelId });

    return model?.name;
  }

  private get generation() {
    return this.data.generationId;
  }

  private set generation(value: number | null) {
    this.clearError({ name: 'generationId' });
    this.setField({ generationId: value });
  }

  private get error() {
    return (this.errors['generationId'] || []).join('. ');
  }

  private initDefaultGeneration() {
    const generations = this.generations;

    if (generations && generations.length === 1) {
      this.generation = parseInt(this.generations![0].id);
    } else if (
      generations &&
      this.generation &&
      !find(generations, { id: this.generation })
    ) {
      this.generation = null;
    }
  }

  @Watch('modelName')
  private updateGenerations() {
    if (this.dictionaries[this.url]) {
      return;
    }

    dictionariesService.getDictionary(
      'model',
      this.urlData,
      (data: Dictionary) => {
        this.setDictionary({
          rootName: 'generation',
          name: this.url,
          dictionary: data,
        });

        this.initDefaultGeneration();
      }
    );
  }

  @Watch('generations')
  private onGenerationsChange(generations: Dictionary, oldValue: Dictionary) {
    if (isEqual(generations, oldValue)) {
      return;
    }

    this.initDefaultGeneration();
  }
}
