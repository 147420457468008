var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__panel-item" }, [
    _c(
      "div",
      {
        staticClass:
          "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed-default",
      },
      [_vm._v("\n    Контактные данные\n  ")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "vehicle-form__person" }, [
      _c("div", { staticClass: "vehicle-form__person-list" }, [
        _c(
          "div",
          { staticClass: "vehicle-form__person-item" },
          [
            _c("div", { staticClass: "vehicle-form__preview" }, [
              _c("div", {
                staticClass: "vehicle-form__image",
                style: _vm._f("background")(_vm.avatar),
              }),
              _vm._v(" "),
              _vm.advert.author.onlineStatus &&
              _vm.advert.author.onlineStatus.isOnline
                ? _c("div", {
                    staticClass:
                      "vehicle-form__status vehicle-form__status_online",
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.seller.type === "individual"
              ? [
                  _vm.advert.permissions.viewProfile
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                          attrs: { target: "_blank", href: _vm.profileLink },
                        },
                        [_vm._v(_vm._s(_vm.seller.name))]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.seller.name) +
                              "\n          "
                          ),
                        ]
                      ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.seller.type === "dealer"
              ? [
                  _vm.advert.permissions.viewProfile
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                          attrs: { target: "_blank", href: _vm.profileLink },
                        },
                        [_vm._v("Автодилер «" + _vm._s(_vm.seller.name) + "»")]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                        },
                        [
                          _vm._v(
                            "\n            Автодилер «" +
                              _vm._s(_vm.seller.name) +
                              "»\n          "
                          ),
                        ]
                      ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.seller.type === "autohaus"
              ? [
                  _vm.advert.permissions.viewProfile
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                          attrs: { target: "_blank", href: _vm.profileLink },
                        },
                        [_vm._v("Автохаус «" + _vm._s(_vm.seller.name) + "»")]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                        },
                        [
                          _vm._v(
                            "\n            Автохаус «" +
                              _vm._s(_vm.seller.name) +
                              "»\n          "
                          ),
                        ]
                      ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.seller.type === "individual"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny vehicle-form__description_condensed-other",
                  },
                  [_vm._v("\n          Частное лицо\n        ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.seller.type === "dealer" || _vm.seller.type === "autohaus"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny vehicle-form__description_condensed-other",
                  },
                  [
                    _vm._v(
                      "\n          УНП: " +
                        _vm._s(_vm.seller.unp) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.ipData
              ? [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny vehicle-form__description_condensed-other",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.ipData.country) +
                          ", " +
                          _vm._s(_vm.ipData.city) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny vehicle-form__description_condensed-other",
                    },
                    [
                      _vm._v(
                        "\n            IP: " +
                          _vm._s(_vm.ipData.ip) +
                          "\n          "
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base-alter vehicle-form__link_enter",
                    attrs: {
                      to: {
                        name: "active-adverts",
                        params: { id: _vm.author.id },
                      },
                    },
                  },
                  [_vm._v("Все объявления продавца")]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "tooltip-style tooltip-style_error tooltip-style_small tooltip-style_bottom-left tooltip-style_text_left tooltip-style_noarrow tooltip-style_visible vehicle-form__tooltip vehicle-form__tooltip_width_full",
      },
      [
        _c("div", { staticClass: "tooltip-style__content" }, [
          _c("div", { staticClass: "vehicle-form" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_info-special",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_font-weight_semibold vehicle-form__description_condensed",
                  },
                  [_vm._v("\n            Советы по безопасности\n          ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                  },
                  [
                    _vm._v(
                      "\n            Не вносите предоплату за просмотр\n            автомобиля и заключайте сделки\n            при личной встрече\n          "
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }