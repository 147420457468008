
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import { InputEvent } from '@/types/events';
import { Dictionaries } from '@/services/dictionaries-service';
import FieldMixin from '@/mixins/create/field';
import IsMobileMixin from '@/mixins/common/is-mobile';
import StepCostVinInfo from '@/components/create/step-cost/step-cost-vin-info.vue';

const commonStore = namespace('common');

interface IMixins extends FieldMixin, IsMobileMixin {}

@Component({
  components: {
    StepCostVinInfo,
  },
})
export default class StepCostVin extends Mixins<IMixins>(
  FieldMixin,
  IsMobileMixin
) {
  @commonStore.State dictionaries!: Dictionaries;

  private get specs() {
    return this.data.specs || {};
  }

  private get state() {
    return this.specs.state;
  }

  private get vin() {
    return this.specs.vin;
  }

  private set vin(value: string) {
    const vin = this.getValue(value);

    this.setField({ specs: { ...this.specs, vin } });
    this.clearError({ name: 'specs.vin' });
  }

  private onInput(event: InputEvent) {
    event.target.value = this.getValue(event.target.value);
  }

  private get error() {
    return (this.errors['specs.vin'] || []).join('. ');
  }

  private getValue(value: string) {
    return value
      .substring(0, 17)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, '')
      .replace(/[OIQ]/g, '');
  }
}
