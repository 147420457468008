import { UserData } from '@/types/websocket';
import camelCaseInterceptor from './interceptors/camel-case-interceptor';

const ApiService = (window as any).ApiService;

class BroadcastingApi {
  private static instance: BroadcastingApi;
  private apiService: any;

  public constructor() {
    this.apiService = new ApiService({
      baseURL: '/sdapi/notifications',
      headers: {
        'Content-Type': 'application/json',
      },
      errors: {
        silent: true,
      },
    });

    camelCaseInterceptor(this.apiService);
  }

  getConnectionSettings(userData: UserData, callbacks = {}) {
    var config = {
      url: '/broadcasting/token',
      method: 'post',
      callbacks: callbacks,
    };

    if (!userData.userId) {
      Object.assign(config, {
        data: {
          session_id: userData.sessionId,
        },
      });
    }

    this.apiService.query(config);
  }

  auth(
    data: {
      channels: string[];
      client: string;
    },
    callbacks = {},
  ) {
    var config = {
      url: '/broadcasting/auth',
      method: 'post',
      data,
      callbacks: callbacks,
    };

    this.apiService.query(config);
  }

  public static getInstance() {
    if (!BroadcastingApi.instance) {
      BroadcastingApi.instance = new BroadcastingApi();
    }

    return BroadcastingApi.instance;
  }
}

export default BroadcastingApi.getInstance();
