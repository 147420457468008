var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-show" },
    [
      _c(
        "div",
        { staticClass: "vehicle-form" },
        [
          _vm.isReviewNotFound
            ? _c("review-not-found")
            : _vm.isReviewEmpty
            ? _c("advert-stub")
            : _vm._e(),
          _vm._v(" "),
          _vm.review
            ? _c(
                "div",
                {
                  staticClass: "vehicle-form__card vehicle-form__card_specific",
                },
                [
                  _c(
                    "div",
                    { staticClass: "vehicle-form__card-flex" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__card-part vehicle-form__card-part_nav",
                        },
                        [
                          _c("review-breadcrumbs", {
                            attrs: { review: _vm.review },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("review-header", {
                        attrs: {
                          review: _vm.review,
                          "comments-counter": _vm.commentsCounter,
                        },
                        on: { "comments-click": _vm.onCommentsClick },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__card-part vehicle-form__card-part_specification",
                        },
                        [
                          _c("div", { staticClass: "vehicle-form__feature" }, [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__feature-list" },
                              [
                                _c("review-specs", {
                                  attrs: { review: _vm.review },
                                }),
                                _vm._v(" "),
                                _c("review-author", {
                                  attrs: { review: _vm.review },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__card-part vehicle-form__card-part_media",
                        },
                        [
                          _c("review-manage", {
                            attrs: { review: _vm.review },
                            on: {
                              opened: _vm.onReviewChange,
                              closed: _vm.onReviewChange,
                              moderated: _vm.onReviewChange,
                              published: _vm.onReviewChange,
                            },
                          }),
                          _vm._v(" "),
                          _c("review-status", {
                            attrs: { review: _vm.review },
                          }),
                          _vm._v(" "),
                          _vm.isGameEnabled
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__mediabox vehicle-form__mediabox_tecno",
                                },
                                [_vm._m(0)]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isPhotosVisible
                            ? _c("gallery", {
                                attrs: {
                                  images: _vm.review.images,
                                  imageKeys: {
                                    thumbnail: "116x72",
                                    imageDesktop: "946x588",
                                    imageMobile: "288x216",
                                    fullscreen: "original",
                                  },
                                  keyboard: false,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__card-part vehicle-form__card-part_info",
                        },
                        [
                          _c("div", { staticClass: "vehicle-form__panel" }, [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__panel-list" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "vehicle-form__panel-item" },
                                  [
                                    _c("review-text", {
                                      attrs: { text: _vm.review.text },
                                    }),
                                    _vm._v(" "),
                                    _vm._l(_vm.review.videos, function (video) {
                                      return _c("review-video", {
                                        key: video.id,
                                        attrs: { "video-id": video.id },
                                      })
                                    }),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "vehicle-form__panel-item" },
                                  [
                                    _c("review-rating", {
                                      attrs: { review: _vm.review },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "vehicle-form__panel-item" },
                                  [
                                    _vm.reviewsRating
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "vehicle-form__grade",
                                          },
                                          [
                                            _c("rating", {
                                              attrs: {
                                                "reviews-rating":
                                                  _vm.reviewsRating,
                                                "is-counter-clickable": true,
                                                manufacturer:
                                                  _vm.review.manufacturer,
                                                model: _vm.review.model,
                                                generation:
                                                  _vm.review.generation,
                                                hide: { divider: true },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "vehicle-form__benefit" },
                                      [
                                        _c("review-benefit", {
                                          attrs: { review: _vm.review },
                                          on: {
                                            "marks-updated": _vm.onMarksUpdated,
                                          },
                                        }),
                                        _vm._v(" "),
                                        !_vm.isOwnReview
                                          ? _c("review-action-complain", {
                                              attrs: { review: _vm.review },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("review-similar", {
                                      attrs: { review: _vm.review },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("review-proposals", {
                                  attrs: { review: _vm.review },
                                }),
                                _vm._v(" "),
                                _c("review-comments", {
                                  ref: "comments",
                                  attrs: {
                                    review: _vm.review,
                                    "current-user": _vm.currentUser,
                                  },
                                  on: {
                                    "counter-update":
                                      _vm.onCommentsCounterUpdate,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.ld
        ? _c("json-ld", { attrs: { ld: _vm.ld, type: ["Product", "Car"] } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__mediabox-flex" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__mediabox-part vehicle-form__mediabox-part_image",
        },
        [
          _c(
            "a",
            {
              staticClass: "vehicle-form__preview",
              attrs: {
                href: "https://auto.onliner.by/2022/11/01/razygryvaem-9-smartfonov-tecno-spark-8c-za-otzyvy-na-avto\n",
              },
            },
            [
              _c("div", {
                staticClass: "vehicle-form__image vehicle-form__image_tecno",
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__mediabox-part vehicle-form__mediabox-part_content",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_extended-complementary",
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "vehicle-form__link vehicle-form__link_primary-alter vehicle-form__link_nodecor",
                  attrs: {
                    href: "https://auto.onliner.by/2022/11/01/razygryvaem-9-smartfonov-tecno-spark-8c-za-otzyvy-na-avto\n",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed-other",
                    },
                    [
                      _vm._v(
                        "\n                        Разыгрываем смартфоны TECNO\n                      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_extended-complementary",
                    },
                    [
                      _vm._v(
                        "\n                        Оставьте отзыв об авто в «Автобарахолке»\n                      "
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "vehicle-form__link vehicle-form__link_primary vehicle-form__link_small",
                  attrs: {
                    href: "https://b2bblog.onliner.by/2022/10/18/pravila-reklamnoj-igry-vyigryvaj-s-onliner-30",
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    "\n                      Правила рекламной игры\n                    "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }