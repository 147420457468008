var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__row vehicle-form__row_extended-other" },
    [
      _c(
        "div",
        {
          staticClass: "vehicle-form__line vehicle-form__line_condensed-other",
        },
        [
          _c("div", { staticClass: "vehicle-form__group" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__field" }, [
              _vm.generations && _vm.generations.length
                ? _c(
                    "ul",
                    {
                      staticClass:
                        "vehicle-form__checkbox-list vehicle-form__checkbox-list_generation",
                    },
                    _vm._l(_vm.generations, function (item) {
                      return _c(
                        "li",
                        {
                          key: item.id,
                          staticClass:
                            "vehicle-form__checkbox-item vehicle-form__checkbox-item_generation",
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "vehicle-form__checkbox-label" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "i-checkbox i-checkbox_radio i-checkbox_specific vehicle-form__checkbox vehicle-form__checkbox_base",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.generation,
                                        expression: "generation",
                                      },
                                    ],
                                    staticClass: "i-checkbox__real",
                                    attrs: {
                                      type: "radio",
                                      name: "generation",
                                    },
                                    domProps: {
                                      value: item.id,
                                      checked: _vm._q(_vm.generation, item.id),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.generation = item.id
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "i-checkbox__faux",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__checkbox-text",
                                    },
                                    [
                                      item.images && item.images["256x192"]
                                        ? _c("div", {
                                            staticClass:
                                              "vehicle-form__checkbox-image vehicle-form__checkbox-image_generation",
                                            style: _vm._f("background")(
                                              item.images["256x192"]
                                            ),
                                          })
                                        : _c("div", {
                                            staticClass:
                                              "vehicle-form__checkbox-image vehicle-form__checkbox-image_generation",
                                          }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__checkbox-sign",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(item.name) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__checkbox-value",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(item.year.from) +
                                              " —\n                    " +
                                              _vm._s(
                                                item.year.to ||
                                                  "по настоящее время"
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm.generations && !_vm.generations.length
                ? _c("div", { staticClass: "vehicle-form__callout" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_small vehicle-form__description_info",
                      },
                      [
                        _vm._v(
                          "\n            В " +
                            _vm._s(_vm.year) +
                            " году модель " +
                            _vm._s(_vm.modelName) +
                            " не выпускалась.\n            Проверьте правильность введенных данных.\n          "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            {
              staticClass:
                "vehicle-form__line vehicle-form__line_condensed-other",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_full",
                },
                [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("\n              Поколение\n            "),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }