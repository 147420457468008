
  import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';

  import { Review } from '@/types/review';
  import StateServiceMixin from '@/mixins/state-service';

  @Component
  export default class ReviewBreadcrumbs extends Mixins<StateServiceMixin>(
    StateServiceMixin,
  ) {
    @Prop() review!: Review;

    private get allCarsUrl() {
      return '/reviews';
    }

    private get manufacturer() {
      return this.review.manufacturer;
    }

    private get model() {
      return this.review.model;
    }

    private get generation() {
      return this.review.generation || {};
    }

    private get manufacturerUrl() {
      return `/reviews/${this.manufacturer.slug}`;
    }

    private get modelUrl() {
      return `/reviews/${this.manufacturer.slug}/${this.model.slug}`;
    }

    private get generationUrl() {
      return `/reviews/${this.manufacturer.slug}/${this.model.slug}/${this.generation.slug}`;
    }
  }
