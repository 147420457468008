import { Module } from 'vuex';
import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import { ICommonState, IRootState } from '@/interfaces/store';

const namespaced: boolean = true;
const module: Module<ICommonState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default module;
