
  import { HashMap } from '@onlinerby/js-common';
  import { Component } from 'vue-property-decorator';
  import { Mixins } from 'vue-mixin-decorator';
  import { namespace } from 'vuex-class';
  import { isEmpty } from 'underscore';

  import StepWrapper from '@/components/common/step-wrapper.vue';
  import StepTechHeader from '@/components/reviews/create/step-tech/step-tech-header.vue';
  import StepTechHeaderMobile from '@/components/reviews/create/step-tech/step-tech-header-mobile.vue';
  import StepTechManufacturer from '@/components/reviews/create/step-tech/step-tech-manufacturer.vue';
  import StepTechManufacturerMobile from '@/components/reviews/create/step-tech/step-tech-manufacturer-mobile.vue';
  import StepTechModel from '@/components/reviews/create/step-tech/step-tech-model.vue';
  import StepTechModelMobile from '@/components/reviews/create/step-tech/step-tech-model-mobile.vue';
  import StepTechYear from '@/components/reviews/create/step-tech/step-tech-year.vue';
  import StepTechYearMobile from '@/components/reviews/create/step-tech/step-tech-year-mobile.vue';
  import StepTechGeneration from '@/components/reviews/create/step-tech/step-tech-generation.vue';
  import StepTechBodyType from '@/components/reviews/create/step-tech/step-tech-body-type.vue';
  import StepTechBodyTypeMobile from '@/components/reviews/create/step-tech/step-tech-body-type-mobile.vue';
  import StepTechEngine from '@/components/reviews/create/step-tech/step-tech-engine.vue';
  import StepTechDrivetrain from '@/components/reviews/create/step-tech/step-tech-drivetrain.vue';
  import StepTechTransmission from '@/components/reviews/create/step-tech/step-tech-transmission.vue';
  import StepDescriptionHeader from '@/components/reviews/create/step-description/step-description-header.vue';

  import StepMixin from '@/mixins/reviews/create/step';
  import StepMutationDataMixin from '@/mixins/reviews/create/step-mutation-data';
  import stepsErrorMap from '@/config/reviews/steps-error-map';
  import { VuexAction } from '@/types/functions';

  const createStore = namespace('reviews/create');

  interface IMixins extends StepMixin, StepMutationDataMixin {}

  @Component({
    components: {
      StepWrapper,
      StepTechHeader,
      StepTechHeaderMobile,
      StepTechManufacturer,
      StepTechManufacturerMobile,
      StepTechModel,
      StepTechModelMobile,
      StepTechYear,
      StepTechYearMobile,
      StepTechGeneration,
      StepTechBodyType,
      StepTechBodyTypeMobile,
      StepTechEngine,
      StepTechDrivetrain,
      StepTechTransmission,
      StepDescriptionHeader,
    },
  })
  export default class StepTech extends Mixins<IMixins>(
    StepMixin,
    StepMutationDataMixin,
  ) {
    protected step: string = 'tech';
    private errorMapTech = stepsErrorMap[this.step];

    @createStore.State statusesByStep!: HashMap<string>;
    @createStore.State mode!: string;
    @createStore.Action setStatusesByStep!: VuexAction;

    private get componentStepTechManufacturer() {
      return this.isMobileSpecial
        ? 'step-tech-manufacturer-mobile'
        : 'step-tech-manufacturer';
    }

    private get componentStepTechYear() {
      return this.isMobileSpecial ? 'step-tech-year-mobile' : 'step-tech-year';
    }

    private onEngineTypeChange() {
      this.clearError(this.errorMapTech.engineType);
      this.clearError(this.errorMapTech.engineCapacity);
      this.resetSecondaryParameters();
    }

    private onEngineCapacityChange() {
      this.clearError(this.errorMapTech.engineCapacity);
    }

    private onEnginePowerChange() {
      this.clearError(this.errorMapTech.enginePower);
    }

    private onEnginePowerUnitChange() {
      this.clearError(this.errorMapTech.enginePowerUnit);
    }

    private onEngineTorqueChange() {
      this.clearError(this.errorMapTech.engineTorque);
    }

    private resetSecondaryParameters() {
      this.onSecondParametersChange(null);
    }

    protected validate() {
      const genDictionary = this.dictionaries[this.urlSelectedModel];
      const hasGenerations = genDictionary && genDictionary.length;

      if (hasGenerations && !this.data.generationId) {
        this.setErrors({
          [this.errorMapTech.generation]: ['Укажите поколение'],
        });
      }

      if (!this.data.specs.engine || !this.data.specs.engine.type) {
        this.setErrors({
          [this.errorMapTech.engineType]: ['Укажите тип двигателя'],
        });
      } else if (
        this.data.specs.engine.type !== 'electric' &&
        !this.data.specs.engine.capacity
      ) {
        this.setErrors({
          [this.errorMapTech.engineCapacity]: ['Укажите объем двигателя'],
        });
      }

      if (!this.data.manufacturerId) {
        this.setErrors({
          [this.errorMapTech.manufacturer]: ['Укажите марку'],
        });
      } else if (!this.data.modelId) {
        this.setErrors({
          [this.errorMapTech.model]: ['Укажите модель'],
        });
      }

      if (!this.data.specs.year) {
        this.setErrors({
          [this.errorMapTech.year]: ['Укажите год выпуска'],
        });
      }

      if (!this.data.specs.bodyType) {
        this.setErrors({
          [this.errorMapTech.bodyType]: ['Укажите тип кузова'],
        });
      }

      if (!this.data.specs.drivetrain) {
        this.setErrors({
          [this.errorMapTech.drivetrain]: ['Укажите привод'],
        });
      }

      if (!this.data.specs.transmission) {
        this.setErrors({
          [this.errorMapTech.transmission]: ['Укажите тип коробки передач'],
        });
      }

      return isEmpty(this.errors);
    }

    public scrollToNext() {
      (this.$refs.next as HTMLElement).scrollIntoView();
    }
  }
