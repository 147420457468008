
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { findWhere } from 'underscore';

import FieldMixin from '@/mixins/create/field';
import DropdownMixin from '@/mixins/common/dropdown';
import dictionariesService from '@/services/dictionaries-service';
import { Dictionaries, Dictionary } from '@/services/dictionaries-service';
import { VuexAction } from '@/types/functions';

interface IMixins extends FieldMixin, DropdownMixin {}

const commonStore = namespace('common');

@Component
export default class StepContactsLocationCountry extends Mixins<IMixins>(
  DropdownMixin,
  FieldMixin
) {
  @commonStore.State dictionaries!: Dictionaries;
  @commonStore.Action setDictionary!: VuexAction;

  private open() {
    this.openDropdown();

    document.body.classList.add('body_state-dropdown-style_visible');
  }

  private close() {
    this.closeDropdown();

    document.body.classList.remove('body_state-dropdown-style_visible');
  }

  private toggle() {
    const isVisible = this.toggleDropdown();

    isVisible
      ? document.body.classList.add('body_state-dropdown-style_visible')
      : document.body.classList.remove('body_state-dropdown-style_visible');
  }

  protected get country() {
    return this.data.country;
  }

  protected set country(value) {
    this.clearError({ name: 'country' });
    this.clearError({ name: 'region' });
    this.clearError({ name: 'cityId' });
    this.setField({ country: value });
    this.setField({ region: null });
    this.setField({ cityId: null });
  }

  private get countryName() {
    const country = findWhere(this.dictionaries.country, {
      id: this.data.country,
    });

    if (!country) {
      return 'Страна';
    }

    return country.name;
  }

  private get urlData() {
    return {
      country: this.country,
    };
  }

  private get url() {
    return dictionariesService.buildUrl('country', this.urlData);
  }

  private onChange() {
    this.close();

    this.url &&
      dictionariesService.getDictionary(
        'country',
        this.urlData,
        (data: Dictionary) => {
          this.setDictionary({
            rootName: 'region',
            name: this.url,
            dictionary: data,
          });
        }
      );
  }
}
