
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import purify from 'vue-dompurify-html';

import { IAdvert } from '@/interfaces/adverts';

const advertStore = namespace('advert');

@Component
export default class AdvertDescription extends Vue {
  @advertStore.State advert!: IAdvert;

  private get description() {
    return this.advert.description.replace(
      /((\s|^)https?:\/\/[^\s]+)/g,
      (match: string) => {
        const link = document.createElement('a');
        const linkText = document.createTextNode(match);

        link.appendChild(linkText);
        link.title = match;
        link.href = match;
        link.target = '_blank';

        return link.outerHTML;
      }
    );
  }
}
