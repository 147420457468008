var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-error" }, [
    _c("div", { staticClass: "vehicle-message" }, [
      _c("div", { staticClass: "vehicle-message__content" }, [
        _c(
          "div",
          { staticClass: "vehicle-container vehicle-container_width_xxllss" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-message__title vehicle-message__title_big-alter vehicle-message__title_condensed-other",
              },
              [_vm._v("Отзыв не найден")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-message__description vehicle-message__description_primary vehicle-message__description_middle",
              },
              [
                _vm._v(
                  "Возможно, он был удален или вы перешли по неверной ссылке."
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-message__control vehicle-message__control_condensed-extra",
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "button-style button-style_primary button-style_small vehicle-message__button",
                    attrs: { to: { name: "reviews" } },
                  },
                  [_vm._v("Перейти на главную")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-message__preview" }, [
      _c("div", {
        staticClass: "vehicle-message__image vehicle-message__image_ufo",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }