import { ActionTree } from 'vuex';
import { IRootState, IUserState } from '@/interfaces/store';
import { IUser } from '@/interfaces/common';
import userService from '@/services/user-service';

const actions: ActionTree<IUserState, IRootState> = {
  updateCurrentUser(context, { callback }) {
    if (!userService.isSessionSet) {
      context.commit('setUser', null);

      callback && callback();

      return;
    }

    userService.getMe((data: IUser) => {
      context.commit('setUser', data);

      callback && callback();
    });
  },
};

export default actions;
