
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';

  import { Marks, Review } from '@/types/review';
  import plural from '@/filters/plural-form';
  import vmApi from '@/api/vm-api';

  const userStore = namespace('user');

  @Component({
    filters: {
      plural,
    },
  })
  export default class ReviewBenefit extends Vue {
    @Prop() review!: Review;

    @userStore.Getter isAuthenticated!: boolean;

    private get marks() {
      return this.review.stats.marks;
    }

    private onMarkClick(mark: string) {
      if (this.isAuthenticated) {
        vmApi.markReview(this.review.id, mark, {
          success: ({ data }: { data: Marks }) => {
            this.$emit('marks-updated', {
              marks: data,
              myMark: this.review.myMark === mark ? null : mark,
            });
          },
        });
      } else {
        this.showLogin();
      }
    }

    private showLogin() {
      const profileAuth = (window as any).profileAuth as {
        showLoginModal: () => {};
      };

      profileAuth.showLoginModal();
    }
  }
