var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "vehicle-form__offers-part vehicle-form__offers-part_image",
    },
    [
      _c("div", { staticClass: "vehicle-form__panorama" }, [
        _c(
          "div",
          { staticClass: "vehicle-form__panorama-box" },
          [
            _c(
              "div",
              { staticClass: "vehicle-form__panorama-list" },
              _vm._l(_vm.galleryPhotos, function (photo, index) {
                return _c(
                  "div",
                  {
                    key: `${photo["100x100"] || photo.original}_${index}`,
                    staticClass: "vehicle-form__panorama-item",
                    class: `vehicle-form__panorama-item_${
                      _vm.isPremium ? "primary" : "secondary"
                    }`,
                  },
                  [
                    _c("div", { staticClass: "vehicle-form__preview" }, [
                      _c("div", { staticClass: "vehicle-form__image" }, [
                        _c("picture", [
                          _c("img", {
                            attrs: {
                              loading: "lazy",
                              srcset: `${photo["380x240"] || photo.original}, ${
                                photo["600x370"] || photo.original
                              } 2x`,
                              src: photo["600x370"] || photo.original,
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm._t("default"),
            _vm._v(" "),
            _vm.closureReason
              ? _c(
                  "div",
                  {
                    staticClass:
                      "button-style button-style_supererogatory button-style_small button-style_noreflex vehicle-form__button vehicle-form__button_foto",
                  },
                  [_vm._v("\n        Закрыто модератором\n      ")]
                )
              : _vm.isClosed
              ? _c(
                  "div",
                  {
                    staticClass:
                      "button-style button-style_supererogatory button-style_small button-style_noreflex vehicle-form__button vehicle-form__button_foto",
                  },
                  [_vm._v("\n        Закрыто\n      ")]
                )
              : _c(
                  "div",
                  {
                    staticClass:
                      "button-style button-style_transparent button-style_small button-style_noreflex vehicle-form__button vehicle-form__button_foto",
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("plural")(_vm.images.length, [
                            "фотография",
                            "фотографии",
                            "фотографий",
                          ])
                        ) +
                        "\n      "
                    ),
                  ]
                ),
          ],
          2
        ),
        _vm._v(" "),
        !_vm.isMobileSpecial && _vm.isPremium
          ? _c(
              "div",
              { staticClass: "vehicle-form__panorama-box helpers_hide_tablet" },
              [
                _c(
                  "div",
                  { staticClass: "vehicle-form__panorama-list" },
                  _vm._l(_vm.thumbnails, function (photo, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass:
                          "vehicle-form__panorama-item vehicle-form__panorama-item_additional",
                      },
                      [
                        _c("div", { staticClass: "vehicle-form__preview" }, [
                          _c("div", { staticClass: "vehicle-form__image" }, [
                            _c("picture", [
                              _c("img", {
                                attrs: {
                                  loading: "lazy",
                                  src: photo["100x100"] || photo.original,
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }