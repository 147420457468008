
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Commercial extends Vue {
  private services = [
    {
      name: 'Ремонт и обслуживание авто',
      url: 'https://s.onliner.by/profiles?sections%5B%5D=service',
    },
    {
      name: 'Перетяжка и ремонт салона',
      url: 'https://s.onliner.by/profiles?sections%5B%5D=car-interior',
    },
    {
      name: 'Шиномонтаж',
      url: 'https://s.onliner.by/profiles?sections%5B%5D=tirefitting',
    },
    {
      name: 'Автомойка',
      url: 'https://s.onliner.by/profiles?sections%5B%5D=carwash',
    },
    {
      name: 'Химчистка',
      url: 'https://s.onliner.by/profiles?sections%5B%5D=drycleaner',
    },
    {
      name: 'Другие автоуслуги',
      url: 'https://s.onliner.by/profiles?sections%5B%5D=carother',
    },
  ];
}
