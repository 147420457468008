var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "vehicle-form__intro" }, [
        _c("div", { staticClass: "vehicle-form__intro-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__intro-part vehicle-form__intro-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__intro-list" }, [
                _c("div", { staticClass: "vehicle-form__intro-item" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_condensed-either",
                    },
                    [_vm._v("\n              Описание\n            ")]
                  ),
                  _vm._v(" "),
                  _vm.isCurrent
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_extended-other",
                        },
                        [
                          _vm._v(
                            "\n              Расскажите об опыте использования автомобиля\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.isComplete && !_vm.isEditMode
            ? _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__intro-part vehicle-form__intro-part_2",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "vehicle-form__link vehicle-form__link_primary vehicle-form__link_tiny vehicle-form__link_font-weight_normal vehicle-form__link_ellipsis",
                      on: {
                        click: function ($event) {
                          return _vm.editStep("description")
                        },
                      },
                    },
                    [_vm._v("\n          Изменить\n        ")]
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm.isComplete
        ? [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__upload vehicle-form__upload_specific",
              },
              [
                _c(
                  "div",
                  { staticClass: "vehicle-form__upload-list" },
                  _vm._l(_vm.images, function (image, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "vehicle-form__upload-item" },
                      [
                        _c(
                          "div",
                          { staticClass: "vehicle-form__upload-preview" },
                          [
                            _c("div", {
                              staticClass: "vehicle-form__upload-image",
                              style: _vm._f("background")(
                                _vm.getImageUrl(image)
                              ),
                            }),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__parameter" }, [
              _c("div", { staticClass: "vehicle-form__parameter-list" }, [
                _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                  _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.tenure) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                  _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm._f("number")(_vm.odometer.value)) +
                                "\n                " +
                                _vm._s(_vm.odometerTypes[_vm.odometer.unit]) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.videoLinks
                  ? _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                      _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-flex" },
                        [
                          _vm._m(2),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.videoLinks) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                  _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                    _vm._m(3),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.summary) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                  _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                    _vm._m(4),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.ratingAverage.toFixed(1).replace(".", ",")
                                ) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.formattedPros.length
                  ? _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                      _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-flex" },
                        [
                          _vm._m(5),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.formattedPros) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.formattedCons.length
                  ? _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                      _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-flex" },
                        [
                          _vm._m(6),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.formattedCons) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]
        : _c("div"),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1 vehicle-form__parameter-part_width_s",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n                Срок владения\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1 vehicle-form__parameter-part_width_s",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n                Пробег\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1 vehicle-form__parameter-part_width_s",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n                Видео с YouTube\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1 vehicle-form__parameter-part_width_s",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n                Заголовок\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1 vehicle-form__parameter-part_width_s",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n                Итоговая оценка\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1 vehicle-form__parameter-part_width_s",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n                Плюсы\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1 vehicle-form__parameter-part_width_s",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n                Минусы\n              ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }