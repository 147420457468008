var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.label
    ? _c(
        "div",
        {
          staticClass:
            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_small vehicle-form__description_condensed",
        },
        [
          _c(
            "span",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
            },
            [_vm._v(_vm._s(_vm.label) + ":")]
          ),
          _vm._v("\n  " + _vm._s(_vm.value) + "\n"),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }