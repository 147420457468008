
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Viewer } from '@toast-ui/vue-editor';

import escapeHtml from '@/filters/escape-html';

@Component({
  components: {
    Viewer,
  },
  filters: {
    escapeHtml,
  },
})
export default class ReviewText extends Vue {
  @Prop() text!: string;
}
