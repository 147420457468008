var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__row vehicle-form__row_extended-other" },
    [
      _c(
        "div",
        { staticClass: "vehicle-form__group vehicle-form__group_width_full" },
        [
          _c(
            "div",
            { staticClass: "vehicle-form__label vehicle-form__label_base" },
            [
              _c("div", { staticClass: "vehicle-form__label-flex" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__label-part vehicle-form__label-part_1",
                  },
                  [
                    _c("div", { staticClass: "vehicle-form__label-title" }, [
                      _vm._v(_vm._s(_vm.sectionName)),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass:
                    "vehicle-form__label-part vehicle-form__label-part_2",
                }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__field" }, [
            _c("div", { staticClass: "vehicle-form__columns" }, [
              _c(
                "div",
                { staticClass: "vehicle-form__columns-flex" },
                _vm._l(_vm.parts, function (part, index) {
                  return _c(
                    "div",
                    {
                      staticClass: "vehicle-form__columns-part",
                      class: `vehicle-form__columns-part_${index}`,
                    },
                    [
                      _vm._l(part.dropdowns, function (dropdown) {
                        return _c(
                          dropdown.multiple
                            ? "step-exterior-equipment-multiple-dropdown"
                            : "step-exterior-equipment-dropdown",
                          {
                            key: dropdown.id,
                            tag: "div",
                            attrs: { item: dropdown },
                          }
                        )
                      }),
                      _vm._v(" "),
                      part.checkboxes.length
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__line vehicle-form__line_nocollapse vehicle-form__line_condensed-specific",
                            },
                            [
                              _c(
                                "ul",
                                { staticClass: "vehicle-form__checkbox-list" },
                                _vm._l(part.checkboxes, function (checkbox) {
                                  return _c(
                                    "step-exterior-equipment-checkbox",
                                    {
                                      key: checkbox.id,
                                      attrs: { item: checkbox },
                                    }
                                  )
                                }),
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }