
import { Vue, Prop, Component } from 'vue-property-decorator';

import phone from '@/filters/format-phone';
import time from '@/filters/format-time';

@Component({
  filters: {
    phone,
    time,
  },
})
export default class AdvertContactsListItem extends Vue {
  @Prop() phoneNumber!: string;
  @Prop() call!: {
    from: string;
    to: string;
  };
}
