var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__row vehicle-form__row_extended-other" },
    [
      _c(
        "div",
        {
          staticClass: "vehicle-form__line vehicle-form__line_condensed-other",
        },
        [
          _c("div", { staticClass: "vehicle-form__group" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__field" }, [
              _c(
                "div",
                {
                  staticClass:
                    "input-style__combo input-style__combo_stretch vehicle-form__input-combo vehicle-form__input-combo_width_full",
                },
                [
                  _vm._l(_vm.yearsDictionary.slice(0, 10), function (item) {
                    return _c(
                      "a",
                      {
                        key: item.id,
                        staticClass:
                          "button-style button-style_more button-style_base button-style_bordered vehicle-form__button vehicle-form__button_choose",
                        class: { "button-style_active": item.id == _vm.year },
                        on: {
                          click: function ($event) {
                            return _vm.setYear(item.id)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " + _vm._s(item.name) + "\n          "
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "button-style button-style_more button-style_base button-style_bordered vehicle-form__button vehicle-form__button_choose vehicle-form__button_choose_specific",
                      class: { "button-style_active": _vm.isOlderSelected },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.isOlderSelected ? _vm.year : "Старше") +
                          "\n            "
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.year,
                              expression: "year",
                            },
                          ],
                          staticClass:
                            "button-style__select vehicle-form__button-select",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.year = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        _vm._l(_vm.yearsDictionary.slice(10), function (item) {
                          return _c("option", { key: item.id }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(item.name) +
                                "\n              "
                            ),
                          ])
                        }),
                        0
                      ),
                    ]
                  ),
                ],
                2
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            {
              staticClass:
                "vehicle-form__line vehicle-form__line_condensed-other",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_fail vehicle-form__description_base vehicle-form__description_error vehicle-form__description_width_full",
                },
                [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("\n              Год выпуска\n            "),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }