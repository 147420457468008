
  import { Comment } from '@/types/comments';
  import { Component, Prop, Vue } from 'vue-property-decorator';

  import { Filters, UrlService } from '@onlinerby/js-common';

  @Component({
    filters: {
      plural: Filters.plural,
      background: Filters.background,
    },
  })
  export default class CommentsListMoreReplies extends Vue {
    @Prop() replies!: Comment[];
    @Prop() visibleCount!: number;

    private get hiddenReplies() {
      return this.replies.slice(this.visibleCount);
    }

    private getReplyImage(reply: Comment) {
      return UrlService.secureProjectUrl(
        'content',
        `user/avatar/60x60/${reply.author.id}`,
      );
    }

    private onShowClick() {
      this.$emit('show');
    }
  }
