var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "vehicle-form__save-item",
      class: { "vehicle-form__save-item_new": _vm.hasNewAdverts },
    },
    [
      _c(
        "div",
        { staticClass: "popover-style__handle vehicle-form__popover-handle" },
        [
          _c("div", {
            staticClass:
              "vehicle-form__popover-trigger vehicle-form__popover-trigger_remove",
            on: { click: _vm.onDeleteClick },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-other",
        },
        [
          _c(
            "a",
            {
              staticClass:
                "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base-alter",
              on: { click: _vm.onClick },
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._l(Object.keys(_vm.items).slice(0, 3), function (key) {
        return _c("saved-searches-subitem", {
          key: key,
          attrs: { "filter-key": key, filter: _vm.items[key] },
        })
      }),
      _vm._v(" "),
      _vm.count > 3
        ? _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_small vehicle-form__description_condensed",
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
                },
                [
                  _vm._v(
                    "+\n      " +
                      _vm._s(
                        _vm._f("plural")(_vm.count - 3, [
                          "параметр",
                          "параметра",
                          "параметров",
                        ])
                      )
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }