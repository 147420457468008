
  import { Component, Mixins } from 'vue-property-decorator';

  import CommentMixin from '@/mixins/comments/comment';
  import MarkdownText from '@/components/comments/markdown-text.vue';
  import relativeDate from '@/filters/comments/format-relative-date';
  import PendingInfo from '@/components/comments/pending-info.vue';

  @Component({
    components: {
      MarkdownText,
      PendingInfo,
    },
    filters: {
      relativeDate,
    },
  })
  export default class CommentsListReply extends Mixins<CommentMixin>(
    CommentMixin,
  ) {}
