var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "vehicle-form__row",
      class: { "vehicle-form__row_condensed-alter": _vm.isCondensedAlter },
    },
    [
      _c(
        "div",
        { staticClass: "vehicle-form__group vehicle-form__group_width_full" },
        [
          _c(
            "div",
            { staticClass: "vehicle-form__label vehicle-form__label_base" },
            [
              _c("div", { staticClass: "vehicle-form__label-flex" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__label-part vehicle-form__label-part_1",
                  },
                  [
                    _c("div", { staticClass: "vehicle-form__label-title" }, [
                      _vm._v(
                        _vm._s(_vm.label) +
                          ", " +
                          _vm._s(_vm.selectedUnit.label)
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__label-part vehicle-form__label-part_2",
                  },
                  _vm._l(_vm.availableUnits, function (unit) {
                    return _c(
                      "div",
                      {
                        key: unit.id,
                        staticClass: "vehicle-form__label-description",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base",
                            on: {
                              click: function ($event) {
                                return _vm.setUnit(unit)
                              },
                            },
                          },
                          [_vm._v(_vm._s(unit.label))]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__field" }, [
            _c("div", { staticClass: "vehicle-form__line" }, [
              _c(
                "div",
                {
                  staticClass:
                    "input-style__combo input-style__combo_stretch vehicle-form__input-combo vehicle-form__input-combo_width_full",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_width_full",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filter.value.from,
                            expression: "filter.value.from",
                          },
                        ],
                        staticClass:
                          "input-style input-style_primary input-style_small vehicle-form__input vehicle-form__input_width_full",
                        attrs: {
                          type: "text",
                          placeholder: "от",
                          maxlength: _vm.maxlength,
                        },
                        domProps: { value: _vm.filter.value.from },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.filter.value,
                                "from",
                                $event.target.value
                              )
                            },
                            function ($event) {
                              return _vm.onInput("from", $event)
                            },
                          ],
                          change: function ($event) {
                            return _vm.updateFilter()
                          },
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_width_full",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filter.value.to,
                            expression: "filter.value.to",
                          },
                        ],
                        staticClass:
                          "input-style input-style_primary input-style_small vehicle-form__input vehicle-form__input_width_full",
                        attrs: {
                          type: "text",
                          placeholder: "до",
                          maxlength: _vm.maxlength,
                        },
                        domProps: { value: _vm.filter.value.to },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.filter.value,
                                "to",
                                $event.target.value
                              )
                            },
                            function ($event) {
                              return _vm.onInput("to", $event)
                            },
                          ],
                          change: function ($event) {
                            return _vm.updateFilter()
                          },
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }