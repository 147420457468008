var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { ref: "gallery", staticClass: "vehicle-form__gallery" }, [
    _c(
      "div",
      { ref: "galleryFotorama", staticClass: "vehicle-form__gallery-list" },
      _vm._l(_vm.images, function (photo, index) {
        return _c("a", {
          key: `${photo[_vm.imageKeys.thumb]}_${index}`,
          staticClass: "vehicle-form__gallery-item",
          attrs: {
            "data-img":
              photo[
                _vm.isMobileSpecial
                  ? _vm.imageKeys.imageMobile
                  : _vm.imageKeys.imageDesktop
              ],
            "data-thumb": photo[_vm.imageKeys.thumb],
            "data-full": photo[_vm.imageKeys.fullscreen],
          },
        })
      }),
      0
    ),
    _vm._v(" "),
    _c("div", {
      ref: "galleryFotoramaPagesBar",
      staticClass:
        "button-style button-style_transparent button-style_small button-style_noreflex vehicle-form__button vehicle-form__button_pages helpers_show_tablet",
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }