class TimerService {
  private static instance: TimerService;

  public createTimer(
    initialValue: number,
    onTimerUpdate: Function,
    interval?: number
  ) {
    const timer: {
      interval: NodeJS.Timeout | null;
      value: number;
    } = {
      interval: null,
      value: initialValue,
    };

    let lastCurrentTime = Date.now();

    timer.interval = setInterval(() => {
      const currentTime = Date.now();
      const diff = Math.round((currentTime - lastCurrentTime) / 1000);

      lastCurrentTime = currentTime;

      timer.value = Math.max(timer.value - diff, 0);

      onTimerUpdate && onTimerUpdate(timer.value);

      if (timer.value <= 0) {
        timer.interval && clearInterval(timer.interval);
      }
    }, interval || 1000);

    return timer;
  }

  public static getInstance() {
    if (!TimerService.instance) {
      TimerService.instance = new TimerService();
    }

    return TimerService.instance;
  }
}

export default TimerService.getInstance();
