
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { contains, findWhere, intersection } from 'underscore';

import { IAdvert } from '@/interfaces/adverts';
import { Dictionaries } from '@/services/dictionaries-service';
import AdvertEquipmentSection from '@/components/advert/advert-equipment-section.vue';
import { equipmentMap, sectionsDictionary } from '@/config/equipment';
import { HashMap } from '@/types/common';
import camelize from '@/filters/format-camelize';

const advertStore = namespace('advert');

@Component({
  components: {
    AdvertEquipmentSection,
  },
})
export default class AdvertEquipment extends Vue {
  private openedSections: Array<string> = [];

  @advertStore.State advert!: IAdvert;

  private created() {
    this.openedSections = this.advert.equipment.map(item => item.id);
  }

  private get sections() {
    return equipmentMap;
  }

  private get isAllOpened() {
    return this.openedSections.length === this.advert.equipment.length;
  }

  private isOpened(key: string) {
    return contains(this.openedSections, key);
  }

  private toggleSection(key: string) {
    const index = this.openedSections.indexOf(key);

    index === -1
      ? this.openedSections.push(key)
      : this.openedSections.splice(index, 1);
  }

  private toggleAll() {
    this.openedSections = this.isAllOpened
      ? []
      : this.advert.equipment.map(item => item.id);
  }
}
