var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_condensed-either",
      },
      [_vm._v("\n    Оцените модель автомобиля\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_extended-other",
      },
      [_vm._v("\n    Что вам понравилось и не понравилось?\n  ")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "vehicle-form__row" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__line vehicle-form__line_condensed-specific",
        },
        [
          _vm._l(_vm.primaryMarks, function (mark) {
            return _c("grade", {
              key: mark.id,
              attrs: { "current-value": _vm.grades[mark.id], mark: mark },
              on: {
                change: function ($event) {
                  return _vm.onGradeChange(mark.id, $event)
                },
              },
            })
          }),
          _vm._v(" "),
          _vm.isGradesSet
            ? [
                _c("div", {
                  staticClass:
                    "vehicle-form__divider vehicle-form__divider_condensed vehicle-form__divider_width_xxxsss",
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__group vehicle-form__group_alter vehicle-form__group_wide",
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "vehicle-form__field" },
                      [
                        _c("rating-stars", {
                          attrs: { "rating-average": _vm.avgGrade },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            {
              staticClass:
                "vehicle-form__line vehicle-form__line_condensed-specific",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_width_full",
                },
                [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("\n                  Итоговая оценка\n                "),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }