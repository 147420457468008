var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__card vehicle-form__card_stub" },
      [
        _c("div", { staticClass: "vehicle-form__card-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__card-part vehicle-form__card-part_nav",
            },
            [
              _c("div", { staticClass: "vehicle-form__nav" }, [
                _c("div", { staticClass: "vehicle-form__nav-list" }, [
                  _c("div", { staticClass: "vehicle-form__nav-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__link vehicle-form__link_other vehicle-form__link_base",
                      },
                      [_vm._v("Все автомобили")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "vehicle-form__nav-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__link vehicle-form__link_other vehicle-form__link_base",
                      },
                      [_vm._v("Skoda")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "vehicle-form__nav-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__link vehicle-form__link_other vehicle-form__link_base",
                      },
                      [_vm._v("Octavia")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "vehicle-form__nav-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__link vehicle-form__link_other vehicle-form__link_base",
                      },
                      [_vm._v("III")]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__card-part vehicle-form__card-part_specification",
            },
            [
              _c("div", { staticClass: "vehicle-form__feature" }, [
                _c("div", { staticClass: "vehicle-form__feature-list" }, [
                  _c("div", { staticClass: "vehicle-form__feature-item" }, [
                    _c("div", { staticClass: "vehicle-form__feature-box" }, [
                      _c("div", { staticClass: "vehicle-form__price" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_condensed-other",
                          },
                          [_vm._v("14 500 000 р.")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
                          },
                          [_vm._v("6 712 $ / 5 895 €")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__control vehicle-form__control_condensed",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter vehicle-form__description_condensed-other",
                            },
                            [_vm._v("Купить этот автомобиль в лизинг")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "vehicle-form__unfold" }, [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__unfold-header" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "vehicle-form__leasing" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__leasing-list",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__leasing-item",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vehicle-form__leasing-flex",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "vehicle-form__leasing-part vehicle-form__leasing-part_1",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "vehicle-form__preview",
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "vehicle-form__image",
                                                          staticStyle: {
                                                            "background-image":
                                                              "url(https://s8.hostingkartinok.com/uploads/images/2019/07/e3b9fe61ebd7e9daa07bed032f92d20c.png)",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "vehicle-form__leasing-part vehicle-form__leasing-part_2",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "vehicle-form__description vehicle-form__description_primary-alter vehicle-form__description_base-alter",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "от 368 р. / мес."
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "vehicle-form__unfold-footer" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__description vehicle-form__description_primary vehicle-form__description_tiny",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__link vehicle-form__link_primary-alter vehicle-form__link_tiny",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__unfold-text vehicle-form__unfold-text_on",
                                          },
                                          [_vm._v("Все предложения")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "vehicle-form__parameter" }, [
                        _c(
                          "div",
                          { staticClass: "vehicle-form__parameter-list" },
                          [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__parameter-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vehicle-form__parameter-flex",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
                                          },
                                          [_vm._v("Год выпуска")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter",
                                          },
                                          [_vm._v("2018")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "vehicle-form__parameter-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vehicle-form__parameter-flex",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
                                          },
                                          [_vm._v("Пробег")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter",
                                          },
                                          [_vm._v("10 000 000 км")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "vehicle-form__parameter-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vehicle-form__parameter-flex",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
                                          },
                                          [_vm._v("Кузов")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter",
                                          },
                                          [_vm._v("Хэтчбек 5 дв.")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "vehicle-form__parameter-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vehicle-form__parameter-flex",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
                                          },
                                          [_vm._v("Цвет")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter",
                                          },
                                          [_vm._v("Черный")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "vehicle-form__parameter-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vehicle-form__parameter-flex",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
                                          },
                                          [_vm._v("Двигатель")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter",
                                          },
                                          [_vm._v("1.5 л / Бензин")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "vehicle-form__parameter-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vehicle-form__parameter-flex",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
                                          },
                                          [_vm._v("Коробка")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter",
                                          },
                                          [_vm._v("Автоматическая")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "vehicle-form__parameter-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vehicle-form__parameter-flex",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
                                          },
                                          [_vm._v("Привод")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter",
                                          },
                                          [_vm._v("Передний")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "vehicle-form__parameter-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vehicle-form__parameter-flex",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
                                          },
                                          [_vm._v("Состояние")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter",
                                          },
                                          [_vm._v("Аварийное")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "vehicle-form__parameter-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vehicle-form__parameter-flex",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
                                          },
                                          [_vm._v("Растаможен")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter",
                                          },
                                          [_vm._v("Да")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "vehicle-form__feature-item" }, [
                    _c("div", { staticClass: "vehicle-form__feature-box" }, [
                      _c("div", { staticClass: "vehicle-form__person" }, [
                        _c(
                          "div",
                          { staticClass: "vehicle-form__person-list" },
                          [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__person-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                                  },
                                  [_vm._v("Евгений")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny vehicle-form__description_condensed-other",
                                  },
                                  [_vm._v("Частное лицо")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base-alter vehicle-form__link_enter",
                                      },
                                      [_vm._v("Все объявления продавца")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__control vehicle-form__control_condensed vehicle-form__control_bar /*vehicle-form__control_bar_alter*/ /*vehicle-form__control_bar_hidden*/ /*vehicle-form__control_bar_opened*/",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "button-style button-style_auxiliary button-style_big vehicle-form__button vehicle-form__button_phone vehicle-form__button_width_full",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "vehicle-form__button-sign" },
                                [_vm._v("Показать телефон")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "vehicle-form__button-value" },
                                [
                                  _vm._v(
                                    "\n                    +375 29\n                    "
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "vehicle-form__button-dot" },
                                    [_vm._v(" • • •  • •  • •")]
                                  ),
                                ]
                              ),
                              _vm._v(">\n                "),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "button-style button-style_appendant button-style_base vehicle-form__button vehicle-form__button_message vehicle-form__button_width_full",
                            },
                            [_vm._v("Написать продавцу")]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__card-part vehicle-form__card-part_action",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "input-style__combo input-style__combo_stretch vehicle-form__input-combo vehicle-form__input-combo_width_full",
                },
                [
                  _c("div", {
                    staticClass:
                      "button-style button-style_auxiliary button-style_base vehicle-form__button vehicle-form__button_favorite-alter",
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass:
                      "button-style button-style_auxiliary button-style_base vehicle-form__button vehicle-form__button_share",
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__card-part vehicle-form__card-part_data",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_condensed-other",
                },
                [_vm._v("Mercedes-Benz S-Класс W221 S350 4MATIK LONG")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "vehicle-form__intro" }, [
                _c("div", { staticClass: "vehicle-form__intro-list" }, [
                  _c("div", { staticClass: "vehicle-form__intro-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_warning-alter vehicle-form__description_small vehicle-form__description_up",
                      },
                      [_vm._v("2 дня назад")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "vehicle-form__intro-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_other vehicle-form__description_small vehicle-form__description_eye",
                      },
                      [
                        _vm._v("\n              1245\n              "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_success vehicle-form__description_small",
                          },
                          [_vm._v("+ 88 за сегодня")]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "vehicle-form__intro-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
                      },
                      [_vm._v("№ 1023341234")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "vehicle-form__intro-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
                      },
                      [_vm._v("Витебск, Витебская область")]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__card-part vehicle-form__card-part_media",
            },
            [_c("div", { staticClass: "vehicle-form__gallery" })]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }