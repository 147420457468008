
  import { Component, Prop, Vue } from 'vue-property-decorator';

  import background from '@/filters/format-background';

  @Component({
    filters: {
      background,
    },
  })
  export default class StepTechDataAb extends Vue {
    @Prop() image!: string;
    @Prop() manufacturer!: string;
    @Prop() model!: string;
    @Prop() generation!: string | undefined;
    @Prop() year!: number;
    @Prop() capacity!: number;

    private get title() {
      const capacity = this.capacity ? `(${this.capacity} л) ` : '';
      const generation = this.generation ? ` ${this.generation}` : '';

      return `${this.manufacturer} ${this.model}${generation}, ${this.year} ${capacity}— ваша машина?`;
    }

    private onSubmitClick() {
      this.$emit('submit');
    }
  }
