var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__package" }, [
    _c(
      "div",
      { staticClass: "vehicle-form__package-list" },
      _vm._l(_vm.features, function (feature) {
        return _c("promote-package", {
          key: feature.id,
          attrs: { feature: feature },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }