
  import { Vue, Component, Watch } from 'vue-property-decorator';
  import { Mixins } from 'vue-mixin-decorator';
  import urlService from '@/services/url-service';
  import { namespace } from 'vuex-class';

  import { VuexAction } from '@/types/functions';
  import DeviceClass from '@/directives/device-class';
  import SearchesMixin from '@/mixins/adverts/searches';
  import SavedSearches from '@/components/search/saved-searches.vue';
  import { IUser } from '@/interfaces/common';
  import { some } from 'underscore';

  const createStore = namespace('create');
  const userStore = namespace('user');

  @Component({
    components: {
      SavedSearches,
    },
    directives: {
      DeviceClass,
    },
  })
  export default class Navigation extends Mixins<SearchesMixin>(SearchesMixin) {
    private isSearchesReady: boolean = false;

    @createStore.Action init!: VuexAction;

    @userStore.State currentUser!: IUser;
    @userStore.Getter isAuthenticated!: boolean;

    private created() {
      if (this.currentUser === null && this.$route.hash === '#saved_searches') {
        setTimeout(this.showLogin, 0);
      }
    }

    private get userId() {
      return this.currentUser ? this.currentUser.id : '';
    }

    private get abUrl(): string {
      return urlService.secureProjectUrl('ab');
    }

    private get mbUrl(): string {
      return urlService.secureProjectUrl('mb');
    }

    private get reviewsUrl(): string {
      return urlService.secureProjectUrl('ab', 'reviews');
    }

    private get bookmarksUrl(): string {
      return urlService.secureProjectUrl('profile', 'bookmarks/ab');
    }

    private get isCreateVisible() {
      return this.$route.name === 'search';
    }

    private get isReviews() {
      return some(this.$route.matched, item => {
        return item.meta?.navigation === 'reviews';
      });
    }

    private get isMyAuto() {
      return (
        some(
          this.$route.matched,
          item => item.meta?.navigation === 'user-auto',
        ) && this.currentUser.id == this.$route.params.id
      );
    }

    private createAdvert() {
      this.init();

      this.$router.push({ name: 'create-tech' }).catch(err => err);
    }

    private showBalance() {
      if (this.isAuthenticated) {
        document.body.classList.add('body_state-vehicle-form-save_opened');

        this.isSearchesReady = false;

        this.refreshSearches(() => {
          this.isSearchesReady = true;
        });

        this.$gtm.trackEvent({
          event: 'popup-view',
          'popup-view-name': 'saved_search',
        });
      } else {
        this.showLogin();
      }
    }

    private showLogin() {
      const profileAuth = (window as any).profileAuth as {
        showLoginModal: () => {};
      };

      profileAuth.showLoginModal();
    }

    private onSavedClick() {
      this.showBalance();
    }

    @Watch('currentUser')
    private onUserUpdate() {
      if (this.isAuthenticated && this.$route.hash === '#saved_searches') {
        this.showBalance();

        this.$router
          .replace({
            hash: '',
            query: this.$route.query,
          })
          .catch(ex => {});
      }
    }
  }
