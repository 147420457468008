
import { Vue } from 'vue-property-decorator';
import { Mixins, Component } from 'vue-mixin-decorator';
import Bookmark from '@/mixins/common/bookmark';
import { namespace } from 'vuex-class';
import { VuexAction } from '@/types/functions';

const searchStore = namespace('search');

@Component
export default class AdvertActionBookmark extends Mixins<Bookmark>(Bookmark) {
  @searchStore.Action addToBookmarks!: VuexAction;
  @searchStore.Action removeFromBookmarks!: VuexAction;
}
