
  import { Component, Mixins, Prop } from 'vue-property-decorator';

  import { TimeFormatService, UrlService } from '@onlinerby/js-common';
  import CommentsFormMixin from '@/mixins/comments/comments-form';
  import LinksMixin from '@/mixins/comments/links';
  import { ActiveBan, CommentsSettings } from '@/types/api';
  import ShowInContainer from '@/directives/show-in-container';
  import { Editor } from '@toast-ui/vue-editor';

  interface IMixins extends CommentsFormMixin, LinksMixin {}

  @Component({
    directives: {
      ShowInContainer,
    },
  })
  export default class CommentsFormMobile extends Mixins<IMixins>(
    CommentsFormMixin,
    LinksMixin,
  ) {
    @Prop() settings!: CommentsSettings;
    @Prop() activeBan!: ActiveBan | null;

    private isOpened: boolean = false;

    private get activeBanExpireAt() {
      return TimeFormatService.timeTo(this.activeBan?.expiredAt);
    }

    public openForm() {
      this.isOpened = true;

      this.$nextTick(() => {
        (this.$refs.toastuiEditor as Editor).invoke('focus');
      });

      (window as Window).document.body.classList.add(
        'body_state-popover-style_opened',
      );
    }

    private closeForm() {
      this.isOpened = false;

      (window as Window).document.body.classList.remove(
        'body_state-popover-style_opened',
      );
    }

    protected onSubmitComplete() {
      this.closeForm();
    }
  }
