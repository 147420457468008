var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "li",
    {
      staticClass:
        "vehicle-form__checkbox-item vehicle-form__checkbox-item_condensed",
    },
    [
      _c("label", { staticClass: "vehicle-form__checkbox-label" }, [
        _c(
          "div",
          {
            staticClass:
              "i-checkbox vehicle-form__checkbox vehicle-form__checkbox_base",
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model,
                  expression: "model",
                },
              ],
              staticClass: "i-checkbox__real",
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.model)
                  ? _vm._i(_vm.model, null) > -1
                  : _vm.model,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.model,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.model = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.model = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.model = $$c
                  }
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "i-checkbox__faux" }),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__checkbox-text" }, [
              _c("span", { staticClass: "vehicle-form__checkbox-sign" }, [
                _vm._v(_vm._s(_vm.label)),
              ]),
              _vm._v(" "),
              _vm.counters
                ? _c("span", { staticClass: "vehicle-form__checkbox-value" }, [
                    _vm._v(_vm._s(_vm._f("number")(_vm.counters))),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }