var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__offers-list" },
    [
      _vm.isLoading
        ? _vm._l(_vm.stubCount, function (index) {
            return _c("search-adverts-list-item-stub", { key: index })
          })
        : _vm.adverts.length
        ? [
            _vm._l(_vm.adverts, function (advert, index) {
              return [
                _c("search-adverts-list-item", {
                  key: advert.id,
                  attrs: { advert: advert },
                }),
                _vm._v(" "),
                _vm.isBannersVisible && _vm.bannerKeys.indexOf(index) !== -1
                  ? _c("google-banner", {
                      attrs: {
                        "slot-id": _vm.bannersConfig[index].id,
                        params: _vm.bannersConfig[index].params,
                        "is-mobile": _vm.bannersConfig[index].isMobile,
                      },
                    })
                  : _vm._e(),
              ]
            }),
          ]
        : _c("no-results"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }