var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "vehicle-form__step-item",
      class: { [_vm.statusClass]: true, "js-step-error": _vm.hasErrors },
    },
    [
      _c(
        "div",
        { staticClass: "vehicle-form__step-helper" },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }