
  import { Component } from 'vue-property-decorator';
  import { Mixins } from 'vue-mixin-decorator';
  import { HashMap } from '@onlinerby/js-common';
  import { namespace } from 'vuex-class';

  import StepWrapper from '@/components/common/step-wrapper.vue';
  import StepTechHeader from '@/components/reviews/create/step-tech/step-tech-header.vue';
  import StepTechHeaderMobile from '@/components/reviews/create/step-tech/step-tech-header-mobile.vue';
  import StepDescriptionHeader from '@/components/reviews/create/step-description/step-description-header.vue';

  import StepMixin from '@/mixins/reviews/create/step';
  import StepMutationDataMixin from '@/mixins/reviews/create/step-mutation-data';
  import { stepStatuses } from '@/config/reviews/create';
  import { listUrlService } from '@/config/urls';
  import urlService from '@/services/url-service';
  import vmApi from '@/api/vm-api';
  import reviewsCreateTransformer from '@/transformers/reviews-create-transfromer';
  import { Review } from '@/types/review';
  import { VuexAction } from '@/types/functions';
  import * as $ from 'jquery';
  import { isEmpty } from 'underscore';
  import stepsErrorMap from '@/config/reviews/steps-error-map';

  const createStore = namespace('reviews/create');

  interface IMixins extends StepMixin, StepMutationDataMixin {}

  @Component({
    components: {
      StepWrapper,
      StepTechHeader,
      StepTechHeaderMobile,
      StepDescriptionHeader,
    },
  })
  export default class StepEdit extends Mixins<IMixins>(
    StepMixin,
    StepMutationDataMixin,
  ) {
    protected step: string = 'edit';
    private isLoading: boolean = false;
    private errorMap = stepsErrorMap[this.step];

    @createStore.State statusesByStep!: HashMap<string>;
    @createStore.State mode!: string;
    @createStore.Action setStatusesByStep!: HashMap<string>;
    @createStore.Action setErrors!: VuexAction;

    private mounted() {
      window.scrollTo(0, 0);
    }

    private get ruleLink() {
      const {
        rules: { projectName, pathQuery },
      } = listUrlService;
      return urlService.secureProjectUrl(projectName, pathQuery);
    }

    private onStepChange(step: string) {
      this.setStatusesByStep({ [step]: stepStatuses.edit });
    }

    private scrollToError() {
      this.$nextTick(() => {
        const $error = $('.js-step-error');

        if (!$error.length) {
          return;
        }

        const errorTop = $error.first().offset()!.top;

        $(window).scrollTop(errorTop - this.OFFSET);
      });
    }

    private onUpdateClick() {
      if (!this.validate()) {
        this.trackError();
        return;
      }

      this.isLoading = true;

      vmApi.updateReview(this.data.id, reviewsCreateTransformer(this.data), {
        success: ({ data }: Review) => {
          const { manufacturer, model, id } = data;

          this.trackSuccess();

          this.$router.push({
            name: 'review',
            params: {
              manufacturer: manufacturer.slug,
              model: model.slug,
              id: id,
            },
          });
        },
        error: ({ response }) => {
          this.setErrors(response.data.errors);
          this.scrollToError();
          this.trackError();
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    }

    protected validate() {
      const genDictionary = this.dictionaries[this.urlSelectedModel];
      const hasGenerations = genDictionary && genDictionary.length;

      if (hasGenerations && !this.data.generationId) {
        this.setErrors({
          [this.errorMapTech.generation]: ['Укажите поколение'],
        });
      }

      if (!this.data.specs.engine || !this.data.specs.engine.type) {
        this.setErrors({
          [this.errorMapTech.engineType]: ['Укажите тип двигателя'],
        });
      } else if (
        this.data.specs.engine.type !== 'electric' &&
        !this.data.specs.engine.capacity
      ) {
        this.setErrors({
          [this.errorMapTech.engineCapacity]: ['Укажите объем двигателя'],
        });
      }

      if (!this.data.manufacturerId) {
        this.setErrors({
          [this.errorMapTech.manufacturer]: ['Укажите марку'],
        });
      } else if (!this.data.modelId) {
        this.setErrors({
          [this.errorMapTech.model]: ['Укажите модель'],
        });
      }

      if (!this.data.specs.year) {
        this.setErrors({
          [this.errorMapTech.year]: ['Укажите год выпуска'],
        });
      }

      if (!this.data.specs.bodyType) {
        this.setErrors({
          [this.errorMapTech.bodyType]: ['Укажите тип кузова'],
        });
      }

      if (!this.data.specs.drivetrain) {
        this.setErrors({
          [this.errorMapTech.drivetrain]: ['Укажите привод'],
        });
      }

      if (!this.data.specs.transmission) {
        this.setErrors({
          [this.errorMapTech.transmission]: ['Укажите тип коробки передач'],
        });
      }

      // description
      if (!this.data.tenure) {
        this.setErrors({
          [this.errorMap.tenure]: ['Укажите срок владения'],
        });
      }

      if (this.data.specs.odometer && !this.data.specs.odometer.value) {
        this.setErrors({
          [this.errorMap.odometer]: ['Укажите пробег'],
        });
      }

      if (!this.data.text) {
        this.setErrors({
          [this.errorMap.text]: ['Укажите текст отзыва'],
        });
      }

      if (!this.data.summary) {
        this.setErrors({
          [this.errorMap.summary]: ['Укажите заголовок отзыва'],
        });
      }

      const anyProcessedImages = this.data.images && this.data.images.length;

      if (!anyProcessedImages) {
        this.setErrors({
          [this.errorMap.images]: ['Загрузите фотографии'],
        });
      }

      const isRatingSpecified = !this.dictionaries.primaryMarks.filter(
        mark => !this.data.rating || !this.data.rating[mark.id],
      ).length;

      if (!isRatingSpecified) {
        this.setErrors({
          [this.errorMap.rating]: ['Оцените все параметры'],
        });
      }

      const secondaryMarksErrorText = 'Оцените минимум 3 параметра';

      if (this.data.secondaryMarks) {
        const { pros, cons } = this.data.secondaryMarks;
        const countSelectedSecondaryMarks = pros.length + cons.length;

        if (countSelectedSecondaryMarks < 3) {
          this.setErrors({
            [this.errorMap.secondaryMarks]: [secondaryMarksErrorText],
          });
        }
      } else {
        this.setErrors({
          [this.errorMap.secondaryMarks]: [secondaryMarksErrorText],
        });
      }

      if (this.hasErrors) {
        this.scrollToError();
      }

      return isEmpty(this.errors);
    }

    private trackSuccess() {
      this.$gtm.trackEvent({
        event: 'edit_review - success',
      });
    }

    private trackError() {
      this.$gtm.trackEvent({
        event: 'edit_review - error',
        value: JSON.stringify({
          error: this.errors,
        }),
      });
    }
  }
