var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__group vehicle-form__group_alter" },
    [
      _c(
        "div",
        { staticClass: "vehicle-form__line vehicle-form__line_condensed" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("dropdown", {
            attrs: {
              options: _vm.tenures,
              "selected-value": _vm.selectedTenure,
              "is-error": !!_vm.error,
              placeholder: "Выберите срок владения",
            },
            on: { change: _vm.onChangeTenure },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            { staticClass: "vehicle-form__line vehicle-form__line_condensed" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_width_full",
                },
                [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("\n            Срок владения\n          "),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }