export default {
  inserted(el: HTMLElement, { value: { containerSelector } }: any) {
    const $container = $(containerSelector);
    const $element = $(el);

    if (!$container.length) {
      return;
    }

    const processVisibility = () => {
      const containerTop = $container.offset()!.top;
      const scrollBottom = $(document).scrollTop()! + $(window).height()!;

      if (scrollBottom > containerTop) {
        $element.show();
      } else {
        $element.hide();
      }
    };

    $(window).scroll(processVisibility);

    processVisibility();
  },
};
