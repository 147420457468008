
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';

  import urlService from '@/services/url-service';
  import background from '@/filters/format-background';
  import { Review } from '@/types/review';
  import { DictionaryItem } from '@/services/dictionaries-service';

  const commonStore = namespace('common');

  @Component({
    filters: {
      background,
    },
  })
  export default class ReviewAuthor extends Vue {
    @Prop() review!: Review;

    @commonStore.Getter getDictionaryItem!: (
      type: string,
      key: string,
    ) => DictionaryItem;

    private get tenureString() {
      return this.getDictionaryItem(
        'tenure',
        this.review.tenure,
      ).name.toLowerCase();
    }

    private get profileLink() {
      return urlService.secureProjectUrl(
        'profile',
        `user/${this.review.author.id}`,
      );
    }
  }
