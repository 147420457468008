const userAgent = window.navigator.userAgent;
const ratio = window.devicePixelRatio || 1;
const screenInfo = {
  width: window.screen.width * ratio,
  height: window.screen.height * ratio,
};

type DeviceInfo = {
  device: string | null;
  browser: string | null;
};

export default class DeviceDetectService {
  public static isMobile() {
    return /Android|iPhone|iPad|iPod|IEMobile/i.test(userAgent);
  }

  public static isIOS() {
    return /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;
  }

  public static get isEdge() {
    return /Edge/.test(userAgent);
  }

  public static detect() {
    const deviceInfo: DeviceInfo = {
      device: null,
      browser: null,
    };

    if (DeviceDetectService.isIOS()) {
      if (screenInfo.width === 1125 && screenInfo.height === 2436) {
        deviceInfo.device = 'iphonex';
      }

      if (screenInfo.width === 1242 && screenInfo.height === 2688) {
        deviceInfo.device = 'iphonexsmax';
      }

      if (screenInfo.width === 828 && screenInfo.height === 1792) {
        deviceInfo.device = 'iphonexr';
      }

      userAgent.match('CriOS') && (deviceInfo.browser = 'Chrome');
    }

    return deviceInfo;
  }
}
