import { Mixins } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';

import CommentsCoreMixin from '@/mixins/comments/comments-core';
import WebsocketService from '@/services/websocket-service';
import { BanEvent } from '@/types/websocket';

@Mixin
export default class UserWsMixin extends Mixins<CommentsCoreMixin>(
  CommentsCoreMixin,
) {
  private USER_WEBSOCKET_CHANNEL: string = `$public:activity.user.${this.currentUser?.id}`;
  protected userWebsocketService: WebsocketService = new WebsocketService(
    {
      userId: this.currentUser?.id,
    },
    [this.USER_WEBSOCKET_CHANNEL],
  );

  protected created() {
    this.subscribeUserEvents();
  }

  private subscribeUserEvents() {
    this.addUserHandler('user.banned', (data: BanEvent) => {
      this.activeBan = {
        expiredAt: data.expiredAt,
        feature: data.feature,
        project: data.project,
      };
    });

    this.addUserHandler('user.unbanned', () => {
      this.activeBan = null;
    });
  }

  private addUserHandler(event: string, handler: Function) {
    this.userWebsocketService.addHandler(
      this.USER_WEBSOCKET_CHANNEL,
      event,
      handler,
    );
  }
}
