
import { Prop, Watch } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import { State, namespace } from 'vuex-class';
import { findWhere, first, isEmpty } from 'underscore';

import ChainedSelectFilterChunkPopular from '@/components/filters/chained-select-filter-chunk-popular.vue';
import Dropdown from '@/mixins/common/dropdown';
import IsMobileMixin from '@/mixins/common/is-mobile';
import KeysNavigationMixin from '@/mixins/search/keys-navigation';
import { ChainChunk } from '@/types/filters';
import {
  Dictionary,
  Dictionaries,
  DictionaryItem,
} from '@/services/dictionaries-service';
import { ICounters } from '@/interfaces/search';
import { HashMap } from '@/types/common';
import capitalize from '@/filters/format-capitalize';
import number from '@/filters/format-number';

const commonStore = namespace('common');

interface IMixins extends Dropdown, IsMobileMixin, KeysNavigationMixin {}

@Component({
  components: {
    ChainedSelectFilterChunkPopular,
  },
  filters: {
    number,
  },
})
export default class ChainedSelectFilterChunk extends Mixins<IMixins>(
  Dropdown,
  IsMobileMixin,
  KeysNavigationMixin
) {
  @Prop() value!: string;
  @Prop() index!: number;
  @Prop() groupIndex!: number;
  @Prop() dictionaryName!: string;
  @Prop() parent!: ChainChunk;
  @Prop() chunk!: ChainChunk;
  @Prop() typeChunk!: ChainChunk;
  @Prop() counters!: HashMap<ICounters>;
  @Prop({
    default: false,
  })
  isDisabled!: boolean;

  private optionsFilter: string = '';
  private boundValue?: number | null = null;

  @commonStore.State dictionaries!: Dictionaries;

  public created() {
    this.boundValue = parseInt(this.value);

    this.onToggle = (isVisible: boolean) => {
      !this.isMobile && (this.$refs.filterInput as HTMLElement).focus();
    };
  }

  private get filterString() {
    return this.optionsFilter
      .trim()
      .toLowerCase()
      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  private get options() {
    const dictionary: Dictionary =
      this.dictionaries[this.dictionaryName] ||
      (this.typeChunk &&
        this.parent &&
        this.dictionaries[`${this.typeChunk.name}_${this.parent.value}`]) ||
      [];

    if (!dictionary) {
      return [];
    }

    if (!this.optionsFilter) {
      return dictionary;
    }

    const regex = new RegExp(this.filterString);

    return dictionary.filter((item: DictionaryItem) => {
      return regex.test(item.name.toLowerCase());
    });
  }

  private get selected(): DictionaryItem {
    const dictionary: Dictionary = this.dictionaries[this.typeChunk.name];

    const selected: any = findWhere(dictionary, {
      id: parseInt(this.value),
    });

    return selected;
  }

  private get selectedTitle(): string {
    return this.selected ? this.selected.name : `${this.typeChunk.placeholder}`;
  }

  private get hasPopular() {
    return !!this.dictionaries[`popular${capitalize(this.dictionaryName)}`];
  }

  private onChange() {
    this.closeDropdown();

    const handler = () => {
      (this.$refs.dropdown as HTMLElement).removeEventListener(
        'transitionend',
        handler
      );

      this.optionsFilter = '';
    };

    (this.$refs.dropdown as HTMLElement).addEventListener(
      'transitionend',
      handler
    );

    this.$emit('change', {
      index: this.index,
      value: this.boundValue,
    });
  }

  private onPopularSelected(id: number) {
    this.closeDropdown();

    this.$emit('change', {
      index: this.index,
      value: id,
    });
  }

  private setCurrent() {
    const item = this.options[this.cursorPointer];

    if (!item) {
      return;
    }

    this.boundValue = parseInt(item.id);
    this.onChange(false);
    this.resetCurrent();
  }

  @Watch('dictionaryName') onDictionaryNameChanged(options: string) {
    this.optionsFilter = '';
  }

  @Watch('value') onValueChanged(value: string) {
    this.boundValue = parseInt(value);
  }
}
