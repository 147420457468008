
import { Vue, Component } from 'vue-property-decorator';

import AdvertLeasingForm from '@/components/advert/advert-leasing-form.vue';
import Popup from '@/components/common/popup.vue';
import { ILeasingItem } from '@/interfaces/adverts';

@Component({
  components: {
    AdvertLeasingForm,
    Popup,
  },
})
export default class AdvertLeasingPopup extends Vue {
  private leasing: ILeasingItem | null = null;

  public $refs!: {
    leasingPopup: HTMLFormElement;
  };

  public openPopup(leasing: ILeasingItem) {
    this.leasing = leasing;
    this.$refs.leasingPopup.openPopup();
  }

  private onSuccess() {
    this.$refs.leasingPopup.closePopup();
  }
}
