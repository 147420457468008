
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { IPage } from '@/interfaces/search';
import plural from '@/filters/plural-form';
import { ISearchAdvert } from '@/interfaces/adverts';
import { IUser } from '@/interfaces/common';
import { HashMap } from '@/types/common';

const advertsStore = namespace('adverts');

const filters = [
  {
    id: 'active',
    name: 'Открытые',
  },
  {
    id: 'closed',
    name: 'Закрытые',
  },
];

@Component({
  filters: {
    plural,
  },
})
export default class OwnAdvertsHeader extends Vue {
  @advertsStore.State isLoading!: boolean;
  @advertsStore.State page!: IPage;
  @advertsStore.State user!: IUser;
  @advertsStore.State permissions!: HashMap<boolean>;
  @advertsStore.State total!: number;
  @advertsStore.State adverts!: Array<ISearchAdvert>;

  private get status() {
    return this.$route.meta.status;
  }

  private get filters() {
    return filters;
  }

  private get author() {
    return this.user || {};
  }
}
