var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "vehicle-form__row vehicle-form__row_extended-other vehicle-form__row_choose",
    },
    [
      _c(
        "div",
        { staticClass: "vehicle-form__group vehicle-form__group_width_auto" },
        [
          _c("div", { staticClass: "vehicle-form__field" }, [
            _c("ul", { staticClass: "vehicle-form__checkbox-list" }, [
              _c(
                "li",
                {
                  staticClass:
                    "vehicle-form__checkbox-item vehicle-form__checkbox-item_inline",
                },
                [
                  _c("label", { staticClass: "vehicle-form__checkbox-label" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "i-checkbox vehicle-form__checkbox vehicle-form__checkbox_base",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.includeVat,
                              expression: "includeVat",
                            },
                          ],
                          staticClass: "i-checkbox__real",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.includeVat)
                              ? _vm._i(_vm.includeVat, null) > -1
                              : _vm.includeVat,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.includeVat,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.includeVat = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.includeVat = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.includeVat = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "i-checkbox__faux" }),
                        _vm._v(" "),
                        _vm._m(0),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vehicle-form__group vehicle-form__group_width_auto" },
        [
          _c("div", { staticClass: "vehicle-form__field" }, [
            _c("ul", { staticClass: "vehicle-form__checkbox-list" }, [
              _c(
                "li",
                {
                  staticClass:
                    "vehicle-form__checkbox-item vehicle-form__checkbox-item_inline",
                },
                [
                  _c("label", { staticClass: "vehicle-form__checkbox-label" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "i-checkbox vehicle-form__checkbox vehicle-form__checkbox_base",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.exchange,
                              expression: "exchange",
                            },
                          ],
                          staticClass: "i-checkbox__real",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.exchange)
                              ? _vm._i(_vm.exchange, null) > -1
                              : _vm.exchange,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.exchange,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.exchange = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.exchange = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.exchange = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "i-checkbox__faux" }),
                        _vm._v(" "),
                        _vm._m(1),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__checkbox-text" }, [
      _c("div", { staticClass: "vehicle-form__checkbox-sign" }, [
        _vm._v("Цена с НДС"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__checkbox-text" }, [
      _c("div", { staticClass: "vehicle-form__checkbox-sign" }, [
        _vm._v("Возможен обмен"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }