var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.isLoading
    ? _c(
        "div",
        { staticClass: "vehicle-steps" },
        [
          _c("div", { staticClass: "vehicle-form" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_extended-fringe",
              },
              [_vm._v("\n      Разместить объявление\n    ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vehicle-form__step" },
              [_c("router-view")],
              1
            ),
          ]),
          _vm._v(" "),
          _c("jivosite", { attrs: { "widget-id": "AS5MmyAj0k" } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }