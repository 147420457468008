
import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { findWhere } from 'underscore';

import { Dictionaries } from '@/services/dictionaries-service';
import capitalize from '@/filters/format-capitalize';
import popular from '@/config/popular';
import { HashMap } from '@/types/common';

const commonStore = namespace('common');

@Component
export default class ChainedSelectFilterChunkPopular extends Vue {
  @Prop() dictionaryName!: string;
  @Prop() value!: number;

  @commonStore.State dictionaries!: Dictionaries;

  private get popular() {
    return this.dictionaries[`popular${capitalize(this.dictionaryName)}`];
  }

  private getClassName(id: number) {
    const item = findWhere(this.dictionaries[this.dictionaryName], { id });

    return item ? item.slug : '';
  }

  private get id() {
    return this.value;
  }

  private set id(id: number) {
    this.$emit('selected', id);
  }
}
