var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__row vehicle-form__row_extended-other" },
    [
      _c(
        "div",
        {
          staticClass: "vehicle-form__line vehicle-form__line_condensed-other",
        },
        [
          _c("div", { staticClass: "vehicle-form__group" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__field" }, [
              _c(
                "ul",
                {
                  staticClass:
                    "vehicle-form__checkbox-list vehicle-form__checkbox-list_carcass",
                  class: {
                    "vehicle-form__checkbox-list_opened": _vm.isAllVisible,
                  },
                },
                [
                  _vm._l(_vm.types, function (item) {
                    return _c(
                      "li",
                      {
                        key: item.id,
                        staticClass:
                          "vehicle-form__checkbox-item vehicle-form__checkbox-item_carcass",
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "vehicle-form__checkbox-label" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "i-checkbox i-checkbox_radio i-checkbox_specific vehicle-form__checkbox vehicle-form__checkbox_base",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.bodyType,
                                      expression: "bodyType",
                                    },
                                  ],
                                  staticClass: "i-checkbox__real",
                                  attrs: { type: "radio", name: "bodyType" },
                                  domProps: {
                                    value: item.id,
                                    checked: _vm._q(_vm.bodyType, item.id),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.bodyType = item.id
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "i-checkbox__faux" }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vehicle-form__checkbox-text",
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "vehicle-form__checkbox-image",
                                      class: `vehicle-form__checkbox-image_${item.id}-alter`,
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__checkbox-sign",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm._f("capitalize")(item.name)
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  !_vm.isAllVisible
                    ? _c(
                        "li",
                        {
                          staticClass:
                            "vehicle-form__checkbox-item vehicle-form__checkbox-item_carcass vehicle-form__checkbox-item_carcass_all",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__checkbox-label" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "i-checkbox i-checkbox_radio i-checkbox_specific vehicle-form__checkbox vehicle-form__checkbox_base",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__checkbox-text",
                                      on: { click: _vm.onToggle },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__checkbox-sign vehicle-form__checkbox-sign_on",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    Показать все\n                  "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            {
              staticClass:
                "vehicle-form__line vehicle-form__line_condensed-other",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_full",
                },
                [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("\n              Тип кузова\n            "),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }