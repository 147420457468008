
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class VideoBanner extends Vue {
    @Prop() id!: string;
    @Prop() containerId!: string;
    @Prop() ownerId!: string;

    private mounted() {
      (window as any).yaContextCb.push(() => {
        (window as any).Ya.adfoxCode.createScroll({
          ownerId: this.ownerId,
          containerId: this.containerId,
          params: {
            p1: 'crahl',
            p2: 'gyqt',
            insertAfter: 'undefined',
            insertPosition: '0',
            stick: true,
            stickTo: 'bottom-right',
            stickyColorScheme: 'light',
            puid26: '',
            puid28: '',
          },
        });
      });
    }
  }
