import Vue from 'vue';
import Router from 'vue-router';
import * as qs from 'qs';
import { isEmpty, isObject, some } from 'underscore';
import * as Cookies from 'cookies-js';

import routes from '@/config/routes';
import urlService from '@/services/url-service';

Vue.use(Router);

interface queryObject {
  [key: string]: any;
}

const flattenObject: (object: queryObject) => queryObject = object => {
  const flatQuery: queryObject = {};

  for (let key in object) {
    if (!object.hasOwnProperty(key)) {
      continue;
    }

    const value = object[key];

    if (isObject(value)) {
      for (let innerKey in value) {
        if (!value.hasOwnProperty(innerKey)) {
          continue;
        }

        flatQuery[`${key}[${innerKey}]`] = value[innerKey];
      }
    } else {
      flatQuery[key] = value;
    }
  }

  if (some(flatQuery, property => isObject(property))) {
    return flattenObject(flatQuery);
  } else {
    return flatQuery;
  }
};

const router = new Router({
  mode: 'history',

  routes,

  parseQuery(query) {
    const parsedQuery = qs.parse(query);

    return flattenObject(parsedQuery);
  },

  stringifyQuery(query: queryObject) {
    const filteredQuery: queryObject = {};

    for (let key in query) {
      if (!query.hasOwnProperty(key) || !query[key]) {
        continue;
      }

      filteredQuery[key] = query[key];
    }

    return isEmpty(filteredQuery) ? '' : `?${qs.stringify(filteredQuery)}`;
  },

  scrollBehavior(to, from, savedPosition) {
    const isCustomScroll = some(to.matched, item => item.meta.customScroll);

    if (isCustomScroll || to.meta.customScroll) {
      return;
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (
    to.meta.requiresAuth ||
    some(to.matched, item => item.meta.requiresAuth)
  ) {
    if (!Cookies.get('logged_in')) {
      const toUrl = `${window.location.origin}${to.fullPath}`;

      window.location.href = urlService.secureProjectUrl(
        'profile',
        `login?redirect=${toUrl}`,
      );

      return;
    }
  }

  if (
    to.meta.feature &&
    Number(Cookies.get(`feature_${to.meta.feature}`)) !== 1
  ) {
    next({ name: 'not-found' });
  }

  next();
});

export default router;
