import { ISortType } from '@/interfaces/search';
import { HashMap } from '@/types/common';

export const advertOptions: Array<string> = [
  'Кондиционер',
  'Кожаный салон',
  'Легкосплавные диски',
  'Ксенон',
  'Парктроник',
  'Подогрев сидений',
  'Система контроля стабилизации',
  'Навигация',
  'Громкая связь',
];

export const odometerTypes: HashMap<string> = {
  km: 'км',
  miles: 'миль',
};

export const powerTypes: HashMap<string> = {
  horse: 'л.с.',
  kw: 'кВт',
};

export const advertsSortTypes: Array<ISortType> = [
  {
    name: 'last_up_at:desc',
    title: 'Актуальные',
  },
  {
    name: 'created_at:desc',
    title: 'Новые объявления',
  },
  {
    name: 'price:asc',
    title: 'Дешевые авто',
  },
  {
    name: 'price:desc',
    title: 'Дорогие авто',
  },
  {
    name: 'year:desc',
    title: 'Новые авто',
  },
  {
    name: 'year:asc',
    title: 'Старые авто',
  },
  {
    name: 'odometer:asc',
    title: 'Меньший пробег',
  },
];

export const reviewsSortTypes: Array<ISortType> = [
  {
    name: 'actuality:desc',
    title: 'Актуальные',
  },
  {
    name: 'discussed',
    title: 'Самые обсуждаемые',
  },
  {
    name: 'useful',
    title: 'Самые полезные',
  },
  {
    name: 'actuality:asc',
    title: 'Сначала старые',
  },
];
