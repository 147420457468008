import Vue from 'vue';
import { Mixin, Mixins } from 'vue-mixin-decorator';
import * as $ from 'jquery';
import { namespace } from 'vuex-class';
import { intersection, isEmpty } from 'underscore';

import { VuexAction } from '@/types/functions';
import { HashMap } from '@/types/common';
import IsMobileMixin from '@/mixins/common/is-mobile';
import CreateFinish from '@/components/create/create-finish.vue';
import errorMap from '@/config/steps-error-map';

const createStore = namespace('create');

@Mixin({
  components: {
    CreateFinish,
  },
})
export default class Step extends Mixins<IsMobileMixin>(IsMobileMixin) {
  private OFFSET: number = 0;
  protected step!: string;

  @createStore.State mode!: string;
  @createStore.State steps!: HashMap<any>;
  @createStore.State errors!: HashMap<any>;
  @createStore.Action setStep!: VuexAction;
  @createStore.Action setError!: VuexAction;

  created() {
    this.OFFSET = this.isMobileSpecial ? 70 : 20;
  }

  mounted() {
    const step: Vue = this.$refs[this.step] as Vue;

    if (!step) {
      return;
    }

    const stepTop = $(step.$el).offset()!.top;

    $(window).scrollTop(stepTop - this.OFFSET);
  }

  protected get isEdit() {
    return this.steps[this.step] === 'edit';
  }

  protected get hasErrors() {
    const errors: Array<string> = errorMap[this.step];
    const currentErrors = Object.keys(this.errors);

    return !!intersection(errors, currentErrors).length;
  }

  protected next() {
    this.setStep({ [this.step]: 'complete' });

    this.$router.replace({ name: `create-${this.getStep('pristine')}` });
  }

  protected finish() {
    this.setStep({ [this.step]: 'complete' });

    const name =
      this.mode === 'create'
        ? `create-${this.getStep('current')}`
        : 'edit-init';

    this.$router.replace({ name });
  }

  protected validate() {
    return true;
  }

  protected onError() {
    this.$nextTick(() => {
      const $row = $(
        '.vehicle-form__description_error, .drop-style__hint_error',
      ).closest('.vehicle-form__row');
      const correction = this.isMobileSpecial ? -50 : 0;

      $row.length &&
        $(window).scrollTop($row.first().offset()!.top + correction);
    });
  }

  protected onFinishClick() {
    this.validate();

    this.hasErrors ? this.onError() : this.finish();
  }

  protected onNextClick() {
    this.validate();

    if (this.hasErrors) {
      this.onError();
      this.trackGtm('error');
    } else {
      this.next();
      this.trackGtm('success');
    }
  }

  private getStep(status: string) {
    for (let key of Object.keys(this.steps)) {
      if (this.steps[key] === status) {
        return key;
      }
    }

    return '';
  }

  private trackGtm(result: string) {
    const event = {
      create: `create_ad - ${this.step} - ${result}`,
      edit: `edit_ad - ${this.step} - ${result}`,
    }[this.mode];
    const value = !isEmpty(this.errors)
      ? { errors: JSON.stringify(this.errors) }
      : {};

    this.$gtm.trackEvent({
      event,
      value,
    });
  }
}
