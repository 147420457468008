
import { Vue, Prop, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import Popup from '@/components/common/popup.vue';
import { Dictionaries, DictionaryItem } from '@/services/dictionaries-service';
import { closureReasons } from '@/dictionaries/reviews';

const commonStore = namespace('common');

@Component({
  components: {
    Popup,
  },
})
export default class ReviewClosePopup extends Vue {
  private closureReason: string = closureReasons[0].name;
  private text: string = '';
  private error: string = '';

  @Prop() reviewId!: string;

  @commonStore.State dictionaries!: Dictionaries;
  @commonStore.Getter getDictionaryItem!: (
    type: string,
    key: number,
  ) => DictionaryItem;

  public $refs!: {
    closeReviewPopup: HTMLFormElement;
  };

  private get closureReasons() {
    return closureReasons;
  }

  private onCloseClick() {
    if (!this.closureReason && !this.text) {
      this.error = 'Укажите причину закрытия';

      return;
    }

    const closureReason = this.closureReason || this.text;

    this.$emit('close', { closureReason });
    this.closePopup();
  }

  public closePopup() {
    this.$refs.closeReviewPopup.closePopup();
  }

  public openPopup(ids: Array<number>) {
    this.$refs.closeReviewPopup.openPopup();
  }

  private onTextInput() {
    this.error = '';
  }
}
