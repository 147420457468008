var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__step-list" },
    [
      _c(
        "step-wrapper",
        { attrs: { step: "tech" } },
        [
          !_vm.isMobileSpecial
            ? _c("step-tech-header")
            : _c("step-tech-header-mobile"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "step-wrapper",
        { attrs: { step: "exterior" } },
        [_c("step-exterior-header")],
        1
      ),
      _vm._v(" "),
      _c(
        "step-wrapper",
        { attrs: { step: "cost" } },
        [_c("step-cost-header")],
        1
      ),
      _vm._v(" "),
      _c(
        "step-wrapper",
        { attrs: { step: "contacts" } },
        [_c("step-contacts-header")],
        1
      ),
      _vm._v(" "),
      _c("create-finish"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }