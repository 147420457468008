var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "vehicle-form__control vehicle-form__control_condensed vehicle-form__control_management",
    },
    [
      _vm.isOwnAdvert
        ? [
            _vm.permissions.up
              ? _c("a", {
                  staticClass:
                    "button-style button-style_secondary button-style_base vehicle-form__button vehicle-form__button_up",
                  on: { click: _vm.onUpClick },
                })
              : !_vm.isAdvertClosed && _vm.advert.upAvailableIn
              ? _c("advert-manage-up-countdown", {
                  attrs: {
                    "up-available-in": _vm.advert.upAvailableIn,
                    "is-premium": _vm.advert.premium,
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.permissions.edit
        ? _c(
            "router-link",
            {
              staticClass:
                "button-style button-style_adscititious button-style_base vehicle-form__button",
              attrs: { to: { name: "edit", params: { id: _vm.advert.id } } },
            },
            [_vm._v("Редактировать")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.permissions.close &&
      !_vm.isAdvertClosed &&
      _vm.advert.premium &&
      _vm.isOwnAdvert
        ? _c("advert-close-popover", { on: { close: _vm.onCloseClick } })
        : _vm.permissions.close && !_vm.isAdvertClosed
        ? _c(
            "a",
            {
              staticClass:
                "button-style button-style_adscititious button-style_base vehicle-form__button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.onCloseClick.apply(null, arguments)
                },
              },
            },
            [_vm._v("Закрыть")]
          )
        : _vm.permissions.open
        ? _c(
            "a",
            {
              staticClass:
                "button-style button-style_adscititious button-style_base vehicle-form__button",
              on: { click: _vm.onOpenClick },
            },
            [_vm._v("Открыть")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("advert-close-popup", {
        ref: "closeAdvertPopup",
        on: { close: _vm.onClose },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }