export const closureReasons = [
  {
    title: 'В отзыве использована грубая, нецензурная  лексика (п.п. 14)',
    name:
      'Ваш отзыв удален за использование грубой, нецензурной лексики, что является нарушением пункта 14 Правил «Автобарахолки» (https://blog.onliner.by/avtobaraholka_rules)',
  },
  {
    title: 'В отзыве размещена реклама',
    name:
      'Отзыв был удален за размещение в нем рекламы (пункт Правил 12 https://blog.onliner.by/avtobaraholka_rules)',
  },
  {
    title: 'Информация не является отзывом на автомобиль',
    name:
      'Отзыв удален, так как размещенная в нем информация фактически не является отзывом о марке автомобиля.',
  },
  {
    title: 'Фото не соответствует марке/модели',
    name:
      'Ваш отзыв удален из-за несоответствия размещенной фотографии описываемому автомобилю (пункт Правил 6 https://blog.onliner.by/avtobaraholka_rules)',
  },
  {
    title:
      'Отзыв удален по результатам технической модерации или из-за указания недостоверной информации',
    name:
      'Ваш отзыв удален по данным технической модерации и/или за размещение недостоверной информации. Обратите внимание на пункт 16 Правил сервиса (https://blog.onliner.by/avtobaraholka_rules)',
  },
  {
    title: 'Малосодержательный отзыв',
    name:
      'Ваш отзыв удален как малосодержательный. Просим вас найти время и оставить более подробную информацию об автомобиле. Заранее спасибо',
  },
];

export const status = [
  {
    id: 'waiting_moderation',
    name: 'Ожидает модерации',
  },
  {
    id: 'on_moderation',
    name: 'На модерации',
  },
  {
    id: 'published',
    name: 'Опубликован',
  },
  {
    id: 'closed',
    name: 'Закрыт(автором, модератором)',
  },
];
