export const stepStatuses = {
  current: 'current',
  pristine: 'pristine',
  complete: 'complete',
  edit: 'edit',
};

export const COUNT_MAIN_BODY_TYPES = 7;

export const defaultData = {
  generationId: null,
  specs: {
    odometer: {
      unit: 'km',
    },
    engine: {
      power: {
        unit: 'horse',
      },
    },
  },
};

export const maxGrade = 5;

export const plus = 'plus';

export const minus = 'minus';

export const maxLengthTextReview = 10000;
