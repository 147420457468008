
import { Watch } from 'vue-property-decorator';
import { Mixins, Component } from 'vue-mixin-decorator';

import FieldMixin from '@/mixins/create/field';
import StorageMixin from '@/mixins/create/storage';
import number from '@/filters/format-number';

interface IMixins extends FieldMixin, StorageMixin {}

@Component
export default class StepContactsSellerUnp extends Mixins<IMixins>(
  FieldMixin,
  StorageMixin
) {
  private get seller() {
    return this.data.seller || {};
  }

  private get unp() {
    return this.seller.unp;
  }

  private set unp(value) {
    const unp = parseInt(value);

    this.clearError({ name: 'seller.unp' });

    if (unp && !isNaN(unp as number)) {
      this.setField({ seller: { ...this.seller, unp: unp.toString() } });
    } else {
      const { unp, ...seller } = this.seller;

      this.setField({ seller });
    }
  }

  private get error() {
    return (this.errors['seller.unp'] || []).join('. ');
  }
}
