import { Component, Mixins } from 'vue-mixin-decorator';
import { Prop } from 'vue-property-decorator';
import { findWhere } from 'underscore';

import { IRangeUnit } from '@/interfaces/search';

import FilterMixin from '@/mixins/filters/filter';
import { HashMap } from '@/types/common';
import { InputEvent } from '@/types/events';

@Component
export default class RangeUnitFilterMixin extends Mixins<FilterMixin>(
  FilterMixin
) {
  @Prop() unitType!: string;
  @Prop() units!: Array<IRangeUnit>;
  @Prop() maxlength!: number;

  protected filterType = 'object';

  protected get selectedUnit(): IRangeUnit {
    const unitName: string = (this.filter.value as HashMap<string>)[
      this.unitType
    ];
    const selectedUnit =
      findWhere(this.units, {
        id: unitName,
      }) || this.units[0];

    return selectedUnit;
  }

  protected setUnit(unit: IRangeUnit) {
    this.updateFilter(unit);
  }

  protected updateFilter(selectedUnit?: IRangeUnit) {
    const value: HashMap<string> = this.filter.value as HashMap<string>;
    const unit = selectedUnit || this.selectedUnit;

    this._updateFilter({
      from: value.from,
      to: value.to,
      [this.unitType]: unit.id,
    });
  }

  protected onInput(property: string, event: InputEvent) {
    const value = this.filter.value as HashMap<string>;

    event.target.value = event.target.value.replace(/\D/g, '');
    value[property] = value[property] ? value[property].replace(/\D/g, '') : '';
  }
}
