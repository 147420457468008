var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__text vehicle-form__text_max-width_xxxxllll" },
    [_c("viewer", { attrs: { initialValue: _vm._f("escapeHtml")(_vm.text) } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }