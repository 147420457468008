import { Vue } from 'vue-property-decorator';

import { Mixin } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { HashMap } from '@/types/common';
import { VuexAction } from '@/types/functions';
import capitalize from '@/filters/format-capitalize';

const createStore = namespace('create');

@Mixin({
  filters: {
    capitalize,
  },
})
export default class Field extends Vue {
  @createStore.State data!: HashMap<any>;
  @createStore.State mode!: string;
  @createStore.State errors!: HashMap<any>;
  @createStore.Action setField!: VuexAction;
  @createStore.Action clearError!: VuexAction;
}
