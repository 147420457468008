var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__rating" }, [
    _c("div", {
      staticClass: "rating rating_middle vehicle-form__rating-stars",
      class: `rating_${_vm.ratingAverageCss}`,
    }),
    _vm._v(" "),
    _c("div", { staticClass: "vehicle-form__rating-text" }, [
      _c(
        "div",
        { staticClass: "vehicle-form__title vehicle-form__title_base" },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.ratingAverage.toFixed(1).replace(".", ",")) +
              "\n    "
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _vm.isInfoBlockVisible
      ? _c(
          "div",
          {
            staticClass:
              "popover-style__handle popover-style__handle_interactive vehicle-form__popover-handle",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "popover-style popover-style_primary popover-style_base popover-style_bottom-left vehicle-form__popover vehicle-form__popover_width_sssm",
              },
              [
                _c("div", { staticClass: "popover-style__content" }, [
                  _c("div", { staticClass: "vehicle-form" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_font-weight_semibold vehicle-form__description_condensed",
                      },
                      [
                        _vm._v(
                          "\n            Отзывы в Автобарахолке\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                      },
                      [
                        _vm._v(
                          "\n            Оставляйте и изучайте отзывы об автомобилях. Смотрите рейтинги\n            моделей и марок\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__control vehicle-form__control_condensed",
                      },
                      [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "gtm",
                                rawName: "v-gtm",
                                value: { event: "create_review" },
                                expression: "{ event: 'create_review' }",
                              },
                            ],
                            staticClass:
                              "button-style button-style_primary button-style_small vehicle-form__button vehicle-form__button_width_full",
                            on: { click: _vm.onCreateReviewClick },
                          },
                          [
                            _vm._v(
                              "\n              Написать отзыв\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass:
                "vehicle-form__popover-trigger vehicle-form__popover-trigger_info",
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }