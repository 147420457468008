
  import { Mixins, Component } from 'vue-mixin-decorator';
  import { namespace } from 'vuex-class';

  import { CurrentUser } from '@onlinerby/js-common';

  import IsMobileMixin from '@/mixins/common/is-mobile';
  import ScrollMixin from '@/mixins/common/scroll';
  import ReviewMeta from '@/mixins/reviews/view/review-meta';
  import vmApi from '@/api/vm-api';
  import { Marks, Review, ReviewsRating } from '@/types/review';
  import AdvertStub from '@/components/advert/advert-stub.vue';
  import Gallery from '@/components/common/gallery.vue';
  import JsonLd from '@/components/common/json-ld.vue';
  import ReviewActionComplain from '@/components/reviews/view/review-action-complain.vue';
  import ReviewAuthor from '@/components/reviews/view/review-author.vue';
  import ReviewBenefit from '@/components/reviews/view/review-benefit.vue';
  import ReviewBreadcrumbs from '@/components/reviews/view/review-breadcrumbs.vue';
  import ReviewComments from '@/components/reviews/view/review-comments.vue';
  import ReviewHeader from '@/components/reviews/view/review-header.vue';
  import ReviewManage from '@/components/reviews/view/review-manage.vue';
  import ReviewNotFound from '@/components/reviews/view/review-not-found.vue';
  import ReviewProposals from '@/components/reviews/view/review-proposals.vue';
  import ReviewRating from '@/components/reviews/view/review-rating.vue';
  import ReviewSimilar from '@/components/reviews/view/review-similar.vue';
  import ReviewSpecs from '@/components/reviews/view/review-specs.vue';
  import ReviewStatus from '@/components/reviews/view/review-status.vue';
  import ReviewText from '@/components/reviews/view/review-text.vue';
  import ReviewVideo from '@/components/reviews/view/review-video.vue';
  import Rating from '@/components/common/rating-reviews/rating.vue';
  import { socialIcon } from '@/config/urls';
  import { isEmpty } from 'underscore';
  import { HashMap } from '@/types/common';
  import { Watch } from 'vue-property-decorator';

  interface IMixins extends IsMobileMixin, ScrollMixin, ReviewMeta {}

  const userStore = namespace('user');

  @Component({
    components: {
      AdvertStub,
      Gallery,
      JsonLd,
      ReviewActionComplain,
      ReviewAuthor,
      ReviewBenefit,
      ReviewBreadcrumbs,
      ReviewComments,
      ReviewHeader,
      ReviewManage,
      ReviewNotFound,
      ReviewProposals,
      ReviewRating,
      ReviewSimilar,
      ReviewSpecs,
      ReviewStatus,
      ReviewText,
      ReviewVideo,
      Rating,
    },
    metaInfo(): any {
      return {
        title: 'Автобарахолка',
        meta: [
          {
            name: 'description',
            content: '',
          },
        ],
        link: [],
      };
    },
  })
  export default class ReviewPage extends Mixins<IMixins>(
    IsMobileMixin,
    ScrollMixin,
    ReviewMeta,
  ) {
    protected review: Review | null = null;
    private reviewsRating: ReviewsRating | null = null;
    private commentsCounter: number | null = null;
    private isReviewNotFound: boolean = false;
    private ld: HashMap<any> | null = null;

    @userStore.State currentUser!: CurrentUser;

    protected created() {
      this.updateReview();
    }

    private get isPhotosVisible(): boolean {
      return !!(this.review && this.review.images);
    }

    private get isReviewEmpty() {
      return isEmpty(this.review);
    }

    protected get isOwnReview() {
      return (
        this.currentUser && this.currentUser.id === this.review?.author?.id
      );
    }

    private get isGameEnabled() {
      return (window as any).isGameEnabled;
    }

    private updateReview() {
      this.review = null;

      vmApi.getReview(this.$route.params.id, {
        success: ({ data }: { data: Review }) => {
          this.review = data;

          const params = {
            car: {
              manufacturer: data.manufacturer.id,
              model: data.model.id,
              generation: data.generation.id,
            },
            include: 'stats',
          };

          vmApi.getReviewsRating(params, {
            success: ({ data: reviewsRating }: { data: ReviewsRating }) => {
              if (reviewsRating.stats && reviewsRating.count < 3) {
                reviewsRating.stats.cons = [];
                reviewsRating.stats.pros = [];
              }

              this.reviewsRating = {
                ...reviewsRating,
                ratingBy: 'модели',
                car: {
                  manufacturer: data.manufacturer,
                  model: data.model,
                  generation: data.generation,
                },
              };

              this.updateMeta();
              this.setLd();
            },
          });
        },
        error: ({ response }: any) => {
          if (response.status === 403 || response.status === 404) {
            this.isReviewNotFound = true;
          }
        },
      });
    }

    private setLd() {
      if (!this.review) {
        return;
      }

      const { manufacturer, model } = this.review;
      const ld: HashMap<any> = {
        itemReviewed: {
          '@type': 'Product',
          image: this.review.images[0]['172x132'],
          name: `${manufacturer.name} ${model.name}`,
        },
        reviewRating: {
          '@type': 'Rating',
          ratingValue: this.review.ratingAverage,
        },
        name: this.review.summary,
        author: {
          '@type': 'Person',
          name: this.review.author.name,
        },
        reviewBody: this.review.text,
        publisher: {
          '@type': 'Organization',
          name: 'Автобарахолка Onlíner',
        },
      };

      this.ld = ld;
    }

    private onMarksUpdated({
      marks,
      myMark,
    }: {
      marks: Marks;
      myMark: string;
    }) {
      if (!this.review) {
        return;
      }

      this.review.stats.marks = marks;
      this.review.myMark = myMark;
    }

    private onReviewChange(review: Review) {
      this.review = review;
    }

    private onCommentsCounterUpdate(counter: number) {
      this.commentsCounter = counter;
    }

    private onCommentsClick() {
      (this.$refs.comments as Vue).$el.scrollIntoView();
      this.correctOffset();
    }

    @Watch('$route.params.id')
    onIdChange() {
      this.updateReview();
    }
  }
