
import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import PromoteAdvertsListItem from '@/components/promote/promote-adverts-list-item.vue';
import { ISearchAdvert } from '@/interfaces/adverts';
import { Entities, Entity } from '@/types/promote';
import plural from '@/filters/plural-form';

const promoteStore = namespace('promote');
const INITIAL_PAGE_SIZE = 3;
const PAGE_SIZE = 10;

@Component({
  components: {
    PromoteAdvertsListItem,
  },
  filters: {
    plural,
  },
})
export default class PromoteAdvertsList extends Vue {
  private PAGE_SIZE: number = PAGE_SIZE;
  private count: number = INITIAL_PAGE_SIZE;

  @promoteStore.State entities!: Entities;
  @promoteStore.State modelId!: string;
  @promoteStore.State manufacturerId!: string;
  @promoteStore.State showNonPremiumOnly!: boolean;

  private get filteredEntities(): Entities {
    return this.entities.filter(item => {
      return (
        (!this.showNonPremiumOnly || !item.activeFeatures.length) &&
        (!this.manufacturerId ||
          item.data.manufacturer.id === this.manufacturerId) &&
        (!this.modelId || item.data.model.id === this.modelId)
      );
    });
  }

  private get entitiesList() {
    return this.filteredEntities.slice(0, this.count);
  }

  private get remains() {
    const remains = this.filteredEntities.length - this.count;

    return remains < this.PAGE_SIZE ? remains : this.PAGE_SIZE;
  }

  private addPage() {
    this.count += this.PAGE_SIZE;
  }

  @Watch('manufacturerId')
  @Watch('modelId')
  @Watch('showNonPremiumOnly')
  private onFilter() {
    this.count = INITIAL_PAGE_SIZE;
  }
}
