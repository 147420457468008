
import { Watch } from 'vue-property-decorator';
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import { Dictionaries } from '@/services/dictionaries-service';
import FieldMixin from '@/mixins/create/field';
import { odometerTypes } from '@/config/dictionaries';

const commonStore = namespace('common');

@Component
export default class StepCostOdometer extends Mixins<FieldMixin>(FieldMixin) {
  @commonStore.State dictionaries!: Dictionaries;

  private get state() {
    return this.specs.state;
  }

  private setState(value: string) {
    this.setField({ state: value });
  }

  private get specs() {
    return this.data.specs || {};
  }

  private get odometer() {
    return this.specs.odometer || {};
  }

  private get odometerValue() {
    return this.odometer.value;
  }

  private set odometerValue(_value) {
    const value = parseInt(_value);

    if (value && !isNaN(value as number)) {
      const odometer = { ...this.odometer, value };

      this.setField({ specs: { ...this.specs, odometer } });
    } else {
      const { value, ...odometer } = this.odometer;

      this.setField({ specs: { ...this.specs, odometer } });
    }
  }

  private get odometerUnits() {
    return odometerTypes;
  }

  private get dealTerms() {
    return this.data.dealTerms || {};
  }

  private get customsClearance() {
    return this.dealTerms.customsClearance === false;
  }

  private set customsClearance(value) {
    this.setField({
      dealTerms: { ...this.dealTerms, customsClearance: !value },
    });
  }

  private get error() {
    return (this.errors['specs.odometer.value'] || []).join('. ');
  }

  private setOdometerUnit(value: string) {
    const odometer = { ...this.odometer, unit: value };

    this.setField({ specs: { ...this.specs, odometer } });
  }

  @Watch('odometerValue') onValueChange() {
    this.clearError({ name: 'specs.odometer.value' });
  }
}
