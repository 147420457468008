import { Module } from 'vuex';

import { ISearchState } from '@/interfaces/reviews/store';
import { IRootState } from '@/interfaces/store';
import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const namespaced: boolean = true;

const module: Module<ISearchState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default module;
