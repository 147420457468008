import { Mixins } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';

import { IFilter } from '@/interfaces/filters';
import { findIndex, isEmpty } from 'underscore';
import { HashMap } from '@/types/common';
import FilterObjects from '@/mixins/search/filter-objects';

@Mixin
export default class Humanize extends Mixins<FilterObjects>(FilterObjects) {
  protected filters!: Array<IFilter>;

  protected searchPrefix!: string;

  public removePlainFilter(filters: Array<IFilter>, name: string) {
    const index = findIndex(filters, { name });

    index !== -1 && filters.splice(index, 1);
  }

  public removeProperty(filters: Array<IFilter>, name: string, prop: string) {
    const index = findIndex(filters, { name });

    if (index === -1) {
      return;
    }

    const newValue: HashMap<string> = {};
    const value = filters[index].value as HashMap<string>;

    for (let key of Object.keys(value)) {
      if (key !== prop) {
        newValue[key] = value[key];
      }
    }

    isEmpty(newValue)
      ? filters.splice(index, 1)
      : filters.splice(index, 1, { name, value: newValue });
  }

  public removeGroupProperty(
    filters: Array<IFilter>,
    name: string,
    prop: string,
  ) {
    const index = findIndex(filters, { name });

    if (index === -1) {
      return;
    }

    const newValue: Array<any> = [];
    const part: HashMap<string> = {};
    const value = (filters[index].value as Array<any>)[0] as HashMap<string>;

    for (let key of Object.keys(value)) {
      if (key !== prop) {
        part[key] = value[key];
      }
    }

    !isEmpty(part) && newValue.push(part);

    isEmpty(newValue)
      ? filters.splice(index, 1)
      : filters.splice(index, 1, { name, value: newValue });
  }

  public humanize() {
    if (this.searchPrefix === 'reviews') {
      return this.humanizeReviews();
    }

    return this.humanizeAdverts();
  }

  private humanizeAdverts() {
    const filters = [...this.filters];

    if (
      this.brandObject &&
      this.modelObject &&
      this.generationObject &&
      this.cityObject &&
      [255, 281, 312, 334, 393, 269].indexOf(this.cityId) !== -1
    ) {
      this.removeGroupProperty(filters, 'car', 'manufacturer');
      this.removeGroupProperty(filters, 'car', 'model');
      this.removeGroupProperty(filters, 'car', 'generation');
      this.removeProperty(filters, 'location', 'country');
      this.removeProperty(filters, 'location', 'region');
      this.removeProperty(filters, 'location', 'city');

      return {
        path: `/${this.brandObject.slug}/${this.modelObject.slug}/${this.generationObject.slug}/city/${this.cityObject.slug}`,
        filters,
      };
    }

    if (
      this.brandObject &&
      this.modelObject &&
      this.cityObject &&
      [255, 281, 312, 334, 393, 269].indexOf(this.cityId) !== -1
    ) {
      this.removeGroupProperty(filters, 'car', 'manufacturer');
      this.removeGroupProperty(filters, 'car', 'model');
      this.removeProperty(filters, 'location', 'country');
      this.removeProperty(filters, 'location', 'region');
      this.removeProperty(filters, 'location', 'city');

      return {
        path: `/${this.brandObject.slug}/${this.modelObject.slug}/city/${this.cityObject.slug}`,
        filters,
      };
    }

    if (this.state === 'new' && this.brandObject && this.modelObject) {
      this.removePlainFilter(filters, 'state');
      this.removeGroupProperty(filters, 'car', 'manufacturer');
      this.removeGroupProperty(filters, 'car', 'model');

      return {
        path: `/new-cars/${this.brandObject.slug}/${this.modelObject.slug}`,
        filters,
      };
    }

    if (
      this.state === 'new' &&
      this.isDealer &&
      this.cityObject &&
      [255, 281, 312, 334, 393, 269].indexOf(this.cityId) !== -1
    ) {
      this.removePlainFilter(filters, 'state');
      this.removePlainFilter(filters, 'seller_type');
      this.removeProperty(filters, 'location', 'country');
      this.removeProperty(filters, 'location', 'region');
      this.removeProperty(filters, 'location', 'city');

      return {
        path: `/new-cars/dealers/city/${this.cityObject.slug}`,
        filters,
      };
    }

    if (
      this.state === 'new' &&
      this.cityObject &&
      [255, 281, 312, 334, 393, 269].indexOf(this.cityId) !== -1
    ) {
      this.removePlainFilter(filters, 'state');
      this.removeProperty(filters, 'location', 'country');
      this.removeProperty(filters, 'location', 'region');
      this.removeProperty(filters, 'location', 'city');

      return {
        path: `/new-cars/city/${this.cityObject.slug}`,
        filters,
      };
    }

    if (this.state === 'new' && this.isDealer) {
      this.removePlainFilter(filters, 'state');
      this.removePlainFilter(filters, 'seller_type');

      return {
        path: `/new-cars/dealers`,
        filters,
      };
    }

    if (
      this.state === 'owned' &&
      this.cityObject &&
      [4400, 255, 281, 312, 334, 393, 269].indexOf(this.cityId) !== -1
    ) {
      this.removePlainFilter(filters, 'state');
      this.removeProperty(filters, 'location', 'country');
      this.removeProperty(filters, 'location', 'region');
      this.removeProperty(filters, 'location', 'city');

      return {
        path: `/used-cars/city/${this.cityObject.slug}`,
        filters,
      };
    }

    if (
      this.state === 'owned' &&
      this.countryObject &&
      [3159].indexOf(this.countryId) !== -1
    ) {
      this.removePlainFilter(filters, 'state');
      this.removeProperty(filters, 'location', 'country');

      return {
        path: `/used-cars/country/${this.countryObject.slug}`,
        filters,
      };
    }

    if ([4400].indexOf(this.cityId) !== -1 && this.cityObject) {
      this.removeProperty(filters, 'location', 'country');
      this.removeProperty(filters, 'location', 'region');
      this.removeProperty(filters, 'location', 'city');

      return {
        path: `/city/${this.cityObject.slug}`,
        filters,
      };
    }

    if (
      [2897, 2514, 1012, 3159, 5681].indexOf(this.countryId) !== -1 &&
      this.countryObject
    ) {
      this.removeProperty(filters, 'location', 'country');

      return {
        path: `/country/${this.countryObject.slug}`,
        filters,
      };
    }

    if (
      [248].indexOf(this.countryId) !== -1 &&
      this.countryObject &&
      this.cityObject
    ) {
      this.removeProperty(filters, 'location', 'country');
      this.removeProperty(filters, 'location', 'region');
      this.removeProperty(filters, 'location', 'city');

      return {
        path: `/city/${this.cityObject.slug}`,
        filters,
      };
    }

    if (this.brandObject && this.modelObject && this.generationObject) {
      this.removeGroupProperty(filters, 'car', 'manufacturer');
      this.removeGroupProperty(filters, 'car', 'model');
      this.removeGroupProperty(filters, 'car', 'generation');

      return {
        path: `/${this.brandObject.slug}/${this.modelObject.slug}/${this.generationObject.slug}`,
        filters,
      };
    }

    if (this.brandObject && this.modelObject) {
      this.removeGroupProperty(filters, 'car', 'manufacturer');
      this.removeGroupProperty(filters, 'car', 'model');

      return {
        path: `/${this.brandObject.slug}/${this.modelObject.slug}`,
        filters,
      };
    }

    if (this.brandObject) {
      this.removeGroupProperty(filters, 'car', 'manufacturer');

      return {
        path: `/${this.brandObject.slug}`,
        filters,
      };
    }

    if (this.state === 'new') {
      this.removePlainFilter(filters, 'state');

      return {
        path: `/new-cars`,
        filters,
      };
    }

    if (this.state === 'owned') {
      this.removePlainFilter(filters, 'state');

      return {
        path: `/used-cars`,
        filters,
      };
    }

    if (this.isVatIncluded) {
      this.removePlainFilter(filters, 'include_vat');

      return {
        path: `/nds`,
        filters,
      };
    }

    if (this.state === 'emergency') {
      this.removePlainFilter(filters, 'state');

      return {
        path: `/damaged-cars`,
        filters,
      };
    }

    return {
      path: '/',
      filters,
    };
  }

  private humanizeReviews() {
    const filters = [...this.filters];

    if (this.brandObject && this.modelObject && this.generationObject) {
      this.removeGroupProperty(filters, 'car', 'manufacturer');
      this.removeGroupProperty(filters, 'car', 'model');
      this.removeGroupProperty(filters, 'car', 'generation');

      return {
        path: `/${this.brandObject.slug}/${this.modelObject.slug}/${this.generationObject.slug}`,
        filters,
      };
    }

    if (this.brandObject && this.modelObject) {
      this.removeGroupProperty(filters, 'car', 'manufacturer');
      this.removeGroupProperty(filters, 'car', 'model');

      return {
        path: `/${this.brandObject.slug}/${this.modelObject.slug}`,
        filters,
      };
    }

    if (this.brandObject) {
      this.removeGroupProperty(filters, 'car', 'manufacturer');

      return {
        path: `/${this.brandObject.slug}`,
        filters,
      };
    }

    return {
      path: '/',
      filters,
    };
  }
}
