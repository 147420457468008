
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { every, isEmpty } from 'underscore';

import { VuexAction } from '@/types/functions';
import { HashMap, Image } from '@/types/common';
import StepWrapper from '@/components/create/step-common/step-wrapper.vue';
import StepTechHeader from '@/components/create/step-tech/step-tech-header.vue';
import StepTechHeaderMobile from '@/components/create/step-tech/step-tech-header-mobile.vue';
import StepExteriorColor from '@/components/create/step-exterior/step-exterior-color.vue';
import StepExteriorEquipment from '@/components/create/step-exterior/step-exterior-equipment.vue';
import StepExteriorHeader from '@/components/create/step-exterior/step-exterior-header.vue';
import StepExteriorImages from '@/components/create/step-exterior/step-exterior-images.vue';
import StepCostHeader from '@/components/create/step-cost/step-cost-header.vue';
import StepContactsHeader from '@/components/create/step-contacts/step-contacts-header.vue';
import StepMixin from '@/mixins/create/step';

const createStore = namespace('create');

@Component({
  components: {
    StepWrapper,
    StepTechHeader,
    StepCostHeader,
    StepContactsHeader,
    StepTechHeaderMobile,
    StepExteriorColor,
    StepExteriorEquipment,
    StepExteriorHeader,
    StepExteriorImages,
  },
})
export default class StepExterior extends Mixins<StepMixin>(StepMixin) {
  protected step: string = 'exterior';

  @createStore.State data!: HashMap<any>;
  @createStore.Action setError!: VuexAction;
  @createStore.Action setStep!: VuexAction;
  @createStore.State steps!: HashMap<any>;

  protected validate() {
    const anyProcessedImages = this.data.images && this.data.images.length;

    if (!anyProcessedImages) {
      this.setError({
        images: ['Загрузите фотографии'],
      });
    }

    if (!this.data.specs.color) {
      this.setError({
        'specs.color': ['Укажите цвет'],
      });
    }

    return isEmpty(this.errors);
  }
}
