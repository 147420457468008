
  import { Mixins, Component } from 'vue-mixin-decorator';
  import { namespace } from 'vuex-class';

  import { GoogleBanner } from '@onlinerby/js-vue-components';
  import { ScreenService } from '@onlinerby/js-common';

  import { VuexAction } from '@/types/functions';
  import dictionariesService, {
    Dictionaries,
  } from '@/services/dictionaries-service';
  import Navigation from '@/components/common/navigation.vue';
  import { HashMap } from '@/types/common';
  import { IUser } from '@/interfaces/common';
  import Gtm from '@/mixins/common/gtm';
  import { some } from 'underscore';

  const commonStore = namespace('common');
  const userStore = namespace('user');
  const createStore = namespace('create');

  @Component({
    components: {
      GoogleBanner,
      Navigation,
    },
    metaInfo() {
      return {
        title: 'Купить авто в Беларуси - Автобарахолка Onlíner',
      };
    },
  })
  export default class VehicleMarket extends Mixins<Gtm>(Gtm) {
    private isUserProcessed: boolean = false;

    @commonStore.Action setDictionaries!: VuexAction;
    @commonStore.Action setIsFirstPage!: VuexAction;
    @userStore.Action updateCurrentUser!: VuexAction;
    @userStore.State currentUser!: IUser;
    @createStore.State steps!: HashMap<any>;

    private created() {
      dictionariesService.getDictionaries((dictionaries: Dictionaries) => {
        this.setDictionaries({ dictionaries });
      });

      this.updateCurrentUser({
        callback: () => {
          this.isUserProcessed = true;
        },
      });
      !this.$gtm.enabled() && this.$gtm.enable(true);

      this.$router.beforeEach((to, from, next) => {
        this.clearPageData();
        this.setIsFirstPage({ isFirstPage: false });
        next();
      });
    }

    private get isBannerVisible() {
      const isBannerHidden =
        this.$route.meta.hideBanner ||
        some(this.$route.matched, item => item.meta.hideBanner);

      return ScreenService.checkMinWidth(1000) && !isBannerHidden;
    }
  }
