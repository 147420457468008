var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__step-list" },
    [
      _c(
        "step-wrapper",
        {
          attrs: {
            step: "tech",
            "statuses-by-step": _vm.statusesByStep,
            errors: _vm.errors,
          },
        },
        [
          _c(_vm.componentStepTechHeader, {
            tag: "component",
            attrs: {
              mode: _vm.mode,
              "statuses-by-step": _vm.statusesByStep,
              "manufacturer-id": _vm.data.manufacturerId,
              "manufacturer-name": _vm.manufacturerName,
              "model-id": _vm.data.modelId,
              "model-name": _vm.modelName,
              year: _vm.data.specs.year,
              "generation-name": _vm.generationName,
              "body-type": _vm.bodyType,
              "engine-type": _vm.engineType,
              "engine-capacity": _vm.data.specs.engine.capacity,
              drivetrain: _vm.drivetrain,
              transmission: _vm.transmission,
            },
            on: { "change-step": _vm.onStepChange },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "step-wrapper",
        {
          ref: "description",
          attrs: {
            step: "description",
            "statuses-by-step": _vm.statusesByStep,
            errors: _vm.errors,
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__step-helper vehicle-form__step-helper_specific",
            },
            [
              _c("step-description-header", {
                attrs: {
                  mode: _vm.mode,
                  "statuses-by-step": _vm.statusesByStep,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vehicle-form__row" },
                [
                  _c("step-description-tenure", {
                    attrs: {
                      mode: _vm.mode,
                      data: _vm.data,
                      tenures: _vm.dictionaries.tenure,
                      errors: _vm.errors[_vm.errorMapDescription.tenure],
                    },
                    on: { change: _vm.onTenureChange },
                  }),
                  _vm._v(" "),
                  _c("step-description-odometer", {
                    attrs: {
                      mode: _vm.mode,
                      data: _vm.data,
                      errors: _vm.odometerErrors,
                    },
                    on: { change: _vm.onOdometerChange },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("step-description-text", {
                attrs: {
                  mode: _vm.mode,
                  data: _vm.data,
                  errors: _vm.errors[_vm.errorMapDescription.text],
                },
                on: { change: _vm.onTextChange },
              }),
              _vm._v(" "),
              _c("step-description-video-link", {
                attrs: {
                  mode: _vm.mode,
                  data: _vm.data,
                  errors: _vm.errors[_vm.errorMapDescription.videosUrl],
                },
                on: { change: _vm.onVideoLinkChange },
              }),
              _vm._v(" "),
              _c("step-description-summary", {
                attrs: {
                  mode: _vm.mode,
                  data: _vm.data,
                  errors: _vm.errors[_vm.errorMapDescription.summary],
                },
                on: { change: _vm.onSummaryChange },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "vehicle-form__divider vehicle-form__divider_condensed-excess",
              }),
              _vm._v(" "),
              _c("step-description-photo", {
                attrs: {
                  "current-images": _vm.data.images,
                  errors: _vm.errors[_vm.errorMapDescription.images],
                  project: _vm.projects.review,
                },
                on: { change: _vm.onPhotoChange },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "vehicle-form__divider vehicle-form__divider_condensed-excess",
              }),
              _vm._v(" "),
              _c("step-description-grades", {
                attrs: {
                  mode: _vm.mode,
                  data: _vm.data,
                  "primary-marks": _vm.dictionaries.primaryMarks,
                  errors: _vm.errors[_vm.errorMapDescription.rating],
                },
                on: { change: _vm.onGradesChange },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "vehicle-form__divider vehicle-form__divider_condensed-excess",
              }),
              _vm._v(" "),
              _c("step-description-secondary-parameters", {
                attrs: {
                  mode: _vm.mode,
                  data: _vm.data,
                  errors: _vm.errors[_vm.errorMapDescription.secondaryMarks],
                },
                on: { change: _vm.onSecondParametersChange },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__control vehicle-form__control_condensed-supererogatory",
                },
                [
                  _vm.isEdit
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "button-style button-style_primary button-style_middle vehicle-form__button vehicle-form__button_width_sssm",
                          on: { click: _vm.onFinishClick },
                        },
                        [_vm._v("\n          Подтвердить\n        ")]
                      )
                    : _c(
                        "a",
                        {
                          staticClass:
                            "button-style button-style_primary button-style_middle vehicle-form__button vehicle-form__button_width_xxss",
                          class: { "button-style_animated": _vm.isLoading },
                          on: { click: _vm.onCreateClick },
                        },
                        [_vm._v("\n          Разместить отзыв\n        ")]
                      ),
                ]
              ),
              _vm._v(" "),
              !_vm.isEdit
                ? _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_info",
                    },
                    [
                      _vm._v(
                        "\n        Размещая отзыв, вы соглашаетесь с\n        "
                      ),
                      _c(
                        "a",
                        {
                          staticClass:
                            "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base",
                          attrs: { href: _vm.ruleLink, target: "_blank" },
                        },
                        [_vm._v("\n          правилами")]
                      ),
                      _vm._v(
                        ". Перед публикацией все отзывы проверяются модератором\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }