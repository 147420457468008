
  import { Vue } from 'vue-property-decorator';
  import { Mixins, Component } from 'vue-mixin-decorator';
  import { namespace } from 'vuex-class';
  import { isEmpty, findWhere } from 'underscore';

  import dictionariesService, {
    Dictionary,
  } from '@/services/dictionaries-service';

  import { Dictionaries } from '@/services/dictionaries-service';
  import { VuexAction } from '@/types/functions';
  import { HashMap } from '@/types/common';
  import { IAdvert } from '@/interfaces/adverts';
  import EditStub from '@/components/create/step-edit/edit-stub.vue';
  import GtmMixin from '@/mixins/common/gtm';
  import { IUser } from '@/interfaces/common';
  import dictionariesMap from '@/config/dictionaries-map';
  import { socialIcon } from '@/config/urls';

  const commonStore = namespace('common');
  const createStore = namespace('create');
  const userStore = namespace('user');

  @Component({
    components: {
      EditStub,
    },
    metaInfo(): any {
      return {
        title: 'Редактирование объявления - Автобарахолка Onlíner',
        meta: [
          {
            name: 'og:image',
            content: socialIcon,
          },
        ],
      };
    },
  })
  export default class EditPage extends Mixins<GtmMixin>(GtmMixin) {
    private isLoading: boolean = true;
    private beforeEach!: Function;
    private updateCount: number = 0;

    @commonStore.State dictionaries!: Dictionaries;
    @commonStore.Action setDictionary!: VuexAction;
    @createStore.State data!: HashMap<any>;
    @createStore.State steps!: HashMap<any>;
    @createStore.Action setStep!: VuexAction;
    @createStore.Action setField!: VuexAction;
    @createStore.Action setMode!: VuexAction;
    @createStore.Action initData!: VuexAction;
    @userStore.State currentUser!: IUser;

    created() {
      this.checkStep();

      this.setMode({ mode: 'edit' });

      this.setStep({
        tech: 'complete',
        exterior: 'complete',
        cost: 'complete',
        contacts: 'complete',
      });

      this.beforeEach = this.$router.beforeEach((to, from, next) => {
        if (to.meta.stepName && this.steps[to.meta.stepName] !== 'edit') {
          this.setStep({ [to.meta.stepName]: 'current' });
        }

        next();
      });

      this.init();
    }

    destroyed() {
      this.beforeEach();
    }

    private init() {
      this.initData({
        id: this.$route.params.id,
        success: (data: IAdvert) => {
          if (!data.permissions.edit) {
            this.$router.replace(`/car/${data.id}`);
          }

          this.initDictionaries();
          this.updateGtm(data);
        },
        error: () => {
          this.$router.replace(`/car/${this.$route.params.id}`);
        },
      });
    }

    private initDictionaries() {
      this.updateDictionary('manufacturer', {
        manufacturer: this.data.manufacturerId,
      });
      this.updateDictionary('model', {
        manufacturer: this.data.manufacturerId,
        model: this.data.modelId,
      });
      this.updateDictionary('country', {
        country: this.data.country,
      });
      this.updateDictionary('region', {
        country: this.data.country,
        region: this.data.region,
      });
    }

    private updateDictionary(name: string, data: HashMap<number>) {
      const dictionaryUrl = dictionariesService.buildUrl(name, data);

      this.updateCount++;

      dictionariesService.getDictionary(name, data, (data: any) => {
        this.setDictionary({
          rootName: dictionariesMap[name].rootName,
          name: dictionaryUrl,
          dictionary: data,
        });

        this.updateCount--;

        if (!this.updateCount) {
          this.isLoading = false;
        }
      });
    }

    private checkStep() {
      if (this.$route.name !== 'edit-init') {
        this.$router.replace({ name: 'edit-init' });
      }
    }

    private updateGtm(advert: IAdvert) {
      const {
        id,
        location,
        specs,
        price,
        manufacturer,
        seller,
        author,
      } = advert;

      this.setPageData({
        ad_id: id,
        ad_region: `${location.country.id}_${location.region.id}_${location.city.id}`,
        ad_condition: specs.state,
        ad_price: price.converted.USD.amount,
        ad_currency: 'USD',
        ad_mfr: manufacturer.id,
        seller_id: author.id.toString(),
        seller_type: seller.type,
        user_id: this.currentUser ? this.currentUser.id.toString() : '',
      });

      this.$gtm.trackEvent({
        event: 'content-view',
        'content-view-name': 'ad_edit',
      });
    }
  }
