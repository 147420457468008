
  import { Vue, Component, Watch } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';

  import { IUser } from '@/interfaces/common';
  import background from '@/filters/format-background';
  import { Counters } from '@/types/common';
  import webSocketService from '@/services/ws-service';
  import { VuexAction } from '@/types/functions';

  const advertsStore = namespace('adverts');
  const userStore = namespace('user');

  @Component({
    filters: {
      background,
    },
  })
  export default class UserPage extends Vue {
    private counters: Counters | null = null;
    private user: IUser | null = null;
    @userStore.State currentUser!: IUser;

    @advertsStore.Action updateOnlineStatus!: VuexAction;

    private isPage(name: string) {
      return this.$route.matched.map(item => item.name).includes(name);
    }

    private get isOnline() {
      return this.user && this.user.onlineStatus?.isOnline;
    }

    private get isOwn() {
      return (
        this.currentUser && this.user && this.currentUser.id === this.user.id
      );
    }

    private subscribeWebSockets(userId: string) {
      webSocketService.trackUserActivity(userId, {
        'activity.updated': ({
          status,
          last_activity,
        }: {
          status: number;
          last_activity: string;
        }) => {
          this.updateOnlineStatus({
            onlineStatus: {
              isOnline: !!status,
              lastActivityAt: last_activity,
            },
          });
        },
      });
    }

    private onCountersUpdate(counters: Counters) {
      this.counters = counters;
    }

    private onUserUpdate(user: IUser) {
      this.user = user;
    }

    @Watch('user')
    private onUserChange(user: IUser) {
      this.subscribeWebSockets(user.id);
    }
  }
