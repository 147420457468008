var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__vin" }, [
    _c(
      "div",
      { staticClass: "vehicle-form__row vehicle-form__row_extended-either" },
      [
        _c("div", { staticClass: "vehicle-form__group" }, [
          _c(
            "div",
            { staticClass: "vehicle-form__label vehicle-form__label_base" },
            [
              _c("div", { staticClass: "vehicle-form__label-flex" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__label-part vehicle-form__label-part_1",
                  },
                  [
                    _c("div", { staticClass: "vehicle-form__label-title" }, [
                      _vm._v("VIN-номер"),
                    ]),
                    _vm._v(" "),
                    _vm.state && _vm.state !== "new"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__label-description vehicle-form__label-description_other",
                          },
                          [
                            _vm._v(
                              "\n              (необязательно)\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass:
                    "vehicle-form__label-part vehicle-form__label-part_2",
                }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__field" }, [
            _c(
              "div",
              {
                staticClass: "vehicle-form__line vehicle-form__line_condensed",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "input-style__wrapper vehicle-form__input-wrapper",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.vin,
                          expression: "vin",
                        },
                      ],
                      staticClass:
                        "input-style input-style_primary input-style_base vehicle-form__input vehicle-form__input_width_ssx",
                      class: { "input-style_error": _vm.error },
                      attrs: {
                        type: "text",
                        maxlength: "17",
                        autocomplete: "off",
                      },
                      domProps: { value: _vm.vin },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.vin = $event.target.value
                          },
                          _vm.onInput,
                        ],
                      },
                    }),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "vehicle-form__line vehicle-form__line_condensed",
              },
              [
                _vm.error
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail",
                      },
                      [
                        _vm._v(
                          "\n            " + _vm._s(_vm.error) + "\n          "
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_base vehicle-form__description_other",
                      },
                      [
                        _vm._v(
                          "\n            Номер содержит 17 символов\n          "
                        ),
                      ]
                    ),
              ]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed-either",
      },
      [_c("step-cost-vin-info")],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_font-weight_semibold vehicle-form__description_condensed",
      },
      [
        _vm._v("\n    Объявления с VIN-номером"),
        _c("br"),
        _vm._v("\n    привлекают к себе больше внимания\n  "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed-either",
      },
      [
        _vm._v("\n    Ваше объявление получит специальный значок: "),
        _c("br", { staticClass: "helpers_show_tablet" }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "button-style button-style_primary button-style_small button-style_noreflex vehicle-form__button vehicle-form__button_tally",
          },
          [_vm._v("\n      VIN\n    ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }