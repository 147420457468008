var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-advert" }, [
    _c(
      "div",
      { staticClass: "vehicle-form" },
      [
        _vm.user
          ? [
              _c("div", { staticClass: "vehicle-form__person" }, [
                _c("div", { staticClass: "vehicle-form__person-list" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__person-item vehicle-form__person-item_extended",
                    },
                    [
                      _c("div", { staticClass: "vehicle-form__preview" }, [
                        _c("div", {
                          staticClass: "vehicle-form__image",
                          style: _vm._f("background")(_vm.user.avatar),
                        }),
                        _vm._v(" "),
                        _vm.isOnline
                          ? _c("div", {
                              staticClass:
                                "vehicle-form__status vehicle-form__status_online",
                            })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
                        },
                        [
                          _vm._v(
                            "\n              Профиль пользователя\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_ellipsis",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.user.name) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "vehicle-form__anchor" }, [
                _c(
                  "div",
                  { staticClass: "vehicle-form__anchor-list" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "vehicle-form__anchor-item",
                        class: {
                          "vehicle-form__anchor-item_active":
                            _vm.isPage("adverts"),
                        },
                        attrs: {
                          to: {
                            name: "active-adverts",
                            params: { id: _vm.user.id },
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "vehicle-form__anchor-title" },
                          [
                            _vm._v(
                              "\n              Объявления\n\n              "
                            ),
                            _vm.counters
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "vehicle-form__anchor-amount",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.counters.adverts) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "vehicle-form__anchor-item",
                        class: {
                          "vehicle-form__anchor-item_active":
                            _vm.isPage("user-reviews"),
                        },
                        attrs: {
                          to: {
                            name: "user-reviews",
                            params: { id: _vm.user.id },
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "vehicle-form__anchor-title" },
                          [
                            _vm._v("\n              Отзывы\n\n              "),
                            _vm.counters
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "vehicle-form__anchor-amount",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.counters.reviews) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "vehicle-form__tabs" }, [
          _c(
            "div",
            { staticClass: "vehicle-form__tabs-list" },
            [
              _c("router-view", {
                attrs: { "is-own": _vm.isOwn },
                on: {
                  "counters-update": _vm.onCountersUpdate,
                  "user-update": _vm.onUserUpdate,
                },
              }),
            ],
            1
          ),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }