import { isEmpty } from 'underscore';
import { GetterTree } from 'vuex';
import { IRootState, IUserState } from '@/interfaces/store';

const getters: GetterTree<IUserState, IRootState> = {
  isAuthenticated(state): boolean {
    return !isEmpty(state.currentUser);
  },
};

export default getters;
