var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", {
    staticClass:
      "button-style button-style_auxiliary button-style_base vehicle-form__button vehicle-form__button_favorite-alter",
    class: {
      "vehicle-form__button_favorite-alter_active": _vm.advert.inBookmarks,
    },
    attrs: { title: _vm.title },
    on: {
      click: function ($event) {
        $event.stopPropagation()
        $event.preventDefault()
        return _vm.onBookmarkClick.apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }