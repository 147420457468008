
  import { Component, Mixins } from 'vue-property-decorator';
  import IsMobileMixin from '@/mixins/common/is-mobile';
  import ReviewCardMixin from '@/mixins/reviews/common/review-card';

  interface IMixins extends IsMobileMixin, ReviewCardMixin {}

  @Component
  export default class ReviewCardLite extends Mixins<IMixins>(
    IsMobileMixin,
    ReviewCardMixin,
  ) {}
