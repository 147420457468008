
  import { Vue, Component, Prop } from 'vue-property-decorator';

  import { Review } from '@/types/review';
  import relativeDate from '@/filters/format-relative-date';
  import { URL_REGEXP } from '@/config/common';

  @Component({
    filters: {
      relativeDate,
    },
  })
  export default class ReviewStatus extends Vue {
    @Prop() review!: Review;

    private get closureReason() {
      return this.review.closureReason.replace(
        URL_REGEXP,
        url => '<a href="' + url + '" target="_blank">' + url + '</a>',
      );
    }
  }
