
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import { Dictionaries } from '@/services/dictionaries-service';
import FieldMixin from '@/mixins/create/field';

const commonStore = namespace('common');

@Component
export default class StepTechTransmission extends Mixins<FieldMixin>(
  FieldMixin
) {
  @commonStore.State dictionaries!: Dictionaries;

  private get specs() {
    return this.data.specs || {};
  }

  private get transmission() {
    return this.specs.transmission;
  }

  private setTransmission(transmission: string) {
    this.clearError({ name: 'specs.transmission' });
    this.setField({ specs: { ...this.specs, transmission } });
  }

  private get error() {
    return (this.errors['specs.transmission'] || []).join('. ');
  }
}
