
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Features } from '@/types/promote';
import { VuexAction } from '@/types/functions';
import PromotePackage from '@/components/promote/promote-package.vue';

const promoteStore = namespace('promote');

@Component({
  components: {
    PromotePackage,
  },
})
export default class PromotePackages extends Vue {
  @promoteStore.State features!: Features;
}
