import { HashMap } from '@/types/common';

export const singleFilters: HashMap<string> = {
  include_vat: 'Цена с НДС',
  exchange: 'Обмен',
  hybrid: 'Гибрид',
  gas: 'Газобаллонное оборудование',
  warranty: 'На гарантии',
  customs_clearance: 'Растаможен',
  manual_control: 'Ручное управление',
};
