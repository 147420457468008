
  import { Component, Prop } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { Mixins } from 'vue-mixin-decorator';
  import { isEmpty, each } from 'underscore';

  import notificationsService from '@/services/notifications-service';
  import plural from '@/filters/plural-form';
  import number from '@/filters/format-number';
  import { VuexAction } from '@/types/functions';
  import StateServiceMixin from '@/mixins/state-service';
  import IsMobileMixin from '@/mixins/common/is-mobile';
  import SearchesMixin from '@/mixins/adverts/searches';
  import { IFilter } from '@/interfaces/filters';
  import * as $ from 'jquery';
  import { IUser } from '@/interfaces/common';
  import { Searches } from '@/types/search';
  import { Dictionary } from 'vue-router/types/router';
  import { searchResultsTitle } from '@/config/common';

  const userStore = namespace('user');

  interface IMixins extends StateServiceMixin, IsMobileMixin, SearchesMixin {}

  @Component({
    filters: {
      plural,
      number,
    },
  })
  export default class SearchResults extends Mixins<IMixins>(
    StateServiceMixin,
    SearchesMixin,
    IsMobileMixin,
  ) {
    private isClearVisible: boolean = false;
    private isFixed: boolean = false;
    private isHanged: boolean = false;
    private interval!: NodeJS.Timeout;

    @Prop() pageName!: string;
    @Prop() isSaveEnabled!: boolean;
    @Prop() total!: number;
    @Prop() isLoading!: boolean;
    @Prop() searches!: Searches;
    @Prop() filters!: Array<IFilter>;
    @Prop() clearFilters!: VuexAction;
    @Prop() updateList!: VuexAction;
    @Prop() updateCounters!: VuexAction;
    @Prop() saveSearch!: VuexAction;

    @userStore.State currentUser!: IUser;
    @userStore.Getter isAuthenticated!: boolean;

    private mounted() {
      !this.isMobileSpecial && this.initStick();
    }

    private beforeDestroy() {
      clearInterval(this.interval);

      document.removeEventListener('scroll', this.updatePosition);
    }

    private initStick() {
      document.addEventListener('scroll', this.updatePosition);

      this.interval = setInterval(() => {
        this.updatePosition();
      }, 500);
    }

    private get anyFilters() {
      return !isEmpty(this.filters);
    }

    private get isSearchDisabled() {
      return (
        !this.filters.length || (this.searches && this.searches.length >= 20)
      );
    }

    private get resultsConfig() {
      return searchResultsTitle[this.pageName];
    }

    private updatePosition() {
      const $container: JQuery<HTMLElement> = $('.js-results-container');
      const $results = $(this.$refs.results);
      const $window: JQuery<Window> = $(window);
      const scrollTop = $window.scrollTop();
      const windowHeight = $window.outerHeight();
      const containerHeight = $container.outerHeight();
      const containerTop = $container.offset()!.top;
      const resultsHeight = $results.outerHeight();
      const resultsMargin = parseInt($results.css('margin-top'));

      const bottomBound = scrollTop! + windowHeight!;

      this.isFixed =
        bottomBound > containerTop &&
        bottomBound <
          containerTop + containerHeight! + resultsHeight! + resultsMargin;

      this.isHanged = bottomBound < $container.offset()!.top;
    }

    private showClear() {
      this.isClearVisible = true;

      setTimeout(() => {
        this.isClearVisible = false;
      }, 5000);
    }

    private onClearClick() {
      this.clearFilters();
      this.stateService.setState(this.filters);

      this.$root.$emit('filterChanged');
      this.$root.$emit('filters-clear');

      const action = !this.isMobileSpecial
        ? this.updateList
        : this.updateCounters;

      action();

      this.isClearVisible = false;
    }

    private getFilters() {
      const state: Dictionary<string | number> = {};

      this.filters.forEach(({ name, value }: { name: string; value: any }) => {
        if (value.from) {
          value.from = parseFloat(value.from);
        }

        if (value.to) {
          value.to = parseFloat(value.to);
        }

        state[name] = value as string;
      });

      return state;
    }

    private save() {
      if (this.isAuthenticated) {
        this.saveSearch({
          id: this.currentUser.id,
          data: this.getFilters(),
          callbacks: {
            success: ({ data }: any) => {
              notificationsService.success('Поиск сохранен');

              this.$gtm.trackEvent({
                event: 'save_search',
                value: {
                  search_filters: JSON.stringify(data.data),
                },
              });

              this.refreshSearches();
            },
            error: ({ response }: any) => {
              if (response.status === 422) {
                const { errors } = response.data;
                const parts: Array<string> = [];

                each(errors, (item: string) => {
                  parts.push(item);
                });

                notificationsService.error(parts.join('. '));
              }
            },
          },
        });
      } else {
        const profileAuth = (window as any).profileAuth as {
          showLoginModal: () => {};
        };

        profileAuth.showLoginModal();
      }
    }
  }
