var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.similar.length >= 2
    ? _c("div", { staticClass: "vehicle-form__panel-item" }, [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed",
          },
          [_vm._v("\n    Похожие объявления\n  ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "vehicle-form__carousel" }, [
          !_vm.isMobileSpecial
            ? _c(
                "div",
                { staticClass: "vehicle-form__carousel-list" },
                [
                  _c(
                    "carousel",
                    {
                      staticClass: "vehicle-form__carousel-frame",
                      attrs: {
                        perPageCustom: [
                          [1001, 2],
                          [1140, 3],
                          [1400, 4],
                        ],
                        navigationEnabled: false,
                        paginationEnabled: false,
                        "navigate-to": _vm.page,
                        mouseDrag: false,
                        centerMode: false,
                      },
                      on: { pageChange: _vm.pageChanged },
                    },
                    _vm._l(_vm.similar, function (item) {
                      return _c(
                        "slide",
                        { key: item.id },
                        [
                          _c("advert-similar-item", {
                            attrs: { item: item, advert: _vm.advert },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _c("div", { staticClass: "vehicle-form__carousel-list" }, [
                _c(
                  "div",
                  { staticClass: "vehicle-form__carousel-frame" },
                  _vm._l(_vm.similar, function (item) {
                    return _c("advert-similar-item", {
                      key: item.id,
                      attrs: { item: item, advert: _vm.advert },
                    })
                  }),
                  1
                ),
              ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "vehicle-form__carousel-nav helpers_hide_tablet" },
            [
              _c("div", { staticClass: "vehicle-form__control" }, [
                _c("div", {
                  staticClass:
                    "button-style button-style_auxiliary button-style_small vehicle-form__button vehicle-form__button_prev vehicle-form__button_narrow",
                  class: { disabled: _vm.page === 0 },
                  on: { click: _vm.showPrevPage },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass:
                    "button-style button-style_auxiliary button-style_small vehicle-form__button vehicle-form__button_next vehicle-form__button_narrow",
                  class: { disabled: _vm.page >= _vm.pagesCount - 1 },
                  on: { click: _vm.showNextPage },
                }),
              ]),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }