
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { findWhere } from 'underscore';

import { Dictionaries, DictionaryItem } from '@/services/dictionaries-service';
import { HashMap } from '@/types/common';
import FieldMixin from '@/mixins/create/field';
import DropdownMixin from '@/mixins/common/dropdown';

const commonStore = namespace('common');

interface IMixins extends FieldMixin, DropdownMixin {}

@Component
export default class StepTechBodyType extends Mixins<IMixins>(
  DropdownMixin,
  FieldMixin
) {
  private isAllVisible: boolean = false;

  @commonStore.State dictionaries!: Dictionaries;

  private get specs() {
    return this.data.specs || {};
  }

  private open() {
    this.openDropdown();

    document.body.classList.add('body_state-dropdown-style_visible');
  }

  private close() {
    this.closeDropdown();

    document.body.classList.remove('body_state-dropdown-style_visible');
  }

  private get bodyType() {
    return this.specs.bodyType;
  }

  private set bodyType(bodyType) {
    this.clearError({ name: 'specs.bodyType' });
    this.setField({ specs: { ...this.specs, bodyType } });
  }

  private get bodyTypeName() {
    const bodyType = findWhere(this.dictionaries.bodyType, {
      id: this.bodyType,
    });

    if (!bodyType) {
      return 'Выберите';
    }

    return bodyType.name;
  }

  private get error() {
    return (this.errors['specs.bodyType'] || []).join('. ');
  }

  private onChange() {
    this.close();
  }
}
