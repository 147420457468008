
  import { Component, Prop, Mixins } from 'vue-property-decorator';

  import BestComment from '@/components/comments/best-comment.vue';
  import CommentsForm from '@/components/comments/comments-form.vue';
  import CommentsFormMobile from '@/components/comments/comments-form-mobile.vue';
  import CommentsListBranch from '@/components/comments/comments-list-branch.vue';
  import CommentsListItemStub from '@/components/comments/comments-list-item-stub.vue';
  import ScrollList from '@/components/comments/scroll-list.vue';
  import { Filters, TimeFormatService, UrlService } from '@onlinerby/js-common';
  import CommentsCoreMixin from '@/mixins/comments/comments-core';
  import CommentsScrollMixin from '@/mixins/comments/comments-scroll';
  import CommentsWsMixin from '@/mixins/comments/comments-ws';
  import UserWsMixin from '@/mixins/comments/user-ws';
  import LinksMixin from '@/mixins/comments/links';

  interface IMixins
    extends CommentsCoreMixin,
      CommentsWsMixin,
      CommentsScrollMixin,
      LinksMixin,
      UserWsMixin {}

  @Component({
    components: {
      BestComment,
      CommentsForm,
      CommentsFormMobile,
      CommentsListBranch,
      CommentsListItemStub,
      ScrollList,
    },
    filters: {
      plural: Filters.plural,
    },
  })
  export default class Comments extends Mixins<IMixins>(
    CommentsCoreMixin,
    CommentsWsMixin,
    CommentsScrollMixin,
    LinksMixin,
    UserWsMixin,
  ) {
    @Prop() title!: string;

    protected created() {
      this.updateComments(this.config.limit, () => {
        this.onCommentsInitialized();
      });
    }

    private get activeBanExpireAt() {
      return TimeFormatService.timeTo(this.activeBan?.expiredAt!);
    }

    private get rulesUrl() {
      return UrlService.getCommentRulesUrl(this.projectName);
    }

    private onCommentsInitialized() {
      const urlCommentId = this.$route.query.comment;

      if (!urlCommentId) {
        return;
      }

      this.$nextTick(() => {
        this.goToComment(urlCommentId as string);
      });

      this.$emit('url-scroll');
    }

    protected async onBestReply(payload: {
      commentId: string;
      mention?: {
        name: string;
      };
    }) {
      await this.goToComment(payload.commentId, false);

      this.onReply(payload);
    }
  }
