
  import { Component, Prop } from 'vue-property-decorator';
  import { Mixins } from 'vue-mixin-decorator';
  import Dropdown from '@/components/common/dropdown.vue';
  import FieldMixin from '@/mixins/reviews/create/field';

  @Component({
    components: {
      Dropdown,
    },
  })
  export default class StepDescriptionTenure extends Mixins<FieldMixin>(
    FieldMixin,
  ) {
    @Prop() tenures!: Array<any>;

    private get selectedTenure() {
      return this.data.tenure;
    }

    private onChangeTenure(value) {
      this.onFieldChange(value);
    }
  }
