import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';
import StateService from '@/services/state-service';

@Mixin
class StateServiceMixin extends Vue {
  protected stateService!: StateService;

  protected created() {
    this.stateService = StateService.getInstance(this.$router);
  }
}

export default StateServiceMixin;
