var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__group vehicle-form__group_alter" },
    [
      _c(
        "div",
        { staticClass: "vehicle-form__line vehicle-form__line_condensed" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "vehicle-form__field vehicle-form__field_flex" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_narrow",
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.odometerValue,
                        expression: "odometerValue",
                      },
                    ],
                    staticClass:
                      "input-style input-style_primary input-style_base vehicle-form__input vehicle-form__input_width_xxssmm",
                    class: { "input-style_error": _vm.error },
                    attrs: {
                      type: "text",
                      maxlength: "8",
                      placeholder: "Введите пробег",
                    },
                    domProps: { value: _vm.odometerValue },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.odometerValue = $event.target.value
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "input-style__combo input-style__combo_stretch vehicle-form__input-combo vehicle-form__input-combo_narrow",
                },
                _vm._l(_vm.odometerUnits, function (item, key) {
                  return _c(
                    "a",
                    {
                      key: key,
                      staticClass:
                        "button-style button-style_more button-style_base button-style_bordered vehicle-form__button vehicle-form__button_choose",
                      class: {
                        "button-style_active": key === _vm.odometer.unit,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setOdometerUnit(key)
                        },
                      },
                    },
                    [_vm._v("\n          " + _vm._s(item) + "\n        ")]
                  )
                }),
                0
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            { staticClass: "vehicle-form__line vehicle-form__line_condensed" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_width_full",
                },
                [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("\n            Пробег\n          "),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }