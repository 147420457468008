
  import { Mixins, Component } from 'vue-mixin-decorator';
  import { namespace } from 'vuex-class';

  const { Carousel, Slide } = require('vue-carousel');

  import { IAdvert } from '@/interfaces/adverts';
  import AdvertSimilarItem from '@/components/advert/advert-similar-item.vue';
  import IsMobileMixin from '@/mixins/common/is-mobile';
  import Swiper from '@/directives/swiper';

  const advertStore = namespace('advert');

  @Component({
    components: {
      AdvertSimilarItem,
      Carousel,
      Slide,
    },
    directives: {
      Swiper,
    },
  })
  export default class AdvertSimilar extends Mixins<IsMobileMixin>(
    IsMobileMixin,
  ) {
    private page: number = 0;
    private pageSize: number = 4;

    @advertStore.State advert!: IAdvert;
    @advertStore.State similar!: Array<IAdvert>;

    private created() {
      this.updatePageSize();

      window.addEventListener('resize', this.updatePageSize);
    }

    private get pagesCount() {
      return Math.ceil(this.similar.length / this.pageSize);
    }

    private updatePageSize() {
      const width = window.innerWidth;
      let pageSize = 4;

      if (width >= 1001 && width < 1140) {
        pageSize = 2;
      } else if (width < 1400) {
        pageSize = 3;
      }

      this.pageSize = pageSize;
    }

    private pageChanged(page: number) {
      this.page = page;
    }

    private showPrevPage() {
      this.page--;
    }

    private showNextPage() {
      this.page++;
    }
  }
