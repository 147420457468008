import { Vue } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import { VuexAction } from '@/types/functions';
import { IUser } from '@/interfaces/common';
import { HashMap } from '@/types/common';
import { Dictionary } from '@/services/dictionaries-service';

const searchStore = namespace('search');
const userStore = namespace('user');
const commonStore = namespace('common');

@Mixin
export default class SearchesMixin extends Vue {
  @searchStore.Action updateSearches!: VuexAction;
  @userStore.State currentUser!: IUser;
  @commonStore.Action setDictionary!: VuexAction;

  protected refreshSearches(callback?: Function) {
    this.updateSearches({
      id: this.currentUser.id,
      callbacks: {
        success: ({
          models,
          generations,
          regions,
          cities,
        }: HashMap<Dictionary>) => {
          models &&
            this.setDictionary({
              rootName: 'model',
              name: ``,
              dictionary: models,
            });

          models &&
            this.setDictionary({
              rootName: 'generation',
              name: ``,
              dictionary: generations,
            });

          regions &&
            this.setDictionary({
              rootName: 'region',
              name: ``,
              dictionary: regions,
            });

          cities &&
            this.setDictionary({
              rootName: 'city',
              name: ``,
              dictionary: cities,
            });

          callback && callback();
        },
      },
    });
  }
}
