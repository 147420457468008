
  import { camelizeService } from '@onlinerby/js-api-clients';
  import { Component } from 'vue-property-decorator';
  import { Mixins } from 'vue-mixin-decorator';

  import FieldMixin from '@/mixins/reviews/create/field';
  import vmApi from '@/api/vm-api';
  import { ReviewMarks } from '@/types/review';
  import ProsConsSwitch from '@/components/reviews/create/step-description/pros-cons-switch.vue';
  import { minus, plus } from '@/config/reviews/create';

  @Component({
    components: {
      ProsConsSwitch,
    },
  })
  export default class StepDescriptionSecondaryParameters extends Mixins<
    FieldMixin
  >(FieldMixin) {
    private reviewMarks: ReviewMarks | null = null;

    private get engineType() {
      return this.data.specs.engine.type;
    }

    private get pros(): Array<string> {
      return (
        (this.data.secondaryMarks &&
          this.data.secondaryMarks.pros.map(item => item.id)) ||
        []
      );
    }

    private get cons(): Array<string> {
      return (
        (this.data.secondaryMarks &&
          this.data.secondaryMarks.cons.map(item => item.id)) ||
        []
      );
    }

    private created() {
      this.getReviewMarks();
    }

    private getReviewMarks() {
      vmApi.getReviewMarks({
        success: ({ data }: { data: ReviewMarks }) => {
          data.properties = camelizeService.decamelize(data.properties);
          this.reviewMarks = data;
        },
      });
    }

    private onSwitchChange(property: string, value: string | undefined) {
      this.deletePropertyFromArrays(property);

      switch (value) {
        case plus:
          this.pros.push(property);
          break;
        case minus:
          this.cons.push(property);
          break;
        default:
          break;
      }

      this.onFieldChange({
        pros: this.pros.map(this.getTitleById),
        cons: this.cons.map(this.getTitleById),
      });
    }

    private deletePropertyFromArrays(property: string) {
      const indexPros = this.pros.indexOf(property);
      const indexCons = this.cons.indexOf(property);

      indexPros > -1 && this.pros.splice(indexPros, 1);
      indexCons > -1 && this.cons.splice(indexCons, 1);
    }

    private getCurrentValueByProperty(property: string) {
      return this.pros.includes(property)
        ? plus
        : this.cons.includes(property)
        ? minus
        : undefined;
    }

    private getTitleById(item) {
      return {
        id: item,
        title: this.reviewMarks?.properties[item].title,
      };
    }
  }
