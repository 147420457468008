var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__row vehicle-form__row_extended-other" },
    [
      _c(
        "div",
        { staticClass: "vehicle-form__group vehicle-form__group_width_full" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__field" }, [
            _c(
              "div",
              {
                staticClass:
                  "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_width_full",
              },
              [
                _c(
                  "div",
                  {
                    ref: "dropzoneContainer",
                    staticClass:
                      "drop-style vehicle-form__drop vehicle-form__drop_width_full",
                    class: {
                      "drop-style_dragged": _vm.isDropZoneActive,
                      "drop-style_error": _vm.error,
                    },
                    on: {
                      dragover: _vm.onDragOver,
                      dragleave: _vm.onDragLeave,
                      drop: _vm.onDrop,
                    },
                  },
                  [
                    _c("div", { staticClass: "drop-style__content" }, [
                      !_vm.images.length
                        ? _c("div", { staticClass: "drop-style__upload" }, [
                            _c(
                              "div",
                              { staticClass: "drop-style__upload-list" },
                              [
                                _vm._m(1),
                                _vm._v(" "),
                                _vm._m(2),
                                _vm._v(" "),
                                _vm._m(3),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "drop-style__upload-item drop-style__upload-item_additional",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "drop-style__upload-link",
                                      },
                                      [
                                        _c("input", {
                                          staticClass:
                                            "drop-style__upload-link-real",
                                          attrs: {
                                            type: "file",
                                            multiple: "",
                                            accept: "image/jpeg, image/png",
                                          },
                                          on: { change: _vm.onFilesSelected },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "drop-style__upload-link-faux",
                                          },
                                          [_vm._v("Выбрать файлы")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _c(
                            "div",
                            { staticClass: "drop-style__upload" },
                            [
                              _c(
                                "draggable",
                                {
                                  staticClass:
                                    "drop-style__upload-list drop-style__upload-list_opened",
                                  attrs: { handle: ".js-move" },
                                  on: { end: _vm.onDrag },
                                  model: {
                                    value: _vm.images,
                                    callback: function ($$v) {
                                      _vm.images = $$v
                                    },
                                    expression: "images",
                                  },
                                },
                                [
                                  _vm._l(_vm.images, function (image, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: `photo_${index}`,
                                        staticClass:
                                          "drop-style__upload-item drop-style__upload-item_primary",
                                        class: {
                                          "drop-style__upload-item_animated":
                                            image &&
                                            image.status === "processing",
                                          "drop-style__upload-item_error":
                                            image && image.status === "error",
                                          "js-move": !_vm.isMobile,
                                        },
                                      },
                                      [
                                        image
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "drop-style__upload-preview",
                                              },
                                              [
                                                image.preview
                                                  ? _c("div", {
                                                      staticClass:
                                                        "drop-style__upload-image",
                                                      style: _vm._f(
                                                        "background"
                                                      )(image.preview),
                                                    })
                                                  : image.images
                                                  ? _c("div", {
                                                      staticClass:
                                                        "drop-style__upload-image",
                                                      style: _vm._f(
                                                        "background"
                                                      )(
                                                        _vm.getImageUrl(
                                                          image.images
                                                        )
                                                      ),
                                                    })
                                                  : _vm._e(),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        image.status === "error" &&
                                        image.errorText
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "drop-style__upload-titles",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "drop-style__upload-title drop-style__upload-title_error",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          image.errorText
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "drop-style__upload-control drop-style__upload-control_top",
                                          },
                                          [
                                            _c("a", {
                                              staticClass:
                                                "button-style button-style_transparent button-style_small drop-style__upload-button drop-style__upload-button_remove",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removePhoto(index)
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        image.status === "processed"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "drop-style__upload-control drop-style__upload-control_bottom",
                                              },
                                              [
                                                index === _vm.mainIndex
                                                  ? _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "button-style button-style_additional button-style_small button-style_noreflex drop-style__upload-button drop-style__upload-button_main",
                                                      },
                                                      [_vm._v("Главная")]
                                                    )
                                                  : _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "button-style button-style_transparent button-style_small drop-style__upload-button drop-style__upload-button_main",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.makeMain(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Сделать главной"
                                                        ),
                                                      ]
                                                    ),
                                                _vm._v(" "),
                                                _vm.isMobile
                                                  ? _c("a", {
                                                      staticClass:
                                                        "button-style button-style_transparent button-style_small drop-style__upload-button drop-style__upload-button_move js-move",
                                                    })
                                                  : _vm._e(),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }),
                                  _vm._v(" "),
                                  _vm.images.length < _vm.LIMIT
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "drop-style__upload-item drop-style__upload-item_additional",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "drop-style__upload-link",
                                            },
                                            [
                                              _c("input", {
                                                staticClass:
                                                  "drop-style__upload-link-real",
                                                attrs: {
                                                  type: "file",
                                                  multiple: "",
                                                  accept:
                                                    "image/jpeg, image/png",
                                                },
                                                on: {
                                                  change: _vm.onFilesSelected,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "drop-style__upload-link-faux",
                                                },
                                                [_vm._v("Выбрать файлы")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                      _vm._v(" "),
                      !_vm.images.length
                        ? _c("div", { staticClass: "drop-style__title" }, [
                            _c("span", { staticClass: "helpers_hide_tablet" }, [
                              _vm._v("Перетащите сюда"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "drop-style__capitalize" },
                              [
                                _vm._v(
                                  "до " + _vm._s(_vm.LIMIT) + " фотографий"
                                ),
                              ]
                            ),
                          ])
                        : _vm.images.length < _vm.LIMIT
                        ? _c("div", { staticClass: "drop-style__title" }, [
                            _vm._v(
                              "\n              Осталось " +
                                _vm._s(_vm.LIMIT - _vm.images.length) +
                                " фото\n            "
                            ),
                          ])
                        : _c("div", { staticClass: "drop-style__title" }, [
                            _vm._v(
                              "\n              Вы достигли лимита загрузки фото\n            "
                            ),
                          ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "drop-style__description" }, [
                        _vm._v(
                          "\n              Добавьте фотографии — это повысит доверие покупателей\n              и сделает ваше объявление привлекательнее.\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "drop-style__description" }, [
                        _vm._v(
                          "\n              Вы можете загрузить изображения в формате JPG или PNG.\n              Максимальный размер файла — 20 MB.\n            "
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.error
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "drop-style__hint drop-style__hint_error drop-style__hint_base drop-style__hint_fail",
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(_vm.error) + "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("\n            Фотографии для объявления\n          "),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "drop-style__upload-item drop-style__upload-item_secondary",
      },
      [
        _c("div", { staticClass: "drop-style__upload-preview" }, [
          _c("div", {
            staticClass:
              "drop-style__upload-image drop-style__upload-image_car-front",
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "drop-style__upload-item drop-style__upload-item_secondary",
      },
      [
        _c("div", { staticClass: "drop-style__upload-preview" }, [
          _c("div", {
            staticClass:
              "drop-style__upload-image drop-style__upload-image_car-back",
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "drop-style__upload-item drop-style__upload-item_secondary",
      },
      [
        _c("div", { staticClass: "drop-style__upload-preview" }, [
          _c("div", {
            staticClass:
              "drop-style__upload-image drop-style__upload-image_car-side",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }