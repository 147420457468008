import { Vue } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import { Dictionaries } from '@/services/dictionaries-service';
import { IFilter } from '@/interfaces/filters';
import { findWhere, intersection } from 'underscore';

const commonStore = namespace('common');

@Mixin
export default class FilterObjects extends Vue {
  protected filters!: Array<IFilter>;

  @commonStore.State dictionaries!: Dictionaries;

  protected get brandObject() {
    const car = findWhere(this.filters, { name: 'car' });
    const brand =
      car && (car.value as Array<any>).length === 1
        ? (car.value as Array<any>)[0].manufacturer
        : null;

    if (brand) {
      const brandObject = findWhere(this.dictionaries.manufacturer, {
        id: parseInt(brand),
      });

      return brandObject;
    }

    return '';
  }

  protected get modelObject() {
    const car = findWhere(this.filters, { name: 'car' });
    const model =
      car && (car.value as Array<any>).length === 1
        ? (car.value as Array<any>)[0].model
        : null;

    if (model) {
      const modelObject = findWhere(this.dictionaries.model, {
        id: parseInt(model),
      });

      return modelObject;
    }

    return '';
  }

  protected get generationObject() {
    const car = findWhere(this.filters, { name: 'car' });
    const generation =
      car && (car.value as Array<any>).length === 1
        ? (car.value as Array<any>)[0].generation
        : null;

    if (generation && generation.length === 1) {
      const generationObject = findWhere(this.dictionaries.generation, {
        id: parseInt(generation),
      });

      return generationObject;
    }

    return '';
  }

  protected get location() {
    const location = findWhere(this.filters, { name: 'location' });

    return location ? location.value : {};
  }

  protected get countryId() {
    return parseInt((this.location as { country: string }).country);
  }

  protected get countryObject() {
    if (this.countryId) {
      const countryObject = findWhere(this.dictionaries.country, {
        id: this.countryId,
      });

      return countryObject;
    }

    return '';
  }

  protected get cityId() {
    return parseInt((this.location as { city: string }).city);
  }

  protected get cityObject() {
    if (this.cityId) {
      const cityObject = findWhere(this.dictionaries.city, {
        id: this.cityId,
      });

      return cityObject;
    }

    return '';
  }

  protected get isDealer() {
    const sellerType = findWhere(this.filters, { name: 'seller_type' });

    if (!sellerType) {
      return '';
    }

    const inter = intersection(sellerType.value as Array<string>, [
      'autohaus',
      'dealer',
    ]);

    return (
      inter.length === 2 && (sellerType.value as Array<string>).length === 2
    );
  }

  protected get isVatIncluded() {
    const isVatIncluded = findWhere(this.filters, { name: 'include_vat' });

    if (!isVatIncluded) {
      return false;
    }

    return isVatIncluded.value;
  }

  protected get state() {
    const state = findWhere(this.filters, { name: 'state' });

    if (state && (state.value as Array<string>).length === 1) {
      return (state.value as Array<string>)[0];
    }

    return '';
  }
}
