var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__row vehicle-form__row_extended-other" },
    [
      _c("div", { staticClass: "vehicle-form__group" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "vehicle-form__field" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__line vehicle-form__line_condensed-other",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "input-style__wrapper vehicle-form__input-wrapper",
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.modification,
                        expression: "modification",
                      },
                    ],
                    staticClass:
                      "input-style input-style_primary input-style_base vehicle-form__input vehicle-form__input_width_ll",
                    attrs: {
                      type: "text",
                      placeholder: "Например, GT, CL, LX, WRC",
                      maxlength: "30",
                    },
                    domProps: { value: _vm.modification },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.modification = $event.target.value
                      },
                    },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.error
            ? _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__line vehicle-form__line_condensed-other",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_xs",
                    },
                    [_vm._v(_vm._s(_vm.error))]
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("\n            Модификация\n            "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__label-description vehicle-form__label-description_other",
                  },
                  [_vm._v("(необязательно)")]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }