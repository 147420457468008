import { ICreateState } from '@/interfaces/reviews/store';
import { stepStatuses, defaultData } from '@/config/reviews/create';

export const defaultStatusesByStep = {
  tech: stepStatuses.current,
  description: stepStatuses.pristine,
};

export const defaultState = {
  data: defaultData,
  mode: 'create',
  statusesByStep: defaultStatusesByStep,
  errors: {},
};

const state: ICreateState = { ...defaultState };

export default state;
