import { Mixin } from 'vue-mixin-decorator';
import { Vue } from 'vue-property-decorator';
import { HashMap } from '@onlinerby/js-common';

@Mixin
export default class Grades extends Vue {
  protected get grades(): HashMap<any> {
    return {};
  }

  private get avgGrade(): number {
    const grades = Object.values(this.grades);
    const sumGrade = grades.reduce((acc, grade) => {
      acc += grade;
      return acc;
    }, 0);

    const avgGrade = sumGrade / grades.length;

    return Math.round(avgGrade * 10) / 10 || 0;
  }
}
