
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { findWhere } from 'underscore';

import { Dictionaries } from '@/services/dictionaries-service';
import capitalize from '@/filters/format-capitalize';
import { VuexAction } from '@/types/functions';
import { HashMap } from '@/types/common';
import StepHeader from '@/mixins/create/step-header';
import { powerTypes } from '@/config/dictionaries';

const createStore = namespace('create');
const commonStore = namespace('common');

@Component({
  filters: {
    capitalize,
  },
})
export default class StepTechHeader extends Mixins<StepHeader>(StepHeader) {
  protected step: string = 'tech';

  @commonStore.State dictionaries!: Dictionaries;
  @createStore.State data!: HashMap<any>;
  @createStore.State mode!: string;

  @createStore.Action setField!: VuexAction;

  private get isEditable() {
    return this.mode === 'create' && (this.isCurrent || this.isEdit);
  }

  private get manufacturerName() {
    const manufacturer = findWhere(this.dictionaries.manufacturer, {
      id: this.data.manufacturerId,
    });

    if (!manufacturer) {
      return;
    }

    return manufacturer.name;
  }

  private get modelName() {
    const manufacturer = findWhere(this.dictionaries.manufacturer, {
      id: this.data.manufacturerId,
    });
    const model = findWhere(this.dictionaries.model, {
      id: this.data.modelId,
    });

    if (!model) {
      return;
    }

    return model.name;
  }

  private get generationName() {
    const { modelId, manufacturerId, generationId } = this.data;

    const manufacturer = findWhere(this.dictionaries.manufacturer, {
      id: this.data.manufacturerId,
    });
    const model = findWhere(this.dictionaries.model, {
      id: modelId,
    });

    if (!model) {
      return '';
    }

    const generation = findWhere(this.dictionaries.generation, {
      id: generationId,
    });

    if (!generation) {
      return '';
    }

    return generation.name;
  }

  private get specs() {
    return this.data.specs || {};
  }

  private get modification() {
    return this.specs.modification;
  }

  private get year() {
    return this.specs.year;
  }

  private get bodyType() {
    const bodyType = findWhere(this.dictionaries.bodyType, {
      id: this.specs.bodyType,
    });

    return bodyType!.name;
  }

  private get engineType() {
    const engineType = findWhere(this.dictionaries.engineType, {
      id: this.specs.engine.type,
    });

    return engineType!.name;
  }

  private get engineCapacity() {
    return `${this.specs.engine.capacity} л`;
  }

  protected get power(): string {
    const parts: Array<string> = [];
    const { power, torque } = this.specs.engine;

    if (power && power.value) {
      const unit = powerTypes[power.unit];

      parts.push(`${power.value} ${unit}`);
    }

    if (torque) {
      parts.push(`${torque} Нм`);
    }

    return parts.join(' / ');
  }

  private get drivetrain() {
    const drivetrain = findWhere(this.dictionaries.drivetrain, {
      id: this.specs.drivetrain,
    });

    return drivetrain!.name;
  }

  private get transmission() {
    const transmission = findWhere(this.dictionaries.transmission, {
      id: this.specs.transmission,
    });

    return transmission!.name;
  }

  private get manualControl() {
    return this.specs.manualControl;
  }

  private resetManufacturer() {
    this.setField({
      manufacturerId: undefined,
      modelId: undefined,
    });
  }

  private resetModel() {
    this.setField({
      modelId: undefined,
    });
  }
}
