import { Module } from 'vuex';
import state from './state';
import actions from './actions';
import mutations from './mutations';
import { IRootState, IAdvertState } from '@/interfaces/store';

const namespaced: boolean = true;

const module: Module<IAdvertState, IRootState> = {
  namespaced,
  state,
  actions,
  mutations,
};

export default module;
