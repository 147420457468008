
import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import AdvertsListPagination from '@/components/adverts/adverts-list-pagination.vue';
import { IUser } from '@/interfaces/common';
import Commercial from '@/components/common/commercial.vue';
import { socialIcon } from '@/config/urls';
import { Counters } from '@/types/common';

const userStore = namespace('user');
const advertsStore = namespace('adverts');

@Component({
  components: {
    AdvertsListPagination,
    Commercial,
  },
  metaInfo(): any {
    return {
      title: 'Объявления пользователя - Автобарахолка Onlíner',
      meta: [
        {
          name: 'og:image',
          content: socialIcon,
        },
      ],
    };
  },
})
export default class Adverts extends Vue {
  @advertsStore.State isLoading!: boolean;
  @advertsStore.State user!: IUser;
  @userStore.State currentUser!: IUser;

  private updateMeta() {
    this.$metaInfo.title = `Объявления пользователя ${this.user.name} - Автобарахолка Onlíner`;

    this.$meta().refresh();
  }

  private onCountersUpdate(counters: Counters) {
    this.$emit('counters-update', counters);
  }

  private onUserUpdate(user: IUser) {
    this.$emit('user-update', user);
    this.updateMeta();
  }
}
