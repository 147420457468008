var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__feature-item" }, [
    _c("div", { staticClass: "vehicle-form__feature-box" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_bold helpers_hide_tablet",
        },
        [_vm._v("\n      Информация об автомобиле\n    ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "vehicle-form__parameter" }, [
        _c("div", { staticClass: "vehicle-form__parameter-list" }, [
          _c("div", { staticClass: "vehicle-form__parameter-item" }, [
            _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.specs.year) +
                          "\n              "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__parameter-item" }, [
            _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("number")(_vm.specs.odometer.value)) +
                          " " +
                          _vm._s(_vm.odometerUnit) +
                          "\n              "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__parameter-item" }, [
            _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("capitalize")(_vm.bodyType)) +
                          "\n              "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__parameter-item" }, [
            _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
              _vm._m(3),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.engine) +
                          "\n              "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.power
            ? _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                  _vm._m(4),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter",
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.power) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__parameter-item" }, [
            _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
              _vm._m(5),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("capitalize")(_vm.transmission)) +
                          "\n              "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__parameter-item" }, [
            _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
              _vm._m(6),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("capitalize")(_vm.drivetrain)) +
                          "\n              "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
          },
          [_vm._v("\n                Год выпуска\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
          },
          [_vm._v("\n                Пробег\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
          },
          [_vm._v("\n                Кузов\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
          },
          [_vm._v("\n                Двигатель\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
          },
          [_vm._v("\n                Мощность\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
          },
          [_vm._v("\n                Коробка\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
          },
          [_vm._v("\n                Привод\n              ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }