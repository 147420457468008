import DeviceDetectService from '@/services/device-detect-service';

type DeviceConfig = {
  device: string;
  browser: string;
  deviceClass: string;
};

const onUpdate = (element: HTMLElement, { value }: any) => {
  const deviceInfo = DeviceDetectService.detect();

  value.forEach((item: DeviceConfig) => {
    const deviceCheckPass = !item.device || deviceInfo.device === item.device;
    const browserCheckPass =
      !item.browser || deviceInfo.browser === item.browser;

    if (deviceCheckPass && browserCheckPass) {
      $(element).addClass(item.deviceClass);
    }
  });
};

export default {
  inserted: onUpdate,
  update: onUpdate,
};
