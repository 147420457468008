
  import { Component, Prop } from 'vue-property-decorator';
  import { Mixins } from 'vue-mixin-decorator';
  import { findWhere } from 'underscore';

  import DropdownMixin from '@/mixins/common/dropdown';

  @Component
  export default class Dropdown extends Mixins<DropdownMixin>(DropdownMixin) {
    @Prop() placeholder!: string;
    @Prop() options!: Array<{
      id: number;
      name: string;
    }>;
    @Prop() selectedValue!: number;
    @Prop() isError!: boolean;

    private get selectedLabel() {
      if (!this.selectedValue) {
        return;
      }

      return findWhere(this.options, { id: this.selectedValue })!.name;
    }

    protected get field() {
      return this.selectedValue;
    }

    protected set field(value) {}

    private onChange(value) {
      this.$emit('change', value);

      this.onClose();
    }

    protected onOpen() {
      this.openDropdown();

      document.body.classList.add('body_state-dropdown-style_visible');
    }

    protected onClose() {
      this.closeDropdown();

      document.body.classList.remove('body_state-dropdown-style_visible');
    }
  }
