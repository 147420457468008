
  import { Prop } from 'vue-property-decorator';
  import { Mixins, Component } from 'vue-mixin-decorator';

  import { UrlService } from '@onlinerby/js-common';

  import { Review } from '@/types/review';
  import SwiperMixin from '@/mixins/common/swiper';
  import ReviewCardVertical from '@/components/reviews/common/review-card-vertical.vue';
  import vmApi from '@/api/vm-api';

  @Component({
    components: {
      ReviewCardVertical,
    },
  })
  export default class ReviewSimilar extends Mixins<SwiperMixin>(SwiperMixin) {
    @Prop() review!: Review;

    private items: Review[] = [];

    private created() {
      this.updateSimilarReviews();
    }

    private get title() {
      return [
        this.review.manufacturer.name,
        this.review.model.name,
        this.review.generation.name,
      ]
        .filter(item => item)
        .join(' ');
    }

    private get searchUrl() {
      const { model, manufacturer, generation } = this.review;

      return UrlService.secureProjectUrl(
        'ab',
        `reviews/${manufacturer.slug}/${model.slug}/${generation.slug}`,
      );
    }

    private updateSimilarReviews() {
      vmApi.getSimilarReviews(this.review?.id, {
        success: ({ data }: { data: Review[] }) => {
          this.items = data;
        },
        complete: () => {
          this.$nextTick(this.initSwiper);
        },
      });
    }
  }
