import { Mixins } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';

import CommentsCoreMixin from '@/mixins/comments/comments-core';
import WebsocketService from '@/services/websocket-service';
import { Comment } from '@/types/comments';

@Mixin
export default class CommentsWsMixin extends Mixins<CommentsCoreMixin>(
  CommentsCoreMixin,
) {
  private COMMENTS_WEBSOCKET_CHANNEL = `comments.${this.projectName}.${this.entityType}.${this.entityId}`;
  protected commentsWebsocketService: WebsocketService = new WebsocketService(
    {
      userId: this.currentUser?.id,
    },
    [this.COMMENTS_WEBSOCKET_CHANNEL],
  );

  protected created() {
    this.subscribeCommentsEvents();
  }

  private subscribeCommentsEvents() {
    this.addCommentsHandler(
      'counters.updated',
      ({ counters: { total } }: { counters: { total: number } }) => {
        this.total = total;
      },
    );

    this.addCommentsHandler(
      'comment.created',
      ({ comment }: { comment: Comment }) => {
        const extendedComment = {
          ...comment,
          isHighlighted: !this.isOwnComment(comment),
          isPending: false,
        };

        comment.parentId
          ? this.onReplyCreated(extendedComment)
          : this.onCommentCreated(extendedComment);
      },
    );

    this.addCommentsHandler(
      'comment.updated',
      ({ comment }: { comment: Comment }) => {
        this.updateComment(comment);
      },
    );

    this.addCommentsHandler(
      'comment.deleted',
      ({ comment }: { comment: Comment }) => {
        this.updateComment({ ...comment, isDeleted: true });
      },
    );

    this.addCommentsHandler(
      'comment.best_updated',
      ({ comment, parent }: { comment: Comment; parent: Comment }) => {
        this.best = comment
          ? {
              comment,
              parent,
            }
          : null;
      },
    );

    this.addCommentsHandler(
      'comment.marks_updated',
      ({ comment, parent }: { comment: Comment; parent: Comment }) => {
        this.updateComment(comment);

        if (this.best?.comment.id === comment.id) {
          this.best.comment.marks = comment.marks;
        }

        if (this.best?.parent?.id === comment.id) {
          this.best.parent.marks = comment.marks;
        }
      },
    );
  }

  private addCommentsHandler(event: string, handler: Function) {
    this.commentsWebsocketService.addHandler(
      this.COMMENTS_WEBSOCKET_CHANNEL,
      event,
      handler,
    );
  }
}
