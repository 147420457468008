
import { Mixins, Component } from 'vue-mixin-decorator';
import * as moment from 'moment';

import FieldMixin from '@/mixins/create/field';
import { range } from '@/services/utils-service';
import { ChangeEvent } from '@/types/events';

@Component
export default class StepContactsSellerCall extends Mixins<FieldMixin>(
  FieldMixin
) {
  private isAllDay: boolean = false;

  created() {
    this.call.from === undefined && this.setTime('from', '9');
    this.call.to === undefined && this.setTime('to', '21');

    this.isAllDay = this.from === this.to;
  }

  private get seller() {
    return this.data.seller || {};
  }

  private get call() {
    return this.seller.call || {};
  }

  private get from() {
    return this.getTime(this.call.from);
  }

  private set from(value) {
    this.setTime('from', value);
  }

  private get to() {
    return this.getTime(this.call.to);
  }

  private set to(value) {
    this.setTime('to', value);
  }

  private get options() {
    return range(0, 24);
  }

  private getTime(value: string) {
    return value ? moment(value, 'HH:mmZ').format('H') : '0';
  }

  private setTime(field: string, value: string) {
    const call = {
      ...this.call,
      [field]: moment(value, 'H').format('HH:mmZ'),
    };

    this.setField({ seller: { ...this.seller, call } });
  }

  private onAllDayChange(event: ChangeEvent) {
    if (event.target.value) {
      this.from = '0';
      this.to = '0';
    }
  }
}
