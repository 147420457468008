
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ILeasingItem } from '@/interfaces/adverts';

import currency from '@/filters/format-currency';
import background from '@/filters/format-background';
import plural from '@/filters/plural-form';

@Component({
  filters: {
    currency,
    background,
    plural,
  },
})
export default class AdvertLeasingItem extends Vue {
  @Prop() item!: ILeasingItem;

  private onClick() {
    this.$emit('click', this.item);
  }
}
