var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-list" }, [
    _c(
      "div",
      { staticClass: "vehicle-form" },
      [
        _c("reviews-counter"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vehicle-form__title vehicle-form__title_big-alter" },
          [
            _c("div", { staticClass: "vehicle-form__title-flex" }, [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__title-part vehicle-form__title-part_1",
                },
                [
                  _c(
                    "h1",
                    {
                      staticClass:
                        "vehicle-form__title vehicle-form__title_big-alter",
                      attrs: { р1: "" },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.header || "Отзывы об автомобилях") +
                          "\n          "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__title-part vehicle-form__title-part_2",
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "device-class",
                          rawName: "v-device-class",
                          value: [
                            {
                              device: "iphonex",
                              deviceClass: "vehicle-form__control_bar_alter",
                            },
                            {
                              device: "iphonexsmax",
                              deviceClass: "vehicle-form__control_bar_alter",
                            },
                            {
                              device: "iphonexr",
                              deviceClass: "vehicle-form__control_bar_alter",
                            },
                          ],
                          expression:
                            "[\n              {\n                device: 'iphonex',\n                deviceClass: 'vehicle-form__control_bar_alter',\n              },\n              {\n                device: 'iphonexsmax',\n                deviceClass: 'vehicle-form__control_bar_alter',\n              },\n              {\n                device: 'iphonexr',\n                deviceClass: 'vehicle-form__control_bar_alter',\n              },\n            ]",
                        },
                      ],
                      staticClass:
                        "vehicle-form__control vehicle-form__control_bar",
                    },
                    [
                      _c(
                        "router-link",
                        {
                          directives: [
                            {
                              name: "gtm",
                              rawName: "v-gtm",
                              value: { event: "create_review" },
                              expression: "{ event: 'create_review' }",
                            },
                          ],
                          staticClass:
                            "button-style button-style_appendant button-style_base vehicle-form__button",
                          attrs: { to: { name: "create-review" } },
                        },
                        [_vm._v("\n              Написать отзыв\n            ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "vehicle-form__filter" }, [
          _c(
            "div",
            { staticClass: "vehicle-form__filter-flex" },
            [
              _c("div", {
                staticClass: "vehicle-form__filter-overlay helpers_show_tablet",
                on: { click: _vm.hideFilters },
              }),
              _vm._v(" "),
              _c("search-filters-block", {
                attrs: {
                  "page-name": "reviews",
                  "is-loaded": _vm.isLoaded,
                  "is-loading": _vm.isLoading,
                  total: _vm.total,
                  "clear-filters": _vm.clearFilters,
                  "set-filter": _vm.setFilter,
                  "state-counters": _vm.stateCounters,
                  "get-filter": _vm.getFilter,
                  "remove-filter": _vm.removeFilter,
                  filters: _vm.filters,
                  "update-list": _vm.getReviews,
                  "update-counters": _vm.updateCounters,
                  "filters-config": _vm.filtersConfig,
                  "schema-properties": _vm.schemaProperties,
                },
              }),
              _vm._v(" "),
              _c("search-reviews", {
                attrs: {
                  "is-loaded": _vm.isLoaded,
                  "update-list": _vm.getReviews,
                  "set-filter": _vm.setFilter,
                  "state-counters": _vm.stateCounters,
                  "get-filter": _vm.getFilter,
                  "remove-filter": _vm.removeFilter,
                  filters: _vm.filters,
                  "reviews-rating": _vm.total < 1 ? null : _vm.reviewsRating,
                  manufacturer: _vm.brandObject,
                  model: _vm.modelObject,
                  generation: _vm.generationObject,
                },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }