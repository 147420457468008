
import { Vue, Component } from 'vue-property-decorator';

import PromoteAdvertsHeader from '@/components/promote/promote-adverts-header.vue';
import PromoteAdvertsList from '@/components/promote/promote-adverts-list.vue';

@Component({
  components: {
    PromoteAdvertsHeader,
    PromoteAdvertsList,
  },
})
export default class PromoteAdverts extends Vue {}
