export default (value: number) => {
  let hours: string | number = Math.floor(value / 3600);
  let minutes: string | number = Math.floor((value - hours * 3600) / 60);
  let seconds: string | number = value - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }

  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return hours + ':' + minutes + ':' + seconds;
};
