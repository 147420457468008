var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__step-helper" }, [
    _c(
      "div",
      { staticClass: "vehicle-form__row vehicle-form__row_condensed-fringe" },
      [
        _c("div", { staticClass: "vehicle-form__group" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__field" }, [
            _c(
              "div",
              {
                staticClass: "input-style__wrapper vehicle-form__input-wrapper",
              },
              [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.description,
                      expression: "description",
                    },
                  ],
                  staticClass:
                    "input-style input-style_primary input-style_small input-style_resize_vertical vehicle-form__input vehicle-form__input_width_xxllm vehicle-form__input_min-height_xssm vehicle-form__input_max-height_m",
                  attrs: { maxlength: "10000" },
                  domProps: { value: _vm.description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.description = $event.target.value
                    },
                  },
                }),
              ]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_width_xxllm",
      },
      [
        _vm._v(
          "Опишите ваш автомобиль подробнее, укажите его особенности, преимущества и недостатки."
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("\n              Комментарий\n              "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__label-description vehicle-form__label-description_other",
                  },
                  [_vm._v("(необязательно)")]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }