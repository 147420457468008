
  import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import ReviewList from '@/components/reviews/list/review-list.vue';
  import ReviewListEmpty from '@/components/reviews/list/review-list-empty.vue';
  import Pagination from '@/components/common/pagination.vue';
  import { IPage, ISortType } from '@/interfaces/search';
  import { VuexAction } from '@/types/functions';
  import { ReviewsRating } from '@/types/review';
  import Order from '@/components/filters/order.vue';
  import RatingHeader from '@/components/common/rating-reviews/rating-header.vue';
  import Rating from '@/components/common/rating-reviews/rating.vue';
  import SearchTags from '@/components/search/search-tags.vue';
  import { reviewsSortTypes } from '@/config/dictionaries';
  import { FiltersConfig, filtersReview } from '@/config/filters';
  import { Review } from '@/types/review';
  import InterfacePropsMixin from '@/mixins/search/interface-props';
  import { Chunk } from '@/types/common';

  const searchStore = namespace('reviews/search');

  @Component({
    components: {
      Order,
      Rating,
      RatingHeader,
      ReviewList,
      ReviewListEmpty,
      Pagination,
      SearchTags,
    },
  })
  export default class SearchReviews extends Mixins<InterfacePropsMixin>(
    InterfacePropsMixin,
  ) {
    @Prop() reviewsRating!: ReviewsRating | null;
    @Prop() isLoaded!: boolean;
    @Prop() updateList!: VuexAction;
    @Prop()
    manufacturer!: Chunk;
    @Prop()
    model!: Chunk;
    @Prop()
    generation!: Chunk;

    public filtersConfig: FiltersConfig = filtersReview;
    public sortTypes: Array<ISortType> = reviewsSortTypes;

    @searchStore.State reviewListPage!: IPage;
    @searchStore.State reviews!: Array<Review>;
    @searchStore.State total!: number;
    @searchStore.State isLoading!: boolean;
    @searchStore.Action setPage!: VuexAction;

    public $refs!: {
      list: HTMLElement;
    };

    public onSetPage(page: IPage) {
      this.setPage({
        page,
        callbacks: {
          success: () => {
            this.$refs.list.scrollIntoView();
          },
        },
      });
    }

    private onShowFilters() {
      $('body').addClass('body_state-vehicle-form-filter_opened');
    }
  }
