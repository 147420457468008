var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__panel-item", attrs: { id: "comments" } },
    [
      _vm.settings && !_vm.settings.enabled
        ? _c(
            "div",
            {
              staticClass:
                "vehicle-form__mediabox vehicle-form__mediabox_pause helpers_hide_tablet",
            },
            [_vm._m(0)]
          )
        : !_vm.currentUser || _vm.activeBan
        ? _c(
            "div",
            {
              staticClass:
                "vehicle-form__mediabox vehicle-form__mediabox_auth helpers_hide_tablet",
            },
            [
              _c("div", { staticClass: "vehicle-form__mediabox-flex" }, [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__mediabox-part vehicle-form__mediabox-part_description",
                  },
                  [
                    !_vm.activeBan
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_max-width_mm",
                          },
                          [
                            _vm._v(
                              "\n          Чтобы оставить свое мнение, необходимо"
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base",
                                attrs: { href: _vm.loginUrl },
                              },
                              [_vm._v("войти")]
                            ),
                            _vm._v("\n          или\n          "),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base",
                                attrs: { href: _vm.registrationUrl },
                              },
                              [_vm._v("зарегистрироваться")]
                            ),
                          ]
                        )
                      : _vm.activeBan.expiredAt
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_max-width_mm",
                          },
                          [
                            _vm._v(
                              "\n          Вы сможете писать комментарии " +
                                _vm._s(_vm.activeBanExpireAt) +
                                "\n        "
                            ),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_max-width_mm",
                          },
                          [
                            _vm._v(
                              "\n          Вы не можете писать комментарии из-за постоянного\n          нарушения\n          "
                            ),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base",
                                attrs: { href: _vm.rulesUrl },
                              },
                              [_vm._v("правил")]
                            ),
                            _vm._v("\n          сайта\n        "),
                          ]
                        ),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_extended-another",
        },
        [_vm._v("\n    " + _vm._s(_vm.title || "Обсуждение") + "\n  ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "vehicle-form__comments" }, [
        _c(
          "div",
          { staticClass: "vehicle-form__comments-list" },
          [
            _vm.best
              ? _c("best-comment", {
                  attrs: {
                    best: _vm.best,
                    config: _vm.config,
                    "entity-author-id": _vm.entityAuthorId,
                    "current-user": _vm.currentUser,
                    settings: _vm.settings,
                    "active-ban": _vm.activeBan,
                  },
                  on: { reply: _vm.onBestReply, mark: _vm.onMarkToggle },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.settings &&
            _vm.settings.enabled &&
            _vm.currentUser &&
            !_vm.activeBan
              ? _c("comments-form", {
                  attrs: {
                    "current-user": _vm.currentUser,
                    "comments-api": _vm.commentsApi,
                    config: _vm.config,
                    "project-name": _vm.projectName,
                  },
                  on: { "comment-created": _vm.onCommentCreated },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("comments-form-mobile", {
              ref: "commentsFormMobile",
              attrs: {
                "current-user": _vm.currentUser,
                "comments-api": _vm.commentsApi,
                settings: _vm.settings,
                "active-ban": _vm.activeBan,
                config: _vm.config,
                "parent-id": _vm.activeReplyFormId,
                "reply-text": _vm.replyText,
                "project-name": _vm.projectName,
              },
              on: {
                "comment-created": _vm.onCommentCreated,
                "reply-created": _vm.onReplyCreated,
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__comments-item vehicle-form__comments-item_alter",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vehicle-form__comments-unit",
                    class: {
                      "vehicle-form__comments-unit_stub": _vm.isInitializing,
                    },
                  },
                  [
                    !_vm.isAllLoaded
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__control vehicle-form__control_condensed-further",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "button-style button-style_other-alter button-style_middle vehicle-form__button vehicle-form__button_width_full",
                                class: {
                                  "button-style_animated": _vm.isLoading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showAllComments()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Показать\n              " +
                                    _vm._s(
                                      _vm._f("plural")(this.total, [
                                        "комментарий",
                                        "комментария",
                                        "комментариев",
                                      ])
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.total
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__title vehicle-form__title_base",
                          },
                          [
                            !_vm.isAllLoaded
                              ? _c("span", [_vm._v("Последние")])
                              : _vm._e(),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("plural")(this.page.items, [
                                    "комментарий",
                                    "комментария",
                                    "комментариев",
                                  ])
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm.isInitializing
              ? _vm._l(10, function (item) {
                  return _c("comments-list-item-stub", { key: item })
                })
              : [
                  _c(
                    "scroll-list",
                    {
                      ref: "scroll",
                      attrs: {
                        config: _vm.config.scrollList,
                        start: 0,
                        "element-height-correction-mobile": _vm.currentUser
                          ? -10
                          : 0,
                      },
                    },
                    _vm._l(_vm.orderedComments, function (comment, index) {
                      return _c("comments-list-branch", {
                        key: comment.id,
                        ref: `comment_${comment.id}`,
                        refInFor: true,
                        attrs: {
                          comment: comment,
                          config: _vm.config,
                          "project-name": _vm.projectName,
                          best: _vm.best,
                          "entity-author-id": _vm.entityAuthorId,
                          "current-user": _vm.currentUser,
                          "comments-api": _vm.commentsApi,
                          settings: _vm.settings,
                          permissions: _vm.permissions,
                          "active-ban": _vm.activeBan,
                          "is-reply-form-visible":
                            _vm.activeReplyFormId === comment.id,
                          "data-index": index,
                          "reply-text": _vm.replyText,
                        },
                        on: {
                          reply: _vm.onReply,
                          delete: _vm.onCommentDelete,
                          "reply-created": _vm.onReplyCreated,
                          mark: _vm.onMarkToggle,
                        },
                      })
                    }),
                    1
                  ),
                ],
            _vm._v(" "),
            _vm.settings &&
            _vm.settings.enabled &&
            _vm.currentUser &&
            !_vm.activeBan &&
            _vm.comments.length >= _vm.config.minCommentsBottomFormVisible
              ? _c("comments-form", {
                  attrs: {
                    "current-user": _vm.currentUser,
                    "comments-api": _vm.commentsApi,
                    config: _vm.config,
                    "project-name": _vm.projectName,
                  },
                  on: { "comment-created": _vm.onCommentCreated },
                })
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      !_vm.isInitializing && !_vm.comments.length
        ? _c(
            "div",
            { staticClass: "vehicle-message vehicle-message_condensed" },
            [
              _c("div", { staticClass: "vehicle-message__content" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-container vehicle-container_width_mmmm",
                  },
                  [
                    _vm._m(2),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-message__title vehicle-message__title_base vehicle-message__title_condensed-other",
                      },
                      [_vm._v("\n          Нет комментариев\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-message__description vehicle-message__description_primary vehicle-message__description_base vehicle-message__description_condensed-complementary",
                      },
                      [
                        _vm._v(
                          "\n          К сожалению, не найдено ни одного комментария. Возможно, тут скоро\n          "
                        ),
                        _c("nobr", [_vm._v("что-нибудь")]),
                        _vm._v(" появится\n        "),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__mediabox-flex" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__mediabox-part vehicle-form__mediabox-part_image",
        },
        [
          _c("div", { staticClass: "vehicle-form__preview" }, [
            _c("div", {
              staticClass: "vehicle-form__image vehicle-form__image_cat",
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__mediabox-part vehicle-form__mediabox-part_description",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed-other vehicle-form__title_max-width_lm",
            },
            [_vm._v("\n          Комментарии на паузе\n        ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_max-width_lm",
            },
            [
              _vm._v(
                "\n          Их отсматривает и публикует модератор. Он ненадолго отошел, но\n          скоро вернется и все включит.\n        "
              ),
            ]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__mediabox-part vehicle-form__mediabox-part_image",
      },
      [
        _c("div", { staticClass: "vehicle-form__preview" }, [
          _c("div", {
            staticClass: "vehicle-form__image vehicle-form__image_lock",
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-message__preview" }, [
      _c("div", {
        staticClass: "vehicle-message__image vehicle-message__image_binoculars",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }