export default {
  location: {
    exclude: {
      country: [248],
    },
    prime: {
      city: [255, 269, 281, 312, 334, 393],
    },
  },
};
