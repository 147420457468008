
  import { Component, Prop, Mixins } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import * as $ from 'jquery';

  import { Dictionaries } from '@/services/dictionaries-service';
  import SearchTags from './search-tags.vue';
  import SearchAdvertsList from './search-adverts-list.vue';
  import SearchAdvertsPagination from '@/components/search/search-adverts-pagination.vue';
  import Order from '@/components/filters/order.vue';
  import InterfacePropsMixin from '@/mixins/search/interface-props';
  import { VuexAction } from '@/types/functions';
  import { filters, extendedFilters, FiltersConfig } from '@/config/filters';
  import { advertsSortTypes } from '@/config/dictionaries';
  import { ReviewsRating } from '@/types/review';
  import RatingHeader from '@/components/common/rating-reviews/rating-header.vue';
  import Rating from '@/components/common/rating-reviews/rating.vue';
  import { Chunk } from '@/types/common';
  import { ISortType } from '@/interfaces/search';

  const commonStore = namespace('common');

  @Component({
    components: {
      Order,
      SearchTags,
      SearchAdvertsList,
      SearchAdvertsPagination,
      RatingHeader,
      Rating,
    },
  })
  export default class SearchAdverts extends Mixins<InterfacePropsMixin>(
    InterfacePropsMixin,
  ) {
    private filtersConfig: FiltersConfig = filters.concat(extendedFilters);

    @Prop() isLoaded!: boolean;
    @Prop() reviewsRating!: ReviewsRating | null;
    @Prop() removeFilter!: VuexAction;
    @Prop()
    manufacturer!: Chunk;
    @Prop()
    model!: Chunk;
    @Prop()
    generation!: Chunk;

    @commonStore.State dictionaries!: Dictionaries;
    @commonStore.Getter anyDropdownOpened!: Dictionaries;

    public sortTypes: Array<ISortType> = advertsSortTypes;

    private get isAnyDropdownOpened() {
      return this.anyDropdownOpened;
    }

    private onShowFilters() {
      $('body').addClass('body_state-vehicle-form-filter_opened');
    }
  }
