
  import { Component } from 'vue-property-decorator';
  import { Mixins } from 'vue-mixin-decorator';
  import FieldMixin from '@/mixins/reviews/create/field';

  @Component
  export default class StepDescriptionVideoLink extends Mixins<FieldMixin>(
    FieldMixin,
  ) {
    private get videoLink() {
      return this.data.videos && this.data.videos[0] && this.data.videos[0].url;
    }

    private set videoLink(value) {
      this.onFieldChange(value);
    }
  }
