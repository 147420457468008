
  import { Mixins, Component } from 'vue-mixin-decorator';
  import { findWhere } from 'underscore';

  import StepTechModelMixin from '@/mixins/reviews/create/step-tech-model';
  import DropdownMixin from '@/mixins/common/dropdown';
  import { Prop } from 'vue-property-decorator';
  import { Dictionaries } from '@/services/dictionaries-service';

  interface IMixins extends StepTechModelMixin, DropdownMixin {}

  @Component
  export default class StepTechModelMobile extends Mixins<IMixins>(
    DropdownMixin,
    StepTechModelMixin,
  ) {
    @Prop() manufacturerId!: string;
    @Prop() dictionaries!: Dictionaries;

    private onOpenClick() {
      this.$refs.search.focus();
      this.openDropdown();

      document.body.classList.add('body_state-dropdown-style_visible');
    }

    private onCloseClick() {
      this.closeDropdown();

      document.body.classList.remove('body_state-dropdown-style_visible');
    }

    private get modelName() {
      const model = findWhere(this.dictionaries.model, {
        id: this.data.modelId,
      });

      if (!model) {
        return 'Выберите';
      }

      return model.name;
    }

    private onModelChange() {
      this.onCloseClick();
      this.search = '';
    }
  }
