var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__commercial" }, [
    _c("div", { staticClass: "vehicle-form__commercial-flex" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__commercial-part vehicle-form__commercial-part_2",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__commercial-box vehicle-form__commercial-box_condensed",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_base-alter vehicle-form__description_primary vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-complementary",
                },
                [_vm._v("Авторазделы в услугах")]
              ),
              _vm._v(" "),
              _vm._l(_vm.services, function (service) {
                return _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_base-alter vehicle-form__description_primary vehicle-form__description_condensed",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base-alter",
                        attrs: { href: service.url, target: "_blank" },
                      },
                      [_vm._v(_vm._s(service.name))]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "vehicle-form__divider vehicle-form__divider_condensed-fringe",
              }),
              _vm._v(" "),
              _vm._m(1),
            ],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__commercial-part vehicle-form__commercial-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__commercial-box vehicle-form__commercial-box_banner",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_condensed-additional",
              },
              [_vm._v("Автобарахолка")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_base vehicle-form__description_other",
              },
              [_vm._v("Мобильное приложение")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__app" }, [
              _c("div", { staticClass: "vehicle-form__app-list" }, [
                _c("a", {
                  staticClass:
                    "vehicle-form__app-item vehicle-form__app-item_apple",
                  attrs: {
                    href: "https://apps.apple.com/by/app/id1110320125",
                    target: "_blank",
                  },
                }),
                _vm._v(" "),
                _c("a", {
                  staticClass:
                    "vehicle-form__app-item vehicle-form__app-item_google",
                  attrs: {
                    href: "https://play.google.com/store/apps/details?id=by.onliner.ab",
                    target: "_blank",
                  },
                }),
                _vm._v(" "),
                _c("a", {
                  staticClass:
                    "vehicle-form__app-item vehicle-form__app-item_huawei",
                  attrs: {
                    href: "https://appgallery.huawei.com/app/C102015137",
                    target: "_blank",
                  },
                }),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_tiny vehicle-form__description_other vehicle-form__description_fail",
          },
          [
            _vm._v(
              "Все расчеты на территории Беларуси между гражданами Республики Беларусь осуществляются исключительно в белорусских рублях. Цена в иностранной валюте (если указана в объявлениях) приведена справочно."
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__description vehicle-form__description_base-alter vehicle-form__description_primary vehicle-form__description_font-weight_semibold vehicle-form__description_condensed",
      },
      [
        _c(
          "a",
          {
            staticClass:
              "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base-alter",
            attrs: { href: "https://s.onliner.by/tasks", target: "_blank" },
          },
          [_vm._v("Все разделы Услуг")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }