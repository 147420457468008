var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__option-item" }, [
    _c("div", {
      staticClass:
        "vehicle-form__option-switch vehicle-form__option-switch_minus",
      class: {
        "vehicle-form__option-switch_active": _vm.currentValue === _vm.minus,
      },
      on: {
        click: function ($event) {
          return _vm.onClick(_vm.minus)
        },
      },
    }),
    _vm._v(" "),
    _c("div", {
      staticClass:
        "vehicle-form__option-switch vehicle-form__option-switch_plus",
      class: {
        "vehicle-form__option-switch_active": _vm.currentValue === _vm.plus,
      },
      on: {
        click: function ($event) {
          return _vm.onClick(_vm.plus)
        },
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "vehicle-form__option-text" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base",
        },
        [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }