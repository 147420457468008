var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "handle",
      staticClass: "popover-style__handle vehicle-form__popover-handle",
      class: { "popover-style__handle_opened": _vm.isVisible },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "popover-style popover-style_primary popover-style_base popover-style_bottom-right vehicle-form__popover vehicle-form__popover_width_sssm",
        },
        [
          _c("div", { staticClass: "popover-style__content" }, [
            _c("div", { staticClass: "vehicle-form" }, [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base",
                },
                [_vm._v("Вы пытаетесь закрыть премиальное объявление")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__control vehicle-form__control_condensed-default",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "button-style button-style_alter button-style_small vehicle-form__button",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.onClose.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Закрыть")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "vehicle-form__link vehicle-form__link_additional vehicle-form__link_base",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.closeDropdown.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Отменить")]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "button-style button-style_adscititious button-style_base vehicle-form__button",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.openDropdown.apply(null, arguments)
            },
          },
        },
        [_vm._v("Закрыть")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }