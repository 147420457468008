import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import { VuexAction } from '@/types/functions';
import { IFilter } from '@/interfaces/filters';
import { SchemaProperty } from '@/types/common';
import { HashMap } from '@onlinerby/js-common';

@Mixin
export default class InterfacePropsMixin extends Vue {
  @Prop() setFilter!: VuexAction;
  @Prop() stateCounters!: any;
  @Prop() getFilter!: (name: string, filterType?: string) => IFilter;
  @Prop() filters!: Array<IFilter>;
  @Prop() updateList!: VuexAction;
  @Prop() updateCounters!: VuexAction;
  @Prop() removeFilter!: VuexAction;
  @Prop() schemaProperties!: HashMap<SchemaProperty>;
}
