
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { VuexAction } from '@/types/functions';

const promoteStore = namespace('promote');

@Component
export default class PromoteAdvertsPremiumFilter extends Vue {
  @promoteStore.State showNonPremiumOnly!: boolean;
  @promoteStore.Action setShowNonPremiumOnly!: VuexAction;

  private get value() {
    return this.showNonPremiumOnly;
  }

  private set value(showNonPremiumOnly) {
    this.setShowNonPremiumOnly({ showNonPremiumOnly });
  }
}
