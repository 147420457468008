import { MutationTree } from 'vuex';
import { findIndex } from 'underscore';

import { ICreateState } from '@/interfaces/store';
import { HashMap } from '@/types/common';
import state, { defaultState } from '@/store/modules/create/state';
import editTransformer from '@/transformers/edit-transformer';

const mutations: MutationTree<ICreateState> = {
  init(state) {
    Object.assign(state, defaultState);
  },

  setField(state, data) {
    state.data = { ...state.data, ...data };
  },

  setMode(state, mode) {
    state.mode = mode;
  },

  clearData() {
    state.data = {};
    state.steps = {
      tech: 'current',
      exterior: 'pristine',
      cost: 'pristine',
      contacts: 'pristine',
    };
  },

  setError(state, errors) {
    state.errors = { ...state.errors, ...errors };
  },

  setStep(state, data) {
    state.steps = { ...state.steps, ...data };
  },

  setData(state, data) {
    state.data = editTransformer(data);
  },

  clearError(state, name) {
    const errors: HashMap<any> = {};

    for (let key of Object.keys(state.errors)) {
      if (key !== name) {
        errors[key] = state.errors[key];
      }
    }

    state.errors = errors;
  },
};

export default mutations;
