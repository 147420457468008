import Vue from 'vue';
import { Mixin, Mixins } from 'vue-mixin-decorator';
import * as $ from 'jquery';
import { intersection, isEmpty } from 'underscore';
import { HashMap } from '@onlinerby/js-common';
import { namespace } from 'vuex-class';

import { VuexAction } from '@/types/functions';
import IsMobileMixin from '@/mixins/common/is-mobile';
import { stepStatuses } from '@/config/reviews/create';
import errorMap from '@/config/reviews/steps-error-map';

const createStore = namespace('reviews/create');

@Mixin
export default class Step extends Mixins<IsMobileMixin>(IsMobileMixin) {
  private OFFSET: number = 0;

  protected step!: string;
  protected mode!: string;
  protected statusesByStep!: HashMap<any>;
  protected setStatusesByStep!: VuexAction;

  @createStore.Action setErrors!: VuexAction;
  @createStore.Action clearError!: VuexAction;
  @createStore.State errors!: HashMap<any>;

  protected created() {
    this.OFFSET = this.isMobileSpecial ? 70 : 20;
  }

  private mounted() {
    const step: Vue = this.$refs[this.step] as Vue;

    if (!step) {
      return;
    }

    const stepTop = $(step.$el).offset()!.top;

    $(window).scrollTop(stepTop - this.OFFSET);
  }

  protected get isEdit() {
    return this.statusesByStep[this.step] === stepStatuses.edit;
  }

  private get hasErrors() {
    const errors: Array<string> = Object.values(errorMap[this.step]);
    const currentErrors = Object.keys(this.errors);

    return !!intersection(errors, currentErrors).length;
  }

  private get componentStepTechHeader() {
    return this.isMobileSpecial
      ? 'step-tech-header-mobile'
      : 'step-tech-header';
  }

  protected onError() {
    this.$nextTick(() => {
      const $row = $(
        '.vehicle-form__description_error, .drop-style__hint_error',
      ).closest('.vehicle-form__row');
      const correction = this.isMobileSpecial ? -50 : 0;

      $row.length &&
        $(window).scrollTop($row.first().offset()!.top + correction);
    });
  }

  protected next() {
    this.setStatusesByStep({ [this.step]: stepStatuses.complete });

    this.$router.replace({
      name: `create-review-${this.getStep(stepStatuses.pristine)}`,
    });
  }

  protected finish() {
    this.setStatusesByStep({ [this.step]: stepStatuses.complete });

    const name =
      this.mode === 'create'
        ? `create-review-${this.getStep(stepStatuses.current)}`
        : 'edit-review-init';
    this.$router.replace({ name });
  }

  protected onFinishClick() {
    this.validate();

    this.hasErrors ? this.onError() : this.finish();
  }

  protected onNextClick() {
    this.validate();

    if (this.hasErrors) {
      this.onError();
      this.trackGtm('error');
    } else {
      this.next();
      this.trackGtm('success');
    }
  }

  private getStep(status: string) {
    for (let step of Object.keys(this.statusesByStep)) {
      if (this.statusesByStep[step] === status) {
        return step;
      }
    }

    return '';
  }

  protected validate() {
    return true;
  }

  private trackGtm(result: string) {
    const event = {
      create: `create_review - ${this.step} - ${result}`,
      edit: `edit_review - ${this.step} - ${result}`,
    }[this.mode];
    const value = !isEmpty(this.errors)
      ? { errors: JSON.stringify(this.errors) }
      : {};

    this.$gtm.trackEvent({
      event,
      value,
    });
  }
}
