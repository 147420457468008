import { Vue } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { findWhere } from 'underscore';

import {
  Order,
  PaymentMethod,
  Subscription,
  subscriptionsApi,
  paygateApi,
  SubscriptionsService,
  PaygateNotificationsService,
  ICard,
  CardTokenizeOperationStatusChangedEvent,
  CardTokenizeOperationErrorEvent,
  CardPaymentStatusChangedEvent,
  Card3dsRequiredEvent,
  CardPaymentErrorEvent,
} from '@onlinerby/js-api-clients';
import { EncryptedCardData } from '@onlinerby/js-vue-components';
import { UrlService } from '@onlinerby/js-common';

import { SelectedFeature, Features, Entities } from '@/types/promote';
import { VuexAction } from '@/types/functions';
import { HashMap } from '@/types/common';
import notificationsService from '@/services/notifications-service';

const promoteStore = namespace('promote');

@Mixin
export default class PaymentsMixin extends Vue {
  protected isNewCardRulesAccepted: boolean = true;
  protected newCardErrors: string[] = [];
  protected secure3dUrl: string = '';

  @promoteStore.State selectedFeature!: SelectedFeature;
  @promoteStore.State selectedPaymentMethod!: PaymentMethod;
  @promoteStore.State isRecurrent!: boolean;
  @promoteStore.State verificationValue!: string;
  @promoteStore.State features!: Features;
  @promoteStore.State entities!: Entities;
  @promoteStore.Action createSubscription!: VuexAction;
  @promoteStore.Action setSelectedFeature!: VuexAction;
  @promoteStore.Action setPaymentProcessing!: VuexAction;

  private get gtmData() {
    if (!this.selectedFeature || !this.selectedFeature.entity) {
      return {};
    }

    const advert = findWhere(this.entities, {
      id: this.selectedFeature.entity.id,
    });
    const feature = findWhere(this.features, { id: this.selectedFeature.id });

    if (!advert || !feature) {
      return {};
    }

    const { id, location, specs, price, manufacturer, author } = advert.data;

    return {
      pf_type: feature.name,
      ad_id: id,
      ad_region: `${location.country.id}_${location.region.id}_${location.city.id}`,
      ad_condition: specs.state,
      ad_price: price.converted.USD.amount,
      ad_currency: 'USD',
      ad_mfr: manufacturer.id,
      seller_id: author ? author.id.toString() : '',
    };
  }

  private get isConditionsMet() {
    return (
      (this.selectedPaymentMethod &&
        this.selectedPaymentMethod.name !== 'new_card') ||
      this.isNewCardRulesAccepted
    );
  }

  private get paymentMethod() {
    const methods: HashMap<string> = {
      account: 'account',
      card: 'card',
      new_card: 'card',
    };
    return methods[this.selectedPaymentMethod.name];
  }

  private create(success: (data: Subscription[]) => void) {
    const { id, entity } = this.selectedFeature;
    const feature: SelectedFeature = { id, entity };
    const selectedFeature = findWhere(this.features, { id });
    const recurrent = this.isRecurrent;

    if (
      selectedFeature &&
      ['auto_up', 'sticky'].indexOf(selectedFeature.name) !== -1
    ) {
      feature.options = this.selectedFeature.options || [];
    }

    this.$gtm.trackEvent({
      event: 'pay_for_premium_feature',
      value: this.gtmData,
    });

    this.createSubscription({
      feature,
      recurrent,
      success,
      error: this.onError,
    });
  }

  private addEventListeners(order: Order) {
    const onMessage = ({ data }) => {
      if (data.type === '3ds') {
        window.removeEventListener('message', onMessage);

        paygateApi.getTransactionStatus(data.transaction_id, {
          success: ({ status }: { status: string }) => {
            this.onSuccess(order);

            this.setPaymentProcessing({ isProcessing: false });
          },
          error: () => {
            this.secure3dUrl = '';
          },
        });
      }
    };

    window.addEventListener('message', onMessage, false);
  }

  private onSuccess(order: Order) {
    window.location.href = SubscriptionsService.getRedirectUrl(
      this.paymentMethod,
      order,
      {
        payment_method: this.selectedPaymentMethod.name,
      }
    );
  }

  private onFail(order: Order) {
    window.location.href = SubscriptionsService.getRedirectUrl(
      this.paymentMethod,
      order,
      {
        payment_method: this.selectedPaymentMethod.name,
      }
    );
  }

  private onError({ response: { data } }: any) {
    if (data.errors && data.errors.general) {
      notificationsService.error(data.errors.general.join('. '));
    }

    if (data.errors && data.errors.features) {
      for (const key in data.errors.features) {
        notificationsService.error(data.errors.features[key].join('. '));
      }
    }

    this.setPaymentProcessing({ isProcessing: false });
  }

  private payByCard(order: Order, success: Function) {
    const $paymentsList = this.$refs.paymentsList as any;

    $paymentsList.getVerificationValue((value: string) => {
      const entity = {
        project: 'subscriptions',
        id: order.id,
        type: 'order',
      };
      const card: {
        id: string;
        verificationValue?: string | null;
      } = {
        id: (this.selectedPaymentMethod as ICard).fields.id,
        verificationValue: value || null,
      };

      paygateApi.payByCard(
        {
          entity,
          card,
        },
        {
          success,
        }
      );
    });
  }

  private payByNewCard(order: Order, success: Function) {
    const $paymentsList = this.$refs.paymentsList as any;

    $paymentsList.getNewCardData((card: EncryptedCardData | null) => {
      if (!card) {
        return;
      }

      const entity = {
        project: 'subscriptions',
        id: order.id,
        type: 'order',
      };

      paygateApi.payByNewCard(
        {
          entity,
          card,
          returnUrl: UrlService.secureProjectUrl('ab', 'secure'),
          tokenize: true,
          requestId: order.id,
        },
        {
          success,
        }
      );
    });
  }

  private onPay() {
    if (this.selectedPaymentMethod.name === 'new_card') {
      const $paymentsList = this.$refs.paymentsList as any;
      const hasErrors = $paymentsList.validateNewCard().length;

      if (hasErrors) {
        return;
      }
    }

    if (this.selectedPaymentMethod.name === 'card') {
      const $paymentsList = this.$refs.paymentsList as any;
      const isValid = $paymentsList.validateCard(
        (this.selectedPaymentMethod as ICard).fields.id
      );

      if (!isValid) {
        return;
      }
    }

    this.setPaymentProcessing({ isProcessing: true });

    this.create((data: Subscription[]) => {
      const subscriptions = data.map((item: Subscription) => item.id);

      subscriptionsApi.createOrder(
        {
          subscriptions,
          paymentMethod: this.paymentMethod,
        },
        {
          success: (order: Order) => {
            this.addEventListeners(order);

            switch (this.selectedPaymentMethod.name) {
              case 'account':
                this.onSuccess(order);
                break;
              case 'card':
                this.payByCard(order, ({ id }: { id: string }) => {
                  this.addHandlers(
                    id,
                    this.onSuccess.bind(this, order),
                    this.onFail.bind(this, order)
                  );
                });
                break;
              case 'new_card':
                this.payByNewCard(order, ({ id }: { id: string }) => {
                  this.addHandlers(
                    id,
                    this.onSuccess.bind(this, order),
                    this.onFail.bind(this, order)
                  );
                });
                break;
              default:
            }
          },
          error: this.onError,
        }
      );
    });
  }

  protected onNewCardAcceptedChange(value: boolean) {
    this.isNewCardRulesAccepted = value;
  }

  private addHandlers(cardId: string, success: Function, fail: Function) {
    PaygateNotificationsService.addHandler(
      'card.tokenize.operation.status.changed',
      cardId,
      (data: CardTokenizeOperationStatusChangedEvent) => {
        if (data.status === 'successful') {
          success();
        } else {
          notificationsService.error('Не удалось привязать карту');

          this.setPaymentProcessing({ isProcessing: false });
        }
      }
    );

    PaygateNotificationsService.addHandler(
      'card.tokenize.operation.error',
      cardId,
      () => {
        fail();

        this.setPaymentProcessing({ isProcessing: false });
      }
    );

    PaygateNotificationsService.addHandler(
      'card.3ds.required',
      cardId,
      (data: Card3dsRequiredEvent) => {
        this.secure3dUrl = data['3ds_url'];

        // const cardType = creditCardType(this.unmaskedCreditCardNumber)[0];

        // cardType.type === 'maestro'
        //   ? (window.location = data['3ds_url'])
        //   : (this.secure3dUrl = data['3ds_url']);
      }
    );

    PaygateNotificationsService.addHandler(
      'card.payment.status.changed',
      cardId,
      (data: CardPaymentStatusChangedEvent) => {
        this.setPaymentProcessing({ isProcessing: false });

        if (data.status === 'successful') {
          success();
        }

        if (data.status === 'failed') {
          fail();
        }
      }
    );

    PaygateNotificationsService.addHandler(
      'card.payment.error',
      cardId,
      (error: CardPaymentErrorEvent) => {
        this.setPaymentProcessing({ isProcessing: false });

        fail();
      }
    );
  }
}
