import FilterMixin from '@/mixins/filters/filter';
import { Component, Mixins } from 'vue-mixin-decorator';
import { findWhere, some } from 'underscore';
import capitalize from '@/filters/format-capitalize';
import camelize from '@/filters/format-camelize';
import { HashMap } from '@/types/common';

@Component
export default class MultiselectFilterMixin extends Mixins<FilterMixin>(
  FilterMixin,
) {
  protected filterType = 'array';

  private get checkboxes(): HashMap<boolean> {
    const values: string[] = ((this.filter.value as unknown) as string[]) || [];
    const checkboxes: HashMap<boolean> = {};

    values.forEach(value => {
      checkboxes[value] = true;
    });

    return checkboxes;
  }

  public created() {
    const values: string[] = ((this.filter.value as unknown) as string[]) || [];

    values.forEach(value => {
      this.$set(this.checkboxes, value, true);
    });

    this.checkboxes;
  }

  protected get options() {
    return this.dictionaries[camelize(this.name)];
  }

  protected get selectedValues() {
    const selectedItems = [];

    for (let key of Object.keys(this.checkboxes)) {
      if (this.checkboxes[key]) {
        const option = findWhere(this.options, { id: key });

        option && selectedItems.push(capitalize(option.name));
      }
    }

    return selectedItems.join(', ');
  }

  private clearFilter() {
    this._updateFilter('');
  }

  private updateFilter() {
    const value: string[] = [];

    for (let key of Object.keys(this.checkboxes)) {
      this.checkboxes[key] && value.push(key);
    }

    this._updateFilter(value);
  }

  private get isNoneSelected() {
    return !some(this.checkboxes);
  }
}
