
import { Vue, Component } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { findWhere, uniq } from 'underscore';

import popular from '@/config/popular';
import { VuexAction } from '@/types/functions';
import {
  Dictionaries,
  Dictionary,
  DictionaryItem,
} from '@/services/dictionaries-service';
import DropdownMixin from '@/mixins/common/dropdown';
import { Entities } from '@/types/promote';
import { HashMap } from '@/types/common';
import dictionariesService from '@/services/dictionaries-service';
import DeviceClass from '@/directives/device-class';
import dictionariesMap from '@/config/dictionaries-map';

const commonStore = namespace('common');
const promoteStore = namespace('promote');

@Component({
  directives: {
    DeviceClass,
  },
})
export default class PromoteAdvertsManufacturer extends Mixins<DropdownMixin>(
  DropdownMixin
) {
  private optionsFilter: string = '';

  @commonStore.State dictionaries!: Dictionaries;
  @commonStore.Action setDictionary!: VuexAction;
  @promoteStore.State entities!: Entities;
  @promoteStore.State manufacturerId!: string;
  @promoteStore.Action setManufacturerId!: VuexAction;
  @promoteStore.Action setModelId!: VuexAction;

  private get filterString() {
    return this.optionsFilter
      .trim()
      .toLowerCase()
      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  private get manufacturer() {
    return this.manufacturerId || NaN;
  }

  private get entityManufacturers() {
    return uniq(this.entities.map(item => item.data.manufacturer.id));
  }

  private set manufacturer(id) {
    this.setManufacturerId({ id });
    this.setModelId({ id: null });
  }

  private onClick(id: string) {
    this.setManufacturerId({ id });
    this.onChange();
  }

  private get manufacturers() {
    const dictionary: Dictionary = this.dictionaries.manufacturer.filter(
      item => {
        return this.entityManufacturers.indexOf(item.id) !== -1;
      }
    );

    if (!dictionary) {
      return [];
    }

    if (!this.filterString) {
      return dictionary;
    }

    const regex = new RegExp(this.filterString);

    return dictionary.filter((item: DictionaryItem) => {
      return regex.test(item.name.toLowerCase());
    });
  }

  private get selectedTitle() {
    const selected: any = findWhere(this.manufacturers, {
      id: this.manufacturer,
    });

    return selected ? selected.name : 'Марка';
  }

  private getClassName(id: number) {
    return (popular as HashMap<HashMap<string>>).manufacturer[id];
  }

  private onChange() {
    this.closeDropdown();
    this.addDictionary();

    const handler = () => {
      (this.$refs.dropdown as HTMLElement).removeEventListener(
        'transitionend',
        handler
      );

      this.optionsFilter = '';
    };

    (this.$refs.dropdown as HTMLElement).addEventListener(
      'transitionend',
      handler
    );
  }

  private get urlData() {
    return {
      manufacturer: this.manufacturer as number,
    };
  }

  private get url() {
    return dictionariesService.buildUrl('manufacturer', this.urlData);
  }

  private addDictionary() {
    const name = 'manufacturer';

    this.url &&
      !this.dictionaries[this.url] &&
      dictionariesService.getDictionary(
        name,
        this.urlData,
        (data: Dictionary) => {
          this.setDictionary({
            rootName: dictionariesMap[name].rootName,
            name: this.url,
            dictionary: data,
          });
        }
      );
  }
}
