
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import { Dictionaries } from '@/services/dictionaries-service';
import FieldMixin from '@/mixins/create/field';

const commonStore = namespace('common');

@Component
export default class StepTechDrivetrain extends Mixins<FieldMixin>(FieldMixin) {
  @commonStore.State dictionaries!: Dictionaries;

  private get specs() {
    return this.data.specs || {};
  }

  private get drivetrain() {
    return this.specs.drivetrain;
  }

  private get error() {
    return (this.errors['specs.drivetrain'] || []).join('. ');
  }

  private setDrivetrain(drivetrain: string) {
    this.clearError({ name: 'specs.drivetrain' });
    this.setField({ specs: { ...this.specs, drivetrain } });
  }
}
