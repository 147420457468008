var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.advertsListPage.items && _vm.advertsListPage.last > 1,
          expression: "advertsListPage.items && advertsListPage.last > 1",
        },
      ],
      ref: "handle",
      staticClass: "vehicle-pagination vehicle-pagination_visible",
    },
    [
      _c(
        "a",
        {
          staticClass: "vehicle-pagination__main",
          class: {
            "vehicle-pagination__main_disabled":
              _vm.advertsListPage.current === _vm.advertsListPage.last,
          },
          on: { click: _vm.showNextPage },
        },
        [
          _vm.advertsListPage.current < _vm.advertsListPage.last - 1
            ? _c("span", [
                _vm._v(
                  "Следующие " +
                    _vm._s(
                      _vm._f("plural")(_vm.count, [
                        "объявление",
                        "объявления",
                        "объявлений",
                      ])
                    )
                ),
              ])
            : _c(
                "span",
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm._f("plural")(
                          _vm.count,
                          ["Последнее", "Последние", "Последние"],
                          false
                        )
                      ) +
                      "\n      "
                  ),
                  _vm.count !== 1 ? [_vm._v(_vm._s(_vm.count))] : _vm._e(),
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm._f("plural")(
                          _vm.count,
                          ["объявление", "объявления", "объявлений"],
                          false
                        )
                      ) +
                      "\n    "
                  ),
                ],
                2
              ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "vehicle-pagination__secondary" }, [
        _c(
          "div",
          {
            staticClass: "vehicle-pagination__dropdown",
            on: { click: _vm.toggleDropdown },
          },
          [
            _c("div", { staticClass: "vehicle-pagination__dropdown-value" }, [
              _vm._v(_vm._s(_vm.advertsListPage.current)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-pagination__dropdown-items" }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "vehicle-pagination__pages",
          class: { "vehicle-pagination__pages_active": _vm.isVisible },
        },
        [
          _c(
            "div",
            {
              ref: "pagesContainer",
              staticClass: "vehicle-pagination__pages-container",
            },
            [
              _c(
                "ul",
                { staticClass: "vehicle-pagination__pages-list" },
                _vm._l(_vm.pages, function (page) {
                  return _c(
                    "li",
                    {
                      key: `page_${page}`,
                      ref: `page_${page}`,
                      refInFor: true,
                      staticClass: "vehicle-pagination__pages-item",
                      class: {
                        "vehicle-pagination__pages-item_active":
                          _vm.advertsListPage.current === page,
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "vehicle-pagination__pages-link",
                          on: {
                            click: function ($event) {
                              return _vm.setCurrentPage(page)
                            },
                          },
                        },
                        [_vm._v(_vm._s(page))]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }