var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a",
    {
      staticClass:
        "button-style button-style_auxiliary button-style_base button-style_noreflex vehicle-form__button vehicle-form__button_time-up",
    },
    [_vm._v(" " + _vm._s(_vm._f("duration")(_vm.upAvailableIn)))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }