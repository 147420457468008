var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.leasing
    ? _c(
        "form",
        {
          staticClass: "vehicle-form__request",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed-complementary",
            },
            [
              _vm._v(
                "\n    Купить " +
                  _vm._s(_vm.advert.title) +
                  " в лизинг от \n    "
              ),
              _c("div", { staticClass: "vehicle-form__preview" }, [
                _c("div", {
                  staticClass: "vehicle-form__image",
                  style: _vm._f("background")(_vm.leasing.image),
                }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_extended-extra",
            },
            [_vm._v("\n    " + _vm._s(_vm.leasing.info.summary) + "\n  ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "vehicle-form__request-flex",
              class: { "vehicle-form__request-flex_specific": _vm.isSpecific },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__request-part vehicle-form__request-part_1",
                },
                [
                  _c("div", { staticClass: "vehicle-form__row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__line vehicle-form__line_condensed-specific",
                      },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__group vehicle-form__group_narrow",
                          },
                          [
                            _c("div", { staticClass: "vehicle-form__field" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__line vehicle-form__line_condensed-other",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "input-style__wrapper vehicle-form__input-wrapper",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.name,
                                            expression: "name",
                                          },
                                        ],
                                        staticClass:
                                          "input-style input-style_primary input-style_base vehicle-form__input vehicle-form__input_width_xxssm",
                                        class: {
                                          "input-style_error": _vm.errors.name,
                                        },
                                        attrs: {
                                          type: "text",
                                          maxlength: "50",
                                          placeholder: "Ваше имя",
                                        },
                                        domProps: { value: _vm.name },
                                        on: {
                                          input: [
                                            function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.name = $event.target.value
                                            },
                                            _vm.onNameChange,
                                          ],
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.errors.name
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__line vehicle-form__line_condensed-other",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_xxssmm",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.errors.name.join(".")
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__group vehicle-form__group_narrow-specific",
                          },
                          [
                            _c("div", { staticClass: "vehicle-form__field" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__line vehicle-form__line_condensed-other",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "input-style__wrapper vehicle-form__input-wrapper",
                                    },
                                    [
                                      _c("input", {
                                        staticClass:
                                          "input-style input-style_primary input-style_base vehicle-form__input vehicle-form__input_width_xxssmm",
                                        class: {
                                          "input-style_error": _vm.errors.phone,
                                        },
                                        attrs: {
                                          type: "tel",
                                          maxlength: "55",
                                          placeholder: "Телефон",
                                        },
                                        domProps: { value: _vm.phone },
                                        on: { input: _vm.onPhoneChange },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.errors.phone
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__line vehicle-form__line_condensed-other",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_xxssmm",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.errors.phone.join(".")
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__acquaintance helpers_show_tablet",
                          },
                          [
                            _c(
                              "ul",
                              { staticClass: "vehicle-form__checkbox-list" },
                              [
                                _c(
                                  "li",
                                  {
                                    staticClass: "vehicle-form__checkbox-item",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "vehicle-form__checkbox-label",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "i-checkbox vehicle-form__checkbox vehicle-form__checkbox_tiny",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.isRulesAccepted,
                                                  expression: "isRulesAccepted",
                                                },
                                              ],
                                              staticClass: "i-checkbox__real",
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.isRulesAccepted
                                                )
                                                  ? _vm._i(
                                                      _vm.isRulesAccepted,
                                                      null
                                                    ) > -1
                                                  : _vm.isRulesAccepted,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.isRulesAccepted,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.isRulesAccepted =
                                                          $$a.concat([$$v]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.isRulesAccepted =
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            ))
                                                    }
                                                  } else {
                                                    _vm.isRulesAccepted = $$c
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass: "i-checkbox__faux",
                                            }),
                                            _vm._v(" "),
                                            _vm._m(1),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__group vehicle-form__group_narrow-specific",
                          },
                          [
                            _c("div", { staticClass: "vehicle-form__field" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "button-style button-style_primary button-style_base vehicle-form__button vehicle-form__button_width_xxssmmmmm",
                                  class: {
                                    "button-style_animated": _vm.isProcessing,
                                  },
                                  attrs: { type: "submit" },
                                },
                                [
                                  _vm._v(
                                    "\n                Подать заявку\n              "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__line vehicle-form__line_condensed-specific",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.leasing.info.title) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__request-part vehicle-form__request-part_2",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__price vehicle-form__price_specific",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__title vehicle-form__title_huge-alter vehicle-form__title_condensed-fringe",
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "vehicle-form__price-sup" },
                            [_vm._v("от")]
                          ),
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                _vm.leasing.payment.monthly,
                                "BYN",
                                false
                              )
                            ) + "\n          "
                          ),
                          _c(
                            "span",
                            { staticClass: "vehicle-form__price-sup" },
                            [_vm._v("р.")]
                          ),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "vehicle-form__price-divider",
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "vehicle-form__price-sub" },
                            [_vm._v("месяц")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
                        },
                        [
                          _vm._v(
                            "\n          Первоначальный взнос —\n          "
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_nowrap",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.leasing.payment.advance,
                                    "BYN"
                                  )
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "vehicle-form__acquaintance helpers_hide_tablet" },
            [
              _c("ul", { staticClass: "vehicle-form__checkbox-list" }, [
                _c("li", { staticClass: "vehicle-form__checkbox-item" }, [
                  _c("label", { staticClass: "vehicle-form__checkbox-label" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "i-checkbox vehicle-form__checkbox vehicle-form__checkbox_tiny",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.isRulesAccepted,
                              expression: "isRulesAccepted",
                            },
                          ],
                          staticClass: "i-checkbox__real",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.isRulesAccepted)
                              ? _vm._i(_vm.isRulesAccepted, null) > -1
                              : _vm.isRulesAccepted,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.isRulesAccepted,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.isRulesAccepted = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.isRulesAccepted = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.isRulesAccepted = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "i-checkbox__faux" }),
                        _vm._v(" "),
                        _vm._m(2),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("advert-leasing-success-popup", { ref: "successPopup" }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("Имя и телефон"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__checkbox-text" }, [
      _c("div", { staticClass: "vehicle-form__checkbox-sign" }, [
        _vm._v(
          "\n                        Ознакомлен с\n                        "
        ),
        _c(
          "a",
          {
            staticClass:
              "vehicle-form__link vehicle-form__link_primary vehicle-form__link_tiny",
            attrs: {
              href: "https://blog.onliner.by/politika-konfidencialnosti",
            },
          },
          [_vm._v("Политикой обработки персональных данных")]
        ),
        _vm._v("\n                        и\n                        "),
        _c(
          "a",
          {
            staticClass:
              "vehicle-form__link vehicle-form__link_primary vehicle-form__link_tiny",
            attrs: { href: "https://blog.onliner.by/siterules" },
          },
          [_vm._v("Пользовательским соглашением")]
        ),
        _vm._v(
          ". Даю согласие на обработку своих персональных\n                        данных.\n                      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__checkbox-text" }, [
      _c("div", { staticClass: "vehicle-form__checkbox-sign" }, [
        _vm._v("\n                Ознакомлен с\n                "),
        _c(
          "a",
          {
            staticClass:
              "vehicle-form__link vehicle-form__link_primary vehicle-form__link_tiny",
            attrs: {
              href: "https://blog.onliner.by/politika-konfidencialnosti",
            },
          },
          [_vm._v("Политикой обработки персональных данных")]
        ),
        _vm._v("\n                и\n                "),
        _c(
          "a",
          {
            staticClass:
              "vehicle-form__link vehicle-form__link_primary vehicle-form__link_tiny",
            attrs: { href: "https://blog.onliner.by/siterules" },
          },
          [_vm._v("Пользовательским соглашением")]
        ),
        _vm._v(
          ". Даю согласие на обработку своих персональных данных.\n              "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }