
import { Vue, Component } from 'vue-property-decorator';

import PromoteAdvertsManufacturer from '@/components/promote/promote-adverts-manufacturer.vue';
import PromoteAdvertsModel from '@/components/promote/promote-adverts-model.vue';
import PromoteAdvertsFilterPremium from '@/components/promote/promote-adverts-filter-premium.vue';

@Component({
  components: {
    PromoteAdvertsManufacturer,
    PromoteAdvertsModel,
    PromoteAdvertsFilterPremium,
  },
})
export default class PromoteAdvertsHeader extends Vue {}
