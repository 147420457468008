
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import RatingHeader from '@/components/common/rating-reviews/rating-header.vue';
  import RatingMark from '@/components/reviews/common/rating-mark.vue';
  import { ReviewsRating } from '@/types/review';
  import SecondaryParameter from '@/components/common/rating-reviews/secondary-parameter.vue';
  import DetailedInformation from '@/components/common/rating-reviews/detailed-information/detailed-information.vue';
  import { Chunk, HashMap } from '@/types/common';

  @Component({
    components: {
      DetailedInformation,
      SecondaryParameter,
      RatingMark,
      RatingHeader,
    },
  })
  export default class Rating extends Vue {
    @Prop() reviewsRating!: ReviewsRating;
    @Prop({
      default: false,
    })
    isCounterClickable!: boolean;
    @Prop({
      default: false,
    })
    isInfoBlockVisible!: boolean;
    @Prop()
    manufacturer!: Chunk;
    @Prop()
    model!: Chunk;
    @Prop()
    generation!: Chunk;
    @Prop({
      default: () => {
        return {};
      },
    })
    hide!: HashMap<boolean>;

    private get rating() {
      return this.reviewsRating.stats?.rating;
    }

    private get marks() {
      const marks: {
        key: string;
        dictionaryKey: string;
        value: number;
      }[] = [];

      for (let key of Object.keys(this.rating)) {
        marks.push({
          key,
          dictionaryKey: key,
          value: this.rating[key],
        });
      }

      return marks;
    }

    private get hasPros() {
      return !!this.reviewsRating.stats?.pros.length;
    }

    private get hasCons() {
      return !!this.reviewsRating.stats?.cons.length;
    }
  }
