import { MutationTree } from 'vuex';
import { HashMap } from '@onlinerby/js-common';

import { ICreateState } from '@/interfaces/reviews/store';
import {
  defaultState,
  defaultStatusesByStep,
} from '@/store/modules/reviews/create/state';

const mutations: MutationTree<ICreateState> = {
  init(state) {
    Object.assign(state, defaultState);
  },

  setMode(state, mode) {
    state.mode = mode;
  },

  setField(state, data) {
    state.data = { ...state.data, ...data };
  },

  setErrors(state, errors: HashMap<any>) {
    state.errors = { ...state.errors, ...errors };
  },

  clearError(state, field: string) {
    const errors: HashMap<string> = {};

    for (let key of Object.keys(state.errors)) {
      if (key !== field) {
        errors[key] = state.errors[key];
      }
    }

    state.errors = errors;
  },

  clearData(state) {
    state.data = {};
    state.statusesByStep = defaultStatusesByStep;
  },

  setStatusesByStep(state, data) {
    state.statusesByStep = { ...state.statusesByStep, ...data };
  },
};

export default mutations;
