import { MutationTree } from 'vuex';
import { findIndex, difference } from 'underscore';

import { ICommonState } from '@/interfaces/store';
import { Dictionaries, Dictionary } from '@/services/dictionaries-service';

const mutations: MutationTree<ICommonState> = {
  setDropdown(state, { id, isVisible }: { id: string; isVisible: boolean }) {
    const index = findIndex(state.dropdowns, { id });

    index !== -1
      ? state.dropdowns.splice(index, 1, { id, isVisible })
      : state.dropdowns.push({ id, isVisible });
  },

  removeDropdown(state, { id }: { id: string }) {
    const index = findIndex(state.dropdowns, { id });

    state.dropdowns.splice(index, 1);
  },

  setIsFirstPage(state, isFirstPage) {
    state.isFirstPage = isFirstPage;
  },

  setDictionaries(state, dictionaries: Dictionaries) {
    state.dictionaries = Object.assign(dictionaries, state.dictionaries);
  },

  setDictionary(
    state,
    {
      rootName,
      name,
      dictionary,
    }: { rootName: string; name: string; dictionary: Dictionary },
  ) {
    if (!rootName) {
      state.dictionaries = { ...state.dictionaries, [name]: dictionary };

      return;
    }

    if (!state.dictionaries[name]) {
      state.dictionaries[name] = [];
    }

    if (!state.dictionaries[rootName]) {
      state.dictionaries[rootName] = [];
    }

    let currentDictionary = state.dictionaries[name];
    let rootDictionary = state.dictionaries[rootName];

    if (
      difference(
        dictionary.map(item => item.id),
        rootDictionary.map(item => item.id),
      ).length ||
      difference(
        dictionary.map(item => item.id),
        currentDictionary.map(item => item.id),
      ).length
    ) {
      dictionary.forEach(item => {
        const index = findIndex(currentDictionary, { id: item.id });
        const rootIndex = findIndex(rootDictionary, { id: item.id });

        index !== -1
          ? currentDictionary.splice(index, 1, item)
          : currentDictionary.push(item);

        rootIndex !== -1
          ? rootDictionary.splice(rootIndex, 1, item)
          : rootDictionary.push(item);
      });

      state.dictionaries = Object.assign({}, state.dictionaries);
    }
  },

  setRootDictionary(
    state,
    {
      rootName,
      dictionary,
    }: { rootName: string; name: string; dictionary: Dictionary },
  ) {
    if (!state.dictionaries[rootName]) {
      state.dictionaries[rootName] = [];
    }

    let rootDictionary = state.dictionaries[rootName];

    if (
      difference(
        dictionary.map(item => item.id),
        rootDictionary.map(item => item.id),
      ).length
    ) {
      dictionary.forEach(item => {
        const rootIndex = findIndex(rootDictionary, { id: item.id });

        rootIndex !== -1
          ? rootDictionary.splice(rootIndex, 1, item)
          : rootDictionary.push(item);
      });

      state.dictionaries = Object.assign({}, state.dictionaries);
    }
  },
};

export default mutations;
