import * as moment from 'moment';

export default (value: string) => {
  const date = moment(value);
  const today = moment().startOf('day');

  if (date.isSame(today, 'day')) {
    return date.format('Сегодня, HH:mm');
  }

  const yesterday = moment()
    .subtract(1, 'days')
    .startOf('day');

  if (date.isSame(yesterday, 'day')) {
    return date.format('Вчера, HH:mm');
  }

  if (date.isSame(yesterday, 'year')) {
    return date.format('D MMMM, HH:mm');
  }

  return date.format('D MMMM YYYY, HH:mm');
};
