import { Mixin } from 'vue-mixin-decorator';
import { Prop, Vue } from 'vue-property-decorator';
import { Review } from '@/types/review';
import * as moment from 'moment';
import { URL_REGEXP } from '@/config/common';
import { HashMap } from '@/types/common';
import RatingMark from '@/components/reviews/common/rating-mark.vue';
import background from '@/filters/format-background';
import relativeDate from '@/filters/format-relative-date';

@Mixin({
  components: {
    RatingMark,
  },
  filters: {
    background,
    relativeDate,
  },
})
export default class ReviewCard extends Vue {
  private isPopoverOpened: boolean = false;

  @Prop() review!: Review;
  @Prop({
    default: false,
  })
  isTutorialVisible!: boolean;
  @Prop({
    default: false,
  })
  isTutorialWatched!: boolean;
  @Prop({
    default: true,
  })
  isMoreInfoShow!: boolean;

  private created() {
    if (this.isTutorialVisible && !this.isTutorialWatched) {
      this.isPopoverOpened = true;
    }
  }

  private get isDisabledImage() {
    const listOfStatusesThatDisableImage = [
      'waiting_moderation',
      'on_moderation',
      'closed',
    ];

    return listOfStatusesThatDisableImage.includes(this.review.status);
  }

  private get isClosedReview() {
    return this.review.status === 'closed';
  }

  private get carInformation() {
    const { manufacturer, model, generation, specs } = this.review;
    const capacity = specs.engine.capacity
      ? ` (${specs.engine.capacity} л)`
      : '';

    return `${manufacturer.name} ${model.name} ${generation.name}, ${specs.year}${capacity}`;
  }

  private get formattedPros() {
    return this.review.pros.map(plus => plus.title).join(', ');
  }

  private get formattedCons() {
    return this.review.cons.map(minus => minus.title).join(', ');
  }

  private get avgStars() {
    return (Math.round(this.review.ratingAverage * 2) / 2) * 10 || 0;
  }

  private get marks() {
    const marks: {
      key: string;
      dictionaryKey: string;
      value: number;
    }[] = [];

    for (let key of Object.keys(this.review.rating)) {
      marks.push({
        key,
        dictionaryKey: key,
        value: this.review.rating[key],
      });
    }

    return marks;
  }

  private get closedAt() {
    return moment(this.review.closedAt).format('DD.MM.YYYY в HH:mm');
  }

  private get closureReason() {
    return this.review.closureReason.replace(
      URL_REGEXP,
      url => '<a href="' + url + '" target="_blank">' + url + '</a>',
    );
  }

  private getImageUrl(images: HashMap<string> = {}) {
    return images['296x224'] || images.original;
  }

  private onMoreInfoCarCloseClick() {
    this.closePopover();
  }

  private onMoreInfoCarClick() {
    this.openPopover();
  }

  private onSubmitTutorialClick() {
    this.closePopover();
    this.$emit('submit-tutorial');
  }

  private onOverlayClick() {
    this.closePopover();
    this.$emit('tutorial-watched');
  }

  private openPopover() {
    if (!this.isMoreInfoShow) return;

    this.isPopoverOpened = true;
    $('body').addClass('body_state-popover-style_opened');
  }

  private closePopover() {
    this.isPopoverOpened = false;
    $('body').removeClass('body_state-popover-style_opened');
  }
}
