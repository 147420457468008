import { Vue, Watch } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { findWhere, findIndex, isEmpty } from 'underscore';

import urlService from '@/services/url-service';
import number from '@/filters/format-number';
import plural from '@/filters/plural-form';
import { Dictionaries } from '@/services/dictionaries-service';
import { IAdvert } from '@/interfaces/adverts';
import { Review } from '@/types/review';

const commonStore = namespace('common');

@Mixin
export default class ReviewMeta extends Vue {
  protected review: Review | null = null;

  @commonStore.State dictionaries!: Dictionaries;

  private isOgMetaSet: boolean = false;

  protected updateMeta() {
    const { manufacturer, model, generation } = this.review as Review;

    if (manufacturer && model && generation) {
      const title = `Отзыв автовладельца о  ${manufacturer.name} ${model.name}, ${generation.name} - Автобарахолка Onlíner`;
      const description = `Отзыв о ${manufacturer.name} ${model.name}. Рейтинги, плюсы и минусы автомобилей в «Автобарахолке» Onlíner`;

      this.$metaInfo.title = title;

      const index = findIndex(this.$metaInfo.meta, { name: 'description' });

      if (index !== -1) {
        this.$metaInfo.meta.splice(index, 1, {
          name: 'description',
          content: description,
        });
      }
    }

    !this.isOgMetaSet && !isEmpty(this.dictionaries) && this.setOgMeta();

    if (this.$route.meta.canonical === false) {
      this.$metaInfo.link.push({
        rel: 'canonical',
        href: urlService.secureProjectUrl(
          'ab',
          `reviews/${manufacturer.slug}/${model.slug}/${this.review?.id}`,
        ),
      });
    }

    this.$meta().refresh();
  }

  protected setOgMeta() {
    const { images, summary, manufacturer, model, generation } = this
      .review as Review;
    const title = `Отзыв на ${manufacturer.name} ${model.name} ${generation?.name}`;

    //OG
    this.$metaInfo.meta.push({
      name: 'og:title',
      content: title,
    });

    this.$metaInfo.meta.push({
      name: 'og:image',
      content: images[0]['172x132'],
    });

    this.$metaInfo.meta.push({
      name: 'og:site_name',
      content: `AB.ONLINER.BY`,
    });

    this.$metaInfo.meta.push({
      name: 'og:description',
      content: summary,
    });

    //Twitter
    this.$metaInfo.meta.push({
      name: 'twitter:card',
      content: `summary_large_image`,
    });

    this.$metaInfo.meta.push({
      name: 'twitter:site',
      content: `AB.ONLINER.BY`,
    });

    this.$metaInfo.meta.push({
      name: 'twitter:title',
      content: title,
    });

    this.$metaInfo.meta.push({
      name: 'twitter:description',
      content: summary,
    });

    this.$metaInfo.meta.push({
      name: 'twitter:image',
      content: images[0]['946x588'],
    });

    this.isOgMetaSet = true;
  }

  @Watch('dictionaries') onDictionariesChange() {
    !this.isOgMetaSet && !isEmpty(this.review) && this.setOgMeta();
  }
}
