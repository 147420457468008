var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
    },
    [
      _vm.generalCounters.reviews
        ? [
            _vm._v(
              _vm._s(_vm._f("number")(_vm.generalCounters.reviews.total)) +
                "\n    " +
                _vm._s(
                  _vm._f("plural")(
                    _vm.generalCounters.reviews.total,
                    ["отзыв", "отзыва", "отзывов"],
                    false
                  )
                )
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }