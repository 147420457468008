import { Vue } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import { UrlService } from '@onlinerby/js-common';

@Mixin
export default class LinksMixin extends Vue {
  private get commentsFormRedirectUrl() {
    return encodeURIComponent(UrlService.currentUrl + '#comments');
  }

  private get loginUrl() {
    return UrlService.getLoginUrl(this.commentsFormRedirectUrl);
  }

  private get registrationUrl() {
    return UrlService.getRegistrationUrl(this.commentsFormRedirectUrl);
  }
}
