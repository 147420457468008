export default {
  2: 'audi',
  5: 'bmw',
  10: 'chevrolet',
  11: 'chrysler',
  12: 'citroen',
  16: 'dodge',
  18: 'fiat',
  19: 'ford',
  23: 'honda',
  25: 'hyundai',
  32: 'kia',
  35: 'lexus',
  39: 'mazda',
  41: 'mercedes',
  44: 'mitsubishi',
  45: 'nissan',
  47: 'opel',
  48: 'peugeot',
  52: 'renault',
  57: 'skoda',
  59: 'subaru',
  61: 'toyota',
  62: 'volkswagen',
  63: 'volvo',
  65: 'lada',
};
