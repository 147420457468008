
import { Vue, Component } from 'vue-property-decorator';

import Popup from '@/components/common/popup.vue';

@Component({
  components: {
    Popup,
  },
})
export default class AdvertLeasingSuccessPopup extends Vue {
  public $refs!: {
    leasingSuccessPopup: HTMLFormElement;
  };

  public openPopup() {
    this.$refs.leasingSuccessPopup.openPopup();
  }
}
