
import { Vue, Component, Prop } from 'vue-property-decorator';

import { Review, ReviewStatusHistoryItem } from '@/types/review';
import vmApi from '@/api/vm-api';

import Popup from '@/components/common/popup.vue';
import ReviewStatusHistoryEntry from '@/components/reviews/common/review-status-history-entry.vue';

@Component({
  components: {
    Popup,
    ReviewStatusHistoryEntry,
  },
})
export default class ReviewStatusHistory extends Vue {
  private history: ReviewStatusHistoryItem[] = [];

  public $refs!: {
    historyPopup: HTMLFormElement;
  };

  @Prop() review!: Review;

  private onHistoryClick() {
    vmApi.getReviewStatusHistory(this.review.id, {
      success: ({ data }: { data: ReviewStatusHistoryItem[] }) => {
        this.history = data;
        this.$nextTick(() => {
          this.$refs.historyPopup.openPopup();
        });
      },
    });
  }
}
