var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a",
    {
      staticClass:
        "vehicle-form__link vehicle-form__link_primary vehicle-form__link_small vehicle-form__link_font-weight_normal",
      on: { click: _vm.onViewAllClick },
    },
    [
      _vm._v("\n  Смотреть все\n\n  "),
      _c(
        "popup",
        {
          ref: "ratingPopup",
          attrs: {
            classes: [
              "popup-style_primary",
              "popup-style_middle",
              "vehicle-popup",
              "vehicle-popup_width_xxllss",
              _vm.isLoading ? "popup-style_animated" : "",
            ],
          },
          on: { "popup:closed": _vm.onClosePopupClick },
        },
        [
          _vm.detailedInformation
            ? _c(
                "div",
                { staticClass: "vehicle-form" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
                    },
                    [
                      _vm._v(
                        "\n        Статистика по\n        " +
                          _vm._s(
                            _vm._f("plural")(_vm.detailedInformation.count, [
                              "отзыву",
                              "отзывам",
                              "отзывам",
                            ])
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_extended-extra",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.manufacturerName) +
                          " " +
                          _vm._s(_vm.modelName) +
                          " " +
                          _vm._s(_vm.generationName) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.detailedInformation.pros.length
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_base-alter vehicle-form__description_primary vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-complementary",
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm._f("plural")(
                                    _vm.detailedInformation.pros.length,
                                    ["плюс", "плюса", "плюсов"]
                                  )
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__accordion vehicle-form__accordion_specific",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__accordion-list" },
                              _vm._l(
                                _vm.detailedInformation.pros,
                                function (item) {
                                  return _c("secondary-parameter-item", {
                                    key: item.id,
                                    attrs: {
                                      id: item.id,
                                      label: item.title,
                                      "positive-value": item.positive,
                                      "negative-value": item.negative,
                                      "manufacturer-id": _vm.manufacturer.id,
                                      "model-id": _vm.model.id,
                                      "generation-id": _vm.generation.id,
                                      type: "pros",
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detailedInformation.cons.length
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_base-alter vehicle-form__description_primary vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-complementary",
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm._f("plural")(
                                    _vm.detailedInformation.cons.length,
                                    ["минус", "минуса", "минусов"]
                                  )
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__accordion vehicle-form__accordion_specific",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__accordion-list" },
                              _vm._l(
                                _vm.detailedInformation.cons,
                                function (item) {
                                  return _c("secondary-parameter-item", {
                                    key: item.id,
                                    attrs: {
                                      id: item.id,
                                      label: item.title,
                                      "positive-value": item.positive,
                                      "negative-value": item.negative,
                                      "manufacturer-id": _vm.manufacturer.id,
                                      "model-id": _vm.model.id,
                                      "generation-id": _vm.generation.id,
                                      type: "cons",
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detailedInformation.moot.length
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_base-alter vehicle-form__description_primary vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-complementary",
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm._f("plural")(
                                    _vm.detailedInformation.moot.length,
                                    [
                                      "спорное качество",
                                      "спорных качества",
                                      "спорных качества",
                                    ]
                                  )
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__accordion vehicle-form__accordion_specific",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__accordion-list" },
                              _vm._l(
                                _vm.detailedInformation.moot,
                                function (item) {
                                  return _c("secondary-parameter-item", {
                                    key: item.id,
                                    attrs: {
                                      id: item.id,
                                      label: item.title,
                                      "positive-value": item.positive,
                                      "negative-value": item.negative,
                                      "manufacturer-id": _vm.manufacturer.id,
                                      "model-id": _vm.model.id,
                                      "generation-id": _vm.generation.id,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }