var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "handle",
      staticClass: "popover-style__handle vehicle-form__popover-handle",
      class: {
        "popover-style__handle_opened": _vm.isVisible,
        "popover-style__handle_interactive": !_vm.isMobile,
      },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "popover-style popover-style_secondary popover-style_base popover-style_bottom vehicle-form__popover vehicle-form__popover_width_mmm",
        },
        [
          _c("div", { staticClass: "popover-style__content" }, [
            _c("div", { staticClass: "vehicle-form" }, [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_other vehicle-form__description_base",
                },
                [
                  _vm._v(
                    "Выберите премиальное размещение и получите максимальное количество просмотров вашего объявления!"
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__control vehicle-form__control_condensed-additional",
                },
                [
                  _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "gtm",
                          rawName: "v-gtm",
                          value: { event: "buy_premium_feature" },
                          expression: "{event: 'buy_premium_feature'}",
                        },
                      ],
                      staticClass:
                        "button-style button-style_primary button-style_base vehicle-form__button vehicle-form__button_width_full",
                      attrs: { to: { name: "promote" } },
                    },
                    [_vm._v("Попробовать")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "vehicle-form__popover-trigger",
        class: `vehicle-form__popover-trigger_${_vm.type}`,
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.onClick.apply(null, arguments)
          },
        },
      }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__preview" }, [
      _c("div", {
        staticClass: "vehicle-form__image vehicle-form__image_premium",
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed-default",
      },
      [
        _vm._v("\n          Ускорить продажу\n          "),
        _c("div", {
          staticClass:
            "vehicle-form__popover-trigger vehicle-form__popover-trigger_premium-alter",
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }