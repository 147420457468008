
  import { Component } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { Mixins } from 'vue-mixin-decorator';
  import { findWhere, isArray } from 'underscore';

  import { VuexAction } from '@/types/functions';
  import Commercial from '@/components/common/commercial.vue';
  import SearchHeader from '@/components/search/search-header.vue';
  import SearchFiltersBlock from '@/components/search/search-filters-block.vue';
  import SearchAdverts from '@/components/search/search-adverts.vue';
  import StateServiceMixin from '@/mixins/state-service';
  import Tiles from '@/components/tiles/tiles.vue';
  import MetaMixin from '@/mixins/adverts/meta';
  import GtmMixin from '@/mixins/common/gtm';
  import HumanizeMixin from '@/mixins/search/humanize';
  import FiltersMixin from '@/mixins/search/filters';
  import dictionariesService, {
    Dictionary,
  } from '@/services/dictionaries-service';
  import dictionariesMap from '@/config/dictionaries-map';
  import { HashMap, SchemaProperty } from '@/types/common';
  import { ICounters } from '@/interfaces/search';
  import { IUser } from '@/interfaces/common';
  import JsonLd from '@/components/common/json-ld.vue';
  import { socialIcon } from '@/config/urls';
  import { IFilter } from '@/interfaces/filters';
  import { filters, extendedFilters, FiltersConfig } from '@/config/filters';
  import { Searches } from '@/types/search';
  import { ReviewsRating } from '@/types/review';
  import vmApi from '@/api/vm-api';

  const searchStore = namespace('search');
  const commonStore = namespace('common');
  const userStore = namespace('user');

  interface IMixins
    extends StateServiceMixin,
      FiltersMixin,
      MetaMixin,
      GtmMixin,
      HumanizeMixin {}

  @Component({
    components: {
      Commercial,
      JsonLd,
      SearchHeader,
      SearchFiltersBlock,
      SearchAdverts,
      Tiles,
    },
    metaInfo() {
      return {
        title: 'Купить авто в Беларуси - Автобарахолка Onlíner',
        meta: [
          {
            name: 'description',
            content:
              'Автобарахолка Onlíner - это удобный способ купить или продать авто. Объявления о продаже новых и подержанных автомобилей',
          },
          {
            name: 'og:image',
            content: socialIcon,
          },
        ],
      };
    },
  })
  export default class SearchPage extends Mixins<IMixins>(
    MetaMixin,
    StateServiceMixin,
    GtmMixin,
    HumanizeMixin,
    FiltersMixin,
  ) {
    private isLoaded: boolean = false;
    private ld: HashMap<any> | null = null;
    private filtersConfig: FiltersConfig = filters;
    private extendedFilters: FiltersConfig = extendedFilters;
    private reviewsRating: ReviewsRating | null = null;
    private schemaProperties: SchemaProperty[] = [];

    @searchStore.State total!: ICounters;
    @searchStore.State('counters') stateCounters: any;
    @searchStore.State filters!: Array<IFilter>;
    @searchStore.State isLoading!: boolean;
    @searchStore.State searches!: Searches;
    @searchStore.Getter filtersObject!: any;
    @searchStore.Getter getFilter!: (
      name: string,
      filterType?: string,
    ) => IFilter;
    @searchStore.Action setFilters!: VuexAction;
    @searchStore.Action setFilter!: VuexAction;
    @searchStore.Action removeFilter!: VuexAction;
    @searchStore.Action updateList!: VuexAction;
    @searchStore.Action updateGeneralCounters!: VuexAction;
    @searchStore.Action setPage!: VuexAction;
    @searchStore.Action updateCounters!: VuexAction;
    @searchStore.Action clearFilters!: VuexAction;
    @searchStore.Action saveSearch!: VuexAction;

    @commonStore.State isFirstPage!: boolean;
    @commonStore.Action setDictionary!: VuexAction;

    @userStore.State currentUser!: IUser;

    public created() {
      this.updateFilters();
      this.updateSchema();
      this.initRouter();

      this.$root.$on('filter-car-change', () => {
        this.getReviewsRating();
      });

      this.$root.$on('filterChanged', this.onFiltersChange);
      this.$root.$on('filterChanged', this.onFilterChanged);
    }

    public destroyed() {
      this.$root.$off('filterChanged', this.onFiltersChange);
      this.$root.$off('filterChanged', this.onFilterChanged);
    }

    private setLd(data: any, total: number) {
      if (!data) {
        return;
      }

      const ld: HashMap<any> = {
        offers: {
          '@type': 'AggregateOffer',
          priceCurrency: data.lowPrice.currency,
          lowPrice: data.lowPrice.amount,
          highPrice: data.highPrice.amount,
          offerCount: total,
        },
      };

      if (this.model) {
        ld.brand = this.brand;
        ld.name = `${this.brand} ${this.model}`;
      } else if (this.brand) {
        ld.name = ld.brand = this.brand;
      }

      this.ld = ld;
    }

    private updateLd(data: any, total: number) {
      const isLdAvailable =
        (this.brand && this.model && this.filters.length === 1) ||
        (this.state === 'new' &&
          this.brand &&
          this.model &&
          this.filters.length === 2);

      if (isLdAvailable) {
        this.setLd(data, total);
      }
    }

    private updateFilters() {
      const filters = this.stateService.filters.filter(item => {
        return item.name !== 'page';
      });

      this.setFilters({ filters });
    }

    private initRouter() {
      this.$root.$on('filterChanged', this.updateGtm);
    }

    protected initList() {
      this.stateService.state.page
        ? this.setPage({
            page: this.stateService.state.page,
            callbacks: {
              complete: () => {
                this.getReviewsRating();
                this.isLoaded = true;
              },
            },
          })
        : this.updateList({
            callbacks: {
              success: ({
                expand,
                meta,
                total,
              }: {
                expand: any;
                meta: any;
                total: number;
              }) => {
                const dictionaries: HashMap<Dictionary> = {};

                this.updateGtm();

                for (let key of Object.keys(expand)) {
                  const dictionaryType: HashMap<Dictionary> = expand[key];

                  for (let index of Object.keys(dictionaryType)) {
                    if (dictionariesMap[key]) {
                      const dictionaryName = dictionariesMap[key].parent!;
                      const name = dictionariesMap[key].filterGroup;
                      const filterValue = findWhere(this.filters, {
                        name,
                      })!.value;
                      const data: any = isArray(filterValue)
                        ? findWhere(filterValue, {
                            [dictionaryName]: parseInt(index),
                          })
                        : filterValue;

                      const url = dictionariesService.buildUrl(
                        dictionaryName,
                        data,
                      );

                      this.setDictionary({
                        rootName: `${key}`,
                        name: url,
                        dictionary: expand[key][index],
                      });
                    }
                  }
                }

                this.updateLd(meta, total);
              },
              complete: () => {
                this.getReviewsRating();
                this.isLoaded = true;
              },
            },
          });
    }

    private updateGtm() {
      this.setPageData({
        search_results: this.total,
        search_filters: JSON.stringify(this.filtersObject),
        user_id: this.currentUser ? this.currentUser.id.toString() : '',
      });

      this.$gtm.trackEvent({
        event: 'content-view',
        'content-view-name': 'listing',
      });
    }

    private updateSchema() {
      vmApi.getSearchSchema('vehicles', {
        success: ({
          data: { properties },
        }: {
          data: { properties: SchemaProperty[] };
        }) => {
          this.schemaProperties = properties;
        },
      });
    }

    private getReviewsRating() {
      this.reviewsRating = null;
      const filtersByCar = this.getFilter('car').value;

      if (!filtersByCar || filtersByCar.length > 1 || this.total < 1) return;

      const filter = { ...filtersByCar[0] }; // For review only 1 element, but in ab can be more than 1

      if (filter.generation) {
        filter.generation =
          filter.generation.length > 1 ? undefined : filter.generation[0];
      }

      const params = {
        car: filter,
        include: filter.generation ? 'stats' : undefined,
      };

      vmApi.getReviewsRating(params, {
        success: ({ data }: { data: ReviewsRating }) => {
          if (data.stats && data.count < 3) {
            data.stats.cons = [];
            data.stats.pros = [];
          }

          if (data.count < 1) {
            this.reviewsRating = null;
          } else {
            this.reviewsRating = {
              ...data,
              ratingBy: params.car.model ? 'модели' : 'марки',
              car: {
                manufacturer: {
                  id: filter.manufacturer,
                },
                model: {
                  id: filter.model,
                },
                generation: {
                  id: filter.generation,
                },
              },
            };
          }
        },
      });
    }

    private onFilterChanged() {
      const { path, filters } = this.humanize();

      this.stateService.setState(filters);
      this.$router
        .replace({ path, query: this.stateService.state })
        .catch(ex => {});
    }
  }
