var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__group vehicle-form__group_width_xxs" },
    [
      _c("div", { staticClass: "vehicle-form__field" }, [
        _c(
          "div",
          {
            ref: "handle",
            staticClass:
              "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_width_full",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "input-style input-style_primary input-style_base input-style_arrow_bottom vehicle-form__input vehicle-form__input_width_full",
                class: {
                  "input-style_disabled": !_vm.manufacturerId,
                  "input-style_placeholder": !_vm.model,
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.toggleDropdown.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "input-style__faux" }, [
                  _vm._v(_vm._s(_vm.selectedTitle)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "input-style__real" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "dropdown",
                staticClass:
                  "dropdown-style dropdown-style_primary dropdown-style_small dropdown-style_bottom dropdown-style_specific /*dropdown-style_visible*/ vehicle-form__dropdown vehicle-form__dropdown_width_full",
                class: { "dropdown-style_visible": _vm.isVisible },
              },
              [
                _c("span", {
                  staticClass: "dropdown-style__close helpers_show_tablet",
                }),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-style__top" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "input-style__wrapper dropdown-style__input-wrapper dropdown-style__input-wrapper_width_full",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.optionsFilter,
                            expression: "optionsFilter",
                          },
                        ],
                        staticClass:
                          "input-style input-style_primary input-style_small dropdown-style__input dropdown-style__input_width_full",
                        attrs: { type: "text", placeholder: "Найти модель" },
                        domProps: { value: _vm.optionsFilter },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.optionsFilter = $event.target.value
                            },
                            function ($event) {
                              _vm.optionsFilter = $event.target.value
                            },
                          ],
                        },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "input-style__helper input-style__helper_clear /*input-style__helper_visible*/ dropdown-style__input-helper",
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-style__container" }, [
                  _c("div", { staticClass: "dropdown-style__content" }, [
                    _c("div", { staticClass: "dropdown-style__group" }, [
                      _c("ul", { staticClass: "dropdown-style__list" }, [
                        _c(
                          "li",
                          {
                            staticClass:
                              "dropdown-style__item dropdown-style__item_indent",
                          },
                          [
                            _c(
                              "label",
                              { staticClass: "dropdown-style__checkbox-label" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "i-checkbox i-checkbox_dot-alter dropdown-style__checkbox",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.model,
                                          expression: "model",
                                        },
                                      ],
                                      staticClass: "i-checkbox__real",
                                      attrs: { type: "radio", name: "model" },
                                      domProps: {
                                        value: NaN,
                                        checked: _vm._q(_vm.model, NaN),
                                      },
                                      on: {
                                        change: [
                                          function ($event) {
                                            _vm.model = NaN
                                          },
                                          function ($event) {
                                            return _vm.onChange()
                                          },
                                        ],
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "i-checkbox__faux",
                                    }),
                                    _vm._v(" "),
                                    _vm._m(0),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "dropdown-style__group" }, [
                      _c(
                        "ul",
                        { staticClass: "dropdown-style__list" },
                        _vm._l(_vm.models, function (item) {
                          return _c(
                            "li",
                            {
                              staticClass:
                                "dropdown-style__item dropdown-style__item_indent dropdown-style__item_letter",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "dropdown-style__checkbox-label",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "i-checkbox i-checkbox_dot dropdown-style__checkbox",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.model,
                                            expression: "model",
                                          },
                                        ],
                                        staticClass: "i-checkbox__real",
                                        attrs: { type: "radio", name: "model" },
                                        domProps: {
                                          value: item.id,
                                          checked: _vm._q(_vm.model, item.id),
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              _vm.model = item.id
                                            },
                                            function ($event) {
                                              return _vm.onChange()
                                            },
                                          ],
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "i-checkbox__faux",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "dropdown-style__checkbox-text",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "dropdown-style__checkbox-sign",
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "device-class",
                        rawName: "v-device-class",
                        value: [
                          {
                            device: "iphonex",
                            deviceClass: "dropdown-style__bottom_alter",
                          },
                          {
                            device: "iphonexsmax",
                            deviceClass: "dropdown-style__bottom_alter",
                          },
                          {
                            device: "iphonexr",
                            deviceClass: "dropdown-style__bottom_alter",
                          },
                        ],
                        expression:
                          "[{device: 'iphonex', deviceClass: 'dropdown-style__bottom_alter'},\n                           {device: 'iphonexsmax', deviceClass: 'dropdown-style__bottom_alter'},\n                           {device: 'iphonexr', deviceClass: 'dropdown-style__bottom_alter'}]",
                      },
                    ],
                    staticClass: "dropdown-style__bottom helpers_show_tablet",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "button-style button-style_additional button-style_small dropdown-style__button",
                        on: { click: _vm.closeDropdown },
                      },
                      [_vm._v("Готово")]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "dropdown-style__overlay helpers_show_tablet",
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dropdown-style__checkbox-text" }, [
      _c("div", { staticClass: "dropdown-style__checkbox-sign" }, [
        _vm._v("Любая"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }