var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "vehicle-form__control vehicle-form__control_condensed-fringe vehicle-form__control_management",
    },
    [
      _vm.permissions.edit
        ? _c(
            "router-link",
            {
              staticClass:
                "button-style button-style_pony button-style_base vehicle-form__button",
              attrs: {
                to: { name: "edit-review", params: { id: _vm.review.id } },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_primary-alter",
                },
                [_vm._v("\n      Редактировать\n    ")]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.permissions.open
        ? _c(
            "div",
            {
              staticClass:
                "button-style button-style_pony button-style_base vehicle-form__button",
              on: { click: _vm.onOpenClick },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_success",
                },
                [_vm._v("\n      Открыть\n    ")]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.permissions.close
        ? _c("review-close", {
            attrs: { review: _vm.review },
            on: { close: _vm.onClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.permissions.moderate
        ? _c(
            "div",
            {
              staticClass:
                "button-style button-style_appendant button-style_base vehicle-form__button",
              on: { click: _vm.onModerateClick },
            },
            [_vm._v("\n    Взять в работу\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.permissions.publish
        ? _c(
            "div",
            {
              staticClass:
                "button-style button-style_primary button-style_base vehicle-form__button",
              on: { click: _vm.onPublishClick },
            },
            [_vm._v("\n    Опубликовать\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.permissions["view-status-history"]
        ? _c("review-status-history", { attrs: { review: _vm.review } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }