var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__row" }, [
    _c(
      "div",
      { staticClass: "vehicle-form__group vehicle-form__group_width_full" },
      [
        _vm.label
          ? _c(
              "div",
              { staticClass: "vehicle-form__label vehicle-form__label_base" },
              [
                _c("div", { staticClass: "vehicle-form__label-flex" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__label-part vehicle-form__label-part_1",
                    },
                    [
                      _c("div", { staticClass: "vehicle-form__label-title" }, [
                        _vm._v(_vm._s(_vm.label)),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass:
                      "vehicle-form__label-part vehicle-form__label-part_2",
                  }),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "vehicle-form__field" }, [
          _c("div", { staticClass: "vehicle-form__line" }, [
            _c(
              "ul",
              { staticClass: "vehicle-form__checkbox-list" },
              [_vm._t("default")],
              2
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }