var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__parameter-item" }, [
    _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__parameter-part vehicle-form__parameter-part_1 vehicle-form__parameter-part_width_x",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_small",
            },
            [_vm._v("\n        " + _vm._s(_vm.label) + "\n      ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__parameter-part vehicle-form__parameter-part_2",
        },
        [
          _c("secondary-parameter-diagram", {
            attrs: {
              "positive-value": _vm.positiveValue,
              "negative-value": _vm.negativeValue,
              "is-small": true,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }