var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-content" },
    [
      _c("navigation"),
      _vm._v(" "),
      _vm.isUserProcessed
        ? _c("div", { staticClass: "vehicle-wrapper" }, [_c("router-view")], 1)
        : _vm._e(),
      _vm._v(" "),
      _vm.isBannerVisible
        ? _c("google-banner", {
            attrs: {
              "slot-id": "dweb_AB_970x250_footer",
              params: [
                "/282428283/dweb_AB_970x250_footer/dweb_AB_970x250_footer",
                ["fluid", [970, 90], [970, 250], [728, 90]],
                "dweb_AB_970x250_footer",
              ],
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }