import { MutationTree } from 'vuex';

import { IUserState } from '@/interfaces/store';
import { IUser } from '@/interfaces/common';

const mutations: MutationTree<IUserState> = {
  setUser(state, user: IUser) {
    state.currentUser = user;
  },
};

export default mutations;
