
import { Component, Mixins } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { findWhere } from 'underscore';

import { HashMap } from '@/types/common';
import StepContactsLocationCountry from '@/components/create/step-contacts/step-contacts-location-country.vue';
import StepContactsLocationRegion from '@/components/create/step-contacts/step-contacts-location-region.vue';
import StepContactsLocationCity from '@/components/create/step-contacts/step-contacts-location-city.vue';
import { Dictionaries, Dictionary } from '@/services/dictionaries-service';
import { VuexAction } from '@/types/functions';
import StorageMixin from '@/mixins/create/storage';
import dictionariesService from '@/services/dictionaries-service';
import dictionariesMap from '@/config/dictionaries-map';

const createStore = namespace('create');
const commonStore = namespace('common');

@Component({
  components: {
    StepContactsLocationCountry,
    StepContactsLocationRegion,
    StepContactsLocationCity,
  },
})
export default class StepContactsLocation extends Mixins<StorageMixin>(
  StorageMixin
) {
  @createStore.State data!: HashMap<any>;
  @createStore.State errors!: HashMap<any>;
  @commonStore.State dictionaries!: Dictionaries;
  @commonStore.Action setDictionary!: VuexAction;
  @createStore.Action setField!: VuexAction;

  public created() {
    this.initCountry();
    this.initRegion();
    this.initCity();
  }

  private initCountry() {
    const name = 'country';
    const country = this.getStorageValue(name);

    if (country) {
      this.setField({ country });

      const data = {
        country,
      };
      const url = dictionariesService.buildUrl(name, data);

      dictionariesService.getDictionary(name, data, (data: any) => {
        this.setDictionary({
          rootName: dictionariesMap[name].rootName,
          name: url,
          dictionary: data,
        });
      });
    }
  }

  private initRegion() {
    const name = 'region';
    const region = this.getStorageValue(name);

    if (region) {
      this.setField({ region });

      const data = {
        country: this.data.country,
        region,
      };
      const url = dictionariesService.buildUrl(name, data);

      dictionariesService.getDictionary(name, data, (data: any) => {
        this.setDictionary({
          rootName: dictionariesMap[name].rootName,
          name: url,
          dictionary: data,
        });
      });
    }
  }

  private initCity() {
    const cityId = this.getStorageValue('cityId');

    if (cityId) {
      this.setField({ cityId });
    }
  }

  private get error() {
    return (
      this.errors['country'] ||
      this.errors['region'] ||
      this.errors['cityId'] ||
      []
    ).join('. ');
  }
}
