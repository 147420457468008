var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__step-list" },
    [
      _c(
        "step-wrapper",
        { ref: "tech", attrs: { step: "tech" } },
        [
          !_vm.isMobileSpecial
            ? _c("step-tech-header")
            : _c("step-tech-header-mobile"),
          _vm._v(" "),
          _vm.isMobileSpecial || !_vm.data.manufacturerId
            ? [
                !_vm.isMobileSpecial
                  ? _c("step-tech-manufacturer")
                  : _c("step-tech-manufacturer-mobile"),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isMobileSpecial || (_vm.data.manufacturerId && !_vm.data.modelId)
            ? [
                !_vm.isMobileSpecial
                  ? _c("step-tech-model")
                  : _c("step-tech-model-mobile"),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isMobileSpecial || _vm.data.modelId
            ? [
                !_vm.isMobileSpecial
                  ? _c("step-tech-year")
                  : _c("step-tech-year-mobile"),
                _vm._v(" "),
                _vm.data.manufacturerId &&
                _vm.data.modelId &&
                _vm.data.specs.year
                  ? _c("step-tech-generation")
                  : _vm._e(),
                _vm._v(" "),
                _c("step-tech-modification"),
                _vm._v(" "),
                !_vm.isMobileSpecial
                  ? _c("step-tech-body-type")
                  : _c("step-tech-body-type-mobile"),
                _vm._v(" "),
                _c("step-tech-engine-type"),
                _vm._v(" "),
                _c("step-tech-drivetrain"),
                _vm._v(" "),
                _c("step-tech-transmission"),
                _vm._v(" "),
                _c("step-tech-manual-control"),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__control vehicle-form__control_condensed-specific",
                  },
                  [
                    _vm.isEdit
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "button-style button-style_primary button-style_middle vehicle-form__button vehicle-form__button_width_sssm",
                            on: { click: _vm.onFinishClick },
                          },
                          [_vm._v("Подтвердить")]
                        )
                      : _c(
                          "a",
                          {
                            staticClass:
                              "button-style button-style_primary button-style_middle vehicle-form__button vehicle-form__button_width_sssm",
                            on: { click: _vm.onNextClick },
                          },
                          [_vm._v("Далее")]
                        ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "step-wrapper",
        { attrs: { step: "exterior" } },
        [_c("step-exterior-header")],
        1
      ),
      _vm._v(" "),
      _c(
        "step-wrapper",
        { attrs: { step: "cost" } },
        [_c("step-cost-header")],
        1
      ),
      _vm._v(" "),
      _c(
        "step-wrapper",
        { attrs: { step: "contacts" } },
        [_c("step-contacts-header")],
        1
      ),
      _vm._v(" "),
      _vm.mode === "edit" ? _c("create-finish") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }