var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__feature-item" }, [
    _c("div", { staticClass: "vehicle-form__feature-box" }, [
      _c("div", { staticClass: "vehicle-form__person" }, [
        _c("div", { staticClass: "vehicle-form__person-list" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__person-item vehicle-form__person-item_condensed-alter",
            },
            [
              _c("div", { staticClass: "vehicle-form__preview" }, [
                _c("div", {
                  staticClass: "vehicle-form__image",
                  style: _vm._f("background")(_vm.review.author.avatar),
                }),
                _vm._v(" "),
                _vm.review.author.onlineStatus &&
                _vm.review.author.onlineStatus.isOnline
                  ? _c("div", {
                      staticClass:
                        "vehicle-form__status vehicle-form__status_online",
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-appendant",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "vehicle-form__link vehicle-form__link_primary-alter vehicle-form__link_base-alter",
                      attrs: { target: "_blank", href: _vm.profileLink },
                    },
                    [_vm._v(_vm._s(_vm.review.author.name))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny vehicle-form__description_condensed-other",
                },
                [
                  _vm._v(
                    "\n            Владеет автомобилем " +
                      _vm._s(_vm.tenureString) +
                      "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "vehicle-form__link vehicle-form__link_primary vehicle-form__link_small vehicle-form__link_enter",
                      attrs: {
                        to: {
                          name: "user-reviews",
                          params: { id: _vm.review.author.id },
                        },
                      },
                    },
                    [_vm._v("\n              Все отзывы автора\n            ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__person-item vehicle-form__person-item_condensed helpers_show_tablet",
            },
            [
              _c(
                "router-link",
                {
                  directives: [
                    {
                      name: "gtm",
                      rawName: "v-gtm",
                      value: { event: "create_review" },
                      expression: "{ event: 'create_review' }",
                    },
                  ],
                  staticClass:
                    "button-style button-style_appendant button-style_base vehicle-form__button vehicle-form__button_width_full",
                  attrs: { to: { name: "create-review" } },
                },
                [_vm._v("\n            Написать отзыв\n          ")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }