
  import { HashMap } from '@onlinerby/js-common';
  import { Component, Mixins } from 'vue-property-decorator';
  import { isEmpty } from 'underscore';
  import { namespace } from 'vuex-class';
  import { Dictionaries } from '@/services/dictionaries-service';
  import { stepStatuses } from '@/config/reviews/create';
  import { VuexAction } from '@/types/functions';

  import StepTechDataAb from '@/components/reviews/create/step-tech/step-tech-data-ab.vue';
  import StepMutationData from '@/mixins/reviews/create/step-mutation-data';
  import StepMixin from '@/mixins/reviews/create/step';
  import StepMutationDataMixin from '@/mixins/reviews/create/step-mutation-data';
  import StorageMixin from '@/mixins/reviews/create/storage';
  import vmApi from '@/api/vm-api';
  import { ListPageResponse } from '@/types/api';
  import { ISearchAdvert } from '@/interfaces/adverts';
  import { IUser } from '@/interfaces/common';
  import { defaultData } from '@/config/reviews/create';
  import { socialIcon } from '@/config/urls';
  import StepTech from '@/components/reviews/create/step-tech/step-tech.vue';

  const commonStore = namespace('common');
  const createStore = namespace('reviews/create');

  const userStore = namespace('user');

  interface IMixins extends StepMixin, StepMutationDataMixin, StorageMixin {}

  @Component({
    components: {
      StepTechDataAb,
    },
    metaInfo(): any {
      return {
        title: 'Добавить отзыв - Автобарахолка Onlíner',
        meta: [
          {
            name: 'description',
            content: 'Добавить отзыв - Автобарахолка Onlíner',
          },
          {
            name: 'og:image',
            content: socialIcon,
          },
        ],
      };
    },
  })
  export default class Create extends Mixins<IMixins>(
    StepMixin,
    StepMutationData,
    StorageMixin,
  ) {
    protected step: string = 'tech';

    private mostRecentAdvert: ISearchAdvert | null = null;
    private beforeEach!: Function;

    @commonStore.State dictionaries!: Dictionaries;

    @createStore.State statusesByStep!: HashMap<string>;
    @createStore.State mode!: string;
    @createStore.Action setStatusesByStep!: VuexAction;
    @createStore.Action setMode!: VuexAction;
    @createStore.Action setField!: VuexAction;
    @createStore.Action init!: VuexAction;
    @createStore.Action clearData!: VuexAction;

    @userStore.State currentUser!: IUser;

    private get isLoading() {
      return isEmpty(this.dictionaries);
    }

    private get isTechStep() {
      return this.$route.name === 'create-review-tech';
    }

    protected created() {
      this.checkStep();

      this.init();

      this.setMode({ mode: 'create' });

      this.checkCachedData();

      vmApi.getAdverts(
        this.currentUser.id,
        {
          forReview: true,
          limit: 1,
        },
        {
          success: ({ data }: ListPageResponse) => {
            this.mostRecentAdvert = data.adverts[0];
          },
        },
      );

      this.beforeEach = this.$router.beforeEach((to, from, next) => {
        if (this.statusesByStep[to.meta.stepName] !== stepStatuses.edit) {
          this.setStatusesByStep({ [to.meta.stepName]: stepStatuses.current });
        }

        next();
      });
    }

    private destroyed() {
      this.clearData();
      this.beforeEach();
    }

    private checkStep() {
      if (!this.isTechStep) {
        this.$router.replace({ name: 'create-review-tech' });
      }
    }

    private scrollToNext() {
      (this.$refs.step as StepTech).scrollToNext();
    }

    private onSubmitMostRecentAdvert() {
      if (!this.mostRecentAdvert) {
        return;
      }

      const {
        manufacturer,
        model,
        generation,
        specs: { bodyType, year, engine, drivetrain, transmission, odometer },
        images,
      } = this.mostRecentAdvert;

      if (!engine.power) {
        engine.power = { ...defaultData.specs.engine.power };
      }

      if (odometer.value === 0) {
        delete odometer.value;
      }

      this.onManufacturerChange(Number(manufacturer.id));
      this.onModelChange(Number(model.id));
      generation && this.onGenerationChange(generation.id);
      this.onYearChange(year);
      this.onBodyTypeChange(bodyType);
      this.onEngineChange(engine);
      this.onDrivetrainChange(drivetrain);
      this.onTransmissionChange(transmission);

      this.onPhotoChange(images);
      this.onOdometerChange(odometer);

      this.$nextTick(() => {
        this.scrollToNext();
      });
    }

    private checkCachedData() {
      const data = this.getStorageValue('data');

      if (data) {
        this.setField(data);
        data.manufacturerId && this.onManufacturerChange(data.manufacturerId);
        data.modelId && this.onModelChange(data.modelId);
        data.generationId && this.onGenerationChange(data.generationId);
      }
    }
  }
