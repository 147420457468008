import { findWhere } from 'underscore';
import { GetterTree } from 'vuex';
import { ISearchState, IRootState } from '@/interfaces/reviews/store';
import { IFilter } from '@/interfaces/filters';
import { HashMap } from '@/types/common';

const getters: GetterTree<ISearchState, IRootState> = {
  filters(state): Array<IFilter> {
    return state.filters;
  },

  filtersObject(state): object {
    const filtersObject: HashMap<any> = {};

    state.filters.forEach(({ name, value }) => {
      filtersObject[name] = value;
    });

    return filtersObject;
  },

  getFilter: state => (name: string, filterType: string = 'primitive') => {
    if (filterType === 'primitive') {
      return (
        findWhere(state.filters, { name }) || {
          name,
          value: '',
        }
      );
    }

    const escapedName = name.replace('[', '\\[').replace(']', '\\]');
    const regex = new RegExp(`(^${escapedName})\\[(.*?)\\]`);
    let value;

    if (filterType === 'object') {
      value = {};
    } else if (filterType === 'array') {
      value = [];
    }

    const resultingFilter: any = {
      name,
      value,
    };

    state.filters.forEach(filter => {
      if (filter.name === name) {
        resultingFilter.value = filter.value;
      } else if (regex.test(filter.name)) {
        const groups = regex.exec(filter.name) || [];
        const index = groups[2] || '';

        if (filterType === 'object') {
          resultingFilter.value[index] = filter.value;
        } else if (filterType === 'array') {
          resultingFilter.value.push(filter.value);
        }
      }
    });

    return resultingFilter;
  },
};

export default getters;
