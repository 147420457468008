
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';

import SearchAdvertsListPhotos from './search-adverts-list-photos.vue';
import SearchAdvertsListBookmark from './search-adverts-list-bookmark.vue';
import SearchAdvertsListPremium from './search-adverts-list-premium.vue';
import AdvertsListItemMixin from '@/mixins/adverts/adverts-list-item';

@Component({
  components: {
    SearchAdvertsListPhotos,
    SearchAdvertsListBookmark,
    SearchAdvertsListPremium,
  },
})
export default class SearchAdvertsListItem extends Mixins<AdvertsListItemMixin>(
  AdvertsListItemMixin
) {}
