import { IPromoteState } from '@/interfaces/store';

const state: IPromoteState = {
  isLoading: true,
  features: [],
  entities: [],
  periods: [],
  paymentMethods: [],
  page: {},
  selectedFeature: {
    recurrent: false,
  },
  isRecurrent: false,
  selectedPaymentMethod: null,
  isPaymentProcessing: false,
  total: 0,
  manufacturerId: '',
  modelId: '',
  showNonPremiumOnly: false,
  generalCounters: {},
};

export default state;
