
import { Component } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import StepTechHeaderMixin from "@/mixins/reviews/create/step-tech-header";

@Component
export default class StepTechHeaderMobile extends Mixins<StepTechHeaderMixin>(StepTechHeaderMixin) {
  private get title() {
    const parts: Array<string> = [];

    this.manufacturerName && parts.push(this.manufacturerName);
    this.modelName && parts.push(this.modelName);

    return parts.join(', ');
  }
}
