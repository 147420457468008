
import { Vue, Component } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';

import { vmApi, VmTiles } from '@onlinerby/js-api-clients';

import Tile from '@/components/tiles/tile.vue';
import SwiperMixin from '@/mixins/common/swiper';

@Component({
  components: {
    Tile,
  },
})
export default class Tiles extends Mixins<SwiperMixin>(SwiperMixin) {
  private isLoading: boolean = true;
  private tiles: VmTiles | [] = [];

  private created() {
    vmApi.getTiles({
      success: (data: VmTiles) => {
        this.tiles = data;
      },
      complete: () => {
        this.isLoading = false;
        this.$nextTick(this.initSwiper);
      },
    });
  }
}
