
  import { Watch, Prop } from 'vue-property-decorator';
  import { Mixins, Component } from 'vue-mixin-decorator';
  import { isEqual, find } from 'underscore';

  import { Dictionary } from '@/services/dictionaries-service';
  import FieldMixin from '@/mixins/reviews/create/field';
  import background from '@/filters/format-background';

  @Component({
    filters: {
      background,
    },
  })
  export default class StepTechGeneration extends Mixins<FieldMixin>(
    FieldMixin,
  ) {
    @Prop() generationsByModel!: Array<any>;
    @Prop() year!: string;
    @Prop() modelName!: string;

    private created() {
      this.initDefaultGeneration();
    }

    private get generations() {
      const dictionary = this.generationsByModel;

      if (!dictionary) {
        return null;
      }

      return dictionary.filter((item: any) => {
        return (
          this.year >= item.year.from &&
          (this.year <= item.year.to || item.year.to === null)
        );
      });
    }

    private get generation() {
      return this.data.generationId;
    }

    private set generation(value: number | null) {
      this.onFieldChange(value);
    }

    private initDefaultGeneration() {
      const generations = this.generations;

      if (generations && generations.length === 1) {
        this.generation = parseInt(this.generations![0].id);
      } else if (
        generations &&
        this.generation &&
        !find(generations, { id: this.generation })
      ) {
        this.generation = null;
      }
    }

    @Watch('generations')
    private onGenerationsChange(generations: Dictionary, oldValue: Dictionary) {
      if (isEqual(generations, oldValue)) {
        return;
      }

      this.initDefaultGeneration();
    }
  }
