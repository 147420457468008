import { render, staticRenderFns } from "./promote-adverts-manufacturer.vue?vue&type=template&id=4ba810ab&"
import script from "./promote-adverts-manufacturer.vue?vue&type=script&lang=ts&"
export * from "./promote-adverts-manufacturer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports