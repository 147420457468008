var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "vehicle-form__accordion-item vehicle-form__accordion-item_grade",
      class: {
        "vehicle-form__accordion-item_opened": _vm.isOpened,
        "vehicle-form__accordion-item_animated": !_vm.isOpened && _vm.isLoading,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "vehicle-form__accordion-header",
          on: { click: _vm.onClickToggle },
        },
        [
          _c("div", { staticClass: "vehicle-form__accordion-flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__accordion-part vehicle-form__accordion-part_1",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
                  },
                  [_vm._v("\n          " + _vm._s(_vm.label) + "\n        ")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__accordion-part vehicle-form__accordion-part_2",
              },
              [
                _c("secondary-parameter-diagram", {
                  attrs: {
                    "positive-value": _vm.positiveValue,
                    "negative-value": _vm.negativeValue,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "vehicle-form__accordion-body" }, [
        _c(
          "div",
          { staticClass: "vehicle-form__proscons" },
          _vm._l(_vm.reviews, function (review) {
            return _c(
              "div",
              {
                key: review.id,
                staticClass:
                  "vehicle-form__description vehicle-form__description_middle vehicle-form__description_other",
                class: _vm.getIconClass(review.pros),
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_middle vehicle-form__description_other vehicle-form__description_condensed-appendant",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "vehicle-form__link vehicle-form__link_middle vehicle-form__link_primary",
                        attrs: {
                          to: {
                            name: "review",
                            params: {
                              manufacturer: review.manufacturer.slug,
                              model: review.model.slug,
                              id: review.id,
                            },
                          },
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(review.summary) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_tiny vehicle-form__description_other vehicle-form__description_condensed-appendant",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(review.author.name) +
                        ",\n          " +
                        _vm._s(_vm._f("relativeDate")(review.statusChangedAt)) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.page.items && _vm.page.last > _vm.page.current
          ? _c(
              "div",
              {
                staticClass:
                  "vehicle-form__control vehicle-form__control_condensed-fringe",
              },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "button-style button-style_auxiliary button-style_small vehicle-form__button",
                    class: {
                      "button-style_animated": _vm.isLoading,
                    },
                    on: { click: _vm.onClickNextPage },
                  },
                  [
                    _vm._v(
                      "\n        Показать еще " +
                        _vm._s(
                          _vm._f("plural")(_vm.count, [
                            "отзыв",
                            "отзыва",
                            "отзывов",
                          ])
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }