var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "dropdown-style__group" }, [
    _c("div", { staticClass: "dropdown-style__title" }, [
      _vm._v("Популярные марки"),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "dropdown-style__list dropdown-style__list_brand" },
      _vm._l(_vm.popular, function (item) {
        return _c(
          "li",
          {
            key: item.id,
            staticClass: "dropdown-style__item dropdown-style__item_brand",
            class: `dropdown-style__item_brand_${_vm.getClassName(
              item.id
            )}-alter`,
            attrs: { title: item.name },
          },
          [
            _c("label", { staticClass: "dropdown-style__checkbox-label" }, [
              _c(
                "div",
                { staticClass: "i-checkbox dropdown-style__checkbox" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.id,
                        expression: "id",
                      },
                    ],
                    staticClass: "i-checkbox__real",
                    attrs: {
                      type: "radio",
                      name: `popular_${_vm.dictionaryName}`,
                    },
                    domProps: {
                      value: item.id,
                      checked: _vm._q(_vm.id, item.id),
                    },
                    on: {
                      change: function ($event) {
                        _vm.id = item.id
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "dropdown-style__checkbox-text" }),
                ]
              ),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }