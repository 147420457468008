import { Prop } from 'vue-property-decorator';
import { Mixin, Mixins } from 'vue-mixin-decorator';
import dictionariesService, {
  Dictionaries,
  Dictionary,
} from '@/services/dictionaries-service';
import { namespace } from 'vuex-class';
import { IFilter } from '@/interfaces/filters';
import { VuexAction } from '@/types/functions';
import { debounce, isNumber } from 'underscore';
import camelize from '@/filters/format-camelize';

import IsMobileMixin from '@/mixins/common/is-mobile';
import InterfacePropsMixin from '@/mixins/search/interface-props';
import dictionariesMap from '@/config/dictionaries-map';
import { HashMap } from '@/types/common';
import camelizeService from '@/services/camelize-service';

const commonStore = namespace('common');

interface IMixins extends IsMobileMixin, InterfacePropsMixin {}

@Mixin
export default class FilterMixin extends Mixins<IMixins>(
  IsMobileMixin,
  InterfacePropsMixin,
) {
  protected _updateFilter!: Function;

  @Prop() label!: string;
  @Prop() labelAny!: string;
  @Prop() labelPlaceholder!: string;
  @Prop() name!: string;
  @Prop() groupIndex!: number;
  @Prop() withImages!: boolean;
  @Prop() usePreferred!: boolean;
  @Prop() globalEmit!: string;

  @commonStore.State dictionaries!: Dictionaries;
  @commonStore.Action setDictionary!: VuexAction;

  protected filterType: string = 'primitive';
  protected selectedPreferred: HashMap<boolean> = {};

  protected mounted() {
    this._updateFilter = debounce(
      this.update,
      this.isMobileSpecial ? 500 : 200,
    );
  }

  protected get filter(): IFilter {
    const filter: any = this.getFilter(this.name, this.filterType);
    const value = isNumber(this.groupIndex)
      ? filter.value[this.groupIndex]
      : filter.value;

    return {
      name: this.name,
      value,
    };
  }

  private get schemaProperty() {
    const propertyName = camelize(this.name);

    return this.schemaProperties[propertyName];
  }

  public get preferredOptions() {
    return this.usePreferred ? this.schemaProperty['x-preferred'] : null;
  }

  get counters() {
    const counters = this.stateCounters[camelize(this.name)];

    if (counters instanceof Object) {
      const decamelizeCounters: any = {};

      for (let key in counters) {
        decamelizeCounters[camelizeService.decamelizeString(key)] =
          counters[key];
      }
      return decamelizeCounters;
    }

    return counters;
  }

  protected addDictionary(name: string, callback: Function) {
    const urlData = this.filter.value as HashMap<any>;
    const dictionaryUrl = dictionariesService.buildUrl(name, urlData);
    const dictionary = this.dictionaries[dictionaryUrl];

    if (!dictionary) {
      dictionariesService.getDictionary(name, urlData, (data: Dictionary) => {
        this.setDictionary({
          rootName: dictionariesMap[name].rootName,
          name: dictionaryUrl,
          dictionary: data,
        });

        callback(data);
      });
    } else {
      setTimeout(() => {
        callback(dictionary);
      }, 0);
    }
  }

  private update(value: any, forceUpdate: boolean) {
    this.setFilter({
      name: this.name,
      value,
      groupIndex: this.groupIndex,
    });

    const shouldUpdate = forceUpdate || !this.isMobileSpecial;
    const action = shouldUpdate ? this.updateList : this.updateCounters;

    action({
      callbacks: {
        success: () => {
          this.globalEmit && this.$root.$emit(this.globalEmit);
          this.$root.$emit('filterChanged');
        },
      },
    });
  }
}
