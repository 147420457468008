import { IEquipmentItem, IEquipmentGroup } from '@/interfaces/adverts';
import { HashMap } from '@/types/common';
import camelize from '@/filters/format-camelize';

export default (data: any) => {
  const seller = {
    ...data.seller,
    phones: data.seller.phones?.length ? data.seller.phones : [''],
  };
  const specs = data.specs;

  if (!specs.odometer.value) {
    specs.odometer = {};
  }

  if (!specs.engine.power) {
    specs.engine.power = {
      unit: 'horse',
    };
  }

  let equipment: HashMap<any> = {};

  data.equipment.forEach((group: IEquipmentGroup) => {
    group.items.forEach((item: IEquipmentItem) => {
      equipment[camelize(item.id)] = item.value;
    });
  });

  const newData = {
    description: data.description,
    manufacturerId: data.manufacturer.id,
    modelId: data.model.id,
    generationId: data.generation ? data.generation.id : null,
    specs,
    equipment,
    cityId: data.location.city.id,
    country: data.location.country.id,
    region: data.location.region.id,
    seller,
    price: {
      amount: data.price.amount.split('.').shift(),
      currency: data.price.currency,
    },
    dealTerms: data.dealTerms,
    images: data.images,
  };

  return newData;
};
