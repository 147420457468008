import { HashMap } from '@/types/common';

export const SINCE_YEAR = 1890;

export const URL_REGEXP = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

export const searchResultsTitle: HashMap<{
  found: string[];
  items: string[];
}> = {
  adverts: {
    found: ['Найдено', 'Найдено', 'Найдено'],
    items: ['обьявление', 'обьявления', 'обьявлений'],
  },
  reviews: {
    found: ['Найден', 'Найдено', 'Найдено'],
    items: ['отзыв', 'отзыва', 'отзывов'],
  },
};
