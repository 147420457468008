var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__tag-item", on: { click: _vm.onRemoveClick } },
    [
      _c(
        "div",
        {
          staticClass:
            "button-style button-style_either button-style_small vehicle-form__button vehicle-form__button_tag",
        },
        [_vm._v("\n    " + _vm._s(_vm.tag.title) + "\n  ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }