
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import SecondaryParameterDiagram from '@/components/common/rating-reviews/secondary-parameter-diagram.vue';

  @Component({
    components: {
      SecondaryParameterDiagram,
    },
  })
  export default class SecondaryParameter extends Vue {
    @Prop() label!: string;
    @Prop() positiveValue!: number;
    @Prop() negativeValue!: number;
  }
