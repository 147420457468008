var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "handle",
      staticClass: "popover-style__handle vehicle-form__popover-handle",
      class: { "popover-style__handle_opened": _vm.isVisible },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.toggleDropdown.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n    Где искать VIN-номер\n  ")]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "popover-style popover-style_primary popover-style_base popover-style_bottom-left vehicle-form__popover vehicle-form__popover_width_lm",
      },
      [
        _c("div", { staticClass: "popover-style__content" }, [
          _c("div", { staticClass: "vehicle-form" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__mediabox vehicle-form__mediabox_vin",
              },
              [
                _c("div", { staticClass: "vehicle-form__mediabox-flex" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__mediabox-part vehicle-form__mediabox-part_image",
                    },
                    [
                      _c("div", { staticClass: "vehicle-form__preview" }, [
                        _c("div", {
                          staticClass:
                            "vehicle-form__image vehicle-form__image_vin",
                        }),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__mediabox-part vehicle-form__mediabox-part_content",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base",
                        },
                        [
                          _vm._v(
                            "\n                VIN-номер вы можете найти в техническом паспорте"
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                автомобиля\n              "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }