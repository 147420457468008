import camelizeService from '@/services/camelize-service';
import {
  IApiService,
  IApiServiceConfig,
  IApiServiceResponse,
  IApiServiceError,
} from '@/interfaces/api';

export default (apiService: IApiService) => {
  const instance = apiService.instance;

  instance.interceptors.request.use((config: IApiServiceConfig) => {
    if (config.data) {
      config.data = camelizeService.decamelize(config.data);
    }

    if (config.params) {
      config.params = camelizeService.decamelize(config.params);
    }

    return config;
  });

  instance.interceptors.response.use(
    (response: IApiServiceResponse) => {
      if (response.data) {
        response.data = camelizeService.camelize(response.data);
      }

      return response;
    },
    (error: IApiServiceError) => {
      if (error.response && error.response.data) {
        error.response.data = camelizeService.camelize(error.response.data);
      }

      return Promise.reject(error);
    }
  );
};
