var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "vehicle-form__card-part vehicle-form__card-part_action",
      class: {
        "vehicle-form__card-part_action_specific":
          _vm.isOwnAdvert &&
          !_vm.advert.closedAt &&
          (_vm.advert.permissions.up || _vm.advert.upAvailableIn),
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "input-style__combo input-style__combo_stretch vehicle-form__input-combo vehicle-form__input-combo_width_full",
        },
        [
          _vm.isBookmarksVisible
            ? _c("advert-action-bookmark", { attrs: { advert: _vm.advert } })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isOwnAdvert ? _c("advert-action-complain") : _vm._e(),
          _vm._v(" "),
          !_vm.isMobileSpecial ? _c("advert-action-share") : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }