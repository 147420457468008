var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__phone-item" }, [
    _c(
      "div",
      {
        staticClass:
          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-appendant",
      },
      [
        _c(
          "a",
          {
            directives: [
              {
                name: "gtm",
                rawName: "v-gtm",
                value: { event: "call" },
                expression: "{event: 'call'}",
              },
            ],
            staticClass:
              "vehicle-form__link vehicle-form__link_primary-alter vehicle-form__link_base-alter vehicle-form__link_font-weight_semibold",
            attrs: { href: `tel:${_vm.phoneNumber}` },
          },
          [_vm._v(_vm._s(_vm._f("phone")(_vm.phoneNumber)))]
        ),
      ]
    ),
    _vm._v(" "),
    _vm.call.from === _vm.call.to
      ? _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny",
          },
          [_vm._v("Звонить круглосуточно")]
        )
      : _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny",
          },
          [
            _vm._v(
              "с " +
                _vm._s(_vm._f("time")(this.call.from)) +
                " до " +
                _vm._s(_vm._f("time")(this.call.to))
            ),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }