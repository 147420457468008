var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "project-navigation project-navigation_overflow project-navigation_nocap project-navigation_scroll",
          attrs: { id: "navigation" },
        },
        [
          _c("div", { staticClass: "project-navigation__flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "project-navigation__part project-navigation__part_1",
              },
              [
                _c(
                  "ul",
                  {
                    staticClass:
                      "project-navigation__list project-navigation__list_primary",
                  },
                  [
                    _c(
                      "li",
                      {
                        staticClass:
                          "project-navigation__item project-navigation__item_primary project-navigation__item_active",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "project-navigation__link project-navigation__link_primary",
                            attrs: { href: _vm.abUrl },
                          },
                          [_vm._m(0)]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass:
                          "project-navigation__item project-navigation__item_primary",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "project-navigation__link project-navigation__link_primary",
                            attrs: { href: _vm.mbUrl },
                          },
                          [_vm._m(1)]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass:
                          "project-navigation__item project-navigation__item_secondary",
                        class: {
                          "project-navigation__item_active": _vm.isReviews,
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "project-navigation__link project-navigation__link_primary",
                            attrs: { href: _vm.reviewsUrl },
                          },
                          [_vm._m(2)]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.userId
                      ? [
                          _c(
                            "li",
                            {
                              staticClass:
                                "project-navigation__item project-navigation__item_secondary",
                              class: {
                                "project-navigation__item_active": _vm.isMyAuto,
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "project-navigation__link project-navigation__link_primary",
                                  attrs: {
                                    to: {
                                      name: "active-adverts",
                                      params: { id: _vm.userId },
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "project-navigation__text" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "project-navigation__sign",
                                        },
                                        [_vm._v("Мои авто")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              staticClass:
                                "project-navigation__item project-navigation__item_secondary",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "project-navigation__link project-navigation__link_primary",
                                  attrs: { href: _vm.bookmarksUrl },
                                },
                                [_vm._m(3)]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              staticClass:
                                "project-navigation__item project-navigation__item_secondary",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "project-navigation__link project-navigation__link_primary",
                                  on: { click: _vm.onSavedClick },
                                },
                                [_vm._m(4)]
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "project-navigation__part project-navigation__part_2",
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "gtm",
                        rawName: "v-gtm",
                        value: { event: "create_ad" },
                        expression: "{ event: 'create_ad' }",
                      },
                    ],
                    staticClass: "project-navigation__button",
                    on: { click: _vm.createAdvert },
                  },
                  [_vm._v("\n          Разместить объявление\n        ")]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.isCreateVisible
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "device-class",
                      rawName: "v-device-class",
                      value: [
                        {
                          device: "iphonex",
                          deviceClass: "project-navigation__bar_alter",
                        },
                        {
                          device: "iphonexsmax",
                          deviceClass: "project-navigation__bar_alter",
                        },
                        {
                          device: "iphonexr",
                          deviceClass: "project-navigation__bar_alter",
                        },
                      ],
                      expression:
                        "[\n        { device: 'iphonex', deviceClass: 'project-navigation__bar_alter' },\n        {\n          device: 'iphonexsmax',\n          deviceClass: 'project-navigation__bar_alter',\n        },\n        { device: 'iphonexr', deviceClass: 'project-navigation__bar_alter' },\n      ]",
                    },
                  ],
                  staticClass: "project-navigation__bar",
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "gtm",
                          rawName: "v-gtm",
                          value: { event: "create_ad" },
                          expression: "{ event: 'create_ad' }",
                        },
                      ],
                      staticClass:
                        "project-navigation__button project-navigation__button_middle",
                      on: { click: _vm.createAdvert },
                    },
                    [_vm._v("\n        Разместить объявление\n      ")]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("saved-searches", { attrs: { "is-ready": _vm.isSearchesReady } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "project-navigation__text" }, [
      _c("span", { staticClass: "project-navigation__sign" }, [_vm._v("Авто")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "project-navigation__text" }, [
      _c("span", { staticClass: "project-navigation__sign" }, [_vm._v("Мото")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "project-navigation__text" }, [
      _c("span", { staticClass: "project-navigation__sign" }, [
        _vm._v("Отзывы об авто"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "project-navigation__text" }, [
      _c("span", { staticClass: "project-navigation__sign" }, [
        _vm._v("Закладки"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "project-navigation__text" }, [
      _c("span", { staticClass: "project-navigation__sign" }, [
        _vm._v("Сохраненные поиски"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }