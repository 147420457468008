import { Mixins } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';

import IsMobileSpecial from '@/mixins/common/is-mobile';

@Mixin
export default class CommentsScrollMixin extends Mixins<IsMobileSpecial>(
  IsMobileSpecial,
) {
  private SCROLL_OFFSET?: number;

  protected created() {
    this.SCROLL_OFFSET = this.isMobileSpecial ? 70 : 30;
  }

  protected correctOffset() {
    const offset = $(window).scrollTop()! - this.SCROLL_OFFSET!;

    $(window).scrollTop(offset);
  }
}
