
import { Vue, Component, Prop } from 'vue-property-decorator';

import { ReviewStatusHistoryItem } from '@/types/review';
import moment from 'moment';
import { findWhere } from 'underscore';
import { status } from '@/dictionaries/reviews';

@Component
export default class ReviewStatusHistoryEntry extends Vue {
  @Prop() entry!: ReviewStatusHistoryItem;

  private get time() {
    return moment(this.entry.createdAt).format('DD.MM.YYYY, в HH:mm');
  }

  private get status() {
    return findWhere(status, { id: this.entry.status })!.name;
  }
}
