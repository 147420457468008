
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as moment from 'moment';

import Popup from '@/components/common/popup.vue';
import Chart from '@/components/common/bar-chart.vue';
import { IStats, IAdvert } from '@/interfaces/adverts';
import { VuexAction } from '@/types/functions';
import plural from '@/filters/plural-form';

const advertStore = namespace('advert');

@Component({
  components: {
    Popup,
    Chart,
  },
  filters: {
    plural,
  },
})
export default class AdvertStats extends Vue {
  private selectedPeriod: number = 7;
  private isChartPresent: boolean = false;

  @advertStore.State stats!: IStats;
  @advertStore.State advert!: IAdvert;
  @advertStore.Action updateStats!: VuexAction;

  private get periods() {
    return [
      {
        label: 'за неделю',
        count: 7,
      },
      {
        label: 'за месяц',
        count: 30,
      },
    ];
  }

  private get labels() {
    return Object.keys(this.stats).map(item => {
      return moment(item).format('DD');
    });
  }

  private get chartData() {
    if (!this.stats) {
      return {};
    }

    const data = Object.values(this.stats).slice(-this.selectedPeriod);

    return {
      labels: this.labels.slice(-this.selectedPeriod),
      datasets: [
        {
          barPercentage: 1,
          categoryPercentage: 0.9,
          label: 'Количество просмотров',
          backgroundColor: '#70B8FF',
          hoverBackgroundColor: '#2979FF',
          data,
        },
      ],
    };
  }

  private get options() {
    return {
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
              tickMarkLength: 0,
            },
            ticks: {
              padding: 10,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              drawOnChartArea: true,
              tickMarkLength: 0,
            },
            ticks: {
              min: 0,
              padding: 10,
              precision: 0,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      tooltips: {
        displayColors: false,
        callbacks: {
          title: function() {
            return;
          },
        },
      },
    };
  }

  private get promotionLink() {
    return {};
  }

  private setPeriod(count: number) {
    this.selectedPeriod = count;
  }

  private openStatsPopup() {
    this.updateStats({
      id: this.advert.id,
      success: (data: IStats) => {
        (this.$refs.statsPopup as Popup).openPopup();

        this.$gtm.trackEvent({
          event: 'popup-view',
          'popup-view-name': 'ad_stats',
        });
      },
    });

    this.isChartPresent = true;
  }

  private closeStatsPopup() {
    (this.$refs.statsPopup as Popup).closePopup();

    this.isChartPresent = false;
  }

  private onPromoteClick() {
    this.closeStatsPopup();

    this.$nextTick(() => {
      this.$router.push(`/promote?entity=${this.advert.id}`);
    });
  }
}
