var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "popup",
    {
      ref: "contactsPopup",
      attrs: {
        classes: [
          "popup-style_primary",
          " popup-style_middle",
          "vehicle-popup",
          "vehicle-popup_width_lm",
        ],
      },
    },
    [
      _c("div", { staticClass: "vehicle-form" }, [
        _c("div", { staticClass: "vehicle-form__phone" }, [
          _c(
            "div",
            { staticClass: "vehicle-form__phone-list" },
            _vm._l(_vm.seller.phones, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "vehicle-form__phone-item vehicle-form__phone-item_extended",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_condensed-additional",
                    },
                    [
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "gtm",
                              rawName: "v-gtm",
                              value: { event: "call" },
                              expression: "{ event: 'call' }",
                            },
                          ],
                          staticClass:
                            "vehicle-form__link vehicle-form__link_primary-alter",
                          attrs: { href: `tel:${item}` },
                        },
                        [_vm._v(_vm._s(_vm._f("phone")(item)))]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.seller.call.from === _vm.seller.call.to
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_other vehicle-form__description_base",
                        },
                        [
                          _vm._v(
                            "\n            Звонить круглосуточно\n          "
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_other vehicle-form__description_base",
                        },
                        [
                          _vm._v(
                            "\n            Звонить с " +
                              _vm._s(_vm._f("time")(_vm.seller.call.from)) +
                              " до\n            " +
                              _vm._s(_vm._f("time")(_vm.seller.call.to)) +
                              "\n          "
                          ),
                        ]
                      ),
                ]
              )
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "vehicle-form__divider vehicle-form__divider_condensed",
        }),
        _vm._v(" "),
        _c("div", { staticClass: "vehicle-form__person" }, [
          _c("div", { staticClass: "vehicle-form__person-list" }, [
            _c(
              "div",
              { staticClass: "vehicle-form__person-item" },
              [
                _c("div", { staticClass: "vehicle-form__preview" }, [
                  _c("div", {
                    staticClass: "vehicle-form__image",
                    style: _vm._f("background")(_vm.avatar),
                  }),
                  _vm._v(" "),
                  _vm.author.onlineStatus && _vm.author.onlineStatus.isOnline
                    ? _c("div", {
                        staticClass:
                          "vehicle-form__status vehicle-form__status_online",
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.seller.type === "individual"
                  ? [
                      _vm.permissions.viewProfile
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                              attrs: {
                                target: "_blank",
                                href: _vm.profileLink,
                              },
                            },
                            [_vm._v(_vm._s(_vm.seller.name))]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.seller.name) +
                                  "\n            "
                              ),
                            ]
                          ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.seller.type === "dealer"
                  ? [
                      _vm.permissions.viewProfile
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                              attrs: {
                                target: "_blank",
                                href: _vm.profileLink,
                              },
                            },
                            [
                              _vm._v(
                                "Автодилер «" + _vm._s(_vm.seller.name) + "»"
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                            },
                            [
                              _vm._v(
                                "\n              Автодилер «" +
                                  _vm._s(_vm.seller.name) +
                                  "»\n            "
                              ),
                            ]
                          ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.seller.type === "autohaus"
                  ? [
                      _vm.permissions.viewProfile
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                              attrs: {
                                target: "_blank",
                                href: _vm.profileLink,
                              },
                            },
                            [
                              _vm._v(
                                "Автохаус «" + _vm._s(_vm.seller.name) + "»"
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                            },
                            [
                              _vm._v(
                                "\n              Автохаус «" +
                                  _vm._s(_vm.seller.name) +
                                  "»\n            "
                              ),
                            ]
                          ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.seller.type === "individual"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny vehicle-form__description_condensed-other",
                      },
                      [_vm._v("\n            Частное лицо\n          ")]
                    )
                  : _vm.seller.type === "dealer" ||
                    _vm.seller.type === "autohaus"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny vehicle-form__description_condensed-other",
                      },
                      [
                        _vm._v(
                          "\n            УНП: " +
                            _vm._s(_vm.seller.unp) +
                            "\n          "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _vm.seller.type === "dealer" || _vm.seller.type === "autohaus"
              ? _c("div", { staticClass: "vehicle-form__person-item" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny vehicle-form__description_condensed-appendant",
                    },
                    [_vm._v("\n            Контактное лицо\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_condensed-either",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.seller.contactName) +
                          "\n          "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__person-item" }, [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_condensed-either vehicle-form__description_compass-additional",
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm._f("location")(_vm.location)) +
                      "\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__person-item" }, [
              _c(
                "div",
                {
                  staticClass:
                    "tooltip-style tooltip-style_secondary tooltip-style_small tooltip-style_bottom-left tooltip-style_text_left tooltip-style_noarrow tooltip-style_visible vehicle-form__tooltip vehicle-form__tooltip_width_auto",
                },
                [
                  _c("div", { staticClass: "tooltip-style__content" }, [
                    _c("div", { staticClass: "vehicle-form" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_info",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_font-weight_semibold vehicle-form__description_condensed",
                            },
                            [
                              _vm._v(
                                "\n                    Советы по безопасности\n                  "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                            },
                            [
                              _vm._v(
                                "\n                    Не вносите предоплату дистанционно без предварительного просмотра\n                  "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }