var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticStyle: {
        width: "100%",
        "margin-top": "10px",
        "margin-bottom": "10px",
      },
    },
    [_c("div", { attrs: { id: _vm.bannerId } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }