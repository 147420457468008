
  import { Component, Prop } from 'vue-property-decorator';
  import { Mixins } from 'vue-mixin-decorator';
  import background from '@/filters/format-background';
  import number from '@/filters/format-number';
  import StepHeader from '@/mixins/reviews/create/step-header';
  import { Odometer, Photo } from '@/types/common';
  import { Quality, Video } from '@/types/review';
  import { odometerTypes } from '@/config/dictionaries';

  @Component({
    filters: {
      background,
      number,
    },
  })
  export default class StepDescriptionHeader extends Mixins<StepHeader>(
    StepHeader,
  ) {
    protected step: string = 'description';

    @Prop() images!: Array<Photo>;
    @Prop() tenure!: string;
    @Prop() odometer!: Odometer;
    @Prop() videos!: Array<Video> | undefined;
    @Prop() summary!: string;
    @Prop() ratingAverage!: Number;
    @Prop() pros!: Array<Quality> | null;
    @Prop() cons!: Array<Quality> | null;

    private get videoLinks() {
      return this.videos && this.videos.map(video => video.url).join(', ');
    }

    private get formattedPros(): Array<string> {
      return (this.pros && this.pros.map(plus => plus.title).join(', ')) || [];
    }

    private get formattedCons(): Array<string> {
      return (
        (this.cons && this.cons.map(minus => minus.title).join(', ')) || []
      );
    }

    private get odometerTypes() {
      return odometerTypes;
    }

    private getImageUrl(images) {
      return (
        images.preview || // base64 from user
        images['172x132'] || // For edit review with new upload.api
        images['380x240'] || // For data from AB with old upload.api
        images.original // fallback
      );
    }
  }
