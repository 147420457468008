export default (value: string, fixed: number = 2) => {
  const floatValue = parseFloat(value.toString().replace(',', '.'));

  if (isNaN(floatValue)) {
    return NaN;
  }

  const [decimal, float] = floatValue.toString().split('.');

  return float ? [decimal, float].join('.') : `${decimal}.${'0'.repeat(fixed)}`;
};
