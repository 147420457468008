
  import { Vue, Component, Prop } from 'vue-property-decorator';

  import { Review } from '@/types/review';
  import RatingMark from '@/components/reviews/common/rating-mark.vue';
  import RatingQuality from '@/components/reviews/common/rating-quality.vue';
  import RatingStars from '@/components/common/rating-reviews/rating-stars.vue';

  @Component({
    components: {
      RatingStars,
      RatingMark,
      RatingQuality,
    },
  })
  export default class ReviewRating extends Vue {
    @Prop() review!: Review;

    private get marks() {
      const marks: {
        key: string;
        dictionaryKey: string;
        value: number;
      }[] = [];

      for (let key of Object.keys(this.review.rating)) {
        marks.push({
          key,
          dictionaryKey: key,
          value: this.review.rating[key],
        });
      }

      return marks;
    }
  }
