var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "popup",
    {
      ref: "leasingPopup",
      attrs: {
        classes: [
          "popup-style_primary",
          " popup-style_middle",
          "vehicle-popup",
          "vehicle-popup_width_xxls",
        ],
      },
    },
    [
      _c(
        "div",
        { staticClass: "vehicle-form" },
        [
          _c("advert-leasing-form", {
            attrs: { "is-specific": true, leasing: _vm.leasing },
            on: { success: _vm.onSuccess },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }