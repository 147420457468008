var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__step-list" }, [
    _c(
      "div",
      { staticClass: "vehicle-form__step-list" },
      [
        _c(
          "step-wrapper",
          { attrs: { step: "tech" } },
          [
            !_vm.isMobileSpecial
              ? _c("step-tech-header")
              : _c("step-tech-header-mobile"),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "step-wrapper",
          { attrs: { step: "exterior" } },
          [_c("step-exterior-header")],
          1
        ),
        _vm._v(" "),
        _c(
          "step-wrapper",
          { attrs: { step: "cost" } },
          [_c("step-cost-header")],
          1
        ),
        _vm._v(" "),
        _c(
          "step-wrapper",
          { ref: "contacts", attrs: { step: "contacts" } },
          [
            _c("step-contacts-header"),
            _vm._v(" "),
            _c("step-contacts-location"),
            _vm._v(" "),
            !_vm.isMobileSpecial
              ? _c("step-contacts-seller-type")
              : _c("step-contacts-seller-type-mobile"),
            _vm._v(" "),
            _vm.seller.type
              ? _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__row vehicle-form__row_extended-other",
                  },
                  [
                    _c("step-contacts-seller-name"),
                    _vm._v(" "),
                    ["autohaus", "dealer"].indexOf(_vm.seller.type) !== -1
                      ? [
                          _c("step-contacts-seller-unp"),
                          _vm._v(" "),
                          _c("step-contacts-seller-contact-name"),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("step-contacts-seller-chat-preferred"),
            _vm._v(" "),
            _c("step-contacts-seller-phones"),
            _vm._v(" "),
            _c("step-contacts-seller-call"),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__control vehicle-form__control_condensed-specific",
              },
              [
                _vm.isEdit
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "button-style button-style_primary button-style_middle vehicle-form__button vehicle-form__button_width_sssm",
                        on: { click: _vm.onFinishClick },
                      },
                      [_vm._v("Подтвердить")]
                    )
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("create-finish"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }