export default (value: string | number) => {
  if (value === '.' || value === ',') {
    return '0.';
  }

  const floatValue = parseFloat(value.toString().replace(',', '.'));

  if (isNaN(floatValue)) {
    return '';
  }

  const [decimal, float] = floatValue
    .toString()
    .replace(',', '.')
    .split('.');
  const hasDot = value.toString().indexOf('.') !== -1;

  return decimal + (hasDot ? '.' : '') + (float ? float : '');
};
