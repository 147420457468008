var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__nav" }, [
    _c("div", { staticClass: "vehicle-form__nav-list" }, [
      _c("div", { staticClass: "vehicle-form__nav-item" }, [
        _c(
          "a",
          {
            staticClass:
              "vehicle-form__link vehicle-form__link_other vehicle-form__link_base",
            attrs: { href: _vm.allCarsUrl },
          },
          [_vm._v("Отзывы об автомобилях")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "vehicle-form__nav-item" }, [
        _c(
          "a",
          {
            staticClass:
              "vehicle-form__link vehicle-form__link_other vehicle-form__link_base",
            attrs: { href: _vm.manufacturerUrl },
          },
          [_vm._v(_vm._s(_vm.manufacturer.name))]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "vehicle-form__nav-item" }, [
        _c(
          "a",
          {
            staticClass:
              "vehicle-form__link vehicle-form__link_other vehicle-form__link_base",
            attrs: { href: _vm.modelUrl },
          },
          [_vm._v(_vm._s(_vm.model.name))]
        ),
      ]),
      _vm._v(" "),
      _vm.generation.name
        ? _c("div", { staticClass: "vehicle-form__nav-item" }, [
            _c(
              "a",
              {
                staticClass:
                  "vehicle-form__link vehicle-form__link_other vehicle-form__link_base",
                attrs: { href: _vm.generationUrl },
              },
              [_vm._v(_vm._s(_vm.generation.name))]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }