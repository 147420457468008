import formatPrice from '@/filters/format-price';

export default (data: any) => {
  const amount = formatPrice(data.price.amount);
  const price = { ...data.price, amount };
  const odometer = { value: 0, unit: 'km', ...data.specs.odometer };
  const year = parseInt(data.specs.year);
  const specs = { ...data.specs, odometer, year };

  if (!specs.engine.power || !specs.engine.power.value) {
    specs.engine.power = null;
  }

  specs.engine.torque = specs.engine.torque || null;

  const images = data.images.filter((item: any) => item);
  const phones = (data.seller.phones || []).filter((item: string) => !!item);
  const seller = { ...data.seller, phones };

  return { ...data, images, price, specs, seller };
};
