import { Mixin, Mixins } from "vue-mixin-decorator";
import { Prop } from "vue-property-decorator";
import StepHeader from "@/mixins/reviews/create/step-header";
import capitalize from "@/filters/format-capitalize";

@Mixin({
  filters: {
    capitalize
  },
})
export default class StepTechHeader extends Mixins<StepHeader>(StepHeader) {
  protected step: string = 'tech';

  @Prop() manufacturerId!: number;
  @Prop() manufacturerName!: string;
  @Prop() modelId!: number;
  @Prop() modelName!: string;
  @Prop() year!: string;
  @Prop() generationName!: string;
  @Prop() bodyType!: string;
  @Prop() engineType!: string;
  @Prop() engineCapacity!: string;
  @Prop() drivetrain!: string;
  @Prop() transmission!: string;
}
