
  import { Component, Vue } from 'vue-property-decorator';
  import { HashMap } from '@onlinerby/js-common';
  import { namespace } from 'vuex-class';
  import { isEmpty } from 'underscore';

  import { VuexAction } from '@/types/functions';
  import { Review } from '@/types/review';
  import vmApi from '@/api/vm-api';
  import { stepStatuses } from '@/config/reviews/create';
  import EditStub from '@/components/create/step-edit/edit-stub.vue';
  import dictionariesService, {
    Dictionaries,
  } from '@/services/dictionaries-service';
  import editTransformer from '@/transformers/reviews-edit-transformer';
  import dictionariesMap from '@/config/dictionaries-map';
  import { socialIcon } from '@/config/urls';

  const commonStore = namespace('common');
  const createStore = namespace('reviews/create');

  @Component({
    components: { EditStub },
    metaInfo(): any {
      return {
        title: 'Редактирование отзыва - Автобарахолка Onlíner',
        meta: [
          {
            name: 'description',
            content: 'Редактирование отзыва - Автобарахолка Onlíner',
          },
          {
            name: 'og:image',
            content: socialIcon,
          },
        ],
      };
    },
  })
  export default class Edit extends Vue {
    private updateCount: number = 0;
    private isLoading: boolean = true;
    private beforeEach!: Function;

    @commonStore.State dictionaries!: Dictionaries;
    @createStore.State statusesByStep!: HashMap<string>;

    @commonStore.Action setDictionary!: VuexAction;
    @createStore.Action setMode!: VuexAction;
    @createStore.Action setStatusesByStep!: VuexAction;
    @createStore.Action setField!: VuexAction;

    private get isLoadingAllData() {
      return this.isLoading || isEmpty(this.dictionaries);
    }

    private created() {
      this.checkStep();

      this.setMode({ mode: 'edit' });

      this.setStatusesByStep({
        tech: 'complete',
        description: 'complete',
      });

      this.beforeEach = this.$router.beforeEach((to, from, next) => {
        if (
          to.meta.stepName &&
          this.statusesByStep[to.meta.stepName] !== stepStatuses.edit
        ) {
          this.setStatusesByStep({ [to.meta.stepName]: stepStatuses.current });
        }

        next();
      });

      this.updateCount++;

      vmApi.getReview(this.$route.params.id, {
        success: ({ data }: { data: Review }) => {
          if (!data.permissions.edit) {
            this.$router.push({
              name: 'review',
              params: {
                manufacturer: data.manufacturer.slug,
                model: data.model.slug,
                id: data.id,
              },
            });
          }

          this.initDictionaries({
            manufacturer: data.manufacturer.id,
            model: data.model.id,
          });
          this.setField(editTransformer(data));

          this.updateCount--;
          this.checkIsLoading();
        },
      });
    }

    private destroyed() {
      this.beforeEach();
    }

    private checkStep() {
      if (this.$route.name !== 'edit-review-init') {
        this.$router.replace({ name: 'edit-review-init' });
      }
    }

    private initDictionaries({ manufacturer, model }) {
      this.updateDictionary('manufacturer', {
        manufacturer,
      });
      this.updateDictionary('model', {
        manufacturer,
        model,
      });
    }

    private updateDictionary(name: string, data: HashMap<number>) {
      const dictionaryUrl = dictionariesService.buildUrl(name, data);

      this.updateCount++;

      dictionariesService.getDictionary(name, data, (data: any) => {
        this.setDictionary({
          rootName: dictionariesMap[name].rootName,
          name: dictionaryUrl,
          dictionary: data,
        });

        this.updateCount--;

        this.checkIsLoading();
      });
    }

    private checkIsLoading() {
      if (!this.updateCount) {
        this.isLoading = false;
      }
    }
  }
