import { ISearchState } from '@/interfaces/store';

const state: ISearchState = {
  isLoading: true,
  advertsList: [],
  advertsListPage: {
    current: 1,
    last: 1,
    limit: 50,
    items: 0,
  },
  filters: [],
  counters: {},
  generalCounters: {},
  searches: null,
  notificationsSettings: null,
  total: 0,
};

export default state;
