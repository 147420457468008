import { ISearchState } from '@/interfaces/reviews/store';

const state: ISearchState = {
  isLoading: true,
  reviews: [],
  reviewListPage: {
    current: 1,
    last: 1,
    limit: 50,
    items: 0,
  },
  total: 0,
  filters: [],
  counters: {},
  generalCounters: {},
};

export default state;
