
import { Prop } from 'vue-property-decorator';
import { Mixins, Component } from 'vue-mixin-decorator';
import DropdownMixin from '@/mixins/common/dropdown';
import IsMobileMixin from '@/mixins/common/is-mobile';

interface IMixins extends DropdownMixin, IsMobileMixin {}

@Component
export default class SearchAdvertsListPremium extends Mixins<IMixins>(
  DropdownMixin,
  IsMobileMixin
) {
  @Prop() type!: string;

  private onClick() {
    this.isMobile && this.toggleDropdown();
  }
}
