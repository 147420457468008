var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__tabs-item vehicle-form__tabs-item_active" },
    [
      _c("router-view", {
        key: `header_${_vm.$route.fullPath}`,
        attrs: { name: "header" },
      }),
      _vm._v(" "),
      _c("router-view", {
        key: `list_${_vm.$route.fullPath}`,
        attrs: { name: "list" },
        on: {
          "counters-update": _vm.onCountersUpdate,
          "user-update": _vm.onUserUpdate,
        },
      }),
      _vm._v(" "),
      !_vm.isLoading ? _c("adverts-list-pagination") : _vm._e(),
      _vm._v(" "),
      _c("commercial"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }