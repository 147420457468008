import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import { VuexAction } from '@/types/functions';
import { HashMap } from '@/types/common';

const createStore = namespace('create');

@Mixin
export default class StepHeader extends Vue {
  protected step!: string;

  @createStore.State steps!: HashMap<any>;
  @createStore.State mode!: string;
  @createStore.Action setStep!: VuexAction;

  protected get isComplete() {
    return this.steps[this.step] === 'complete';
  }

  protected get isCurrent() {
    return this.steps[this.step] === 'current';
  }

  protected get isEdit() {
    return this.steps[this.step] === 'edit';
  }

  protected get isEditMode() {
    for (let key of Object.keys(this.steps)) {
      if (this.steps[key] === 'edit') {
        return true;
      }
    }

    return false;
  }

  protected editStep(step: string) {
    this.setStep({ [step]: 'edit' });

    this.$router.replace({ name: `${this.mode}-${step}` });
  }
}
