var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__row" }, [
    _c(
      "div",
      { staticClass: "vehicle-form__group vehicle-form__group_width_full" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "vehicle-form__field" }, [
          _c(
            "ul",
            {
              staticClass:
                "vehicle-form__checkbox-list vehicle-form__checkbox-list_color",
            },
            _vm._l(_vm.options, function (option) {
              return _c(
                "li",
                {
                  key: option.id,
                  staticClass:
                    "vehicle-form__checkbox-item vehicle-form__checkbox-item_color",
                  class: [`vehicle-form__checkbox-item_color_${option.id}`],
                  attrs: { title: _vm._f("capitalize")(option.name) },
                },
                [
                  _c("label", { staticClass: "vehicle-form__checkbox-label" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "i-checkbox vehicle-form__checkbox vehicle-form__checkbox_base",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.checkboxes[option.id],
                              expression: "checkboxes[option.id]",
                            },
                          ],
                          staticClass: "i-checkbox__real",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.checkboxes[option.id])
                              ? _vm._i(_vm.checkboxes[option.id], null) > -1
                              : _vm.checkboxes[option.id],
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$a = _vm.checkboxes[option.id],
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.checkboxes,
                                        option.id,
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.checkboxes,
                                        option.id,
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.checkboxes, option.id, $$c)
                                }
                              },
                              _vm.updateFilter,
                            ],
                          },
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "vehicle-form__checkbox-text",
                        }),
                      ]
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("Цвет"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }