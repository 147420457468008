
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { equipmentMap } from '@/config/equipment';
import StepExteriorEquipmentSection from '@/components/create/step-exterior/step-exterior-equipment-section.vue';

@Component({
  components: {
    StepExteriorEquipmentSection,
  },
})
export default class StepExteriorEquipment extends Vue {
  private equipmentMap = equipmentMap;
}
