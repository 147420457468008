import { HashMap } from '@onlinerby/js-common';
import { Mixin, Mixins } from 'vue-mixin-decorator';
import { findWhere } from 'underscore';
import { namespace } from 'vuex-class';

import { VuexAction } from '@/types/functions';
import dictionariesService, {
  Dictionaries,
  Dictionary,
} from '@/services/dictionaries-service';
import stepsErrorMap from '@/config/reviews/steps-error-map';
import StorageMixin from '@/mixins/reviews/create/storage';

const commonStore = namespace('common');
const createStore = namespace('reviews/create');

@Mixin
export default class StepMutationData extends Mixins<StorageMixin>(
  StorageMixin,
) {
  @createStore.State data!: HashMap<any>;
  @createStore.State mode!: string;
  @createStore.Action setField!: VuexAction;
  @createStore.Action clearError!: VuexAction;

  @commonStore.State dictionaries!: Dictionaries;
  @commonStore.Action setDictionary!: VuexAction;

  private get manufacturerName() {
    const manufacturer = findWhere(this.dictionaries.manufacturer, {
      id: this.data.manufacturerId,
    });

    if (!manufacturer) {
      return;
    }

    return manufacturer.name;
  }

  private get modelName() {
    const model = findWhere(this.dictionaries.model, {
      id: this.data.modelId,
    });

    if (!model) {
      return;
    }

    return model.name;
  }

  private get generationName() {
    const { generationId } = this.data;

    const generation = findWhere(this.dictionaries.generation, {
      id: generationId,
    });

    if (!generation) {
      return '';
    }

    return generation.name;
  }

  private get engineType() {
    const engineType = findWhere(this.dictionaries.engineType, {
      id: this.data.specs.engine.type,
    });

    return engineType!.name;
  }

  private get bodyType() {
    const bodyType = findWhere(this.dictionaries.bodyType, {
      id: this.data.specs.bodyType,
    });

    return bodyType!.name;
  }

  private get drivetrain() {
    const drivetrain = findWhere(this.dictionaries.drivetrain, {
      id: this.data.specs.drivetrain,
    });

    return drivetrain!.name;
  }

  private get transmission() {
    const transmission = findWhere(this.dictionaries.transmission, {
      id: this.data.specs.transmission,
    });

    return transmission!.name;
  }

  private get tenure() {
    const tenure = findWhere(this.dictionaries.tenure, {
      id: this.data.tenure,
    });

    return tenure && tenure.name;
  }

  private get urlDataSelectedManufacturer() {
    return {
      manufacturer: this.data.manufacturerId,
    };
  }

  private get urlSelectedManufacturer() {
    return dictionariesService.buildUrl(
      'manufacturer',
      this.urlDataSelectedManufacturer,
    );
  }

  private get urlDataSelectedModel() {
    return {
      manufacturer: this.data.manufacturerId,
      model: this.data.modelId,
    };
  }

  private get urlSelectedModel() {
    return dictionariesService.buildUrl('model', this.urlDataSelectedModel);
  }

  protected onManufacturerChange(manufacturerId: number) {
    this.onResetModel();
    this.clearError(stepsErrorMap.tech.manufacturer);
    this.changeField('manufacturerId', manufacturerId);

    dictionariesService.getDictionary(
      'manufacturer',
      this.urlDataSelectedManufacturer,
      (data: Dictionary) => {
        this.setDictionary({
          rootName: 'model',
          name: this.urlSelectedManufacturer,
          dictionary: data,
        });
      },
    );
  }

  protected onModelChange(modelId: number) {
    this.onResetGeneration();
    this.clearError(stepsErrorMap.tech.model);
    this.changeField('modelId', modelId);

    dictionariesService.getDictionary(
      'model',
      this.urlDataSelectedModel,
      (data: Dictionary) => {
        this.setDictionary({
          rootName: 'generation',
          name: this.urlSelectedModel,
          dictionary: data,
        });
      },
    );
  }

  protected onYearChange(year: number) {
    const value = { ...this.data.specs, year };
    this.clearError(stepsErrorMap.tech.year);
    this.changeField('specs', value);
  }

  protected onGenerationChange(generationId: string) {
    this.clearError(stepsErrorMap.tech.generation);
    this.changeField('generationId', generationId);
  }

  protected onBodyTypeChange(bodyType: string) {
    const value = { ...this.data.specs, bodyType };

    this.clearError(stepsErrorMap.tech.bodyType);
    this.changeField('specs', value);
  }

  protected onEngineChange(engine) {
    const value = { ...this.data.specs, engine };

    this.changeField('specs', value);
  }

  protected onDrivetrainChange(drivetrain) {
    const value = { ...this.data.specs, drivetrain };

    this.clearError(stepsErrorMap.tech.drivetrain);
    this.changeField('specs', value);
  }

  protected onTransmissionChange(transmission) {
    const value = { ...this.data.specs, transmission };

    this.clearError(stepsErrorMap.tech.transmission);
    this.changeField('specs', value);
  }

  private changeField(field: string, value: any) {
    this.setField({ [field]: value });

    if (this.mode === 'create') {
      let { images, ...dataToSave } = this.data;

      this.setStorageValue('data', dataToSave);
    }
  }

  protected onResetManufacturer() {
    this.setField({
      manufacturerId: undefined,
      modelId: undefined,
      generationId: undefined,
    });
  }

  protected onResetModel() {
    this.setField({
      modelId: undefined,
      generationId: undefined,
    });
  }

  protected onResetGeneration() {
    this.setField({
      generationId: undefined,
    });
  }

  protected onOdometerChange(odometer) {
    const value = { ...this.data.specs, odometer };

    this.clearError(stepsErrorMap.description.odometer);
    this.clearError(stepsErrorMap.description.odometerValue);
    this.changeField('specs', value);
  }

  protected onPhotoChange(photo) {
    this.clearError(stepsErrorMap.description.images);
    this.changeField('images', photo);
  }

  protected onSecondParametersChange(parameters) {
    this.clearError(stepsErrorMap.description.secondaryMarks);
    this.changeField('secondaryMarks', parameters);
  }
}
