var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__panel-item" }, [
    _c(
      "div",
      {
        staticClass:
          "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed-default",
      },
      [_vm._v("Комментарий продавца")]
    ),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "dompurify-html",
          rawName: "v-dompurify-html:a",
          value: _vm.description,
          expression: "description",
          arg: "a",
        },
      ],
      staticClass:
        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_break vehicle-form__description_max-width_xxxxllll",
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }