var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__row" }, [
    _c("div", { staticClass: "vehicle-form__group" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "vehicle-form__field" }, [
        _c(
          "div",
          { staticClass: "vehicle-form__line vehicle-form__line_condensed" },
          [
            _c(
              "div",
              {
                staticClass: "input-style__wrapper vehicle-form__input-wrapper",
              },
              [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.videoLink,
                      expression: "videoLink",
                    },
                  ],
                  staticClass:
                    "input-style input-style_primary input-style_base vehicle-form__input vehicle-form__input_width_ll vehicle-form__input_min-height_xxxxsss",
                  class: { "input-style_error": _vm.error },
                  attrs: {
                    maxlength: "2000",
                    placeholder: "https://www.youtube.com/watch?v=X2WH8mHJnhM",
                  },
                  domProps: { value: _vm.videoLink },
                  on: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.preventDefault()
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.videoLink = $event.target.value
                    },
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.error
          ? _c(
              "div",
              {
                staticClass: "vehicle-form__line vehicle-form__line_condensed",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_width_full",
                  },
                  [_vm._v("\n          " + _vm._s(_vm.error) + "\n        ")]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("\n            Видео с YouTube\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__label-description vehicle-form__label-description_other",
                },
                [_vm._v("\n            (необязательно)\n          ")]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }