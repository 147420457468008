export const defaultData = {
  generationId: null,
  dealTerms: {
    customsClearance: true,
  },
  specs: {
    odometer: {
      unit: 'km',
    },
    engine: {
      power: {
        unit: 'horse',
      },
    },
  },
  price: {
    currency: 'USD',
  },
};
