import { Mixin, Mixins } from 'vue-mixin-decorator';

import { Dictionaries } from '@/services/dictionaries-service';
import FieldMixin from '@/mixins/reviews/create/field';
import { Prop } from 'vue-property-decorator';

@Mixin
export default class StepTechManufacturerMixin extends Mixins<FieldMixin>(
  FieldMixin,
) {
  protected search: string = '';

  @Prop() dictionaries!: Dictionaries;

  protected get filterString() {
    return this.search
      .trim()
      .toLowerCase()
      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  protected get manufacturer() {
    return this.data.manufacturerId;
  }

  protected set manufacturer(value) {
    this.onFieldChange(value);
  }
}
