
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Search } from '@/types/search';
import { findWhere, isArray, isObject } from 'underscore';
import { namespace } from 'vuex-class';

import { Dictionaries } from '@/services/dictionaries-service';
import translate from '@/filters/translate';
import capitalize from '@/filters/format-capitalize';
import { HashMap } from '@/types/common';
import { ranges, rangesAlt, measures, currencies } from '@/dictionaries/common';
import camelizeService from '@/services/camelize-service';

const commonStore = namespace('common');

@Component
export default class SavedSearchesSubitem extends Vue {
  @Prop() filterKey!: string;
  @Prop() filter!: any;

  @commonStore.State dictionaries!: Dictionaries;

  private get label() {
    return translate(this.filterKey);
  }

  private get value() {
    if (this.filter === true) {
      return 'Да';
    }

    if (isArray(this.filter)) {
      return this.parseArray(this.filter);
    }

    if (isObject(this.filter)) {
      const { from, to, unit } = this.filter;

      if (from || to) {
        return this.parseRange(this.filter, this.filterKey);
      }

      return this.parseObject(this.filter);
    }

    return '';
  }

  private parseArray(filter: Array<string>) {
    const parts: Array<string> = [];

    filter.forEach((id: string) => {
      const dictionary = this.dictionaries[this.filterKey];
      const item = findWhere(dictionary, { id });

      item && parts.push(capitalize(item.name));
    });

    return parts.join(', ');
  }

  private parseRange(filter: HashMap<string>, key: string) {
    const { from, to, currency } = filter;
    const parts: Array<string> = [];
    const measure = currency
      ? currencies[currency.toUpperCase()]
      : camelizeService.camelize(measures)[key];
    const rangesDictionary = key === 'year' ? rangesAlt : ranges;

    if (from) {
      parts.push(`${capitalize(rangesDictionary.from)} ${from} ${measure}`);
    }

    if (to) {
      parts.push(`${capitalize(rangesDictionary.to)} ${to} ${measure}`);
    }

    return parts.join(', ');
  }

  private parseObject(filter: HashMap<string>) {
    const parts: Array<string> = [];

    for (let key in filter) {
      const id = filter[key];
      const dictionary = this.dictionaries[key];
      const item = findWhere(dictionary, { id });

      item && parts.push(capitalize(item.name));
    }

    return parts.join(', ');
  }
}
