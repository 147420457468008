import { Centrifuge } from 'centrifuge-ts';
import broadcastingApi from '@/api/broadcasting-api';
import {
  GetConnectionSettingsReponse,
  SocketMessage,
  UserData,
} from '@/types/websocket';
import { ErrorResponse, HashMap, UrlService } from '@onlinerby/js-common';
import camelizeService from '@/services/camelize-service';

class WebsocketService {
  private centrifuge!: Centrifuge;
  private userData!: UserData;
  private channels: string[];
  private handlers: HashMap<HashMap<Function[]>> = {};

  constructor(userData: UserData, channels: string[]) {
    this.userData = userData;
    this.channels = channels;

    this.init();
  }

  private init() {
    this.getConnectionSettings((data: GetConnectionSettingsReponse) => {
      const connectionData = Object.assign(data, {
        url: UrlService.secureProjectUrl('notifications', 'centrifugo'),
        onPrivateChannelAuth: this.onAuth.bind(this),
      });

      this.centrifuge = new Centrifuge(connectionData);

      this.channels.forEach(channel => {
        this.subscribeChannel(channel);
      });

      this.centrifuge.connect();
    });
  }

  private getConnectionSettings(callback: Function) {
    broadcastingApi.getConnectionSettings(this.userData, {
      success: ({ data }: { data: GetConnectionSettingsReponse }) => {
        callback(data);
      },
    });
  }

  private subscribeChannel(channel: string) {
    this.centrifuge.subscribe(channel, (message: SocketMessage) => {
      this.handlers[channel] &&
        this.handlers[channel][message.data.event] &&
        this.handlers[channel][message.data.event].forEach(handler => {
          handler(camelizeService.camelize(message.data));
        });
    });
  }

  public addHandler(channel: string, event: string, handler: Function) {
    if (!this.handlers[channel]) {
      this.handlers[channel] = {};
    }

    if (!this.handlers[channel][event]) {
      this.handlers[channel][event] = [];
    }

    this.handlers[channel][event].push(handler);
  }

  private onAuth(
    {
      data,
    }: {
      data: {
        channels: string[];
        client: string;
      };
    },
    callback: Function,
  ) {
    function processAuth(repeat?: boolean) {
      broadcastingApi.auth(data, {
        success: function({
          data,
        }: {
          data: HashMap<{
            info: string;
            sign: string;
          }>;
        }) {
          callback(null, data);
        },
        error: function(error: ErrorResponse) {
          if (error.status === 401 && repeat) {
            processAuth();
          }
        },
      });
    }

    processAuth(true);
  }
}

export default WebsocketService;
