
import { Vue, Prop, Component } from 'vue-property-decorator';
import { findWhere } from 'underscore';
import { HashMap } from '@/types/common';
import { iconMap } from '@/config/promote';

@Component
export default class PromoteFeatureDetail extends Vue {
  @Prop() item!: {
    id: string;
    title: string;
  };

  private get iconClass() {
    return iconMap[this.item.id];
  }
}
