
import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { some, findWhere } from 'underscore';
import Decimal from 'decimal.js-light';

import {
  Order,
  PaymentMethod,
  Subscription,
  subscriptionsApi,
  paygateApi,
  ICard,
} from '@onlinerby/js-api-clients';

import { SelectedFeature, Features, Entities } from '@/types/promote';
import { VuexAction } from '@/types/functions';
import urlService from '@/services/url-service';
import notificationsService from '@/services/notifications-service';

const promoteStore = namespace('promote');

@Component
export default class PromoteControls extends Vue {
  @Prop() isConditionsMet!: boolean;

  private isProcessing: boolean = false;
  private isRulesAccepted: boolean = true;

  @promoteStore.State selectedFeature!: SelectedFeature;
  @promoteStore.State selectedPaymentMethod!: PaymentMethod;
  @promoteStore.State isRecurrent!: boolean;
  @promoteStore.State verificationValue!: string;
  @promoteStore.State features!: Features;
  @promoteStore.State entities!: Entities;
  @promoteStore.State isPaymentProcessing!: boolean;
  @promoteStore.Action createSubscription!: VuexAction;
  @promoteStore.Action setSelectedFeature!: VuexAction;

  private get isValid() {
    return (
      this.selectedFeature.id &&
      this.selectedFeature.entity &&
      this.isRulesAccepted &&
      this.isConditionsMet
    );
  }

  private get sum() {
    if (!this.selectedFeature) {
      return null;
    }

    const feature = findWhere(this.features, { id: this.selectedFeature.id })!;
    const total = new Decimal(feature.basePrice.amount).mul(3);

    return total.toFixed(2).toString().replace('.', ',');
  }

  public onCreateClick() {
    this.$emit('pay');
  }
}
