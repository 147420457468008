import VueRouter from 'vue-router';
import { Dictionary } from 'vue-router/types/router';
import { IFilter } from '@/interfaces/filters';
import { parse, stringify } from 'qs';
import { isArray, isObject, every, map } from 'underscore';

type State = Dictionary<string | (string | null)[]>;

export default class StateService {
  private router: VueRouter;
  private static instance: StateService;

  private constructor(router: VueRouter) {
    this.router = router;
  }

  public get state(): State {
    const {
      _ga,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
      utm_label,
      ...state
    } = parse(stringify(this.router.currentRoute.query));

    for (let key of Object.keys(state)) {
      const value = state[key];

      if (
        isObject(value) &&
        every(Object.keys(value), item => !isNaN(parseInt(item)))
      ) {
        state[key] = map(value, item => item);
      }
    }

    return state;
  }

  public get filters(): Array<IFilter> {
    const filters = [];
    const state = this.state;

    for (let name in state) {
      if (!state.hasOwnProperty(name)) {
        continue;
      }

      filters.push({
        name,
        value: this.parse(state[name]),
      });
    }

    return filters;
  }

  public set state(filter: State) {
    this.router
      .replace({
        query: filter,
      })
      .catch(ex => {});
  }

  public setProperty(name: string, value: string) {
    const query = {
      ...this.state,
      [name]: value,
    };

    this.router
      .push({
        query,
      })
      .catch(ex => {});
  }

  public removeProperty(name: string) {
    const { [name]: omit, ...query } = this.state;

    this.router
      .push({
        query,
      })
      .catch(ex => {});
  }

  public setState(filters: Array<IFilter>) {
    const state: Dictionary<string> = {};

    filters.forEach(({ name, value }) => {
      state[name] = value as string;
    });

    this.state = state;
  }

  public static getInstance(router: VueRouter) {
    if (!this.instance) {
      this.instance = new StateService(router);
    }

    return this.instance;
  }

  private parse(initial: any) {
    let value: any;

    switch (initial) {
      case 'true':
        value = true;
        break;
      case 'false':
        value = true;
        break;
      default:
        value = initial as string;
    }

    if (isArray(value)) {
      value.forEach(item => {
        if (isObject(item)) {
          for (let key of Object.keys(item)) {
            const property = item[key];

            if (isArray(property)) {
              item[key] = (property as Array<any>).map(item => {
                return isNaN(parseFloat(item)) ? item : parseFloat(item);
              });
            } else if (!isNaN(parseFloat(property))) {
              item[key] = parseFloat(property);
            }
          }
        }
      });
    } else if (isObject(value)) {
      for (let key of Object.keys(value)) {
        const property = value[key];

        if (!isNaN(parseFloat(property))) {
          value[key] = parseFloat(property);
        }
      }
    }

    return value;
  }
}
