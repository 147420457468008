
  import { Mixins, Component } from 'vue-mixin-decorator';
  import * as $ from 'jquery';
  import { every, some, isEmpty } from 'underscore';
  import { namespace } from 'vuex-class';

  import AdvertPreview from '@/components/create/advert-preview.vue';
  import { HashMap } from '@/types/common';
  import { IAdvert, IAdvertSpecs } from '@/interfaces/adverts';
  import { VuexAction } from '@/types/functions';
  import IsMobileMixin from '@/mixins/common/is-mobile';
  import StorageMixin from '@/mixins/create/storage';
  import dictionariesService, {
    Dictionaries,
  } from '@/services/dictionaries-service';
  import { ErrorResponse } from '@onlinerby/js-common';

  const commonStore = namespace('common');
  const createStore = namespace('create');

  interface IMixins extends IsMobileMixin, StorageMixin {}

  @Component({
    components: {
      AdvertPreview,
    },
  })
  export default class CreateFinish extends Mixins<IMixins>(
    IsMobileMixin,
    StorageMixin,
  ) {
    private OFFSET: number = 0;
    private isProcessing: boolean = false;

    @commonStore.State dictionaries!: Dictionaries;
    @createStore.State mode!: string;
    @createStore.State data!: HashMap<any>;
    @createStore.State errors!: HashMap<any>;
    @createStore.Action createAdvert!: VuexAction;
    @createStore.Action updateAdvert!: VuexAction;
    @createStore.Action clearData!: VuexAction;
    @createStore.Action setError!: VuexAction;

    created() {
      this.OFFSET = this.isMobileSpecial ? 70 : 20;
    }

    private get seller() {
      return this.data.seller || {};
    }

    private get hasAnyPhones() {
      return (
        this.data.seller &&
        this.data.seller.phones &&
        some(this.data.seller.phones)
      );
    }

    protected validate() {
      const generationsUrl = dictionariesService.buildUrl('model', {
        manufacturer: this.data.manufacturerId,
        model: this.data.modelId,
      });
      const generations = this.dictionaries[generationsUrl];

      if (!this.data.country) {
        this.setError({
          country: ['Укажите страну'],
        });
      } else if (!this.data.region) {
        this.setError({
          region: ['Укажите область'],
        });
      } else if (!this.data.cityId) {
        this.setError({
          cityId: ['Укажите город'],
        });
      }

      if (!this.data.generationId && !isEmpty(generations)) {
        this.setError({
          generationId: ['Укажите поколение'],
        });
      }

      if (!this.data.seller.chatPreferred && !this.hasAnyPhones) {
        this.setError({
          phones: ['Укажите контактный телефон'],
        });
      }

      if (!this.seller.type) {
        this.setError({
          'seller.type': ['Укажите тип продавца'],
        });
      }

      if (!this.seller.name) {
        this.setError({
          'seller.name': ['Укажите имя'],
        });
      }

      if (this.seller.type !== 'individual' && !this.seller.unp) {
        this.setError({
          'seller.unp': ['Укажите УНП'],
        });
      }

      if (this.seller.type !== 'individual' && !this.seller.contactName) {
        this.setError({
          'seller.contactName': ['Укажите контактное лицо'],
        });
      }

      return isEmpty(this.errors);
    }

    private create() {
      this.isProcessing = true;

      this.createAdvert({
        success: ({ data }: any) => {
          this.trackSuccess(data);
          this.saveLocal();
          this.clearData();

          const advertUrl = `/${data.manufacturer.slug}/${data.model.slug}/${data.id}`;

          this.$router.push(advertUrl);
        },
        error: ({ response }: { response: ErrorResponse }) => {
          this.onError({ response });
          this.trackError();
        },
        complete: () => {
          this.isProcessing = false;
        },
      });
    }

    private saveLocal() {
      const { country, region, cityId, seller } = this.data;

      this.setStorageValue('country', country);
      this.setStorageValue('region', region);
      this.setStorageValue('cityId', cityId);
      this.setStorageValue('sellerName', seller.name);
      this.setStorageValue('unp', seller.unp);
      this.setStorageValue('contactName', seller.contactName);
      this.setStorageValue('phones', seller.phones);
    }

    private update() {
      if (!this.validate()) {
        this.scrollToError();
        this.trackError('edit');

        return;
      }

      this.isProcessing = true;

      this.updateAdvert({
        id: this.$route.params.id,
        callbacks: {
          success: ({ data }: any) => {
            this.trackSuccess(data, 'edit');

            this.saveLocal();
            this.clearData();

            const advertUrl = `/${data.manufacturer.slug}/${data.model.slug}/${data.id}`;

            this.$router.push(advertUrl);
          },
          error: this.onError,
          complete: () => {
            this.isProcessing = false;
          },
        },
      });
    }

    private onError({ response }: { response: any }) {
      this.setError(response.data.errors);
      this.trackError(this.mode);
      this.scrollToError();
    }

    private scrollToError() {
      this.$nextTick(() => {
        const $error = $('.js-step-error');

        if (!$error.length) {
          return;
        }

        const errorTop = $error.first().offset()!.top;

        $(window).scrollTop(errorTop - this.OFFSET);
      });
    }

    private trackSuccess(advert: IAdvert, mode: string = 'create') {
      this.$gtm.trackEvent({
        event: `${mode === 'create' ? 'publish' : 'edit'}_ad - success`,
        value: JSON.stringify({
          ad_id: advert.id,
          ad_region: `${advert.location.country.id}_${advert.location.region.id}_${advert.location.city.id}`,
          ad_condition: advert.specs.state,
          ad_price: advert.price.converted.USD.amount,
          ad_currency: 'USD',
          ad_mfr: advert.manufacturer.id,
          seller_id: advert.author.id.toString(),
          seller_type: advert.seller.type,
          user_id: this.currentUser ? this.currentUser.id.toString() : '',
        }),
      });
    }

    private trackError(mode: string = 'create') {
      this.$gtm.trackEvent({
        event: `${mode === 'create' ? 'publish' : 'edit'}_ad - error`,
        value: JSON.stringify({
          error: this.errors,
        }),
      });
    }
  }
