import { Rating, Review } from '@/types/review';
import camelizeService from '@/services/camelize-service';

export default (data: Review) => {
  const specs = data.specs;

  if (!specs.engine.power) {
    specs.engine.power = {
      unit: 'horse',
    };
  }

  let rating: Rating = {};

  Object.keys(data.rating).forEach(key => {
    rating[camelizeService.decamelizeString(key)] = data.rating[key];
  });

  const secondaryMarks = {
    pros: data.pros,
    cons: data.cons,
  };

  const newData = {
    ...data,
    manufacturerId: data.manufacturer.id,
    modelId: data.model.id,
    generationId: data.generation.id,
    specs,
    rating,
    secondaryMarks,
  };

  return newData;
};
