var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "label",
    {
      staticClass: "vehicle-form__offers-unit vehicle-form__offers-unit_alter",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "i-checkbox i-checkbox_radio i-checkbox_success vehicle-form__checkbox vehicle-form__checkbox_base",
          class: { "i-checkbox_disabled": _vm.subscriptions.length },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selected,
                expression: "selected",
              },
            ],
            staticClass: "i-checkbox__real",
            attrs: {
              type: "radio",
              name: "selectedEntity",
              disabled: !!_vm.subscriptions.length,
            },
            domProps: {
              value: _vm.entity.id,
              checked: _vm._q(_vm.selected, _vm.entity.id),
            },
            on: {
              change: function ($event) {
                _vm.selected = _vm.entity.id
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "i-checkbox__faux" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "vehicle-form__offers-item",
              class: {
                "vehicle-form__offers-item_disabled": _vm.subscriptions.length,
              },
            },
            [
              _c(
                "div",
                { staticClass: "vehicle-form__offers-flex" },
                [
                  _c("search-adverts-list-photos", {
                    attrs: {
                      images: _vm.advert.images,
                      "is-premium": _vm.isPremium,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__offers-part vehicle-form__offers-part_time",
                    },
                    [
                      _vm.isLastUpVisible
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_tiny vehicle-form__description_other vehicle-form__description_up",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("relativeDate")(_vm.advert.lastUpAt)
                                  ) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_tiny vehicle-form__description_other",
                            },
                            [
                              _vm._v(
                                "\n            Создано " +
                                  _vm._s(
                                    _vm._f("relativeDate")(_vm.advert.createdAt)
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__offers-part vehicle-form__offers-part_data",
                    },
                    [
                      _c("div", { staticClass: "vehicle-form__offers-flex" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__offers-part vehicle-form__offers-part_info",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__offers-flex" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__offers-part vehicle-form__offers-part_mileage",
                                  },
                                  [
                                    _vm.specs.odometer.value
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm._f("number")(
                                                    _vm.specs.odometer.value
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(_vm.odometerUnit) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Новый\n                  "
                                            ),
                                          ]
                                        ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__offers-part vehicle-form__offers-part_year",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.specs.year) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__offers-part vehicle-form__offers-part_city",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_compass-alter",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm._f("location")(
                                                _vm.advert.location,
                                                true
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__offers-part vehicle-form__offers-part_title",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary vehicle-form__description_font-weight_bold",
                                class: {
                                  "vehicle-form__description_premium-additional":
                                    _vm.isPremium,
                                  "vehicle-form__description_fixing-additional":
                                    _vm.isFixed,
                                },
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "vehicle-form__link vehicle-form__link_primary-alter vehicle-form__link_middle",
                                    attrs: { to: _vm.route },
                                  },
                                  [_vm._v(_vm._s(_vm.title))]
                                ),
                                _vm._v(" "),
                                _vm.advert.specs.hasVin
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "button-style button-style_primary button-style_small button-style_noreflex vehicle-form__button vehicle-form__button_tally",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  VIN\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.advert.dealTerms.exchange
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "button-style button-style_otherwise button-style_small button-style_noreflex vehicle-form__button vehicle-form__button_tally helpers_hide_tablet",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Обмен\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__offers-part vehicle-form__offers-part_price",
                    },
                    [
                      _vm.isPremium
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "button-style button-style_primary button-style_base button-style_noreflex vehicle-form__button vehicle-form__button_price",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("currency")(_vm.advert.price, "BYN")
                                  ) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary vehicle-form__description_font-weight_bold vehicle-form__description_condensed-other",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("currency")(_vm.advert.price, "BYN")
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_tiny vehicle-form__description_other",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("currency")(_vm.advert.price, "USD")
                              ) +
                              " /\n            " +
                              _vm._s(
                                _vm._f("currency")(_vm.advert.price, "EUR")
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__offers-part vehicle-form__offers-part_specification",
                    },
                    [
                      _c("div", { staticClass: "vehicle-form__offers-flex" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__offers-part vehicle-form__offers-part_parameter",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_engine vehicle-form__description_condensed-other",
                              },
                              [
                                _vm.specs.engine.capacity
                                  ? [
                                      _vm._v(
                                        _vm._s(_vm.specs.engine.capacity) +
                                          " л /"
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm._f("capitalize")(_vm.engine)) +
                                    "\n              "
                                ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_transmission vehicle-form__description_condensed-other",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("capitalize")(_vm.transmission)
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_car vehicle-form__description_condensed-other",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm._f("capitalize")(_vm.bodyType)) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__offers-part vehicle-form__offers-part_parameter",
                          },
                          [
                            _vm.power
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_horse vehicle-form__description_condensed-other",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.power) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_chassis vehicle-form__description_condensed-other",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("capitalize")(_vm.drivetrain)
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.optionsCount
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_dots vehicle-form__description_condensed-other",
                                  },
                                  [
                                    _vm._v(
                                      "\n                Еще " +
                                        _vm._s(
                                          _vm._f("plural")(_vm.optionsCount, [
                                            "опция",
                                            "опции",
                                            "опций",
                                          ])
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isOptionsDisplayed
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__offers-part vehicle-form__offers-part_ticket",
                        },
                        [
                          _c("div", { staticClass: "vehicle-form__ticket" }, [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__ticket-list" },
                              _vm._l(_vm.options, function (option) {
                                return _c(
                                  "div",
                                  {
                                    key: option,
                                    staticClass: "vehicle-form__ticket-item",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "button-style button-style_small button-style_noreflex vehicle-form__button vehicle-form__button_ticket",
                                        class: {
                                          "button-style_appendant":
                                            _vm.activeFeature.name === "sticky",
                                          "button-style_alternate":
                                            _vm.activeFeature.name ===
                                            "auto_up",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.getOptionName(option)) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.subscriptions.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__offers-part vehicle-form__offers-part_notice",
                        },
                        [
                          _vm.activeSubscription
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_pass",
                                },
                                [
                                  _vm._v(
                                    "\n            Премиальный пакет оплачен до " +
                                      _vm._s(_vm.nextBillAt) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _vm.pendingSubscriptions.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_attention",
                                },
                                [
                                  _vm._v(
                                    "\n            Премиальный пакет уже создан, перейти для\n            "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "vehicle-form__link vehicle-form__link_base vehicle-form__link_primary vehicle-form__link_solid",
                                      attrs: { href: _vm.paymentLink },
                                    },
                                    [_vm._v("оплаты")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }