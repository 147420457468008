var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.dictionaries
    ? _c(
        "div",
        {
          staticClass: "vehicle-form__filter-part vehicle-form__filter-part_2",
          attrs: { id: "list" },
        },
        [
          _c("div", { staticClass: "vehicle-form__sorting" }, [
            _c("div", { staticClass: "vehicle-form__sorting-flex" }, [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__sorting-part vehicle-form__sorting-part_1",
                },
                [
                  _vm.isLoaded
                    ? _c("search-tags", {
                        attrs: {
                          filters: _vm.filters,
                          "remove-filter": _vm.removeFilter,
                          "update-list": _vm.updateList,
                          "filters-config": _vm.filtersConfig,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__sorting-part vehicle-form__sorting-part_2",
                },
                [
                  _c("order", {
                    attrs: {
                      name: "order",
                      "sort-types": _vm.sortTypes,
                      "set-filter": _vm.setFilter,
                      "state-counters": _vm.stateCounters,
                      "get-filter": _vm.getFilter,
                      filters: _vm.filters,
                      "update-list": _vm.updateList,
                      "update-counters": _vm.updateCounters,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__sorting-part vehicle-form__sorting-part_3 helpers_show_tablet",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "button-style button-style_auxiliary button-style_small vehicle-form__button vehicle-form__button_filter",
                      on: { click: _vm.onShowFilters },
                    },
                    [_vm._v("Фильтры")]
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.reviewsRating
            ? _c(
                "div",
                {
                  staticClass: "vehicle-form__grade vehicle-form__grade_decor",
                },
                [
                  !_vm.reviewsRating.stats
                    ? _c("rating-header", {
                        attrs: {
                          "rating-average": _vm.reviewsRating.average,
                          "count-reviews": _vm.reviewsRating.count,
                          "rating-by": _vm.reviewsRating.ratingBy,
                          "is-counter-clickable": true,
                          "is-info-block-visible": true,
                          manufacturer: _vm.manufacturer,
                          model: _vm.model,
                          generation: _vm.generation,
                        },
                      })
                    : _c("rating", {
                        attrs: {
                          "reviews-rating": _vm.reviewsRating,
                          "is-counter-clickable": true,
                          "is-info-block-visible": true,
                          manufacturer: _vm.manufacturer,
                          model: _vm.model,
                          generation: _vm.generation,
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "vehicle-form__offers",
              class: {
                "vehicle-form__offers_noreflex": _vm.isAnyDropdownOpened,
              },
            },
            [_c("search-adverts-list")],
            1
          ),
          _vm._v(" "),
          _c("search-adverts-pagination"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }