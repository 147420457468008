
  import { Mixins, Component } from 'vue-mixin-decorator';
  import { Prop } from 'vue-property-decorator';

  import FieldMixin from '@/mixins/reviews/create/field';

  @Component
  export default class StepTechDrivetrain extends Mixins<FieldMixin>(
    FieldMixin,
  ) {
    @Prop() listDrivetrain!: Array<any>;

    private get drivetrain() {
      return this.data.specs.drivetrain;
    }

    private onSetDrivetrainClick(drivetrain: string) {
      this.onFieldChange(drivetrain);
    }
  }
