var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__line vehicle-form__line_condensed-specific" },
    [
      _c(
        "div",
        {
          staticClass:
            "input-style__combo input-style__combo_elastic vehicle-form__input-combo vehicle-form__input-combo_width_full",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_narrow vehicle-form__input-wrapper_width_full",
            },
            _vm._l(_vm.typeChain, function (type, index) {
              return _c(
                type.multi
                  ? "chained-select-filter-chunk-multi"
                  : "chained-select-filter-chunk",
                {
                  key: type.name,
                  tag: "div",
                  attrs: {
                    value: _vm.filter.value ? _vm.filter.value[type.name] : "",
                    type: type,
                    index: index,
                    "group-index": _vm.groupIndex,
                    "dictionary-name": _vm.getDictionaryName(index),
                    parent: index > 0 ? _vm.chain[index - 1] : null,
                    chunk: _vm.chain[index],
                    "type-chunk": _vm.typeChain[index],
                    counters: _vm.counters,
                    "is-disabled":
                      index > 0 &&
                      (!_vm.chain[index - 1] || !_vm.chain[index - 1].value),
                  },
                  on: { change: _vm.onChunkChange },
                }
              )
            }),
            1
          ),
          _vm._v(" "),
          _vm.canDelete
            ? _c("a", {
                staticClass:
                  "button-style button-style_auxiliary button-style_small vehicle-form__button vehicle-form__button_narrow vehicle-form__button_remove",
                on: {
                  click: function ($event) {
                    return _vm.$emit("remove-filter", _vm.groupIndex)
                  },
                },
              })
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }