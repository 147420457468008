var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__row" }, [
    _c(
      "div",
      { staticClass: "vehicle-form__group vehicle-form__group_width_full" },
      [
        _c(
          "div",
          { staticClass: "vehicle-form__label vehicle-form__label_base" },
          [
            _c("div", { staticClass: "vehicle-form__label-flex" }, [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__label-part vehicle-form__label-part_1",
                },
                [
                  _c("div", { staticClass: "vehicle-form__label-title" }, [
                    _vm._v(_vm._s(_vm.label)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "vehicle-form__label-part vehicle-form__label-part_2",
              }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "vehicle-form__field" }, [
          _c("div", { staticClass: "vehicle-form__line" }, [
            _c(
              "ul",
              { staticClass: "vehicle-form__checkbox-list" },
              _vm._l(_vm.preferredOptions, function (option) {
                return _c(
                  "li",
                  {
                    key: option.key,
                    staticClass: "vehicle-form__checkbox-item",
                  },
                  [
                    _c(
                      "label",
                      { staticClass: "vehicle-form__checkbox-label" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "i-checkbox vehicle-form__checkbox vehicle-form__checkbox_base",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.checkboxes[option.key],
                                  expression: "checkboxes[option.key]",
                                },
                              ],
                              staticClass: "i-checkbox__real",
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.checkboxes[option.key]
                                )
                                  ? _vm._i(_vm.checkboxes[option.key], null) >
                                    -1
                                  : _vm.checkboxes[option.key],
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.checkboxes[option.key],
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.checkboxes,
                                            option.key,
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.checkboxes,
                                            option.key,
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.checkboxes, option.key, $$c)
                                    }
                                  },
                                  _vm.updateFilter,
                                ],
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "i-checkbox__faux" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "vehicle-form__checkbox-text" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vehicle-form__checkbox-sign",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm._f("capitalize")(option.title)
                                        ) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.counters && _vm.counters[option.key]
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__checkbox-value",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm._f("number")(
                                                _vm.counters[option.key]
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.withImages
                                  ? _c("div", {
                                      staticClass:
                                        "vehicle-form__checkbox-image",
                                      class: [
                                        `vehicle-form__checkbox-image_${option.key}`,
                                      ],
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__line" }, [
            _c(
              "div",
              {
                ref: "handle",
                staticClass:
                  "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_width_full",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "input-style input-style_primary input-style_small input-style_arrow_right vehicle-form__input vehicle-form__input_width_full",
                    class: { "input-style_placeholder": !_vm.selectedValues },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.toggleDropdown.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "input-style__faux" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.selectedValues ||
                              _vm.labelPlaceholder ||
                              _vm.labelAny ||
                              "Любое"
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-style__real" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "dropdown-style dropdown-style_primary dropdown-style_small dropdown-style_right vehicle-form__dropdown vehicle-form__dropdown_width_ssm",
                    class: { "dropdown-style_visible": _vm.isVisible },
                  },
                  [
                    _c("div", { staticClass: "dropdown-style__container" }, [
                      _c("div", { staticClass: "dropdown-style__content" }, [
                        _c("div", { staticClass: "dropdown-style__group" }, [
                          _c("ul", { staticClass: "dropdown-style__list" }, [
                            _c(
                              "li",
                              {
                                staticClass:
                                  "dropdown-style__item dropdown-style__item_indent",
                                on: { click: _vm.clearFilter },
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "dropdown-style__checkbox-label",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "i-checkbox i-checkbox_dot-alter dropdown-style__checkbox",
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "i-checkbox__real",
                                          class: {
                                            "i-checkbox__real_checked":
                                              _vm.isNoneSelected,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "i-checkbox__faux",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "dropdown-style__checkbox-text",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "dropdown-style__checkbox-sign",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                              " +
                                                    _vm._s(
                                                      _vm.labelAny || "Любое"
                                                    ) +
                                                    "\n                            "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "dropdown-style__group" }, [
                          _c(
                            "ul",
                            { staticClass: "dropdown-style__list" },
                            _vm._l(_vm.options, function (option) {
                              return _c(
                                "li",
                                {
                                  key: option.id,
                                  staticClass:
                                    "dropdown-style__item dropdown-style__item_indent",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "dropdown-style__checkbox-label",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "i-checkbox i-checkbox_warning dropdown-style__checkbox",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.checkboxes[option.id],
                                                expression:
                                                  "checkboxes[option.id]",
                                              },
                                            ],
                                            staticClass: "i-checkbox__real",
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.checkboxes[option.id]
                                              )
                                                ? _vm._i(
                                                    _vm.checkboxes[option.id],
                                                    null
                                                  ) > -1
                                                : _vm.checkboxes[option.id],
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$a =
                                                      _vm.checkboxes[option.id],
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.checkboxes,
                                                          option.id,
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.checkboxes,
                                                          option.id,
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.checkboxes,
                                                      option.id,
                                                      $$c
                                                    )
                                                  }
                                                },
                                                _vm.updateFilter,
                                              ],
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("div", {
                                            staticClass: "i-checkbox__faux",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "dropdown-style__checkbox-text",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "dropdown-style__checkbox-sign",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                              " +
                                                      _vm._s(
                                                        _vm._f("capitalize")(
                                                          option.name
                                                        )
                                                      ) +
                                                      "\n                            "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.counters &&
                                              _vm.counters[option.id]
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "dropdown-style__checkbox-value",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                              (" +
                                                          _vm._s(
                                                            _vm._f("number")(
                                                              _vm.counters[
                                                                option.id
                                                              ]
                                                            )
                                                          ) +
                                                          ")\n                            "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.withImages
                                                ? _c("div", {
                                                    staticClass:
                                                      "vehicle-form__checkbox-image",
                                                    class: [
                                                      `vehicle-form__checkbox-image_${option.id}`,
                                                    ],
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "dropdown-style__bottom helpers_show_tablet",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "button-style button-style_additional button-style_small dropdown-style__button",
                            on: { click: _vm.closeDropdown },
                          },
                          [_vm._v("\n                Готово\n              ")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }