export default class UrlService {
  public static baseDomain: string = document.location.host
    .split('.')
    .slice(-2)
    .join('.');
  public static projectName: string = document.location.host
    .split('.')
    .slice(-3, -2)[0];

  public static get currentUrl() {
    return window.location.origin + window.location.pathname;
  }

  public static get profileUrl() {
    return UrlService.secureProjectUrl('profile');
  }

  public static getLoginUrl(redirectUrl: string) {
    return this.profileUrl + '/login?redirect=' + redirectUrl;
  }

  public static getRegistrationUrl(redirectUrl: string) {
    return this.profileUrl + '/reg?redirect=' + redirectUrl;
  }

  public static projectDomain(projectName: string) {
    return projectName + '.' + UrlService.baseDomain;
  }

  public static projectUrl(
    projectName: string,
    pathQuery?: string,
    schema?: string
  ) {
    pathQuery = pathQuery ? pathQuery.replace(/^\/*/, '/') : '';
    schema = schema || 'http://';

    return schema + UrlService.projectDomain(projectName) + pathQuery;
  }

  public static secureProjectUrl(projectName: string, pathQuery?: string) {
    return UrlService.projectUrl(projectName, pathQuery, 'https://');
  }

  public static redirect(url: string) {
    document.location.href = url;
  }
}
