
  import { Component, Prop } from 'vue-property-decorator';
  import { Mixins } from 'vue-mixin-decorator';
  import { namespace } from 'vuex-class';
  import { IFilter } from '@/interfaces/filters';
  import { ITag } from '@/interfaces/search';
  import {
    contains,
    find,
    findWhere,
    flatten,
    isArray,
    isObject,
  } from 'underscore';

  import StateServiceMixin from '@/mixins/state-service';
  import SearchTag from './search-tag.vue';
  import {
    IFilterConfigItem,
    FilterContainer,
    FiltersConfig,
  } from '@/config/filters';
  import dictionariesService, {
    Dictionaries,
  } from '@/services/dictionaries-service';
  import capitalize from '@/filters/format-capitalize';
  import { ranges, rangesAlt, measures, currencies } from '@/dictionaries/common';
  import groupMap from '@/config/group-map';
  import { HashMap } from '@/types/common';
  import camelize from '@/filters/format-camelize';
  import number from '@/filters/format-number';
  import HumanizeMixin from '@/mixins/search/humanize';
  import { VuexAction } from '@/types/functions';

  const commonStore = namespace('common');

  interface IMixins extends StateServiceMixin, HumanizeMixin {}

  @Component({
    components: {
      SearchTag,
    },
  })
  export default class SearchTags extends Mixins<IMixins>(
    StateServiceMixin,
    HumanizeMixin,
  ) {
    @Prop() filters!: Array<IFilter>;
    @Prop() removeFilter!: VuexAction;
    @Prop() updateList!: VuexAction;
    @Prop() filtersConfig!: FiltersConfig;
    @Prop() searchPrefix!: string;

    @commonStore.State dictionaries!: Dictionaries;

    private get tags(): Array<ITag> {
      const tags: Array<ITag> = [];

      const processPart = (
        name: string,
        value: any,
        rootName: string,
        groupIndex?: number | undefined,
      ) => {
        if (isArray(value)) {
          value.forEach((item, index) => {
            if (rootName === name && isObject(item)) {
              processPart(name, item, rootName, index);
            } else if (rootName === name && !isObject(item)) {
              processPart(name, item, rootName);
            } else {
              processPart(name, item, rootName, groupIndex);
            }
          });
        } else if (isObject(value)) {
          for (let key of Object.keys(value)) {
            processPart(key, value[key], rootName, groupIndex);
          }
        } else {
          const title = this.getTagTitle(
            name,
            value,
            rootName,
            this.dictionaries,
          );

          if (value && title) {
            tags.push({
              name,
              rootName,
              groupIndex: groupIndex,
              title,
              value: <string>value,
            });
          }
        }
      };

      this.filters.forEach(({ name, value }) => {
        processPart(name, value, name);
      });

      return tags;
    }

    private getTagTitle(
      filterName: string,
      value: any,
      rootName: string,
      dictionaries: Dictionaries,
    ): string {
      const config = this.getFilterConfig(rootName) as IFilterConfigItem;
      let title = '';

      if (!config) {
        return '';
      }

      if (filterName === 'group') {
        const dictionaryName: string = (groupMap as HashMap<string>)[rootName];
        const dictionary = dictionaries[dictionaryName];
        const group = findWhere(dictionary, { id: parseInt(value) });

        title = group ? group.name : '';
      }

      if (contains(['range-unit', 'range'], config.type)) {
        let measure;

        if (!contains(['from', 'to'], filterName)) {
          return '';
        }

        if (config.unitType) {
          const filter = findWhere(this.filters, { name: rootName });

          if (filter && filter.value) {
            const unit = (filter.value as HashMap<string>)[
              config.unitType
            ].toUpperCase();

            measure = currencies[unit];
          }
        } else {
          measure = measures[rootName];
        }

        const rangesDictionary = rootName === 'year' ? rangesAlt : ranges;
        const translatedPredicate = rangesDictionary[filterName];

        title = `${translatedPredicate} ${number(value)} ${measure}`;
      } else if (contains(['checkbox'], config.type)) {
        title = dictionariesService.singleFilters[filterName];
      } else {
        const dictionary = dictionaries[camelize(filterName)];

        const item = find(dictionary, (item) => item.id == value);

        if (item) {
          title = item.name;
        }
      }

      return capitalize(title);
    }

    private getFilterConfig(name: string) {
      const flatFilters = flatten(
        this.filtersConfig.map((item) => {
          return (item as FilterContainer).children
            ? (item as FilterContainer).children
            : item;
        }),
      );

      return find(flatFilters, (filter: IFilterConfigItem) => {
        return filter.name === name;
      });
    }

    private onTagRemove() {
      const { path, filters } = this.humanize();
      const fullPath = this.searchPrefix ? `/${this.searchPrefix}${path}` : path;

      this.stateService.setState(filters);
      this.$router
        .replace({
          path: fullPath,
          query: this.stateService.state,
        })
        .catch((ex) => {});
    }

    private getGlobalEmitByTag(tag: ITag) {
      const config = findWhere(this.filtersConfig, { name: tag.rootName });

      return config && config.globalEmit ? config.globalEmit : null;
    }
  }
