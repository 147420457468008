var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__line vehicle-form__line_condensed-other" },
    [
      _c(
        "div",
        {
          ref: "handle",
          staticClass:
            "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_width_full",
          on: {
            mouseover: function ($event) {
              _vm.cursorPointer > 0 && _vm.resetCurrent()
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "input-style input-style_primary input-style_small input-style_arrow_right vehicle-form__input vehicle-form__input_width_full",
              class: {
                "input-style_disabled": _vm.isDisabled,
                "input-style_placeholder": !_vm.value,
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.toggleDropdown.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "input-style__faux" }, [
                _vm._v(_vm._s(_vm.selectedTitle)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input-style__real" }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "dropdown",
              staticClass:
                "dropdown-style dropdown-style_primary dropdown-style_small dropdown-style_right vehicle-form__dropdown vehicle-form__dropdown_width_sssm",
              class: { "dropdown-style_visible": _vm.isVisible },
            },
            [
              _c("div", { staticClass: "dropdown-style__top" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "input-style__wrapper dropdown-style__input-wrapper dropdown-style__input-wrapper_width_full",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.optionsFilter,
                          expression: "optionsFilter",
                        },
                      ],
                      ref: "filterInput",
                      staticClass:
                        "input-style input-style_primary input-style_small dropdown-style__input dropdown-style__input_width_full",
                      attrs: {
                        type: "text",
                        placeholder: `${_vm.typeChunk.filterPlaceholder}`,
                      },
                      domProps: { value: _vm.optionsFilter },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.optionsFilter = $event.target.value
                          },
                          function ($event) {
                            _vm.optionsFilter = $event.target.value
                          },
                        ],
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "down", 40, $event.key, [
                              "Down",
                              "ArrowDown",
                            ])
                          )
                            return null
                          return _vm.onFilterDownKey.apply(null, arguments)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass:
                        "input-style__helper input-style__helper_clear /*input-style__helper_visible*/ dropdown-style__input-helper",
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "dropdown-style__container" }, [
                _c(
                  "div",
                  { staticClass: "dropdown-style__content" },
                  [
                    _c("div", { staticClass: "dropdown-style__group" }, [
                      _c("ul", { staticClass: "dropdown-style__list" }, [
                        _c(
                          "li",
                          {
                            staticClass:
                              "dropdown-style__item dropdown-style__item_indent",
                          },
                          [
                            _c(
                              "label",
                              { staticClass: "dropdown-style__checkbox-label" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "i-checkbox i-checkbox_dot-alter dropdown-style__checkbox",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.boundValue,
                                          expression: "boundValue",
                                        },
                                      ],
                                      staticClass: "i-checkbox__real",
                                      attrs: {
                                        type: "radio",
                                        name: _vm.typeChunk.name,
                                      },
                                      domProps: {
                                        value: NaN,
                                        checked: _vm._q(_vm.boundValue, NaN),
                                      },
                                      on: {
                                        change: [
                                          function ($event) {
                                            _vm.boundValue = NaN
                                          },
                                          function ($event) {
                                            return _vm.onChange()
                                          },
                                        ],
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "i-checkbox__faux",
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "dropdown-style__checkbox-text",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "dropdown-style__checkbox-sign",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.typeChunk.anyPlaceholder
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.hasPopular
                      ? _c("chained-select-filter-chunk-popular", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.optionsFilter,
                              expression: "!optionsFilter",
                            },
                          ],
                          attrs: {
                            "dictionary-name": _vm.dictionaryName,
                            value: _vm.boundValue,
                          },
                          on: { selected: _vm.onPopularSelected },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "dropdown-style__group" }, [
                      _vm.typeChunk.allPlaceholder
                        ? _c("div", { staticClass: "dropdown-style__title" }, [
                            _vm._v(_vm._s(_vm.typeChunk.allPlaceholder)),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          ref: "list",
                          staticClass: "dropdown-style__list",
                          attrs: { tabindex: "0" },
                          on: {
                            keydown: [
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k($event.keyCode, "up", 38, $event.key, [
                                    "Up",
                                    "ArrowUp",
                                  ])
                                )
                                  return null
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.onUpKey.apply(null, arguments)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "down",
                                    40,
                                    $event.key,
                                    ["Down", "ArrowDown"]
                                  )
                                )
                                  return null
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.onDownKey.apply(null, arguments)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.setCurrent.apply(null, arguments)
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.options, function (option, index) {
                          return _c(
                            "li",
                            {
                              key: option.id,
                              ref: `item_${index}`,
                              refInFor: true,
                              staticClass:
                                "dropdown-style__item dropdown-style__item_indent dropdown-style__item_letter",
                              class: {
                                "dropdown-style__item_highlighted":
                                  _vm.cursorPointer === index,
                              },
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "dropdown-style__checkbox-label",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "i-checkbox i-checkbox_dot dropdown-style__checkbox",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.boundValue,
                                            expression: "boundValue",
                                          },
                                        ],
                                        staticClass: "i-checkbox__real",
                                        attrs: {
                                          type: "radio",
                                          name: _vm.groupIndex
                                            ? `${_vm.typeChunk.name}_${_vm.groupIndex}`
                                            : _vm.typeChunk.name,
                                        },
                                        domProps: {
                                          value: option.id,
                                          checked: _vm._q(
                                            _vm.boundValue,
                                            option.id
                                          ),
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              _vm.boundValue = option.id
                                            },
                                            function ($event) {
                                              return _vm.onChange(false)
                                            },
                                          ],
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "i-checkbox__faux",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "dropdown-style__checkbox-text",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "dropdown-style__checkbox-sign",
                                            },
                                            [_vm._v(_vm._s(option.name))]
                                          ),
                                          _vm._v(" "),
                                          _vm.counters &&
                                          _vm.counters[_vm.typeChunk.name] &&
                                          _vm.counters[_vm.typeChunk.name][
                                            option.id
                                          ]
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "dropdown-style__checkbox-value",
                                                },
                                                [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        _vm._f("number")(
                                                          _vm.counters[
                                                            _vm.typeChunk.name
                                                          ][option.id]
                                                        )
                                                      ) +
                                                      ")"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dropdown-style__bottom helpers_show_tablet" },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "button-style button-style_additional button-style_small dropdown-style__button",
                      on: { click: _vm.closeDropdown },
                    },
                    [_vm._v("Готово")]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }