import { Prop, Vue } from 'vue-property-decorator';

import { Mixin } from 'vue-mixin-decorator';
import { HashMap } from '@/types/common';
import capitalize from '@/filters/format-capitalize';

@Mixin({
  filters: {
    capitalize,
  },
})
export default class Field extends Vue {
  @Prop() data!: HashMap<any>;
  @Prop() mode!: string;
  @Prop() errors!: Array<string> | HashMap<any>;

  private get error() {
    return (this.errors || []).join('. ');
  }

  public onFieldChange(value: any) {
    this.$emit('change', value);
  }
}
