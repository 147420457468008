var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__row vehicle-form__row_extended-other" },
    [
      _c(
        "div",
        {
          staticClass: "vehicle-form__line vehicle-form__line_condensed-other",
        },
        [
          _c("div", { staticClass: "vehicle-form__group" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__field" }, [
              _c(
                "div",
                {
                  ref: "handle",
                  staticClass:
                    "input-style__wrapper vehicle-form__input-wrapper",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "input-style input-style_primary input-style_base input-style_arrow_bottom vehicle-form__input vehicle-form__input_width_ll",
                      class: {
                        "input-style_disabled":
                          !this.manufacturer || this.mode === "edit",
                        "input-style_placeholder": !_vm.model,
                      },
                      on: { click: _vm.open },
                    },
                    [
                      _c("div", { staticClass: "input-style__faux" }, [
                        _vm._v(_vm._s(_vm.modelName)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-style__real" }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-style dropdown-style_primary dropdown-style_small dropdown-style_bottom dropdown-style_specific vehicle-form__dropdown vehicle-form__dropdown_width_full",
                      class: { "dropdown-style_visible": _vm.isVisible },
                    },
                    [
                      _c("span", {
                        staticClass:
                          "dropdown-style__close helpers_show_tablet",
                        on: { click: _vm.close },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "dropdown-style__top" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-style__wrapper dropdown-style__input-wrapper dropdown-style__input-wrapper_width_full",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.search,
                                  expression: "search",
                                },
                              ],
                              staticClass:
                                "input-style input-style_primary input-style_small dropdown-style__input dropdown-style__input_width_full",
                              attrs: {
                                type: "text",
                                placeholder: "Найти модель",
                              },
                              domProps: { value: _vm.search },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.search = $event.target.value
                                  },
                                  function ($event) {
                                    _vm.search = $event.target.value
                                  },
                                ],
                              },
                            }),
                            _vm._v(" "),
                            _c("div", {
                              staticClass:
                                "input-style__helper input-style__helper_clear dropdown-style__input-helper",
                              class: {
                                "input-style__helper_visible": _vm.filterString,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.search = ""
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "dropdown-style__container" }, [
                        _c("div", { staticClass: "dropdown-style__content" }, [
                          _c("div", { staticClass: "dropdown-style__group" }, [
                            _vm.models.length
                              ? _c(
                                  "ul",
                                  { staticClass: "dropdown-style__list" },
                                  _vm._l(_vm.models, function (item) {
                                    return _c(
                                      "li",
                                      {
                                        key: `model_${item.id}`,
                                        staticClass:
                                          "dropdown-style__item dropdown-style__item_indent",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "dropdown-style__checkbox-label",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "i-checkbox i-checkbox_dot dropdown-style__checkbox",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.model,
                                                      expression: "model",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "i-checkbox__real",
                                                  attrs: {
                                                    type: "radio",
                                                    name: "model",
                                                  },
                                                  domProps: {
                                                    value: item.id,
                                                    checked: _vm._q(
                                                      _vm.model,
                                                      item.id
                                                    ),
                                                  },
                                                  on: {
                                                    change: [
                                                      function ($event) {
                                                        _vm.model = item.id
                                                      },
                                                      _vm.onChange,
                                                    ],
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass:
                                                    "i-checkbox__faux",
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dropdown-style__checkbox-text",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown-style__checkbox-sign",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm.filterString
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_break vehicle-form__description_max-width_xxxxllll",
                                  },
                                  [_vm._v("Ничего не найдено")]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "dropdown-style__overlay helpers_show_tablet",
                    on: { click: _vm.close },
                  }),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          !_vm.error ? _c("div", { staticClass: "dummy" }) : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            {
              staticClass:
                "vehicle-form__line vehicle-form__line_condensed-other",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_full",
                },
                [_vm._v(_vm._s(_vm.error))]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("Модель"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }