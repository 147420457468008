import vmApi from '@/api/vm-api';
import { HashMap } from '@/types/common';
import { singleFilters } from '@/dictionaries/search';
import { status } from '@/dictionaries/reviews';
import { equipmentMap } from '@/config/equipment';
import dictionariesMap from '@/config/dictionaries-map';

export type Dictionaries = HashMap<Dictionary>;

export type Dictionary = Array<DictionaryItem>;

export type DictionaryItem = {
  id: string | number;
  name: string;
  slug?: string;
  url?: string;
  groupName?: string;
};

const additionalDictionaries: Dictionaries = {
  status,
};

class DictionariesService {
  private static instance: DictionariesService;
  private dictionaries: Dictionaries | null = null;
  private urlDictionaries: { [index: string]: Dictionary } = {};
  private callbacks: Array<Function> = [];

  private constructor() {
    this.updateDictionaries();
  }

  private updateDictionaries() {
    vmApi.getDictionaries({
      success: ({ data }: { data: Dictionaries }) => {
        this.dictionaries = {
          ...data,
          ...additionalDictionaries,
        };

        this.callbacks.forEach((callback: Function) =>
          callback(this.dictionaries),
        );
      },
    });
  }

  public get singleFilters() {
    const equipment: HashMap<string> = {};

    for (let key of Object.keys(equipmentMap)) {
      const part = equipmentMap[key];

      part.forEach(item => {
        equipment[item.id] = item.name;
      });
    }

    return { ...equipment, ...singleFilters };
  }

  public getDictionaries(callback: Function) {
    if (!this.dictionaries) {
      this.callbacks.push(callback);
    } else {
      callback(this.dictionaries);
    }
  }

  public getDictionary(
    name: string,
    data: HashMap<number>,
    callback: Function,
  ) {
    const url = this.buildUrl(name, data);

    vmApi.getDictionaryByUrl(url, {
      success: ({ data }: { data: any }) => {
        const dataName = dictionariesMap[name].dataName || '';

        this.urlDictionaries[url] = data[dataName];

        callback && callback(data[dataName]);
      },
    });
  }

  public buildUrl(name: string, data: HashMap<number>) {
    const { chunk, parts } = dictionariesMap[name];
    const urlParts: Array<string> = [];

    parts &&
      parts.forEach((part: string) => {
        urlParts.push(`/${dictionariesMap[part].chunk}/${data[part]}`);
      });

    return `${urlParts.join('')}`;
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new DictionariesService();
    }

    return this.instance;
  }
}

export default DictionariesService.getInstance();
