import { ActionTree } from 'vuex';

import { IRootState } from '@/interfaces/store';
import { ISearchState } from '@/interfaces/reviews/store';

import vmApi from '@/api/vm-api';
import { ListReviewResponse } from '@/types/review';
import { IFilter } from '@/interfaces/filters';
import { isNumber } from 'underscore';
import { CountersResponse } from '@/types/api';
import { ICounters } from '@/interfaces/search';

const actions: ActionTree<ISearchState, IRootState> = {
  getReviews(context, { filters, callbacks = {} } = {}) {
    const filtersObject = filters || context.getters.filtersObject;

    context.commit('resetPage');
    context.commit('setLoading', true);

    vmApi.getReviews(filtersObject, {
      success: (response: ListReviewResponse) => {
        context.commit('setReviews', response.data.reviews);
        context.commit('setPage', response.data.page);
        context.commit('setTotal', response.data.total);

        callbacks.success && callbacks.success(response.data);
        callbacks.complete && callbacks.complete();
      },
      complete: () => {
        context.commit('setLoading', false);
      },
    });

    vmApi.getReviewCounters(filtersObject, {
      success: (response: CountersResponse) => {
        context.commit('setCounters', response.data.counters);
      },
    });
  },

  setPage(context, { page, callbacks = {} }) {
    const params = Object.assign({}, context.getters.filtersObject, {
      page,
    });

    vmApi.getReviews(params, {
      success: (response: ListReviewResponse) => {
        context.commit('setReviews', response.data.reviews);
        context.commit('setPage', response.data.page);
        context.commit('setTotal', response.data.total);

        callbacks.success && callbacks.success(response.data);
        callbacks.complete && callbacks.complete();
      },
      complete: () => {
        context.commit('setLoading', false);
      },
    });

    vmApi.getReviewCounters(params, {
      success: (response: CountersResponse) => {
        context.commit('setCounters', response.data.counters);
      },
    });
  },

  setFilters(context, { filters }) {
    filters.forEach((filter: IFilter) => context.commit('setFilter', filter));
  },

  setFilter(context, { name, value, groupIndex }) {
    isNumber(groupIndex)
      ? context.commit('setGroupFilter', { name, value, groupIndex })
      : context.commit('setFilter', { name, value });
  },

  removeFilter(context, payload) {
    context.commit('removeFilter', payload);
  },

  clearFilters(context) {
    context.commit('clearFilters');
  },

  updateCounters(context, { filters, callbacks }) {
    const params = filters || context.getters.filtersObject;

    vmApi.getReviewCounters(params, {
      success({ data }: CountersResponse) {
        context.commit('setCounters', data.counters);
        context.commit('setTotal', data.total);

        callbacks.success && callbacks.success(data);
      },
    });
  },

  updateGeneralCounters(context) {
    vmApi.getGeneralCounters({
      success: ({ data }: { data: ICounters }) => {
        context.commit('setGeneralCounters', data);
      },
    });
  },
};

export default actions;
