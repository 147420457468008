var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "popup",
    {
      ref: "closeAdvertPopup",
      attrs: {
        classes: [
          "popup-style_primary",
          " popup-style_middle",
          "vehicle-popup",
          "vehicle-popup_width_xxllss",
        ],
      },
    },
    [
      _c("div", { staticClass: "vehicle-form" }, [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_extended-other",
          },
          [_vm._v("Закрыть объявление")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "vehicle-form__row vehicle-form__row_extended-either",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__group vehicle-form__group_width_full",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vehicle-form__label vehicle-form__label_base",
                  },
                  [
                    _c("div", { staticClass: "vehicle-form__label-flex" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__label-part vehicle-form__label-part_1",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__label-title" },
                            [_vm._v("Выберите причину")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "vehicle-form__label-part vehicle-form__label-part_2",
                      }),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "vehicle-form__field" }, [
                  _c(
                    "ul",
                    { staticClass: "vehicle-form__checkbox-list" },
                    _vm._l(_vm.closureReasons, function (reason) {
                      return _c(
                        "li",
                        {
                          key: reason.id,
                          staticClass: "vehicle-form__checkbox-item",
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "vehicle-form__checkbox-label" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "i-checkbox i-checkbox_radio i-checkbox_warning vehicle-form__checkbox vehicle-form__checkbox_base",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.closureReasonId,
                                        expression: "closureReasonId",
                                      },
                                    ],
                                    staticClass: "i-checkbox__real",
                                    attrs: { type: "radio", name: "reason" },
                                    domProps: {
                                      value: reason.id,
                                      checked: _vm._q(
                                        _vm.closureReasonId,
                                        reason.id
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.closureReasonId = reason.id
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "i-checkbox__faux",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__checkbox-text",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__checkbox-sign",
                                        },
                                        [_vm._v(_vm._s(reason.name))]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "vehicle-form__row vehicle-form__row_extended-either",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__group vehicle-form__group_width_full",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vehicle-form__label vehicle-form__label_base",
                  },
                  [
                    _c("div", { staticClass: "vehicle-form__label-flex" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__label-part vehicle-form__label-part_1",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__label-title" },
                            [_vm._v("Описание причины")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "vehicle-form__label-part vehicle-form__label-part_2",
                      }),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "vehicle-form__field" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_width_full",
                    },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.text,
                            expression: "text",
                          },
                        ],
                        staticClass:
                          "input-style input-style_primary input-style_small input-style_resize_vertical vehicle-form__input vehicle-form__input_width_full vehicle-form__input_min-height_xxxs vehicle-form__input_max-height_m",
                        class: { "input-style_error": _vm.error },
                        attrs: { maxlength: "500" },
                        domProps: { value: _vm.text },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.text = $event.target.value
                            },
                            _vm.onTextInput,
                          ],
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.error
              ? _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__line vehicle-form__line_condensed-other",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_auto",
                      },
                      [_vm._v(_vm._s(_vm.error))]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__control vehicle-form__control_condensed-complementary",
          },
          [
            _c(
              "a",
              {
                staticClass:
                  "button-style button-style_alter button-style_base vehicle-form__button",
                on: { click: _vm.onCloseClick },
              },
              [
                _vm._v("\n        Закрыть\n        "),
                _c("span", { staticClass: "helpers_hide_tablet" }, [
                  _vm._v("объявление"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "button-style button-style_either button-style_base vehicle-form__button",
                on: { click: _vm.closePopup },
              },
              [_vm._v("Отменить")]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }