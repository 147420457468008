import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';

@Mixin
export default class IsMobile extends Vue {
  protected isMobile: boolean = ((window as any).Onliner as any).isMobile;
  protected isMobileSpecial: boolean =
    this.isMobile && window.matchMedia('(max-width:1000px)').matches;

  private get isHighDensity() {
    return (
      (window.matchMedia &&
        (window.matchMedia(
          'only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)'
        ).matches ||
          window.matchMedia(
            'only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)'
          ).matches)) ||
      (window.devicePixelRatio && window.devicePixelRatio > 1.3)
    );
  }

  private get isRetina() {
    return (
      (window.matchMedia &&
        (window.matchMedia(
          'only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)'
        ).matches ||
          window.matchMedia(
            'only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)'
          ).matches)) ||
      (window.devicePixelRatio && window.devicePixelRatio >= 2)
    );
  }
}
