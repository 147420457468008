
import { Prop } from 'vue-property-decorator';
import { Mixins, Component } from 'vue-mixin-decorator';
import { Search } from '@/types/search';
import { findWhere, flatten, isArray } from 'underscore';
import { namespace } from 'vuex-class';

import camelizeService from '@/services/camelize-service';
import dictionariesService, {
  Dictionaries,
  Dictionary,
} from '@/services/dictionaries-service';
import dictionariesMap from '@/config/dictionaries-map';
import SavedSearchesSubitem from '@/components/search/saved-searches-subitem.vue';
import { VuexAction } from '@/types/functions';
import StateMixin from '@/mixins/state-service';
import { IUser } from '@/interfaces/common';
import plural from '@/filters/plural-form';
import { HashMap } from '@/types/common';
import { IFilter } from '@/interfaces/filters';

const commonStore = namespace('common');
const searchStore = namespace('search');
const userStore = namespace('user');

@Component({
  components: {
    SavedSearchesSubitem,
  },
  filters: {
    plural,
  },
})
export default class SavedSearchesItem extends Mixins<StateMixin>(StateMixin) {
  @Prop() id!: string;
  @Prop() newAdverts!: Number;
  @Prop() search!: Search;

  @commonStore.State dictionaries!: Dictionaries;
  @commonStore.Action setDictionary!: VuexAction;
  @userStore.State currentUser!: IUser;
  @searchStore.State filters!: Array<IFilter>;
  @searchStore.Action clearFilters!: VuexAction;
  @searchStore.Action setFilters!: VuexAction;
  @searchStore.Action updateList!: VuexAction;
  @searchStore.Action updateSearches!: VuexAction;
  @searchStore.Action deleteSearch!: VuexAction;

  private get title() {
    const parts: Array<string> = [];
    const car = this.search.car;

    if (car) {
      car.forEach(
        ({
          manufacturer,
          model,
        }: {
          manufacturer?: string;
          model?: string;
        }) => {
          const part = [];

          if (manufacturer) {
            const manufacturerObject = findWhere(
              this.dictionaries.manufacturer,
              {
                id: manufacturer,
              }
            );

            manufacturerObject && part.push(manufacturerObject.name);
          }

          if (model) {
            const modelObject = findWhere(this.dictionaries.model, {
              id: model,
            });

            modelObject && part.push(modelObject.name);
          }

          part.length && parts.push(part.join(' '));
        }
      );
    }

    if (parts.length) {
      return parts.join(', ');
    }

    return 'Любая марка';
  }

  private get items() {
    const { car, fuel, order, ...items } = this.search;
    let generation;

    if (this.search.car) {
      generation = flatten(
        this.search.car.map((item: any) => item.generation)
      ).filter((item: any) => item);
    }

    return generation && generation.length ? { generation, ...items } : items;
  }

  private get count() {
    return this.items ? Object.keys(this.items).length : 0;
  }

  private get hasNewAdverts() {
    return !!this.newAdverts;
  }

  private get query() {
    const { fuel, ...search } = this.search;

    return camelizeService.decamelize(search);
  }

  private get link() {
    return { name: 'search', query: this.query };
  }

  private onClick() {
    this.$router.push(this.link).catch((ex) => {});

    const filters = this.stateService.filters.filter((item) => {
      return item.name !== 'page';
    });
    const filtersObject: HashMap<any> = {};

    filters.forEach(({ name, value }) => {
      filtersObject[name] = value;
    });

    this.clearFilters();

    this.updateList({
      filters: filtersObject,
      callbacks: {
        success: ({ expand }: { expand: any }) => {
          const dictionaries: HashMap<Dictionary> = {};

          this.setFilters({ filters });
          this.$root.$emit('filterChanged');

          for (let key of Object.keys(expand)) {
            const dictionaryType: HashMap<Dictionary> = expand[key];

            for (let index of Object.keys(dictionaryType)) {
              if (dictionariesMap[key]) {
                const dictionaryName = dictionariesMap[key].parent!;
                const name = dictionariesMap[key].filterGroup;
                const filterValue = findWhere(this.filters, { name })!.value;
                const data: any = isArray(filterValue)
                  ? findWhere(filterValue, {
                      [dictionaryName]: parseInt(index),
                    })
                  : filterValue;

                const url = dictionariesService.buildUrl(dictionaryName, data);

                this.setDictionary({
                  rootName: `${key}`,
                  name: url,
                  dictionary: expand[key][index],
                });
              }
            }
          }
        },
      },
    });

    document.body.classList.remove('body_state-vehicle-form-save_opened');
  }

  private onDeleteClick() {
    this.deleteSearch({
      userId: this.currentUser.id,
      id: this.id,
      callbacks: {
        success: () => {
          this.updateSearches({
            id: this.currentUser.id,
          });
        },
      },
    });
  }
}
