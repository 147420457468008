import * as $ from 'jquery';

export default class ScrollService {
  protected static isMobile: boolean = ((window as any).Onliner as any)
    .isMobile;
  protected static isMobileSpecial: boolean =
    ScrollService.isMobile && window.matchMedia('(max-width:1000px)').matches;

  public static scrollTop(top: number, force: boolean) {
    const $window: JQuery<Window> = $(window);
    const scrollTop = $window.scrollTop();

    if (!force && scrollTop! <= top) {
      return;
    }

    $('html, body').animate(
      {
        scrollTop: top || 0,
      },
      180,
    );
  }

  public static scrollTo(selector: string) {
    const element = $(selector);
    const topElement = element.offset()!.top;
    const computedTop = ScrollService.isMobileSpecial
      ? topElement - 51
      : topElement;

    const top = element.length ? computedTop : 0;

    ScrollService.scrollTop(top, true);
  }
}
