var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "vehicle-form__package-item",
      class: {
        "vehicle-form__package-item_basic": _vm.feature.name === "auto_up",
        "vehicle-form__package-item_extended": _vm.feature.name === "sticky",
        "vehicle-form__package-item_maximum": _vm.feature.name === "premium",
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_condensed-specific",
        },
        [_vm._v("\n    " + _vm._s(_vm.feature.title) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vehicle-form__preview" },
        [
          _c("div", {
            staticClass: "vehicle-form__image",
            class: {
              "vehicle-form__image_autoup": _vm.feature.name === "auto_up",
              "vehicle-form__image_sticky": _vm.feature.name === "sticky",
              "vehicle-form__image_premium": _vm.feature.name === "premium",
            },
          }),
          _vm._v(" "),
          _vm._l(_vm.labels, function (item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass:
                  "button-style button-style_additional button-style_small button-style_noreflex vehicle-form__button vehicle-form__button_package-alter",
              },
              [_vm._v("\n      " + _vm._s(item.title) + "\n    ")]
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm._l(_vm.feature.details, function (item) {
        return _c("promote-feature-detail", {
          key: item.id,
          attrs: { item: item },
        })
      }),
      _vm._v(" "),
      _vm.feature.options
        ? [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_card",
              },
              [_vm._v("\n      " + _vm._s(_vm.stickersLabel) + "\n    ")]
            ),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "vehicle-form__checkbox-list" },
              _vm._l(_vm.feature.options.items, function (option) {
                return _c("promote-package-option", {
                  key: option.id,
                  attrs: {
                    option: option,
                    limit: _vm.feature.options.limit,
                    options: _vm.options,
                  },
                  on: {
                    "options-add": _vm.onOptionsAdd,
                    "options-remove": _vm.onOptionsRemove,
                  },
                })
              }),
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__control vehicle-form__control_condensed-alter",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__control-flex vehicle-form__control-flex_column-reverse",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__control-part vehicle-form__control-part_1 vehicle-form__control-part_vertical_middle",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "button-style button-style_primary button-style_middle vehicle-form__button",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "i-checkbox i-checkbox_radio i-checkbox_success vehicle-form__checkbox vehicle-form__checkbox_base",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selected,
                                expression: "selected",
                              },
                            ],
                            staticClass: "i-checkbox__real",
                            attrs: { type: "radio", name: "selectedFeature" },
                            domProps: {
                              value: _vm.feature.id,
                              checked: _vm._q(_vm.selected, _vm.feature.id),
                            },
                            on: {
                              change: function ($event) {
                                _vm.selected = _vm.feature.id
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "i-checkbox__faux" }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "vehicle-form__package-special",
                          }),
                        ]
                      ),
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.isSelected ? "Пакет выбран" : "Выбрать пакет"
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__control-part vehicle-form__control-part_2 vehicle-form__control-part_vertical_middle",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__title vehicle-form__title_huge-additional vehicle-form__title_price",
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.price) + "\n          "
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "vehicle-form__title vehicle-form__title_small vehicle-form__title_currency",
                        },
                        [_vm._v("р.")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "vehicle-form__title vehicle-form__title_small vehicle-form__title_time",
                        },
                        [_vm._v("за 3 дня")]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isSelected
        ? _c("ul", { staticClass: "vehicle-form__checkbox-list" }, [
            _c("li", { staticClass: "vehicle-form__checkbox-item" }, [
              _c("label", { staticClass: "vehicle-form__checkbox-label" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "i-checkbox i-checkbox_success vehicle-form__checkbox vehicle-form__checkbox_base vehicle-form__checkbox_font-weight_normal",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.recurrent,
                          expression: "recurrent",
                        },
                      ],
                      staticClass: "i-checkbox__real",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.recurrent)
                          ? _vm._i(_vm.recurrent, null) > -1
                          : _vm.recurrent,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.recurrent,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.recurrent = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.recurrent = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.recurrent = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "i-checkbox__faux" }),
                    _vm._v(" "),
                    _vm._m(0),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__checkbox-text" }, [
      _c("div", { staticClass: "vehicle-form__checkbox-sign" }, [
        _vm._v("\n              Автоматически продлевать пакет\n            "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }