class NotificationsService {
  private static instance: NotificationsService;
  private notifier!: {
    success: (text: string) => void;
    error: (text: string) => void;
  };

  constructor() {
    const $ = (window as any).$;

    if (!$) {
      return;
    }

    const notifier = ($ as any).notifications;

    this.notifier = notifier;
  }

  public success(text: string) {
    this.notifier.success(text);
  }

  public error(text: string) {
    this.notifier.error(text);
  }

  public static getInstance() {
    if (!NotificationsService.instance) {
      NotificationsService.instance = new NotificationsService();
    }

    return NotificationsService.instance;
  }
}

export default NotificationsService.getInstance();
