import { HashMap } from '@/types/common';

export type SocialConfig = {
  popupUrl: string;
  popupWidth: number;
  popupHeight: number;
};

export default {
  facebook: {
    popupUrl:
      'https://www.facebook.com/sharer/sharer.php?u={url}&picture={image}',
    popupWidth: 600,
    popupHeight: 359,
  },
  twitter: {
    popupUrl: 'https://twitter.com/intent/tweet?url={url}&text={title}',
    popupWidth: 600,
    popupHeight: 250,
  },
  vkontakte: {
    popupUrl: 'https://vk.com/share.php?url={url}&title={title}&image={image}',
    popupWidth: 655,
    popupHeight: 450,
  },
  odnoklassniki: {
    popupUrl:
      'https://connect.ok.ru/offer?url={url}&title={title}&imageUrl={imageUrl}&description={description}',
    popupWidth: 580,
    popupHeight: 336,
  },
  telegram: {
    popupUrl: 'https://t.me/share/url?url={url}&text={title}',
    popupWidth: 580,
    popupHeight: 336,
  },
} as HashMap<SocialConfig>;
