
import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { intersection } from 'underscore';

import { HashMap } from '@/types/common';
import errorMap from '@/config/steps-error-map';

const createStore = namespace('create');

@Component
export default class StepWrapper extends Vue {
  @Prop() step!: string;

  @createStore.State steps!: HashMap<string>;
  @createStore.State errors!: HashMap<any>;

  private get statusClass() {
    const status = this.steps[this.step];

    if (this.hasErrors) {
      return 'vehicle-form__step-item_alter';
    }

    if (status === 'complete') {
      return 'vehicle-form__step-item_primary';
    }

    if (status === 'current' || status === 'edit') {
      return 'vehicle-form__step-item_secondary';
    }

    return 'vehicle-form__step-item_additional';
  }

  private get hasErrors() {
    const errors: Array<string> = errorMap[this.step];
    const currentErrors = Object.keys(this.errors);

    return !!intersection(errors, currentErrors).length;
  }
}
