
  import { Mixins, Component } from 'vue-mixin-decorator';
  import { findWhere } from 'underscore';

  import { Dictionaries } from '@/services/dictionaries-service';
  import FieldMixin from '@/mixins/reviews/create/field';
  import DropdownMixin from '@/mixins/common/dropdown';
  import { Prop } from 'vue-property-decorator';

  interface IMixins extends FieldMixin, DropdownMixin {}

  @Component
  export default class StepTechBodyType extends Mixins<IMixins>(
    DropdownMixin,
    FieldMixin,
  ) {
    @Prop() dictionaries!: Dictionaries;

    private onOpen() {
      this.openDropdown();

      document.body.classList.add('body_state-dropdown-style_visible');
    }

    private onClose() {
      this.closeDropdown();

      document.body.classList.remove('body_state-dropdown-style_visible');
    }

    private get bodyType() {
      return this.data.specs.bodyType;
    }

    private set bodyType(bodyType) {
      this.onFieldChange(bodyType);
    }

    private get bodyTypeName() {
      const bodyType = findWhere(this.dictionaries.bodyType, {
        id: this.bodyType,
      });

      if (!bodyType) {
        return 'Выберите';
      }

      return bodyType.name;
    }

    private onChange() {
      this.onClose();
    }
  }
