
  import { Component, Prop } from 'vue-property-decorator';
  import { Mixins } from 'vue-mixin-decorator';

  import StateServiceMixin from '@/mixins/state-service';
  import InterfacePropsMixin from '@/mixins/search/interface-props';
  import { range } from '@/services/utils-service';

  interface IMixins extends StateServiceMixin, InterfacePropsMixin {}

  @Component
  export default class FilterGroup extends Mixins<IMixins>(
    StateServiceMixin,
    InterfacePropsMixin,
  ) {
    @Prop() label!: string;
    @Prop() name!: string;
    @Prop() placeholder!: string;
    @Prop() groupMax!: number;
    @Prop() globalEmit!: string;

    private count: number = 1;

    public created() {
      const filter = this.getFilter(this.name);

      if (filter.value) {
        this.count = (filter.value as Array<any>).length;
      }

      this.initEvents();
    }

    private destroyed() {
      this.$root.$off('filterChanged', this.onFilterChanged);
    }

    private initEvents() {
      this.$root.$on('filterChanged', this.onFilterChanged);
    }

    private get indexes() {
      return range(0, this.count);
    }

    private get canAddGroup() {
      return this.count < this.groupMax;
    }

    private addFilter() {
      this.count++;
    }

    private onFilterRemove(index: number) {
      this.removeFilter({
        rootName: this.name,
        groupIndex: index,
      });

      this.stateService.setState(this.filters);
      this.updateList({
        callbacks: {
          success: () => {
            this.$root.$emit('filterChanged');

            this.globalEmit && this.$root.$emit(this.globalEmit);
          },
        },
      });

      this.count--;
    }

    private onFilterChanged() {
      const filter = this.getFilter(this.name);
      const length = (filter.value as Array<any>).length;

      if (length > this.count) {
        this.count = length;
      }
    }
  }
