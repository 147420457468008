
  import { Component, Prop } from 'vue-property-decorator';
  import { Mixins } from 'vue-mixin-decorator';

  import FieldMixin from '@/mixins/reviews/create/field';
  import GradesMixin from '@/mixins/reviews/grades';
  import { IMark } from '@/interfaces/reviews/grade';
  import Grade from '@/components/reviews/create/step-description/grade.vue';
  import RatingStars from '@/components/common/rating-reviews/rating-stars.vue';

  interface IMixins extends FieldMixin, GradesMixin {}

  @Component({
    components: {
      RatingStars,
      Grade,
    },
  })
  export default class StepDescriptionGrades extends Mixins<IMixins>(
    FieldMixin,
    GradesMixin,
  ) {
    @Prop() primaryMarks!: Array<IMark>;

    private get grades() {
      return this.data.rating || {};
    }

    private get isGradesSet() {
      return Object.keys(this.grades).length === this.primaryMarks.length;
    }

    private onGradeChange(property, grade) {
      this.$set(this.grades, property, grade);

      const { grades, avgGrade } = this;

      this.onFieldChange({ grades, avgGrade });
    }
  }
