
  import { Vue, Emit, Prop, Component } from 'vue-property-decorator';

  import { VuexAction } from '@/types/functions';
  import { ITag } from '@/interfaces/search';
  import {
    IFilterConfigItem,
    getPlainFilters,
    IFilterChunk,
  } from '@/config/filters';
  import { find, findIndex, findWhere, isArray } from 'underscore';
  import { IFilter } from '@/interfaces/filters';

  @Component
  export default class SearchTag extends Vue {
    @Prop() tag!: ITag;
    @Prop() filters!: Array<IFilter>;
    @Prop() globalEmit!: string;
    @Prop() removeFilter!: VuexAction;
    @Prop() updateList!: VuexAction;

    @Emit('removed')
    private onRemoveClick() {
      const config = this.getFilterConfig(this.tag.rootName);
      const filter = findWhere(this.filters, { name: this.tag.rootName });

      if (!filter) {
        return;
      }

      const initialValueLength = isArray(filter.value)
        ? filter.value.length
        : null;

      if (config && config.typeChain) {
        const typeChain = [...config.typeChain];

        let initialIndex = findIndex(typeChain, { name: this.tag.name });
        let index = initialIndex;

        do {
          const chunk: IFilterChunk = typeChain[index] as IFilterChunk;

          if (!chunk) {
            return;
          }

          const { name, multi } = chunk;

          this.removeFilter({
            name,
            rootName: this.tag.rootName,
            groupIndex: this.tag.groupIndex,
            value: this.tag.value,
            removeAll: index !== initialIndex,
          });
          this.$root.$emit('tag-clear', this.tag.rootName);

          index++;
        } while (
          typeChain[index] &&
          (!initialValueLength ||
            initialValueLength === (filter.value as Array<any>).length)
        );
      } else {
        this.removeFilter({
          name: this.tag.name,
          rootName: this.tag.rootName,
          groupIndex: this.tag.groupIndex,
          value: this.tag.value,
        });
        this.$root.$emit('tag-clear', this.tag.rootName);
      }

      this.updateList({
        callbacks: {
          success: () => {
            this.globalEmit && this.$root.$emit(this.globalEmit);

            this.$root.$emit('filterChanged');
          },
        },
      });
    }

    private getFilterConfig(name: string) {
      return find(getPlainFilters(), (filter: IFilterConfigItem) => {
        return filter.name === name;
      });
    }
  }
