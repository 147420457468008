var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.notificationsSettings
    ? _c("div", { staticClass: "vehicle-form__row" }, [
        _c(
          "div",
          { staticClass: "vehicle-form__group vehicle-form__group_width_full" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__field" }, [
              _c(
                "div",
                {
                  ref: "handle",
                  staticClass:
                    "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_width_full",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "input-style input-style_primary input-style_small input-style_arrow_right vehicle-form__input vehicle-form__input_width_full",
                      attrs: { title: _vm.channelsTitle },
                      on: { click: _vm.toggle },
                    },
                    [
                      _c("div", { staticClass: "input-style__faux" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.channelsTitle) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-style__real" }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-style dropdown-style_primary dropdown-style_small dropdown-style_left-top vehicle-form__dropdown vehicle-form__dropdown_width_ssm",
                      class: { "dropdown-style_visible": _vm.isVisible },
                    },
                    [
                      _vm.notificationsSettings
                        ? _c(
                            "div",
                            { staticClass: "dropdown-style__container" },
                            [
                              _c(
                                "div",
                                { staticClass: "dropdown-style__content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "dropdown-style__group" },
                                    [
                                      _c(
                                        "ul",
                                        { staticClass: "dropdown-style__list" },
                                        _vm._l(
                                          _vm.notificationsSettings.channels,
                                          function (item) {
                                            return _c(
                                              "li",
                                              {
                                                key: item.value,
                                                staticClass:
                                                  "dropdown-style__item dropdown-style__item_indent",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "dropdown-style__checkbox-label",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "i-checkbox i-checkbox_warning dropdown-style__checkbox",
                                                      },
                                                      [
                                                        _c("input", {
                                                          staticClass:
                                                            "i-checkbox__real",
                                                          attrs: {
                                                            type: "checkbox",
                                                          },
                                                          domProps: {
                                                            checked:
                                                              _vm
                                                                .notificationsSettings
                                                                .values
                                                                .channels &&
                                                              _vm.notificationsSettings.values.channels.indexOf(
                                                                item.value
                                                              ) !== -1,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.onChannelChange(
                                                                item.value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("div", {
                                                          staticClass:
                                                            "i-checkbox__faux",
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "dropdown-style__checkbox-text",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "dropdown-style__checkbox-sign",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            " +
                                                                    _vm._s(
                                                                      item.title
                                                                    ) +
                                                                    "\n                          "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "dropdown-style__group" },
                                    [
                                      _c(
                                        "ul",
                                        { staticClass: "dropdown-style__list" },
                                        _vm._l(
                                          _vm.notificationsSettings.periods,
                                          function (item) {
                                            return _c(
                                              "li",
                                              {
                                                key: item.value,
                                                staticClass:
                                                  "dropdown-style__item dropdown-style__item_indent",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "dropdown-style__checkbox-label",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "i-checkbox i-checkbox_warning i-checkbox_radio dropdown-style__checkbox",
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value: _vm.period,
                                                              expression:
                                                                "period",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "i-checkbox__real",
                                                          attrs: {
                                                            type: "radio",
                                                            name: "radioNotifications",
                                                          },
                                                          domProps: {
                                                            value: item.value,
                                                            checked: _vm._q(
                                                              _vm.period,
                                                              item.value
                                                            ),
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              _vm.period =
                                                                item.value
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("div", {
                                                          staticClass:
                                                            "i-checkbox__faux",
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "dropdown-style__checkbox-text",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "dropdown-style__checkbox-sign",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            " +
                                                                    _vm._s(
                                                                      item.title
                                                                    ) +
                                                                    "\n                          "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "dropdown-style__bottom helpers_show_tablet",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "button-style button-style_additional button-style_small dropdown-style__button",
                              on: { click: _vm.closeDropdown },
                            },
                            [_vm._v("\n              Готово\n            ")]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("\n            Уведомления"),
                _c("br"),
                _vm._v("\n            о новых объявлениях\n          "),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }