export const range = (start: number, end: number): Array<number> => {
  const range = [];

  for (let i = start; i < end; i++) {
    range.push(i);
  }
  return range;
};

export const roundTo = (value: number, target: number) => {
  return Math.round(value / target) * target;
};
