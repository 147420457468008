var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "container", staticClass: "vehicle-form__feature-item" },
    [
      _c(
        "div",
        {
          ref: "contacts",
          staticClass: "vehicle-form__feature-box",
          class: {
            "vehicle-form__feature-box_fixed": _vm.isFixed,
            "vehicle-form__feature-box_hanged": _vm.isHanged,
          },
        },
        [
          _c(
            "div",
            { staticClass: "vehicle-form__person helpers_hide_tablet" },
            [
              _c("div", { staticClass: "vehicle-form__person-list" }, [
                _c(
                  "div",
                  { staticClass: "vehicle-form__person-item" },
                  [
                    _c("div", { staticClass: "vehicle-form__preview" }, [
                      _c("div", {
                        staticClass: "vehicle-form__image",
                        style: _vm._f("background")(_vm.avatar),
                      }),
                      _vm._v(" "),
                      _vm.advert.author.onlineStatus &&
                      _vm.advert.author.onlineStatus.isOnline
                        ? _c("div", {
                            staticClass:
                              "vehicle-form__status vehicle-form__status_online",
                          })
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm.seller.type === "individual"
                      ? [
                          _vm.advert.permissions.viewProfile
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                                  attrs: {
                                    target: "_blank",
                                    href: _vm.profileLink,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.seller.name))]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.seller.name) +
                                      "\n            "
                                  ),
                                ]
                              ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.seller.type === "dealer"
                      ? [
                          _vm.advert.permissions.viewProfile
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                                  attrs: {
                                    target: "_blank",
                                    href: _vm.profileLink,
                                  },
                                },
                                [
                                  _vm._v(
                                    "Автодилер «" +
                                      _vm._s(_vm.seller.name) +
                                      "»"
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                                },
                                [
                                  _vm._v(
                                    "\n              Автодилер «" +
                                      _vm._s(_vm.seller.name) +
                                      "»\n            "
                                  ),
                                ]
                              ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.seller.type === "autohaus"
                      ? [
                          _vm.advert.permissions.viewProfile
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                                  attrs: {
                                    target: "_blank",
                                    href: _vm.profileLink,
                                  },
                                },
                                [
                                  _vm._v(
                                    "Автохаус «" + _vm._s(_vm.seller.name) + "»"
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
                                },
                                [
                                  _vm._v(
                                    "\n              Автохаус «" +
                                      _vm._s(_vm.seller.name) +
                                      "»\n            "
                                  ),
                                ]
                              ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.seller.type === "individual"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny vehicle-form__description_condensed-other",
                          },
                          [_vm._v("\n            Частное лицо\n          ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.seller.type === "dealer" ||
                    _vm.seller.type === "autohaus"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny vehicle-form__description_condensed-other",
                          },
                          [
                            _vm._v(
                              "\n            УНП: " +
                                _vm._s(_vm.seller.unp) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.ipData
                      ? [
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny vehicle-form__description_condensed-other",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.ipData.country) +
                                  ", " +
                                  _vm._s(_vm.ipData.city) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny vehicle-form__description_condensed-other",
                            },
                            [
                              _vm._v(
                                "\n              IP: " +
                                  _vm._s(_vm.ipData.ip) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base-alter vehicle-form__link_enter",
                            attrs: {
                              to: {
                                name: "active-adverts",
                                params: { id: _vm.author.id },
                              },
                            },
                          },
                          [_vm._v("Все объявления продавца")]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "device-class",
                  rawName: "v-device-class",
                  value: [
                    {
                      device: "iphonex",
                      deviceClass: "vehicle-form__control_bar_alter",
                    },
                    {
                      device: "iphonexsmax",
                      deviceClass: "vehicle-form__control_bar_alter",
                    },
                    {
                      device: "iphonexr",
                      deviceClass: "vehicle-form__control_bar_alter",
                    },
                  ],
                  expression:
                    "[\n        { device: 'iphonex', deviceClass: 'vehicle-form__control_bar_alter' },\n        {\n          device: 'iphonexsmax',\n          deviceClass: 'vehicle-form__control_bar_alter',\n        },\n        {\n          device: 'iphonexr',\n          deviceClass: 'vehicle-form__control_bar_alter',\n        },\n      ]",
                },
              ],
              staticClass:
                "vehicle-form__control vehicle-form__control_condensed vehicle-form__control_bar",
              class: {
                "vehicle-form__control_bar_opened":
                  _vm.isPhoneVisible || _vm.isOwnAdvert,
              },
            },
            [
              _vm.seller.phones && _vm.seller.phones.length
                ? [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "gtm",
                            rawName: "v-gtm",
                            value: { event: "show_contacts" },
                            expression: "{ event: 'show_contacts' }",
                          },
                        ],
                        staticClass:
                          "button-style button-style_primary button-style_big vehicle-form__button vehicle-form__button_phone vehicle-form__button_width_full",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onShowNumberClick.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "helpers_hide_tablet" }, [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__button-sign" },
                            [_vm._v("Показать телефон")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "vehicle-form__button-value" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm._f("phone")(_vm.mainPhone)) +
                                  "\n              "
                              ),
                              !_vm.isPhoneVisible && !_vm.isOwnAdvert
                                ? _c(
                                    "span",
                                    { staticClass: "vehicle-form__button-dot" },
                                    [_vm._v(" • • •  • •  • •")]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "helpers_show_tablet" }, [
                          _vm._v("Позвонить"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.isMobileSpecial
                      ? _c(
                          "div",
                          {
                            staticClass: "vehicle-form__expand",
                            class: {
                              "vehicle-form__expand_opened":
                                _vm.isAllPhonesVisible &&
                                _vm.otherPhones.length,
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__expand-header" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "vehicle-form__phone" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "vehicle-form__phone-list",
                                      },
                                      [
                                        _c("advert-contacts-list-item", {
                                          attrs: {
                                            "phone-number": _vm.mainPhone,
                                            call: _vm.seller.call,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "vehicle-form__expand-body" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "vehicle-form__phone" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "vehicle-form__phone-list",
                                      },
                                      _vm._l(
                                        _vm.otherPhones,
                                        function (item, index) {
                                          return _c(
                                            "advert-contacts-list-item",
                                            {
                                              key: index,
                                              attrs: {
                                                "phone-number": item,
                                                call: _vm.seller.call,
                                              },
                                            }
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.otherPhones.length
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "vehicle-form__expand-footer",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base-alter",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.onToggleAllClick.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vehicle-form__expand-text vehicle-form__expand-text_on",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  Ещё\n                  " +
                                                    _vm._s(
                                                      _vm._f("plural")(
                                                        _vm.otherPhones.length,
                                                        [
                                                          "телефон",
                                                          "телефона",
                                                          "телефонов",
                                                        ]
                                                      )
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vehicle-form__expand-text vehicle-form__expand-text_off",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  Скрыть\n                "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              !_vm.isOwnAdvert || !_vm.isMobileSpecial
                ? _c(
                    "div",
                    {
                      staticClass:
                        "popover-style__handle vehicle-form__popover-handle popover-style__handle_opened vehicle-form__popover-handle_width_full",
                    },
                    [
                      !_vm.isOwnAdvert
                        ? _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "gtm",
                                  rawName: "v-gtm",
                                  value: { event: "send_message" },
                                  expression: "{ event: 'send_message' }",
                                },
                              ],
                              staticClass:
                                "button-style button-style_appendant button-style_base vehicle-form__button vehicle-form__button_message vehicle-form__button_width_full",
                              attrs: { target: "_blank", href: _vm.chatLink },
                            },
                            [
                              _vm._v("\n          Написать\n          "),
                              _c(
                                "span",
                                { staticClass: "helpers_hide_tablet" },
                                [_vm._v("продавцу")]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.seller.chatPreferred
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "popover-style popover-style_specific popover-style_secondary popover-style_small popover-style_bottom popover-style_text_center popover-style_noshadow vehicle-form__popover vehicle-form__popover_width_full helpers_hide_tablet",
                            },
                            [_vm._m(0)]
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          !_vm.isMobileSpecial
            ? _c("banner-web", { attrs: { "margin-left": "10" } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("advert-contacts-popup", {
        ref: "contactsPopup",
        attrs: {
          seller: _vm.seller,
          author: _vm.author,
          "ip-data": _vm.advert.ipData,
          location: _vm.advert.location,
          permissions: _vm.advert.permissions,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "popover-style__content" }, [
      _c("div", { staticClass: "vehicle-form" }, [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base",
          },
          [
            _vm._v(
              "\n                Хочу общаться только в чатах\n              "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "tooltip-style tooltip-style_error tooltip-style_small tooltip-style_bottom-left tooltip-style_text_left tooltip-style_noarrow tooltip-style_visible vehicle-form__tooltip vehicle-form__tooltip_width_full helpers_hide_tablet",
      },
      [
        _c("div", { staticClass: "tooltip-style__content" }, [
          _c("div", { staticClass: "vehicle-form" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_info-special",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_font-weight_semibold vehicle-form__description_condensed",
                  },
                  [
                    _vm._v(
                      "\n              Советы по безопасности\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                  },
                  [
                    _vm._v(
                      "\n              Не вносите предоплату за просмотр\n              автомобиля и заключайте сделки\n              при личной встрече\n            "
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }