var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__card-part vehicle-form__card-part_data" },
    [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_condensed-other",
        },
        [
          _c("div", { staticClass: "vehicle-form__title-flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__title-part vehicle-form__title-part_1",
              },
              [
                _c(
                  "h1",
                  {
                    staticClass:
                      "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_condensed-other",
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.review.summary) + "\n        "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__title-part vehicle-form__title-part_2",
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "device-class",
                        rawName: "v-device-class",
                        value: [
                          {
                            device: "iphonex",
                            deviceClass: "vehicle-form__control_bar_alter",
                          },
                          {
                            device: "iphonexsmax",
                            deviceClass: "vehicle-form__control_bar_alter",
                          },
                          {
                            device: "iphonexr",
                            deviceClass: "vehicle-form__control_bar_alter",
                          },
                        ],
                        expression:
                          "[\n            {\n              device: 'iphonex',\n              deviceClass: 'vehicle-form__control_bar_alter',\n            },\n            {\n              device: 'iphonexsmax',\n              deviceClass: 'vehicle-form__control_bar_alter',\n            },\n            {\n              device: 'iphonexr',\n              deviceClass: 'vehicle-form__control_bar_alter',\n            },\n          ]",
                      },
                    ],
                    staticClass:
                      "vehicle-form__control vehicle-form__control_bar helpers_hide_tablet",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "gtm",
                            rawName: "v-gtm",
                            value: { event: "create_review" },
                            expression: "{ event: 'create_review' }",
                          },
                        ],
                        staticClass:
                          "button-style button-style_appendant button-style_base vehicle-form__button vehicle-form__button_width_full",
                        attrs: { to: { name: "create-review" } },
                      },
                      [_vm._v("\n            Написать отзыв\n          ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "vehicle-form__intro" }, [
        _c("div", { staticClass: "vehicle-form__intro-list" }, [
          _c("div", { staticClass: "vehicle-form__intro-item" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm._f("relativeDate")(_vm.review.statusChangedAt)) +
                    "\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__intro-item" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_other vehicle-form__description_small vehicle-form__description_eye",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.review.stats.views.total) +
                    "\n          "
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_success vehicle-form__description_small",
                  },
                  [
                    _vm._v(
                      "+ " +
                        _vm._s(_vm.review.stats.views.today) +
                        " за сегодня"
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.commentsCounter !== null
            ? _c("div", { staticClass: "vehicle-form__intro-item" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "vehicle-form__link vehicle-form__link_primary vehicle-form__link_small vehicle-form__link_comments",
                        on: { click: _vm.onCommentsClick },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("plural")(_vm.commentsCounter, [
                                "комментарий",
                                "комментария",
                                "комментариев",
                              ])
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }