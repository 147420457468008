import { MutationTree } from 'vuex';

import { IPromoteState } from '@/interfaces/store';
import { ICounters, IPage } from '@/interfaces/search';
import { Features, Entities, Periods } from '@/types/promote';
import { PaymentMethods, PaymentMethod } from '@onlinerby/js-api-clients';
import { EncryptedCardData } from '@onlinerby/js-vue-components';

const mutations: MutationTree<IPromoteState> = {
  setLoading(state, isLoading: boolean) {
    state.isLoading = isLoading;
  },

  setPage(state, page: IPage) {
    state.page = page;
  },

  setFeatures(state, features: Features) {
    state.features = features;
  },

  setPayments(state, paymentMethods: PaymentMethods) {
    state.paymentMethods = paymentMethods;
  },

  setEntities(state, entities: Entities) {
    state.entities = entities;
  },

  setPeriods(state, periods: Periods) {
    state.periods = periods;
  },

  setSelectedFeature(state, feature) {
    state.selectedFeature = { ...state.selectedFeature, ...feature };
  },

  setSelectedPayment(state, paymentMethod: PaymentMethod) {
    state.selectedPaymentMethod = paymentMethod;
  },

  setPaymentProcessing(state, isProcessing) {
    state.isPaymentProcessing = isProcessing;
  },

  setRecurrent(state, isRecurrent) {
    state.isRecurrent = isRecurrent;
  },

  setTotal(state, total) {
    state.total = total;
  },

  setGeneralCounters(state, counters: ICounters) {
    state.generalCounters = counters;
  },

  setManufacturerId(state, id) {
    state.manufacturerId = id;
  },

  setModelId(state, id) {
    state.modelId = id;
  },

  setShowNonPremiumOnly(state, showNonPremiumOnly) {
    state.showNonPremiumOnly = showNonPremiumOnly;
  },
};

export default mutations;
