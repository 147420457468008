
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import { debounce } from 'underscore';
  import { CreateElement, VNode } from 'vue';
  import { ScrollListConfig } from '@/types/comments';

  @Component
  export default class ScrollList extends Vue {
    @Prop({ required: true }) config!: ScrollListConfig;

    @Prop({ default: 'div' })
    rtag!: string;
    @Prop({ default: 'div' })
    wtag!: string;
    @Prop({ default: '' })
    wclass!: string;
    @Prop({ default: 0 })
    start!: number;

    @Prop({ default: 0 })
    elementHeightCorrectionDesktop!: number;
    @Prop({ default: 0 })
    elementHeightCorrectionMobile!: number;
    @Prop({ default: 100 })
    correctionTreshhold!: number;

    private size: number = this.config.size;
    private remain: number = this.config.remain;
    private step: number = this.config.step;
    private bench: number = this.config.bench;
    private offsetCorrection: number = this.config.offsetCorrection;

    private heights: number[] = [];
    private lastOvers: number = this.start >= this.remain ? this.start : 0;
    private delta = {
      start: this.lastOvers, // start index.
      keeps: this.remain + (this.bench || this.remain), // nums keeping in real dom.
      end: this.start + this.remain + (this.bench || this.remain) - 1, // end index.
      total: 0, // all items count, update in filter.
      paddingTop: 0, // container wrapper real padding-top.
      paddingBottom: 0,
    };
    private marginBottom: number = 0;
    private forceScroll: boolean = false;
    private updateInProgress: boolean = false;
    private onScrollCallback: Function | null = null;
    private branches!: HTMLCollection;

    private mounted() {
      const debouncedUpdate = debounce(() => {
        this.updateVisibleList();
      }, 40);

      this.branches = this.$el.firstElementChild?.children!;

      $(window).on('scroll', debouncedUpdate);

      setInterval(this.updateVisibleList, 300);

      this.marginBottom = parseInt(
        $(this.branches).first().css('margin-bottom'),
        10,
      );

      this.storeHeights();
    }

    private updated() {
      this.storeHeights();
    }

    private render(h: CreateElement): VNode {
      const list = this.filter();
      const delta = this.delta;

      return h(
        this.rtag,
        {
          ref: 'vsl',
          style: {
            display: 'block',
          },
        },
        [
          h(
            this.wtag,
            {
              style: {
                display: 'block',
                position: 'relative',
                'padding-top': delta.paddingTop + 'px',
                'padding-bottom': delta.paddingBottom + 'px',
              },
              class: this.wclass,
            },
            list,
          ),
        ],
      );
    }

    private updateZone(offset: number, force: boolean) {
      const overs = this.getOvers(offset);

      if (!force && overs === null) {
        return;
      }

      const delta = this.delta;
      const zone = this.getZone(overs);
      const bench = this.bench || this.remain;

      if (
        !force &&
        !zone.isLast &&
        overs > delta.start &&
        overs - delta.start <= bench
      ) {
        this.$nextTick(() => {
          this.runScrollCallback();
        });

        return;
      }

      if (!force && overs === this.lastOvers) {
        this.runScrollCallback();

        return;
      }

      const shouldUpdate =
        Math.abs(overs - this.lastOvers) >= this.step ||
        (delta.total - overs < this.step &&
          delta.total - this.lastOvers > this.step) ||
        (overs < this.step && this.lastOvers > this.step);

      if (force || shouldUpdate) {
        delta.end = zone.end;
        delta.start = zone.start;

        const target = this.getTopVisibleItem();
        const initialPosition = $(window).scrollTop();

        this.$forceUpdate();

        this.lastOvers = overs;

        target && initialPosition && this.runCorrection(target, initialPosition);
      }
    }

    private getZone(index: number) {
      const delta = this.delta;
      let start, end;

      index = Math.max(0, index);

      const lastStart = delta.total - delta.keeps;
      const isLast =
        (index <= delta.total && index >= lastStart) || index > delta.total;

      if (isLast) {
        start = Math.max(0, lastStart);
        end = delta.total - 1;
      } else {
        start = index;
        end = start + delta.keeps - 1;
      }

      return {
        end,
        start: Math.max(0, start - delta.keeps),
        isLast,
      };
    }

    private filter() {
      const delta = this.delta;
      let slots = this.$slots.default;

      if (!slots) {
        slots = [];
        delta.start = 0;
      }

      delta.total = slots.length;

      const layout = this.getLayout();

      delta.paddingTop = layout.paddingTop;
      delta.paddingBottom = layout.paddingBottom;

      if (delta.total < delta.keeps) {
        delta.end = delta.keeps - 1;
      }

      return slots.filter(function (slot, index) {
        return index >= delta.start && index <= delta.end;
      });
    }

    private updateVisibleList(force = false) {
      if (this.forceScroll) {
        this.forceScroll = false;

        return;
      }

      if (this.updateInProgress) {
        return;
      }

      this.updateInProgress = true;

      const delta = this.delta;
      const vslOffset = $(this.$refs.vsl).offset();

      if (!vslOffset) {
        return;
      }

      const offset = window.pageYOffset - vslOffset.top;

      if (delta.total > delta.keeps) {
        this.updateZone(offset, force);
      }

      this.$nextTick(() => {
        this.runScrollCallback();
      });

      this.updateInProgress = false;
    }

    private storeHeights() {
      Array.from(this.branches).forEach((branch: Element) => {
        const branchIndex = parseInt((branch as HTMLElement).dataset.index!);

        if (branchIndex) {
          this.heights[branchIndex] = this.getFullHeight(branch);
        }
      });
    }

    private getTopVisibleItem() {
      let topItem;

      const scrollTop = $(window).scrollTop()!;

      Array.from(this.branches).forEach(branch => {
        if ($(branch)!.offset()!.top > scrollTop) {
          topItem = branch;

          return false;
        }

        return;
      });

      return topItem;
    }

    private getCorrection(target: HTMLElement) {
      const line = $(target || $(this.branches).last())!.offset()!.top;

      let sum = 0;

      Array.from(this.branches).forEach(branch => {
        const index = $(branch).data('index');
        const fullHeight = this.getFullHeight(branch);

        if ($(branch)!.offset()!.top < line) {
          sum += this.heights[index]
            ? fullHeight - this.heights[index]
            : fullHeight - this.size;
        }

        this.heights[index] = fullHeight;
      });

      return sum;
    }

    private getFullHeight(item: Element) {
      if (!item) {
        return 0;
      }

      return item.clientHeight + this.marginBottom;
    }

    private getOvers(offset: number) {
      let sum = 0;
      let index = -1;

      if (offset > (this.$refs.vsl as HTMLElement).offsetHeight) {
        return this.delta.total - this.delta.keeps;
      }

      while (sum <= Math.max(0, offset + this.offsetCorrection)) {
        sum += this.heights[++index] || this.size;
      }

      return index;
    }

    private getLayout() {
      const delta = this.delta;
      const hasPadding = delta.total > delta.keeps;

      let paddingTop = 0;
      let paddingBottom = 0;

      hasPadding &&
        this.createArray(0, delta.start).forEach(index => {
          paddingTop += this.heights[index] || this.size;
        });

      paddingTop = Math.max(paddingTop, 0);

      hasPadding &&
        this.createArray(delta.end, delta.total - 1).forEach(index => {
          paddingBottom += this.heights[index] || this.size;
        });

      paddingBottom = Math.max(paddingBottom, 0);

      return {
        paddingTop,
        paddingBottom,
      };
    }

    private createArray(start: number, end: number) {
      const array = [];

      for (let i = 0; i < end; i++) {
        array.push(i);
      }

      return end < 0 ? [] : array.slice(start);
    }

    private runScrollCallback() {
      if (this.onScrollCallback) {
        this.onScrollCallback();
        this.onScrollCallback = null;
      }
    }

    private runCorrection(target: HTMLElement, initialPosition: number) {
      this.$nextTick(() => {
        const correction = this.getCorrection(target);
        const currentPosition = $(window).scrollTop()!;

        if (
          Math.abs(initialPosition + correction - currentPosition) <
          this.correctionTreshhold
        ) {
          return;
        }

        this.forceScroll = true;

        correction && $(window).scrollTop($(window).scrollTop()! + correction);

        this.$nextTick(() => {
          this.runScrollCallback();
        });
      });
    }

    public goToIndex(index: number, callback?: Function) {
      let scroll = $(this.$refs.vsl)!.offset()!.top;

      for (let i = 0; i < index; i++) {
        scroll += this.heights[i] || this.size;
      }

      $(window).scrollTop(scroll - this.offsetCorrection);

      this.onScrollCallback = callback || null;

      this.updateVisibleList();
    }

    @Watch('start')
    onStartChange(index: number) {
      index >= 0 && this.goToIndex(index);
    }

    @Watch('delta.total')
    onDeltaTotalChange(value: number, oldValue: number) {
      if (value !== oldValue) {
        this.updateVisibleList(true);
      }
    }
  }
