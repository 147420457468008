
import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { findWhere } from 'underscore';

import { sectionsDictionary } from '@/config/equipment';
import StepExteriorEquipmentCheckbox from '@/components/create/step-exterior/step-exterior-equipment-checkbox.vue';
import StepExteriorEquipmentDropdown from '@/components/create/step-exterior/step-exterior-equipment-dropdown.vue';
import StepExteriorEquipmentMultipleDropdown from '@/components/create/step-exterior/step-exterior-equipment-multiple-dropdown.vue';
import { Dictionary, Dictionaries } from '@/services/dictionaries-service';
import camelize from '@/filters/format-camelize';

const commonStore = namespace('common');

@Component({
  components: {
    StepExteriorEquipmentCheckbox,
    StepExteriorEquipmentDropdown,
    StepExteriorEquipmentMultipleDropdown,
  },
})
export default class StepExteriorEquipmentSection extends Vue {
  @Prop() name!: string;
  @Prop() section!: Dictionary;

  @commonStore.State dictionaries!: Dictionaries;

  private get sectionName() {
    return findWhere(sectionsDictionary, { id: this.name })!.name;
  }

  private get parts() {
    const COLUMNS_COUNT = 3;
    const parts: Array<Dictionaries> = [];

    for (let i = 1; i <= COLUMNS_COUNT; i++) {
      const length = this.section.length;
      const column = this.section.slice(
        Math.ceil(length / COLUMNS_COUNT) * (i - 1),
        Math.ceil(length / COLUMNS_COUNT) * i
      );

      const dropdowns = column.filter(item => {
        const dictionaryName = camelize(item.id);

        return !!this.dictionaries[dictionaryName];
      });
      const checkboxes = column.filter(item => {
        const dictionaryName = camelize(item.id);

        return !this.dictionaries[dictionaryName];
      });

      parts.push({
        dropdowns,
        checkboxes,
      });
    }

    return parts;
  }
}
