
  import { Vue, Prop, Component } from 'vue-property-decorator';

  import phone from '@/filters/format-phone';
  import background from '@/filters/format-background';
  import time from '@/filters/format-time';
  import location from '@/filters/format-location';
  import urlService from '@/services/url-service';
  import Popup from '@/components/common/popup.vue';
  import {
    IAdvertSeller,
    ILocation,
    IAdvertPermissions,
  } from '@/interfaces/adverts';

  @Component({
    components: {
      Popup,
    },
    filters: {
      phone,
      background,
      time,
      location,
    },
  })
  export default class AdvertContactsPopup extends Vue {
    @Prop() seller!: IAdvertSeller;
    @Prop() author!: {
      id: string;
    };
    @Prop() location!: ILocation;
    @Prop() permissions!: IAdvertPermissions;

    public $refs!: {
      contactsPopup: HTMLFormElement;
    };

    private get avatar() {
      return urlService.secureProjectUrl(
        'content',
        `user/avatar/60x60/${this.author.id}`,
      );
    }

    private get profileLink() {
      return urlService.secureProjectUrl('profile', `user/${this.author.id}`);
    }

    public openPopup() {
      this.$refs.contactsPopup.openPopup();
    }
  }
