var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__offers-unit" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__offers-item vehicle-form__offers-item_stub",
        },
        [
          _c("div", { staticClass: "vehicle-form__offers-flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__offers-part vehicle-form__offers-part_image",
              },
              [
                _c("div", { staticClass: "vehicle-form__panorama" }, [
                  _c("div", { staticClass: "vehicle-form__panorama-box" }, [
                    _c("div", { staticClass: "vehicle-form__panorama-list" }, [
                      _c("div", {
                        staticClass:
                          "vehicle-form__panorama-item vehicle-form__panorama-item_secondary",
                      }),
                    ]),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__offers-part vehicle-form__offers-part_time",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_tiny vehicle-form__description_other vehicle-form__description_up",
                  },
                  [_vm._v("2 дня назад")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__offers-part vehicle-form__offers-part_data",
              },
              [
                _c("div", { staticClass: "vehicle-form__offers-flex" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__offers-part vehicle-form__offers-part_info",
                    },
                    [
                      _c("div", { staticClass: "vehicle-form__offers-flex" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__offers-part vehicle-form__offers-part_mileage",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary",
                              },
                              [_vm._v("10 000 000 км")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__offers-part vehicle-form__offers-part_year",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary",
                              },
                              [_vm._v("2018")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__offers-part vehicle-form__offers-part_city",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_compass-alter",
                              },
                              [_vm._v("Мозырь")]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__offers-part vehicle-form__offers-part_title",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary vehicle-form__description_font-weight_bold vehicle-form__description_fixing-additional",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__link vehicle-form__link_primary-alter vehicle-form__link_middle",
                            },
                            [_vm._v("Volvo S90 Momentum T4 Drive-E")]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__offers-part vehicle-form__offers-part_price",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_middle vehicle-form__description_primary vehicle-form__description_font-weight_bold vehicle-form__description_condensed-other",
                  },
                  [_vm._v("4 066 125 р.")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_tiny vehicle-form__description_other",
                  },
                  [_vm._v("1 953 084 $ / 1 750 000 €")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__offers-part vehicle-form__offers-part_specification",
              },
              [
                _c("div", { staticClass: "vehicle-form__offers-flex" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__offers-part vehicle-form__offers-part_parameter",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_engine vehicle-form__description_condensed-other",
                        },
                        [_vm._v("7 л / Бензин")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_car vehicle-form__description_condensed-other",
                        },
                        [_vm._v("Купе")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_transmission vehicle-form__description_condensed-other",
                        },
                        [_vm._v("Механическая")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__offers-part vehicle-form__offers-part_parameter",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_chassis vehicle-form__description_condensed-other",
                        },
                        [_vm._v("Полный")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_droplet-red vehicle-form__description_condensed-other",
                        },
                        [_vm._v("Красный")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_base vehicle-form__description_primary vehicle-form__description_dots vehicle-form__description_condensed-other",
                        },
                        [_vm._v("Еще 127 опций")]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }