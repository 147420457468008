var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.review.status === "waiting_moderation" ||
    _vm.review.status === "on_moderation"
    ? _c(
        "div",
        {
          staticClass:
            "tooltip-style tooltip-style_secondary tooltip-style_small tooltip-style_bottom-left tooltip-style_text_left tooltip-style_noarrow tooltip-style_visible vehicle-form__tooltip vehicle-form__tooltip_width_full",
        },
        [
          _c("div", { staticClass: "tooltip-style__content" }, [
            _c("div", { staticClass: "vehicle-form" }, [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_info",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_font-weight_semibold vehicle-form__description_condensed",
                    },
                    [
                      _vm._v(
                        "\n          Отзыв отправлен на модерацию\n          " +
                          _vm._s(
                            _vm._f("relativeDate")(_vm.review.statusChangedAt)
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                    },
                    [
                      _vm._v(
                        "\n          Вы получите уведомление, когда ваш отзыв будет опубликован\n        "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      )
    : _vm.review.status === "closed" && _vm.review.closureReason
    ? _c(
        "div",
        {
          staticClass:
            "tooltip-style tooltip-style_error tooltip-style_small tooltip-style_bottom-left tooltip-style_text_left tooltip-style_noarrow tooltip-style_visible vehicle-form__tooltip vehicle-form__tooltip_width_full",
        },
        [
          _c("div", { staticClass: "tooltip-style__content" }, [
            _c("div", { staticClass: "vehicle-form" }, [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_fail",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_font-weight_semibold vehicle-form__description_condensed",
                    },
                    [
                      _vm._v(
                        "\n          Ваш отзыв закрыт модератором " +
                          _vm._s(_vm._f("relativeDate")(_vm.review.closedAt)) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_other vehicle-form__description_base",
                        },
                        [_vm._v("\n            Причина:\n          ")]
                      ),
                      _vm._v(" "),
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.closureReason) },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      )
    : _vm.review.status === "closed"
    ? _c(
        "div",
        {
          staticClass:
            "tooltip-style tooltip-style_error tooltip-style_small tooltip-style_bottom-left tooltip-style_text_left tooltip-style_noarrow tooltip-style_visible vehicle-form__tooltip vehicle-form__tooltip_width_full",
        },
        [
          _c("div", { staticClass: "tooltip-style__content" }, [
            _c("div", { staticClass: "vehicle-form" }, [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_fail",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_font-weight_semibold vehicle-form__description_condensed",
                    },
                    [
                      _vm._v(
                        "\n          Вы закрыли свой отзыв " +
                          _vm._s(_vm._f("relativeDate")(_vm.review.closedAt)) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                    },
                    [
                      _vm._v(
                        "\n          Отзыв можно открыть в любой момент\n        "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }