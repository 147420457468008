import { Module } from 'vuex';
import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { IRootState, IUserState } from '@/interfaces/store';

const namespaced: boolean = true;
const module: Module<IUserState, IRootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};

export default module;
