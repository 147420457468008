
import { Mixins, Component } from 'vue-mixin-decorator';
import { findWhere } from 'underscore';

import StepTechModelMixin from '@/mixins/create/step-tech-model';
import DropdownMixin from '@/mixins/common/dropdown';

interface IMixins extends StepTechModelMixin, DropdownMixin {}

@Component
export default class StepTechModelMobile extends Mixins<IMixins>(
  DropdownMixin,
  StepTechModelMixin
) {
  private open() {
    this.openDropdown();

    document.body.classList.add('body_state-dropdown-style_visible');
  }

  private close() {
    this.closeDropdown();

    document.body.classList.remove('body_state-dropdown-style_visible');
  }

  private get modelName() {
    const manufacturer = findWhere(this.dictionaries.manufacturer, {
      id: this.data.manufacturerId,
    });
    const model = findWhere(this.dictionaries.model, {
      id: this.data.modelId,
    });

    if (!model) {
      return 'Выберите';
    }

    return model.name;
  }

  private onChange() {
    this.close();
    this.clearError({ name: 'modelId' });
    this.search = '';
  }

  private get error() {
    return (this.errors.modelId || []).join('. ');
  }
}
