import { Vue } from 'vue-property-decorator';

import { Mixin } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { HashMap } from '@/types/common';
import { IUser } from '@/interfaces/common';

const userStore = namespace('user');
const prefix = 'ab_review_create_';

@Mixin
export default class Field extends Vue {
  @userStore.State currentUser!: IUser;

  private get key() {
    return `${prefix}${this.currentUser.id}`;
  }

  protected setStorageValue(name: string, value: any) {
    const newValue = { ...this.getStorage(), [name]: value };

    window.localStorage.setItem(this.key, JSON.stringify(newValue));
  }

  private getStorage(): HashMap<any> {
    let value: HashMap<any> = {};

    try {
      value = JSON.parse(window.localStorage.getItem(this.key) || '');
    } catch (ex) {}

    return value || {};
  }

  protected getStorageValue(name: string) {
    const storage = this.getStorage();

    return storage ? storage[name] : null;
  }
}
