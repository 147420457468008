import { Module } from 'vuex';
import state from './state';
import actions from './actions';
import mutations from './mutations';
import { IRootState, ICreateState } from '@/interfaces/reviews/store';

const namespaced: boolean = true;

const module: Module<ICreateState, IRootState> = {
  namespaced,
  state,
  actions,
  mutations,
};

export default module;
