
  import { Prop } from 'vue-property-decorator';
  import { Mixins, Component } from 'vue-mixin-decorator';

  import { Review } from '@/types/review';
  import vmApi from '@/api/vm-api';
  import { IAdvert } from '@/interfaces/adverts';
  import SwiperMixin from '@/mixins/common/swiper';
  import plural from '@/filters/plural-form';
  import { reviewProposalsCount } from '@/config/reviews/view';
  import UrlService from '@/services/url-service';

  import ReviewProposalsItem from '@/components/reviews/view/review-proposals-item.vue';

  @Component({
    components: {
      ReviewProposalsItem,
    },

    filters: {
      plural,
    },
  })
  export default class ReviewProposals extends Mixins<SwiperMixin>(
    SwiperMixin,
  ) {
    @Prop() review!: Review;

    private adverts: IAdvert[] = [];
    private total: number = 0;

    private created() {
      vmApi.search(
        {
          car: [
            {
              model: this.review.model.id,
              manufacturer: this.review.manufacturer.id,
              generation: this.review.generation.id,
            },
          ],
        },
        {
          success: ({
            data: { adverts, total },
          }: {
            data: { adverts: IAdvert[]; total: number };
          }) => {
            this.adverts = adverts.slice(0, reviewProposalsCount);
            this.total = total;
            this.$nextTick(this.initSwiper);
          },
        },
      );
    }

    private get searchUrl() {
      const { model, manufacturer, generation } = this.review;

      return UrlService.secureProjectUrl(
        'ab',
        `${manufacturer.slug}/${model.slug}/${generation.slug}`,
      );
    }
  }
