var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__comments-item vehicle-form__comments-item_secondary",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__comments-unit vehicle-form__comments-unit_stub",
          },
          [
            _c("div", { staticClass: "vehicle-form__preview" }, [
              _c(
                "a",
                {
                  staticClass: "vehicle-form__image",
                  attrs: { href: "#", target: "_blank" },
                },
                [
                  _c("img", {
                    staticClass: "vehicle-form__avatar",
                    attrs: {
                      loading: "lazy",
                      src: "https://content.onliner.by/user/avatar/60x60/1865611",
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_condensed-appendant",
              },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "vehicle-form__link vehicle-form__link_primary-alter vehicle-form__link_middle vehicle-form__link_ellipsis",
                    attrs: { href: "#" },
                  },
                  [_vm._v("\n        qazas\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "button-style button-style_primary-alter button-style_small button-style_noreflex vehicle-form__button vehicle-form__button_status",
                  },
                  [_vm._v("\n        Лучший комментарий\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny vehicle-form__description_font-weight_normal",
                  },
                  [_vm._v("\n        15.12.2017 в 15:50\n      ")]
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__text" }, [
              _c("div", { staticClass: "tui-editor-contents" }, [
                _c("p", [_vm._v("Текст заглушка")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__details" }, [
              _c("div", { staticClass: "vehicle-form__details-list" }, [
                _c("div", { staticClass: "vehicle-form__details-item" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_small",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "vehicle-form__link vehicle-form__link_primary vehicle-form__link_small",
                          attrs: { href: "#" },
                        },
                        [_vm._v("\n              Ответить\n            ")]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }