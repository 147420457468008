
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { findWhere } from 'underscore';
  import { namespace } from 'vuex-class';
  import Popup from '@/components/common/popup.vue';
  import vmApi from '@/api/vm-api';
  import { ReviewsRatingDetailed } from '@/types/review';
  import plural from '@/filters/plural-form';
  import { Dictionaries, Dictionary } from '@/services/dictionaries-service';
  import SecondaryParameterItem from '@/components/common/rating-reviews/detailed-information/secondary-parameter-item.vue';
  import { Chunk } from '@/types/common';

  const commonStore = namespace('common');

  @Component({
    components: {
      SecondaryParameterItem,
      Popup,
    },
    filters: {
      plural,
    },
  })
  export default class DetailedInformation extends Vue {
    private isLoading: boolean = false;
    private detailedInformation: ReviewsRatingDetailed = null;

    @Prop() manufacturer!: Chunk;
    @Prop() model!: Chunk;
    @Prop() generation!: Chunk;

    @commonStore.State dictionaries!: Dictionaries;

    public $refs!: {
      ratingPopup: HTMLFormElement;
    };

    private get manufacturerName() {
      const dictionary: Dictionary = this.dictionaries.manufacturer;

      return (
        this.manufacturer.name ||
        findWhere(dictionary, {
          id: this.manufacturer.id,
        })?.name
      );
    }

    private get modelName() {
      const dictionary: Dictionary = this.dictionaries.model;

      return (
        this.model.name ||
        findWhere(dictionary, {
          id: this.model.id,
        })?.name
      );
    }

    private get generationName() {
      const dictionary: Dictionary = this.dictionaries.generation;

      return (
        this.generation.name ||
        findWhere(dictionary, {
          id: this.generation.id,
        })?.name
      );
    }

    private onViewAllClick() {
      this.isLoading = true;

      this.$refs.ratingPopup.openPopup();

      const params = {
        car: {
          manufacturer: this.manufacturer.id,
          model: this.model.id,
          generation: this.generation.id,
        },
      };

      vmApi.getReviewsRatingDetailed(params, {
        success: ({ data }: { data: ReviewsRatingDetailed }) => {
          this.detailedInformation = data;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    }

    private onClosePopupClick() {
      this.detailedInformation = null;
    }
  }
