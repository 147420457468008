var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-message" }, [
    _c("div", { staticClass: "vehicle-message__content" }, [
      _c(
        "div",
        { staticClass: "vehicle-container vehicle-container_width_mmmm" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-message__title vehicle-message__title_base vehicle-message__title_condensed-other",
            },
            [_vm._v("\n        Оставьте первый отзыв\n      ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-message__description vehicle-message__description_primary vehicle-message__description_base vehicle-message__description_condensed-complementary",
            },
            [
              _vm._v(
                "\n        Для пользователей Автобарахолки ваш отзыв может стать решающим\n        при выборе автомобиля\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-message__control vehicle-message__control_condensed-default",
            },
            [
              _c(
                "router-link",
                {
                  directives: [
                    {
                      name: "gtm",
                      rawName: "v-gtm",
                      value: { event: "create_review" },
                      expression: "{ event: 'create_review' }",
                    },
                  ],
                  staticClass:
                    "button-style button-style_primary button-style_small vehicle-message__button",
                  attrs: { to: { name: "create-review" } },
                },
                [_vm._v("\n          Написать отзыв\n        ")]
              ),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-message__preview" }, [
      _c("div", {
        staticClass: "vehicle-message__image vehicle-message__image_suv-alter",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }