
  import { Component } from 'vue-property-decorator';
  import { Mixins } from 'vue-mixin-decorator';
  import FieldMixin from '@/mixins/reviews/create/field';

  @Component
  export default class StepDescriptionSummary extends Mixins<FieldMixin>(
    FieldMixin,
  ) {
    private get summary() {
      return this.data.summary;
    }

    private set summary(value) {
      this.onFieldChange(value);
    }
  }
