var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-steps" }, [
    _c(
      "div",
      { staticClass: "vehicle-form" },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_extended-fringe",
          },
          [_vm._v("\n      Редактировать объявление\n    ")]
        ),
        _vm._v(" "),
        !_vm.isLoading
          ? _c(
              "div",
              { staticClass: "vehicle-form__step" },
              [_c("router-view")],
              1
            )
          : _c("edit-stub"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }