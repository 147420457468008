var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("comments", {
    attrs: {
      "project-name": _vm.config.projectName,
      "entity-type": _vm.config.entityType,
      "entity-id": _vm.review.id,
      "entity-author-id": _vm.review.author.id,
      config: _vm.config,
      "current-user": _vm.currentUser,
    },
    on: {
      error: _vm.onError,
      "counter-update": _vm.onCounterUpdate,
      "url-scroll": _vm.onUrlScroll,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }