var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__row" }, [
    _c(
      "div",
      { staticClass: "vehicle-form__group vehicle-form__group_width_full" },
      [
        _c(
          "div",
          { staticClass: "vehicle-form__label vehicle-form__label_base" },
          [
            _c("div", { staticClass: "vehicle-form__label-flex" }, [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__label-part vehicle-form__label-part_1",
                },
                [
                  _c("div", { staticClass: "vehicle-form__label-title" }, [
                    _vm._v(_vm._s(_vm.label)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "vehicle-form__label-part vehicle-form__label-part_2",
              }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "vehicle-form__field" }, [
          _vm.preferredOptions
            ? _c("div", { staticClass: "vehicle-form__line" }, [
                _c(
                  "ul",
                  { staticClass: "vehicle-form__checkbox-list" },
                  _vm._l(_vm.preferredOptions, function (option) {
                    return _c(
                      "li",
                      {
                        key: option.key,
                        staticClass: "vehicle-form__checkbox-item",
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "vehicle-form__checkbox-label" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "i-checkbox vehicle-form__checkbox vehicle-form__checkbox_base",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedPreferred[option.key],
                                      expression:
                                        "selectedPreferred[option.key]",
                                    },
                                  ],
                                  staticClass: "i-checkbox__real",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.selectedPreferred[option.key]
                                    )
                                      ? _vm._i(
                                          _vm.selectedPreferred[option.key],
                                          null
                                        ) > -1
                                      : _vm.selectedPreferred[option.key],
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a =
                                            _vm.selectedPreferred[option.key],
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.selectedPreferred,
                                                option.key,
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.selectedPreferred,
                                                option.key,
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.selectedPreferred,
                                            option.key,
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        return _vm.onPreferredSelect(option.key)
                                      },
                                    ],
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "i-checkbox__faux" }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vehicle-form__checkbox-text",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__checkbox-sign",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(option.title) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.counters && _vm.counters[option.key]
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__checkbox-value",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.counters[option.key]
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__line" }, [
            _c(
              "div",
              {
                staticClass:
                  "input-style__combo input-style__combo_stretch vehicle-form__input-combo vehicle-form__input-combo_width_full",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_width_full",
                  },
                  [
                    _vm.optionsDictionary
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "input-style input-style_primary input-style_small input-style_arrow_bottom vehicle-form__input vehicle-form__input_width_full",
                            class: {
                              "input-style_placeholder": !_vm.filter.value.from,
                            },
                          },
                          [
                            _c("div", { staticClass: "input-style__faux" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.filter.value.from ||
                                      _vm.rangePlaceholders.from ||
                                      "от"
                                  ) +
                                  "\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filter.value.from,
                                    expression: "filter.value.from",
                                  },
                                ],
                                staticClass: "input-style__real",
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.filter.value,
                                        "from",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    _vm.updateFilter,
                                  ],
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v(_vm._s(_vm.labelAny || "Любое")),
                                ]),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.optionsDictionary,
                                  function (option) {
                                    return _c(
                                      "option",
                                      {
                                        key: option.id,
                                        domProps: { value: option.id },
                                      },
                                      [_vm._v(_vm._s(option.name))]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        )
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filter.value.from,
                              expression: "filter.value.from",
                            },
                          ],
                          staticClass:
                            "input-style input-style_primary input-style_small vehicle-form__input vehicle-form__input_width_full",
                          attrs: {
                            type: "text",
                            placeholder: "от",
                            maxlength: _vm.maxlength,
                          },
                          domProps: { value: _vm.filter.value.from },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.filter.value,
                                  "from",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.onInput("from", $event)
                              },
                            ],
                            change: _vm.updateFilter,
                          },
                        }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_width_full",
                  },
                  [
                    _vm.optionsDictionary
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "input-style input-style_primary input-style_small input-style_arrow_bottom vehicle-form__input vehicle-form__input_width_full",
                            class: {
                              "input-style_placeholder": !_vm.filter.value.to,
                            },
                          },
                          [
                            _c("div", { staticClass: "input-style__faux" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.filter.value.to ||
                                      _vm.rangePlaceholders.to ||
                                      "до"
                                  ) +
                                  "\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filter.value.to,
                                    expression: "filter.value.to",
                                  },
                                ],
                                staticClass: "input-style__real",
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.filter.value,
                                        "to",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    _vm.updateFilter,
                                  ],
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v(_vm._s(_vm.labelAny || "Любое")),
                                ]),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.optionsDictionary,
                                  function (option) {
                                    return _c(
                                      "option",
                                      {
                                        key: option.id,
                                        domProps: { value: option.id },
                                      },
                                      [_vm._v(_vm._s(option.name))]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        )
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filter.value.to,
                              expression: "filter.value.to",
                            },
                          ],
                          staticClass:
                            "input-style input-style_primary input-style_small vehicle-form__input vehicle-form__input_width_full",
                          attrs: {
                            type: "text",
                            placeholder: "до",
                            maxlength: _vm.maxlength,
                          },
                          domProps: { value: _vm.filter.value.to },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.filter.value,
                                  "to",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.onInput("to", $event)
                              },
                            ],
                            change: _vm.updateFilter,
                          },
                        }),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }