var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "vehicle-form__intro" }, [
        _c("div", { staticClass: "vehicle-form__intro-flex" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm.isComplete && !_vm.isEditMode
            ? _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__intro-part vehicle-form__intro-part_2",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "vehicle-form__link vehicle-form__link_primary vehicle-form__link_tiny vehicle-form__link_font-weight_normal vehicle-form__link_ellipsis",
                      on: {
                        click: function ($event) {
                          return _vm.editStep("contacts")
                        },
                      },
                    },
                    [_vm._v("Изменить")]
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm.isComplete
        ? [
            _c("div", { staticClass: "vehicle-form__parameter" }, [
              _c(
                "div",
                { staticClass: "vehicle-form__parameter-list" },
                [
                  _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                    _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.cityName) +
                                  ", " +
                                  _vm._s(_vm.regionName) +
                                  ", " +
                                  _vm._s(_vm.countryName)
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                    _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                      _vm._m(2),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm._f("capitalize")(_vm.sellerType))
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                    _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__parameter-part vehicle-form__parameter-part_1",
                        },
                        [
                          _vm.seller.type === "individual"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
                                },
                                [_vm._v("Имя продавца")]
                              )
                            : _vm.seller.type === "autohaus"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
                                },
                                [_vm._v("Название автохауса")]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
                                },
                                [_vm._v("Название дилера")]
                              ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                            },
                            [_vm._v(_vm._s(_vm.data.seller.name))]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.seller.unp
                    ? _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__parameter-flex" },
                            [
                              _vm._m(3),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v(_vm._s(_vm.seller.unp))]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.seller.contactName
                    ? _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__parameter-flex" },
                            [
                              _vm._m(4),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [_vm._v(_vm._s(_vm.seller.contactName))]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.seller.chatPreferred
                    ? _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-item" },
                        [_vm._m(5)]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.seller.phones.filter((item) => item).length
                    ? [
                        _c(
                          "div",
                          { staticClass: "vehicle-form__parameter-item" },
                          [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__parameter-flex" },
                              [
                                _vm._m(6),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                  },
                                  _vm._l(
                                    _vm.data.seller.phones.filter(
                                      (item) => item
                                    ),
                                    function (phone) {
                                      return _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                        },
                                        [_vm._v(_vm._s(phone))]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "vehicle-form__parameter-item" },
                          [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__parameter-flex" },
                              [
                                _vm._m(7),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                                  },
                                  [
                                    _vm.from !== _vm.to
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                          },
                                          [
                                            _vm._v(
                                              "с " +
                                                _vm._s(_vm.from) +
                                                " до " +
                                                _vm._s(_vm.to) +
                                                " часов"
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                          },
                                          [_vm._v("Круглосуточно")]
                                        ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]
        : _c("div"),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__intro-part vehicle-form__intro-part_1" },
      [
        _c("div", { staticClass: "vehicle-form__intro-list" }, [
          _c("div", { staticClass: "vehicle-form__intro-item" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_extended-other",
              },
              [_vm._v("Контакты")]
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("Местонахождение")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("Продавец")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("УНП")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("Контактное лицо")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__parameter-part vehicle-form__parameter-part_1",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
            },
            [_vm._v("Хочу общаться только в чатах")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__parameter-part vehicle-form__parameter-part_2",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
            },
            [_vm._v("Да")]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("Телефон")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("Звонить")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }