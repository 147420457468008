import { Prop } from 'vue-property-decorator';
import { Mixin, Mixins } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import FieldMixin from '@/mixins/create/field';
import DropdownMixin from '@/mixins/common/dropdown';
import { Dictionaries } from '@/services/dictionaries-service';
import camelize from '@/filters/format-camelize';
import { HashMap } from '@/types/common';

const commonStore = namespace('common');

interface IMixins extends DropdownMixin, FieldMixin {}

@Mixin
export default class StepExteriorEquipmentDropdownMixin extends Mixins<IMixins>(
  FieldMixin,
  DropdownMixin
) {
  @Prop() item!: {
    id: string;
    name: string;
    multiple: boolean;
  };

  @commonStore.State dictionaries!: Dictionaries;

  protected get equipment() {
    return this.data.equipment || {};
  }

  protected get property() {
    return camelize(this.item.id);
  }

  protected get options() {
    return this.dictionaries[this.property];
  }

  protected get isNoneSelected() {
    return !this.equipment[this.property];
  }

  protected get field() {
    return this.equipment[this.property];
  }

  protected set field(value) {}

  protected toggle() {
    this.toggleDropdown();
  }

  protected open() {
    this.openDropdown();

    document.body.classList.add('body_state-dropdown-style_visible');
  }

  protected close() {
    this.closeDropdown();

    document.body.classList.remove('body_state-dropdown-style_visible');
  }

  protected clear() {
    const newEquipment: HashMap<string | Array<string>> = {};

    for (let key of Object.keys(this.equipment)) {
      if (key !== this.property) {
        newEquipment[key] = this.equipment[key];
      }
    }

    this.setField({ equipment: newEquipment });

    this.close();
  }
}
