
  import { Component, Prop, Vue } from 'vue-property-decorator';

  import vmApi from '@/api/vm-api';
  import { Counters } from '@/types/common';
  import { IUser } from '@/interfaces/common';

  import ReviewAddBlock from '@/components/reviews/common/review-add-block.vue';
  import ReviewCard from '@/components/reviews/common/review-card.vue';
  import ReviewCardStub from '@/components/reviews/common/review-card-stub.vue';
  import ReviewListManage from '@/components/reviews/list/review-list-manage.vue';
  import Pagination from '@/components/common/pagination.vue';
  import { Review } from '@/types/review';
  import { IPage } from '@/interfaces/search';
  import { socialIcon } from '@/config/urls';

  @Component({
    components: {
      ReviewAddBlock,
      ReviewCard,
      ReviewCardStub,
      ReviewListManage,
      Pagination,
    },
    metaInfo(): any {
      return {
        title: 'Отзывы пользователя - Автобарахолка Onlíner',
        meta: [
          {
            name: 'description',
            content: 'Отзывы пользователя - Автобарахолка Onlíner',
          },
          {
            name: 'og:image',
            content: socialIcon,
          },
        ],
      };
    },
  })
  export default class UserReviews extends Vue {
    @Prop() isOwn!: boolean;

    private isLoading: boolean = true;
    private reviews: Review[] = [];
    private stubCount = 10;
    private page: IPage | null = null;
    private total: number = 0;

    private created() {
      this.updateReviews();
    }

    private updateReviews(page?: number) {
      vmApi.getUserReviews(
        this.$route.params.id,
        {
          page,
        },
        {
          success: ({
            data: { reviews, counters, user, page, total },
          }: {
            data: {
              reviews: Review[];
              counters: Counters;
              user: IUser;
              page: IPage;
              total: number;
            };
          }) => {
            this.reviews = reviews;
            this.page = page;
            this.total = total;

            this.$emit('counters-update', counters);
            this.$emit('user-update', user);
          },
          complete: () => {
            this.isLoading = false;
          },
        },
      );
    }

    private onReviewChange(data: Review, index: number) {
      this.reviews.splice(index, 1, data);
    }

    private onPageSet(page: number) {
      this.updateReviews(page);
    }
  }
