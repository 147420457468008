var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__row vehicle-form__row_extended-other" },
    [
      _c(
        "div",
        {
          staticClass: "vehicle-form__line vehicle-form__line_condensed-other",
        },
        [
          _c("div", { staticClass: "vehicle-form__group" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__field" }, [
              _c(
                "div",
                {
                  staticClass:
                    "input-style__combo input-style__combo_stretch vehicle-form__input-combo vehicle-form__input-combo_width_full",
                },
                _vm._l(_vm.transmissions, function (item) {
                  return _c(
                    "a",
                    {
                      key: item.id,
                      staticClass:
                        "button-style button-style_more button-style_base button-style_bordered vehicle-form__button vehicle-form__button_choose",
                      class: {
                        "button-style_active": item.id === _vm.transmission,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onSetTransmissionClick(item.id)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm._f("capitalize")(item.name)))]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            {
              staticClass:
                "vehicle-form__line vehicle-form__line_condensed-other",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_full",
                },
                [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("\n              Коробка передач\n            "),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }