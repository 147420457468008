var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__group vehicle-form__group_width_auto" },
    [
      _c("div", { staticClass: "vehicle-form__field" }, [
        _c("ul", { staticClass: "vehicle-form__checkbox-list" }, [
          _c("li", { staticClass: "vehicle-form__checkbox-item" }, [
            _c("label", { staticClass: "vehicle-form__checkbox-label" }, [
              _c(
                "div",
                {
                  staticClass:
                    "i-checkbox vehicle-form__checkbox vehicle-form__checkbox_base",
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.value,
                        expression: "value",
                      },
                    ],
                    staticClass: "i-checkbox__real",
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.value)
                        ? _vm._i(_vm.value, null) > -1
                        : _vm.value,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.value,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.value = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.value = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.value = $$c
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "i-checkbox__faux" }),
                  _vm._v(" "),
                  _vm._m(0),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__checkbox-text" }, [
      _c("div", { staticClass: "vehicle-form__checkbox-sign" }, [
        _vm._v("Только непремиальные объявления"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }