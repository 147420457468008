import { Mixins } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import { IFilter } from '@/interfaces/filters';
import { Dictionaries } from '@/services/dictionaries-service';
import FilterObjects from '@/mixins/search/filter-objects';
import MetaMixin from '@/mixins/search/meta';

const commonStore = namespace('common');
const searchStore = namespace('search');

interface IMixins extends FilterObjects, MetaMixin {}

@Mixin
export default class AdvertsMetaMixin extends Mixins<IMixins>(
  FilterObjects,
  MetaMixin,
) {
  protected defaultMeta = {
    title: `Автобарахолка - покупка, продажа автомобилей`,
    description: `Автобарахолка Onlíner - это удобный способ купить или продать авто в Беларуси и за рубежом. Объявления о продаже новых и подержанных автомобилей`,
    header: `Автобарахолка`,
  };

  @commonStore.State dictionaries!: Dictionaries;
  @searchStore.State filters!: Array<IFilter>;

  protected parseFilters() {
    if (!this.filters.length) {
      return {
        title: `Купить авто в Беларуси - Автобарахолка Onlíner`,
        description: `Автобарахолка Onlíner - это удобный способ купить или продать авто. Объявления о продаже новых и подержанных автомобилей`,
        header: `Автобарахолка`,
      };
    }

    if (
      this.countryId === 2897 &&
      Object.keys(this.location).length === 1 &&
      this.filters.length === 1
    ) {
      return {
        title: `Купить авто в Польше`,
        description: `Автобарахолка Onlíner - это удобный способ купить авто в Польше. Объявления о продаже новых и подержанных автомобилей`,
        header: `Автомобили в Польше`,
      };
    }

    if (
      this.countryId === 2514 &&
      Object.keys(this.location).length === 1 &&
      this.filters.length === 1
    ) {
      return {
        title: `Купить авто в Литве`,
        description: `Автобарахолка Onlíner - это удобный способ купить авто в Литве. Объявления о продаже новых и подержанных автомобилей`,
        header: `Автомобили в Литве`,
      };
    }

    if (
      this.countryId === 1012 &&
      Object.keys(this.location).length === 1 &&
      this.filters.length === 1
    ) {
      return {
        title: `Купить авто в Германии`,
        description: `Автобарахолка Onlíner - это удобный способ купить авто в Германии. Объявления о продаже новых и подержанных автомобилей`,
        header: `Автомобили в Германии`,
      };
    }

    if (this.cityId === 4400 && this.filters.length === 1) {
      return {
        title: `Купить авто в Москве`,
        description: `Автобарахолка Onlíner - это удобный способ купить авто в Москве. Объявления о продаже новых и подержанных автомобилей`,
        header: `Автомобили в Москве`,
      };
    }

    if (
      this.countryId === 3159 &&
      Object.keys(this.location).length === 1 &&
      this.filters.length === 1
    ) {
      return {
        title: `Купить авто в России`,
        description: `Автобарахолка Onlíner - это удобный способ купить авто в России. Объявления о продаже новых и подержанных автомобилей`,
        header: `Автомобили в России`,
      };
    }

    if (
      this.countryId === 3159 &&
      this.state === 'owned' &&
      Object.keys(this.location).length === 1 &&
      this.filters.length === 2
    ) {
      return {
        title: `Купить авто с пробегом в России`,
        description: `Автобарахолка Onlíner - это удобный способ купить авто с пробегом в России. Объявления о продаже подержанных автомобилей`,
        header: `Авто с пробегом в России`,
      };
    }

    if (
      this.countryId === 5681 &&
      Object.keys(this.location).length === 1 &&
      this.filters.length === 1
    ) {
      return {
        title: `Купить авто в США`,
        description: `Автобарахолка Onlíner - это удобный способ купить авто в США. Объявления о продаже новых и подержанных автомобилей`,
        header: `Автомобили в Америке`,
      };
    }

    if (
      this.cityObject &&
      this.brandObject &&
      this.modelObject &&
      this.generationObject &&
      this.filters.length === 2
    ) {
      return {
        title: `${this.brandObject.name} ${this.modelObject.name} ${this.generationObject.name} ${this.cityObject.name}. Объявления, цены - Автобарахолка Onlíner`,
        description: `${this.cityObject.name}. ${this.brandObject.name} ${this.modelObject.name} ${this.generationObject.name} на Автобарахолке Onlíner. Объявления о продаже новых и подержанных автомобилей`,
        header: `${this.brandObject.name} ${this.modelObject.name} ${this.generationObject.name} ${this.cityObject.name}`,
      };
    }

    if (
      this.cityObject &&
      this.brandObject &&
      this.modelObject &&
      this.filters.length === 2
    ) {
      return {
        title: `${this.brandObject.name} ${this.modelObject.name} ${this.cityObject.name}. Объявления, цены - Автобарахолка Onlíner`,
        description: `${this.cityObject.name}. ${this.brandObject.name} ${this.modelObject.name} на Автобарахолке Onlíner. Объявления о продаже новых и подержанных автомобилей`,
        header: `${this.brandObject.name} ${this.modelObject.name} ${this.cityObject.name}`,
      };
    }

    if (
      this.brandObject &&
      this.modelObject &&
      this.generationObject &&
      this.filters.length === 1
    ) {
      return {
        title: `Купить ${this.brandObject.name} ${this.modelObject.name} ${this.generationObject.name}. Объявления, цены - Автобарахолка Onlíner`,
        description: `${this.brandObject.name} ${this.modelObject.name} ${this.generationObject.name} на Автобарахолке Onlíner. Объявления о продаже новых и подержанных автомобилей`,
        header: `${this.brandObject.name} ${this.modelObject.name} ${this.generationObject.name}`,
      };
    }

    if (this.brandObject && this.modelObject && this.filters.length === 1) {
      return {
        title: `Купить ${this.brandObject.name} ${this.modelObject.name}. Объявления, цены - Автобарахолка Onlíner`,
        description: `${this.brandObject.name} ${this.modelObject.name} на Автобарахолке Onlíner. Объявления о продаже новых и подержанных автомобилей`,
        header: `${this.brandObject.name} ${this.modelObject.name}`,
      };
    }

    if (this.brandObject && this.filters.length === 1) {
      return {
        title: `Купить ${this.brandObject.name}. Объявления, цены - Автобарахолка Onlíner`,
        description: `${this.brandObject.name} на Автобарахолке Onlíner. Объявления о продаже новых и подержанных автомобилей`,
        header: `${this.brandObject.name}`,
      };
    }

    if (this.state === 'new' && this.filters.length === 1) {
      return {
        title: `Купить новый автомобиль в Беларуси - Автобарахолка Onlíner`,
        description: `Автобарахолка Onlíner - это удобный способ купить или продать авто. Объявления о продаже новых автомобилей`,
        header: `Новые автомобили`,
      };
    }

    if (
      this.state === 'new' &&
      this.brandObject &&
      this.modelObject &&
      this.filters.length === 2
    ) {
      return {
        title: `Купить новый автомобиль ${this.brandObject.name} ${this.modelObject.name} в Беларуси - Автобарахолка Onlíner`,
        description: `Автобарахолка Onlíner - это удобный способ купить новый автомобиль ${this.brandObject.name} ${this.modelObject.name}. Объявления о продаже новых автомобилей`,
        header: `Новые автомобили ${this.brandObject.name} ${this.modelObject.name}`,
      };
    }

    if (this.state === 'new' && this.cityObject && this.filters.length === 2) {
      return {
        title: `Купить новый автомобиль ${this.cityObject.name} - Автобарахолка Onlíner`,
        description: `${this.cityObject.name} Автобарахолка Onlíner - это удобный способ купить новый автомобиль. Объявления о продаже новых автомобилей`,
        header: `Новые автомобили ${this.cityObject.name}`,
      };
    }

    if (
      this.state === 'new' &&
      this.isDealer &&
      this.cityObject &&
      this.filters.length === 3
    ) {
      return {
        title: `Купить новый автомобиль ${this.cityObject.name}. Автохаусы, автосалоны, Официальные дилеры`,
        description: `${this.cityObject.name} Автобарахолка Onlíner - это удобный способ купить новый автомобиль в автосалоне. Объявления о продаже новых автомобилей`,
        header: `Новые автомобили. Автосалоны ${this.cityObject.name}`,
      };
    }

    if (this.state === 'new' && this.isDealer && this.filters.length === 2) {
      return {
        title: `Купить новое авто в автосалоне. Автохаусы, автосалоны, Официальные дилеры`,
        description: `Автобарахолка Onlíner - это удобный способ купить новый автомобиль в автосалоне. Объявления о продаже новых автомобилей`,
        header: `Новые автомобили в автосалонах`,
      };
    }

    if (
      this.state === 'owned' &&
      this.cityObject &&
      this.filters.length === 2
    ) {
      return {
        title: `Автомобили с пробегом ${this.cityObject.name} - Автобарахолка Onlíner`,
        description: `${this.cityObject.name}. Автобарахолка Onlíner - это удобный способ купить или продать авто. Объявления о продаже подержанных автомобилей`,
        header: `Автомобили с пробегом ${this.cityObject.name}`,
      };
    }

    if (this.state === 'owned' && this.filters.length === 1) {
      return {
        title: `Купить Б/У авто в Беларуси - Автобарахолка Onlíner`,
        description: `Автобарахолка Onlíner - это удобный способ купить или продать авто. Объявления о продаже подержанных автомобилей`,
        header: `Подержанные автомобили`,
      };
    }

    if (
      this.state === 'owned' &&
      this.cityObject &&
      this.countryId &&
      this.filters.length === 1
    ) {
      return {
        title: `Автомобили с пробегом ${this.cityObject.name} - Автобарахолка Onlíner`,
        description: `${this.cityObject.name}. Автобарахолка Onlíner - это удобный способ купить или продать авто. Объявления о продаже подержанных автомобилей`,
        header: `Автомобили с пробегом ${this.cityObject.name}`,
      };
    }

    if (this.state === 'owned' && this.filters.length === 1) {
      return {
        title: `Купить Б/У авто в Беларуси - Автобарахолка Onlíner`,
        description: `Автобарахолка Onlíner - это удобный способ купить или продать авто. Объявления о продаже подержанных автомобилей`,
        header: `Подержанные автомобили`,
      };
    }

    if (this.isVatIncluded && this.filters.length === 1) {
      return {
        title: `Купить авто с НДС в Беларуси`,
        description: `Автобарахолка Onlíner - это удобный способ купить авто с НДС. Объявления о продаже новых и подержанных автомобилей`,
        header: `Автомобили с НДС`,
      };
    }

    if (this.state === 'emergency' && this.filters.length === 1) {
      return {
        title: `Купить битый автомобиль, авто в аварийном состоянии в Беларуси`,
        description: `Автобарахолка Onlíner - это удобный способ купить или продать авто. Объявления о продаже автомобилей в аварийном состоянии`,
        header: `Аварийные автомобили`,
      };
    }

    if (
      this.countryId === 248 &&
      this.cityObject &&
      this.filters.length === 1
    ) {
      return {
        title: `Купить авто ${this.cityObject.name} - Автобарахолка Onlíner`,
        description: `${this.cityObject.name}. Автобарахолка Onlíner.by - это удобный способ купить или продать авто. Объявления о продаже новых и подержанных автомобилей`,
        header: `Автомобили ${this.cityObject.name}`,
      };
    }

    return null;
  }
}
