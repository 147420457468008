
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { IAdvert } from '@/interfaces/adverts';
  import { VuexAction } from '@/types/functions';
  import Popup from '@/components/common/popup.vue';
  import vmApi from '@/api/vm-api';
  import { Review } from '@/types/review';

  const advertStore = namespace('advert');
  const userStore = namespace('user');

  const reasons = [
    'Размещены фотографии, не соответствующие описываемой модели ',
    'В отзыве использована нецензурная лексика',
    'В отзыве размещена реклама',
    'Иное',
  ];

  @Component({
    components: {
      Popup,
    },
  })
  export default class ReviewActionComplain extends Vue {
    @Prop() review!: Review;

    private subject: string = reasons[0];
    private text: string = '';
    protected isProcessing: boolean = false;
    protected error: string = '';
    protected authError: string = '';

    @userStore.Getter isAuthenticated!: boolean;

    public $refs!: {
      complainPopup: HTMLFormElement;
    };

    private get reasons() {
      return reasons;
    }

    private onComplainClick() {
      if (this.subject === 'Иное' && !this.text) {
        this.error = 'Укажите причину жалобы';

        return;
      }

      this.isProcessing = true;

      vmApi.reviewComplain(
        this.review.id,
        {
          subject: this.subject,
          text: this.text,
        },
        {
          success: () => {
            this.$gtm.trackEvent({
              event: 'claim_ad',
              value: {
                reason: this.subject,
              },
            });
            this.closeComplainPopup();
          },
          error: ({ response }: any) => {
            if (response.status === 403 || response.status === 404) {
              this.authError = 'Произошла ошибка, попробуйте позже';
            }
          },
          complete: () => {
            this.isProcessing = false;
          },
        },
      );
    }

    private openComplainPopup() {
      if (this.isAuthenticated) {
        this.subject = reasons[0];
        this.text = '';

        this.$refs.complainPopup.openPopup();
      } else {
        const profileAuth = (window as any).profileAuth as {
          showLoginModal: () => {};
        };

        profileAuth.showLoginModal();
      }
    }

    private closeComplainPopup() {
      this.$refs.complainPopup.closePopup();
    }

    protected onOpenComplainClick() {
      this.openComplainPopup();
    }

    protected onCloseComplainClick() {
      this.closeComplainPopup();
    }

    private onSubjectChange() {
      this.error = '';
    }

    private onTextInput() {
      this.error = '';
    }
  }
