var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__control" },
    [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__control-flex vehicle-form__control-flex_column",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__control-part vehicle-form__control-part_1",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed-additional",
                },
                [
                  _vm._v(
                    "\n        Рейтинг " +
                      _vm._s(_vm.ratingBy.toLowerCase()) +
                      ":\n        "
                  ),
                  _c("rating-stars", {
                    attrs: {
                      "rating-average": _vm.ratingAverage,
                      "is-info-block-visible": _vm.isInfoBlockVisible,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
                },
                [
                  _vm.isCounterClickable
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "vehicle-form__link vehicle-form__link_primary vehicle-form__link_small",
                          attrs: { href: _vm.reviewsUrl },
                        },
                        [
                          _vm._v(
                            "\n          по " +
                              _vm._s(
                                _vm._f("plural")(_vm.countReviews, [
                                  "отзыву",
                                  "отзывам",
                                  "отзывам",
                                ])
                              ) +
                              "\n        "
                          ),
                        ]
                      )
                    : [
                        _vm._v(
                          "\n          по " +
                            _vm._s(
                              _vm._f("plural")(_vm.countReviews, [
                                "отзыву",
                                "отзывам",
                                "отзывам",
                              ])
                            ) +
                            "\n        "
                        ),
                      ],
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__control-part vehicle-form__control-part_2",
            },
            [
              _vm.isSpecificsVisible
                ? _c(
                    "a",
                    {
                      staticClass:
                        "button-style button-style_auxiliary-specific button-style_small vehicle-form__button",
                      on: { click: _vm.onSpecificsClick },
                    },
                    [_vm._v("\n        Узнать подробнее\n      ")]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.ld
        ? _c("json-ld", { attrs: { ld: _vm.ld, type: "AggregateRating" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }