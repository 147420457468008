import { MutationTree } from 'vuex';
import { findIndex } from 'underscore';

import { IAdvertsState } from '@/interfaces/store';
import { IAdvert } from '@/interfaces/adverts';
import { IPage } from '@/interfaces/search';
import { IUser } from '@/interfaces/common';
import { HashMap } from '@/types/common';

const mutations: MutationTree<IAdvertsState> = {
  setLoading(state, isLoading: boolean) {
    state.isLoading = isLoading;
  },

  setAdverts(state, adverts) {
    state.adverts = adverts;
  },

  setPage(state, page: IPage) {
    state.page = page;
  },

  setUser(state, user: IUser) {
    state.user = user;
  },

  setPermissions(state, permissions: HashMap<boolean>) {
    state.permissions = permissions;
  },

  setAdvert(state, { id, data }: { id: string; data: IAdvert }) {
    const index = findIndex(state.adverts, { id });

    if (index === -1) {
      return;
    }

    state.adverts.splice(index, 1, data);
  },

  patchAdvert(state, { id, data }: { id: string; data: any }) {
    const index = findIndex(state.adverts, { id });

    if (index === -1) {
      return;
    }

    const advert = { ...state.adverts[index], ...data };

    state.adverts.splice(index, 1, advert);
  },

  patchPermissions(state, { id, data }: { id: string; data: any }) {
    const index = findIndex(state.adverts, { id });

    if (index === -1) {
      return;
    }

    const permissions = {
      ...state.adverts[index].permissions,
      ...data.permissions,
    };
    const advert = { ...state.adverts[index], permissions };

    state.adverts.splice(index, 1, advert);
  },

  setTotal(state, total) {
    state.total = total;
  },
};

export default mutations;
