
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IUser } from '@/interfaces/common';

import { ICounters } from '@/interfaces/search';

const userStore = namespace('user');
const promoteStore = namespace('promote');

@Component
export default class PromoteHeader extends Vue {
  @userStore.State currentUser!: IUser;
  @promoteStore.State generalCounters!: ICounters;

  private get counter() {
    const total = this.generalCounters.total as number;

    return total - (total % 1000);
  }
}
