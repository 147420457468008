import { MutationTree } from 'vuex';
import { IAdvertState } from '@/interfaces/store';
import { IAdvert } from '@/interfaces/adverts';

const mutations: MutationTree<IAdvertState> = {
  setAdvert(state, advert) {
    state.advert = advert;
  },

  setPhones(state, phones) {
    const advert: IAdvert = state.advert as IAdvert;
    const seller = { ...advert.seller, phones };

    state.advert = { ...state.advert, seller };
  },

  setVin(state, vin) {
    (state.advert as IAdvert).specs.vin = vin;
  },

  setSimilar(state, similar) {
    state.similar = similar;
  },

  setStats(state, stats) {
    state.stats = stats;
  },

  patchAdvert(state, data) {
    state.advert = Object.assign(state.advert, data);
  },

  patchPermissions(state, data) {
    const initialPermissions = (state.advert as IAdvert).permissions;

    const permissions = { ...initialPermissions, ...data.permissions };

    state.advert = Object.assign(state.advert, { permissions });
  },
};

export default mutations;
