import { HashMap } from '@/types/common';
import { Dictionary } from '@/services/dictionaries-service';

export type EquipmentMapItem = {
  id: string;
  name: string;
  multiple?: boolean;
};

export type EquipmentMap = HashMap<Array<EquipmentMapItem>>;

export const sectionsDictionary: Dictionary = [
  {
    id: 'multimedia',
    name: 'Мультимедиа',
  },
  {
    id: 'comfort',
    name: 'Комфорт',
  },
  {
    id: 'sight',
    name: 'Обзор',
  },
  {
    id: 'exterior',
    name: 'Экстерьер',
  },
  {
    id: 'security',
    name: 'Защита от угона',
  },
  {
    id: 'interior',
    name: 'Салон',
  },
  {
    id: 'safety',
    name: 'Безопасность',
  },
];

export const equipmentMap: EquipmentMap = {
  multimedia: [
    { id: 'audio_system', name: 'Аудиосистема' },
    { id: 'navigation_system', name: 'Навигационная система' },
    { id: 'carplay', name: 'CarPlay' },
    { id: 'yandex_auto', name: 'Яндекс.Авто' },
    { id: 'android_auto', name: 'Android Auto' },
    { id: 'bluetooth', name: 'Bluetooth' },
    { id: 'aux', name: 'AUX' },
    { id: 'usb', name: 'USB' },
    { id: 'outlet_12v', name: 'Розетка 12V' },
    { id: 'outlet_220v', name: 'Розетка 220V' },
    {
      id: 'multimedia_for_rear_passengers',
      name: 'Мультимедиасистема для задних пассажиров',
    },
  ],
  comfort: [
    { id: 'power_windows', name: 'Электростеклоподъемники' },
    { id: 'wheel_configuration', name: 'Регулировка руля' },
    { id: 'suspension', name: 'Подвеска', multiple: true },
    { id: 'conditioner', name: 'Кондиционер' },
    { id: 'parktronic', name: 'Парктроник', multiple: true },
    { id: 'camera', name: 'Камера', multiple: true },
    { id: 'onboard_computer', name: 'Бортовой компьютер' },
    { id: 'auxiliary_heater', name: 'Автономный отопитель' },
    { id: 'keyless_entry', name: 'Бесключевой доступ' },
    { id: 'digital_dashboard', name: 'Цифровая приборная панель' },
    {
      id: 'remote_engine_start',
      name: 'Дистанционный запуск двигателя',
    },
    { id: 'multiwheel', name: 'Мультируль' },
    {
      id: 'decklid_electricdrive',
      name: 'Электропривод крышки багажника',
    },
    { id: 'park_assist', name: 'Система активной помощи при парковке' },
  ],
  sight: [
    { id: 'headlamps', name: 'Фары' },
    { id: 'electrical_heating', name: 'Электрообогрев', multiple: true },
    { id: 'fog_lights', name: 'Противотуманные фары' },
    { id: 'light_washer', name: 'Омыватель фар' },
    { id: 'rain_sensor', name: 'Датчик дождя' },
    { id: 'light_sensor', name: 'Датчик света' },
    {
      id: 'adaptive_lighting_system',
      name: 'Система адаптивного освещения',
    },
    {
      id: 'automatic_highbeams_control',
      name: 'Автоматическое управление дальним светом',
    },
  ],
  exterior: [
    { id: 'wheels', name: 'Тип дисков' },
    { id: 'wheels_size', name: 'Размер дисков' },
    { id: 'drawbar', name: 'Фаркоп' },
    { id: 'toned_glasses', name: 'Тонированные стекла' },
  ],
  security: [
    { id: 'alarm_system', name: 'Сигнализация' },
    { id: 'central_locking', name: 'Центральный замок' },
  ],
  interior: [
    { id: 'number_of_seats', name: 'Количество мест' },
    { id: 'compartment_decoration', name: 'Материал салона' },
    { id: 'compartment_color', name: 'Цвет салона' },
    {
      id: 'seat_adjustment',
      name: 'Регулировка сидений',
      multiple: true,
    },
    { id: 'heated_seats', name: 'Подогрев сидений', multiple: true },
    { id: 'seats_aeration', name: 'Вентиляция сидений', multiple: true },
    { id: 'seats_massage', name: 'Сиденья с массажем', multiple: true },
    { id: 'seat_position_memory', name: 'Память положения сидений' },
    { id: 'third_seats_row', name: 'Третий ряд сидений' },
    { id: 'panoramic_roof', name: 'Панорамная крыша' },
    { id: 'hatch', name: 'Люк' },
    {
      id: 'steering_wheel_leather_trim',
      name: 'Отделка кожей рулевого колеса',
    },
    { id: 'steering_wheel_heating', name: 'Обогрев рулевого колеса' },
    {
      id: 'leather_transmission_lever',
      name: 'Отделка кожей рычага КПП',
    },
    { id: 'black_ceiling', name: 'Черный потолок' },
    { id: 'door_closer', name: 'Доводчики дверей' },
    {
      id: 'ceiling_trim_in_alcantara',
      name: 'Отделка потолка алькантарой',
    },
    {
      id: 'front_center_armrest',
      name: 'Передний центральный подлокотник',
    },
    {
      id: 'salon_mirror_with_autodarkening',
      name: 'Салонное зеркало с автозатемнением',
    },
    {
      id: 'electro_foldable_rearview_mirrors',
      name: 'Электроскладывание зеркал заднего вида',
    },
    {
      id: 'electro_controlled_rearview_mirrors',
      name: 'Электрорегулировка зеркал заднего вида',
    },
    {
      id: 'rearview_mirrors_with_automo_antidazzle',
      name: 'Зеркала заднего вида с автозатемнением',
    },
    {
      id: 'shift_paddles',
      name: 'Подрулевые лепестки переключения передач',
    },
  ],
  safety: [
    { id: 'cruise_control', name: 'Круиз-контроль' },
    {
      id: 'auxiliary_system',
      name: 'Вспомогательные системы',
      multiple: true,
    },
    {
      id: 'child_seat_mounting_system',
      name: 'Система крепления детских автокресел',
    },
  ],
};
