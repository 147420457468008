var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.adverts.length
    ? _c("div", { staticClass: "vehicle-form__panel-item" }, [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed",
          },
          [
            _c(
              "a",
              {
                staticClass: "vehicle-form__link vehicle-form__link_primary",
                attrs: { href: _vm.searchUrl, target: "_blank" },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm._f("plural")(_vm.total, [
                        "объявление",
                        "объявления",
                        "объявлений",
                      ])
                    ) +
                    "\n      о продаже\n    "
                ),
              ]
            ),
            _vm._v(
              "\n    " +
                _vm._s(_vm.review.manufacturer.name) +
                " " +
                _vm._s(_vm.review.model.name) +
                "\n    " +
                _vm._s(_vm.review.generation.name) +
                "\n  "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "slider",
            staticClass:
              "vehicle-form__slider vehicle-form__slider_proposal js-slider",
          },
          [
            !_vm.isMobileSpecial
              ? _c("div", {
                  staticClass:
                    "vehicle-form__slider-arrow vehicle-form__slider-arrow_prev swiper-button-prev js-slider-swipper-prev",
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.isMobileSpecial
              ? _c("div", {
                  staticClass:
                    "vehicle-form__slider-arrow vehicle-form__slider-arrow_next swiper-button-next js-slider-swipper-next",
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__slider-box swiper-container js-slider-swipper",
              },
              [
                _c(
                  "div",
                  { staticClass: "vehicle-form__slider-list swiper-wrapper" },
                  _vm._l(_vm.adverts, function (item) {
                    return _c("review-proposals-item", {
                      key: item.id,
                      attrs: { review: _vm.review, item: item },
                    })
                  }),
                  1
                ),
              ]
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }