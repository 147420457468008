
import { Watch } from 'vue-property-decorator';
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { contains } from 'underscore';

import { Dictionaries, DictionaryItem } from '@/services/dictionaries-service';
import { HashMap } from '@/types/common';
import FieldMixin from '@/mixins/create/field';
import float from '@/filters/format-float';
import { powerTypes } from '@/config/dictionaries';

const commonStore = namespace('common');

@Component
export default class StepTechEngineType extends Mixins<FieldMixin>(FieldMixin) {
  private isAllVisible: boolean = false;

  @commonStore.State dictionaries!: Dictionaries;

  private toggle() {
    this.isAllVisible = !this.isAllVisible;
  }

  private get specs() {
    return this.data.specs || {};
  }

  private get engine() {
    return this.specs.engine || {};
  }

  private get power() {
    return this.engine.power || {};
  }

  private get typeError() {
    return (this.errors['specs.engine.type'] || []).join('. ');
  }

  private get capacityError() {
    return (this.errors['specs.engine.capacity'] || []).join('. ');
  }

  private get torqueError() {
    return (this.errors['specs.engine.torque'] || []).join('. ');
  }

  private get powerError() {
    return (this.errors['specs.engine.power.value'] || []).join('. ');
  }

  private isGasAvailable(value: string) {
    return contains(['gasoline'], value);
  }

  private isHybridAvailable(value: string) {
    return contains(['gasoline', 'diesel'], value);
  }

  private isCapacityAvailable(value: string) {
    return contains(['gasoline', 'diesel'], value);
  }

  private setEngineType(value: string) {
    this.clearError({ name: 'specs.engine.type' });
    this.clearError({ name: 'specs.engine.capacity' });

    const engine: HashMap<any> = {
      type: value,
      power: this.power,
      gas: !!(this.isGasAvailable(value) && this.gas),
      hybrid: !!(this.isHybridAvailable(value) && this.hybrid),
    };

    if (this.isCapacityAvailable(value)) {
      engine.capacity = this.engine.capacity;
    }

    this.setField({
      specs: {
        ...this.specs,
        engine,
      },
    });
  }

  private get gas() {
    return this.engine.gas;
  }

  private set gas(value) {
    const engine = { ...this.engine, gas: value };

    this.setField({
      specs: {
        ...this.specs,
        engine,
      },
    });
  }

  private get hybrid() {
    return this.engine.hybrid;
  }

  private set hybrid(value) {
    const engine = { ...this.engine, hybrid: value };

    this.setField({
      specs: {
        ...this.specs,
        engine,
      },
    });
  }

  private get capacity() {
    return this.engine.capacity;
  }

  private set capacity(value) {
    const capacity = float(value.replace(',', '.'));

    this.clearError({ name: 'specs.engine.capacity' });

    if (capacity) {
      const engine = { ...this.engine, capacity };

      this.setField({
        specs: {
          ...this.specs,
          engine,
        },
      });
    } else {
      const { capacity, ...engine } = this.engine;

      this.setField({
        specs: {
          ...this.specs,
          engine,
        },
      });
    }
  }

  private get powerValue() {
    return this.power.value;
  }

  private set powerValue(_value) {
    const value = parseInt(_value);

    this.clearError({ name: 'specs.engine.power.value' });

    if (value && !isNaN(value as number)) {
      const power = { ...this.power, value };
      const engine = { ...this.engine, power };

      this.setField({ specs: { ...this.specs, engine } });
    } else {
      const { value, ...power } = this.power;
      const engine = { ...this.engine, power };

      this.setField({ specs: { ...this.specs, engine } });
    }
  }

  private get powerUnits() {
    return powerTypes;
  }

  private setPowerUnit(value: string) {
    const power = { ...this.power, unit: value };
    const engine = { ...this.engine, power };

    this.setField({ specs: { ...this.specs, engine } });
  }

  private get torque() {
    return this.engine.torque;
  }

  private set torque(value) {
    const torque = parseInt(value);

    this.clearError({ name: 'specs.engine.torque' });

    if (torque) {
      const engine = { ...this.engine, torque };

      this.setField({
        specs: {
          ...this.specs,
          engine,
        },
      });
    } else {
      const { torque, ...engine } = this.engine;

      this.setField({
        specs: {
          ...this.specs,
          engine,
        },
      });
    }
  }
}
