
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { IAdvert } from '@/interfaces/adverts';
import { VuexAction } from '@/types/functions';
import * as moment from 'moment';

import AdvertManageMixin from '@/mixins/adverts/advert-manage';
import AdvertClosePopover from '@/components/common/advert-close-popover.vue';

const advertStore = namespace('advert');

@Component({
  components: {
    AdvertClosePopover,
  },
})
export default class AdvertManage extends Mixins<AdvertManageMixin>(
  AdvertManageMixin
) {
  @advertStore.Action openAdvert!: VuexAction;
  @advertStore.Action closeAdvert!: VuexAction;
  @advertStore.Action upAdvert!: VuexAction;

  private onClose({ closureReason }: { closureReason: string }) {
    this.$gtm.trackEvent({
      event: 'close_ad',
      value: {
        reason: closureReason,
      },
    });

    this.closeAdvert({
      id: this.advert.id,
      closureReason,
      success: () => {
        this.$refs.closeAdvertPopup.closePopup();
      },
    });
  }
}
