var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__diagram" }, [
    _c(
      "div",
      {
        staticClass:
          "vehicle-form__diagram-value vehicle-form__diagram-value_primary",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_success",
            class: _vm.isSmall
              ? "vehicle-form__description_small"
              : "vehicle-form__description_base-alter",
          },
          [_vm._v("\n      " + _vm._s(_vm.positiveValue) + "\n    ")]
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "vehicle-form__diagram-line" }, [
      _c("div", {
        staticClass: "vehicle-form__diagram-fill",
        style: `width: ${_vm.percentagePositive}%;`,
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "vehicle-form__diagram-value vehicle-form__diagram-value_secondary",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_error-alter",
            class: _vm.isSmall
              ? "vehicle-form__description_small"
              : "vehicle-form__description_base-alter",
          },
          [_vm._v("\n      " + _vm._s(_vm.negativeValue) + "\n    ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }