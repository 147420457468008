var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "vehicle-form__row vehicle-form__row_extended-other vehicle-form__row_choose",
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "vehicle-form__line vehicle-form__line_condensed-other",
        },
        [
          _c("div", { staticClass: "vehicle-form__group" }, [
            _c("div", { staticClass: "vehicle-form__field" }, [
              _c(
                "div",
                {
                  staticClass:
                    "input-style__combo input-style__combo_stretch vehicle-form__input-combo vehicle-form__input-combo_width_full",
                },
                _vm._l(_vm.dictionaries.state, function (item) {
                  return _c(
                    "a",
                    {
                      key: item.id,
                      staticClass:
                        "button-style button-style_more button-style_base button-style_bordered vehicle-form__button vehicle-form__button_choose",
                      class: { "button-style_active": item.id === _vm.state },
                      on: {
                        click: function ($event) {
                          return _vm.setState(item.id)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm._f("capitalize")(item.name)))]
                  )
                }),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.isMobileSpecial && _vm.error
            ? _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_full",
                },
                [_vm._v(_vm._s(_vm.error))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "vehicle-form__group vehicle-form__group_width_auto",
            },
            [
              _c("div", { staticClass: "vehicle-form__field" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__line vehicle-form__line_condensed-other",
                  },
                  [
                    _c("ul", { staticClass: "vehicle-form__checkbox-list" }, [
                      _c(
                        "li",
                        {
                          staticClass:
                            "vehicle-form__checkbox-item vehicle-form__checkbox-item_inline",
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "vehicle-form__checkbox-label" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "i-checkbox vehicle-form__checkbox vehicle-form__checkbox_base",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.warranty,
                                        expression: "warranty",
                                      },
                                    ],
                                    staticClass: "i-checkbox__real",
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(_vm.warranty)
                                        ? _vm._i(_vm.warranty, null) > -1
                                        : _vm.warranty,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.warranty,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.warranty = $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.warranty = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.warranty = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "i-checkbox__faux",
                                  }),
                                  _vm._v(" "),
                                  _vm._m(1),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.isMobileSpecial && _vm.error
        ? _c("div", { staticClass: "vehicle-form__line" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_full",
              },
              [_vm._v(_vm._s(_vm.error))]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("Состояние"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__checkbox-text" }, [
      _c("div", { staticClass: "vehicle-form__checkbox-sign" }, [
        _vm._v("На гарантии"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }