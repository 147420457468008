var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__step-list" },
    [
      _c(
        "step-wrapper",
        {
          ref: "tech",
          attrs: {
            step: "tech",
            "statuses-by-step": _vm.statusesByStep,
            errors: _vm.errors,
          },
        },
        [
          !_vm.isMobileSpecial
            ? _c("step-tech-header", {
                attrs: {
                  mode: _vm.mode,
                  "statuses-by-step": _vm.statusesByStep,
                  "manufacturer-id": _vm.data.manufacturerId,
                  "manufacturer-name": _vm.manufacturerName,
                  "model-id": _vm.data.modelId,
                  "model-name": _vm.modelName,
                },
                on: {
                  "reset-manufacturer": _vm.onResetManufacturer,
                  "reset-model": _vm.onResetModel,
                },
              })
            : _c("step-tech-header-mobile", {
                attrs: {
                  mode: _vm.mode,
                  "statuses-by-step": _vm.statusesByStep,
                  "manufacturer-id": _vm.data.manufacturerId,
                  "manufacturer-name": _vm.manufacturerName,
                  "model-id": _vm.data.modelId,
                  "model-name": _vm.modelName,
                },
              }),
          _vm._v(" "),
          _vm.isMobileSpecial || !_vm.data.manufacturerId
            ? [
                _c(_vm.componentStepTechManufacturer, {
                  tag: "component",
                  attrs: {
                    data: _vm.data,
                    mode: _vm.mode,
                    dictionaries: _vm.dictionaries,
                    errors: _vm.errors[_vm.errorMapTech.manufacturer],
                  },
                  on: { change: _vm.onManufacturerChange },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isMobileSpecial || (_vm.data.manufacturerId && !_vm.data.modelId)
            ? [
                !_vm.isMobileSpecial
                  ? _c("step-tech-model", {
                      attrs: {
                        data: _vm.data,
                        mode: _vm.mode,
                        "models-by-manufacturer-id":
                          _vm.dictionaries[_vm.urlSelectedManufacturer] || [],
                      },
                      on: { change: _vm.onModelChange },
                    })
                  : _c("step-tech-model-mobile", {
                      attrs: {
                        data: _vm.data,
                        mode: _vm.mode,
                        "manufacturer-id": _vm.data.manufacturerId,
                        dictionaries: _vm.dictionaries,
                        "models-by-manufacturer-id":
                          _vm.dictionaries[_vm.urlSelectedManufacturer],
                        errors: _vm.errors[_vm.errorMapTech.model],
                      },
                      on: { change: _vm.onModelChange },
                    }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isMobileSpecial || _vm.data.modelId
            ? [
                _c(_vm.componentStepTechYear, {
                  tag: "component",
                  attrs: {
                    data: _vm.data,
                    mode: _vm.mode,
                    errors: _vm.errors[_vm.errorMapTech.year],
                  },
                  on: { change: _vm.onYearChange },
                }),
                _vm._v(" "),
                _vm.data.manufacturerId &&
                _vm.data.modelId &&
                _vm.data.specs.year
                  ? _c("step-tech-generation", {
                      attrs: {
                        data: _vm.data,
                        mode: _vm.mode,
                        "generations-by-model":
                          _vm.dictionaries[_vm.urlSelectedModel],
                        "model-name": _vm.modelName,
                        year: _vm.data.specs.year,
                        errors: _vm.errors[_vm.errorMapTech.generation],
                      },
                      on: { change: _vm.onGenerationChange },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isMobileSpecial
                  ? _c("step-tech-body-type", {
                      attrs: {
                        data: _vm.data,
                        mode: _vm.mode,
                        "body-types": _vm.dictionaries.bodyType,
                        errors: _vm.errors[_vm.errorMapTech.bodyType],
                      },
                      on: { change: _vm.onBodyTypeChange },
                    })
                  : _c("step-tech-body-type-mobile", {
                      attrs: {
                        data: _vm.data,
                        mode: _vm.mode,
                        dictionaries: _vm.dictionaries,
                        errors: _vm.errors[_vm.errorMapTech.bodyType],
                      },
                      on: { change: _vm.onBodyTypeChange },
                    }),
                _vm._v(" "),
                _c("step-tech-engine", {
                  attrs: {
                    data: _vm.data,
                    mode: _vm.mode,
                    "engine-types": _vm.dictionaries.engineType,
                    errors: _vm.errors,
                  },
                  on: {
                    change: _vm.onEngineChange,
                    "change-engine-type": _vm.onEngineTypeChange,
                    "change-capacity": _vm.onEngineCapacityChange,
                    "change-power": _vm.onEnginePowerChange,
                    "change-power-unit": _vm.onEnginePowerUnitChange,
                    "change-torque": _vm.onEngineTorqueChange,
                  },
                }),
                _vm._v(" "),
                _c("step-tech-drivetrain", {
                  attrs: {
                    data: _vm.data,
                    mode: _vm.mode,
                    "list-drivetrain": _vm.dictionaries.drivetrain,
                    errors: _vm.errors[_vm.errorMapTech.drivetrain],
                  },
                  on: { change: _vm.onDrivetrainChange },
                }),
                _vm._v(" "),
                _c("step-tech-transmission", {
                  attrs: {
                    data: _vm.data,
                    mode: _vm.mode,
                    transmissions: _vm.dictionaries.transmission,
                    errors: _vm.errors[_vm.errorMapTech.transmission],
                  },
                  on: { change: _vm.onTransmissionChange },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__control vehicle-form__control_condensed-supererogatory",
                  },
                  [
                    _vm.isEdit
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "button-style button-style_primary button-style_middle vehicle-form__button vehicle-form__button_width_sssm",
                            on: { click: _vm.onFinishClick },
                          },
                          [_vm._v("\n          Подтвердить\n        ")]
                        )
                      : _c(
                          "a",
                          {
                            ref: "next",
                            staticClass:
                              "button-style button-style_primary button-style_middle vehicle-form__button vehicle-form__button_width_xxss",
                            on: { click: _vm.onNextClick },
                          },
                          [_vm._v("\n          Далее\n        ")]
                        ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "step-wrapper",
        {
          attrs: {
            step: "description",
            "statuses-by-step": _vm.statusesByStep,
            errors: _vm.errors,
          },
        },
        [
          _c("step-description-header", {
            attrs: {
              mode: _vm.mode,
              "statuses-by-step": _vm.statusesByStep,
              images: _vm.data.images,
              tenure: _vm.tenure,
              odometer: _vm.data.specs.odometer,
              videos: _vm.data.videos,
              summary: _vm.data.summary,
              "rating-average": _vm.data.avgGrade || _vm.data.ratingAverage,
              pros: _vm.data.secondaryMarks && _vm.data.secondaryMarks.pros,
              cons: _vm.data.secondaryMarks && _vm.data.secondaryMarks.cons,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }