import { ActionTree } from 'vuex';
import { isEmpty } from 'underscore';

import vmApi from '@/api/vm-api';
import { IAdvertState, IRootState } from '@/interfaces/store';
import { AdvertResponse } from '@/types/api';
import { IOnlineStatus, IAdvert } from '@/interfaces/adverts';

const actions: ActionTree<IAdvertState, IRootState> = {
  clearAdvert(context) {
    context.commit('setAdvert', {});
    context.commit('setSimilar', []);
  },

  updateAdvert(context, { id, success, error }) {
    vmApi.getAdvert(id, {
      success({ data }: AdvertResponse) {
        context.commit('setAdvert', data);

        success && success(data);
      },
      error,
    });
  },

  updatePhones(context, { id, success, error }) {
    vmApi.getAdvertPhones(id, {
      success({ data }: any) {
        context.commit('setPhones', data);

        success && success(data);
      },
      error,
    });
  },

  updateVin(context, { id, success, error }) {
    vmApi.getAdvertVin(id, {
      success({ data: { vin } }: { data: { vin: string } }) {
        context.commit('setVin', vin);

        success && success(vin);
      },
      error,
    });
  },

  updateSimilar(context, { id, success, error }) {
    vmApi.getAdvertSimilar(id, {
      success({ data }: any) {
        context.commit('setSimilar', data);

        success && success(data);
      },
      error,
    });
  },

  updateStats(context, { id, success }) {
    vmApi.getAdvertStats(id, {
      success({ data }: AdvertResponse) {
        context.commit('setStats', data);

        success && success(data);
      },
    });
  },

  addToBookmarks(context, { id, onError }) {
    vmApi.addToBookmarks(id, {
      success() {
        context.commit('patchAdvert', {
          inBookmarks: true,
        });
      },
      error({ response }: any) {
        onError && onError(response);
      },
    });
  },

  removeFromBookmarks(context, { id }) {
    vmApi.removeFromBookmarks(id, {
      success() {
        context.commit('patchAdvert', {
          inBookmarks: false,
        });
      },
    });
  },

  openAdvert(context, { id }) {
    vmApi.openAdvert(id, {
      success({ data }: AdvertResponse) {
        context.commit('setAdvert', data);
      },
    });
  },

  upAdvert(context, { id }) {
    vmApi.upAdvert(id, {
      success({ data }: AdvertResponse) {
        context.commit('setAdvert', data);
      },
    });
  },

  closeAdvert(context, { id, closureReason, success }) {
    vmApi.closeAdvert(id, closureReason, {
      success({ data }: AdvertResponse) {
        context.commit('setAdvert', data);

        success && success();
      },
    });
  },

  updateUpTimer(context, { upAvailableIn }) {
    context.commit('patchAdvert', {
      upAvailableIn,
    });

    if (upAvailableIn === 0) {
      context.commit('patchPermissions', {
        permissions: {
          up: true,
        },
      });
    }
  },

  updateOnlineStatus(
    context,
    { onlineStatus }: { onlineStatus: IOnlineStatus }
  ) {
    if (isEmpty(context.state.advert)) {
      return;
    }

    const author = {
      ...(context.state.advert as IAdvert).author,
      ...{ onlineStatus },
    };

    context.commit('patchAdvert', {
      author,
    });
  },

  complain(context, { id, subject, text, success, error, complete }) {
    vmApi.advertComplain(id, { subject, text }, { success, error, complete });
  },
};

export default actions;
