var render = function render(_c, _vm) {
  return _c(
    "div",
    { staticClass: "vehicle-form__mediabox vehicle-form__mediabox_reviews" },
    [
      _c("div", { staticClass: "vehicle-form__mediabox-flex" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__mediabox-part vehicle-form__mediabox-part_content",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed-other",
              },
              [_vm._v("\n        Оставьте отзыв\n      ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base",
              },
              [
                _vm._v(
                  "\n        Для пользователей Автобарахолки ваш отзыв может стать решающим при\n        выборе автомобиля\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__control vehicle-form__control_condensed-additional",
              },
              [
                _c(
                  "router-link",
                  {
                    directives: [
                      {
                        name: "gtm",
                        rawName: "v-gtm",
                        value: { event: "create_review" },
                        expression: "{ event: 'create_review' }",
                      },
                    ],
                    staticClass:
                      "button-style button-style_primary button-style_small vehicle-form__button",
                    attrs: { to: { name: "create-review" } },
                  },
                  [_vm._v("\n          Написать отзыв\n        ")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function (_c, _vm) {
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__mediabox-part vehicle-form__mediabox-part_image helpers_hide_tablet",
      },
      [
        _c("div", { staticClass: "vehicle-form__preview" }, [
          _c("div", {
            staticClass: "vehicle-form__image vehicle-form__image_suv",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }