
import { Vue, Component } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { ISearchAdvert, IAdvertSpecs } from '@/interfaces/adverts';
import * as moment from 'moment';
import { contains, isArray, isEmpty } from 'underscore';

import { HashMap } from '@/types/common';
import generalConfig from '@/config/general';
import number from '@/filters/format-number';
import currency from '@/filters/format-currency';
import capitalize from '@/filters/format-capitalize';
import plural from '@/filters/plural-form';
import location from '@/filters/format-location';
import date from '@/filters/format-date';
import relativeDate from '@/filters/format-relative-date';
import SearchAdvertsListPhotos from '@/components/search/search-adverts-list-photos.vue';
import SearchAdvertsListBookmark from '@/components/search/search-adverts-list-bookmark.vue';
import SearchAdvertsListPremium from '@/components/search/search-adverts-list-premium.vue';
import { Dictionaries, DictionaryItem } from '@/services/dictionaries-service';
import { odometerTypes } from '@/config/dictionaries';

const commonStore = namespace('common');
const createStore = namespace('create');

@Component({
  components: {
    SearchAdvertsListPhotos,
    SearchAdvertsListBookmark,
    SearchAdvertsListPremium,
  },
  filters: {
    number,
    currency,
    plural,
    capitalize,
    location,
    date,
    relativeDate,
  },
})
export default class AdvertPreview extends Vue {
  @commonStore.Getter getDictionaryItem!: (
    type: string,
    key: string
  ) => DictionaryItem;
  @createStore.State data!: HashMap<any>;

  private get advert() {
    return this.data;
  }

  private get isPreviewVisible() {
    return !isEmpty(this.data);
  }

  private get specs(): IAdvertSpecs {
    return this.advert.specs || {};
  }

  private get dealTerms() {
    return this.advert.dealTerms || {};
  }

  private get title(): string {
    const manufacturer = this.getDictionaryItem(
      'manufacturer',
      this.advert.manufacturerId
    );

    const model = this.getDictionaryItem('model', this.advert.modelId);
    const generation = this.getDictionaryItem(
      'generation',
      this.advert.generationId
    );

    return [
      manufacturer.name,
      model.name,
      generation.name,
      this.specs.modification,
    ]
      .filter((item) => item)
      .join(' ');
  }

  private get locationObject() {
    const country = this.getDictionaryItem('country', this.advert.country);
    const region = this.getDictionaryItem('region', this.advert.region);
    const city = this.getDictionaryItem('city', this.advert.cityId);

    return {
      country,
      region,
      city,
    };
  }

  private get engine(): string {
    return this.getDictionaryItem('fuel', this.specs.engine.type).name;
  }

  private get drivetrain(): string {
    return this.getDictionaryItem('drivetrain', this.specs.drivetrain).name;
  }

  private get bodyType(): string {
    return this.getDictionaryItem('bodyType', this.specs.bodyType).name;
  }

  private get transmission(): string {
    return this.getDictionaryItem('transmission', this.specs.transmission).name;
  }

  private get color(): string {
    return this.getDictionaryItem('color', this.specs.color).name;
  }

  private get isPremium(): boolean {
    return this.advert.isPremium;
  }

  private get odometerUnit(): string {
    const unit = odometerTypes[this.specs.odometer.unit];

    return unit;
  }

  private get optionsCount() {
    const equipment = this.advert.equipment;

    if (!equipment) {
      return 0;
    }

    let count = 0;

    for (let key of Object.keys(equipment)) {
      if (equipment[key]) {
        count += isArray(equipment[key])
          ? (equipment[key] as Array<string>).length
          : 1;
      }
    }

    return count;
  }
}
