var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "vehicle-form__row vehicle-form__row_condensed" },
      [
        _c("div", { staticClass: "vehicle-form__group" }, [
          _c("div", { staticClass: "vehicle-form__field" }, [
            _c(
              "div",
              {
                staticClass: "input-style__wrapper vehicle-form__input-wrapper",
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search,
                      expression: "search",
                    },
                  ],
                  staticClass:
                    "input-style input-style_primary input-style_base vehicle-form__input vehicle-form__input_width_xxxxllll",
                  attrs: {
                    type: "text",
                    placeholder: "Найти марку. Например, Volkswagen",
                  },
                  domProps: { value: _vm.search },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.search = $event.target.value
                    },
                  },
                }),
              ]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "vehicle-form__row vehicle-form__row_condensed" },
      [
        _c("div", { staticClass: "vehicle-form__group" }, [
          _c("div", { staticClass: "vehicle-form__field" }, [
            _vm.manufacturers.length
              ? _c(
                  "ul",
                  {
                    staticClass:
                      "vehicle-form__checkbox-list vehicle-form__checkbox-list_brand vehicle-form__checkbox-list_brand_indent vehicle-form__checkbox-list_width_xxxxllll",
                    class: {
                      "vehicle-form__checkbox-list_opened": _vm.isAllVisible,
                    },
                  },
                  [
                    _vm._l(_vm.manufacturers, function (item) {
                      return _c(
                        "li",
                        {
                          key: `manufacturer_${item.id}`,
                          staticClass:
                            "vehicle-form__checkbox-item vehicle-form__checkbox-item_brand",
                          class: `vehicle-form__checkbox-item_brand_${item.slug}-alter`,
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "vehicle-form__checkbox-label" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "i-checkbox vehicle-form__checkbox vehicle-form__checkbox_base",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.manufacturer,
                                        expression: "manufacturer",
                                      },
                                    ],
                                    staticClass: "i-checkbox__real",
                                    attrs: {
                                      type: "radio",
                                      name: "manufacturer",
                                    },
                                    domProps: {
                                      value: item.id,
                                      checked: _vm._q(
                                        _vm.manufacturer,
                                        item.id
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.manufacturer = item.id
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__checkbox-text",
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    !_vm.filterString
                      ? _c(
                          "li",
                          {
                            staticClass:
                              "vehicle-form__checkbox-item vehicle-form__checkbox-item_brand vehicle-form__checkbox-item_brand_all",
                            on: { click: _vm.onToggleClick },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "vehicle-form__checkbox-label" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "i-checkbox vehicle-form__checkbox vehicle-form__checkbox_base-alter",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__checkbox-text",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__checkbox-sign vehicle-form__checkbox-sign_on",
                                          },
                                          [
                                            _vm._v(
                                              "Показать все (" +
                                                _vm._s(
                                                  _vm.dictionaries.manufacturer
                                                    .length
                                                ) +
                                                ")"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__checkbox-sign vehicle-form__checkbox-sign_off",
                                          },
                                          [_vm._v("Свернуть")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                )
              : _vm.filterString
              ? _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_break vehicle-form__description_max-width_xxxxllll",
                  },
                  [_vm._v("Ничего не найдено")]
                )
              : _vm._e(),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }