
import { Mixins, Component } from 'vue-mixin-decorator';

import FieldMixin from '@/mixins/create/field';
import StorageMixin from '@/mixins/create/storage';

interface IMixins extends FieldMixin, StorageMixin {}

@Component
export default class StepContactsSellerContactName extends Mixins<IMixins>(
  FieldMixin,
  StorageMixin
) {
  private get seller() {
    return this.data.seller || {};
  }

  private get contactName() {
    return this.seller.contactName;
  }

  private set contactName(contactName) {
    this.clearError({ name: 'seller.contactName' });

    if (contactName) {
      this.setField({ seller: { ...this.seller, contactName } });
    } else {
      const { contactName, ...seller } = this.seller;

      this.setField({ seller });
    }
  }

  private get error() {
    return (this.errors['seller.contactName'] || []).join('. ');
  }
}
