
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import DropdownMixin from '@/mixins/common/dropdown';

@Component
export default class AdvertClosePopover extends Mixins<DropdownMixin>(
  DropdownMixin
) {
  private onClose() {
    this.closeDropdown();
    this.$emit('close');
  }
}
