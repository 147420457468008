var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__benefit-box" }, [
    _c("div", { staticClass: "vehicle-form__benefit-flex" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__benefit-part vehicle-form__benefit-part_1",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-either",
            },
            [_vm._v("\n        Был ли отзыв полезен?\n      ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__control vehicle-form__control_condensed-supernumerary",
            },
            [
              _vm.review.myMark
                ? [
                    _c(
                      "span",
                      {
                        staticClass:
                          "button-style button-style_auxiliary button-style_small vehicle-form__button vehicle-form__button_likes vehicle-form__button_likes-alter vehicle-form__button_narrow",
                        class: {
                          "vehicle-form__button_likes-active":
                            _vm.review.myMark === "like",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onMarkClick("like")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.marks.like) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "button-style button-style_auxiliary button-style_small vehicle-form__button vehicle-form__button_dislikes vehicle-form__button_dislikes-alter vehicle-form__button_narrow",
                        class: {
                          "vehicle-form__button_dislikes-active":
                            _vm.review.myMark === "dislike",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onMarkClick("dislike")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.marks.dislike) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                : [
                    _c(
                      "span",
                      {
                        staticClass:
                          "button-style button-style_auxiliary button-style_small vehicle-form__button vehicle-form__button_likes vehicle-form__button_narrow",
                        on: {
                          click: function ($event) {
                            return _vm.onMarkClick("like")
                          },
                        },
                      },
                      [_vm._v("\n            Да\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "button-style button-style_auxiliary button-style_small vehicle-form__button vehicle-form__button_dislikes vehicle-form__button_narrow",
                        on: {
                          click: function ($event) {
                            return _vm.onMarkClick("dislike")
                          },
                        },
                      },
                      [_vm._v("\n            Нет\n          ")]
                    ),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _vm.marks.like
            ? _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_small vehicle-form__description_other",
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("plural")(_vm.marks.like, [
                          "пользователь",
                          "пользователя",
                          "пользователей",
                        ])
                      ) +
                      "\n        " +
                      _vm._s(
                        _vm._f("plural")(
                          _vm.marks.like,
                          ["считает", "считают", "считают"],
                          false
                        )
                      ) +
                      "\n        этот отзыв полезным\n      "
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }