
  import { Mixins, Component } from 'vue-mixin-decorator';
  import { namespace } from 'vuex-class';
  import { isEmpty } from 'underscore';
  import { first, shuffle } from 'underscore';

  import { VuexAction } from '@/types/functions';
  import { IAdvert } from '@/interfaces/adverts';
  import webSocketService from '@/services/ws-service';
  import AdvertStub from '@/components/advert/advert-stub.vue';
  import AdvertBreadcrumbs from '@/components/advert/advert-breadcrumbs.vue';
  import AdvertHeader from '@/components/advert/advert-header.vue';
  import AdvertAction from '@/components/advert/advert-action.vue';
  import AdvertSpecs from '@/components/advert/advert-specs.vue';
  import AdvertSeller from '@/components/advert/advert-contacts.vue';
  import AdvertDescription from '@/components/advert/advert-description.vue';
  import AdvertSimilar from '@/components/advert/advert-similar.vue';
  import AdvertPerson from '@/components/advert/advert-person.vue';
  import AdvertManage from '@/components/advert/advert-manage.vue';
  import AdvertEquipment from '@/components/advert/advert-equipment.vue';
  import AdvertLeasingForm from '@/components/advert/advert-leasing-form.vue';
  import AdvertNotFound from '@/components/advert/advert-not-found.vue';
  import VideoBanner from '@/components/common/video-banner.vue';
  import BannerMobileInline from '@/components/common/banner-mobile-inline.vue';
  import Commercial from '@/components/common/commercial.vue';
  import AdvertMetaMixin from '@/mixins/adverts/advert-meta';
  import IsMobileMixin from '@/mixins/common/is-mobile';
  import Gallery from '@/components/common/gallery.vue';
  import GtmMixin from '@/mixins/common/gtm';
  import { IUser } from '@/interfaces/common';
  import JsonLd from '@/components/common/json-ld.vue';
  import { HashMap } from '@/types/common';
  import vmApi from '@/api/vm-api';
  import { Review, ReviewsRating } from '@/types/review';
  import RatingHeader from '@/components/common/rating-reviews/rating-header.vue';
  import Rating from '@/components/common/rating-reviews/rating.vue';
  import scrollService from '@/services/scroll-service';
  import ReviewCardVertical from '@/components/reviews/common/review-card-vertical.vue';
  import ReviewCardLite from '@/components/reviews/common/review-card-lite.vue';

  const advertStore = namespace('advert');
  const commonStore = namespace('common');
  const userStore = namespace('user');

  interface IMixins extends AdvertMetaMixin, IsMobileMixin, GtmMixin {}

  @Component({
    components: {
      ReviewCardLite,
      ReviewCardVertical,
      Rating,
      RatingHeader,
      AdvertStub,
      AdvertBreadcrumbs,
      AdvertHeader,
      AdvertAction,
      AdvertPerson,
      AdvertSpecs,
      AdvertSeller,
      AdvertDescription,
      AdvertSimilar,
      AdvertManage,
      AdvertEquipment,
      AdvertLeasingForm,
      AdvertNotFound,
      BannerMobileInline,
      Commercial,
      Gallery,
      JsonLd,
      VideoBanner,
    },
    metaInfo(): any {
      return {
        title: 'Автобарахолка',
        meta: [
          {
            name: 'description',
            content: '',
          },
        ],
        link: [],
      };
    },
  })
  export default class AdvertPage extends Mixins<IMixins>(
    AdvertMetaMixin,
    IsMobileMixin,
    GtmMixin,
  ) {
    private isAdvertNotFound: boolean = false;
    private ld: HashMap<any> | null = null;
    private reviewsRating: ReviewsRating | null = null;

    @commonStore.Action setDictionary!: VuexAction;
    @advertStore.Action updateAdvert!: VuexAction;
    @advertStore.Action updateSimilar!: VuexAction;
    @advertStore.Action clearAdvert!: VuexAction;
    @advertStore.Action updateOnlineStatus!: VuexAction;
    @advertStore.State advert!: IAdvert;
    @userStore.State currentUser!: IUser;

    private created() {
      this.updateAdvert({
        id: this.$route.params.id,
        success: (data: IAdvert) => {
          const { manufacturer, model, generation, specs } = data;

          if (
            this.$route.name === 'advert' &&
            (manufacturer.slug !== this.$route.params.manufacturer ||
              model.slug !== this.$route.params.model)
          ) {
            this.$router.push({ name: 'not-found' });
          }

          ['new', 'owned'].indexOf(specs.state) !== -1 &&
            this.updateSimilar({
              id: this.$route.params.id,
            });

          this.subscribeWebSockets(data.author.id);
          this.updateMeta();
          this.updateGtm();
          this.setLd();
          this.getReviewsRating({ manufacturer, model, generation });
        },
        error: ({ response }: any) => {
          if (response.status === 403 || response.status === 404) {
            this.isAdvertNotFound = true;
          }
        },
      });
    }

    private destroyed() {
      this.clearAdvert();
    }

    private get isGameEnabled() {
      return (window as any).isGameEnabled;
    }

    private setLd() {
      if (!this.advert) {
        return;
      }

      const ld: HashMap<any> = {
        image: this.advert.images[0]['100x100'],
        offers: {
          '@type': 'Offer',
          priceCurrency: 'BYN',
          price: this.advert.price.converted.BYN.amount,
        },
      };
      const { manufacturer, model } = this.advert;

      ld.brand = manufacturer.name;
      ld.name = `${manufacturer.name} ${model.name}`;

      this.ld = ld;
    }

    protected get isOwnAdvert() {
      return this.currentUser && this.currentUser.id === this.advert.author.id;
    }

    private get isAdvertEmpty() {
      return isEmpty(this.advert);
    }

    private get isAdvertPhotosVisible(): boolean {
      return !!this.advert.images;
    }

    private get permissions() {
      return this.advert.permissions;
    }

    private get isManageVisible() {
      return (
        this.permissions &&
        (this.permissions.open ||
          this.permissions.close ||
          this.permissions.up ||
          this.permissions.edit)
      );
    }

    private get hasAnyEquipment() {
      return !isEmpty(this.advert.equipment);
    }

    private get promotionLink() {
      return {
        name: 'promote',
        query: {
          entity: this.advert.id,
        },
      };
    }

    private get exclusiveLeasing() {
      return first(shuffle(this.advert.leasings.exclusive));
    }

    private get isRatingHeaderVisible() {
      return !this.isOwnAdvert && this.reviewsRating;
    }

    private subscribeWebSockets(userId: string) {
      webSocketService.trackUserActivity(userId, {
        'activity.updated': ({
          status,
          last_activity,
        }: {
          status: number;
          last_activity: string;
        }) => {
          this.updateOnlineStatus({
            onlineStatus: {
              isOnline: !!status,
              lastActivityAt: last_activity,
            },
          });
        },
      });
    }

    private updateGtm() {
      const { id, location, specs, price, manufacturer, seller, author } =
        this.advert;

      this.setPageData({
        ad_id: id,
        ad_region: `${location.country.id}_${location.region.id}_${location.city.id}`,
        ad_condition: specs.state,
        ad_price: price.converted.USD.amount,
        ad_currency: 'USD',
        ad_mfr: manufacturer.id,
        seller_id: author.id.toString(),
        seller_type: seller.type,
        user_id: this.currentUser ? this.currentUser.id.toString() : '',
      });

      this.$gtm.trackEvent({
        event: 'content-view',
        'content-view-name': 'ad_view',
      });
    }

    private getReviewsRating({ manufacturer, model, generation }) {
      this.reviewsRating = null;

      const params = {
        car: {
          manufacturer: manufacturer.id,
          model: model.id,
          generation: generation.id,
        },
        include: 'stats,reviews',
      };

      vmApi.getReviewsRating(params, {
        success: ({ data: reviewsRating }: { data: ReviewsRating }) => {
          if (reviewsRating.count < 1) return;
          if (reviewsRating.stats && reviewsRating.count < 3) {
            reviewsRating.stats.cons = [];
            reviewsRating.stats.pros = [];
          }

          this.reviewsRating = {
            ...reviewsRating,
            ratingBy: 'модели',
            car: {
              manufacturer,
              model,
              generation,
            },
          };
        },
      });
    }

    private onRatingHeaderSpecificsClick() {
      scrollService.scrollTo('#rating');
    }

    public onReviewClick(review: Review) {
      const dataLayer = (window as any).dataLayer;

      dataLayer &&
        dataLayer.push({
          event: 'select_item',
          items: [
            {
              item_name: review.summary,
              item_id: review.id,
              item_brand: review.manufacturer,
              item_category: review.model,
              item_variant: review.specs.year,
              creative_slot: 'review',
              quantity: 1,
            },
          ],
          item_list_name: 'Avtobaraholka - Reviews on Ad',
          item_list_id: this.advert.id,
        });
    }
  }
