
  import * as $ from 'jquery';
  import { Vue, Prop, Watch, Component } from 'vue-property-decorator';

  import keys from '@/config/keys';

  const modifiers = {
    bodyStatePopupOpened: 'body_state-popup-style_opened',
  };

  @Component
  export default class Popup extends Vue {
    private body: any = $('body');
    private document: any = $(document);
    private window: any = $(window);

    @Prop() classes!: Array<string>;
    @Prop({
      default: true,
    })
    hasClose!: boolean;

    private isOpened: boolean = false;
    private $popup?: any;

    private mounted() {
      this.$popup = $(this.$refs.popup);

      $(this.$el).appendTo('body');

      this.subscribeEvents();
    }

    private updatePopupPosition() {
      setTimeout(() => {
        this.$popup.css({
          top: (this.window.height() - this.$popup.outerHeight()) / 2,
        });
      }, 0);
    }

    public closePopup() {
      this.$emit('popup:closed');

      this.isOpened = false;
    }

    public openPopup() {
      this.isOpened = true;
    }

    private subscribeEvents() {
      this.document.on('keydown', (event: KeyboardEvent) => {
        event.which === keys.esc && this.closePopup();
      });

      this.window.on('orientationchange', () => {
        this.closePopup();
      });
    }

    private onContainerClick(event: MouseEvent) {
      if (
        (event!.target! as Node).contains(this.$refs.popup as Node) &&
        !(event!.target! as Node).isEqualNode(this.$refs.popup as Node)
      ) {
        this.closePopup();
      }
    }

    @Watch('isOpened') onOpenedChanged(value: boolean) {
      if (value) {
        this.body.addClass(modifiers.bodyStatePopupOpened);
      } else {
        this.body.removeClass(modifiers.bodyStatePopupOpened);
      }
    }
  }
