var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a",
    {
      directives: [
        {
          name: "gtm",
          rawName: "v-gtm",
          value: {
            event: "leasing_click",
            value: { company: _vm.item.company },
          },
          expression:
            "{event: 'leasing_click', value: { company: item.company }}",
        },
      ],
      staticClass: "vehicle-form__leasing-item",
      on: { click: _vm.onClick },
    },
    [
      _c("div", { staticClass: "vehicle-form__leasing-flex" }, [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__leasing-part vehicle-form__leasing-part_1",
          },
          [
            _c("div", { staticClass: "vehicle-form__preview" }, [
              _c("div", {
                staticClass: "vehicle-form__image",
                style: _vm._f("background")(_vm.item.image),
              }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__leasing-part vehicle-form__leasing-part_2",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_primary-alter vehicle-form__description_base-alter vehicle-form__description_condensed-alter",
              },
              [
                _vm._v(
                  "от " +
                    _vm._s(_vm._f("currency")(_vm.item.cost, "BYN")) +
                    " / мес."
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_other vehicle-form__description_tiny",
              },
              [
                _vm._v(
                  _vm._s(
                    _vm._f("plural")(_vm.item.payment.period, [
                      "месяц",
                      "месяца",
                      "месяцев",
                    ])
                  )
                ),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }