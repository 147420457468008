var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.iconClass
    ? _c(
        "div",
        {
          staticClass:
            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold",
          class: _vm.iconClass,
        },
        [_vm._v("\n  " + _vm._s(_vm.item.title) + "\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }