export default (value: string): string => {
  if (!value) {
    return '';
  }

  return `${value
    .toString()
    .slice(0, 1)
    .toUpperCase()}${value.slice(1)}`;
};
