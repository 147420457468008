
import { Mixins, Component } from 'vue-mixin-decorator';
import { State, Action, namespace } from 'vuex-class';
import { ISearchAdvert } from '@/interfaces/adverts';
import { VuexAction } from '@/types/functions';

import { GoogleBanner } from '@onlinerby/js-vue-components';
import { ScreenService } from '@onlinerby/js-common';

import SearchAdvertsListItem from '@/components/search/search-adverts-list-item.vue';
import SearchAdvertsListItemStub from '@/components/search/search-adverts-list-item-stub.vue';
import NoResults from '@/components/common/no-results.vue';
import IsMobileMixin from '@/mixins/common/is-mobile';
import { searchList } from '@/config/banners';

const searchStore = namespace('search');

@Component({
  components: {
    GoogleBanner,
    SearchAdvertsListItem,
    SearchAdvertsListItemStub,
    NoResults,
  },
})
export default class SearchAdvertsList extends Mixins<IsMobileMixin>(
  IsMobileMixin
) {
  private isAllLoaded = false;
  private stubCount = 0;

  @searchStore.State advertsList!: Array<ISearchAdvert>;
  @searchStore.State isLoading!: boolean;

  private created() {
    this.stubCount = this.isMobileSpecial ? 30 : 60;
  }

  private get adverts() {
    return this.advertsList;
  }

  private get bannerKeys() {
    return Object.keys(searchList).map((item) => parseInt(item));
  }

  private get bannersConfig() {
    return searchList;
  }

  private get isBannersVisible() {
    return ScreenService.checkMaxWidth(650);
  }
}
