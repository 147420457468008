
  import { Component, Mixins, Prop } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';

  import IsMobileMixin from '@/mixins/common/is-mobile';
  import ReviewCardMixin from '@/mixins/reviews/common/review-card';
  import { HashMap } from '@onlinerby/js-common';

  interface IMixins extends IsMobileMixin, ReviewCardMixin {}

  const commonStore = namespace('common');

  @Component
  export default class ReviewCard extends Mixins<IMixins>(
    IsMobileMixin,
    ReviewCardMixin,
  ) {
    @Prop({
      default() {
        return {};
      },
    })
    hide!: HashMap<string>;

    @commonStore.Getter anyDropdownOpened!: boolean;

    public get isAnyDropdownOpened() {
      return this.anyDropdownOpened;
    }

    public onLinkClick(event: MouseEvent) {
      event.preventDefault();
    }
  }
