
  import { Component, Mixins, Watch, Prop } from 'vue-property-decorator';
  import * as $ from 'jquery';
  require('jquery-mousewheel')($);
  require('malihu-custom-scrollbar-plugin')($);

  import StateServiceMixin from '@/mixins/state-service';
  import Dropdown from '@/mixins/common/dropdown';
  import { IPage } from '@/interfaces/search';
  import { range } from '@/services/utils-service';
  import plural from '@/filters/plural-form';
  import { HashMap } from '@/types/common';

  interface IMixins extends StateServiceMixin, Dropdown {}

  @Component({
    filters: {
      plural,
    },
  })
  export default class Pagination extends Mixins<IMixins>(
    StateServiceMixin,
    Dropdown,
  ) {
    @Prop() listPage!: IPage;
    @Prop() total!: number;
    @Prop() lastForms!: Array<string>;
    @Prop() entityForms!: Array<string>;

    public $refs!: {
      pagesContainer: HTMLElement;
    };

    private mounted() {
      ($(this.$refs.pagesContainer) as any).mCustomScrollbar({
        axis: 'x',
        scrollInertia: 0,
        advanced: {
          updateOnContentResize: false,
          updateOnSelectorChange: true,
        },
      });
    }

    private get pages() {
      return range(1, this.listPage.last + 1);
    }

    private get count() {
      return this.listPage.current < this.listPage.last - 1
        ? this.listPage.limit
        : this.total % this.listPage.limit || this.listPage.limit;
    }

    private showNextPage() {
      const page = this.listPage.current + 1;

      this.setCurrentPage(page);
    }

    private setCurrentPage(page: number) {
      this.$emit('set-page', page);

      page === 1
        ? this.stateService.removeProperty('page')
        : this.stateService.setProperty('page', page.toString());

      this.closeDropdown();
    }

    private scrollToPage(page: string) {
      const pageNode = (this.$refs as HashMap<HTMLElement>)[`page_${page}`];

      ($(this.$refs.pagesContainer) as any).mCustomScrollbar(
        'scrollTo',
        pageNode,
      );
    }

    @Watch('pages')
    onPagesInit(pages: Array<number>) {
      if (pages.length > 1) {
        this.$nextTick(() => {
          this.scrollToPage(this.listPage.current.toString());
        });
      }
    }
  }
