var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("li", { staticClass: "vehicle-form__checkbox-item" }, [
    _c("label", { staticClass: "vehicle-form__checkbox-label" }, [
      _c(
        "div",
        {
          staticClass:
            "i-checkbox i-checkbox_warning vehicle-form__checkbox vehicle-form__checkbox_base",
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.field,
                expression: "field",
              },
            ],
            staticClass: "i-checkbox__real",
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.field)
                ? _vm._i(_vm.field, null) > -1
                : _vm.field,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.field,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.field = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.field = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.field = $$c
                }
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "i-checkbox__faux" }),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__checkbox-text" }, [
            _c("div", { staticClass: "vehicle-form__checkbox-sign" }, [
              _vm._v(_vm._s(_vm.item.name)),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }