var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__panel-item" }, [
    _c(
      "div",
      {
        staticClass:
          "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed",
      },
      [_vm._v("Комплектация")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "vehicle-form__accordion" }, [
      _c(
        "div",
        { staticClass: "vehicle-form__accordion-list" },
        [
          _vm._l(_vm.advert.equipment, function (section, index) {
            return _c("advert-equipment-section", {
              key: `section_${index}`,
              attrs: {
                section: section,
                "is-opened": _vm.isOpened(section.id),
              },
              on: {
                toggle: function ($event) {
                  return _vm.toggleSection(section.id)
                },
              },
            })
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__accordion-item vehicle-form__accordion-item_all",
              class: { "vehicle-form__accordion-item_opened": _vm.isAllOpened },
            },
            [
              _c("div", { staticClass: "vehicle-form__accordion-header" }, [
                _c("div", { staticClass: "vehicle-form__accordion-flex" }, [
                  _c("div", {
                    staticClass:
                      "vehicle-form__accordion-part vehicle-form__accordion-part_1",
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__accordion-part vehicle-form__accordion-part_2",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base-alter",
                              on: { click: _vm.toggleAll },
                            },
                            [
                              _vm.isAllOpened
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__accordion-text vehicle-form__accordion-text_off",
                                    },
                                    [_vm._v("Свернуть все опции")]
                                  )
                                : _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "gtm",
                                          rawName: "v-gtm",
                                          value: { event: "show_ad_details" },
                                          expression:
                                            "{event: 'show_ad_details'}",
                                        },
                                      ],
                                      staticClass:
                                        "vehicle-form__accordion-text vehicle-form__accordion-text_on",
                                    },
                                    [_vm._v("Развернуть все опции")]
                                  ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }