import { HashMap } from '@/types/common';

export default {
  tech: [
    'specs.modification',
    'specs.engine.type',
    'specs.engine.capacity',
    'specs.engine.torque',
    'specs.engine.power.value',
    'manufacturerId',
    'modelId',
    'generationId',
    'specs.year',
    'specs.bodyType',
    'specs.drivetrain',
    'specs.transmission',
  ],
  exterior: ['images', 'specs.color'],
  cost: ['specs.vin', 'specs.odometer.value', 'price.amount', 'specs.state'],
  contacts: [
    'seller.phones',
    'seller.phones.0',
    'seller.phones.1',
    'seller.phones.2',
    'seller.phones.3',
    'seller.phones.4',
    'seller.type',
    'seller.name',
    'seller.unp',
    'seller.contactName',
    'country',
    'region',
    'cityId',
  ],
} as HashMap<Array<string>>;
