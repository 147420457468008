
  import { Component, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';

  import plural from '@/filters/plural-form';
  import number from '@/filters/format-number';
  import { ICounters } from '@/interfaces/search';

  const searchStore = namespace('reviews/search');

  @Component({
    filters: {
      plural,
      number,
    },
  })
  export default class ReviewsCounter extends Vue {
    @searchStore.State generalCounters!: ICounters;
  }
