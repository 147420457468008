import { ICreateState } from '@/interfaces/store';
import { defaultData } from '@/config/create';

export const defaultState = {
  data: defaultData,
  mode: 'create',
  errors: {},
  steps: {
    tech: 'current',
    exterior: 'pristine',
    cost: 'pristine',
    contacts: 'pristine',
  },
};

const state: ICreateState = { ...defaultState };

export default state;
