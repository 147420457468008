
import { Prop } from 'vue-property-decorator';
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import FieldMixin from '@/mixins/create/field';
import { Dictionary, Dictionaries } from '@/services/dictionaries-service';
import camelize from '@/filters/format-camelize';

const commonStore = namespace('common');

@Component
export default class StepExteriorEquipmentCheckbox extends Mixins<FieldMixin>(
  FieldMixin
) {
  @Prop() item!: {
    id: string;
    name: string;
  };

  @commonStore.State dictionaries!: Dictionaries;

  protected get equipment() {
    return this.data.equipment || {};
  }

  protected get property() {
    return camelize(this.item.id);
  }

  protected get field() {
    return this.equipment[this.property];
  }

  protected set field(value) {
    const equipment = { ...this.equipment, [this.property]: value };

    this.setField({ equipment });
  }
}
