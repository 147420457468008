
  import { Component, Mixins } from 'vue-mixin-decorator';
  import { namespace } from 'vuex-class';
  import { Prop } from 'vue-property-decorator';

  import { IAdvert, IAdvertSpecs } from '@/interfaces/adverts';
  import { Dictionaries, DictionaryItem } from '@/services/dictionaries-service';
  import { odometerTypes, powerTypes } from '@/config/dictionaries';

  import AdvertLeasing from '@/components/advert/advert-leasing.vue';

  import capitalize from '@/filters/format-capitalize';
  import currency from '@/filters/format-currency';
  import number from '@/filters/format-number';
  import IsMobileMixin from '@/mixins/common/is-mobile';
  import { IUser } from '@/interfaces/common';
  import { VuexAction } from '@/types/functions';
  import { ReviewsRating } from '@/types/review';
  import RatingHeader from '@/components/common/rating-reviews/rating-header.vue';
  import scrollService from '@/services/scroll-service';

  const advertStore = namespace('advert');
  const commonStore = namespace('common');
  const userStore = namespace('user');

  @Component({
    filters: { capitalize, currency, number },
    components: {
      RatingHeader,
      AdvertLeasing,
    },
  })
  export default class AdvertSpecs extends Mixins<IsMobileMixin>(IsMobileMixin) {
    private isVinVisible: boolean = false;

    @Prop() reviewsRating!: ReviewsRating;

    @advertStore.State advert!: IAdvert;
    @advertStore.Action updateVin!: VuexAction;

    @commonStore.State dictionaries!: Dictionaries;
    @commonStore.Getter getDictionaryItem!: (
      type: string,
      key: string,
    ) => DictionaryItem;
    @userStore.State currentUser!: IUser;

    private get specs(): IAdvertSpecs {
      return this.advert.specs;
    }

    private get odometerUnit(): string {
      const unit = odometerTypes[this.specs.odometer.unit];

      return unit;
    }

    private get engine(): string {
      const capacity = this.specs.engine.capacity;

      let type = capitalize(
        this.getDictionaryItem('engineType', this.specs.engine.type).name,
      );

      if (this.specs.engine.hybrid) {
        type += ' / Гибрид';
      }

      if (this.specs.engine.gas) {
        type += ' / Газобалонное оборудование';
      }

      return capacity ? `${capacity} л / ${type}` : type;
    }

    private get power(): string {
      const parts: Array<string> = [];
      const { power, torque } = this.specs.engine;

      if (power && power.value) {
        const unit = powerTypes[power.unit];

        parts.push(`${power.value} ${unit}`);
      }

      if (torque) {
        parts.push(`${torque} Нм`);
      }

      return parts.join(' / ');
    }

    private get drivetrain(): string {
      return this.getDictionaryItem('drivetrain', this.specs.drivetrain).name;
    }

    private get bodyType(): string {
      return this.getDictionaryItem('bodyType', this.specs.bodyType).name;
    }

    private get transmission(): string {
      return this.getDictionaryItem('transmission', this.specs.transmission).name;
    }

    private get color(): string {
      return this.getDictionaryItem('color', this.specs.color).name;
    }

    private get state(): string {
      return this.getDictionaryItem('state', this.specs.state).name;
    }

    private get vin(): string {
      const vin = this.specs.vin || '';

      return this.isOwnAdvert || this.isVinVisible ? vin : vin.slice(0, 9);
    }

    private get isExchangeVisible(): boolean {
      return !!this.advert.dealTerms.exchange;
    }

    private get customsClearance(): boolean {
      return !!this.advert.dealTerms.customsClearance;
    }

    private get isWarrantyVisible(): boolean {
      return this.specs.warranty;
    }

    private get isManualControlVisible(): boolean {
      return this.specs.manualControl;
    }

    private get author() {
      return this.advert.author;
    }

    private get isOwnAdvert() {
      return this.currentUser && this.author.id === this.currentUser.id;
    }

    private get isRatingHeaderVisible() {
      return !this.isOwnAdvert && this.reviewsRating;
    }

    private onShowVinClick() {
      this.updateVin({
        id: this.advert.id,
        success: () => {
          this.isVinVisible = true;
        },
      });
    }

    private onRatingHeaderSpecificsClick() {
      scrollService.scrollTo('#rating');
    }
  }
