
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { shuffle } from 'underscore';

import { IAdvert, ILeasingItem } from '@/interfaces/adverts';
import AdvertLeasingItem from '@/components/advert/advert-leasing-item.vue';
import AdvertLeasingPopup from '@/components/advert/advert-leasing-popup.vue';
import AdvertStats from '@/components/advert/advert-stats.vue';
import { IUser } from '@/interfaces/common';

const advertStore = namespace('advert');
const userStore = namespace('user');

@Component({
  components: {
    AdvertLeasingItem,
    AdvertLeasingPopup,
    AdvertStats,
  },
})
export default class AdvertLeasing extends Vue {
  private isOpened: boolean = false;

  @userStore.State currentUser!: IUser;
  @advertStore.State advert!: IAdvert;

  private get leasing() {
    return shuffle(this.advert.leasings?.common);
  }

  private get isMoreLeasingOptions() {
    return this.leasing.length > 1;
  }

  private get mainLeasingOption() {
    return this.leasing[0];
  }

  private get otherLeasingOptions() {
    return this.leasing.slice(1);
  }

  private get isOwnAdvert() {
    return this.currentUser && this.currentUser.id === this.advert.author.id;
  }

  private onLeasingClick(leasing: ILeasingItem) {
    (this.$refs.leasingPopup as AdvertLeasingPopup).openPopup(leasing);
  }
}
