
  import { Component, Prop, Vue } from 'vue-property-decorator';

  import CommentsListBranch from '@/components/comments/comments-list-branch.vue';
  import { ActiveBan, CommentsSettings } from '@/types/api';
  import { CurrentUser } from '@onlinerby/js-common';
  import { CommentsConfig } from '@/types/comments';

  @Component({
    components: {
      CommentsListBranch,
    },
  })
  export default class BestComment extends Vue {
    @Prop() best!: {
      comment: Comment;
      parent?: Comment;
    };
    @Prop() config!: CommentsConfig;
    @Prop() entityAuthorId!: number;
    @Prop() currentUser!: CurrentUser;
    @Prop() settings!: CommentsSettings;
    @Prop() activeBan!: ActiveBan;

    private get comment() {
      if (!this.best.parent) {
        return { ...this.best.comment, isBranchOpened: true };
      }

      return {
        ...this.best.parent,
        replies: [this.best.comment],
        isBranchOpened: true,
      };
    }

    private onReply(payload: { commentId: string; mention: string }) {
      this.$emit('reply', payload);
    }

    private onMarkToggle({
      commentId,
      mark,
    }: {
      commentId: string;
      mark: string;
    }) {
      this.$emit('mark', {
        commentId,
        mark,
      });
    }
  }
