import camelCaseInterceptor from './interceptors/camel-case-interceptor';

const ApiService = (window as any).ApiService;

class UserApi {
  private static instance: UserApi;
  private apiService: any;

  private constructor() {
    this.apiService = new ApiService({
      baseURL: '/sdapi/user.api',
      headers: {
        'X-Api-Version': 2,
        'Access-Control-Allow-Credentials': true,
      },
      authorization: {
        useToken: true,
      },
      errors: {
        silent: true,
      },
      cache: true,
    });

    camelCaseInterceptor(this.apiService);
  }

  public getMe(callbacks = {}) {
    const config = {
      url: '/me',
      method: 'get',
      callbacks,
    };

    this.apiService.query(config);
  }

  public static getInstance() {
    if (!UserApi.instance) {
      UserApi.instance = new UserApi();
    }

    return UserApi.instance;
  }
}

export default UserApi.getInstance();
