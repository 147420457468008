import { Mixin, Mixins } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import { Dictionaries } from '@/services/dictionaries-service';
import FieldMixin from '@/mixins/create/field';
import manufacturersMap from '@/config/manufacturers-map';
import { HashMap } from '@/types/common';

const commonStore = namespace('common');

@Mixin
export default class StepTechManufacturerMixin extends Mixins<FieldMixin>(
  FieldMixin
) {
  protected search: string = '';

  @commonStore.State dictionaries!: Dictionaries;

  protected get filterString() {
    return this.search
      .trim()
      .toLowerCase()
      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  protected get manufacturer() {
    return this.data.manufacturerId;
  }

  protected set manufacturer(value) {
    this.setField({ manufacturerId: value });
    this.setField({ modelId: null });
    this.setField({ generationId: null });
    this.clearError({ name: 'modelId' });
    this.clearError({ name: 'generationId' });
  }

  protected getClass(id: number) {
    return `vehicle-form__checkbox-item_brand_${
      (manufacturersMap as HashMap<string>)[id]
    }-alter`;
  }
}
