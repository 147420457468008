
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { findWhere } from 'underscore';
  import SecondaryParameterDiagram from '@/components/common/rating-reviews/secondary-parameter-diagram.vue';
  import vmApi from '@/api/vm-api';
  import { ListReviewResponse, Review } from '@/types/review';
  import relativeDate from '@/filters/format-relative-date';
  import plural from '@/filters/plural-form';
  import { IPage } from '@/interfaces/search';

  const defaultPage: IPage = {
    current: 1,
    items: 0,
    last: 1,
    limit: 5,
  };

  @Component({
    components: {
      SecondaryParameterDiagram,
    },
    filters: {
      relativeDate,
      plural,
    },
  })
  export default class SecondaryParameterItem extends Vue {
    private isOpened: boolean = false;
    private isLoading: boolean = false;
    private reviews: Review[] = [];
    private page!: IPage = defaultPage;
    private total!: number = 0;

    @Prop() id!: string;
    @Prop() label!: string;
    @Prop() positiveValue!: number;
    @Prop() negativeValue!: number;
    @Prop() type!: string | undefined;
    @Prop() manufacturerId!: number;
    @Prop() modelId!: number;
    @Prop() generationId!: number;

    private get count() {
      return this.page.current < this.page.last - 1
        ? this.page.limit
        : this.total % this.page.limit || this.page.limit;
    }

    private async onClickToggle() {
      this.reviews = [];
      this.page = defaultPage;

      !this.isOpened && (await this.getReviews());

      this.isOpened = !this.isOpened;
    }

    private getReviews(page = this.page.current) {
      this.isLoading = true;

      return new Promise(resolve => {
        const params = {
          car: [
            {
              manufacturer: this.manufacturerId,
              model: this.modelId,
              generation: this.generationId,
            },
          ],
          secondaryMark: {
            id: this.id,
            type: this.type,
          },
          status: ['published'],
          limit: this.page.limit,
          page: page,
        };

        vmApi.getReviews(params, {
          success: ({ data }: ListReviewResponse) => {
            this.reviews.push(...data.reviews);
            this.page = data.page;
            this.total = data.total;
          },
          complete: () => {
            this.isLoading = false;
            resolve();
          },
        });
      });
    }

    private onClickNextPage() {
      const page = this.page.current + 1;

      this.getReviews(page);
    }

    private getIconClass(pros) {
      return findWhere(pros, {
        id: this.id,
      })
        ? 'vehicle-form__description_plus'
        : 'vehicle-form__description_minus';
    }
  }
