import { Mixin, Mixins } from 'vue-mixin-decorator';
import FieldMixin from '@/mixins/reviews/create/field';
import { Dictionary } from '@/services/dictionaries-service';
import { range } from '@/services/utils-service';
import { SINCE_YEAR } from '@/config/common';

@Mixin
export default class StepTechYear extends Mixins<FieldMixin>(FieldMixin) {
  protected get specs() {
    return this.data.specs || {};
  }

  protected get year() {
    return this.specs.year;
  }

  protected set year(year) {
    this.setYear(year);
  }

  protected get yearsDictionary(): Dictionary {
    const yearsRange = range(
      SINCE_YEAR,
      new Date().getFullYear() + 1,
    ).reverse();

    return yearsRange.map(item => {
      return {
        id: item!.toString(),
        name: item!.toString(),
      };
    });
  }

  protected setYear(year: string) {
    this.onFieldChange(Number(year));
  }
}
