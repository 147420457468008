
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { HashMap } from '@onlinerby/js-common';
  import { stepStatuses } from '@/config/reviews/create';
  import errorMap from '@/config/reviews/steps-error-map';
  import { intersection } from 'underscore';

  @Component
  export default class StepWrapper extends Vue {
    @Prop() step!: string;
    @Prop() statusesByStep!: HashMap<string>;
    @Prop() errors!: HashMap<any>;

    private get statusClass() {
      const status = this.statusesByStep[this.step];

      if (this.hasErrors) {
        return 'vehicle-form__step-item_alter';
      }

      if (status === stepStatuses.complete) {
        return 'vehicle-form__step-item_primary';
      }

      if (status === stepStatuses.current || status === stepStatuses.edit) {
        return 'vehicle-form__step-item_secondary';
      }

      return 'vehicle-form__step-item_additional';
    }

    private get hasErrors() {
      const errors: Array<string> = Object.values(errorMap[this.step]);
      const currentErrors = Object.keys(this.errors);

      return !!intersection(errors, currentErrors).length;
    }
  }
