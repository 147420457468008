
import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Searches } from '@/types/search';
import SavedSearchesItem from '@/components/search/saved-searches-item.vue';
import SavedSearchesSettings from '@/components/search/saved-searches-settings.vue';

const searchStore = namespace('search');

@Component({
  components: {
    SavedSearchesItem,
    SavedSearchesSettings,
  },
})
export default class SavedSearches extends Vue {
  private isBlocked: boolean = false;

  @Prop() isReady!: boolean;

  @searchStore.State searches!: Searches;

  private close() {
    document.body.classList.remove('body_state-vehicle-form-save_opened');
  }

  private onSettingsOpen() {
    this.isBlocked = true;
  }

  private onSettingsClose() {
    this.isBlocked = false;
  }
}
