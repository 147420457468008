var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__save" }, [
    _c("div", {
      staticClass: "vehicle-form__save-overlay",
      on: { click: _vm.close },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "vehicle-form__save-aside" }, [
      _c("span", {
        staticClass: "vehicle-form__save-close",
        on: { click: _vm.close },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vehicle-form__save-header" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("saved-searches-settings", {
            on: { open: _vm.onSettingsOpen, close: _vm.onSettingsClose },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "vehicle-form__save-body" }, [
        _c(
          "div",
          {
            staticClass: "vehicle-form__save-overflow",
            class: {
              "vehicle-form__save-overflow_animated": !_vm.isReady,
              "vehicle-form__save-overflow_blocked": _vm.isBlocked,
            },
          },
          [
            _vm.searches && _vm.searches.length
              ? _c(
                  "div",
                  { staticClass: "vehicle-form__save-list" },
                  _vm._l(_vm.searches, function (item) {
                    return _c("saved-searches-item", {
                      key: item.id,
                      attrs: {
                        id: item.id,
                        search: item.data,
                        "new-adverts": item.newAdverts,
                      },
                    })
                  }),
                  1
                )
              : _vm.searches
              ? _c("div", { staticClass: "vehicle-message" }, [_vm._m(1)])
              : _vm._e(),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__title vehicle-form__title_base vehicle-form__title_extended-additional",
      },
      [
        _vm._v("\n        Сохраненные\n        "),
        _c("br"),
        _vm._v("поиски\n      "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-message__content" }, [
      _c(
        "div",
        { staticClass: "vehicle-container vehicle-container_width_auto" },
        [
          _c("div", { staticClass: "vehicle-message__preview" }, [
            _c("div", {
              staticClass: "vehicle-message__image vehicle-message__image_suv",
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-message__description vehicle-message__description_other vehicle-message__description_middle vehicle-message__description_extended-additional",
            },
            [
              _vm._v(
                "\n                Сохраняйте поиски, чтобы не пропустить новые объявления\n              "
              ),
            ]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }