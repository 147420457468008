import camelCaseInterceptor from './interceptors/camel-case-interceptor';

const ApiService = (window as any).ApiService;

class CommentsApi {
  private apiService: any;

  public constructor(params: {
    projectName: string;
    entityType: string;
    entityId: string;
  }) {
    this.apiService = new ApiService({
      baseURL: `/sdapi/comments.api/${params.projectName}/${params.entityType}/${params.entityId}`,
      headers: {
        'Access-Control-Allow-Credentials': true,
      },
      authorization: {
        useToken: true,
      },
      errors: {
        silent: true,
      },
      cache: true,
    });

    camelCaseInterceptor(this.apiService);
  }

  public getComments(
    params: {
      limit?: number;
    },
    callbacks = {},
  ) {
    const config = {
      url: `/comments`,
      method: 'get',
      params: {
        limit: params.limit,
      },
      callbacks,
    };

    this.apiService.query(config);
  }

  public postComment(text: string, callbacks = {}) {
    const config = {
      url: `/comments`,
      method: 'post',
      data: {
        text,
      },
      callbacks,
    };

    this.apiService.query(config);
  }

  deleteComment(commentId: string, callbacks = {}) {
    const config = {
      url: `/comments/${commentId}`,
      method: 'delete',
      callbacks,
    };

    this.apiService.query(config);
  }

  public postReply(commentId: string, text: string, callbacks = {}) {
    const config = {
      url: `/comments/${commentId}/reply`,
      method: 'post',
      data: {
        text,
      },
      callbacks,
    };

    this.apiService.query(config);
  }

  public putCommentMark(commentId: string, mark: string, callbacks = {}) {
    const config = {
      url: `/comments/${commentId}/marks`,
      method: 'put',
      data: {
        mark,
      },
      callbacks,
    };

    this.apiService.query(config);
  }
}

export default CommentsApi;
