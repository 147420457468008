
import { Vue, Prop, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { without } from 'underscore';

import { SelectedFeature, Option } from '@/types/promote';
import { VuexAction } from '@/types/functions';

const promoteStore = namespace('promote');

@Component
export default class PromotePackageOption extends Vue {
  @Prop() option!: Option;
  @Prop() options!: string[];
  @Prop() limit!: {
    min: number;
    max: number;
  };

  @promoteStore.State selectedFeature!: SelectedFeature;
  @promoteStore.Action setSelectedFeature!: VuexAction;

  private get isSelected() {
    return this.options.indexOf(this.option.id) !== -1;
  }

  private set isSelected(value) {
    value
      ? this.$emit('options-add', this.option.id)
      : this.$emit('options-remove', this.option.id);
  }

  private get isDisabled() {
    return this.options.length >= this.limit.max && !this.isSelected;
  }
}
