
  import { Vue, Component } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import * as $ from 'jquery';

  import urlService from '@/services/url-service';
  import { IAdvert } from '@/interfaces/adverts';

  import background from '@/filters/format-background';

  import AdvertContactsListItem from '@/components/advert/advert-contacts-list-item.vue';
  import { IUser } from '@/interfaces/common';

  const advertStore = namespace('advert');
  const userStore = namespace('user');

  @Component({
    components: {
      AdvertContactsListItem,
    },
    filters: {
      background,
    },
  })
  export default class AdvertPerson extends Vue {
    @advertStore.State advert!: IAdvert;
    @userStore.State currentUser!: IUser;

    private get seller() {
      return this.advert.seller;
    }

    private get author() {
      return this.advert.author;
    }

    private get isOwnAdvert() {
      return this.author.id === this.currentUser.id;
    }

    private get avatar() {
      return urlService.secureProjectUrl(
        'content',
        `user/avatar/60x60/${this.author.id}`,
      );
    }

    public get ipData() {
      return this.advert.ipData;
    }

    private get profileLink() {
      return urlService.secureProjectUrl('profile', `user/${this.author.id}`);
    }
  }
