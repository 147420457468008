
  import { Component, Mixins } from 'vue-mixin-decorator';

  import MultiselectFilterMixin from '@/mixins/filters/multiselect-filter';
  import capitalize from '@/filters/format-capitalize';
  import number from '@/filters/format-number';

  @Component({
    filters: {
      capitalize,
      number,
    },
  })
  export default class MultiselectFilter extends Mixins<
    MultiselectFilterMixin
  >(MultiselectFilterMixin) {}
