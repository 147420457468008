export default (
  count: number,
  forms: Array<string> = [],
  withCount: boolean = true
) => {
  let index = 2;

  if (count % 10 === 1 && count % 100 !== 11) {
    index = 0;
  } else if (
    count % 10 >= 2 &&
    count % 10 <= 4 &&
    (count % 100 < 10 || count % 100 >= 20)
  ) {
    index = 1;
  }

  const text = forms[index] || '';

  return withCount ? count + ' ' + text : text;
};
