import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import { IAdvert } from '@/interfaces/adverts';
import { VuexAction } from '@/types/functions';
import { IUser } from '@/interfaces/common';
import notificationsService from '@/services/notifications-service';

const userStore = namespace('user');

@Mixin
class Bookmark extends Vue {
  protected addToBookmarks!: VuexAction;
  protected removeFromBookmarks!: VuexAction;

  @Prop() advert!: IAdvert;

  @userStore.Getter isAuthenticated!: boolean;
  @userStore.State currentUser!: IUser;

  private get title() {
    return this.advert.inBookmarks
      ? 'Удалить из закладок'
      : 'Добавить в закладки';
  }

  private onBookmarkClick() {
    if (this.isAuthenticated) {
      this.toggleBookmark();
    } else {
      const profileAuth = (window as any).profileAuth as {
        showLoginModal: () => {};
      };

      profileAuth.showLoginModal();
    }
  }

  protected toggleBookmark() {
    const payload = { id: this.advert.id };
    const action = this.advert.inBookmarks
      ? this.removeFromBookmarks
      : this.addToBookmarks;
    const {
      id,
      location,
      specs,
      price,
      manufacturer,
      seller,
      author,
    } = this.advert;

    this.$gtm.trackEvent({
      event: this.advert.inBookmarks
        ? 'remove_from_wishlist'
        : 'add_to_wishlist',
      value: {
        ad_id: id,
        ad_region: `${location.country.id}_${location.region.id}_${location.city.id}`,
        ad_condition: specs.state,
        ad_price: price.converted.USD.amount,
        ad_currency: 'USD',
        ad_mfr: manufacturer.id,
        seller_id: author ? author.id.toString() : '',
        seller_type: seller ? seller.type : '',
        user_id: this.currentUser ? this.currentUser.id.toString() : '',
      },
    });

    action({
      ...payload,
      onError: ({ data, status }: any) => {
        if (status === 422 && data.errors.advertId) {
          notificationsService.error(data.errors.advertId.join('. '));
        }
      },
    });
  }
}

export default Bookmark;
