
  import { Mixins, Component } from 'vue-mixin-decorator';
  import StepTechYearMixin from '@/mixins/reviews/create/step-tech-year';

  @Component
  export default class StepTechYear extends Mixins<StepTechYearMixin>(
    StepTechYearMixin,
  ) {
    private get isOlderSelected() {
      return this.year && this.year <= this.yearsDictionary[10].id;
    }
  }
