
import { VmTile } from '@onlinerby/js-api-clients';
import { Vue, Component, Prop } from 'vue-property-decorator';

import background from '@/filters/format-background';

@Component({
  filters: {
    background,
  },
})
export default class Tile extends Vue {
  @Prop() tile!: VmTile;
  @Prop() index!: number;

  private onTileClick() {
    const dataLayer = (window as any).dataLayer;

    dataLayer &&
      dataLayer.push({
        event: 'select_promotion',
        ecommerce: {
          items: [
            {
              item_name: this.tile.title,
              promotion_name: 'Avtobaraholka - News in Header',
              creative_name: this.tile.topic,
              creative_slot: 'news',
              index: this.index + 1,
            },
          ],
        },
      });
  }
}
