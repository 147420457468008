
  import { Mixins, Component } from 'vue-mixin-decorator';
  import { Prop } from 'vue-property-decorator';
  import { contains } from 'underscore';

  import { HashMap } from '@/types/common';
  import FieldMixin from '@/mixins/reviews/create/field';
  import float from '@/filters/format-float';
  import { powerTypes } from '@/config/dictionaries';

  @Component
  export default class StepTechEngine extends Mixins<FieldMixin>(FieldMixin) {
    @Prop() engineTypes!: Array<any>;

    private get typeError() {
      return (this.errors['specs.engine.type'] || []).join('. ');
    }

    private get capacityError() {
      return (this.errors['specs.engine.capacity'] || []).join('. ');
    }

    private get powerError() {
      const powerErrors = [];

      if (this.errors['specs.engine.power.value']) {
        powerErrors.push(...this.errors['specs.engine.power.value']);
      }

      if (this.errors['specs.engine.power.unit']) {
        powerErrors.push(...this.errors['specs.engine.power.unit']);
      }

      return powerErrors.join('. ');
    }

    private get torqueError() {
      return (this.errors['specs.engine.torque'] || []).join('. ');
    }

    private get powerUnits() {
      return powerTypes;
    }

    private get power() {
      return this.engine.power || {};
    }

    private get engine() {
      return this.data.specs.engine || {};
    }
    private set engine(value) {
      this.onFieldChange(value);
    }

    private get gas() {
      return this.engine.gas;
    }
    private set gas(value) {
      this.engine = { ...this.engine, gas: value };
    }

    private get hybrid() {
      return this.engine.hybrid;
    }
    private set hybrid(value) {
      this.engine = { ...this.engine, hybrid: value };
    }

    private get capacity() {
      return this.engine.capacity;
    }
    private set capacity(value) {
      const capacity = float(value.replace(',', '.'));

      this.$emit('change-capacity');

      if (capacity) {
        this.engine = { ...this.engine, capacity };
      } else {
        const { capacity, ...engine } = this.engine;

        this.engine = engine;
      }
    }

    private get powerValue() {
      return this.power.value;
    }
    private set powerValue(_value) {
      const value = parseInt(_value);

      if (value && !isNaN(value as number)) {
        const power = { ...this.power, value };
        this.engine = { ...this.engine, power };
      } else {
        const { value, ...power } = this.power;
        this.engine = { ...this.engine, power };
      }

      this.$emit('change-power');
    }

    private get torque() {
      return this.engine.torque;
    }

    private set torque(_torque) {
      const torque = parseInt(_torque);

      if (torque) {
        this.engine = { ...this.engine, torque };
      } else {
        const { torque, ...engine } = this.engine;
        this.engine = engine;
      }

      this.$emit('change-torque');
    }

    private onSetEngineTypeClick(value: string) {
      const engine: HashMap<any> = {
        type: value,
        power: this.power,
        gas: !!(this.isGasAvailable(value) && this.gas),
        hybrid: !!(this.isHybridAvailable(value) && this.hybrid),
      };

      if (this.isCapacityAvailable(value)) {
        engine.capacity = this.engine.capacity;
      }

      this.engine = engine;
      this.$emit('change-engine-type');
    }

    private onSetPowerUnitClick(value: string) {
      const power = { ...this.power, unit: value };
      this.engine = { ...this.engine, power };
      this.$emit('change-power-unit');
    }

    private isGasAvailable(value: string) {
      return contains(['gasoline'], value);
    }

    private isHybridAvailable(value: string) {
      return contains(['gasoline', 'diesel'], value);
    }

    private isCapacityAvailable(value: string) {
      return contains(['gasoline', 'diesel'], value);
    }
  }
