var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__tabs-item vehicle-form__tabs-item_active" },
    [
      _c("div", { staticClass: "vehicle-form__offers" }, [
        _c(
          "div",
          { staticClass: "vehicle-form__offers-list" },
          [
            _vm.isLoading
              ? _vm._l(_vm.stubCount, function (index) {
                  return _c("review-card-stub", { key: index })
                })
              : _vm.reviews.length
              ? [
                  _c("review-add-block"),
                  _vm._v(" "),
                  _vm._l(_vm.reviews, function (review, index) {
                    return _c(
                      "review-card",
                      {
                        key: review.id,
                        attrs: { review: review, hide: { author: true } },
                      },
                      [
                        _c("review-list-manage", {
                          attrs: { slot: "controls", review: review },
                          on: {
                            opened: (data) => _vm.onReviewChange(data, index),
                            closed: (data) => _vm.onReviewChange(data, index),
                            moderated: (data) =>
                              _vm.onReviewChange(data, index),
                            published: (data) =>
                              _vm.onReviewChange(data, index),
                          },
                          slot: "controls",
                        }),
                      ],
                      1
                    )
                  }),
                ]
              : _vm.isOwn
              ? _c(
                  "div",
                  { staticClass: "vehicle-message vehicle-message_condensed" },
                  [
                    _c("div", { staticClass: "vehicle-message__content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-container vehicle-container_width_mmmm",
                        },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-message__title vehicle-message__title_base vehicle-message__title_condensed-other",
                            },
                            [
                              _vm._v(
                                "\n              Оставьте первый отзыв\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-message__description vehicle-message__description_primary vehicle-message__description_base vehicle-message__description_condensed-complementary",
                            },
                            [
                              _vm._v(
                                "\n              Для пользователей Автобарахолки ваш отзыв может стать\n              решающим при выборе автомобиля\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-message__control vehicle-message__control_condensed-default",
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  directives: [
                                    {
                                      name: "gtm",
                                      rawName: "v-gtm",
                                      value: { event: "create_review" },
                                      expression: "{ event: 'create_review' }",
                                    },
                                  ],
                                  staticClass:
                                    "button-style button-style_primary button-style_small vehicle-message__button",
                                  attrs: { to: { name: "create-review" } },
                                },
                                [
                                  _vm._v(
                                    "\n                Написать отзыв\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              : _c(
                  "div",
                  { staticClass: "vehicle-message vehicle-message_condensed" },
                  [
                    _c("div", { staticClass: "vehicle-message__content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-container vehicle-container_width_mmmm",
                        },
                        [
                          _vm._m(1),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-message__title vehicle-message__title_base vehicle-message__title_condensed-other",
                            },
                            [
                              _vm._v(
                                "\n              У пользователя нет отзывов\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-message__description vehicle-message__description_primary vehicle-message__description_base vehicle-message__description_condensed-complementary",
                            },
                            [
                              _vm._v(
                                "\n              К сожалению, не найдено ни одного отзыва. Возможно, тут скоро\n              что-нибудь появится\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-message__control vehicle-message__control_condensed-default",
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "button-style button-style_primary button-style_small vehicle-message__button",
                                  attrs: { to: { name: "create-review" } },
                                },
                                [
                                  _vm._v(
                                    "\n                Оставьте свой отзыв об автомобиле\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.page
        ? _c("pagination", {
            attrs: {
              "list-page": _vm.page,
              total: _vm.total,
              "last-forms": ["Последний", "Последние", "Последние"],
              "entity-forms": ["отзыв", "отзыва", "отзывов"],
            },
            on: { "set-page": _vm.onPageSet },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-message__preview" }, [
      _c("div", {
        staticClass: "vehicle-message__image vehicle-message__image_reviews",
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-message__preview" }, [
      _c("div", {
        staticClass: "vehicle-message__image vehicle-message__image_binoculars",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }