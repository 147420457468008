var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.items.length
    ? _c("div", [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed",
          },
          [
            _c(
              "a",
              {
                staticClass: "vehicle-form__link vehicle-form__link_primary",
                attrs: { href: _vm.searchUrl, target: "_blank" },
              },
              [_vm._v("\n      Другие отзывы\n    ")]
            ),
            _vm._v("\n    о " + _vm._s(_vm.title) + "\n  "),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "vehicle-form__reviews" }, [
          _c(
            "div",
            {
              ref: "slider",
              staticClass:
                "vehicle-form__slider vehicle-form__slider_others js-slider",
            },
            [
              !_vm.isMobileSpecial
                ? _c("div", {
                    staticClass:
                      "vehicle-form__slider-arrow vehicle-form__slider-arrow_prev swiper-button-prev js-slider-swipper-prev",
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isMobileSpecial
                ? _c("div", {
                    staticClass:
                      "vehicle-form__slider-arrow vehicle-form__slider-arrow_next swiper-button-next js-slider-swipper-next",
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__slider-box swiper-container js-slider-swipper",
                },
                [
                  _c(
                    "div",
                    { staticClass: "vehicle-form__slider-list swiper-wrapper" },
                    _vm._l(_vm.items, function (item) {
                      return _c("review-card-vertical", {
                        key: item.id,
                        attrs: {
                          "item-class":
                            "vehicle-form__slider-item swiper-slide",
                          review: item,
                        },
                      })
                    }),
                    1
                  ),
                ]
              ),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }