var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__group vehicle-form__group_alter" },
    [
      _c(
        "div",
        { staticClass: "vehicle-form__label vehicle-form__label_base" },
        [
          _c("div", { staticClass: "vehicle-form__label-flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__label-part vehicle-form__label-part_1",
              },
              [
                _vm.seller.type === "individual"
                  ? _c("div", { staticClass: "vehicle-form__label-title" }, [
                      _vm._v("Имя продавца"),
                    ])
                  : _vm.seller.type === "autohaus"
                  ? _c("div", { staticClass: "vehicle-form__label-title" }, [
                      _vm._v("Название автохауса"),
                    ])
                  : _c("div", { staticClass: "vehicle-form__label-title" }, [
                      _vm._v("Название дилера"),
                    ]),
              ]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_2",
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "vehicle-form__field" }, [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__line vehicle-form__line_condensed-other",
          },
          [
            _c(
              "div",
              {
                staticClass: "input-style__wrapper vehicle-form__input-wrapper",
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.name,
                      expression: "name",
                    },
                  ],
                  staticClass:
                    "input-style input-style_primary input-style_base vehicle-form__input vehicle-form__input_width_xs",
                  class: { "input-style_error": _vm.error },
                  attrs: { type: "text", maxlength: "50" },
                  domProps: { value: _vm.name },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.name = $event.target.value
                      },
                      function ($event) {
                        return _vm.clearError({ name: "name" })
                      },
                    ],
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.error
          ? _c(
              "div",
              {
                staticClass:
                  "vehicle-form__line vehicle-form__line_condensed-other",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_xs",
                  },
                  [_vm._v(_vm._s(_vm.error))]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }