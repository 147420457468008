import { Prop, Vue } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import { each, findIndex, first } from 'underscore';
import draggable from 'vuedraggable';

import ImageUploadService from '@/services/image-upload-service';
import { getRandomId } from '@/services/random-service';
import background from '@/filters/format-background';
import { Image } from '@/types/common';

@Mixin({
  components: {
    draggable,
  },
  filters: {
    background,
  },
})
export default class UploadPhoto extends Vue {
  @Prop() currentImages!: Array<Image>;
  @Prop() project!: string;

  private images: Array<Image> = [];
  private isDropZoneActive: boolean = false;
  private LIMIT = 50;

  private get mainIndex() {
    return findIndex(this.images, { status: 'processed' });
  }

  private created() {
    if (this.currentImages) {
      this.currentImages.forEach((image: any) => {
        this.images.push({ images: image, status: 'processed' });
      });

      this.update();
    }
  }

  private onFilesSelected(event: any) {
    this.processImageUpload(event.target.files);

    event.target.value = '';
  }

  private processImageUpload(files: Array<File>) {
    each(files, (file: File) => {
      const id: string = getRandomId();

      let message;
      let progress;

      const image =
        this.images.length < this.LIMIT
          ? {
              id,
              status: 'init',
            }
          : {
              id,
              status: 'error',
              errorText: 'Превышен лимит',
            };

      this.images.push(image);

      ImageUploadService.processUpload(
        file,
        {
          previewReady: (preview: { content: string }) => {
            const index = findIndex(this.images, { id });

            if (index === -1) {
              return;
            }

            const newImage =
              this.images[index].status === 'init'
                ? {
                    id,
                    status: 'processing',
                    images: {
                      preview: preview.content,
                    },
                  }
                : {
                    id,
                    status: 'error',
                    errorText: 'Превышен лимит',
                    images: {
                      preview: preview.content,
                    },
                  };

            this.images.splice(index, 1, newImage);
          },

          complete: (data: any) => {
            const index = findIndex(this.images, { id });

            if (index >= this.LIMIT) {
              return;
            }

            data.images.preview = this.images[index].images?.preview;

            this.images.splice(index, 1, data);

            this.update();
          },

          error: ({ errorText }: { errorText: string }) => {
            const index = findIndex(this.images, { id });

            if (index >= this.LIMIT) {
              return;
            }

            this.images.splice(index, 1, {
              ...this.images[index],
              errorText: errorText || 'Не удалось загрузить изображение',
              status: 'error',
            });
          },
        },
        this.project,
      );
    });
  }

  private removePhoto(index: number) {
    this.images.splice(index, 1);

    this.update();
  }

  private onDrag() {
    this.update();
  }

  private onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();

    if (this.isDropZoneActive) {
      return;
    }

    if (
      $(this.$refs.dropzoneContainer).find(event.fromElement).length ||
      $(this.$refs.dropzoneContainer).find(event.fromTo).length
    ) {
      return;
    }

    this.isDropZoneActive = true;
  }

  private onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.isDropZoneActive) {
      return;
    }

    if (
      $(this.$refs.dropzoneContainer).find(event.fromElement).length ||
      $(this.$refs.dropzoneContainer).find(event.fromTo).length
    ) {
      return;
    }

    this.isDropZoneActive = false;
  }

  private onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();

    this.isDropZoneActive = false;

    this.processImageUpload(event.dataTransfer.files);

    event.target.value = '';
  }

  private makeMain(index: number) {
    const item = first(this.images.splice(index, 1));

    this.images.unshift(item!);

    this.update();
  }

  private update() {
    this.$emit(
      'change',
      this.images.filter(image => !!image.images).map(image => image.images),
    );
  }
}
