
import { Mixins, Component } from 'vue-mixin-decorator';
import { findWhere } from 'underscore';

import { Dictionaries, DictionaryItem } from '@/services/dictionaries-service';

import DropdownMixin from '@/mixins/common/dropdown';
import StepTechManufacturerMixin from '@/mixins/create/step-tech-manufacturer';
import popular from '@/config/popular';
import { HashMap } from '../../../types/common';

interface IMixins extends StepTechManufacturerMixin, DropdownMixin {}

@Component
export default class StepTechManufacturer extends Mixins<IMixins>(
  StepTechManufacturerMixin,
  DropdownMixin
) {
  private open() {
    this.openDropdown();

    document.body.classList.add('body_state-dropdown-style_visible');
  }

  private close() {
    this.closeDropdown();

    document.body.classList.remove('body_state-dropdown-style_visible');
  }

  private get manufacturers() {
    if (this.search && this.dictionaries.manufacturer) {
      const regex = new RegExp(this.filterString);

      return this.dictionaries.manufacturer.filter((item: DictionaryItem) => {
        return regex.test(item.name.toLowerCase());
      });
    }

    return this.dictionaries.manufacturer;
  }

  private get manufacturerName() {
    const manufacturer = findWhere(this.dictionaries.manufacturer, {
      id: this.data.manufacturerId,
    });

    if (!manufacturer) {
      return 'Выберите';
    }

    return manufacturer.name;
  }

  private getClassName(id: number) {
    const item = findWhere(this.dictionaries.manufacturer, { id });

    return item ? item.slug : '';
  }

  private onChange() {
    this.close();
    this.clearError({ name: 'manufacturerId' });
    this.search = '';
  }

  private get error() {
    return (this.errors.manufacturerId || []).join('. ');
  }
}
