
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { findWhere } from 'underscore';

import FieldMixin from '@/mixins/create/field';
import DropdownMixin from '@/mixins/common/dropdown';

import dictionariesService from '@/services/dictionaries-service';
import { Dictionaries, Dictionary } from '@/services/dictionaries-service';
import { VuexAction } from '@/types/functions';

interface IMixins extends FieldMixin, DropdownMixin {}

const commonStore = namespace('common');

@Component
export default class StepContactsLocationCity extends Mixins<IMixins>(
  DropdownMixin,
  FieldMixin
) {
  @commonStore.State dictionaries!: Dictionaries;
  @commonStore.Action setDictionary!: VuexAction;

  private open() {
    this.openDropdown();

    document.body.classList.add('body_state-dropdown-style_visible');
  }

  private close() {
    this.closeDropdown();

    document.body.classList.remove('body_state-dropdown-style_visible');
  }

  private toggle() {
    const isVisible = this.toggleDropdown();

    isVisible
      ? document.body.classList.add('body_state-dropdown-style_visible')
      : document.body.classList.remove('body_state-dropdown-style_visible');
  }

  private get dictionary() {
    const url = dictionariesService.buildUrl('region', {
      country: this.data.country,
      region: this.data.region,
    });

    return this.dictionaries[url] || [];
  }

  protected get city() {
    return this.data.cityId;
  }

  protected set city(value) {
    this.clearError({ name: 'country' });
    this.clearError({ name: 'region' });
    this.clearError({ name: 'cityId' });
    this.setField({ cityId: value });
  }

  private get cityName() {
    const city = findWhere(this.dictionaries.city, {
      id: this.city,
    });

    if (!city) {
      return 'Город';
    }

    return city.name;
  }

  private get urlData() {
    return {
      country: this.data.country,
      region: this.data.region,
    };
  }

  private get url() {
    return dictionariesService.buildUrl('region', this.urlData);
  }

  private onChange() {
    this.close();
  }
}
