var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form",
    {
      staticClass:
        "vehicle-form__comments-item vehicle-form__comments-item_subsidiary helpers_show_tablet",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "vehicle-form__comments-unit" }, [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__control vehicle-form__control_condensed vehicle-form__control_bar vehicle-form__control_bar_additional /*vehicle-form__control_bar_hidden*/",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "popover-style__handle vehicle-form__popover-handle vehicle-form__popover-handle_width_full",
                class: { "popover-style__handle_opened": _vm.isOpened },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "popover-style popover-style_primary popover-style_base popover-style_bottom popover-style_noarrow popover-style_specific-alter popover-style_specific-additional vehicle-form__popover vehicle-form__popover_width_full",
                  },
                  [
                    _c("span", {
                      staticClass: "popover-style__close helpers_show_tablet",
                      on: { click: _vm.closeForm },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "popover-style__container" }, [
                      _c("div", { staticClass: "popover-style__content" }, [
                        _c("div", { staticClass: "vehicle-form" }, [
                          _vm.settings && !_vm.settings.enabled
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__mediabox vehicle-form__mediabox_pause",
                                },
                                [_vm._m(0)]
                              )
                            : !_vm.currentUser || _vm.activeBan
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__mediabox vehicle-form__mediabox_auth",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__mediabox-flex",
                                    },
                                    [
                                      _vm._m(1),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__mediabox-part vehicle-form__mediabox-part_description",
                                        },
                                        [
                                          !_vm.activeBan
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_max-width_mm",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Чтобы оставить свое мнение, необходимо"
                                                  ),
                                                  _c("br"),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base vehicle-form__link_vertical_top vehicle-form__link_narrow-alter",
                                                      attrs: {
                                                        href: _vm.loginUrl,
                                                      },
                                                    },
                                                    [_vm._v("войти")]
                                                  ),
                                                  _vm._v(
                                                    "или\n                        "
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base vehicle-form__link_vertical_top vehicle-form__link_narrow-alter",
                                                      attrs: {
                                                        href: _vm.registrationUrl,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "зарегистрироваться"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm.activeBan.expiredAt
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_max-width_m",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Вы сможете писать комментарии\n                        " +
                                                      _vm._s(
                                                        _vm.activeBanExpireAt
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_max-width_m",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Вы не можете писать комментарии из-за\n                        постоянного нарушения\n                        "
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base vehicle-form__link_vertical_top vehicle-form__link_narrow-alter",
                                                      attrs: {
                                                        href: _vm.rulesUrl,
                                                      },
                                                    },
                                                    [_vm._v("правил")]
                                                  ),
                                                  _vm._v(
                                                    "\n                        сайта\n                      "
                                                  ),
                                                ]
                                              ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.settings &&
                          _vm.settings.enabled &&
                          _vm.currentUser &&
                          !_vm.activeBan
                            ? _c(
                                "div",
                                { staticClass: "vehicle-form__comments" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vehicle-form__comments-box",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__row vehicle-form__row_condensed-fringe",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vehicle-form__group vehicle-form__group_width_full",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__field",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-form__line vehicle-form__line_condensed",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_width_full",
                                                        },
                                                        [
                                                          _c("editor", {
                                                            ref: "toastuiEditor",
                                                            staticClass:
                                                              "input-style input-style_primary input-style_base vehicle-form__input vehicle-form__input_width_full vehicle-form__input_min-height_xss auto-height",
                                                            class: {
                                                              "input-style_error":
                                                                _vm.errors.text,
                                                            },
                                                            attrs: {
                                                              "initial-value":
                                                                _vm.text,
                                                              options:
                                                                _vm.editorOptions,
                                                              height: "auto",
                                                              "initial-edit-type":
                                                                "wysiwyg",
                                                            },
                                                            on: {
                                                              change:
                                                                _vm.onChange,
                                                              load: _vm.onLoad,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.errors.text
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "vehicle-form__line vehicle-form__line_condensed",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_width_full",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                              " +
                                                                  _vm._s(
                                                                    _vm.errors.text.join(
                                                                      ". "
                                                                    )
                                                                  ) +
                                                                  "\n                            "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-form__control vehicle-form__control_condensed-farther",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vehicle-form__control-flex vehicle-form__control-flex_column",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__control-part vehicle-form__control-part_1",
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "button-style button-style_primary button-style_base vehicle-form__button vehicle-form__button_width_full",
                                                      class: {
                                                        "button-style_animated":
                                                          _vm.isProcessing,
                                                      },
                                                      attrs: { type: "submit" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                            Высказаться\n                          "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__control-part vehicle-form__control-part_2 vehicle-form__control-part_text_left",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-form__description vehicle-form__description_other vehicle-form__description_base",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                            Комментируя, вы соглашаетесь с\n                            "
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "vehicle-form__link vehicle-form__link_primary",
                                                          attrs: {
                                                            href: _vm.rulesUrl,
                                                            target: "_blank",
                                                          },
                                                        },
                                                        [_vm._v("правилами")]
                                                      ),
                                                      _vm._v("."),
                                                      _c("br", {
                                                        staticClass:
                                                          "helpers_hide_tablet",
                                                      }),
                                                      _vm._v(
                                                        "\n                            Мы опубликуем комментарий после проверки:\n                            "
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "vehicle-form__link vehicle-form__link_primary",
                                                          attrs: {
                                                            href: "https://people.onliner.by/2020/11/03/onliner-kommentarii",
                                                            target: "_blank",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "как это работает"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    (_vm.settings && !_vm.settings.enabled) || !_vm.currentUser
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "popover-style__bottom helpers_show_tablet",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "button-style button-style_additional button-style_small popover-style__button",
                                attrs: { type: "button" },
                                on: { click: _vm.closeForm },
                              },
                              [_vm._v("\n              Понятно\n            ")]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "popover-style__overlay helpers_show_tablet",
                  on: { click: _vm.closeForm },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show-in-container",
                        rawName: "v-show-in-container",
                        value: {
                          containerSelector: "#comments",
                        },
                        expression:
                          "{\n            containerSelector: '#comments',\n          }",
                      },
                    ],
                    staticClass:
                      "button-style button-style_primary button-style_base vehicle-form__button vehicle-form__button_width_full",
                    attrs: { type: "button" },
                    on: { click: _vm.openForm },
                  },
                  [_vm._v("\n          Высказаться\n        ")]
                ),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__mediabox-flex" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__mediabox-part vehicle-form__mediabox-part_image",
        },
        [
          _c("div", { staticClass: "vehicle-form__preview" }, [
            _c("div", {
              staticClass: "vehicle-form__image vehicle-form__image_cat",
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__mediabox-part vehicle-form__mediabox-part_description",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed-other vehicle-form__title_max-width_lm",
            },
            [
              _vm._v(
                "\n                        Комментарии на паузе\n                      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_max-width_lm",
            },
            [
              _vm._v(
                "\n                        Их отсматривает и публикует модератор. Он\n                        ненадолго отошел, но скоро вернется и все включит.\n                      "
              ),
            ]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__mediabox-part vehicle-form__mediabox-part_image",
      },
      [
        _c("div", { staticClass: "vehicle-form__preview" }, [
          _c("div", {
            staticClass: "vehicle-form__image vehicle-form__image_lock",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }