
import { Mixins, Component } from 'vue-mixin-decorator';

import FieldMixin from '@/mixins/create/field';
import number from '@/filters/format-number';

@Component
export default class StepContactsSellerChatPreferred extends Mixins<FieldMixin>(
  FieldMixin
) {
  private get seller() {
    return this.data.seller || {};
  }

  private get chatPreferred() {
    return this.seller.chatPreferred;
  }

  private set chatPreferred(chatPreferred: boolean) {
    this.clearError({ name: 'seller.phones' });
    this.setField({ seller: { ...this.seller, chatPreferred } });
  }
}
