var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__field" }, [
    _c(
      "div",
      {
        ref: "handle",
        staticClass: "input-style__wrapper vehicle-form__input-wrapper",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "input-style input-style_primary input-style_base input-style_arrow_bottom vehicle-form__input vehicle-form__input_width_sssm",
            class: {
              "input-style_placeholder": !_vm.selectedValue,
              "input-style_error": _vm.isError,
            },
            on: { click: _vm.onOpen },
          },
          [
            _c("div", { staticClass: "input-style__faux" }, [
              _vm._v(_vm._s(_vm.selectedLabel || _vm.placeholder)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-style__real" }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "dropdown-style dropdown-style_primary dropdown-style_small dropdown-style_bottom dropdown-style_specific vehicle-form__dropdown vehicle-form__dropdown_width_full",
            class: { "dropdown-style_visible": _vm.isVisible },
          },
          [
            _c("span", {
              staticClass: "dropdown-style__close helpers_show_tablet",
              on: { click: _vm.onClose },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "dropdown-style__container" }, [
              _c("div", { staticClass: "dropdown-style__content" }, [
                _c("div", { staticClass: "dropdown-style__group" }, [
                  _c("ul", { staticClass: "dropdown-style__list" }, [
                    _c(
                      "li",
                      {
                        staticClass:
                          "dropdown-style__item dropdown-style__item_indent",
                        on: {
                          click: function ($event) {
                            return _vm.onChange(null)
                          },
                        },
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "dropdown-style__checkbox-label" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "i-checkbox i-checkbox_dot-alter dropdown-style__checkbox",
                              },
                              [
                                _c("span", {
                                  staticClass: "i-checkbox__real",
                                  class: {
                                    "i-checkbox__real_checked":
                                      !_vm.selectedValue,
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "i-checkbox__faux" }),
                                _vm._v(" "),
                                _vm._m(0),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-style__group" }, [
                  _c(
                    "ul",
                    { staticClass: "dropdown-style__list" },
                    _vm._l(_vm.options, function (option) {
                      return _c(
                        "li",
                        {
                          key: `model_${option.id}`,
                          staticClass:
                            "dropdown-style__item dropdown-style__item_indent",
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "dropdown-style__checkbox-label" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "i-checkbox i-checkbox_dot dropdown-style__checkbox",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.field,
                                        expression: "field",
                                      },
                                    ],
                                    staticClass: "i-checkbox__real",
                                    attrs: { type: "radio" },
                                    domProps: {
                                      value: option.id,
                                      checked: _vm._q(_vm.field, option.id),
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          _vm.field = option.id
                                        },
                                        function ($event) {
                                          return _vm.onChange(option.id)
                                        },
                                      ],
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "i-checkbox__faux",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dropdown-style__checkbox-text",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "dropdown-style__checkbox-sign",
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(option.name) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "dropdown-style__overlay helpers_show_tablet",
          on: { click: _vm.onClose },
        }),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dropdown-style__checkbox-text" }, [
      _c("div", { staticClass: "dropdown-style__checkbox-sign" }, [
        _vm._v("\n                        Не выбрано\n                      "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }