import * as moment from 'moment';

export default (date: string) => {
  if (!date) {
    return '';
  }

  const days = moment()
    .startOf('day')
    .diff(moment(date).startOf('day'), 'days');

  if (days === 0) {
    return moment(date).format('Сегодня в HH:mm');
  }

  if (days === 1) {
    return moment(date).format('Вчера в HH:mm');
  }

  return moment(date).format('DD.MM.YYYY в HH:mm');
};
