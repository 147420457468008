import { IRangeUnit } from '@/interfaces/search';
import { range } from '@/services/utils-service';
import { Dictionary } from '@/services/dictionaries-service';
import { SINCE_YEAR } from '@/config/common';

export interface IFilterChunk {
  name: string;
  placeholder: string;
  filterPlaceholder: string;
  allPlaceholder?: string;
  anyPlaceholder?: string;
  multi?: boolean;
}

export interface IFilterConfigItem {
  label?: string;
  name: string;
  type: string;
  labelAny?: string;
  labelPlaceholder?: string;
  group?: boolean;
  groupMax?: number;
  withImages?: boolean;
  unitType?: string;
  units?: Array<IRangeUnit>;
  maxlength?: number;
  options?: Dictionary;
  placeholder?: string;
  rangePlaceholders?: {
    from: string;
    to: string;
  };
  typeChain?: IFilterChunk[];
  isCondensed?: boolean;
  isCondensedAlter?: boolean;
  globalEmit?: string;
  usePreferred?: boolean;
}

export type FilterContainer = {
  label?: string;
  type: string;
  isCondensed?: boolean;
  isCondensedAlter?: boolean;
  children: Array<IFilterConfigItem>;
};

export type FilterGroup = Array<IFilterConfigItem>;

export type FilterItem = IFilterConfigItem | FilterGroup | FilterContainer;

export type FiltersConfig = Array<FilterItem>;

const yearsRange = range(SINCE_YEAR, new Date().getFullYear() + 1).reverse();
const yearsDictionary: Dictionary = yearsRange.map(item => {
  return {
    id: item!.toString(),
    name: item!.toString(),
  };
});

export const filters: FiltersConfig = [
  {
    type: 'filter-container',
    label: 'Местонахождение',
    children: [
      {
        name: 'location',
        type: 'chained-select',
        typeChain: [
          {
            name: 'country',
            placeholder: 'Все страны',
            filterPlaceholder: 'Найти страну',
            anyPlaceholder: 'Любая',
          },
          {
            name: 'region',
            placeholder: 'Все области',
            filterPlaceholder: 'Найти область',
            anyPlaceholder: 'Любая',
          },
          {
            name: 'city',
            placeholder: 'Все города',
            filterPlaceholder: 'Найти город',
            anyPlaceholder: 'Любой',
          },
        ],
      },
    ],
  },
  {
    label: 'Марка',
    name: 'car',
    type: 'chained-select',
    group: true,
    groupMax: 5,
    placeholder: 'марку',
    globalEmit: 'filter-car-change',
    typeChain: [
      {
        name: 'manufacturer',
        placeholder: 'Марка',
        filterPlaceholder: 'Найти марку',
        allPlaceholder: 'Все марки',
        anyPlaceholder: 'Любая',
      },
      {
        name: 'model',
        placeholder: 'Модель',
        filterPlaceholder: 'Найти модель',
        anyPlaceholder: 'Любая',
      },
      {
        name: 'generation',
        placeholder: 'Поколение',
        filterPlaceholder: 'Найти поколение',
        anyPlaceholder: 'Любое',
        multi: true,
      },
    ],
  },
  {
    label: 'Цена',
    name: 'price',
    type: 'range-unit',
    maxlength: 8,
    unitType: 'currency',
    units: [
      { id: 'BYN', label: 'BYN' },
      { id: 'USD', label: 'USD' },
      { id: 'EUR', label: 'EUR' },
    ],
    isCondensedAlter: true,
  },
  {
    type: 'filter-container',
    children: [
      {
        label: 'Обмен',
        name: 'exchange',
        type: 'checkbox',
      },
      {
        label: 'Цена с НДС',
        name: 'include_vat',
        type: 'checkbox',
      },
    ],
  },
  {
    type: 'filter-container',
    children: [
      {
        label: 'Год выпуска',
        name: 'year',
        type: 'range',
        labelAny: 'Любой',
        rangePlaceholders: {
          from: 'с',
          to: 'до',
        },
        options: yearsDictionary,
      },
    ],
  },
  {
    type: 'multiselect-dropdown-extended',
    label: 'Тип кузова',
    name: 'body_type',
    withImages: true,
    labelAny: 'Любой',
    usePreferred: true,
  },
  {
    type: 'filter-container',
    label: 'Тип двигателя',
    children: [
      {
        name: 'engine_type',
        type: 'multiselect',
      },
    ],
  },
  {
    type: 'checkbox-container',
    children: [
      {
        label: 'Газобаллонное \nоборудование',
        name: 'gas',
        type: 'checkbox',
      },
      {
        label: 'Гибрид',
        name: 'hybrid',
        type: 'checkbox',
      },
    ],
  },
  {
    label: 'Объем двигателя, л',
    name: 'engine_capacity',
    type: 'range',
    labelAny: 'Любой',
  },
  {
    type: 'filter-container',
    label: 'Коробка передач',
    children: [
      {
        name: 'transmission',
        type: 'multiselect',
      },
    ],
  },
  {
    type: 'filter-container',
    label: 'Привод',
    children: [
      {
        name: 'drivetrain',
        type: 'multiselect',
      },
    ],
  },
  {
    label: 'Пробег, км',
    name: 'odometer',
    type: 'range',
    usePreferred: true,
    maxlength: 8,
  },
  {
    label: 'Цвет',
    name: 'color',
    type: 'color',
  },
  {
    type: 'filter-container',
    label: 'Состояние автомобиля',
    children: [
      {
        name: 'state',
        type: 'multiselect-dropdown',
        labelAny: 'Любое',
      },
    ],
  },
  {
    type: 'filter-container',
    label: 'Продавец',
    children: [
      {
        name: 'seller_type',
        type: 'multiselect',
      },
    ],
  },
  {
    type: 'checkbox-container',
    label: 'Дополнительно',
    children: [
      {
        label: 'На гарантии',
        name: 'warranty',
        type: 'checkbox',
      },
      {
        label: 'Растаможен',
        name: 'customs_clearance',
        type: 'checkbox',
      },
      {
        label: 'Ручное управление',
        name: 'manual_control',
        type: 'checkbox',
      },
    ],
  },
];

export const extendedFilters: FiltersConfig = [
  {
    type: 'filter-container',
    label: 'Мультимедиа',

    isCondensed: true,
    children: [
      {
        name: 'audio_system',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Аудиосистема',
        labelAny: 'Любая',
      },
    ],
  },
  {
    type: 'checkbox-container',
    children: [
      {
        label: 'Навигационная система',
        name: 'navigation_system',
        type: 'checkbox',
      },
      {
        label: 'CarPlay',
        name: 'carplay',
        type: 'checkbox',
      },
      {
        label: 'Яндекс.Авто',
        name: 'yandex_auto',
        type: 'checkbox',
      },
      {
        label: 'Android Auto',
        name: 'android_auto',
        type: 'checkbox',
      },
      {
        label: 'Bluetooth',
        name: 'bluetooth',
        type: 'checkbox',
      },
      {
        label: 'AUX',
        name: 'aux',
        type: 'checkbox',
      },
      {
        label: 'USB',
        name: 'usb',
        type: 'checkbox',
      },
      {
        label: 'Розетка 12V',
        name: 'outlet_12v',
        type: 'checkbox',
      },
      {
        label: 'Розетка 220V',
        name: 'outlet_220v',
        type: 'checkbox',
      },
      {
        label: 'Мультимедиасистема для задних пассажиров',
        name: 'multimedia_for_rear_passengers',
        type: 'checkbox',
      },
    ],
  },
  {
    type: 'filter-container',
    label: 'Комфорт',
    isCondensed: true,
    children: [
      {
        name: 'power_windows',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Электростеклоподъемники',
        labelAny: 'Любые',
      },
      {
        name: 'wheel_configuration',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Регулировка руля',
        labelAny: 'Любая',
      },
      {
        name: 'suspension',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Подвеска',
        labelAny: 'Любая',
      },
      {
        name: 'conditioner',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Кондиционер',
        labelAny: 'Любой',
      },
      {
        name: 'parktronic',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Парктроник',
        labelAny: 'Любой',
      },
      {
        name: 'camera',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Камера',
        labelAny: 'Любая',
      },
    ],
  },
  {
    type: 'checkbox-container',
    children: [
      {
        label: 'Бортовой компьютер',
        name: 'onboard_computer',
        type: 'checkbox',
      },
      {
        label: 'Автономный отопитель',
        name: 'auxiliary_heater',
        type: 'checkbox',
      },
      {
        label: 'Бесключевой доступ',
        name: 'keyless_entry',
        type: 'checkbox',
      },
      {
        label: 'Цифровая приборная панель',
        name: 'digital_dashboard',
        type: 'checkbox',
      },
      {
        label: 'Дистанционный запуск двигателя',
        name: 'remote_engine_start',
        type: 'checkbox',
      },
      {
        label: 'Мультируль',
        name: 'multiwheel',
        type: 'checkbox',
      },
      {
        label: 'Электропривод крышки багажника',
        name: 'decklid_electricdrive',
        type: 'checkbox',
      },
      {
        label: 'Система активной помощи при парковке',
        name: 'park_assist',
        type: 'checkbox',
      },
    ],
  },
  {
    type: 'filter-container',
    label: 'Обзор',
    isCondensed: true,
    children: [
      {
        name: 'headlamps',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Фары',
        labelAny: 'Любые',
      },
      {
        name: 'electrical_heating',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Электрообогрев',
        labelAny: 'Любой',
      },
    ],
  },
  {
    type: 'checkbox-container',
    children: [
      {
        label: 'Противотуманные фары',
        name: 'fog_lights',
        type: 'checkbox',
      },
      {
        label: 'Омыватель фар',
        name: 'light_washer',
        type: 'checkbox',
      },
      {
        label: 'Датчик дождя',
        name: 'rain_sensor',
        type: 'checkbox',
      },
      {
        label: 'Датчик света',
        name: 'light_sensor',
        type: 'checkbox',
      },
      {
        label: 'Система адаптивного освещения',
        name: 'adaptive_lighting_system',
        type: 'checkbox',
      },
      {
        label: 'Автоматическое управление дальним светом',
        name: 'automatic_highbeams_control',
        type: 'checkbox',
      },
    ],
  },
  {
    type: 'filter-container',
    label: 'Экстерьер',
    isCondensed: true,
    children: [
      {
        name: 'wheels',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Тип дисков',
        labelAny: 'Любые',
      },
      {
        name: 'wheels_size',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Размер дисков',
        labelAny: 'Любой',
      },
    ],
  },
  {
    type: 'checkbox-container',
    children: [
      {
        label: 'Фаркоп',
        name: 'drawbar',
        type: 'checkbox',
      },
      {
        label: 'Тонированные стекла',
        name: 'toned_glasses',
        type: 'checkbox',
      },
    ],
  },
  {
    type: 'filter-container',
    label: 'Защита от угона',
    isCondensed: true,
    children: [
      {
        name: 'alarm_system',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Сигнализация',
        labelAny: 'Любая',
      },
    ],
  },
  {
    type: 'checkbox-container',
    children: [
      {
        label: 'Центральный замок',
        name: 'central_locking',
        type: 'checkbox',
      },
    ],
  },
  {
    type: 'filter-container',
    label: 'Салон',
    isCondensed: true,
    children: [
      {
        name: 'number_of_seats',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Количество мест',
        labelAny: 'Любое',
      },
      {
        name: 'compartment_decoration',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Материал салона',
        labelAny: 'Любой',
      },
      {
        name: 'compartment_color',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Цвет салона',
        labelAny: 'Любой',
      },
      {
        name: 'seat_adjustment',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Регулировка сидений',
        labelAny: 'Любая',
      },
      {
        name: 'heated_seats',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Подогрев сидений',
        labelAny: 'Любой',
      },
      {
        name: 'seats_aeration',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Вентиляция сидений',
        labelAny: 'Любая',
      },
      {
        name: 'seats_massage',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Сиденья с массажем',
        labelAny: 'Любые',
      },
    ],
  },
  {
    type: 'checkbox-container',
    children: [
      {
        label: 'Память положения сидений',
        name: 'seat_position_memory',
        type: 'checkbox',
      },
      {
        label: 'Третий ряд сидений',
        name: 'third_seats_row',
        type: 'checkbox',
      },
      {
        label: 'Панорамная крыша',
        name: 'panoramic_roof',
        type: 'checkbox',
      },
      {
        label: 'Люк',
        name: 'hatch',
        type: 'checkbox',
      },
      {
        label: 'Отделка кожей рулевого колеса',
        name: 'steering_wheel_leather_trim',
        type: 'checkbox',
      },
      {
        label: 'Обогрев рулевого колеса',
        name: 'steering_wheel_heating',
        type: 'checkbox',
      },
      {
        label: 'Подрулевые лепестки переключения передач',
        name: 'shift_paddles',
        type: 'checkbox',
      },
      {
        label: 'Отделка кожей рычага КПП',
        name: 'leather_transmission_lever',
        type: 'checkbox',
      },
      {
        label: 'Черный потолок',
        name: 'black_ceiling',
        type: 'checkbox',
      },
      {
        label: 'Отделка потолка алькантарой',
        name: 'ceiling_trim_in_alcantara',
        type: 'checkbox',
      },
      {
        label: 'Передний центральный подлокотник',
        name: 'front_center_armrest',
        type: 'checkbox',
      },
      {
        label: 'Электрорегулировка зеркал заднего вида',
        name: 'electro_controlled_rearview_mirrors',
        type: 'checkbox',
      },
      {
        label: 'Электроскладывание зеркал заднего вида',
        name: 'electro_foldable_rearview_mirrors',
        type: 'checkbox',
      },
      {
        label: 'Зеркала заднего вида с автозатемнением',
        name: 'rearview_mirrors_with_automo_antidazzle',
        type: 'checkbox',
      },
      {
        label: 'Салонное зеркало с автозатемнением',
        name: 'salon_mirror_with_autodarkening',
        type: 'checkbox',
      },
      {
        label: 'Доводчики дверей',
        name: 'door_closer',
        type: 'checkbox',
      },
    ],
  },
  {
    type: 'filter-container',
    label: 'Безопасность',
    isCondensed: true,
    children: [
      {
        name: 'cruise_control',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Круиз-контроль',
        labelAny: 'Любой',
      },
      {
        name: 'auxiliary_system',
        type: 'multiselect-dropdown',
        labelPlaceholder: 'Вспомогательные системы',
        labelAny: 'Любые',
      },
    ],
  },
  {
    type: 'checkbox-container',
    children: [
      {
        label: 'Система крепления детских автокресел',
        name: 'child_seat_mounting_system',
        type: 'checkbox',
      },
    ],
  },
];

export const filtersReview: FiltersConfig = [
  {
    type: 'filter-container',
    label: 'Статусы',
    children: [
      {
        name: 'status',
        type: 'multiselect',
      },
    ],
  },
  {
    label: 'Марка',
    name: 'car',
    type: 'chained-select',
    group: true,
    groupMax: 1,
    placeholder: 'марку',
    globalEmit: 'filter-car-change',
    typeChain: [
      {
        name: 'manufacturer',
        placeholder: 'Марка',
        filterPlaceholder: 'Найти марку',
        allPlaceholder: 'Все марки',
        anyPlaceholder: 'Любая',
      },
      {
        name: 'model',
        placeholder: 'Модель',
        filterPlaceholder: 'Найти модель',
        anyPlaceholder: 'Любая',
      },
      {
        name: 'generation',
        placeholder: 'Поколение',
        filterPlaceholder: 'Найти поколение',
        anyPlaceholder: 'Любое',
        multi: true,
      },
    ],
  },
  {
    type: 'filter-container',
    children: [
      {
        label: 'Год выпуска',
        name: 'year',
        type: 'range',
        labelAny: 'Любой',
        rangePlaceholders: {
          from: 'с',
          to: 'до',
        },
        options: yearsDictionary,
      },
    ],
  },
  {
    type: 'multiselect-dropdown-extended',
    label: 'Тип кузова',
    name: 'body_type',
    withImages: true,
    labelAny: 'Любой',
    usePreferred: true,
  },
  {
    type: 'filter-container',
    label: 'Тип двигателя',
    children: [
      {
        name: 'engine_type',
        type: 'multiselect',
      },
    ],
  },
  {
    type: 'checkbox-container',
    children: [
      {
        label: 'Газобаллонное \nоборудование',
        name: 'gas',
        type: 'checkbox',
      },
      {
        label: 'Гибрид',
        name: 'hybrid',
        type: 'checkbox',
      },
    ],
  },
  {
    label: 'Объем двигателя, л',
    name: 'engine_capacity',
    type: 'range',
    labelAny: 'Любой',
  },
  {
    type: 'filter-container',
    label: 'Коробка передач',
    children: [
      {
        name: 'transmission',
        type: 'multiselect',
      },
    ],
  },
  {
    type: 'filter-container',
    label: 'Привод',
    children: [
      {
        name: 'drivetrain',
        type: 'multiselect',
      },
    ],
  },
  {
    label: 'Пробег, км',
    name: 'odometer',
    type: 'range',
    usePreferred: true,
    maxlength: 8,
  },
  {
    type: 'filter-container',
    label: 'Срок владения',
    children: [
      {
        name: 'tenure',
        type: 'multiselect',
      },
    ],
  },
];

export const getPlainFilters = () => {
  const filtersPlain: Array<IFilterConfigItem> = [];

  filters.forEach((filter: FilterItem) => {
    if ((filter as FilterContainer).children) {
      (filter as FilterContainer).children.forEach(
        (child: IFilterConfigItem) => {
          filtersPlain.push(child);
        },
      );
    } else {
      filtersPlain.push(filter as IFilterConfigItem);
    }
  });

  return filtersPlain;
};
