var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "vehicle-form__offers-list", attrs: { id: "list" } },
        [
          _vm.isLoading
            ? _vm._l(40, function (index) {
                return _c("search-adverts-list-item-stub", { key: index })
              })
            : _vm.adverts.length
            ? _vm._l(_vm.advertsList, function (advert) {
                return _c("adverts-list-item", {
                  key: advert.id,
                  attrs: {
                    advert: advert,
                    selected: _vm.selectedItems.indexOf(advert.id) !== -1,
                  },
                  on: {
                    select: _vm.onSelect,
                    open: _vm.onOpen,
                    close: _vm.onClose,
                  },
                })
              })
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      !_vm.isLoading && !_vm.adverts.length
        ? _c("div", { staticClass: "vehicle-message" }, [
            _c("div", { staticClass: "vehicle-message__content" }, [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-container vehicle-container_width_xxllss",
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm.isOwn
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-message__title vehicle-message__title_big-alter vehicle-message__title_condensed-other",
                        },
                        [
                          _vm._v(
                            "\n          У вас нет\n          " +
                              _vm._s(
                                _vm.status === "active"
                                  ? "активных"
                                  : "закрытых"
                              ) +
                              " объявлений\n        "
                          ),
                        ]
                      )
                    : _vm.permissions.moderate
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-message__title vehicle-message__title_big-alter vehicle-message__title_condensed-other",
                        },
                        [
                          _vm._v(
                            "\n          У пользователя нет\n          " +
                              _vm._s(
                                _vm.status === "active"
                                  ? "активных"
                                  : "закрытых"
                              ) +
                              " объявлений\n        "
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-message__title vehicle-message__title_big-alter vehicle-message__title_condensed-other",
                        },
                        [
                          _vm._v(
                            "\n          У пользователя нет объявлений\n        "
                          ),
                        ]
                      ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-message__control vehicle-message__control_condensed-extra",
                    },
                    [
                      _vm.isOwn
                        ? _c(
                            "router-link",
                            {
                              directives: [
                                {
                                  name: "gtm",
                                  rawName: "v-gtm",
                                  value: { event: "create_ad" },
                                  expression: "{ event: 'create_ad' }",
                                },
                              ],
                              staticClass:
                                "button-style button-style_primary button-style_small vehicle-message__button",
                              attrs: { to: { name: "create-tech" } },
                            },
                            [_vm._v("Разместить объявление")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.permissions.moderate && _vm.adverts.length
        ? _c(
            "div",
            {
              staticClass:
                "vehicle-form__control vehicle-form__control_condensed vehicle-form__control_choose /*vehicle-form__control_choose_alter*/ /*vehicle-form__control_choose_hidden*/",
            },
            [
              _c("div", { staticClass: "vehicle-form__control-flex" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__control-part vehicle-form__control-part_1",
                  },
                  [
                    _c("ul", { staticClass: "vehicle-form__checkbox-list" }, [
                      _c("li", { staticClass: "vehicle-form__checkbox-item" }, [
                        _c(
                          "label",
                          { staticClass: "vehicle-form__checkbox-label" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "i-checkbox vehicle-form__checkbox vehicle-form__checkbox_base",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.isAllSelected,
                                      expression: "isAllSelected",
                                    },
                                  ],
                                  staticClass: "i-checkbox__real",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(_vm.isAllSelected)
                                      ? _vm._i(_vm.isAllSelected, null) > -1
                                      : _vm.isAllSelected,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.isAllSelected,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.isAllSelected = $$a.concat([
                                              $$v,
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.isAllSelected = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.isAllSelected = $$c
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "i-checkbox__faux" }),
                                _vm._v(" "),
                                _vm._m(1),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.selectedItems.length
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__control-part vehicle-form__control-part_2",
                      },
                      [
                        _vm.canOpen
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "button-style button-style_primary button-style_small vehicle-form__button",
                                on: {
                                  click: function ($event) {
                                    return _vm.onOpen(_vm.selectedItems)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Открыть (" +
                                    _vm._s(_vm.selectedItems.length) +
                                    ")"
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.canClose
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "button-style button-style_alter button-style_small vehicle-form__button",
                                on: {
                                  click: function ($event) {
                                    return _vm.onClose(_vm.selectedItems)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Закрыть (" +
                                    _vm._s(_vm.selectedItems.length) +
                                    ")"
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("advert-close-popup", {
        ref: "closeAdvertPopup",
        on: { close: _vm.onClosed },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-message__preview" }, [
      _c("div", {
        staticClass: "vehicle-message__image vehicle-message__image_key",
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__checkbox-text" }, [
      _c("div", { staticClass: "vehicle-form__checkbox-sign" }, [
        _vm._v("Выбрать все"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }