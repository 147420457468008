const Swiper = require('swiper/js/swiper.js');

let swiper: any;

export default {
  inserted(el: HTMLElement, { value }: any) {
    if (value.disable) {
      return;
    }

    const swiperContainer: any = el;
    const config = value ? value.config : null;

    swiper = new Swiper(
      swiperContainer,
      config || {
        slidesPerView: 'auto',
        spaceBetween: 0,
        speed: 300,
        autoplay: false,
        cssMode: true,
        preloadImages: false,
        lazy: true,
        watchSlidesVisibility: true,
        a11y: {
          enabled: false,
        },
      },
    );
  },

  unbind(el: HTMLElement) {
    swiper && swiper.destroy();
  },
};
