var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "list",
      staticClass: "vehicle-form__filter-part vehicle-form__filter-part_2",
    },
    [
      _c("div", { staticClass: "vehicle-form__sorting" }, [
        _c("div", { staticClass: "vehicle-form__sorting-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__sorting-part vehicle-form__sorting-part_1",
            },
            [
              _vm.isLoaded
                ? _c("search-tags", {
                    attrs: {
                      "search-prefix": "reviews",
                      filters: _vm.filters,
                      "remove-filter": _vm.removeFilter,
                      "update-list": _vm.updateList,
                      "filters-config": _vm.filtersConfig,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__sorting-part vehicle-form__sorting-part_2",
            },
            [
              _c("order", {
                attrs: {
                  name: "order",
                  "sort-types": _vm.sortTypes,
                  "set-filter": _vm.setFilter,
                  "state-counters": _vm.stateCounters,
                  "get-filter": _vm.getFilter,
                  filters: _vm.filters,
                  "update-list": _vm.updateList,
                  "update-counters": _vm.updateCounters,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__sorting-part vehicle-form__sorting-part_3 helpers_show_tablet",
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "button-style button-style_auxiliary-specific button-style_small vehicle-form__button vehicle-form__button_filter",
                  on: { click: _vm.onShowFilters },
                },
                [_vm._v("\n          Фильтры\n        ")]
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.reviewsRating
        ? _c(
            "div",
            { staticClass: "vehicle-form__grade vehicle-form__grade_decor" },
            [
              !_vm.reviewsRating.stats
                ? _c("rating-header", {
                    attrs: {
                      "rating-average": _vm.reviewsRating.average,
                      "count-reviews": _vm.reviewsRating.count,
                      "rating-by": _vm.reviewsRating.ratingBy,
                      manufacturer: _vm.manufacturer,
                      model: _vm.model,
                      generation: _vm.generation,
                    },
                  })
                : _c("rating", {
                    attrs: {
                      "reviews-rating": _vm.reviewsRating,
                      manufacturer: _vm.manufacturer,
                      model: _vm.model,
                      generation: _vm.generation,
                    },
                  }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && !_vm.reviews.length
        ? _c("review-list-empty")
        : _c(
            "div",
            { staticClass: "vehicle-form__offers" },
            [_c("review-list")],
            1
          ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          "list-page": _vm.reviewListPage,
          total: _vm.total,
          "last-forms": ["Последний", "Последние", "Последние"],
          "entity-forms": ["отзыв", "отзыва", "отзывов"],
        },
        on: { "set-page": _vm.onSetPage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }