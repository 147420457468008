
  import { Component, Mixins } from 'vue-mixin-decorator';
  import { Prop } from 'vue-property-decorator';

  import MultiselectFilterMixin from '@/mixins/filters/multiselect-filter';
  import Dropdown from '@/mixins/common/dropdown';
  import capitalize from '@/filters/format-capitalize';
  import number from '@/filters/format-number';

  interface IMixinInterface extends Dropdown, MultiselectFilterMixin {}

  @Component({
    filters: {
      capitalize,
      number,
    },
  })
  export default class MultiselectDropdownExtendedFilter extends Mixins<
    IMixinInterface
  >(Dropdown, MultiselectFilterMixin) {
    @Prop() isCondensed!: boolean;
  }
