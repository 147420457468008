import { Vue, Prop } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { IAdvert } from '@/interfaces/adverts';
import { VuexAction } from '@/types/functions';
import * as moment from 'moment';

import AdvertClosePopup from '@/components/advert/advert-close-popup.vue';
import AdvertManageUpCountdown from '@/components/advert/advert-manage-up-countdown.vue';
import { IUser } from '@/interfaces/common';

const userStore = namespace('user');

@Mixin({
  components: {
    AdvertClosePopup,
    AdvertManageUpCountdown,
  },
})
export default class AdvertManage extends Vue {
  @Prop() advert!: IAdvert;

  protected openAdvert!: VuexAction;
  protected closeAdvert!: VuexAction;
  protected upAdvert!: VuexAction;

  @userStore.State currentUser!: IUser;

  public $refs!: {
    closeAdvertPopup: HTMLFormElement;
  };

  protected get isAdvertClosed() {
    return this.advert.closedAt;
  }

  protected get isOwnAdvert() {
    return this.currentUser && this.currentUser.id === this.advert.author.id;
  }

  protected get upAvailableIn() {
    return moment(this.advert.upAvailableIn).format('HH:mm:ss');
  }

  protected get permissions() {
    return this.advert.permissions;
  }

  protected onOpenClick() {
    this.openAdvert({ id: this.advert.id });
    this.$gtm.trackEvent({ event: 'open_ad' });
  }

  protected onCloseClick() {
    if (this.isOwnAdvert) {
      this.closeAdvert({ id: this.advert.id });
      this.$gtm.trackEvent({ event: 'close_ad' });
    } else {
      this.$refs.closeAdvertPopup.openPopup();
    }
  }

  protected onUpClick() {
    this.upAdvert({ id: this.advert.id });
  }
}
