
  import {
    ErrorResponse,
    HashMap,
    ValidationErrors,
  } from '@onlinerby/js-common';
  import { Component } from 'vue-property-decorator';
  import { Mixins } from 'vue-mixin-decorator';
  import { namespace } from 'vuex-class';
  import { findWhere, isEmpty } from 'underscore';
  import * as $ from 'jquery';

  import StepMixin from '@/mixins/reviews/create/step';
  import StepMutationDataMixin from '@/mixins/reviews/create/step-mutation-data';
  import StorageMixin from '@/mixins/reviews/create/storage';
  import urlService from '@/services/url-service';
  import { VuexAction } from '@/types/functions';
  import { stepStatuses } from '@/config/reviews/create';
  import { listUrlService } from '@/config/urls';
  import reviewsCreateTransformer from '@/transformers/reviews-create-transfromer';
  import vmApi from '@/api/vm-api';
  import stepsErrorMap from '@/config/reviews/steps-error-map';
  import { Review } from '@/types/review';
  import { projects } from '@/config/image-upload';

  import StepWrapper from '@/components/common/step-wrapper.vue';
  import StepTechHeader from '@/components/reviews/create/step-tech/step-tech-header.vue';
  import StepTechHeaderMobile from '@/components/reviews/create/step-tech/step-tech-header-mobile.vue';
  import StepDescriptionHeader from '@/components/reviews/create/step-description/step-description-header.vue';
  import StepDescriptionOdometer from '@/components/reviews/create/step-description/step-description-odometer.vue';
  import StepDescriptionTenure from '@/components/reviews/create/step-description/step-description-tenure.vue';
  import StepDescriptionText from '@/components/reviews/create/step-description/step-description-text.vue';
  import StepDescriptionVideoLink from '@/components/reviews/create/step-description/step-description-video-link.vue';
  import StepDescriptionSummary from '@/components/reviews/create/step-description/step-description-summary.vue';
  import StepDescriptionPhoto from '@/components/reviews/create/step-description/step-description-photo.vue';
  import StepDescriptionGrades from '@/components/reviews/create/step-description/step-description-grades.vue';
  import StepDescriptionSecondaryParameters from '@/components/reviews/create/step-description/step-description-secondary-parameters.vue';

  const createStore = namespace('reviews/create');

  interface IMixins extends StepMixin, StepMutationDataMixin, StorageMixin {}

  @Component({
    components: {
      StepWrapper,
      StepTechHeader,
      StepTechHeaderMobile,
      StepDescriptionHeader,
      StepDescriptionOdometer,
      StepDescriptionTenure,
      StepDescriptionText,
      StepDescriptionVideoLink,
      StepDescriptionSummary,
      StepDescriptionPhoto,
      StepDescriptionGrades,
      StepDescriptionSecondaryParameters,
    },
  })
  export default class StepDescription extends Mixins<IMixins>(
    StepMixin,
    StepMutationDataMixin,
    StorageMixin,
  ) {
    protected step: string = 'description';
    private errorMapDescription = stepsErrorMap[this.step];
    private isLoading: boolean = false;

    @createStore.State statusesByStep!: HashMap<string>;
    @createStore.State mode!: string;
    @createStore.Action setStatusesByStep!: VuexAction;

    private get ruleLink() {
      const {
        rules: { projectName, pathQuery },
      } = listUrlService;
      return urlService.secureProjectUrl(projectName, pathQuery);
    }

    private get odometerErrors() {
      const errors = [];

      if (this.errors[this.errorMapDescription.odometer]) {
        errors.push(this.errors[this.errorMapDescription.odometer]);
      }
      if (this.errors[this.errorMapDescription.odometerValue]) {
        errors.push(this.errors[this.errorMapDescription.odometerValue]);
      }

      return errors;
    }

    private get projects() {
      return projects;
    }

    private onStepChange(step: string) {
      this.setStatusesByStep({ [step]: stepStatuses.edit });
    }

    private onTenureChange(tenure) {
      this.clearError(this.errorMapDescription.tenure);
      this.changeField('tenure', tenure);
    }

    private onTextChange(text) {
      this.clearError(this.errorMapDescription.text);
      this.changeField('text', text);
    }

    private onVideoLinkChange(link) {
      const video = link
        ? [
            {
              type: 'youtube',
              url: link,
            },
          ]
        : [];

      this.clearError(this.errorMapDescription.videosUrl);
      this.changeField('videos', video);
    }

    private onSummaryChange(summary) {
      this.clearError(this.errorMapDescription.summary);
      this.changeField('summary', summary);
    }

    private onGradesChange({ grades, avgGrade }) {
      this.clearError(this.errorMapDescription.rating);
      this.changeField('rating', grades);
      this.changeField('avgGrade', avgGrade); // For preview in edit mode
    }

    private onCreateClick() {
      if (!this.validate()) {
        this.trackError(this.errors);

        return;
      }

      this.isLoading = true;

      vmApi.createReview(reviewsCreateTransformer(this.data), {
        success: ({ data }: Review) => {
          const { manufacturer, model, id } = data;

          this.trackSuccess();

          this.$router.push({
            name: 'review',
            params: {
              manufacturer: manufacturer.slug,
              model: model.slug,
              id: id,
            },
          });

          this.setStorageValue('data', null);
        },
        error: ({ response }: { response: ErrorResponse }) => {
          if (response.status === 422) {
            this.setErrors(response.data.errors);
            this.scrollToError();
          }

          response.data.errors && this.trackError(response.data.errors);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    }

    private scrollToError() {
      this.$nextTick(() => {
        const $error = $('.js-step-error');

        if (!$error.length) {
          return;
        }

        const errorTop = $error.first().offset()!.top;

        $(window).scrollTop(errorTop - this.OFFSET);
      });
    }

    protected validate() {
      if (!this.data.tenure) {
        this.setErrors({
          [this.errorMapDescription.tenure]: ['Укажите срок владения'],
        });
      }

      if (this.data.specs.odometer && !this.data.specs.odometer.value) {
        this.setErrors({
          [this.errorMapDescription.odometer]: ['Укажите пробег'],
        });
      }

      if (!this.data.text) {
        this.setErrors({
          [this.errorMapDescription.text]: ['Укажите текст отзыва'],
        });
      }

      if (!this.data.summary) {
        this.setErrors({
          [this.errorMapDescription.summary]: ['Укажите заголовок отзыва'],
        });
      }

      const anyProcessedImages = this.data.images && this.data.images.length;

      if (!anyProcessedImages) {
        this.setErrors({
          [this.errorMapDescription.images]: ['Загрузите фотографии'],
        });
      }

      const isRatingSpecified = !this.dictionaries.primaryMarks.filter(
        mark => !this.data.rating || !this.data.rating[mark.id],
      ).length;

      if (!isRatingSpecified) {
        this.setErrors({
          [this.errorMapDescription.rating]: ['Оцените все параметры'],
        });
      }

      const secondaryMarksErrorText = 'Оцените минимум 3 параметра';

      if (this.data.secondaryMarks) {
        const { pros, cons } = this.data.secondaryMarks;
        const countSelectedSecondaryMarks = pros.length + cons.length;

        if (countSelectedSecondaryMarks < 3) {
          this.setErrors({
            [this.errorMapDescription.secondaryMarks]: [
              secondaryMarksErrorText,
            ],
          });
        }
      } else {
        this.setErrors({
          [this.errorMapDescription.secondaryMarks]: [secondaryMarksErrorText],
        });
      }

      return isEmpty(this.errors);
    }

    private trackSuccess() {
      this.$gtm.trackEvent({
        event: `publish_review - success`,
      });
    }

    private trackError() {
      this.$gtm.trackEvent({
        event: `publish_review - error`,
        value: JSON.stringify({
          errors: this.errors,
        }),
      });
    }
  }
