
import { Mixins, Component } from 'vue-mixin-decorator';

import FieldMixin from '@/mixins/create/field';

@Component
export default class StepCostDescription extends Mixins<FieldMixin>(
  FieldMixin
) {
  private get description() {
    return this.data.description;
  }

  private set description(value: string) {
    this.setField({ description: value });
  }
}
