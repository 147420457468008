import { Prop, Vue } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';

import { CurrentUser, UrlService } from '@onlinerby/js-common';
import { Comment, CommentsConfig } from '@/types/comments';
import CommentsApi from '@/api/comments-api';
import { ActiveBan, CommentsSettings } from '@/types/api';

@Mixin
export default class CommentMixin extends Vue {
  @Prop() config!: CommentsConfig;
  @Prop() projectName!: string;
  @Prop() comment!: Comment;
  @Prop() best!: {
    comment: Comment;
    parent?: Comment;
  };
  @Prop() entityAuthorId!: number;
  @Prop() currentUser!: CurrentUser;
  @Prop() commentsApi!: CommentsApi;
  @Prop() settings!: CommentsSettings;
  @Prop({
    default: true,
  })
  isMarksActive!: boolean;
  @Prop() permissions!: Permissions;
  @Prop() activeBan!: ActiveBan;

  protected created() {
    if (this.comment.isDeleted) {
      this.comment.isHidden = true;
    }

    setTimeout(() => {
      this.comment.isHighlighted = false;
    }, this.config.highlightDuration);
  }

  protected get avatar() {
    return UrlService.secureProjectUrl(
      'content',
      `user/avatar/60x60/${this.comment.author.id}`,
    );
  }

  protected get userLink() {
    return UrlService.secureProjectUrl(
      'profile',
      `user/${this.comment.author.id}`,
    );
  }

  protected get isBest() {
    return this.best && this.best.comment.id === this.comment.id;
  }

  protected get isAuthors() {
    return (
      this.entityAuthorId && this.entityAuthorId === this.comment.author.id
    );
  }

  protected get adminLink() {
    return UrlService.secureProjectUrl(
      'comments.acp',
      `projects/${this.projectName}?comment_id=${this.comment.id}&accepted=true`,
    );
  }

  protected showAllReplies() {}

  private toggleMark(mark: string) {
    this.$emit('mark', {
      commentId: this.comment.id,
      mark,
    });
  }

  private onReplyClick({
    commentId,
    mention,
  }: {
    commentId: string;
    mention: string;
  }) {
    const payload: {
      commentId: string;
      mention?: string;
    } = { commentId };

    if (mention) {
      payload.mention = mention;
    }

    this.showAllReplies();
    this.$emit('reply', payload);
  }

  private onDeleteClick() {
    if (confirm('Вы действительно хотите удалить это сообщение?')) {
      this.$emit('delete', this.comment);
    }
  }
}
