
  import { Component, Prop, Mixins } from 'vue-property-decorator';
  import { CreateElement, VNode } from 'vue';
  import { last } from 'underscore';

  import { FiltersConfig } from '@/config/filters';
  import ColorFilter from '@/components/filters/color-filter.vue';
  import ChainedSelectFilter from '@/components/filters/chained-select-filter.vue';
  import CheckboxFilter from '@/components/filters/checkbox-filter.vue';
  import FilterContainer from '@/components/filters/filter-container.vue';
  import CheckboxContainer from '@/components/filters/checkbox-container.vue';
  import FilterGroup from '@/components/filters/filter-group.vue';
  import MultiselectDropdownFilter from '@/components/filters/multiselect-dropdown-filter.vue';
  import MultiselectDropdownExtendedFilter from '@/components/filters/multiselect-dropdown-extended-filter.vue';
  import MultiselectFilter from '@/components/filters/multiselect-filter.vue';
  import RangeFilter from '@/components/filters/range-filter.vue';
  import RangeUnitFilter from '@/components/filters/range-unit-filter.vue';
  import InterfacePropsMixin from '@/mixins/search/interface-props';
  import { SchemaProperty } from '@/types/common';
  import camelize from '@/filters/format-camelize';

  @Component({
    components: {
      ColorFilter,
      CheckboxFilter,
      CheckboxContainer,
      ChainedSelectFilter,
      FilterContainer,
      FilterGroup,
      MultiselectDropdownFilter,
      MultiselectDropdownExtendedFilter,
      MultiselectFilter,
      RangeFilter,
      RangeUnitFilter,
    },
  })
  export default class SearchFilters extends Mixins<InterfacePropsMixin>(
    InterfacePropsMixin,
  ) {
    @Prop() filtersConfig!: FiltersConfig;

    public render(h: CreateElement): VNode {
      const filters: any = [];
      const interfaceProps = {
        setFilter: this.setFilter,
        stateCounters: this.stateCounters,
        getFilter: this.getFilter,
        filters: this.filters,
        updateList: this.updateList,
        updateCounters: this.updateCounters,
        removeFilter: this.removeFilter,
        schemaProperties: this.schemaProperties,
      };

      this.filtersConfig.forEach((item: any) => {
        if (item.group) {
          this.isVisible(item.name) &&
            filters.push(
              h('filter-group', {
                props: {
                  ...interfaceProps,
                  label: item.label,
                  placeholder: item.placeholder,
                  name: item.name,
                  groupMax: item.groupMax,
                  globalEmit: item.globalEmit,
                },
                scopedSlots: {
                  default: props => {
                    return h(`${item.type}-filter`, {
                      props: Object.assign(item, interfaceProps, {
                        groupIndex: props.groupIndex,
                        canDelete: props.canDelete,
                        globalEmit: item.globalEmit,
                      }),
                      on: {
                        'remove-filter': props.onFilterRemove,
                      },
                    });
                  },
                },
              }),
            );
        } else if (item.type === 'checkbox-container') {
          const children: any = [];

          item.children.forEach((child: any) => {
            this.isVisible(child.name) &&
              children.push(
                h(`${child.type}-filter`, {
                  props: Object.assign(child, interfaceProps),
                }),
              );
          });

          children.length &&
            filters.push(
              h(
                'checkbox-container',
                {
                  props: {
                    label: item.label,
                  },
                },
                children,
              ),
            );
        } else if (item.type === 'filter-container') {
          const children: any = [];

          item.children.forEach((child: any) => {
            const isCondensed = last(item.children) !== child;

            this.isVisible(child.name) &&
              children.push(
                h(`${child.type}-filter`, {
                  props: {
                    ...child,
                    ...interfaceProps,
                    isCondensed,
                  },
                }),
              );
          });

          children.length &&
            filters.push(
              h(
                'filter-container',
                {
                  props: {
                    label: item.label,
                    isCondensed: item.isCondensed,
                  },
                },
                children,
              ),
            );
        } else {
          this.isVisible(item.name) &&
            filters.push(
              h(`${item.type}-filter`, {
                props: Object.assign(item, interfaceProps),
              }),
            );
        }
      });

      return h('div', filters);
    }

    private get visibleFilters() {
      return Object.keys(this.schemaProperties || {});
    }

    private isVisible(name: string) {
      return (
        !this.schemaProperties || this.visibleFilters.includes(camelize(name))
      );
    }
  }
