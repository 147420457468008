
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { every } from 'underscore';

import { HashMap } from '@/types/common';
import { VuexAction } from '@/types/functions';
import StepWrapper from '@/components/create/step-common/step-wrapper.vue';
import StepTechHeader from '@/components/create/step-tech/step-tech-header.vue';
import StepTechHeaderMobile from '@/components/create/step-tech/step-tech-header-mobile.vue';
import StepExteriorHeader from '@/components/create/step-exterior/step-exterior-header.vue';
import StepCostHeader from '@/components/create/step-cost/step-cost-header.vue';
import StepContactsHeader from '@/components/create/step-contacts/step-contacts-header.vue';
import StepMixin from '@/mixins/create/step';

const createStore = namespace('create');

@Component({
  components: {
    StepWrapper,
    StepTechHeader,
    StepTechHeaderMobile,
    StepExteriorHeader,
    StepCostHeader,
    StepContactsHeader,
  },
})
export default class StepEdit extends Mixins<StepMixin>(StepMixin) {
  protected step: string = 'edit';

  @createStore.State data!: HashMap<any>;
}
