
  import { Vue, Component } from 'vue-property-decorator';
  import * as Cookies from 'cookies-js';

  @Component
  export default class BannerMobileInline extends Vue {
    private banner!: any;

    mounted() {
      const windowObject = window as any;
      const p1 =
        this.isFeatureCookiesEnabled &&
        !(windowObject.cookieConsent && windowObject.cookieConsent.ads)
          ? 'ddatz'
          : 'chsun';

      if (
        (window as any).Ya &&
        (window as any).Ya.adfoxCode &&
        (window as any).yaContextCb
      ) {
        (window as any).yaContextCb.push(() => {
          this.banner = (window as any).Ya.adfoxCode.createScroll(
            {
              ownerId: 260941,
              containerId: this.bannerId,
              params: {
                p1,
                p2: 'gekv',
                puid1: 'ab',
              },
            },
            ['phone'],
            {
              tabletWidth: 1000,
              phoneWidth: 640,
              isAutoReloads: false,
            },
          );
        });
      }
    }

    destroyed() {
      this.banner && this.banner.destroy();
    }

    public get bannerId() {
      return 'adfox_157261309391834608';
    }

    private get isFeatureCookiesEnabled() {
      return Cookies('feature_cookies');
    }
  }
