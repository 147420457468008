import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { isArray, findWhere } from 'underscore';

import number from '@/filters/format-number';
import currency from '@/filters/format-currency';
import capitalize from '@/filters/format-capitalize';
import plural from '@/filters/plural-form';
import location from '@/filters/format-location';
import date from '@/filters/format-date';
import relativeDate from '@/filters/format-relative-date';
import {
  ISearchAdvert,
  IAdvertSpecs,
  IEquipmentGroup,
  IEquipmentItem,
} from '@/interfaces/adverts';
import { DictionaryItem } from '@/services/dictionaries-service';
import { odometerTypes, powerTypes } from '@/config/dictionaries';

const commonStore = namespace('common');

@Mixin({
  filters: {
    number,
    currency,
    plural,
    capitalize,
    location,
    date,
    relativeDate,
  },
})
export default class AdvertsListItem extends Vue {
  @Prop() advert!: ISearchAdvert;

  @commonStore.Getter getDictionaryItem!: (
    type: string,
    key: string
  ) => DictionaryItem;

  protected get specs(): IAdvertSpecs {
    return this.advert.specs;
  }

  protected get title(): string {
    return this.advert.title;
  }

  protected get link() {
    return `/car/${this.advert.id}`;
  }

  protected get route() {
    return {
      name: 'advert',
      params: {
        id: this.advert.id,
        manufacturer: this.advert.manufacturer.slug,
        model: this.advert.model.slug,
      },
    };
  }

  protected get engine(): string {
    return this.getDictionaryItem('engineType', this.specs.engine.type).name;
  }

  protected get power(): string {
    const parts: Array<string> = [];
    const { power, torque } = this.specs.engine;

    if (power && power.value) {
      const unit = powerTypes[power.unit];

      parts.push(`${power.value} ${unit}`);
    }

    if (torque) {
      parts.push(`${torque} Нм`);
    }

    return parts.join(' / ');
  }

  protected get drivetrain(): string {
    return this.getDictionaryItem('drivetrain', this.specs.drivetrain).name;
  }

  protected get bodyType(): string {
    return this.getDictionaryItem('bodyType', this.specs.bodyType).name;
  }

  protected get transmission(): string {
    return this.getDictionaryItem('transmission', this.specs.transmission).name;
  }

  protected get color(): string {
    return this.getDictionaryItem('color', this.specs.color).name;
  }

  protected get isPremium(): boolean {
    return this.advert.premium && this.advert.premium.type === 'premium';
  }

  protected get isFixed(): boolean {
    return this.advert.premium && this.advert.premium.type === 'sticky';
  }

  protected get isOptionsDisplayed(): boolean {
    return !!(
      this.advert.premium &&
      this.advert.premium.stickers &&
      this.advert.premium.stickers.length
    );
  }

  protected getOptionName(id: string): string {
    const sticker = this.getDictionaryItem('premiumStickers', id);

    return sticker ? sticker.name : '';
  }

  protected get options() {
    if (this.advert.premium && this.advert.premium.stickers) {
      return this.advert.premium.stickers;
    }

    return [];
  }

  protected get odometerUnit(): string {
    const unit = odometerTypes[this.specs.odometer.unit];

    return unit;
  }

  protected get optionsCount() {
    const equipment = this.advert.equipment;

    let count = 0;

    equipment.forEach((group: IEquipmentGroup) => {
      group.items.forEach((item: IEquipmentItem) => {
        count += isArray(item.value) ? (item.value as Array<string>).length : 1;
      });
    });

    return count;
  }

  protected get isLastUpVisible() {
    const { lastUpAt, createdAt } = this.advert;

    return lastUpAt && lastUpAt !== createdAt;
  }

  protected get premiumType() {
    if (this.isPremium) {
      return 'premium';
    }

    if (this.isFixed) {
      return 'fixing';
    }

    return '';
  }
}
