type ParseFunction = (value: string) => string;
type keyedObject = { [index: string]: any };

const parse = (value: any, method: ParseFunction) => {
  if (!(value instanceof Object)) {
    return value;
  }

  if (value instanceof Array) {
    return parseArray(value, method);
  } else {
    return parseObject(value, method);
  }
};

const parseObject = (value: keyedObject, method: ParseFunction) => {
  const result: keyedObject = {};

  for (let key of Object.keys(value)) {
    const newKey = method(key);

    result[newKey] = parse(value[key], method);
  }

  return result;
};

const parseArray = (value: Array<any>, method: ParseFunction) => {
  const result: Array<any> = [];

  value.forEach(item => {
    result.push(parse(item, method));
  });

  return result;
};

class CamelizeService {
  private static instance: CamelizeService;

  public camelizeString(string: string) {
    const result = string.replace(/_([a-z])/g, function(substring) {
      return substring[1].toUpperCase();
    });

    return result;
  }

  public decamelizeString(string: string) {
    const result = string.replace(/([A-Z])/g, (substring: string) => {
      return '_' + substring[0].toLocaleLowerCase();
    });

    return result;
  }

  public camelize(value: any) {
    return parse(value, this.camelizeString);
  }

  public decamelize(value: any) {
    return parse(value, this.decamelizeString);
  }

  public static getInstance() {
    if (!CamelizeService.instance) {
      CamelizeService.instance = new CamelizeService();
    }

    return CamelizeService.instance;
  }
}

export default CamelizeService.getInstance();
