var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "handle",
      staticClass: "dropdown-style__handle vehicle-form__dropdown-handle",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "dropdown-style dropdown-style_primary dropdown-style_small dropdown-style_bottom-right vehicle-form__dropdown vehicle-form__dropdown_width_xxs",
          class: { "dropdown-style_visible": _vm.isVisible },
        },
        [
          _c("div", { staticClass: "dropdown-style__container" }, [
            _c("div", { staticClass: "dropdown-style__content" }, [
              _c(
                "ul",
                { staticClass: "dropdown-style__list" },
                _vm._l(_vm.sortTypes, function (type) {
                  return _c(
                    "li",
                    {
                      key: type.name,
                      staticClass: "dropdown-style__item",
                      class: {
                        "dropdown-style__item_active":
                          _vm.filterValue === type.name,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.setValue(type.name)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(type.title) + "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "vehicle-form__link vehicle-form__link_other vehicle-form__link_base vehicle-form__link_arrow_bottom",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.toggleDropdown.apply(null, arguments)
            },
          },
        },
        [_vm._v(_vm._s(_vm.selectedFilterLabel))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }