var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "vehicle-form__video vehicle-form__video_max-width_xxxxllll",
    },
    [
      _c(
        "div",
        { staticClass: "vehicle-form__video-viewport" },
        [_c("youtube", { attrs: { "video-id": _vm.videoId } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }