
  import { Component, Mixins } from 'vue-property-decorator';
  import IsMobileMixin from '@/mixins/common/is-mobile';
  import UploadPhotoMixin from '@/mixins/common/upload-photo';
  import FieldMixin from '@/mixins/reviews/create/field';

  interface IMixins extends IsMobileMixin, UploadPhotoMixin, FieldMixin {}

  @Component
  export default class StepDescriptionPhoto extends Mixins<IMixins>(
    IsMobileMixin,
    UploadPhotoMixin,
    FieldMixin,
  ) {
    private getImageUrl(images) {
      return (
        images.preview || // base64 from user
        images['172x132'] || // For edit review with new upload.api
        images['380x240'] || // For data from AB with old upload.api
        images.original // fallback
      );
    }
  }
