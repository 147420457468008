import { Watch } from 'vue-property-decorator';
import { Mixins, Mixin } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { findWhere } from 'underscore';

import {
  Dictionary,
  Dictionaries,
  DictionaryItem,
} from '@/services/dictionaries-service';
import { VuexAction } from '@/types/functions';
import dictionariesService from '@/services/dictionaries-service';
import FieldMixin from '@/mixins/create/field';

const commonStore = namespace('common');

@Mixin
export default class StepTechModel extends Mixins<FieldMixin>(FieldMixin) {
  protected search: string = '';

  @commonStore.State dictionaries!: Dictionaries;
  @commonStore.Action setDictionary!: VuexAction;

  created() {
    this.addDictionary();
  }

  protected get filterString() {
    return this.search
      .trim()
      .toLowerCase()
      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  protected get models() {
    if (!this.manufacturer) {
      return [];
    }

    const dictionary = this.dictionaries[this.url];

    if (this.search && dictionary) {
      const regex = new RegExp(this.filterString);

      return dictionary.filter((item: DictionaryItem) => {
        return regex.test(item.name.toLowerCase());
      });
    }

    return dictionary || [];
  }

  protected get manufacturer() {
    const manufacturer = findWhere(this.dictionaries.manufacturer, {
      id: this.data.manufacturerId,
    })!;

    return manufacturer;
  }

  protected get model() {
    return this.data.modelId;
  }

  protected set model(value) {
    this.setField({ modelId: value });
    this.setField({ generationId: null });
    this.clearError({ name: 'generationId' });
  }

  private get urlData() {
    return {
      manufacturer: this.data.manufacturerId,
    };
  }

  private get url() {
    return dictionariesService.buildUrl('manufacturer', this.urlData);
  }

  protected addDictionary() {
    this.manufacturer &&
      dictionariesService.getDictionary(
        'manufacturer',
        this.urlData,
        (data: Dictionary) => {
          this.setDictionary({
            rootName: 'model',
            name: this.url,
            dictionary: data,
          });
        }
      );
  }

  @Watch('manufacturer')
  onManufacturerChange(value: number, oldValue: number) {
    if (value === oldValue) {
      return;
    }

    this.addDictionary();
  }
}
