
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { every, some, isEmpty } from 'underscore';

import { HashMap } from '@/types/common';
import { VuexAction } from '@/types/functions';
import StepWrapper from '@/components/create/step-common/step-wrapper.vue';
import StepTechHeader from '@/components/create/step-tech/step-tech-header.vue';
import StepTechHeaderMobile from '@/components/create/step-tech/step-tech-header-mobile.vue';
import StepExteriorHeader from '@/components/create/step-exterior/step-exterior-header.vue';
import StepCostHeader from '@/components/create/step-cost/step-cost-header.vue';
import StepContactsHeader from '@/components/create/step-contacts/step-contacts-header.vue';
import StepContactsLocation from '@/components/create/step-contacts/step-contacts-location.vue';
import StepContactsSellerType from '@/components/create/step-contacts/step-contacts-seller-type.vue';
import StepContactsSellerTypeMobile from '@/components/create/step-contacts/step-contacts-seller-type-mobile.vue';
import StepContactsSellerName from '@/components/create/step-contacts/step-contacts-seller-name.vue';
import StepContactsSellerUnp from '@/components/create/step-contacts/step-contacts-seller-unp.vue';
import StepContactsSellerContactName from '@/components/create/step-contacts/step-contacts-seller-contact-name.vue';
import StepContactsSellerPhones from '@/components/create/step-contacts/step-contacts-seller-phones.vue';
import StepContactsSellerCall from '@/components/create/step-contacts/step-contacts-seller-call.vue';
import StepContactsSellerChatPreferred from '@/components/create/step-contacts/step-contacts-seller-chat-preferred.vue';
import CreateFinish from '@/components/create/create-finish.vue';
import StepMixin from '@/mixins/create/step';

const createStore = namespace('create');

@Component({
  components: {
    StepWrapper,
    StepTechHeader,
    StepTechHeaderMobile,
    StepExteriorHeader,
    StepCostHeader,
    StepContactsHeader,
    StepContactsLocation,
    StepContactsSellerType,
    StepContactsSellerTypeMobile,
    StepContactsSellerName,
    StepContactsSellerUnp,
    StepContactsSellerContactName,
    StepContactsSellerPhones,
    StepContactsSellerCall,
    StepContactsSellerChatPreferred,
    CreateFinish,
  },
})
export default class StepContacts extends Mixins<StepMixin>(StepMixin) {
  protected step: string = 'contacts';

  @createStore.State mode!: string;
  @createStore.State data!: HashMap<any>;

  private get seller() {
    return this.data.seller || {};
  }

  private get hasAnyPhones() {
    return (
      this.data.seller &&
      this.data.seller.phones &&
      some(this.data.seller.phones)
    );
  }

  protected validate() {
    if (!this.data.country) {
      this.setError({
        country: ['Укажите страну'],
      });
    } else if (!this.data.region) {
      this.setError({
        region: ['Укажите область'],
      });
    } else if (!this.data.cityId) {
      this.setError({
        cityId: ['Укажите город'],
      });
    }

    if (!this.data.seller.chatPreferred && !this.hasAnyPhones) {
      this.setError({
        'seller.phones': ['Укажите контактный телефон'],
      });
    }

    if (!this.seller.type) {
      this.setError({
        'seller.type': ['Укажите тип продавца'],
      });
    }

    if (!this.seller.name) {
      this.setError({
        'seller.name': ['Укажите имя'],
      });
    }

    if (this.seller.type !== 'individual' && !this.seller.unp) {
      this.setError({
        'seller.unp': ['Укажите УНП'],
      });
    }

    if (this.seller.type !== 'individual' && !this.seller.contactName) {
      this.setError({
        'seller.contactName': ['Укажите контактное лицо'],
      });
    }

    return isEmpty(this.errors);
  }
}
