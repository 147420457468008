
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { Mixins } from 'vue-mixin-decorator';
  import { namespace } from 'vuex-class';
  import { findWhere } from 'underscore';
  import * as moment from 'moment';

  import { SelectedFeature, Features, Entity } from '@/types/promote';
  import { VuexAction } from '@/types/functions';
  import SearchAdvertsListPhotos from '@/components/search/search-adverts-list-photos.vue';
  import SearchAdvertsListPremium from '@/components/search/search-adverts-list-premium.vue';
  import AdvertsListItemMixin from '@/mixins/adverts/adverts-list-item';
  import urlService from '@/services/url-service';

  const promoteStore = namespace('promote');

  @Component({
    components: {
      SearchAdvertsListPhotos,
      SearchAdvertsListPremium,
    },
  })
  export default class PromoteAdvertsListItem extends Mixins<AdvertsListItemMixin>(
    AdvertsListItemMixin,
  ) {
    @Prop() entity!: Entity;

    @promoteStore.State selectedFeature!: SelectedFeature;
    @promoteStore.State features!: Features;
    @promoteStore.Action setSelectedFeature!: VuexAction;

    private get selectedEntity() {
      return this.selectedFeature.entity || {};
    }

    protected get options() {
      if (this.advert.premium && this.advert.premium.stickers) {
        return this.advert.premium.stickers;
      }

      return this.selectedFeature.options || [];
    }

    private get subscriptions() {
      return this.entity.subscriptions;
    }

    private get selected() {
      return this.selectedEntity.id;
    }

    private set selected(id) {
      this.setSelectedFeature({
        feature: {
          ...this.selectedFeature,
          entity: {
            id,
            type: this.entity.type,
          },
        },
      });
    }

    private get activeFeature() {
      return findWhere(this.features, {
        id: this.selectedFeature.id,
      });
    }

    private get activeSubscription() {
      return this.entity.subscriptions.filter(
        item => item.status === 'active',
      )[0];
    }

    private get pendingSubscriptions() {
      return this.entity.subscriptions.filter(
        item => item.status === 'pending',
      );
    }

    private get nextBillAt() {
      return moment(this.activeSubscription.nextBillAt).format('DD MMMM');
    }

    protected get isPremium(): boolean {
      if (this.advert.premium && this.advert.premium.type === 'premium') {
        return true;
      }

      return !!(
        this.selected === this.entity.id &&
        this.activeFeature &&
        this.activeFeature.name === 'premium'
      );
    }

    protected get isFixed(): boolean {
      if (this.advert.premium && this.advert.premium.type === 'sticky') {
        return true;
      }

      return !!(
        this.selected === this.entity.id &&
        this.activeFeature &&
        this.activeFeature.name === 'sticky'
      );
    }

    protected get isOptionsDisplayed(): boolean {
      return (
        !!(
          this.selected === this.entity.id &&
          this.activeFeature &&
          ['auto_up', 'sticky'].indexOf(this.activeFeature.name) !== -1
        ) || !!(this.advert.premium && this.advert.premium.stickers)
      );
    }

    private get paymentLink() {
      return urlService.secureProjectUrl('profile', 'account/payments');
    }
  }
