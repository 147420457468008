
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class SecondaryParameterDiagram extends Vue {
    @Prop() positiveValue!: number;
    @Prop() negativeValue!: number;
    @Prop({
      default: false,
    })
    isSmall!: boolean;

    private get percentagePositive() {
      const total = this.positiveValue + this.negativeValue;
      return Math.round((this.positiveValue / total) * 100);
    }
  }
