var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "vehicle-form__control vehicle-form__control_condensed-appendant vehicle-form__control_switch",
    },
    [
      _vm.permissions.viewClosed
        ? _c(
            "div",
            {
              staticClass:
                "input-style__combo input-style__combo_stretch vehicle-form__input-combo vehicle-form__input-combo_width_full",
            },
            _vm._l(_vm.filters, function (item) {
              return _c(
                "router-link",
                {
                  key: item.id,
                  staticClass:
                    "button-style button-style_obverse button-style_small vehicle-form__button",
                  class: { "button-style_active": item.id === _vm.status },
                  attrs: { to: { name: `${item.id}-adverts` } },
                },
                [_vm._v(_vm._s(item.name))]
              )
            }),
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }