var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "vehicle-form__offers-part vehicle-form__offers-part_action",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__control vehicle-form__control_administration",
        },
        [
          _vm.permissions.up
            ? _c("a", {
                directives: [
                  {
                    name: "gtm",
                    rawName: "v-gtm",
                    value: { event: "ad_up", value: _vm.advertData },
                    expression: "{event: 'ad_up', value: advertData}",
                  },
                ],
                staticClass:
                  "button-style button-style_secondary button-style_base vehicle-form__button vehicle-form__button_up",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.onUpClick.apply(null, arguments)
                  },
                },
              })
            : _vm.isOwnAdvert &&
              _vm.advert.upAvailableIn &&
              !_vm.advert.closedAt
            ? _c("adverts-list-item-up", {
                attrs: {
                  "advert-id": _vm.advert.id,
                  "up-available-in": _vm.advert.upAvailableIn,
                  "is-premium": _vm.advert.premium,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isOwnAdvert && !_vm.advert.closedAt && !_vm.advert.premium
            ? _c(
                "router-link",
                {
                  directives: [
                    {
                      name: "gtm",
                      rawName: "v-gtm",
                      value: {
                        event: "buy_premium_feature",
                        value: _vm.advertData,
                      },
                      expression:
                        "{event: 'buy_premium_feature', value: advertData}",
                    },
                  ],
                  staticClass:
                    "button-style button-style_primary button-style_base vehicle-form__button",
                  attrs: { to: _vm.promotionLink },
                },
                [_vm._v("Ускорить продажу")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.permissions.edit
            ? _c(
                "router-link",
                {
                  staticClass:
                    "button-style button-style_adscititious button-style_base vehicle-form__button",
                  attrs: {
                    to: { name: "edit", params: { id: _vm.advert.id } },
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    },
                  },
                },
                [_vm._v("Редактировать")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isOwnAdvert &&
          _vm.permissions.close &&
          !_vm.isAdvertClosed &&
          _vm.advert.premium
            ? _c("advert-close-popover", { on: { close: _vm.onCloseClick } })
            : _vm.permissions.close && !_vm.isAdvertClosed
            ? _c(
                "a",
                {
                  staticClass:
                    "button-style button-style_adscititious button-style_base vehicle-form__button",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.onCloseClick.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Закрыть")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.permissions.open && _vm.isAdvertClosed
            ? _c(
                "a",
                {
                  directives: [
                    {
                      name: "gtm",
                      rawName: "v-gtm",
                      value: { event: "open_ad", value: _vm.advertData },
                      expression: "{event: 'open_ad', value: advertData}",
                    },
                  ],
                  staticClass:
                    "button-style button-style_adscititious button-style_base vehicle-form__button",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.onOpenClick.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Открыть")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }