
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { VuexAction } from '@/types/functions';
import { IPage } from '@/interfaces/search';
import { range } from '@/services/utils-service';
import Dropdown from '@/mixins/common/dropdown';
import StateServiceMixin from '@/mixins/state-service';
import plural from '@/filters/plural-form';

import * as $ from 'jquery';
import { IUser } from '@/interfaces/common';
require('jquery-mousewheel')($);
require('malihu-custom-scrollbar-plugin')($);

const advertsStore = namespace('adverts');
const userStore = namespace('user');

interface IMixins extends StateServiceMixin, Dropdown {}

@Component({
  filters: {
    plural,
  },
})
export default class AdvertsListPagination extends Mixins<IMixins>(
  StateServiceMixin,
  Dropdown
) {
  @userStore.State currentUser!: IUser;
  @advertsStore.State page!: IPage;
  @advertsStore.State total!: number;
  @advertsStore.Action setPage!: VuexAction;

  mounted() {
    ($(this.$refs.pagesContainer) as any).mCustomScrollbar({
      axis: 'x',
      scrollInertia: 0,
      advanced: {
        updateOnContentResize: false,
        updateOnSelectorChange: true,
      },
    });
  }

  private get pages() {
    return range(1, this.page.last + 1);
  }

  private get count() {
    return this.page.current < this.page.last - 1
      ? this.page.limit
      : this.total % this.page.limit || this.page.limit;
  }

  private showNextPage() {
    const page = this.page.current + 1;

    this.setCurrentPage(page);
  }

  private setCurrentPage(page: number) {
    page === 1
      ? this.stateService.removeProperty('page')
      : this.stateService.setProperty('page', page.toString());

    (document.getElementById('navigation') as HTMLElement).scrollIntoView();

    this.closeDropdown();
  }

  private scrollToPage(page: string) {
    const pageNode = this.$refs[`page_${page}`];

    ($(this.$refs.pagesContainer) as any).mCustomScrollbar(
      'scrollTo',
      pageNode
    );
  }

  @Watch('pages')
  onPagesInit(pages: Array<number>) {
    if (pages.length > 1) {
      this.$nextTick(() => {
        this.scrollToPage(this.page.current.toString());
      });
    }
  }
}
