import userApi from '@/api/user-api';
import { IUser } from '@/interfaces/common';

class UserService {
  private static instance: UserService;
  private user!: IUser;
  private callbacks: Array<(user: IUser) => void> = [];

  private constructor() {
    try {
      this.isSessionSet &&
        userApi.getMe({
          success: ({ data }: any) => {
            this.user = data;

            if (this.callbacks.length) {
              this.callbacks.forEach(callback => {
                callback(this.user);
              });

              this.callbacks = [];
            }
          },
        });
    } catch (exception) {}
  }

  public get isSessionSet() {
    return document.cookie.indexOf('logged_in') !== -1;
  }

  public getMe(callback: (user: IUser) => void) {
    if (this.user) {
      callback(this.user);
    } else {
      this.callbacks.push(callback);
    }
  }

  public static getInstance() {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }

    return UserService.instance;
  }
}

export default UserService.getInstance();
