import { any } from 'underscore';

export default (data: any) => {
  const year = parseInt(data.specs.year);
  const specs = { ...data.specs, year };

  if (!specs.engine.power || !specs.engine.power.value) {
    specs.engine.power = null;
  }

  specs.engine.torque = specs.engine.torque || null;

  const images = data.images
    .map((image: any) => image.original)
    .filter((item: any) => item);

  const secondaryMarks = {
    pros: data.secondaryMarks.pros.map(item => item.id),
    cons: data.secondaryMarks.cons.map(item => item.id),
  };

  return { ...data, images, specs, secondaryMarks };
};
