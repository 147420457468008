
import { Mixins, Component } from 'vue-mixin-decorator';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import DropdownMixin from '@/mixins/common/dropdown';

import ReviewClosePopup from '@/components/reviews/common/review-close-popup.vue';
import { IUser } from '@/interfaces/common';
import { Review } from '@/types/review';

const userStore = namespace('user');

@Component({
  components: {
    ReviewClosePopup,
  },
})
export default class ReviewClose extends Mixins<DropdownMixin>(DropdownMixin) {
  @Prop() review!: Review;

  public $refs!: {
    reviewClosePopup: HTMLFormElement;
  };

  @userStore.State currentUser!: IUser;

  private get isOwnReview() {
    return this.currentUser && this.currentUser.id === this.review.author.id;
  }

  private onCloseClick() {
    if (this.isOwnReview) {
      this.openDropdown();
    } else {
      this.$refs.reviewClosePopup.openPopup();
    }
  }

  private onClose({ closureReason }: { closureReason: string }) {
    this.closeDropdown();

    this.$emit('close', closureReason);
  }
}
