import { HashMap } from '@/types/common';

export type DictionaryMapItem = {
  chunk: string;
  filterGroup: string;
  parent?: string;
  rootName?: string;
  dataName?: string;
  parts?: Array<string>;
};

export type DictionaryMap = HashMap<DictionaryMapItem>;

const map: DictionaryMap = {
  manufacturer: {
    chunk: 'manufacturers',
    filterGroup: 'car',
    rootName: 'model',
    dataName: 'models',
    parts: ['manufacturer'],
  },
  model: {
    chunk: 'models',
    filterGroup: 'car',
    parent: 'manufacturer',
    rootName: 'generation',
    dataName: 'generations',
    parts: ['manufacturer', 'model'],
  },
  generation: {
    chunk: 'generations',
    filterGroup: 'car',
    parent: 'model',
  },
  country: {
    chunk: 'countries',
    filterGroup: 'location',
    rootName: 'region',
    dataName: 'regions',
    parts: ['country'],
  },
  region: {
    chunk: 'regions',
    filterGroup: 'location',
    parent: 'country',
    rootName: 'city',
    dataName: 'cities',
    parts: ['country', 'region'],
  },
  city: {
    chunk: 'citiess',
    filterGroup: 'location',
    parent: 'region',
  },
};

export default map;
