var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "vehicle-form__control vehicle-form__control_condensed-excess",
      },
      [
        _c(
          "span",
          {
            staticClass:
              "button-style button-style_primary button-style_huge vehicle-form__button vehicle-form__button_width_full",
            class: {
              "button-style_disabled": !_vm.isValid,
              "button-style_animated": _vm.isPaymentProcessing,
            },
            on: { click: _vm.onCreateClick },
          },
          [
            _vm._v("\n        Оплатить услугу\n        "),
            _vm.sum
              ? _c("span", [_vm._v("(" + _vm._s(_vm.sum) + " р.)")])
              : _vm._e(),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "vehicle-form__control vehicle-form__control_condensed" },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__control-flex vehicle-form__control-flex_column",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__control-part vehicle-form__control-part_1 vehicle-form__control-part_vertical_middle",
              },
              [
                _c("ul", { staticClass: "vehicle-form__checkbox-list" }, [
                  _c("li", { staticClass: "vehicle-form__checkbox-item" }, [
                    _c(
                      "label",
                      { staticClass: "vehicle-form__checkbox-label" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "i-checkbox vehicle-form__checkbox vehicle-form__checkbox_base",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.isRulesAccepted,
                                  expression: "isRulesAccepted",
                                },
                              ],
                              staticClass: "i-checkbox__real",
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.isRulesAccepted)
                                  ? _vm._i(_vm.isRulesAccepted, null) > -1
                                  : _vm.isRulesAccepted,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.isRulesAccepted,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.isRulesAccepted = $$a.concat([
                                          $$v,
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.isRulesAccepted = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.isRulesAccepted = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "i-checkbox__faux" }),
                            _vm._v(" "),
                            _vm._m(0),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__checkbox-text" }, [
      _c("div", { staticClass: "vehicle-form__checkbox-sign" }, [
        _vm._v("\n                      Соглашаюсь с\n                      "),
        _c(
          "a",
          {
            staticClass:
              "vehicle-form__link vehicle-form__link_base vehicle-form__link_vertical_baseline vehicle-form__link_primary",
            attrs: {
              href: "https://blog.onliner.by/premiumrules",
              target: "_blank",
            },
          },
          [_vm._v("Правилами премиального размещения")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }