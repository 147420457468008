var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
    },
    [
      _vm.generalCounters.adverts
        ? [
            _vm._v(
              _vm._s(_vm._f("number")(_vm.generalCounters.adverts.total)) +
                "\n    " +
                _vm._s(
                  _vm._f("plural")(
                    _vm.generalCounters.total,
                    ["объявление", "объявления", "объявлений"],
                    false
                  )
                )
            ),
          ]
        : [_vm._v(" ")],
      _vm._v(" "),
      _vm.generalCounters.adverts
        ? _c(
            "span",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_success vehicle-form__description_base",
            },
            [
              _vm._v(
                "+ " +
                  _vm._s(_vm._f("number")(_vm.generalCounters.adverts.today)) +
                  " за сегодня!"
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }