var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_condensed-either",
      },
      [_vm._v("\n    Плюсы и минусы\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_extended-other",
      },
      [
        _vm._v(
          "\n    Выберите достоинства и недостатки авто, минимум три опции\n  "
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "vehicle-form__row" }, [
      _c(
        "div",
        { staticClass: "vehicle-form__group vehicle-form__group_width_full" },
        [
          _c("div", { staticClass: "vehicle-form__field" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__line vehicle-form__line_condensed-specific",
              },
              [
                _c("div", { staticClass: "vehicle-form__option" }, [
                  _c("div", { staticClass: "vehicle-form__option-columns" }, [
                    _vm.reviewMarks
                      ? _c(
                          "div",
                          { staticClass: "vehicle-form__option-list" },
                          _vm._l(
                            _vm.reviewMarks.properties,
                            function (value, property) {
                              return value["x-engine"].includes(_vm.engineType)
                                ? _c("pros-cons-switch", {
                                    key: property,
                                    attrs: {
                                      "current-value":
                                        _vm.getCurrentValueByProperty(property),
                                      title: value.title,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onSwitchChange(
                                          property,
                                          $event
                                        )
                                      },
                                    },
                                  })
                                : _vm._e()
                            }
                          ),
                          1
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.error
              ? _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__line vehicle-form__line_condensed-specific",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_width_full",
                      },
                      [
                        _vm._v(
                          "\n            " + _vm._s(_vm.error) + "\n          "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }