var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-show" },
    [
      _c(
        "div",
        { staticClass: "vehicle-form" },
        [
          _vm.isAdvertNotFound
            ? _c("advert-not-found")
            : _vm.isAdvertEmpty
            ? _c("advert-stub")
            : _c("div", { staticClass: "vehicle-form__card js-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "vehicle-form__card-flex" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__card-part vehicle-form__card-part_nav",
                      },
                      [_c("advert-breadcrumbs")],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__card-part vehicle-form__card-part_specification",
                      },
                      [
                        _c("div", { staticClass: "vehicle-form__feature" }, [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__feature-list" },
                            [
                              _c("advert-specs", {
                                attrs: { "reviews-rating": _vm.reviewsRating },
                              }),
                              _vm._v(" "),
                              _c("advert-seller"),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.advert.closedAt ? _c("advert-action") : _vm._e(),
                    _vm._v(" "),
                    _c("advert-header"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__card-part vehicle-form__card-part_media",
                      },
                      [
                        _vm.isManageVisible
                          ? _c("advert-manage", {
                              attrs: { advert: _vm.advert },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isRatingHeaderVisible
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__grade vehicle-form__grade_decor helpers_hide_tablet",
                              },
                              [
                                _c("rating-header", {
                                  attrs: {
                                    "count-reviews": _vm.reviewsRating.count,
                                    "rating-average": _vm.reviewsRating.average,
                                    "rating-by": _vm.reviewsRating.ratingBy,
                                    "is-counter-clickable": true,
                                    "is-specifics-visible": true,
                                    manufacturer: _vm.advert.manufacturer,
                                    model: _vm.advert.model,
                                    generation: _vm.advert.generation,
                                  },
                                  on: {
                                    "click-specifics":
                                      _vm.onRatingHeaderSpecificsClick,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isOwnAdvert &&
                        !_vm.advert.closedAt &&
                        !_vm.advert.premium
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__mediabox vehicle-form__mediabox_premium",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vehicle-form__mediabox-flex",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__mediabox-part vehicle-form__mediabox-part_image",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__preview",
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "vehicle-form__image vehicle-form__image_premium",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__mediabox-part vehicle-form__mediabox-part_content",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed-default",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Ускорить продажу\n                    "
                                            ),
                                            _c("div", {
                                              staticClass:
                                                "vehicle-form__popover-trigger vehicle-form__popover-trigger_premium-alter",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Выберите премиальное размещение и получите максимальное\n                    количество просмотров вашего объявления!\n                  "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__control vehicle-form__control_condensed-additional",
                                          },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                directives: [
                                                  {
                                                    name: "gtm",
                                                    rawName: "v-gtm",
                                                    value: {
                                                      event:
                                                        "buy_premium_feature",
                                                    },
                                                    expression:
                                                      "{ event: 'buy_premium_feature' }",
                                                  },
                                                ],
                                                staticClass:
                                                  "button-style button-style_primary button-style_base vehicle-form__button",
                                                attrs: {
                                                  to: _vm.promotionLink,
                                                },
                                              },
                                              [_vm._v("Попробовать")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isGameEnabled
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-form__mediabox vehicle-form__mediabox_tecno",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vehicle-form__mediabox-flex",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__mediabox-part vehicle-form__mediabox-part_image",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "vehicle-form__preview",
                                            attrs: {
                                              href: "https://auto.onliner.by/2022/11/01/razygryvaem-9-smartfonov-tecno-spark-8c-za-otzyvy-na-avto\n",
                                            },
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "vehicle-form__image vehicle-form__image_tecno",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-form__mediabox-part vehicle-form__mediabox-part_content",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_extended-complementary",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "vehicle-form__link vehicle-form__link_primary-alter vehicle-form__link_nodecor",
                                                attrs: {
                                                  href: "https://auto.onliner.by/2022/11/01/razygryvaem-9-smartfonov-tecno-spark-8c-za-otzyvy-na-avto\n",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed-other",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Разыгрываем смартфоны TECNO\n                      "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_extended-complementary",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Оставьте отзыв об авто в «Автобарахолке»\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "vehicle-form__link vehicle-form__link_primary vehicle-form__link_small",
                                                attrs: {
                                                  href: "https://b2bblog.onliner.by/2022/10/18/pravila-reklamnoj-igry-vyigryvaj-s-onliner-30",
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      Правила рекламной игры\n                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isAdvertPhotosVisible
                          ? _c("gallery", {
                              attrs: {
                                images: _vm.advert.images,
                                imageKeys: {
                                  thumbnail: "380x240",
                                  imageDesktop: "1900x1180",
                                  imageMobile: "600x370",
                                  fullscreen: "original",
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__card-part vehicle-form__card-part_info",
                      },
                      [
                        _c("div", { staticClass: "vehicle-form__panel" }, [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__panel-list" },
                            [
                              _c(
                                "div",
                                { staticClass: "vehicle-form__panel-item" },
                                [
                                  _c("advert-leasing-form", {
                                    attrs: {
                                      leasing: _vm.exclusiveLeasing,
                                      "is-exclusive": true,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.hasAnyEquipment
                                ? _c("advert-equipment")
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isMobileSpecial
                                ? _c("banner-mobile-inline")
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.advert.description
                                ? _c("advert-description")
                                : _vm._e(),
                              _vm._v(" "),
                              _c("advert-similar"),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-banner vehicle-banner_extended",
                                },
                                [
                                  _c("video-banner", {
                                    attrs: {
                                      id: "adfox_163490253284746287",
                                      "container-id":
                                        "adfox_163490253284746287",
                                      ownerId: "239538",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.isMobileSpecial
                                ? _c("advert-person")
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.reviewsRating
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "vehicle-form__panel-item",
                                      attrs: { id: "rating" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "vehicle-form__grade" },
                                        [
                                          _c("rating", {
                                            attrs: {
                                              "reviews-rating":
                                                _vm.reviewsRating,
                                              "is-counter-clickable": true,
                                              manufacturer:
                                                _vm.advert.manufacturer,
                                              model: _vm.advert.model,
                                              generation: _vm.advert.generation,
                                              hide: { divider: true },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.reviewsRating.reviews
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vehicle-form__reviews",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-form__reviews-list",
                                                },
                                                [
                                                  _vm.reviewsRating.reviews
                                                    .positive ||
                                                  _vm.reviewsRating.reviews
                                                    .negative
                                                    ? [
                                                        _vm.reviewsRating
                                                          .reviews.positive
                                                          ? _c(
                                                              "review-card-vertical",
                                                              {
                                                                attrs: {
                                                                  review:
                                                                    _vm
                                                                      .reviewsRating
                                                                      .reviews
                                                                      .positive,
                                                                  type: _vm.positive,
                                                                  "is-more-info-show": false,
                                                                },
                                                                nativeOn: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onReviewClick(
                                                                        _vm
                                                                          .reviewsRating
                                                                          .reviews
                                                                          .positive
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm.reviewsRating
                                                          .reviews.negative
                                                          ? _c(
                                                              "review-card-vertical",
                                                              {
                                                                attrs: {
                                                                  review:
                                                                    _vm
                                                                      .reviewsRating
                                                                      .reviews
                                                                      .negative,
                                                                  type: _vm.negative,
                                                                  "is-more-info-show": false,
                                                                },
                                                                nativeOn: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onReviewClick(
                                                                        _vm
                                                                          .reviewsRating
                                                                          .reviews
                                                                          .negative
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    : _c("review-card-lite", {
                                                        attrs: {
                                                          review:
                                                            _vm.reviewsRating
                                                              .reviews.neutral,
                                                          "is-more-info-show": false,
                                                        },
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onReviewClick(
                                                              _vm.reviewsRating
                                                                .reviews.neutral
                                                            )
                                                          },
                                                        },
                                                      }),
                                                ],
                                                2
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
          _vm._v(" "),
          _c("commercial"),
        ],
        1
      ),
      _vm._v(" "),
      _vm.ld
        ? _c("json-ld", { attrs: { ld: _vm.ld, type: "Product" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }