var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "vehicle-form__intro" }, [
        _c("div", { staticClass: "vehicle-form__intro-flex" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm.isComplete && !_vm.isEditMode
            ? _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__intro-part vehicle-form__intro-part_2",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "vehicle-form__link vehicle-form__link_primary vehicle-form__link_tiny vehicle-form__link_font-weight_normal vehicle-form__link_ellipsis",
                      on: {
                        click: function ($event) {
                          return _vm.editStep("cost")
                        },
                      },
                    },
                    [_vm._v("Изменить")]
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm.isComplete
        ? [
            _c("div", { staticClass: "vehicle-form__parameter" }, [
              _c("div", { staticClass: "vehicle-form__parameter-list" }, [
                _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                  _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                          },
                          [
                            _vm.data.specs.state === "new"
                              ? [
                                  _vm._v(
                                    _vm._s(_vm._f("capitalize")(_vm.state))
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    _vm._s(_vm._f("capitalize")(_vm.state)) +
                                      ", " +
                                      _vm._s(_vm._f("number")(_vm.odometer)) +
                                      "\n                  " +
                                      _vm._s(_vm.odometerUnit)
                                  ),
                                ],
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.specs.warranty
                  ? _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                      _vm._m(2),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.dealTerms.customsClearance === false
                  ? _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                      _vm._m(3),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.description
                  ? _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                      _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-flex" },
                        [
                          _vm._m(4),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                            },
                            [
                              _vm.data.description.length < 500
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.data.description) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.data.description.slice(0, 500)
                                          ) +
                                          "...\n              "
                                      ),
                                    ]
                                  ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                  _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                    _vm._m(5),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.data.price,
                                    _vm.data.price.currency
                                  )
                                ) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.dealTerms.includeVat
                  ? _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                      _vm._m(6),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.dealTerms.exchange
                  ? _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                      _vm._m(7),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.vin
                  ? _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                      _c(
                        "div",
                        { staticClass: "vehicle-form__parameter-flex" },
                        [
                          _vm._m(8),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.vin) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]
        : _c("div"),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__intro-part vehicle-form__intro-part_1" },
      [
        _c("div", { staticClass: "vehicle-form__intro-list" }, [
          _c("div", { staticClass: "vehicle-form__intro-item" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_extended-other",
              },
              [_vm._v("\n              Состояние и цена\n            ")]
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n                Состояние\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__parameter-part vehicle-form__parameter-part_1",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
            },
            [_vm._v("\n                На гарантии\n              ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__parameter-part vehicle-form__parameter-part_2",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
            },
            [_vm._v("\n                Да\n              ")]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__parameter-part vehicle-form__parameter-part_1",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
            },
            [_vm._v("\n                Не растаможен\n              ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__parameter-part vehicle-form__parameter-part_2",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
            },
            [_vm._v("\n                Да\n              ")]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n                Комментарий\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n                Цена\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__parameter-part vehicle-form__parameter-part_1",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
            },
            [_vm._v("\n                Цена с НДС\n              ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__parameter-part vehicle-form__parameter-part_2",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
            },
            [_vm._v("\n                Да\n              ")]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__parameter-part vehicle-form__parameter-part_1",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
            },
            [_vm._v("\n                Возможен обмен\n              ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__parameter-part vehicle-form__parameter-part_2",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed",
            },
            [_vm._v("\n                Да\n              ")]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed",
          },
          [_vm._v("\n                VIN-номер\n              ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }