import { Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import { Comment, CommentsConfig, Page } from '@/types/comments';
import { CurrentUser, Permissions } from '@onlinerby/js-common';
import CommentsApi from '@/api/comments-api';
import { ActiveBan, CommentsSettings, GetCommentsResponse } from '@/types/api';
import { findIndex, findWhere, sortBy } from 'underscore';
import CommentsListItem from '@/components/comments/comments-list-item.vue';
import CommentsFormMobile from '@/components/comments/comments-form-mobile.vue';
import IsMobileMixin from '@/mixins/common/is-mobile';
import { NO_MARK_PERMISSION } from '@/dictionaries/errors';

@Mixin
export default class CommentsCoreMixin extends Mixins<IsMobileMixin>(
  IsMobileMixin,
) {
  @Prop() config!: CommentsConfig;
  @Prop() projectName!: string;
  @Prop() entityType!: string;
  @Prop() entityId!: string;
  @Prop() entityAuthorId!: number;
  @Prop() currentUser!: CurrentUser;

  protected commentsApi: CommentsApi = new CommentsApi({
    projectName: this.projectName,
    entityType: this.entityType,
    entityId: this.entityId,
  });

  protected comments: Comment[] = [];
  protected best?: {
    comment: Comment;
    parent?: Comment;
  } | null = null;
  protected page: Page = {
    items: 0,
    lastCommentId: '',
    limit: this.config.limit,
  };
  protected permissions: Permissions = {};
  protected activeBan: ActiveBan | null = null;
  protected settings: CommentsSettings | null = null;
  protected total: number = 0;
  protected isInitializing: boolean = true;
  protected isLoading: boolean = true;
  protected isAllLoaded: boolean = false;
  protected activeReplyFormId: string = '';
  protected replyText: string = '';

  protected get orderedComments() {
    return sortBy(this.comments, 'createdAt');
  }

  protected isOwnComment(comment: Comment) {
    return this.currentUser && this.currentUser.id === comment.author.id;
  }

  protected updateComments(limit?: number, success?: Function) {
    this.isLoading = true;

    this.commentsApi.getComments(
      {
        limit,
      },
      {
        success: ({
          data: {
            activeBan,
            comments,
            page,
            pins,
            total,
            settings,
            permissions,
          },
        }: GetCommentsResponse) => {
          this.activeBan = activeBan;
          this.comments = comments;
          this.page = page;
          this.best = pins.best;
          this.total = total;
          this.settings = settings;
          this.permissions = permissions;

          if (pins.best) {
            this.best = pins.best;
          }

          if (limit === this.total || this.config.limit >= this.total) {
            this.isAllLoaded = true;
          }

          success && success();
        },
        complete: () => {
          this.isLoading = false;
          this.isInitializing = false;
        },
      },
    );
  }

  protected showAllComments(success: Function) {
    this.updateComments(this.total, success);
  }

  protected findComment(commentId: string, parentId?: string | null) {
    if (parentId) {
      const parent = findWhere(this.comments, { id: parentId });

      if (!parent) {
        return null;
      }

      return findWhere(parent.replies, { id: commentId });
    } else {
      return findWhere(this.comments, { id: commentId });
    }
  }

  protected updateComment(comment: Comment) {
    const targetComment = this.findComment(comment.id, comment.parentId);

    Object.assign(targetComment, comment);
  }

  protected onCommentCreated(comment: Comment) {
    if (this.findComment(comment.id)) {
      this.updateComment(comment);

      return;
    }

    this.comments.unshift(comment);
    this.page.items++;

    this.isOwnComment(comment) &&
      this.$nextTick(() => {
        const commentRef = this.$refs[`comment_${comment.id}`] as Vue[];

        commentRef[0].$el.scrollIntoView();
      });
  }

  protected onReplyCreated(comment: Comment) {
    if (this.findComment(comment.id, comment.parentId)) {
      this.updateComment(comment);

      return;
    }

    const parent = findWhere(this.comments, { id: comment.parentId });

    this.activeReplyFormId = '';

    if (!parent) {
      return;
    }

    if (!parent.replies) {
      this.$set(parent, 'replies', []);
    }

    parent.replies.push(comment);
    this.isOwnComment(comment) &&
      (this.$refs[
        `comment_${parent.id}`
      ] as CommentsListItem[])[0]?.showAllReplies();
  }

  protected onReply({
    commentId,
    mention,
  }: {
    commentId: string;
    mention?: {
      name: string;
    };
  }) {
    this.activeReplyFormId = commentId;
    this.replyText = mention ? `@${mention.name}, ` : '';

    if (this.isMobileSpecial) {
      (this.$refs.commentsFormMobile as CommentsFormMobile).openForm();
    }
  }

  protected onMarkToggle({
    commentId,
    mark,
  }: {
    commentId: string;
    mark: string;
  }) {
    if (!this.permissions[mark]) {
      this.$emit('error', {
        message: NO_MARK_PERMISSION,
      });

      return;
    }

    this.commentsApi.putCommentMark(commentId, mark, {
      success: ({ data }: any) => {
        this.updateComment(data);
      },
    });
  }

  protected onCommentDelete(comment: Comment) {
    this.commentsApi.deleteComment(comment.id, {
      success: () => {
        this.removeComment(comment.id, comment.parentId);
      },
    });
  }

  protected removeComment(commentId: string, parentId?: string) {
    const collection = parentId
      ? this.findComment(parentId)?.replies
      : this.comments;

    if (!collection) {
      return;
    }

    const index = findIndex(collection, { id: commentId });

    if (index === -1) {
      return;
    }

    collection.splice(index, 1);
  }

  @Watch('total')
  onTotalUpdate(total: number) {
    this.$emit('counter-update', total);
  }
}
