
  import { Vue, Component, Prop } from 'vue-property-decorator';

  import { Review } from '@/types/review';
  import Comments from '@/components/comments/comments.vue';
  import { CommentsConfig } from '@/types/comments';
  import comments from '@/config/comments/comments';
  import { CurrentUser } from '@onlinerby/js-common';
  import notificationsService from '@/services/notifications-service';

  @Component({
    components: {
      Comments,
    },
  })
  export default class ReviewComments extends Vue {
    @Prop() review!: Review;
    @Prop() currentUser!: CurrentUser;

    private config: CommentsConfig = comments;

    private onError({ message }: { message: string }) {
      notificationsService.error(message);
    }

    private onCounterUpdate(counter: number) {
      this.$emit('counter-update', counter);
    }

    private onUrlScroll() {
      const { comment, ...query } = this.$route.query;

      this.$router
        .replace({
          path: this.$route.path,
          query,
        })
        .catch(ex => {});
    }
  }
