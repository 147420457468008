import UrlService from '@/services/url-service';

export const listUrlService = {
  rules: {
    projectName: 'blog',
    pathQuery: 'avtobaraholka_rules',
  },
};

export const socialIcon = UrlService.secureProjectUrl(
  'ab',
  '/images/social/ab-icon.png',
);
