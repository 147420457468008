var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__step-helper" },
    _vm._l(_vm.equipmentMap, function (section, key) {
      return _c("step-exterior-equipment-section", {
        key: key,
        attrs: { name: key, section: section },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }