import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';

@Mixin
class KeysNavigation extends Vue {
  protected cursorPointer: number = -1;
  protected options!: Array<any>;

  private get isSupported() {
    return (this.$el as any).scrollIntoViewIfNeeded;
  }

  protected onUpKey() {
    if (!this.isSupported) {
      return;
    }

    if (this.cursorPointer > 0) {
      this.cursorPointer--;
    }

    this.correctScroll();
  }

  protected onDownKey() {
    if (!this.isSupported) {
      return;
    }

    if (this.cursorPointer < this.options.length - 1) {
      this.cursorPointer++;
    }

    this.correctScroll();
  }

  protected resetCurrent() {
    this.cursorPointer = -1;
  }

  private correctScroll() {
    const item: any = this.$refs[`item_${this.cursorPointer}`] as any;

    item &&
      item[0] &&
      item[0].scrollIntoViewIfNeeded &&
      item[0].scrollIntoViewIfNeeded(false);
  }

  protected onFilterUpKey(event: any) {
    if (!this.isSupported) {
      return;
    }

    (this.$refs.list as HTMLElement).focus();

    this.onUpKey();
  }

  protected onFilterDownKey(event: any) {
    if (!this.isSupported) {
      return;
    }

    (this.$refs.list as HTMLElement).focus();

    this.onDownKey();
  }
}

export default KeysNavigation;
