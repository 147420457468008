var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_middle vehicle-form__description_font-weight_bold vehicle-form__description_condensed-either",
      },
      [_vm._v("\n    Фотографии для отзыва\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_extended-other",
      },
      [
        _vm._v(
          "\n    Добавьте фотографии — это поможет больше узнать об автомобиле и\n    рассмотреть его с разных сторон. Вы можете загрузить изображение в формате\n    JPG или PNG. Максимальный размер файла — 20 MB.\n  "
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "vehicle-form__row" }, [
      _c(
        "div",
        { staticClass: "vehicle-form__group vehicle-form__group_width_full" },
        [
          _c("div", { staticClass: "vehicle-form__field" }, [
            _c(
              "div",
              {
                staticClass: "vehicle-form__line vehicle-form__line_condensed",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_width_full",
                  },
                  [
                    _c(
                      "div",
                      {
                        ref: "dropzoneContainer",
                        staticClass:
                          "drop-style drop-style_specific /*drop-style_animated*/ vehicle-form__drop vehicle-form__drop_width_full",
                        class: {
                          "drop-style_dragged": _vm.isDropZoneActive,
                          "drop-style_error": _vm.error,
                        },
                        on: {
                          dragover: _vm.onDragOver,
                          dragleave: _vm.onDragLeave,
                          drop: _vm.onDrop,
                        },
                      },
                      [
                        _c("div", { staticClass: "drop-style__content" }, [
                          _vm.images.length
                            ? _c(
                                "div",
                                { staticClass: "drop-style__upload" },
                                [
                                  _c(
                                    "draggable",
                                    {
                                      staticClass:
                                        "drop-style__upload-list_opened",
                                      attrs: { handle: ".js-move" },
                                      on: { end: _vm.onDrag },
                                      model: {
                                        value: _vm.images,
                                        callback: function ($$v) {
                                          _vm.images = $$v
                                        },
                                        expression: "images",
                                      },
                                    },
                                    _vm._l(_vm.images, function (image, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: `photo_${index}`,
                                          staticClass:
                                            "drop-style__upload-item drop-style__upload-item_primary",
                                          class: {
                                            "drop-style__upload-item_animated":
                                              image &&
                                              image.status === "processing",
                                            "drop-style__upload-item_error":
                                              image && image.status === "error",
                                            "js-move": !_vm.isMobile,
                                          },
                                        },
                                        [
                                          image
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "drop-style__upload-preview",
                                                },
                                                [
                                                  image.images
                                                    ? _c("div", {
                                                        staticClass:
                                                          "drop-style__upload-image",
                                                        style: _vm._f(
                                                          "background"
                                                        )(
                                                          _vm.getImageUrl(
                                                            image.images
                                                          )
                                                        ),
                                                      })
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          image.status === "error" &&
                                          image.errorText
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "drop-style__upload-titles",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "drop-style__upload-title drop-style__upload-title_error",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            image.errorText
                                                          ) +
                                                          "\n                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "drop-style__upload-control drop-style__upload-control_top",
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "button-style button-style_transparent button-style_small drop-style__upload-button drop-style__upload-button_remove",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removePhoto(
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          image.status === "processed"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "drop-style__upload-control drop-style__upload-control_bottom",
                                                },
                                                [
                                                  index === _vm.mainIndex
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "button-style button-style_additional button-style_small button-style_noreflex drop-style__upload-button drop-style__upload-button_main",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          Главная\n                        "
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "button-style button-style_transparent button-style_small drop-style__upload-button drop-style__upload-button_main",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.makeMain(
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Сделать главной"
                                                          ),
                                                        ]
                                                      ),
                                                  _vm._v(" "),
                                                  _vm.isMobile
                                                    ? _c("a", {
                                                        staticClass:
                                                          "button-style button-style_transparent button-style_small drop-style__upload-button drop-style__upload-button_move js-move",
                                                      })
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.images.length
                            ? _c(
                                "div",
                                { staticClass: "drop-style__preview" },
                                [
                                  _c("div", {
                                    staticClass:
                                      "drop-style__image drop-style__image_suv",
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.images.length < _vm.LIMIT
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "drop-style__description drop-style__description_primary drop-style__description_base drop-style__description_text_center",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "button-style button-style_primary button-style_small drop-style__button",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "drop-style__button-faux",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Выберите фотографии\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        staticClass: "drop-style__button-real",
                                        attrs: {
                                          type: "file",
                                          multiple: "",
                                          accept: "image/jpeg, image/png",
                                        },
                                        on: { change: _vm.onFilesSelected },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  !_vm.isMobile
                                    ? _c(
                                        "span",
                                        { staticClass: "helpers_hide_tablet" },
                                        [
                                          _vm._v(
                                            "\n                    или просто перетащите их в выделенную область\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _c("div", { staticClass: "drop-style__title" }, [
                                _vm._v(
                                  "\n                  Вы достигли лимита загрузки фото\n                "
                                ),
                              ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm.error
              ? _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__line vehicle-form__line_condensed",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_width_full",
                      },
                      [
                        _vm._v(
                          "\n            " + _vm._s(_vm.error) + "\n          "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }