
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { every, isEmpty } from 'underscore';

import StepWrapper from '@/components/create/step-common/step-wrapper.vue';
import StepTechBodyType from '@/components/create/step-tech/step-tech-body-type.vue';
import StepTechBodyTypeMobile from '@/components/create/step-tech/step-tech-body-type-mobile.vue';
import StepTechDrivetrain from '@/components/create/step-tech/step-tech-drivetrain.vue';
import StepTechEngineType from '@/components/create/step-tech/step-tech-engine-type.vue';
import StepTechHeader from '@/components/create/step-tech/step-tech-header.vue';
import StepTechHeaderMobile from '@/components/create/step-tech/step-tech-header-mobile.vue';
import StepTechManufacturer from '@/components/create/step-tech/step-tech-manufacturer.vue';
import StepTechManufacturerMobile from '@/components/create/step-tech/step-tech-manufacturer-mobile.vue';
import StepTechModel from '@/components/create/step-tech/step-tech-model.vue';
import StepTechModelMobile from '@/components/create/step-tech/step-tech-model-mobile.vue';
import StepTechGeneration from '@/components/create/step-tech/step-tech-generation.vue';
import StepTechManualControl from '@/components/create/step-tech/step-tech-manual-control.vue';
import StepTechModification from '@/components/create/step-tech/step-tech-modification.vue';
import StepTechTransmission from '@/components/create/step-tech/step-tech-transmission.vue';
import StepTechYear from '@/components/create/step-tech/step-tech-year.vue';
import StepTechYearMobile from '@/components/create/step-tech/step-tech-year-mobile.vue';
import StepExteriorHeader from '@/components/create/step-exterior/step-exterior-header.vue';
import StepCostHeader from '@/components/create/step-cost/step-cost-header.vue';
import StepContactsHeader from '@/components/create/step-contacts/step-contacts-header.vue';
import { HashMap } from '@/types/common';
import StepMixin from '@/mixins/create/step';
import { VuexAction } from '@/types/functions';
import { IAdvertSpecs, IEngine } from '@/interfaces/adverts';
import dictionariesService, {
  Dictionaries,
} from '@/services/dictionaries-service';

const commonStore = namespace('common');
const createStore = namespace('create');

@Component({
  components: {
    StepWrapper,
    StepExteriorHeader,
    StepCostHeader,
    StepContactsHeader,
    StepTechBodyType,
    StepTechBodyTypeMobile,
    StepTechDrivetrain,
    StepTechEngineType,
    StepTechHeader,
    StepTechHeaderMobile,
    StepTechManufacturer,
    StepTechManufacturerMobile,
    StepTechManualControl,
    StepTechModel,
    StepTechModelMobile,
    StepTechGeneration,
    StepTechModification,
    StepTechTransmission,
    StepTechYear,
    StepTechYearMobile,
  },
})
export default class StepTech extends Mixins<StepMixin>(StepMixin) {
  protected step: string = 'tech';

  @commonStore.State dictionaries!: Dictionaries;
  @createStore.State data!: HashMap<any>;

  private get specs(): IAdvertSpecs {
    return this.data.specs || {};
  }

  private get engine(): IEngine {
    return this.specs.engine || {};
  }

  protected validate() {
    const generationsUrl = dictionariesService.buildUrl('model', {
      manufacturer: this.data.manufacturerId,
      model: this.data.modelId,
    });
    const genDictionary = this.dictionaries[generationsUrl];
    const hasGenerations = genDictionary && genDictionary.length;

    if (hasGenerations && !this.data.generationId) {
      this.setError({
        generationId: ['Укажите поколение'],
      });
    }

    if (!this.engine || !this.engine.type) {
      this.setError({
        'specs.engine.type': ['Укажите тип двигателя'],
      });
    } else if (this.engine.type !== 'electric' && !this.engine.capacity) {
      this.setError({
        'specs.engine.capacity': ['Укажите объем двигателя'],
      });
    }

    if (!this.data.manufacturerId) {
      this.setError({
        manufacturerId: ['Укажите марку'],
      });
    } else if (!this.data.modelId) {
      this.setError({
        modelId: ['Укажите модель'],
      });
    }

    if (!this.specs.year) {
      this.setError({
        'specs.year': ['Укажите год выпуска'],
      });
    }

    if (!this.specs.year) {
      this.setError({
        'specs.year': ['Укажите год выпуска'],
      });
    }

    if (!this.specs.bodyType) {
      this.setError({
        'specs.bodyType': ['Укажите тип кузова'],
      });
    }

    if (!this.specs.drivetrain) {
      this.setError({
        'specs.drivetrain': ['Укажите привод'],
      });
    }

    if (!this.specs.transmission) {
      this.setError({
        'specs.transmission': ['Укажите тип коробки передач'],
      });
    }

    return isEmpty(this.errors);
  }
}
