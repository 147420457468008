var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "gtm",
          rawName: "v-gtm",
          value: { event: "share_ad" },
          expression: "{event: 'share_ad'}",
        },
      ],
      ref: "handle",
      staticClass: "popover-style__handle vehicle-form__popover-handle",
      class: { "popover-style__handle_opened": _vm.isVisible },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "popover-style popover-style_primary popover-style_base popover-style_bottom-right vehicle-form__popover vehicle-form__popover_width_sss",
        },
        [
          _c("div", { staticClass: "popover-style__content" }, [
            _c("div", { staticClass: "vehicle-form" }, [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__control vehicle-form__control_condensed-default",
                },
                [
                  _c(
                    "div",
                    {
                      ref: "social",
                      staticClass:
                        "input-style__combo input-style__combo_elastic vehicle-form__input-combo",
                    },
                    [
                      _c("a", {
                        staticClass:
                          "facebook button-style button-style_subsidiary button-style_small vehicle-form__button vehicle-form__button_fb vehicle-form__button_narrow vehicle-form__button_rounded",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.shareInFacebook.apply(null, arguments)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("a", {
                        staticClass:
                          "button-style button-style_extra button-style_small vehicle-form__button vehicle-form__button_vk vehicle-form__button_narrow vehicle-form__button_rounded",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.shareInVk.apply(null, arguments)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("a", {
                        staticClass:
                          "button-style button-style_other button-style_small vehicle-form__button vehicle-form__button_tw vehicle-form__button_narrow vehicle-form__button_rounded",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.shareInTwitter.apply(null, arguments)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("a", {
                        staticClass:
                          "button-style button-style_default button-style_small vehicle-form__button vehicle-form__button_ok vehicle-form__button_narrow vehicle-form__button_rounded",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.shareInOk.apply(null, arguments)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("a", {
                        staticClass:
                          "button-style button-style_complementary button-style_small vehicle-form__button vehicle-form__button_tg vehicle-form__button_narrow vehicle-form__button_rounded",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.shareInTelegram.apply(null, arguments)
                          },
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__row vehicle-form__row_condensed-fringe",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-form__group vehicle-form__group_width_full",
                    },
                    [
                      _c("div", { staticClass: "vehicle-form__field" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-style__wrapper vehicle-form__input-wrapper vehicle-form__input-wrapper_width_full",
                          },
                          [
                            _c("input", {
                              staticClass:
                                "input-style input-style_primary input-style_small vehicle-form__input vehicle-form__input_width_full",
                              attrs: { type: "text" },
                              domProps: { value: _vm.link },
                            }),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__control vehicle-form__control_condensed-default",
                },
                [
                  !_vm.isCopied
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "button-style button-style_primary button-style_small vehicle-form__button vehicle-form__button_width_full",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.copyLink.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Скопировать ссылку")]
                      )
                    : _c(
                        "span",
                        {
                          staticClass:
                            "button-style button-style_either button-style_noreflex button-style_small vehicle-form__button vehicle-form__button_width_full",
                        },
                        [_vm._v("Скопировано")]
                      ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("a", {
        staticClass:
          "button-style button-style_auxiliary button-style_base vehicle-form__button vehicle-form__button_share",
        attrs: { title: "Поделиться" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.toggleDropdown.apply(null, arguments)
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }