
  import { Vue, Component, Prop } from 'vue-property-decorator';

  import { IAdvert } from '@/interfaces/adverts';
  import background from '@/filters/format-background';
  import currency from '@/filters/format-currency';
  import number from '@/filters/format-number';
  import { odometerTypes } from '@/config/dictionaries';
import { Review } from '@/types/review';

  @Component({
    filters: {
      background,
      currency,
      number,
    },
  })
  export default class ReviewProposalsItem extends Vue {
    @Prop() review!: Review;
    @Prop() item!: IAdvert;

    private get odometerUnit(): string {
      const unit = odometerTypes[this.item.specs.odometer.unit];

      return unit;
    }

    public onClick() {
      const dataLayer = (window as any).dataLayer;

      dataLayer &&
        dataLayer.push({
          event: 'select_item',
          items: [
            {
              item_name: this.item.title,
              item_id: this.item.id,
              item_brand: this.item.manufacturer,
              item_category: this.item.model,
              item_variant: this.item.specs.year,
              creative_slot: 'ad',
              quantity: 1,
              price: this.item.price,
            },
          ],
          item_list_name: 'Avtobaraholka - Ads from Review',
          item_list_id: this.review.id,
        });
    }
  }
