import { ActionTree, Payload } from 'vuex';
import { HashMap } from '@onlinerby/js-common';

import { IRootState, ICreateState } from '@/interfaces/reviews/store';

const actions: ActionTree<ICreateState, IRootState> = {
  init(context) {
    context.commit('init');
  },

  setMode(context, { mode }: { mode: string }) {
    context.commit('setMode', mode);
  },

  clearData(context) {
    context.commit('clearData');
  },

  setStatusesByStep(context, payload: Payload) {
    context.commit('setStatusesByStep', payload);
  },

  setField(context, payload: Payload) {
    context.commit('setField', payload);
  },

  setErrors(context, payload: HashMap<any>) {
    context.commit('setErrors', payload);
  },

  clearError(context, payload: string) {
    context.commit('clearError', payload);
  },
};

export default actions;
