var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__mediabox vehicle-form__mediabox_car" },
    [
      _c("div", { staticClass: "vehicle-form__mediabox-flex" }, [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__mediabox-part vehicle-form__mediabox-part_image",
          },
          [
            _c("div", { staticClass: "vehicle-form__preview" }, [
              _c("div", {
                staticClass: "vehicle-form__image vehicle-form__image_car",
                style: _vm._f("background")(_vm.image),
              }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__mediabox-part vehicle-form__mediabox-part_content",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_font-weight_semibold vehicle-form__description_condensed vehicle-form__description_max-width_lmm",
              },
              [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed vehicle-form__description_max-width_lmm",
              },
              [
                _vm._v(
                  "\n        Если да, то мы перенесем характеристики и фото\n        автомобиля из вашего объявления.\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__control vehicle-form__control_condensed-default",
              },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "button-style button-style_primary button-style_small vehicle-form__button",
                    on: { click: _vm.onSubmitClick },
                  },
                  [_vm._v("\n          Да, это моя машина\n        ")]
                ),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }