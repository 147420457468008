import { ActionTree } from 'vuex';

import vmApi from '@/api/vm-api';
import { IAdvertsState, IRootState } from '@/interfaces/store';
import { AdvertResponse, ListPageResponse } from '@/types/api';
import { IOnlineStatus } from '@/interfaces/adverts';
import { isEmpty } from 'underscore';

const actions: ActionTree<IAdvertsState, IRootState> = {
  updateAdverts(context, { id, params, success, error }) {
    vmApi.getAdverts(id, params, {
      success({ data }: any) {
        context.commit('setAdverts', data.adverts);
        context.commit('setPage', data.page);
        context.commit('setTotal', data.total);
        context.commit('setPermissions', data.permissions);
        context.commit('setUser', data.user);

        success && success(data);
      },
      error,
    });
  },

  setPage(context, { id, status, page, callbacks = {} }) {
    const params = Object.assign({}, context.getters.filtersObject, {
      status,
      page,
    });

    context.commit('setLoading', true);

    vmApi.getAdverts(id, params, {
      success({ data }: ListPageResponse) {
        context.commit('setAdverts', data.adverts);
        context.commit('setPage', data.page);
        context.commit('setTotal', data.total);
        context.commit('setPermissions', data.permissions);
        context.commit('setUser', data.user);
        context.commit('setLoading', false);

        callbacks.success && callbacks.success(data);
        callbacks.complete && callbacks.complete();
      },
      error: callbacks.error,
    });
  },

  setLoading(context, { isLoading }) {
    context.commit('setLoading', isLoading);
  },

  openAdvert(context, { id, success }) {
    vmApi.openAdvert(id, {
      success({ data }: AdvertResponse) {
        context.commit('setAdvert', { id, data });

        success && success();
      },
    });
  },

  upAdvert(context, { id }) {
    vmApi.upAdvert(id, {
      success({ data }: AdvertResponse) {
        context.commit('setAdvert', { id, data });
      },
    });
  },

  updateUpTimer(context, { id, upAvailableIn }) {
    context.commit('patchAdvert', {
      id,
      data: {
        upAvailableIn,
      },
    });

    if (upAvailableIn === 0) {
      context.commit('patchPermissions', {
        id,
        data: {
          permissions: {
            up: true,
          },
        },
      });
    }
  },

  updateOnlineStatus(
    context,
    { onlineStatus }: { onlineStatus: IOnlineStatus },
  ) {
    if (isEmpty(context.state.adverts)) {
      return;
    }

    const user = {
      ...context.state.user,
      ...{ onlineStatus },
    };

    context.commit('setUser', user);
  },

  openAdverts(context, { ids, userId, success }) {
    vmApi.openAdverts(userId, ids, {
      success,
    });
  },

  closeAdvert(context, { id, closureReason, success }) {
    vmApi.closeAdvert(id, closureReason, {
      success({ data }: AdvertResponse) {
        context.commit('setAdvert', { id, data });

        success && success();
      },
    });
  },

  closeAdverts(context, { ids, userId, closureReason, success }) {
    vmApi.closeAdverts(userId, ids, closureReason, {
      success,
    });
  },

  addToBookmarks(context, { id, onError }) {
    vmApi.addToBookmarks(id, {
      success() {
        context.commit('patchAdvert', {
          id,
          data: {
            inBookmarks: true,
          },
        });
      },
      error({ response }: any) {
        onError && onError(response);
      },
    });
  },

  removeFromBookmarks(context, { id }) {
    vmApi.removeFromBookmarks(id, {
      success() {
        context.commit('patchAdvert', {
          id,
          data: {
            inBookmarks: false,
          },
        });
      },
    });
  },
};

export default actions;
