import * as moment from 'moment';
import plural from '@/filters/plural-form';

const getTodayRelative = (date: moment.Moment) => {
  if (moment().diff(date, 'seconds') <= 59) {
    return 'только что';
  }

  if (moment().diff(date, 'minutes') <= 59) {
    return `${plural(moment().diff(date, 'minutes'), [
      'минуту',
      'минуты',
      'минут',
    ])} назад`;
  }

  return `${plural(moment().diff(date, 'hours'), [
    'час',
    'часа',
    'часов',
  ])} назад`;
};

export default (value: string) => {
  const date = moment(value);
  const today = moment().startOf('day');

  if (date.isSame(today, 'day')) {
    return getTodayRelative(date);
  }

  const yesterday = moment()
    .subtract(1, 'days')
    .startOf('day');

  if (date.isSame(yesterday, 'day')) {
    return date.format('Вчера в HH:mm');
  }

  return date.format('DD.MM.YYYY');
};
