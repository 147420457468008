import { HashMap } from '@/types/common';

export const currencies: HashMap<string> = {
  BYN: 'р.',
  USD: '$',
  EUR: '€',
  RUB: '₽',
};

export const ranges: HashMap<string> = {
  from: 'от',
  to: 'до',
};

export const rangesAlt: HashMap<string> = {
  from: 'с',
  to: 'до',
};

export const measures: HashMap<string> = {
  year: 'года',
  engine_capacity: 'л',
  odometer: 'км',
};
