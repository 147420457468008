
import { Mixins, Component } from 'vue-mixin-decorator';

import FieldMixin from '@/mixins/create/field';

@Component
export default class StepTechManualControl extends Mixins<FieldMixin>(
  FieldMixin
) {
  private get specs() {
    return this.data.specs || {};
  }

  private get manualControl() {
    return this.specs.manualControl;
  }

  private set manualControl(manualControl: string) {
    this.setField({ specs: { ...this.specs, manualControl } });
  }
}
