
import { Vue, Prop, Component } from 'vue-property-decorator';
import { Feature } from '@/types/promote';
import { namespace } from 'vuex-class';
import Decimal from 'decimal.js-light';
import { without, contains, findWhere } from 'underscore';

import promote, { labelIds } from '@/config/promote';
import { SelectedFeature } from '@/types/promote';
import { VuexAction } from '@/types/functions';
import PromoteFeatureDetail from '@/components/promote/promote-feature-detail.vue';
import PromotePackageOption from '@/components/promote/promote-package-option.vue';
import price from '@/filters/format-price';

const promoteStore = namespace('promote');

@Component({
  components: {
    PromoteFeatureDetail,
    PromotePackageOption,
  },
})
export default class PromotePackage extends Vue {
  @Prop() feature!: Feature;

  @promoteStore.State selectedFeature!: SelectedFeature;
  @promoteStore.Action setSelectedFeature!: VuexAction;
  @promoteStore.State isRecurrent!: boolean;
  @promoteStore.Action setRecurrent!: VuexAction;

  private options: string[] = [];

  private get price() {
    const total = new Decimal(this.feature.basePrice.amount).mul(3);

    return total.toFixed(2).toString().replace('.', ',');
  }

  private get config() {
    return promote[this.feature.name];
  }

  private get recurrent() {
    return this.isRecurrent;
  }

  private set recurrent(isRecurrent) {
    this.setRecurrent({ isRecurrent });
  }

  private get selected() {
    return this.selectedFeature.id;
  }

  private set selected(value) {
    this.setSelectedFeature({
      feature: {
        id: value,
        options: this.options,
      },
    });
  }

  private get stickersLabel() {
    const detail = findWhere(this.feature.details, { id: 'stickers' });

    return detail ? detail.title : '';
  }

  private get isSelected() {
    return this.selected === this.feature.id;
  }

  private get labels() {
    return this.feature.details.filter((item) => {
      return contains(labelIds, item.id);
    });
  }

  private onOptionsAdd(value: string) {
    this.options = [...this.options, value];

    this.selectedFeature.id === this.feature.id &&
      this.setSelectedFeature({
        feature: {
          options: this.options,
        },
      });
  }

  private onOptionsRemove(value: string) {
    this.options = without(this.options, value);

    this.selectedFeature.id === this.feature.id &&
      this.setSelectedFeature({
        feature: {
          options: this.options,
        },
      });
  }
}
