var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("tr", { tag: "div" }, [
    _c("td", [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed-alter vehicle-form__description_nowrap",
        },
        [_vm._v("\n      " + _vm._s(_vm.time) + "\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c("td", [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__description vehicle-form__description_base vehicle-form__description_condensed-alter vehicle-form__description_nowrap",
          class: {
            "vehicle-form__description_success":
              _vm.entry.status === "published",
            "vehicle-form__description_error": _vm.entry.status === "closed",
            "vehicle-form__description_other":
              _vm.entry.status === "on_moderation",
          },
        },
        [_vm._v("\n      " + _vm._s(_vm.status) + "\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c("td", [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base vehicle-form__description_condensed-alter vehicle-form__description_nowrap vehicle-form__description_text_right",
        },
        [_vm._v("\n      " + _vm._s(_vm.entry.user.name) + "\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }