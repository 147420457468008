var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__control vehicle-form__control_condensed" },
    [
      _vm.advert.availableLeasing.length
        ? [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter vehicle-form__description_condensed-other",
              },
              [_vm._v("Купить этот автомобиль в лизинг")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "vehicle-form__unfold",
                class: { "vehicle-form__unfold_opened": _vm.isOpened },
              },
              [
                _c("div", { staticClass: "vehicle-form__unfold-header" }, [
                  _c("div", { staticClass: "vehicle-form__leasing" }, [
                    _c(
                      "div",
                      { staticClass: "vehicle-form__leasing-list" },
                      [
                        _c("advert-leasing-item", {
                          attrs: { item: _vm.mainLeasingOption },
                          on: { click: _vm.onLeasingClick },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.isMoreLeasingOptions
                  ? [
                      _c("div", { staticClass: "vehicle-form__unfold-body" }, [
                        _c("div", { staticClass: "vehicle-form__leasing" }, [
                          _c(
                            "div",
                            { staticClass: "vehicle-form__leasing-list" },
                            _vm._l(_vm.otherLeasingOptions, function (item) {
                              return _c("advert-leasing-item", {
                                key: item.company,
                                attrs: { item: item },
                                on: { click: _vm.onLeasingClick },
                              })
                            }),
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "vehicle-form__unfold-footer" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_tiny",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "vehicle-form__link vehicle-form__link_primary-alter vehicle-form__link_tiny",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__unfold-text vehicle-form__unfold-text_on",
                                      on: {
                                        click: function ($event) {
                                          _vm.isOpened = true
                                        },
                                      },
                                    },
                                    [_vm._v("Все предложения")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-form__unfold-text vehicle-form__unfold-text_off",
                                      on: {
                                        click: function ($event) {
                                          _vm.isOpened = false
                                        },
                                      },
                                    },
                                    [_vm._v("Скрыть")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isOwnAdvert ? _c("advert-stats") : _vm._e(),
      _vm._v(" "),
      _c("advert-leasing-popup", { ref: "leasingPopup" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }