
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import { Dictionaries } from '@/services/dictionaries-service';
import FieldMixin from '@/mixins/create/field';

const commonStore = namespace('common');

@Component
export default class StepCostExchange extends Mixins<FieldMixin>(FieldMixin) {
  @commonStore.State dictionaries!: Dictionaries;

  private get dealTerms() {
    return this.data.dealTerms || {};
  }

  private get includeVat() {
    return this.dealTerms.includeVat;
  }

  private set includeVat(value) {
    this.setField({
      dealTerms: { ...this.dealTerms, includeVat: value },
    });
  }

  private get exchange() {
    return this.dealTerms.exchange;
  }

  private set exchange(value) {
    this.setField({
      dealTerms: { ...this.dealTerms, exchange: value },
    });
  }
}
