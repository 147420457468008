import { HashMap } from '@/types/common';

const labels: HashMap<string> = {
  generation: 'Поколение',
  state: 'Состояние автомобиля',
  transmission: 'Коробка передач',
  price: 'Цена',
  exchange: 'Обмен',
  includeVat: 'Цена с НДС',
  bodyType: 'Тип кузова',
  engineType: 'Тип двигателя',
  hybrid: 'Гибрид',
  gas: 'Газобаллонное оборудование',
  engineCapacity: 'Объем двигателя',
  drivetrain: 'Привод',
  odometer: 'Пробег',
  year: 'Год выпуска',
  color: 'Цвет',
  sellerType: 'Продавец',
  location: 'Местонахождение',
  warranty: 'На гарантии',
  customsClearance: 'Растаможен',
  manualControl: 'Ручное управление',
  audioSystem: 'Аудиосистема',
  navigationSystem: 'Навигационная система',
  carplay: 'CarPlay',
  yandexAuto: 'Яндекс.Авто',
  androidAuto: 'Android Auto',
  bluetooth: 'Bluetooth',
  aux: 'AUX',
  usb: 'USB',
  outlet_12v: 'Розетка 12V',
  outlet_220v: 'Розетка 220V',
  multimediaForRearPassengers: 'Мультимедиасистема для задних пассажиров',
  powerWindows: 'Электростеклоподъемники',
  wheelConfiguration: 'Регулировка руля',
  suspension: 'Подвеска',
  conditioner: 'Кондиционер',
  parktronic: 'Парктроник',
  camera: 'Камера',
  onboardComputer: 'Бортовой компьютер',
  auxiliaryHeater: 'Автономный отопитель',
  keylessEntry: 'Бесключевой доступ',
  digitalDashboard: 'Цифровая приборная панель',
  remoteEngineStart: 'Дистанционный запуск двигателя',
  multiwheel: 'Мультируль',
  decklidElectricdrive: 'Электропривод крышки багажника',
  parkAssist: 'Система активной помощи при парковке',
  headlamps: 'Фары',
  electricalHeating: 'Электрообогрев',
  fogLights: 'Противотуманные фары',
  lightWasher: 'Омыватель фар',
  rainSensor: 'Датчик дождя',
  lightSensor: 'Датчик света',
  adaptiveLightingSystem: 'Система адаптивного освещения',
  automaticHighbeamsControl: 'Автоматическое управление дальним светом',
  wheels: 'Тип дисков',
  wheelsSize: 'Размер дисков',
  drawbar: 'Фаркоп',
  tonedGlasses: 'Тонированные стекла',
  alarmSystem: 'Сигнализация',
  centralLocking: 'Центральный замок',
  numberOfSeats: 'Количество мест',
  compartmentDecoration: 'Материал салона',
  compartmentColor: 'Цвет салона',
  seatAdjustment: 'Регулировка сидений',
  heatedSeats: 'Подогрев сидений',
  seatsAeration: 'Вентиляция сидений',
  seatsMassage: 'Сиденья с массажем',
  seatPositionMemory: 'Память положения сидений',
  thirdSeatsRow: 'Третий ряд сидений',
  panoramicRoof: 'Панорамная крыша',
  hatch: 'Люк',
  steeringWheelLeatherTrim: 'Отделка кожей рулевого колеса',
  steeringWheelHeating: 'Обогрев рулевого колеса',
  shiftPaddles: 'Подрулевые лепестки переключения передач',
  leatherTransmissionLever: 'Отделка кожей рычага КПП',
  blackCeiling: 'Черный потолок',
  ceilingTrimInAlcantara: 'Отделка потолка алькантарой',
  frontCenterArmrest: 'Передний центральный подлокотник',
  electroControlledRearviewMirrors: 'Электрорегулировка зеркал заднего вида',
  electroFoldableRearviewMirrors: 'Электроскладывание зеркал заднего вида',
  rearviewMirrorsWithAutomoAntidazzle: 'Зеркала заднего вида с автозатемнением',
  salonMirrorWithAutodarkening: 'Салонное зеркало с автозатемнением',
  doorCloser: 'Доводчики дверей',
  cruiseControl: 'Круиз-контроль',
  auxiliarySystem: 'Вспомогательные системы',
  childSeatMountingSystem: 'Система крепления детских автокресел',
};

export default (key: string) => {
  return labels[key];
};
