import { HashMap } from '@/types/common';
import services, { SocialConfig } from '@/config/socials';

class ShareService {
  private static instance: ShareService;

  private template(tmpl: string, context: HashMap<any>, filter: Function) {
    return tmpl.replace(/\{([^\}]+)\}/g, (m, key) => {
      // If key doesn't exists in the context we should keep template tag as is
      return key in context
        ? filter
          ? filter(context[key])
          : context[key]
        : m;
    });
  }

  private makeUrl(url: string, context: HashMap<any>) {
    return this.template(url, context, encodeURIComponent);
  }

  private openPopup(url: string, params: HashMap<any>) {
    const left = Math.round(screen.width / 2 - params.width / 2);
    let top = 0;
    if (screen.height > params.height) {
      top = Math.round(screen.height / 3 - params.height / 2);
    }

    const win = window.open(
      url,
      'sl_share',
      'left=' +
        left +
        ',top=' +
        top +
        ',' +
        'width=' +
        params.width +
        ',height=' +
        params.height +
        ',personalbar=0,toolbar=0,scrollbars=1,resizable=1'
    );

    if (win) {
      win.focus();
    } else {
      location.href = url;
    }
  }

  private share(service: SocialConfig, context: HashMap<string>) {
    const urlString = this.makeUrl(service.popupUrl, context);

    this.openPopup(urlString, {
      width: service.popupWidth,
      height: service.popupHeight,
    });
  }

  public shareInFacebook(context: HashMap<string>) {
    this.share(services.facebook, context);
  }

  public shareInVk(context: HashMap<string>) {
    this.share(services.vkontakte, context);
  }

  public shareInTwitter(context: HashMap<string>) {
    this.share(services.twitter, context);
  }

  public shareInOk(context: HashMap<string>) {
    this.share(services.odnoklassniki, context);
  }

  public shareInTelegram(context: HashMap<string>) {
    this.share(services.telegram, context);
  }

  public static getInstance() {
    if (!ShareService.instance) {
      ShareService.instance = new ShareService();
    }

    return ShareService.instance;
  }
}

export default ShareService.getInstance();
