import { contains } from 'underscore';

import generalConfig from '@/config/general';
import { ILocation } from '@/interfaces/adverts';

export default (location: ILocation, hideRegion?: boolean) => {
  if (!location) {
    return '';
  }

  const parts = [location.city.name];

  if (
    !hideRegion &&
    location.region.name &&
    !contains(generalConfig.location.prime.city, parseInt(location.city.id))
  ) {
    parts.push(location.region.name);
  }

  if (
    location.country.name &&
    !contains(
      generalConfig.location.exclude.country,
      parseInt(location.country.id)
    )
  ) {
    parts.push(location.country.name);
  }

  return parts.join(', ');
};
