var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__card-part vehicle-form__card-part_data" },
    [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_condensed-other",
        },
        [
          _c(
            "h1",
            {
              staticClass:
                "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_condensed-other",
            },
            [_vm._v("\n      " + _vm._s(_vm.advert.title) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "popover-style__handle popover-style__handle_interactive vehicle-form__popover-handle",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "popover-style popover-style_primary popover-style_base popover-style_bottom-left vehicle-form__popover vehicle-form__popover_width_ssm",
                },
                [
                  _c("div", { staticClass: "popover-style__content" }, [
                    _c("div", { staticClass: "vehicle-form" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base",
                        },
                        [
                          _vm._v(
                            "\n              Создано " +
                              _vm._s(_vm._f("date")(_vm.advert.createdAt)) +
                              "\n              "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _vm.isUpdatedAtVisible
                            ? [
                                _vm._v(
                                  "Редактировалось " +
                                    _vm._s(_vm._f("date")(_vm.advert.updatedAt))
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "vehicle-form__popover-trigger vehicle-form__popover-trigger_info",
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "vehicle-form__intro" }, [
        _c("div", { staticClass: "vehicle-form__intro-list" }, [
          _c("div", { staticClass: "vehicle-form__intro-item" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_warning-alter vehicle-form__description_small vehicle-form__description_up",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm._f("relativeDate")(_vm.advert.lastUpAt)) +
                    "\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__intro-item" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_other vehicle-form__description_small vehicle-form__description_eye",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.advert.stats.views.total) +
                    "\n          "
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_success vehicle-form__description_small",
                  },
                  [
                    _vm._v(
                      "+ " +
                        _vm._s(_vm.advert.stats.views.today) +
                        " за сегодня"
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__intro-item" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
              },
              [_vm._v("\n          № " + _vm._s(_vm.advert.id) + "\n        ")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__intro-item" }, [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm._f("location")(_vm.advert.location)) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }