var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a",
    {
      staticClass: "vehicle-form__slider-item swiper-slide",
      attrs: { target: "_blank", href: _vm.item.htmlUrl },
      on: { click: _vm.onClick },
    },
    [
      _c("div", { staticClass: "vehicle-form__preview" }, [
        _c("div", {
          staticClass: "vehicle-form__image",
          style: _vm._f("background")(
            _vm.item.images[0]["600x370"] || _vm.item.images[0].original
          ),
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_condensed-alter",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base-alter vehicle-form__link_ellipsis",
            },
            [_vm._v("\n      " + _vm._s(_vm.item.title) + "\n    ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "vehicle-form__intro" }, [
        _c("div", { staticClass: "vehicle-form__intro-list" }, [
          _c("div", { staticClass: "vehicle-form__intro-item" }, [
            _vm.item.specs.odometer.value
              ? _c(
                  "span",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_other vehicle-form__description_small vehicle-form__description_nowrap",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.item.specs.year) +
                        ",\n          " +
                        _vm._s(
                          _vm._f("number")(_vm.item.specs.odometer.value)
                        ) +
                        "\n          " +
                        _vm._s(_vm.odometerUnit) +
                        "\n        "
                    ),
                  ]
                )
              : _c(
                  "span",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_other vehicle-form__description_small vehicle-form__description_nowrap",
                  },
                  [_vm._v(_vm._s(_vm.item.specs.year) + ", новый")]
                ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vehicle-form__intro-item" }, [
            _c(
              "span",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_other vehicle-form__description_small vehicle-form__description_nowrap",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.item.location.city.name) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_ellipsis",
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm._f("currency")(_vm.item.price, "BYN")) +
              "\n  "
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }