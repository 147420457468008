
  import { Component, Prop, Vue } from 'vue-property-decorator';

  import plural from '@/filters/plural-form';
  import RatingStars from '@/components/common/rating-reviews/rating-stars.vue';
  import { Chunk } from '@/types/common';
  import JsonLd from '@/components/common/json-ld.vue';

  @Component({
    components: {
      JsonLd,
      RatingStars,
    },
    filters: { plural },
  })
  export default class RatingHeader extends Vue {
    @Prop() ratingBy!: string;
    @Prop() ratingAverage!: number;
    @Prop() countReviews!: number;
    @Prop({
      default: false,
    })
    isCounterClickable!: boolean;
    @Prop({
      default: false,
    })
    isInfoBlockVisible!: boolean;
    @Prop({
      default: false,
    })
    isSpecificsVisible!: boolean;
    @Prop()
    manufacturer!: Chunk;
    @Prop()
    model!: Chunk;
    @Prop()
    generation!: Chunk;

    private get reviewsUrl() {
      return (
        '/reviews/' +
        [this.manufacturer, this.model, this.generation]
          .filter(item => item)
          .map(item => item.slug)
          .join('/')
      );
    }

    private get ld() {
      const name = [
        this.manufacturer?.name,
        this.model?.name,
        this.generation?.name,
      ]
        .filter(item => item)
        .join(' ');

      return {
        itemReviewed: {
          '@type': 'Product',
          name,
        },
        ratingValue: this.ratingAverage,
        bestRating: 5,
        ratingCount: this.countReviews,
      };
    }

    private onSpecificsClick() {
      this.$emit('click-specifics');
    }
  }
