
  import { Component, Mixins } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';

  import ReviewsCounter from '@/components/reviews/list/reviews-counter.vue';
  import SearchReviews from '@/components/reviews/list/search-reviews.vue';
  import SearchFiltersBlock from '@/components/search/search-filters-block.vue';
  import { VuexAction } from '@/types/functions';
  import { IFilter } from '@/interfaces/filters';
  import { filtersReview, FiltersConfig } from '@/config/filters';
  import { ReviewsRating } from '@/types/review';
  import vmApi from '@/api/vm-api';
  import DeviceClass from '@/directives/device-class';
  import { SchemaProperty } from '@/types/common';
  import HumanizeMixin from '@/mixins/search/humanize';
  import MetaMixin from '@/mixins/reviews/search/meta';
  import FiltersMixin from '@/mixins/search/filters';
  import StateServiceMixin from '@/mixins/state-service';
  import { socialIcon } from '@/config/urls';

  const searchStore = namespace('reviews/search');
  const commonStore = namespace('common');

  interface IMixins
    extends StateServiceMixin,
      HumanizeMixin,
      MetaMixin,
      FiltersMixin {}

  @Component({
    components: {
      SearchReviews,
      ReviewsCounter,
      SearchFiltersBlock,
    },
    directives: {
      DeviceClass,
    },
    metaInfo() {
      return {
        title: 'Отзывы автовладельцев об автомобилях',
        meta: [
          {
            name: 'description',
            content:
              'Отзывы, рейтинги, плюсы и минусы автомобилей в «Автобарахолке» Onlíner',
          },
          {
            name: 'og:image',
            content: socialIcon,
          },
        ],
      };
    },
  })
  export default class Reviews extends Mixins<IMixins>(
    FiltersMixin,
    HumanizeMixin,
    MetaMixin,
    StateServiceMixin,
  ) {
    private filtersConfig: FiltersConfig = filtersReview;
    private isLoaded: boolean = false;
    private reviewsRating: ReviewsRating | null = null;
    private schemaProperties: SchemaProperty[] = [];

    @searchStore.State total!: number;
    @searchStore.State('counters') stateCounters: any;
    @searchStore.State filters!: Array<IFilter>;
    @searchStore.State isLoading!: boolean;
    @searchStore.Getter getFilter!: (
      name: string,
      filterType?: string,
    ) => IFilter;
    @searchStore.Action getReviews!: VuexAction;
    @searchStore.Action setPage!: VuexAction;
    @searchStore.Action setFilter!: VuexAction;
    @searchStore.Action setFilters!: VuexAction;
    @searchStore.Action removeFilter!: VuexAction;
    @searchStore.Action clearFilters!: VuexAction;
    @searchStore.Action updateCounters!: VuexAction;
    @searchStore.Action updateGeneralCounters!: VuexAction;

    @commonStore.State isFirstPage!: boolean;

    protected created() {
      this.updateFilters();
      this.updateSchema();

      this.$root.$on('filter-car-change', () => {
        this.getReviewsRating();
      });

      this.$root.$on('filterChanged', this.onFiltersChange);
      this.$root.$on('filterChanged', this.onFilterChanged);
    }

    private destroyed() {
      this.$root.$off('filterChanged', this.onFiltersChange);
      this.$root.$off('filterChanged', this.onFilterChanged);
    }

    private updateFilters() {
      const filters = this.stateService.filters.filter(item => {
        return item.name !== 'page';
      });

      this.setFilters({ filters });
    }

    protected initList() {
      if (this.stateService.state.page) {
        this.setPage({
          page: this.stateService.state.page,
          callbacks: {
            complete: () => {
              this.getReviewsRating();
              this.isLoaded = true;
            },
          },
        });
      } else {
        this.getReviews({
          callbacks: {
            complete: () => {
              this.getReviewsRating();
              this.isLoaded = true;
            },
          },
        });
      }
    }

    protected hideFilters() {
      $('body').removeClass('body_state-vehicle-form-filter_opened');

      this.getReviews({
        callbacks: {
          success: () => {
            this.$root.$emit('filterChanged');
          },
        },
      });
    }

    private getReviewsRating() {
      this.reviewsRating = null;
      const filtersByCar = this.getFilter('car').value;

      if (!filtersByCar || this.total < 1) return;

      const filter = { ...filtersByCar[0] }; // For review only 1 element, but in ab can be more than 1

      if (filter.generation) {
        filter.generation =
          filter.generation.length > 1 ? undefined : filter.generation[0];
      }

      const params = {
        car: filter,
        include: filter.generation ? 'stats' : undefined,
      };

      vmApi.getReviewsRating(params, {
        success: ({ data }: { data: ReviewsRating }) => {
          if (data.stats && data.count < 3) {
            data.stats.cons = [];
            data.stats.pros = [];
          }

          if (data.count < 1) {
            this.reviewsRating = null;
          } else {
            this.reviewsRating = {
              ...data,
              ratingBy: params.car.model ? 'модели' : 'марки',
              car: {
                manufacturer: {
                  id: filter.manufacturer,
                },
                model: {
                  id: filter.model,
                },
                generation: {
                  id: filter.generation,
                },
              },
            };
          }
        },
      });
    }

    private updateSchema() {
      vmApi.getSearchSchema('reviews', {
        success: ({
          data: { properties },
        }: {
          data: { properties: SchemaProperty[] };
        }) => {
          this.schemaProperties = properties;
        },
      });
    }

    private onFilterChanged() {
      if (!this.filters.length) {
        this.reviewsRating = null;
      }

      const { path, filters } = this.humanize();

      this.stateService.setState(filters);
      this.$router
        .replace({ path: `/reviews${path}`, query: this.stateService.state })
        .catch(ex => {});
    }
  }
