var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__step-list" }, [
    _c(
      "div",
      { staticClass: "vehicle-form__step-list" },
      [
        _c(
          "step-wrapper",
          { attrs: { step: "tech" } },
          [
            !_vm.isMobileSpecial
              ? _c("step-tech-header")
              : _c("step-tech-header-mobile"),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "step-wrapper",
          { attrs: { step: "exterior" } },
          [_c("step-exterior-header")],
          1
        ),
        _vm._v(" "),
        _c(
          "step-wrapper",
          { ref: "cost", attrs: { step: "cost" } },
          [
            _c("step-cost-header"),
            _vm._v(" "),
            _c("step-cost-state"),
            _vm._v(" "),
            _c("step-cost-vin"),
            _vm._v(" "),
            _c("step-cost-odometer"),
            _vm._v(" "),
            _c("div", {
              staticClass:
                "vehicle-form__divider vehicle-form__divider_extended-alter vehicle-form__divider_wide-alter",
            }),
            _vm._v(" "),
            _c("step-cost-description"),
            _vm._v(" "),
            _c("div", {
              staticClass:
                "vehicle-form__divider vehicle-form__divider_extended-alter vehicle-form__divider_wide-alter",
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vehicle-form__step-helper" },
              [
                _c("step-cost-price"),
                _vm._v(" "),
                _c("step-cost-exchange"),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__control vehicle-form__control_condensed-specific",
                  },
                  [
                    _vm.isEdit
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "button-style button-style_primary button-style_middle vehicle-form__button vehicle-form__button_width_sssm",
                            on: { click: _vm.onFinishClick },
                          },
                          [_vm._v("Подтвердить")]
                        )
                      : _c(
                          "a",
                          {
                            staticClass:
                              "button-style button-style_primary button-style_middle vehicle-form__button vehicle-form__button_width_sssm",
                            on: { click: _vm.onNextClick },
                          },
                          [_vm._v("Далее")]
                        ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "step-wrapper",
          { attrs: { step: "contacts" } },
          [_c("step-contacts-header")],
          1
        ),
        _vm._v(" "),
        _vm.mode === "edit" ? _c("create-finish") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }