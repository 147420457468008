
  import { Mixins, Component } from 'vue-mixin-decorator';

  import FieldMixin from '@/mixins/reviews/create/field';
  import { odometerTypes } from '@/config/dictionaries';

  @Component
  export default class StepDescriptionOdometer extends Mixins<FieldMixin>(
    FieldMixin,
  ) {
    private get odometer() {
      return this.data.specs.odometer || {};
    }

    private set odometer(value) {
      this.onFieldChange(value);
    }

    private get odometerValue() {
      return this.odometer.value;
    }

    private set odometerValue(_value) {
      const value = parseInt(_value);

      if (value && !isNaN(value as number)) {
        this.odometer = { ...this.odometer, value };
      } else {
        const { value, ...odometer } = this.odometer;

        this.odometer = odometer;
      }
    }

    private get odometerUnits() {
      return odometerTypes;
    }

    private setOdometerUnit(value: string) {
      this.odometer = { ...this.odometer, unit: value };
    }
  }
