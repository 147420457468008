export const searchList = {
  11: {
    id: '300x300_catalog_ab',
    params: [
      '/282428283/Onliner.by_300x300_catalog_ab/Onliner.by_300x300_catalog_ab',
      [
        [300, 300],
        [320, 100],
        [320, 50],
        [300, 250],
        [336, 280],
      ],
      '300x300_catalog_ab',
    ],
    isMobile: true,
  },
  19: {
    id: '300x300_catalog_ab_2',
    params: [
      '/282428283/Onliner.by_300x300_catalog_ab/Onliner.by_300x300_catalog_ab_2',
      [
        [300, 250],
        [320, 100],
        [336, 280],
        [300, 300],
        [320, 50],
      ],
      '300x300_catalog_ab_2',
    ],
    isMobile: true,
  },
};
