
import { Mixins, Component } from 'vue-mixin-decorator';
import { without, isEmpty, findWhere } from 'underscore';

import StepExteriorEquipmentDropdownMixin from '@/mixins/create/step-exterior-equipment-dropdown';
import { DictionaryItem } from '@/services/dictionaries-service';
import { ChangeEvent } from '@/types/events';
import { HashMap } from '@/types/common';
import DeviceClass from '@/directives/device-class';

@Component({
  directives: {
    DeviceClass,
  },
})
export default class StepExteriorEquipmentMultipleDropdown extends Mixins<
  StepExteriorEquipmentDropdownMixin
>(StepExteriorEquipmentDropdownMixin) {
  private get selectedLabel() {
    if (!this.field) {
      return;
    }

    return this.field
      .map((id: string) => {
        return findWhere(this.options, { id })!.name;
      })
      .join(', ');
  }

  private onChange(option: DictionaryItem, event: ChangeEvent) {
    let value;
    let equipment;

    if (event.target.checked) {
      value = [...(this.equipment[this.property] || []), option.id];

      equipment = { ...this.equipment, [this.property]: value };

      this.setField({ equipment });
    } else {
      value = without(this.equipment[this.property], option.id);

      equipment = { ...this.equipment, [this.property]: value };

      const newEquipment: HashMap<string | Array<string>> = {};

      for (let key of Object.keys(this.equipment)) {
        if (equipment[key] === true || !isEmpty(equipment[key])) {
          newEquipment[key] = equipment[key];
        }
      }

      this.setField({ equipment: newEquipment });
    }
  }
}
