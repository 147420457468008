
  import { Vue, Component } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import * as moment from 'moment';

  import { IAdvert } from '@/interfaces/adverts';
  import date from '@/filters/format-date';
  import relativeDate from '@/filters/format-relative-date';
  import location from '@/filters/format-location';

  const advertStore = namespace('advert');

  @Component({
    filters: {
      date,
      relativeDate,
      location,
    },
  })
  export default class AdvertHeader extends Vue {
    @advertStore.State advert!: IAdvert;

    private get isUpdatedAtVisible() {
      const { updatedAt, createdAt } = this.advert;

      return updatedAt && !moment(updatedAt).isSame(createdAt);
    }
  }
