
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { every, isEmpty } from 'underscore';

import { VuexAction } from '@/types/functions';
import StepWrapper from '@/components/create/step-common/step-wrapper.vue';
import StepTechHeader from '@/components/create/step-tech/step-tech-header.vue';
import StepTechHeaderMobile from '@/components/create/step-tech/step-tech-header-mobile.vue';
import StepExteriorHeader from '@/components/create/step-exterior/step-exterior-header.vue';
import StepCostHeader from '@/components/create/step-cost/step-cost-header.vue';
import StepCostState from '@/components/create/step-cost/step-cost-state.vue';
import StepCostOdometer from '@/components/create/step-cost/step-cost-odometer.vue';
import StepCostDescription from '@/components/create/step-cost/step-cost-description.vue';
import StepCostPrice from '@/components/create/step-cost/step-cost-price.vue';
import StepCostVin from '@/components/create/step-cost/step-cost-vin.vue';
import StepCostExchange from '@/components/create/step-cost/step-cost-exchange.vue';
import StepContactsHeader from '@/components/create/step-contacts/step-contacts-header.vue';
import { HashMap } from '@/types/common';
import StepMixin from '@/mixins/create/step';

const createStore = namespace('create');

@Component({
  components: {
    StepWrapper,
    StepTechHeader,
    StepTechHeaderMobile,
    StepExteriorHeader,
    StepCostHeader,
    StepCostState,
    StepCostOdometer,
    StepCostDescription,
    StepCostPrice,
    StepCostExchange,
    StepContactsHeader,
    StepCostVin,
  },
})
export default class StepCost extends Mixins<StepMixin>(StepMixin) {
  protected step: string = 'cost';

  @createStore.State data!: HashMap<any>;
  @createStore.Action setStep!: VuexAction;

  private get specs() {
    return this.data.specs || {};
  }

  protected validate() {
    const odometer =
      this.specs.state === 'new' ||
      (this.specs.odometer && this.specs.odometer.value);
    const price =
      this.data.price && this.data.price.amount && this.data.price.currency;

    if (this.specs.state && !odometer) {
      this.setError({
        'specs.odometer.value': ['Укажите пробег'],
      });
    }

    if (this.specs.state === 'new' && !this.specs.vin) {
      this.setError({
        'specs.vin': ['Укажите VIN'],
      });
    } else if (this.specs.vin && this.specs.vin.length < 17) {
      this.setError({
        'specs.vin': ['VIN должен содержать 17 символов'],
      });
    }

    if (!price) {
      this.setError({
        'price.amount': ['Укажите цену'],
      });
    }

    if (!this.specs.state) {
      this.setError({
        'specs.state': ['Укажите состояние'],
      });
    }

    return isEmpty(this.errors);
  }
}
