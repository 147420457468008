
  import { Mixins, Component } from 'vue-mixin-decorator';
  import { namespace } from 'vuex-class';
  import { first } from 'underscore';
  import * as $ from 'jquery';

  import urlService from '@/services/url-service';
  import { IAdvert } from '@/interfaces/adverts';
  import phone from '@/filters/format-phone';
  import plural from '@/filters/plural-form';
  import background from '@/filters/format-background';

  import AdvertContactsListItem from '@/components/advert/advert-contacts-list-item.vue';
  import AdvertContactsPopup from '@/components/advert/advert-contacts-popup.vue';
  import { IUser } from '@/interfaces/common';
  import IsMobileMixin from '@/mixins/common/is-mobile';
  import DeviceClass from '@/directives/device-class';
  import BannerWeb from '@/components/common/banner-web.vue';
  import { VuexAction } from '@/types/functions';

  const advertStore = namespace('advert');
  const userStore = namespace('user');

  @Component({
    components: {
      AdvertContactsListItem,
      AdvertContactsPopup,
      BannerWeb,
    },
    filters: {
      phone,
      plural,
      background,
    },
    directives: {
      DeviceClass,
    },
  })
  export default class AdvertContacts extends Mixins<IsMobileMixin>(
    IsMobileMixin,
  ) {
    private isPhoneVisible: boolean = false;
    private isAllPhonesVisible: boolean = true;
    public isFixed: boolean = false;
    public isHanged: boolean = false;
    public interval!: NodeJS.Timeout;

    public $refs!: {
      container: HTMLFormElement;
      contacts: HTMLFormElement;
      contactsPopup: HTMLFormElement;
    };

    @advertStore.State advert!: IAdvert;
    @advertStore.Action updatePhones!: VuexAction;
    @userStore.State currentUser!: IUser;

    private mounted() {
      !this.isMobileSpecial && this.seller.phones && this.initStick();
    }

    private beforeDestroy() {
      clearInterval(this.interval);

      document.removeEventListener('scroll', this.updatePosition);
    }

    private initStick() {
      document.addEventListener('scroll', this.updatePosition);

      this.interval = setInterval(() => {
        this.updatePosition();
      }, 500);
    }

    public get seller() {
      return this.advert.seller;
    }

    public get author() {
      return this.advert.author;
    }

    public get isOwnAdvert() {
      return this.currentUser && this.author.id === this.currentUser.id;
    }

    public get avatar() {
      return urlService.secureProjectUrl(
        'content',
        `user/avatar/60x60/${this.author.id}`,
      );
    }

    public get ipData() {
      return this.advert.ipData;
    }

    public get mainPhone() {
      const mainPhone = first(this.seller.phones);

      return this.isPhoneVisible || this.isOwnAdvert
        ? mainPhone
        : mainPhone!.substr(0, 6);
    }

    public get otherPhones() {
      return this.seller.phones.slice(1);
    }

    public get chatLink() {
      return urlService.secureProjectUrl(
        'chats',
        `compose/${this.author.id}/ab/${this.advert.id}`,
      );
    }

    public get profileLink() {
      return urlService.secureProjectUrl('profile', `user/${this.author.id}`);
    }

    public onShowNumberClick() {
      this.updatePhones({
        id: this.advert.id,
        success: () => {
          this.isPhoneVisible = true;

          this.$refs.contactsPopup.openPopup();
        },
      });
    }

    public onToggleAllClick() {
      this.isAllPhonesVisible = !this.isAllPhonesVisible;
    }

    private updatePosition() {
      const $wrapper = $('.js-wrapper');
      const $container = $(this.$refs.container);
      const wrapperBottom = $wrapper.offset()!.top + $wrapper.outerHeight()!;
      const containerTop = $container.offset()!.top;
      const threshhold = 50;

      const paddingTop = parseInt($container.css('padding-top'));
      const marginTop = parseInt($container.css('margin-top'));
      const wrapperTop = containerTop + paddingTop + marginTop;

      const $contacts = $(this.$refs.contacts);
      const $window: JQuery<Window> = $(window);
      const scrollTop = $window.scrollTop();
      const contactsHeight = $contacts.outerHeight();
      const hasEnoughSpace =
        wrapperBottom - wrapperTop > contactsHeight! + threshhold;

      if (!hasEnoughSpace) {
        this.isFixed = false;
        this.isHanged = false;
      } else {
        this.isFixed = scrollTop! > containerTop;
        this.isHanged = scrollTop! + contactsHeight! >= wrapperBottom;
      }
    }
  }
