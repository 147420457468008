var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "gtm",
          rawName: "v-gtm",
          value: { event: "show_ad_details" },
          expression: "{event: 'show_ad_details'}",
        },
      ],
      staticClass: "vehicle-form__accordion-item",
      class: {
        "vehicle-form__accordion-item_opened": _vm.isOpened,
        [`vehicle-form__accordion-item_${_vm.section.id}`]: true,
      },
      on: {
        click: function ($event) {
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c("div", { staticClass: "vehicle-form__accordion-header" }, [
        _c("div", { staticClass: "vehicle-form__accordion-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__accordion-part vehicle-form__accordion-part_1",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold",
                },
                [_vm._v(_vm._s(_vm.section.name))]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__accordion-part vehicle-form__accordion-part_2",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("plural")(_vm.sectionItems.length, [
                        "опция",
                        "опции",
                        "опций",
                      ])
                    )
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "vehicle-form__accordion-body" }, [
        _c(
          "div",
          { staticClass: "vehicle-form__accordion-columns" },
          _vm._l(_vm.sectionItems, function (option, index) {
            return _c(
              "div",
              {
                key: `${_vm.section.id}_${index}`,
                staticClass:
                  "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_condensed-other",
              },
              [_vm._v(_vm._s(option))]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }