var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__step-list" },
    [
      _c(
        "step-wrapper",
        {
          attrs: {
            step: "tech",
            "statuses-by-step": _vm.statusesByStep,
            errors: _vm.errors,
          },
        },
        [
          _c(_vm.componentStepTechHeader, {
            tag: "component",
            attrs: {
              mode: _vm.mode,
              "statuses-by-step": _vm.statusesByStep,
              "manufacturer-id": _vm.data.manufacturerId,
              "manufacturer-name": _vm.manufacturerName,
              "model-id": _vm.data.modelId,
              "model-name": _vm.modelName,
              year: _vm.data.specs.year,
              "generation-name": _vm.generationName,
              "body-type": _vm.bodyType,
              "engine-type": _vm.engineType,
              "engine-capacity": _vm.data.specs.engine.capacity,
              drivetrain: _vm.drivetrain,
              transmission: _vm.transmission,
            },
            on: { "change-step": _vm.onStepChange },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "step-wrapper",
        {
          attrs: {
            step: "description",
            "statuses-by-step": _vm.statusesByStep,
            errors: _vm.errors,
          },
        },
        [
          _c("step-description-header", {
            attrs: {
              mode: _vm.mode,
              "statuses-by-step": _vm.statusesByStep,
              images: _vm.data.images,
              tenure: _vm.tenure,
              odometer: _vm.data.specs.odometer,
              videos: _vm.data.videos,
              summary: _vm.data.summary,
              "rating-average": _vm.data.avgGrade || _vm.data.ratingAverage,
              pros: _vm.data.secondaryMarks && _vm.data.secondaryMarks.pros,
              cons: _vm.data.secondaryMarks && _vm.data.secondaryMarks.cons,
            },
            on: { "change-step": _vm.onStepChange },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__control vehicle-form__control_condensed-supererogatory",
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "button-style button-style_primary button-style_middle vehicle-form__button vehicle-form__button_width_xs",
                  class: { "button-style_animated": _vm.isLoading },
                  on: { click: _vm.onUpdateClick },
                },
                [_vm._v("\n        Сохранить изменения\n      ")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_info",
            },
            [
              _vm._v("\n      Размещая отзыв, вы соглашаетесь с\n      "),
              _c(
                "a",
                {
                  staticClass:
                    "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base",
                  attrs: { href: _vm.ruleLink, target: "_blank" },
                },
                [_vm._v("\n        правилами")]
              ),
              _vm._v(
                ". Перед публикацией все отзывы проверяются модератором\n    "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }