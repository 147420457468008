
import { Vue, Component } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { findWhere, uniq } from 'underscore';

import popular from '@/config/popular';
import { VuexAction } from '@/types/functions';
import {
  Dictionaries,
  Dictionary,
  DictionaryItem,
} from '@/services/dictionaries-service';
import DropdownMixin from '@/mixins/common/dropdown';
import { Entities } from '@/types/promote';
import { HashMap } from '@/types/common';
import dictionariesService from '@/services/dictionaries-service';
import DeviceClass from '@/directives/device-class';

const commonStore = namespace('common');
const promoteStore = namespace('promote');

@Component({
  directives: {
    DeviceClass,
  },
})
export default class PromoteAdvertsModel extends Mixins<DropdownMixin>(
  DropdownMixin
) {
  private optionsFilter: string = '';

  @commonStore.State dictionaries!: Dictionaries;
  @commonStore.Action setDictionary!: VuexAction;
  @promoteStore.State entities!: Entities;
  @promoteStore.State modelId!: string;
  @promoteStore.State manufacturerId!: string;
  @promoteStore.Action setModelId!: VuexAction;

  private get filterString() {
    return this.optionsFilter
      .trim()
      .toLowerCase()
      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  private get model() {
    return this.modelId || NaN;
  }

  private get entityModels() {
    return uniq(this.entities.map(item => item.data.model.id));
  }

  private set model(id) {
    this.setModelId({ id });
  }

  private onClick(id: string) {
    this.setModelId({ id });
    this.onChange();
  }

  private get urlData() {
    return {
      manufacturer: parseInt(this.manufacturerId),
    };
  }

  private get url() {
    return dictionariesService.buildUrl('manufacturer', this.urlData);
  }

  private get models() {
    const dictionary: Dictionary = this.dictionaries[this.url];

    if (!dictionary) {
      return [];
    }

    const entityDictionary = dictionary.filter(item => {
      return this.entityModels.indexOf(item.id) !== -1;
    });

    if (!this.filterString) {
      return entityDictionary;
    }

    const regex = new RegExp(this.filterString);

    return entityDictionary.filter((item: DictionaryItem) => {
      return regex.test(item.name.toLowerCase());
    });
  }

  private get selectedTitle() {
    const selected: any = findWhere(this.models, {
      id: this.model,
    });

    return selected ? selected.name : 'Модель';
  }

  private onChange() {
    this.closeDropdown();

    const handler = () => {
      (this.$refs.dropdown as HTMLElement).removeEventListener(
        'transitionend',
        handler
      );

      this.optionsFilter = '';
    };

    (this.$refs.dropdown as HTMLElement).addEventListener(
      'transitionend',
      handler
    );
  }
}
