
import { Component, Mixins } from 'vue-mixin-decorator';
import { Prop } from 'vue-property-decorator';
import ChainedSelectFilterMixin from '@/mixins/filters/chained-select-filter';
import ChainedSelectFilterChunk from './chained-select-filter-chunk.vue';
import ChainedSelectFilterChunkMulti from './chained-select-filter-chunk-multi.vue';

@Component({
  components: {
    ChainedSelectFilterChunk,
    ChainedSelectFilterChunkMulti,
  },
})
export default class ChainedSelectFilter extends Mixins<
  ChainedSelectFilterMixin
>(ChainedSelectFilterMixin) {
  @Prop() canDelete!: boolean;

  private onChunkChange({ index, value }: { index: number; value: string }) {
    this.updateFilter(index, value);
  }
}
