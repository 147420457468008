
  import { Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { findWhere } from 'underscore';
  import { Mixins, Component } from 'vue-mixin-decorator';

  import userService from '@/services/user-service';
  import {
    paygateApi,
    PaymentMethod,
    PaymentMethods,
    IPaymentMethodsResponse,
  } from '@onlinerby/js-api-clients';
  import {
    PaymentsList,
    EncryptedCardData,
    Secure3d,
  } from '@onlinerby/js-vue-components';

  import PromoteHeader from '@/components/promote/promote-header.vue';
  import PromotePackages from '@/components/promote/promote-packages.vue';
  import PromoteAdverts from '@/components/promote/promote-adverts.vue';
  import PromoteControls from '@/components/promote/promote-controls.vue';
  import { IUser } from '@/interfaces/common';
  import { VuexAction } from '@/types/functions';
  import { Entities, Entity, Features, SelectedFeature } from '@/types/promote';

  import GtmMixin from '@/mixins/common/gtm';
  import PaymentsMixin from '@/mixins/promote/payments';
  import { socialIcon } from '@/config/urls';

  interface IMixins extends GtmMixin, PaymentsMixin {}

  const userStore = namespace('user');
  const promoteStore = namespace('promote');

  @Component({
    components: {
      PromoteHeader,
      PromotePackages,
      PromoteAdverts,
      PromoteControls,
      PaymentsList,
      Secure3d,
    },
    metaInfo(): any {
      return {
        title: 'Выберите вариант премиального размещения в Автобарахолке',
        meta: [
          {
            name: 'og:image',
            content: socialIcon,
          },
        ],
      };
    },
  })
  export default class PromotePage extends Mixins<IMixins>(
    GtmMixin,
    PaymentsMixin,
  ) {
    private isLoading: boolean = true;
    private publicKey: string = (window as any).begatewayKey;

    @userStore.State currentUser!: IUser;
    @promoteStore.State entities!: Entities;
    @promoteStore.State features!: Features;
    @promoteStore.State paymentMethods!: PaymentMethods;
    @promoteStore.State selectedFeature!: SelectedFeature;
    @promoteStore.State selectedPaymentMethod!: PaymentMethod;
    @promoteStore.Action updateFeatures!: VuexAction;
    @promoteStore.Action updatePayments!: VuexAction;
    @promoteStore.Action updateGeneralCounters!: VuexAction;
    @promoteStore.Action setSelectedFeature!: VuexAction;
    @promoteStore.Action setSelectedPayment!: VuexAction;

    private created() {
      this.updateGeneralCounters();
      this.updateFeatures({
        success: ({ entities }: { entities: Entities }) => {
          this.initDefaultFeature();
          this.initDefaultEntity();

          const nonPremiumEntities = entities.filter(
            (item: Entity) => !item.activeFeatures.length,
          );

          if (nonPremiumEntities.length === 1) {
            const entity = nonPremiumEntities[0];

            this.setSelectedFeature({
              feature: {
                entity: {
                  id: entity.id,
                  type: entity.type,
                },
              },
            });
          }
        },
        complete: () => {
          this.isLoading = false;
        },
      });

      this.updateGtm();

      userService.isSessionSet && this.updatePayments();
    }

    private get isGameEnabled() {
      return (window as any).isGameEnabled;
    }

    private get selectedMethod() {
      return this.selectedPaymentMethod;
    }

    private set selectedMethod(method) {
      this.setSelectedPayment({ method });
    }

    private initDefaultFeature() {
      const defaultFeature = findWhere(this.features, { name: 'premium' });

      defaultFeature &&
        this.setSelectedFeature({
          feature: {
            id: defaultFeature.id,
          },
        });
    }

    private initDefaultEntity() {
      if (!this.$route.query.entity) {
        return;
      }

      const entity = findWhere(this.entities, {
        id: this.$route.query.entity.toString(),
      });

      entity &&
        !entity.activeFeatures.length &&
        this.setSelectedFeature({
          feature: {
            ...this.selectedFeature,
            entity: {
              id: entity.id,
              type: entity.type,
            },
          },
        });
    }

    private updateGtm() {
      this.setPageData({
        user_id: this.currentUser ? this.currentUser.id.toString() : '',
      });

      this.$gtm.trackEvent({
        event: 'content-view',
        'content-view-name': 'ad_promotion',
      });
    }

    private onLoginClick() {
      const profileAuth = (window as any).profileAuth as {
        showLoginModal: () => {};
      };

      profileAuth.showLoginModal();
    }
  }
