var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.isLoading
    ? _c("div", { staticClass: "vehicle-steps" }, [
        _c(
          "div",
          { staticClass: "vehicle-form" },
          [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_extended-fringe helpers_hide_tablet",
              },
              [_vm._v("\n      Разместить отзыв\n    ")]
            ),
            _vm._v(" "),
            _vm.isTechStep && _vm.mostRecentAdvert
              ? _c("step-tech-data-ab", {
                  attrs: {
                    image: _vm.mostRecentAdvert.images[0]["380x240"],
                    manufacturer: _vm.mostRecentAdvert.manufacturer.name,
                    model: _vm.mostRecentAdvert.model.name,
                    generation:
                      _vm.mostRecentAdvert.generation &&
                      _vm.mostRecentAdvert.generation.name,
                    year: _vm.mostRecentAdvert.specs.year,
                    capacity: _vm.mostRecentAdvert.specs.engine.capacity,
                  },
                  on: { submit: _vm.onSubmitMostRecentAdvert },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_extended-fringe helpers_show_tablet",
              },
              [_vm._v("\n      Разместить отзыв\n    ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vehicle-form__step" },
              [_c("router-view", { ref: "step" })],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }