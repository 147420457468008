
import { Vue } from 'vue-property-decorator';
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { IAdvert } from '@/interfaces/adverts';
import AdvertActionBookmark from '@/components/advert/advert-action-bookmark.vue';
import AdvertActionComplain from '@/components/advert/advert-action-complain.vue';
import AdvertActionShare from '@/components/advert/advert-action-share.vue';
import IsMobileMixin from '@/mixins/common/is-mobile';
import { IUser } from '@/interfaces/common';

const advertStore = namespace('advert');
const userStore = namespace('user');

@Component({
  components: {
    AdvertActionBookmark,
    AdvertActionComplain,
    AdvertActionShare,
  },
})
export default class AdvertAction extends Mixins<IsMobileMixin>(IsMobileMixin) {
  @advertStore.State advert!: IAdvert;
  @userStore.State currentUser!: IUser;

  private get isBookmarksVisible() {
    return !this.advert.closedAt || this.advert.inBookmarks;
  }

  private get isOwnAdvert() {
    return this.currentUser && this.advert.author.id === this.currentUser.id;
  }
}
