
  import { Component, Mixins } from 'vue-mixin-decorator';
  import { Prop } from 'vue-property-decorator';

  import RangeFilterMixin from '@/mixins/filters/range-filter';

  @Component
  export default class RangeFilter extends Mixins<RangeFilterMixin>(
    RangeFilterMixin,
  ) {
    @Prop({
      default: () => {
        return {};
      },
    })
    rangePlaceholders!: {
      from: string;
      to: string;
    };
  }
