
  import { Mixins, Component } from 'vue-mixin-decorator';
  import { namespace } from 'vuex-class';

  import FieldMixin from '@/mixins/create/field';
  import { range } from '@/services/utils-service';
  import { Dictionary } from '@/services/dictionaries-service';
  import { SINCE_YEAR } from '@/config/common';

  const commonStore = namespace('common');

  @Component
  export default class StepTechYear extends Mixins<FieldMixin>(FieldMixin) {
    private get specs() {
      return this.data.specs || {};
    }

    private get year() {
      return this.specs.year;
    }

    private set year(year) {
      this.clearError({ name: 'specs.year' });
      this.setField({ specs: { ...this.specs, year } });
    }

    private get yearsDictionary(): Dictionary {
      const yearsRange = range(
        SINCE_YEAR,
        new Date().getFullYear() + 1,
      ).reverse();

      return yearsRange.map(item => {
        return {
          id: item!.toString(),
          name: item!.toString(),
        };
      });
    }

    private get error() {
      return (this.errors['specs.year'] || []).join('. ');
    }
  }
