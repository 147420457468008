var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "results",
      staticClass: "vehicle-interaction js-results",
      class: {
        "vehicle-interaction_fixed": _vm.isFixed,
        "vehicle-interaction_hanged": _vm.isHanged,
        "vehicle-interaction_specific": !_vm.isSaveEnabled,
      },
    },
    [
      _c("div", { staticClass: "vehicle-interaction__inner-container" }, [
        _c(
          "div",
          {
            staticClass: "vehicle-interaction__inner",
            class: { "vehicle-interaction__inner_moved": _vm.isClearVisible },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-interaction__state vehicle-interaction__state_initial vehicle-interaction__state_disabled vehicle-interaction__state_control /*vehicle-interaction__state_disabled_alter*/",
                class: { "vehicle-interaction__state_animated": _vm.isLoading },
              },
              [
                _vm.anyFilters
                  ? _c("span", {
                      staticClass:
                        "vehicle-interaction__sub vehicle-interaction__sub_control",
                      on: { click: _vm.showClear },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.total
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "vehicle-interaction__sub vehicle-interaction__sub_main",
                        class: {
                          "vehicle-interaction__sub_main-alter":
                            !_vm.anyFilters,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("plural")(
                              _vm.total,
                              _vm.resultsConfig.found,
                              false
                            )
                          ) +
                            "\n          " +
                            _vm._s(_vm._f("number")(_vm.total)) +
                            "\n          " +
                            _vm._s(
                              _vm._f("plural")(
                                _vm.total,
                                _vm.resultsConfig.items,
                                false
                              )
                            )
                        ),
                      ]
                    )
                  : _c(
                      "span",
                      {
                        staticClass:
                          "vehicle-interaction__sub vehicle-interaction__sub_main",
                        class: {
                          "vehicle-interaction__sub_main-alter":
                            !_vm.anyFilters,
                        },
                      },
                      [_vm._v("Ничего не найдено")]
                    ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "vehicle-interaction__state vehicle-interaction__state_clear vehicle-interaction__state_hidden",
                on: { click: _vm.onClearClick },
              },
              [
                _c("span", { staticClass: "vehicle-interaction__sub" }, [
                  _vm._v("Сбросить фильтр"),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.isSaveEnabled
          ? _c("div", { staticClass: "vehicle-interaction__inner" }, [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-interaction__state vehicle-interaction__state_initial vehicle-interaction__state_save",
                  class: {
                    "vehicle-interaction__state_disabled": _vm.isSearchDisabled,
                  },
                  attrs: {
                    title: _vm.isSearchDisabled
                      ? "Максимум 20 сохраненных поисков"
                      : "",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "vehicle-interaction__sub vehicle-interaction__sub_main",
                      on: { click: _vm.save },
                    },
                    [_vm._v("Сохранить поиск")]
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }