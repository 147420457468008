export default (value: string | number, round?: boolean) => {
  if (!value && value !== 0) {
    return '';
  }

  const floatValue = round
    ? Math.round(parseFloat(value.toString()))
    : parseFloat(value.toString().replace(',', '.'));

  if (isNaN(floatValue)) {
    return NaN;
  }

  const [decimal, float] = floatValue
    .toString()
    .replace('.', ',')
    .split(',');

  const newDecimal =
    parseInt(decimal) > 9999
      ? decimal.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
      : decimal;

  return float ? [newDecimal, float].join(',') : newDecimal;
};
