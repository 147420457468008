
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import FieldMixin from '@/mixins/create/field';

const commonStore = namespace('common');

@Component
export default class StepTechModification extends Mixins<FieldMixin>(
  FieldMixin
) {
  private get specs() {
    return this.data.specs || {};
  }

  private get modification() {
    return this.specs.modification;
  }

  private set modification(modification) {
    this.setField({ specs: { ...this.specs, modification } });
    this.clearError({ name: 'specs.modification' });
  }

  private get error() {
    return (this.errors['specs.modification'] || []).join('. ');
  }
}
