
  import { Prop } from 'vue-property-decorator';
  import { Mixins, Component } from 'vue-mixin-decorator';
  import { findWhere } from 'underscore';

  import FieldMixin from '@/mixins/reviews/create/field';
  import { COUNT_MAIN_BODY_TYPES } from '@/config/reviews/create';

  @Component
  export default class StepTechBodyType extends Mixins<FieldMixin>(FieldMixin) {
    @Prop() bodyTypes!: Array<any>;

    private isAllVisible: boolean = false;

    created() {
      if (this.bodyType && !findWhere(this.types, { id: this.bodyType })) {
        this.isAllVisible = true;
      }
    }

    private get types() {
      return this.isAllVisible
        ? this.bodyTypes
        : this.bodyTypes.slice(0, COUNT_MAIN_BODY_TYPES);
    }

    private get bodyType() {
      return this.data.specs.bodyType;
    }

    private set bodyType(bodyType) {
      this.onFieldChange(bodyType);
    }

    private onToggle() {
      this.isAllVisible = !this.isAllVisible;
    }
  }
