import { each, indexOf } from 'underscore';
import { HashMap } from '@/types/common';

import uploadApiService from '@/api/upload-api';

const maxFilesize = 20971520; // 20 MB

class ImageUploadService {
  private static instance: ImageUploadService;
  private canceledList: Array<string> = [];

  processUpload(
    file: File,
    {
      complete,
      success,
      error,
      previewReady,
    }: {
      complete?: Function;
      success?: Function;
      error?: Function;
      previewReady?: Function;
    },
    project: string,
  ) {
    const checkFormatReader = new FileReader();
    const reader = new FileReader();
    const blob = file.slice(0, 4);

    let formData = {};
    let imageFormat: string;

    if (!file) {
      return;
    }

    checkFormatReader.onload = (event: any) => {
      const uint = new Uint8Array(event.target.result);

      let bytes: Array<string> = [];

      each(uint, byte => {
        bytes.push(byte.toString(16));
      });

      imageFormat = ImageUploadService._getMimeType(
        bytes.join('').toUpperCase(),
      );

      reader.readAsDataURL(file);
    };

    reader.onload = () => {
      let errorText;
      const fileName = file.name;

      formData = ImageUploadService._getFormData(file, project);

      previewReady &&
        previewReady({
          content: reader.result,
          file,
        });

      if (file.size >= maxFilesize) {
        errorText = 'Превышен допустимый размер файла';

        error && error({ fileName, errorText });

        return;
      }

      if (imageFormat === 'not supported') {
        errorText =
          'Поле должно быть файлом одного из следующих типов: jpeg, png';

        error && error({ fileName, errorText });

        return;
      }

      uploadApiService.postImage(formData, {
        success: (data: any) => {
          this.runStatusProcessing(data.data, { complete, error, fileName });

          success && success();
        },
        error: (errorData: any) => {
          error &&
            error({
              fileName,
              errorText:
                errorData.response.data.errors &&
                errorData.response.data.errors[0],
              serverError: true,
            });
        },
      });
    };

    checkFormatReader.readAsArrayBuffer(blob);
  }

  runStatusProcessing(
    data: any,
    {
      complete,
      error,
      fileName,
    }: { complete?: Function; error?: Function; fileName: string },
  ) {
    uploadApiService.getImageStatus(data, {
      success: (data: any) => {
        if (indexOf(this.canceledList, fileName) !== -1) {
          this._removeFromCanceledList(fileName);

          return;
        }

        if (data.data.status === 'processed') {
          complete && complete(data.data);

          return;
        }

        if (data.data.status === 'error') {
          error &&
            error({
              fileName,
              data,
              serverError: true,
            });

          return;
        }

        setTimeout(() => {
          this.runStatusProcessing(data.data, { complete, error, fileName });
        }, 1000);
      },
      error: (errorData: any) => {
        error &&
          error({
            fileName,
            errorData,
            serverError: true,
          });
      },
    });
  }

  cancelUpload(message: { fileName: string }) {
    message && this.canceledList.push(message.fileName);
  }

  _removeFromCanceledList(fileName: string) {
    this.canceledList.splice(indexOf(this.canceledList, fileName), 1);
  }

  static _getFormData(file: File, project: string) {
    const formData = new FormData(),
      uploadData: HashMap<string> = {
        'meta[type]': project,
      };

    formData.append('file', file, file.name);

    for (let name in uploadData) {
      formData.append(name, uploadData[name]);
    }

    return formData;
  }

  static _getMimeType(data: string) {
    switch (data) {
      case '89504E47':
        return 'image/png';
      case '47494638':
        return 'image/gif';
      case 'FFD8FFDB':
      case 'FFD8FFE0':
      case 'FFD8FFEE':
        return 'image/jpeg';
      case 'FFD8FFE1':
        return 'image/jpg';
      default:
        return 'not supported';
    }
  }

  static getInstance() {
    if (!ImageUploadService.instance) {
      ImageUploadService.instance = new ImageUploadService();
    }

    return ImageUploadService.instance;
  }
}

export default ImageUploadService.getInstance();
