
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { findWhere } from 'underscore';

import { Dictionaries, DictionaryItem } from '@/services/dictionaries-service';
import FieldMixin from '@/mixins/create/field';
import DropdownMixin from '@/mixins/common/dropdown';
import StorageMixin from '@/mixins/create/storage';

const commonStore = namespace('common');

interface IMixins extends FieldMixin, DropdownMixin, StorageMixin {}

@Component
export default class StepSellerTypeMobile extends Mixins<IMixins>(
  FieldMixin,
  DropdownMixin,
  StorageMixin
) {
  @commonStore.State dictionaries!: Dictionaries;

  private get seller() {
    return this.data.seller || {};
  }

  private open() {
    this.openDropdown();

    document.body.classList.add('body_state-dropdown-style_visible');
  }

  private close() {
    this.closeDropdown();

    document.body.classList.remove('body_state-dropdown-style_visible');
  }

  private get sellerType() {
    return this.seller.type;
  }

  private set sellerType(value) {
    this.clearError({ name: 'seller.type' });
    this.clearError({ name: 'seller.name' });
    this.clearError({ name: 'seller.unp' });
    this.clearError({ name: 'seller.contactName' });

    if (value === 'individual') {
      const { contactName, unp, ...seller } = this.seller;

      this.clearError({ name: 'seller.unp' });

      this.setField({ seller: { ...seller, type: value } });
    } else {
      const unp = this.getStorageValue('unp');
      const contactName = this.getStorageValue('contactName');

      this.setField({
        seller: { ...this.seller, type: value, unp, contactName },
      });
    }
  }

  private get sellerTypeName() {
    const type = findWhere(this.dictionaries.sellerType, {
      id: this.sellerType,
    });

    if (!type) {
      return 'Выберите';
    }

    return type.name;
  }

  private get error() {
    return (this.errors['seller.type'] || []).join('. ');
  }

  private onChange() {
    this.close();
  }
}
