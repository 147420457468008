
  import { Prop } from 'vue-property-decorator';
  import { Component, Mixins } from 'vue-mixin-decorator';

  import FilterMixin from '@/mixins/filters/filter';
  import number from '@/filters/format-number';

  @Component({
    filters: {
      number,
    },
  })
  export default class CheckboxFilter extends Mixins<FilterMixin>(FilterMixin) {
    private get model() {
      return this.filter.value;
    }

    private set model(value) {
      this._updateFilter(value);
    }
  }
