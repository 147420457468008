var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("comments-list-branch", {
    key: `comment_${_vm.comment.id}`,
    ref: `comment_${_vm.comment.id}`,
    attrs: {
      comment: _vm.comment,
      config: _vm.config,
      best: _vm.best,
      "entity-author-id": _vm.entityAuthorId,
      "current-user": _vm.currentUser,
      settings: _vm.settings,
      "active-ban": _vm.activeBan,
      "is-marks-active": false,
      "is-replies-always-visible": true,
    },
    on: { reply: _vm.onReply, mark: _vm.onMarkToggle },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }