import { HashMap } from '@onlinerby/js-common';

const stepTech = {
  engineType: 'specs.engine.type',
  engineCapacity: 'specs.engine.capacity',
  enginePower: 'specs.engine.power.value',
  enginePowerUnit: 'specs.engine.power.unit',
  engineTorque: 'specs.engine.torque',
  manufacturer: 'manufacturerId',
  model: 'modelId',
  generation: 'generationId',
  year: 'specs.year',
  bodyType: 'specs.bodyType',
  drivetrain: 'specs.drivetrain',
  transmission: 'specs.transmission',
};

const stepDescription = {
  tenure: 'tenure',
  odometer: 'specs.odometer',
  odometerValue: 'specs.odometer.value',
  text: 'text',
  videosUrl: 'videos.0.url',
  summary: 'summary',
  images: 'images',
  rating: 'rating',
  secondaryMarks: 'secondaryMarks',
};

export default {
  tech: stepTech,
  description: stepDescription,
  edit: { ...stepTech, ...stepDescription },
} as HashMap<HashMap<string>>;
