var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "vehicle-form__title vehicle-form__title_giant" },
      [_vm._v("\n    Ускорить продажу\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_max-width_xxxxllll",
      },
      [
        _vm._v(
          "\n    Выделите свое объявление среди более чем " +
            _vm._s(_vm.counter) +
            " объявлений\n    о продаже авто. Используйте услуги платного продвижения, оплачивая\n    банковской картой\n  "
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__control vehicle-form__control_count" },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__control vehicle-form__control_payment helpers_hide_tablet",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "button-style button-style_specific button-style_small button-style_noreflex vehicle-form__button vehicle-form__button_payment",
              },
              [
                _vm._v("\n        Оплачивайте пакеты"),
                _c("br"),
                _vm._v("\n        банковской картой\n      "),
              ]
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }