
  import { Component } from 'vue-property-decorator';
  import { Mixins } from 'vue-mixin-decorator';
  import StepTechHeaderMixin from '@/mixins/reviews/create/step-tech-header';

  @Component
  export default class StepTechHeader extends Mixins<StepTechHeaderMixin>(
    StepTechHeaderMixin,
  ) {
    private onResetManufacturerClick() {
      this.$emit('reset-manufacturer');
    }

    private onResetModelClick() {
      this.$emit('reset-model');
    }
  }
