var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__step-item vehicle-form__step-item_other" },
    [
      [
        _c("div", {
          staticClass:
            "vehicle-form__divider vehicle-form__divider_extended-alter vehicle-form__divider_wide-alter",
        }),
        _vm._v(" "),
        _c("advert-preview"),
      ],
      _vm._v(" "),
      [
        _c("div", {
          staticClass:
            "vehicle-form__divider vehicle-form__divider_extended-alter vehicle-form__divider_wide-alter",
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__control vehicle-form__control_condensed-excess",
          },
          [
            _vm.mode === "create"
              ? _c(
                  "a",
                  {
                    staticClass:
                      "button-style button-style_primary button-style_huge vehicle-form__button vehicle-form__button_width_full",
                    class: { "button-style_animated": _vm.isProcessing },
                    on: { click: _vm.create },
                  },
                  [_vm._v("Разместить объявление")]
                )
              : _c(
                  "a",
                  {
                    staticClass:
                      "button-style button-style_primary button-style_huge vehicle-form__button vehicle-form__button_width_full",
                    class: { "button-style_animated": _vm.isProcessing },
                    on: { click: _vm.update },
                  },
                  [_vm._v("Сохранить")]
                ),
          ]
        ),
        _vm._v(" "),
        _vm._m(0),
      ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_small",
      },
      [
        _vm._v("\n      Размещая объявление, вы соглашаетесь с\n      "),
        _c(
          "a",
          {
            staticClass:
              "vehicle-form__link vehicle-form__link_primary vehicle-form__link_small",
            attrs: {
              href: "https://blog.onliner.by/avtobaraholka_rules",
              target: "_blank",
            },
          },
          [_vm._v("правилами Onlíner")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }