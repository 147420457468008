var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__offers-list" },
    [
      _vm.isLoading
        ? _vm._l(_vm.stubCount, function (index) {
            return _c("review-card-stub", { key: index })
          })
        : _vm.reviews.length
        ? _vm._l(_vm.reviews, function (item, index) {
            return _c("review-card", {
              key: item.id,
              attrs: {
                review: item,
                "is-tutorial-visible": _vm.isMobileSpecial && index === 0,
                "is-tutorial-watched": _vm.isTutorialWatched,
              },
              on: {
                "submit-tutorial": _vm.onSubmitTutorial,
                "tutorial-watched": _vm.onTutorialWatched,
              },
            })
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }