import { MutationTree } from 'vuex';

import { ISearchState } from '@/interfaces/reviews/store';
import { ICounters, IPage } from '@/interfaces/search';
import {
  findIndex,
  isArray,
  isEmpty,
  isNumber,
  isObject,
  without,
} from 'underscore';
import { HashMap } from '@/types/common';
import { IFilter } from '@/interfaces/filters';

const mutations: MutationTree<ISearchState> = {
  setLoading(state, value) {
    state.isLoading = value;
  },

  setTotal(state, total) {
    state.total = total;
  },

  setReviews(state, list) {
    state.reviews = list;
  },

  setPage(state, page: IPage) {
    state.reviewListPage = page;
  },

  setFilter(state, { name, value }) {
    const filterIndex = findIndex(state.filters, { name: name });

    if (filterIndex !== -1) {
      let newValue: any;

      if (isObject(value) && !isArray(value)) {
        newValue = {};

        for (let key of Object.keys(value)) {
          value[key] && (newValue[key] = value[key]);
        }
      } else if (isArray(value)) {
        newValue = value;
      }
      const shouldDelete = !value || (isObject(newValue) && isEmpty(newValue));

      shouldDelete
        ? state.filters.splice(filterIndex, 1)
        : state.filters.splice(filterIndex, 1, {
            name,
            value: newValue || value,
          });
    } else {
      value && state.filters.push({ name, value });
    }
  },

  setGroupFilter(state, { name, value, groupIndex }) {
    let filterIndex = findIndex(state.filters, { name: name });
    let filter: any;

    if (filterIndex !== -1) {
      filter = state.filters[filterIndex];
    } else {
      filter = {
        name,
        value: [],
      };

      state.filters.push(filter);
    }

    if (isEmpty(filter.value[groupIndex])) {
      filter.value[groupIndex] = {};
    }

    let newValue: any;

    if (isObject(value) && !isArray(value)) {
      newValue = {};

      for (let key of Object.keys(value)) {
        value[key] && (newValue[key] = value[key]);
      }
    }

    isEmpty(newValue)
      ? (filter.value as any).splice(groupIndex, 1)
      : (filter.value as any).splice(groupIndex, 1, newValue);

    let shouldDelete = true;

    filterIndex = findIndex(state.filters, { name: name });

    filter.value.forEach((item: HashMap<any>) => {
      for (let key of Object.keys(item)) {
        if (item[key]) {
          shouldDelete = false;
        }
      }
    });
    shouldDelete && state.filters.splice(filterIndex, 1);
  },

  removeFilter(state, { name, rootName, groupIndex, value, removeAll }) {
    const filterIndex = findIndex(state.filters, { name: rootName });

    if (filterIndex !== -1) {
      const filter = state.filters[filterIndex];

      if (isNumber(groupIndex) && !name) {
        (filter.value as Array<any>).splice(groupIndex, 1);

        return;
      }

      const filterValue = isNumber(groupIndex)
        ? (filter.value as Array<any>)[groupIndex]
        : filter.value;

      if (!filterValue) {
        return;
      }

      if (isArray(filterValue)) {
        const itemIndex = filterValue.indexOf(value);

        if (itemIndex !== -1) {
          filterValue.splice(itemIndex, 1);

          const newFilter: IFilter = {
            name: rootName,
            value: filterValue,
          };

          isEmpty(newFilter.value)
            ? state.filters.splice(filterIndex, 1)
            : state.filters.splice(filterIndex, 1, newFilter);
        }
      } else if (isObject(filterValue)) {
        const newValue: HashMap<any> = {};
        const valueObject = filterValue as HashMap<any>;

        for (let key of Object.keys(filterValue)) {
          if (key !== name && valueObject[key]) {
            newValue[key] = valueObject[key];
          } else if (isArray(valueObject[key]) && !removeAll) {
            const newArray = without(valueObject[key], value);

            newArray.length && (newValue[key] = newArray);
          }
        }

        if (isNumber(groupIndex)) {
          if (isEmpty(newValue)) {
            (filter.value as HashMap<any>).splice(groupIndex, 1);
          } else {
            (filter.value as HashMap<any>)[groupIndex] = newValue;
          }
        }

        const newFilter: IFilter = {
          name: rootName,
          value: isNumber(groupIndex) ? filter.value : newValue,
        };

        isEmpty(newFilter.value)
          ? state.filters.splice(filterIndex, 1)
          : state.filters.splice(filterIndex, 1, newFilter);
      } else {
        state.filters.splice(filterIndex, 1);
      }
    }
  },

  clearFilters(state) {
    state.filters = [];
  },

  resetPage(state) {
    state.reviewListPage = {
      current: 1,
      last: 1,
      limit: 50,
      items: 0,
    };
  },

  setCounters(state, counters: ICounters) {
    state.counters = counters;
  },

  setGeneralCounters(state, counters: ICounters) {
    state.generalCounters = counters;
  },
};

export default mutations;
