
import { Component, Mixins } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { findWhere, first } from 'underscore';

import { IAdvert } from '@/interfaces/adverts';
import { VuexAction } from '@/types/functions';
import Dropdown from '@/mixins/common/dropdown';
import shareService from '@/services/share-service';

import number from '@/filters/format-number';
import plural from '@/filters/plural-form';
import { Dictionaries } from '@/services/dictionaries-service';

const advertStore = namespace('advert');
const commonStore = namespace('common');

@Component
export default class AdvertActionBookmark extends Mixins<Dropdown>(Dropdown) {
  @advertStore.State advert!: IAdvert;
  @advertStore.Action addToBookmarks!: VuexAction;
  @advertStore.Action removeFromBookmarks!: VuexAction;
  @commonStore.State dictionaries!: Dictionaries;

  public $copyText!: Function;
  private isCopied: boolean = false;

  private get link() {
    return window.location.href;
  }

  private toggleBookmark() {
    const payload = { id: this.advert.id };
    const action = this.advert.inBookmarks
      ? this.removeFromBookmarks
      : this.addToBookmarks;

    action(payload);
  }

  private shareInFacebook() {
    this.closeDropdown();

    shareService.shareInFacebook({
      url: this.link,
      image: first(this.advert.images)!.original,
    });
  }

  private shareInVk() {
    this.closeDropdown();

    shareService.shareInVk({
      url: this.link,
      image: first(this.advert.images)!.original,
      title: this.advert.title,
    });
  }

  private shareInTwitter() {
    this.closeDropdown();

    shareService.shareInTwitter({
      url: this.link,
      title: this.advert.title,
    });
  }

  private shareInOk() {
    const { manufacturer, model, specs, location, images, price } = this.advert;
    const odometer = number(specs.odometer.value);
    const bodyType = findWhere(this.dictionaries.bodyType, {
      id: specs.bodyType,
    })!.name;
    const engineType = findWhere(this.dictionaries.engineType, {
      id: specs.engine.type,
    })!.name;
    const transmission = findWhere(this.dictionaries.transmission, {
      id: specs.transmission,
    })!.name;
    const amount = price.converted.BYN.amount;
    const formattedPrice = `${number(amount)} ${plural(
      parseInt(amount),
      ['рубль', 'рубля', 'рублей'],
      false
    )}.`;

    const title = `${manufacturer.name} ${model.name}, ${
      specs.state !== 'new' ? odometer + ' км' : 'Новый'
    }, ${specs.year} г.в., ${location.city.name}`;
    const description = `${bodyType}, ${engineType}${
      specs.engine.capacity ? ' ' + specs.engine.capacity + ' л' : ''
    }, ${transmission}, ${formattedPrice}`;

    this.closeDropdown();

    shareService.shareInOk({
      url: this.link,
      title,
      description,
      imageUrl: first(this.advert.images)!.original,
    });
  }

  private shareInTelegram() {
    this.closeDropdown();

    shareService.shareInTelegram({
      url: this.link,
      title: this.advert.title,
    });
  }

  private copyLink() {
    this.$copyText(this.link).then(() => {
      this.openDropdown();
      this.isCopied = true;
    });
  }
}
