
import { Component, Mixins } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';

import { NotificationsSettings } from '@/types/search';
import Dropdown from '@/mixins/common/dropdown';
import SavedSearchesItem from '@/components/search/saved-searches-item.vue';
import { VuexAction } from '@/types/functions';
import { IUser } from '@/interfaces/common';
import capitalize from '@/filters/format-capitalize';
import { findWhere } from 'underscore';

const searchStore = namespace('search');
const userStore = namespace('user');

@Component({
  components: {
    SavedSearchesItem,
  },
})
export default class SavedSearchesSettings extends Mixins<Dropdown>(Dropdown) {
  @searchStore.State notificationsSettings!: NotificationsSettings;
  @searchStore.Action saveNotificationsSettings!: VuexAction;
  @userStore.State currentUser!: IUser;

  private get period() {
    return this.notificationsSettings.values.period;
  }

  private set period(period) {
    const values = Object.assign(this.notificationsSettings.values, { period });
    const notificationsSettings = Object.assign(
      {},
      this.notificationsSettings,
      { values }
    );
    const userId = this.currentUser.id;

    this.saveNotificationsSettings({ userId, notificationsSettings });
  }

  private get channelsTitle() {
    const { periods, channels, values } = this.notificationsSettings;

    const selectedChannels = channels.filter(
      (item) => values.channels?.indexOf(item.value) !== -1
    );
    const period = findWhere(periods, { value: values.period })?.title;
    const title = selectedChannels
      .map((item) => item.title.toLowerCase())
      .join(', ');

    return title
      ? `${capitalize(title)} ${period?.toLowerCase()}`
      : 'Выключены';
  }

  private toggle() {
    this.toggleDropdown();

    this.$emit(this.isVisible ? 'open' : 'close');
  }

  private onChannelChange(value: String) {
    const channels = this.notificationsSettings.values.channels || [];
    const index = channels.indexOf(value);

    if (index !== -1) {
      channels?.splice(index, 1);
    } else {
      channels.push(value);
    }

    const values = Object.assign(this.notificationsSettings.values, {
      channels,
    });
    const notificationsSettings = Object.assign(
      {},
      this.notificationsSettings,
      { values }
    );
    const userId = this.currentUser.id;

    this.saveNotificationsSettings({ userId, notificationsSettings });
  }
}
