import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { IRootState } from '@/interfaces/store';
import search from './modules/search';
import advert from './modules/advert';
import adverts from './modules/adverts';
import common from './modules/common';
import create from './modules/create';
import user from './modules/user';
import promote from './modules/promote';
import createReviews from './modules/reviews/create';
import searchReviews from './modules/reviews/search';

Vue.use(Vuex);

const store: StoreOptions<IRootState> = {
  state: {},
  modules: {
    common,
    create,
    search,
    advert,
    adverts,
    user,
    promote,
    reviews: {
      namespaced: true,
      modules: {
        create: createReviews,
        search: searchReviews,
      },
    },
  },
};

export default new Vuex.Store<IRootState>(store);
