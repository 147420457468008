import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';
import { HashMap } from '@/types/common';

@Mixin
class Gtm extends Vue {
  private static pageData: HashMap<any>;

  protected setPageData(data: HashMap<any>) {
    this.clearPageData();

    if (data) {
      Gtm.pageData = data;
      this.$gtm.dataLayer().push(data);
    }
  }

  protected clearPageData() {
    const index = (this.$gtm.dataLayer() as Array<any>).indexOf(Gtm.pageData);

    if (index !== -1) {
      (this.$gtm.dataLayer() as Array<any>).splice(index, 1);
    }
  }
}

export default Gtm;
