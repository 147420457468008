import {Vue, Prop} from "vue-property-decorator";
import { Mixin } from 'vue-mixin-decorator';

import { HashMap } from '@onlinerby/js-common';

import capitalize from '@/filters/format-capitalize';
import { stepStatuses } from "@/config/reviews/create";

@Mixin({
  filters: {
    capitalize
  },
})
export default class StepHeader extends Vue {
  protected step!: string;

  @Prop() statusesByStep!: HashMap<any>;
  @Prop() mode!: string;

  protected get isComplete() {
    return this.statusesByStep[this.step] === stepStatuses.complete;
  }

  protected get isCurrent() {
    return this.statusesByStep[this.step] === stepStatuses.current;
  }

  protected get isEdit() {
    return this.statusesByStep[this.step] === stepStatuses.edit;
  }

  protected get isEditMode() {
    for (let key of Object.keys(this.statusesByStep)) {
      if (this.statusesByStep[key] === stepStatuses.edit) {
        return true;
      }
    }

    return false;
  }

  protected get isEditable() {
    return this.mode === 'create' && (this.isCurrent || this.isEdit);
  }


  protected editStep(step: string) {
    this.$emit('change-step', step);

    this.$router.replace({ name: `${this.mode}-review-${step}` });
  }
}
