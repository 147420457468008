
import { Vue } from 'vue-property-decorator';
import { Mixins, Component } from 'vue-mixin-decorator';
import Bookmark from '@/mixins/common/bookmark';
import { namespace } from 'vuex-class';
import { VuexAction } from '@/types/functions';

const advertStore = namespace('advert');

@Component
export default class AdvertActionBookmark extends Mixins<Bookmark>(Bookmark) {
  @advertStore.Action addToBookmarks!: VuexAction;
  @advertStore.Action removeFromBookmarks!: VuexAction;
}
