import { Mixin, Mixins } from 'vue-mixin-decorator';

const Swiper = require('swiper/js/swiper.js');
import SwiperDirective from '@/directives/swiper';

import IsMobileMixin from '@/mixins/common/is-mobile';

@Mixin({
  directives: {
    Swiper: SwiperDirective,
  },
})
export default class SwiperMixin extends Mixins<IsMobileMixin>(IsMobileMixin) {
  private sliderSwiper!: any;

  protected initSwiper() {
    const $slider = $(this.$refs.slider);
    const $sliderSwipper = $slider.find('.js-slider-swipper');
    const $sliderSwipperPrev = $slider.find('.js-slider-swipper-prev');
    const $sliderSwipperNext = $slider.find('.js-slider-swipper-next');

    if (!$sliderSwipper.length || this.isMobileSpecial) {
      return;
    }

    this.sliderSwiper = new Swiper($sliderSwipper, {
      slidesPerView: 'auto',
      navigation: {
        nextEl: $sliderSwipperNext,
        prevEl: $sliderSwipperPrev,
      },
    });
  }

  private beforeDestroy() {
    this.sliderSwiper && this.sliderSwiper.destroy();
  }
}
