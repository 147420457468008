import { Component, Mixins } from 'vue-mixin-decorator';
import { Prop } from 'vue-property-decorator';
import { findIndex, findLastIndex, some } from 'underscore';

import FilterMixin from '@/mixins/filters/filter';
import { HashMap } from '@/types/common';
import { InputEvent } from '@/types/events';
import { Dictionary } from '@/services/dictionaries-service';
import camelize from '@/filters/format-camelize';

@Component
export default class RangeFilterMixin extends Mixins<FilterMixin>(FilterMixin) {
  @Prop() options!: Dictionary;
  @Prop() maxlength!: number;

  protected filterType = 'object';

  created() {
    this.initEvents();
  }

  protected get optionsDictionary() {
    return this.options || this.dictionaries[camelize(this.name)];
  }

  private initEvents() {
    this.$root.$on('tag-clear', (filterName: string) => {
      if (this.filter.name === filterName) {
        this.selectedPreferred = {};
      }
    });

    this.$root.$on('filters-clear', (filterName: string) => {
      this.selectedPreferred = {};
    });
  }

  protected updateFilter() {
    const value: HashMap<string> = this.filter.value as HashMap<string>;

    this._updateFilter({
      from: value.from,
      to: value.to,
    });
  }

  protected onInput(property: string, event: InputEvent) {
    const value = this.filter.value as HashMap<string>;

    event.target.value = event.target.value.replace(/\D/g, '');
    value[property] = value[property].replace(/\D/g, '');

    this.selectedPreferred = {};
  }

  protected onPreferredSelect(selectedKey: string) {
    const isAnySelected = some(this.selectedPreferred, item => item);

    if (!isAnySelected) {
      this.filter.value = { from: '', to: '' };
      this.updateFilter();

      return;
    }

    const selectedValue = this.selectedPreferred[selectedKey];

    const preferredOptions = this.preferredOptions!;
    const selectedIndex = findIndex(preferredOptions, {
      key: selectedKey,
    });
    let firstSelectedIndex, lastSelectedIndex;

    if (selectedValue) {
      firstSelectedIndex = findIndex(
        preferredOptions,
        item => this.selectedPreferred[item.key],
      );
      lastSelectedIndex = findLastIndex(
        preferredOptions,
        item => this.selectedPreferred[item.key],
      );

      this.fillRange(firstSelectedIndex, lastSelectedIndex);
    } else {
      firstSelectedIndex = findIndex(
        preferredOptions,
        item => this.selectedPreferred[item.key],
      );
      lastSelectedIndex = findLastIndex(
        preferredOptions,
        item => this.selectedPreferred[item.key],
      );

      const isCloserToFirst =
        selectedIndex - firstSelectedIndex < lastSelectedIndex - selectedIndex;

      firstSelectedIndex = isCloserToFirst
        ? selectedIndex + 1
        : findIndex(preferredOptions, item => this.selectedPreferred[item.key]);
      lastSelectedIndex = isCloserToFirst
        ? findLastIndex(
            preferredOptions,
            item => this.selectedPreferred[item.key],
          )
        : selectedIndex - 1;

      this.selectedPreferred = {};
      this.fillRange(firstSelectedIndex, lastSelectedIndex);
    }

    const newValue = {
      from: preferredOptions[firstSelectedIndex].value.from || '',
      to: preferredOptions[lastSelectedIndex].value.to || '',
    };

    this.filter.value = newValue;
    this.updateFilter();
  }

  private fillRange(from: number, to: number) {
    const preferredOptions = this.preferredOptions!;

    for (let i = from; i <= to; i++) {
      this.selectedPreferred[preferredOptions[i].key] = true;
    }
  }
}
