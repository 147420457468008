
  import { Vue, Component } from 'vue-property-decorator';
  import { Mixins } from 'vue-mixin-decorator';
  import { namespace } from 'vuex-class';
  import { isArray } from 'underscore';

  import { HashMap } from '@/types/common';
  import background from '@/filters/format-background';
  import capitalize from '@/filters/format-capitalize';
  import plural from '@/filters/plural-form';
  import { DictionaryItem } from '@/services/dictionaries-service';
  import { VuexAction } from '@/types/functions';
  import StepHeader from '@/mixins/create/step-header';
  import { IPhoto } from '@/interfaces/common';

  const createStore = namespace('create');
  const commonStore = namespace('common');

  @Component({
    filters: {
      background,
      capitalize,
      plural,
    },
  })
  export default class StepExteriorHeader extends Mixins<StepHeader>(
    StepHeader,
  ) {
    protected step: string = 'exterior';

    @createStore.State data!: HashMap<any>;

    @commonStore.Getter getDictionaryItem!: (
      type: string,
      key: string,
    ) => DictionaryItem;

    private get images() {
      if (!this.data.images) {
        return [];
      }

      return this.data.images
        .map((images: IPhoto) => {
          return images;
        })
        .filter((item: IPhoto) => item);
    }

    private get color() {
      return this.getDictionaryItem('color', this.data.specs.color).name;
    }

    private get optionsCount() {
      const equipment = this.data.equipment;

      if (!equipment) {
        return 0;
      }

      let count = 0;

      for (let key of Object.keys(equipment)) {
        if (equipment[key]) {
          count += isArray(equipment[key])
            ? (equipment[key] as Array<string>).length
            : 1;
        }
      }

      return count;
    }

    private getImageUrl(images) {
      return (
        images['380x240'] || // For data from AB with old upload.api
        images.original // fallback
      );
    }
  }
