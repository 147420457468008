
  import { Component, Mixins } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';

  import IsMobileMixin from '@/mixins/common/is-mobile';
  import { Review } from '@/types/review';
  import ReviewCard from '@/components/reviews/common/review-card.vue';
  import ReviewCardStub from '@/components/reviews/common/review-card-stub.vue';

  const searchStore = namespace('reviews/search');

  @Component({
    components: {
      ReviewCardStub,
      ReviewCard,
    },
  })
  export default class ReviewList extends Mixins<IsMobileMixin>(IsMobileMixin) {
    private keyLocalStorage: string = 'ab_review_list_tutorial';
    private isTutorialWatched: boolean = false;
    private stubCount = 0;

    @searchStore.State isLoading!: boolean;
    @searchStore.State reviews!: Array<Review>;

    private created() {
      this.isTutorialWatched = !!window.localStorage.getItem(
        this.keyLocalStorage,
      );

      this.stubCount = this.isMobileSpecial ? 30 : 60;
    }

    private onTutorialWatched() {
      setTimeout(() => {
        this.isTutorialWatched = true;
      }, 0);
    }

    private onSubmitTutorial() {
      this.onTutorialWatched();
      window.localStorage.setItem(this.keyLocalStorage, true);
    }
  }
