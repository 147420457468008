import Vue from 'vue';
import * as $ from 'jquery';
import keys from '@/config/keys';
import { Mixin } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { IDropdown } from '@/interfaces/common';
import { getRandomId } from '@/services/random-service';
import { VuexAction } from '@/types/functions';

const commonStore = namespace('common');

@Mixin
class Dropdown extends Vue {
  private id: string = `dropdown_${getRandomId()}`;
  protected onToggle!: (isVisible: boolean) => void;

  @commonStore.Getter getDropdown!: (id: string) => IDropdown;
  @commonStore.Action setDropdown!: VuexAction;
  @commonStore.Action removeDropdown!: VuexAction;

  public created() {
    this.setDropdown({
      id: this.id,
      isVisible: false,
    });

    this.initEvents();
  }

  private beforeDestroy() {
    this.removeDropdown({
      id: this.id,
    });

    $(document).off('click', this.onDocumentClick);
    $(document).off('keydown', this.onKeyDown);
  }

  private initEvents() {
    $(document).on('click', this.onDocumentClick);
    $(document).on('keydown', this.onKeyDown);
  }

  private onDocumentClick(event: JQuery.MouseEventBase) {
    if (!this.isVisible) {
      return;
    }

    const $handle = $(this.$refs.handle);

    if ($handle.find($(event.target)).length) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    this.closeDropdown();
  }

  private onKeyDown(event: JQuery.Event) {
    if (!this.isVisible) {
      return;
    }

    if (event.keyCode === keys.esc && this.isVisible) {
      this.closeDropdown();

      event.preventDefault();

      return;
    }
  }

  private get dropdown() {
    return this.getDropdown(this.id);
  }

  protected get isVisible() {
    return this.dropdown.isVisible;
  }

  protected openDropdown() {
    this.setDropdown({
      id: this.id,
      isVisible: true,
    });
  }

  protected closeDropdown() {
    this.setDropdown({
      id: this.id,
      isVisible: false,
    });
  }

  protected toggleDropdown() {
    this.setDropdown({
      id: this.id,
      isVisible: !this.dropdown.isVisible,
    });

    this.$nextTick(() => {
      this.onToggle && this.onToggle(this.dropdown.isVisible);
    });
    return this.dropdown.isVisible;
  }
}

export default Dropdown;
