
import { Vue, Component } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { findWhere } from 'underscore';

import { HashMap } from '@/types/common';
import { VuexAction } from '@/types/functions';
import { DictionaryItem } from '@/services/dictionaries-service';
import capitalize from '@/filters/format-capitalize';
import number from '@/filters/format-number';
import currency from '@/filters/format-currency';
import { odometerTypes } from '@/config/dictionaries';
import StepHeader from '@/mixins/create/step-header';

const createStore = namespace('create');
const commonStore = namespace('common');

@Component({
  filters: {
    capitalize,
    currency,
    number,
  },
})
export default class StepCostHeader extends Mixins<StepHeader>(StepHeader) {
  protected step: string = 'cost';

  @createStore.State data!: HashMap<any>;
  @createStore.State steps!: HashMap<any>;
  @createStore.Action setStep!: VuexAction;
  @commonStore.Getter getDictionaryItem!: (
    type: string,
    key: string
  ) => DictionaryItem;

  private get specs() {
    return this.data.specs || {};
  }

  private get dealTerms() {
    return this.data.dealTerms || {};
  }

  private get vin() {
    return this.specs.vin;
  }

  private get state() {
    return this.getDictionaryItem('state', this.specs.state).name;
  }

  private get odometer() {
    return this.specs.odometer ? this.specs.odometer.value : '';
  }

  private get odometerUnit() {
    return this.specs.odometer ? odometerTypes[this.specs.odometer.unit] : '';
  }
}
