
import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { findWhere } from 'underscore';

import { Dictionaries, DictionaryItem } from '@/services/dictionaries-service';
import { HashMap } from '@/types/common';
import FieldMixin from '@/mixins/create/field';

const commonStore = namespace('common');

@Component
export default class StepTechBodyType extends Mixins<FieldMixin>(FieldMixin) {
  private isAllVisible: boolean = false;

  @commonStore.State dictionaries!: Dictionaries;

  created() {
    if (
      this.specs.bodyType &&
      !findWhere(this.types, { id: this.specs.bodyType })
    ) {
      this.isAllVisible = true;
    }
  }

  private get specs() {
    return this.data.specs || {};
  }

  private toggle() {
    this.isAllVisible = !this.isAllVisible;
  }

  private get types() {
    if (this.isAllVisible) {
      return this.dictionaries.bodyType;
    } else {
      return this.dictionaries.bodyType.slice(0, 7);
    }
  }

  private get bodyType() {
    return this.specs.bodyType;
  }

  private set bodyType(bodyType) {
    this.clearError({ name: 'specs.bodyType' });
    this.setField({ specs: { ...this.specs, bodyType } });
  }

  private get error() {
    return (this.errors['specs.bodyType'] || []).join('. ');
  }
}
