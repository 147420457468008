var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__feature-item" }, [
    _c(
      "div",
      { staticClass: "vehicle-form__feature-box" },
      [
        _c(
          "div",
          { staticClass: "vehicle-form__price vehicle-form__price_condensed" },
          [
            _c(
              "div",
              {
                staticClass:
                  "vehicle-form__title vehicle-form__title_big-alter vehicle-form__title_condensed-other jest-price-byn",
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("currency")(_vm.advert.price, "BYN")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            !_vm.isMobileSpecial
              ? _c("div", { staticClass: "vehicle-form__intro" }, [
                  _c("div", { staticClass: "vehicle-form__intro-list" }, [
                    _c("div", { staticClass: "vehicle-form__intro-item" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-form__description vehicle-form__description_other vehicle-form__description_small jest-price-other",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("currency")(_vm.advert.price, "USD")
                              ) +
                              " /\n              " +
                              _vm._s(
                                _vm._f("currency")(_vm.advert.price, "EUR")
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.advert.dealTerms.includeVat
                      ? _c("div", { staticClass: "vehicle-form__intro-item" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
                            },
                            [_vm._v("\n              Цена с НДС\n            ")]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_other vehicle-form__description_small jest-price-other",
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("currency")(_vm.advert.price, "USD")) +
                        " /\n        " +
                        _vm._s(_vm._f("currency")(_vm.advert.price, "EUR")) +
                        "\n      "
                    ),
                  ]
                ),
          ]
        ),
        _vm._v(" "),
        _vm.isMobileSpecial && _vm.advert.dealTerms.includeVat
          ? _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_other vehicle-form__description_small",
              },
              [_vm._v("\n      Цена с НДС\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isRatingHeaderVisible
          ? _c(
              "div",
              {
                staticClass:
                  "vehicle-form__grade vehicle-form__grade_decor helpers_show_tablet",
              },
              [
                _c("rating-header", {
                  attrs: {
                    "count-reviews": _vm.reviewsRating.count,
                    "rating-average": _vm.reviewsRating.average,
                    "is-counter-clickable": true,
                    "is-specifics-visible": true,
                    "rating-by": "модели",
                  },
                  on: { "click-specifics": _vm.onRatingHeaderSpecificsClick },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("advert-leasing"),
        _vm._v(" "),
        _c("div", { staticClass: "vehicle-form__parameter" }, [
          _c("div", { staticClass: "vehicle-form__parameter-list" }, [
            _c("div", { staticClass: "vehicle-form__parameter-item" }, [
              _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter jest-year",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.specs.year) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.specs.odometer.value
              ? _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                  _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter jest-odometer",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm._f("number")(_vm.specs.odometer.value)
                                ) +
                                " " +
                                _vm._s(_vm.odometerUnit) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__parameter-item" }, [
              _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter jest-body-type",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm._f("capitalize")(_vm.bodyType)) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__parameter-item" }, [
              _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter jest-color",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm._f("capitalize")(_vm.color)) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__parameter-item" }, [
              _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                _vm._m(4),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter jest-engine",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.engine) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.power
              ? _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                  _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                    _vm._m(5),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter jest-engine",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.power) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__parameter-item" }, [
              _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                _vm._m(6),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter jest-transmission",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm._f("capitalize")(_vm.transmission)) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__parameter-item" }, [
              _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                _vm._m(7),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter jest-drivetrain",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm._f("capitalize")(_vm.drivetrain)) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__parameter-item" }, [
              _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                _vm._m(8),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter jest-state",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm._f("capitalize")(_vm.state)) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.isExchangeVisible
              ? _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                  _vm._m(9),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "vehicle-form__parameter-item" }, [
              _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
                _vm._m(10),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__parameter-part vehicle-form__parameter-part_2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter jest-customs-clearance",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.customsClearance ? "Да" : "Нет") +
                            "\n              "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.isWarrantyVisible
              ? _c("div", { staticClass: "vehicle-form__parameter-item" }, [
                  _vm._m(11),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _vm.vin
          ? _c(
              "div",
              { staticClass: "vehicle-form__vin vehicle-form__vin_condensed" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_semibold vehicle-form__description_condensed-other",
                  },
                  [
                    _vm._v("\n        VIN:\n        "),
                    _vm.isVinVisible || _vm.isOwnAdvert
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter vehicle-form__description_font-weight_normal",
                          },
                          [_vm._v(_vm._s(_vm.vin))]
                        )
                      : _c(
                          "a",
                          {
                            staticClass:
                              "vehicle-form__link vehicle-form__link_primary vehicle-form__link_base-alter vehicle-form__link_font-weight_normal vehicle-form__link_nodecor",
                            on: { click: _vm.onShowVinClick },
                          },
                          [
                            _vm._v(_vm._s(_vm.vin)),
                            _c(
                              "span",
                              { staticClass: "vehicle-form__link-dot" },
                              [_vm._v("• • • • • • • •")]
                            ),
                          ]
                        ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_other vehicle-form__description_base vehicle-form__description_condensed-other",
                  },
                  [
                    _vm._v(
                      "\n        Перед покупкой рекомендуем самостоятельно проверить VIN\n      "
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isManualControlVisible
          ? _c(
              "div",
              {
                staticClass:
                  "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter vehicle-form__description_invalid jest-manual-control",
              },
              [
                _vm._v(
                  "\n      Ручное управление для людей с ограниченными возможностями\n    "
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
          },
          [_vm._v("\n                Год выпуска\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
          },
          [_vm._v("\n                Пробег\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
          },
          [_vm._v("\n                Кузов\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
          },
          [_vm._v("\n                Цвет\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
          },
          [_vm._v("\n                Двигатель\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
          },
          [_vm._v("\n                Мощность\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
          },
          [_vm._v("\n                Коробка\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
          },
          [_vm._v("\n                Привод\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
          },
          [_vm._v("\n                Состояние\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__parameter-part vehicle-form__parameter-part_1",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
            },
            [_vm._v("\n                Обмен\n              ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__parameter-part vehicle-form__parameter-part_2",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter jest-exchange",
            },
            [_vm._v("\n                Возможен\n              ")]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__parameter-part vehicle-form__parameter-part_1",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
          },
          [_vm._v("\n                Растаможен\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "vehicle-form__parameter-flex" }, [
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__parameter-part vehicle-form__parameter-part_1",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_other vehicle-form__description_base-alter",
            },
            [_vm._v("\n                На гарантии\n              ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__parameter-part vehicle-form__parameter-part_2",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_primary vehicle-form__description_base-alter jest-warranty",
            },
            [_vm._v("\n                Да\n              ")]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }