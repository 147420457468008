var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vehicle-form__grade" }, [
    _c("div", { staticClass: "vehicle-form__grade-flex" }, [
      _c("input", {
        staticClass: "i-checkbox__real helpers_show_tablet",
        attrs: {
          type: "radio",
          name: "ratingRadioGroup",
          id: "ratingRadioMarks",
          checked: "",
        },
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "vehicle-form__grade-switcher helpers_show_tablet",
          attrs: { for: "ratingRadioMarks" },
        },
        [_vm._v("Оценки")]
      ),
      _vm._v(" "),
      _c("input", {
        staticClass: "i-checkbox__real helpers_show_tablet",
        attrs: {
          type: "radio",
          name: "ratingRadioGroup",
          id: "ratingRadioPros",
        },
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "vehicle-form__grade-switcher helpers_show_tablet",
          attrs: { for: "ratingRadioPros" },
        },
        [_vm._v("Плюсы")]
      ),
      _vm._v(" "),
      _c("input", {
        staticClass: "i-checkbox__real helpers_show_tablet",
        attrs: {
          type: "radio",
          name: "ratingRadioGroup",
          id: "ratingRadioCons",
        },
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "vehicle-form__grade-switcher helpers_show_tablet",
          attrs: { for: "ratingRadioCons" },
        },
        [_vm._v("Минусы")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vehicle-form__grade-part vehicle-form__grade-part_1" },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__title vehicle-form__title_base vehicle-form__title_condensed-additional",
            },
            [
              _vm._v("\n          Оценка автора:\n          "),
              _c("rating-stars", {
                attrs: { "rating-average": _vm.review.ratingAverage },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vehicle-form__grade-part vehicle-form__grade-part_2" },
        [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description vehicle-form__description_base-alter vehicle-form__description_primary vehicle-form__description_font-weight_semibold",
            },
            [_vm._v("\n          Оценки\n        ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__parameter vehicle-form__parameter_specific",
            },
            [
              _c(
                "div",
                { staticClass: "vehicle-form__parameter-list" },
                _vm._l(_vm.marks, function (mark) {
                  return _c("rating-mark", {
                    key: mark.key,
                    attrs: {
                      "dictionary-key": mark.dictionaryKey,
                      value: mark.value,
                    },
                  })
                }),
                1
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "vehicle-form__grade-part vehicle-form__grade-part_3 vehicle-form__grade-part_3-specific",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm.review.pros.length
            ? _c(
                "div",
                { staticClass: "vehicle-form__proscons" },
                _vm._l(_vm.review.pros, function (quality) {
                  return _c("rating-quality", {
                    key: quality.id,
                    attrs: { mark: "pro", label: quality.title },
                  })
                }),
                1
              )
            : [
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-form__description vehicle-form__description_other vehicle-form__description_small vehicle-form__description_info helpers_show_tablet",
                  },
                  [
                    _vm._v(
                      "\n            Пользователь не поставил ни одного плюса\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "vehicle-form__proscons helpers_hide_tablet" },
                  _vm._l(_vm.review.cons, function (quality) {
                    return _c("rating-quality", {
                      key: quality.id,
                      attrs: { mark: "con", label: quality.title },
                    })
                  }),
                  1
                ),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vehicle-form__grade-part vehicle-form__grade-part_4" },
        [
          _vm._m(1),
          _vm._v(" "),
          _vm.review.cons.length
            ? _c(
                "div",
                {
                  staticClass: "vehicle-form__proscons",
                  class: { helpers_show_tablet: !_vm.review.pros.length },
                },
                _vm._l(_vm.review.cons, function (quality) {
                  return _c("rating-quality", {
                    key: quality.id,
                    attrs: { mark: "con", label: quality.title },
                  })
                }),
                1
              )
            : _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_other vehicle-form__description_small vehicle-form__description_info helpers_show_tablet",
                },
                [
                  _vm._v(
                    "\n          Пользователь не поставил ни одного минуса\n        "
                  ),
                ]
              ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__description vehicle-form__description_base-alter vehicle-form__description_primary vehicle-form__description_font-weight_semibold vehicle-form__description_nonadaptive",
      },
      [
        _c("div", { staticClass: "vehicle-form__description-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description-part vehicle-form__description-part_1",
            },
            [
              _vm._v("\n              Плюсы "),
              _c("span", { staticClass: "helpers_hide_tablet" }, [
                _vm._v("и минусы"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "vehicle-form__description-part vehicle-form__description-part_2",
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "vehicle-form__description vehicle-form__description_base-alter vehicle-form__description_primary vehicle-form__description_font-weight_semibold vehicle-form__description_nonadaptive",
      },
      [
        _c("div", { staticClass: "vehicle-form__description-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description-part vehicle-form__description-part_1 helpers_show_tablet",
            },
            [_vm._v("\n              Минусы\n            ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__description-part vehicle-form__description-part_2",
            },
            [_vm._v("\n               \n            ")]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }