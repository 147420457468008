var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isLoading
    ? _c("div", { staticClass: "vehicle-form__slider" }, [
        _c("div", {
          staticClass:
            "vehicle-form__slider-arrow vehicle-form__slider-arrow_prev swiper-button-prev swiper-button-disabled",
        }),
        _vm._v(" "),
        _c("div", {
          staticClass:
            "vehicle-form__slider-arrow vehicle-form__slider-arrow_next swiper-button-next swiper-button-disabled",
        }),
        _vm._v(" "),
        _vm._m(0),
      ])
    : _vm.tiles.length
    ? _c(
        "div",
        { ref: "slider", staticClass: "vehicle-form__slider js-slider" },
        [
          _c("div", {
            staticClass:
              "vehicle-form__slider-arrow vehicle-form__slider-arrow_prev swiper-button-prev swiper-button-disabled js-slider-swipper-prev",
          }),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "vehicle-form__slider-arrow vehicle-form__slider-arrow_next swiper-button-next swiper-button-disabled js-slider-swipper-next",
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__slider-box swiper-container js-slider-swipper",
            },
            [
              _c(
                "div",
                { staticClass: "vehicle-form__slider-list swiper-wrapper" },
                _vm._l(_vm.tiles, function (tile, index) {
                  return _c("tile", {
                    key: tile.id,
                    attrs: { tile: tile, index: index },
                  })
                }),
                1
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__slider-box swiper-container" },
      [
        _c("div", { staticClass: "vehicle-form__slider-list swiper-wrapper" }, [
          _c("div", {
            staticClass:
              "vehicle-form__slider-item vehicle-form__slider-item_stub swiper-slide",
          }),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "vehicle-form__slider-item vehicle-form__slider-item_stub swiper-slide",
          }),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "vehicle-form__slider-item vehicle-form__slider-item_stub swiper-slide",
          }),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "vehicle-form__slider-item vehicle-form__slider-item_stub swiper-slide",
          }),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "vehicle-form__slider-item vehicle-form__slider-item_stub swiper-slide",
          }),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "vehicle-form__slider-item vehicle-form__slider-item_stub swiper-slide",
          }),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "vehicle-form__slider-item vehicle-form__slider-item_stub swiper-slide",
          }),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "vehicle-form__slider-item vehicle-form__slider-item_stub swiper-slide",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }