export default {
  projectName: 'ab',
  entityType: 'review',
  limit: 15,
  maxTextLength: 3000,
  minCommentsBottomFormVisible: 5,
  repliesVisible: 3,
  mentions: {
    linkClass:
      'vehicle-form__link vehicle-form__link_primary-alter vehicle-form__description_font-weight_semibold',
  },
  highlightDuration: 10000,
  scrollList: {
    size: 78,
    remain: 20,
    bench: 20,
    step: 30,
    offsetCorrection: 70,
  },
};
