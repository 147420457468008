
import { Vue, Component, Prop } from 'vue-property-decorator';
import * as moment from 'moment';
import { namespace } from 'vuex-class';
import { VuexAction } from '@/types/functions';
import duration from '@/filters/format-duration';
import timerService from '@/services/timer-service';

const advertsStore = namespace('adverts');

@Component({
  filters: {
    duration,
  },
})
export default class AdvertListItemUp extends Vue {
  @Prop() advertId!: string;
  @Prop() upAvailableIn!: number;
  @Prop() isPremium!: boolean;

  private timer: {
    interval: NodeJS.Timeout | null;
    value: number;
  } | null = null;

  @advertsStore.Action updateUpTimer!: VuexAction;

  created() {
    this.timer = timerService.createTimer(
      this.upAvailableIn,
      (upAvailableIn: number) => {
        this.updateUpTimer({
          id: this.advertId,
          upAvailableIn,
        });
      }
    );
  }

  destroyed() {
    clearInterval(this.timer!.interval!);
  }
}
