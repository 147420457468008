import { Vue, Watch } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
import { findWhere, findIndex, isEmpty } from 'underscore';

import urlService from '@/services/url-service';
import number from '@/filters/format-number';
import plural from '@/filters/plural-form';
import { Dictionaries } from '@/services/dictionaries-service';
import { IAdvert } from '@/interfaces/adverts';

const commonStore = namespace('common');
const advertStore = namespace('common');

@Mixin
export default class AdvertMeta extends Vue {
  @commonStore.State dictionaries!: Dictionaries;
  @advertStore.State advert!: IAdvert;

  private isOgMetaSet: boolean = false;

  protected updateMeta() {
    const { manufacturer, model, location } = this.advert;

    if (manufacturer && model && location && location.city) {
      const title = `Купить ${manufacturer.name} ${model.name}, ${location.city.name} - Автобарахолка Onlíner`;
      const description = `${location.city.name}. Купить ${manufacturer.name} ${model.name} на Автобарахолке Onlíner. Объявления о продаже новых и подержанных автомобилей`;

      this.$metaInfo.title = title;

      const index = findIndex(this.$metaInfo.meta, { name: 'description' });

      if (index !== -1) {
        this.$metaInfo.meta.splice(index, 1, {
          name: 'description',
          content: description,
        });
      }
    }

    !this.isOgMetaSet && !isEmpty(this.dictionaries) && this.setOgMeta();

    if (this.$route.meta.canonical === false) {
      this.$metaInfo.link.push({
        rel: 'canonical',
        href: urlService.secureProjectUrl(
          'ab',
          `${manufacturer.slug}/${model.slug}/${this.advert.id}`
        ),
      });
    }

    this.$meta().refresh();
  }

  protected setOgMeta() {
    const { manufacturer, model, specs, location, images, price } = this.advert;
    const odometer = number(specs ? specs.odometer?.value : 0);
    const bodyType = findWhere(this.dictionaries.bodyType, {
      id: specs.bodyType,
    })?.name;
    const engineType = findWhere(this.dictionaries.engineType, {
      id: specs.engine.type,
    })?.name;
    const transmission = findWhere(this.dictionaries.transmission, {
      id: specs.transmission,
    })?.name;
    const amount = price.converted.BYN.amount;
    const formattedPrice = `${number(amount)} ${plural(
      parseInt(amount),
      ['рубль', 'рубля', 'рублей'],
      false
    )}.`;
    const title = `${manufacturer.name} ${model.name}, ${
      specs.state !== 'new' ? odometer + ' км' : 'Новый'
    }, ${specs.year} г.в., ${location.city.name}`;
    const description = `${bodyType}, ${engineType}${
      specs.engine.capacity ? ' ' + specs.engine.capacity + ' л' : ''
    }, ${transmission}, ${formattedPrice}`;

    //OG
    this.$metaInfo.meta.push({
      name: 'og:title',
      content: title,
    });

    this.$metaInfo.meta.push({
      name: 'og:image',
      content: images[0]['100x100'],
    });

    this.$metaInfo.meta.push({
      name: 'og:site_name',
      content: `AB.ONLINER.BY`,
    });

    this.$metaInfo.meta.push({
      name: 'og:description',
      content: description,
    });

    //Twitter
    this.$metaInfo.meta.push({
      name: 'twitter:card',
      content: `summary_large_image`,
    });

    this.$metaInfo.meta.push({
      name: 'twitter:site',
      content: `AB.ONLINER.BY`,
    });

    this.$metaInfo.meta.push({
      name: 'twitter:title',
      content: title,
    });

    this.$metaInfo.meta.push({
      name: 'twitter:description',
      content: description,
    });

    this.$metaInfo.meta.push({
      name: 'twitter:image',
      content: images[0]['800x800'],
    });

    this.isOgMetaSet = true;
  }

  @Watch('dictionaries') onDictionariesChange() {
    !this.isOgMetaSet && !isEmpty(this.advert) && this.setOgMeta();
  }
}
