
import { Vue, Component } from 'vue-property-decorator';

@Component({
  metaInfo() {
    return {
      meta: [
        {
          name: 'prerender-status-code',
          content: '404',
        },
      ],
    };
  },
})
export default class AdvertNotFound extends Vue {}
