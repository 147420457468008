var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vehicle-form__row vehicle-form__row_extended-other" },
    [
      _c(
        "div",
        {
          staticClass: "vehicle-form__line vehicle-form__line_condensed-other",
        },
        [
          _c(
            "div",
            {
              staticClass: "vehicle-form__group vehicle-form__group_width_full",
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "vehicle-form__field" }, [
                _c(
                  "ul",
                  {
                    staticClass:
                      "vehicle-form__checkbox-list vehicle-form__checkbox-list_color",
                  },
                  _vm._l(_vm.dictionaries.color, function (item) {
                    return _c(
                      "li",
                      {
                        key: item.id,
                        staticClass:
                          "vehicle-form__checkbox-item vehicle-form__checkbox-item_color vehicle-form__checkbox-item_color_extended",
                        class: [`vehicle-form__checkbox-item_color_${item.id}`],
                        attrs: { title: _vm._f("capitalize")(item.name) },
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "vehicle-form__checkbox-label" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "i-checkbox vehicle-form__checkbox vehicle-form__checkbox_base",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.color,
                                      expression: "color",
                                    },
                                  ],
                                  staticClass: "i-checkbox__real",
                                  attrs: { type: "radio", name: "bodyType" },
                                  domProps: {
                                    value: item.id,
                                    checked: _vm._q(_vm.color, item.id),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.color = item.id
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "vehicle-form__checkbox-text",
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          !_vm.error ? _c("div", { staticClass: "dummy" }) : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            {
              staticClass:
                "vehicle-form__line vehicle-form__line_condensed-other",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "vehicle-form__description vehicle-form__description_base vehicle-form__description_error vehicle-form__description_fail vehicle-form__description_width_full",
                },
                [_vm._v(_vm._s(_vm.error))]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "vehicle-form__label vehicle-form__label_base" },
      [
        _c("div", { staticClass: "vehicle-form__label-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "vehicle-form__label-part vehicle-form__label-part_1",
            },
            [
              _c("div", { staticClass: "vehicle-form__label-title" }, [
                _vm._v("Цвет автомобиля"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "vehicle-form__label-part vehicle-form__label-part_2",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }