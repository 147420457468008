export default {
  manufacturer: {
    '2': 'audi',
    '5': 'bmw',
    '12': 'citroen',
    '18': 'fiat',
    '19': 'ford',
    '25': 'hyundai',
    '44': 'mitsubishi',
    '39': 'mazda',
    '41': 'mercedes',
    '48': 'peugeot',
    '52': 'renault',
    '62': 'volkswagen',
  },
};
